import React from 'react';
import { Tooltip } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import classes from './Icons.module.scss';

import * as dictionary from '../../../../../../../core/constants/dictionary';
import { colors } from '../../../../../../../Theme/colors';

const ExecutedIcon = () => {
  return (
    <Tooltip title={dictionary.EXECUTED}>
      <CheckCircle
        className={classes.executedIcon}
        style={{ color: colors.green_500 }}
      />
    </Tooltip>
  );
};

export default ExecutedIcon;
