import React from 'react';
import BaseModal from '../BaseModal';
import { PromotionalCalendarDto } from '../../../../../../core/types/promotional-spot/promotional-calendar.dto';
import * as dictionary from '../../../../../../core/constants/dictionary';

type Props = {
  calendars: PromotionalCalendarDto[];
  onClose: Function;
  onCreate: Function;
}

const CreatePromotionalSpotModal = ({ calendars, onClose, onCreate }: Props) => {
  return (
    <BaseModal
      calendars={calendars}
      feedbackError={dictionary.CREATE_PROMOTIONAL_SPOT_ERROR}
      feedbackSuccess={dictionary.CREATE_PROMOTIONAL_SPOT_SUCCESS}
      onClose={onClose}
      onSubmit={onCreate}
    />
  );
}

export default CreatePromotionalSpotModal;
