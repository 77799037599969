import { mainStore } from './store/mainStore';
import * as dictionary from './constants/dictionary';

export const valueStatus = { INACTIVE: 0, ACTIVE: 1 };

export const userProfile = {
  SUPER_ADMIN: 0,
  RETAIL_ADMIN: 1,
  BUYER: 2,
  SALES_REP: 3,
  PROMOTER: 4,
  PROMOTER_APPROVER: 5,
  STORE_MANAGER: 6,
  SECURITY: 7,
  RESTOCKER: 8,
};

export const getStatusStringValue = value => {
  return value === 1 ? 'Ativo' : 'Inativo';
};

export const getProfileValue = value => {
  switch (value) {
    case userProfile.SUPER_ADMIN:
      return dictionary.SUPER_ADMIN;
    case userProfile.RETAIL_ADMIN:
      return dictionary.RETAIL_ADMIN;
    case userProfile.BUYER:
      return dictionary.BUYER;
    case userProfile.SALES_REP:
      return dictionary.SALES_REP;
    case userProfile.PROMOTER:
      return dictionary.PROMOTER;
    case userProfile.PROMOTER_APPROVER:
      return dictionary.PROMOTERS_APPROVER;
    case userProfile.STORE_MANAGER:
      return dictionary.STORE_MANAGER;
    case userProfile.SECURITY:
      return dictionary.SECURITY;
    case userProfile.RESTOCKER:
      return dictionary.RESTOCKER;
    default:
      return '';
  }
};

const isSuperAdmin = () => {
  return mainStore.loggedUser?.profile === userProfile.SUPER_ADMIN;
};

const isRetailAdmin = () => {
  return mainStore.loggedUser?.profile === userProfile.RETAIL_ADMIN;
};

const isBuyer = () => mainStore.loggedUser?.profile === userProfile.BUYER;

const isSalesRep = () => mainStore.loggedUser?.profile === userProfile.SALES_REP;

const isPromoter = () => mainStore.loggedUser?.profile === userProfile.PROMOTER;

const isPromoterApprover = () =>
  mainStore.loggedUser?.profile === userProfile.PROMOTER_APPROVER;

const isStoreManager = () =>
  mainStore.loggedUser?.profile === userProfile.STORE_MANAGER;

const isSecurity = () => mainStore.loggedUser?.profile === userProfile.SECURITY;

const isRestocker = () =>
  mainStore.loggedUser?.profile === userProfile.RESTOCKER;

const isRetailsManagersRole = () => {
  return isSalesRep() || isSuperAdmin();
};

const isWebUsersRole = () => {
  return (
    isSuperAdmin() ||
    isRetailAdmin() ||
    isBuyer() ||
    isSalesRep() ||
    isRestocker()
  );
};

const isMobileUsersRole = () => {
  return (
    isPromoter() || isPromoterApprover() || isStoreManager() || isSecurity()
  );
};

const isRetailVision = () => {
  return isRetailAdmin() || isBuyer() || isPromoterApprover();
};

export const profileCheck = {
  isSuperAdmin,
  isRetailAdmin,
  isBuyer,
  isSalesRep,
  isPromoter,
  isPromoterApprover,
  isStoreManager,
  isSecurity,
  isRestocker,
  isRetailsManagersRole,
  isWebUsersRole,
  isMobileUsersRole,
  isRetailVision,
};
