import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { FilterOptionsState } from '@material-ui/lab';
import { DialogActions, DialogContent, makeStyles, Typography } from '@material-ui/core';
import ModalTitle from '../../../../components/Modal/Title';
import CancelButton from '../../../../components/Buttons/Cancel';
import DefaultButton from '../../../../components/Buttons/Default';
import Autocomplete from '../../../../components/Autocomplete';
import ModalSmallContainer from '../../../../components/Modal/SmallContainer';
import PromoterSuccessModal from '../../components/PromoterSuccessModal';
import PromoterWarningModal from '../../components/PromoterWarningModal';
import PromoterSuspensionModal from '../../components/PromoterSuspensionModal';
import LoginModal from '../../components/LoginModal';
import { mainStore } from '../../../../core/store/mainStore';
import { checkInVisit } from '../../../../core/services/visits/visitsService';
import { getPromoterUsers } from '../../../../core/services/users/usersService';
import { useDebounce } from '../../../../core/hooks';
import { exist } from '../../../../core/validation';
import { timezone } from '../../../../core/constants/dates';
import { colors } from '../../../../Theme/colors';
import { UserInfo } from '../../../Users/utils';
import { serializeCpfAndNameSearch } from '../../../../core/utils';
import { DEBOUNCE_DELAY_ON_FETCH_USER } from '../../../../core/constants/user';
import { liberateAccessByStoreManagerLogin } from '../../../../core/services/auth/authService';
import { MAX_PROMOTER_WARNING_BEFORE_SUSPENSION } from '../../../../core/constants/visits';
import * as dictionary from '../../../../core/constants/dictionary';
import checkOutFail from '../../../../assets/general/checkOutFail.svg';
import {
  getOptionLabel,
  getOptionDisabled,
  getOptionSelected,
  renderOption,
} from './utils';
import { styles } from '../../../VisitsAutoCheckin/style';
import { CheckinErrorCode } from '../../../../core/types/visits/checkin-error-code.enum';

const useStyles = makeStyles({
  option: {
    '&[aria-disabled="true"]': {
      opacity: 1,
    },
  }
});

type CheckinVisit = {
  id: string;
  checkinAt: string;
  checkinStatus: number;
  checkoutAt: string;
  checkoutStatus: number;
  promoterUserId: string;
  promoterName?: string;
  qrcodeId?: string;
  storeId: string;
  timezone: string;
  totalWarnings: number;
}

export enum ModalType {
  DEFAULT = 0,
  SUCCESS = 1,
  WARNING = 2,
  SUSPENDED = 3,
  STORE_MANAGER_LOGIN = 4,
  ERROR = 5,
}

export type PromoterInfo = UserInfo & { 
  name: string;
  storeName: string;
  totalWarnings: number; 
  lastVisit: {
    checkinAt: string;
    storeName: string;
  }
}

type CheckinVisitResponse = {
  accesses?: number;
  visits: CheckinVisit[];
  totalWarnings: number;
  lastVisit: {
    checkinAt: string;
    storeName: string;
  }
  isLastVisitWithoutCheckout?: boolean;
}

type Props = {
  isOpen: boolean;
  closeModalCallback: Function;
  reloadCallback: Function;
  currentVisit: CheckinVisit | null;
}

export const CheckIn = ({ isOpen, closeModalCallback, currentVisit, reloadCallback }: Props) => {
  const [promoter, setPromoter] = useState<PromoterInfo>();
  const [modalType, setModalType] = useState<ModalType>(ModalType.DEFAULT);
  const [promotersOptions, setPromotersOptions] = useState<PromoterInfo[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const classes = useStyles();
  
  useEffect(() => {
    if (currentVisit) {
      if (currentVisit.totalWarnings === 3) {
        setModalType(ModalType.STORE_MANAGER_LOGIN);
        setPromoter((prevState: any) => ({
          ...prevState,
          id: currentVisit.promoterUserId,
          name: currentVisit.promoterName,
        }));
      }
      else if (currentVisit.totalWarnings > 0) {
        setModalType(ModalType.WARNING);
        setPromoter((prevState: any) => ({
          ...prevState,
          totalWarnings: currentVisit.totalWarnings,
        }));
      }
      else {
        setModalType(ModalType.DEFAULT);
      }
    }
  }, [currentVisit]);

  const debouncedOnSearch = useDebounce(async (inputValue: string[]) => {
    const queryParam = String(inputValue[0]);
    if (queryParam && queryParam.length >= 3) {
      try {
        mainStore.requestLoading = true;

        const response = await getPromoterUsers(
          mainStore.loggedUser.retailId,
          undefined, 
          undefined,
          serializeCpfAndNameSearch(queryParam),
        );
        setPromotersOptions(response.items);
        mainStore.requestLoading = false;
      }
      catch(err) {
        mainStore.requestLoading = false;
      }
    } else {
      setPromotersOptions([]);
    }
  }, DEBOUNCE_DELAY_ON_FETCH_USER);

  const onClickButtonHandler = useCallback(
    async event => {
      event.preventDefault();
      try {
        mainStore.requestLoading = true;
        const checkoutDTO = {
          promoterUserId: promoter?.id,
          qrcodeId: '',
          timezone: timezone(),
        };
        const { data }: {data: CheckinVisitResponse} = await checkInVisit(checkoutDTO);
        if (!data) {
          throw new Error();
        }
        
        const { 
          visits,
          accesses,
          lastVisit,
          totalWarnings, 
          isLastVisitWithoutCheckout, 
        } = data;
        let checkinModalType = ModalType.SUCCESS;
        
        if (totalWarnings === MAX_PROMOTER_WARNING_BEFORE_SUSPENSION) {
          checkinModalType = ModalType.SUSPENDED;
        }
        else if (isLastVisitWithoutCheckout && totalWarnings && totalWarnings > 0) {
          setPromoter((prevState: any) => ({
            ...prevState,
            lastVisit,
            totalWarnings,
          }));
          checkinModalType = ModalType.WARNING;
        }
        else if (accesses === 0) {
          checkinModalType = ModalType.ERROR;
          setErrorMessage(`
            ${dictionary.PROMOTER_DONT_HAVE_ACCESS_TO_AGREEMENT}.\n
            ${dictionary.MORE_DETAILS_ASK_TO}\n${dictionary.CONTACT_RESPONSIBLE_PERSON}
          `);
        }
        else if (visits.length === 0) {
          checkinModalType = ModalType.ERROR;
          setErrorMessage(
            `${dictionary.MORE_DETAILS_ASK_TO}\n${dictionary.CONTACT_RESPONSIBLE_PERSON}`
          );
        }
        setModalType(checkinModalType);

        mainStore.requestLoading = false;
      } 
      catch (err) {
        setModalType(ModalType.ERROR);
        setErrorMessage(err.response.data.code === CheckinErrorCode.CANNOT_INFER_STORE
          ? `${dictionary.CANNOT_INFER_USER_STORE}.`
          : (
            `${dictionary.UNEXPECTED_ERROR_OCCURRED_ON_CHECKIN}.\n
            ${dictionary.MORE_DETAILS_ASK_TO}\n${dictionary.CONTACT_RESPONSIBLE_PERSON}`
          )
        )
        mainStore.requestLoading = false;
      } 
    },
    [promoter],
  );

  const handleClose = useCallback(() => {
    setModalType(0);
    closeModalCallback();
  }, [closeModalCallback]);

  const emptyMessage = (
    (exist(promoter)) ?
      dictionary.CPF_NO_REGISTRATION :
      dictionary.TYPE_A_FEW_CHARACTERS
  )

  const renderContent = useMemo(() => {
    if (modalType === ModalType.DEFAULT) {
      return (
        <ModalSmallContainer closeModalCallback={closeModalCallback} isOpen={isOpen}>
          <ModalTitle title={dictionary.PROMOTER_CHECK_IN} />
          <DialogContent>
            <Autocomplete
              value={promoter}
              classes={classes}
              options={promotersOptions}
              placeholder={dictionary.TYPE_PROMOTOR_CPF_OR_NAME}
              noOptionsText={emptyMessage}
              renderOption={renderOption}
              getOptionLabel={getOptionLabel}
              getOptionSelected={getOptionSelected}
              getOptionDisabled={getOptionDisabled}
              filterOptions={(options: PromoterInfo[], _state: FilterOptionsState<PromoterInfo>) => options}
              onChange={(_e: React.ChangeEvent<{}>, value: PromoterInfo) => setPromoter(value)}
              onInputChange={(_e: React.ChangeEvent<{}>, value: string) => debouncedOnSearch(value)}
              style={{ 
                width: '100%', 
                marginTop: '3px', 
                marginBottom: '20px',
              }}
            />
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={handleClose} />
            <DefaultButton
              label={dictionary.DO_CHECK_IN}
              disabled={!exist(promoter)}
              style={{ width: 'auto' }}
              onClick={(event: any) => onClickButtonHandler(event)}
            />
          </DialogActions>
        </ModalSmallContainer>
      );
    }
    if (modalType === ModalType.SUCCESS) {
      return (
        <PromoterSuccessModal 
          isOpen={isOpen}
          data={{ promoterName: promoter?.name }}
          onReloadHandler={reloadCallback}
          onCloseModalHandler={closeModalCallback}
        />
      );
    }
    if (modalType === ModalType.WARNING) {
      return (
        <PromoterWarningModal 
          isOpen={isOpen}
          data={{ 
            store: promoter?.lastVisit.storeName ?? '',
            suspensionAt: promoter?.lastVisit.checkinAt ?? '',
            promoterName: promoter?.name ?? '',
            totalWarnings: promoter?.totalWarnings ?? 0,
          }}
          onCloseModalHandler={() => setModalType(ModalType.SUCCESS)}
        />
      );
    }
    if (modalType === ModalType.SUSPENDED) {
      return (
        <PromoterSuspensionModal 
          isOpen={isOpen}
          data={{ promoterName: promoter?.name ?? '' }}
          onReloadHandler={reloadCallback}
          onCloseModalHandler={closeModalCallback}
          onContinueHandler={() => {
            setModalType(ModalType.STORE_MANAGER_LOGIN);
          }}
        />
      );
    }
    if (modalType === ModalType.STORE_MANAGER_LOGIN) {
      return (
        <LoginModal 
          isOpen={isOpen}
          title={"Liberar acesso do promotor"} 
          onReloadHandler={reloadCallback}
          onCloseModalHandler={closeModalCallback}
          onSubmitFormHandler={ async (cpf: string, password: string) => {
            const result = await liberateAccessByStoreManagerLogin({
              cpf, 
              password, 
              promoterId: promoter?.id,
              qrcodeId: '',
              timezone: timezone(),
            });

            if (result) {
              setModalType(ModalType.SUCCESS);
            }
          }}
        />
      );
    }

    if (modalType === ModalType.ERROR) {
      return (
        <ModalSmallContainer closeModalCallback={closeModalCallback} isOpen={isOpen}>
          <DialogContent style={styles.errorModalDialog as React.CSSProperties}>
            <img
              alt="check in check"
              src={checkOutFail}
              style={styles.errorModalImg}
            />

            <Typography variant="h1" style={styles.errorModaltext as React.CSSProperties}>
              {dictionary.ACCESS_NOT_RELEASED}
            </Typography>

            <Typography variant="body1">
              {errorMessage}
            </Typography>
          </DialogContent>

          <DialogActions>
            <CancelButton label={dictionary.CLOSE} onClick={handleClose} />
          </DialogActions>
        </ModalSmallContainer>
      );
    }
    
    return null;
  }, [
    isOpen,
    classes,
    modalType,
    promoter,
    emptyMessage,
    promotersOptions,
    handleClose,
    reloadCallback,
    debouncedOnSearch,
    closeModalCallback,
    onClickButtonHandler,
  ]);

  return renderContent;
};
