import React from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import './style.css';

const arrowFeedback = props => {
    let feedback = (
        <span className="card-title-feedback --danger">
            <ArrowDownwardIcon 
                fontSize="small"
                style={{ color: 'white' }} 
            />
        </span>
    );

    if(props.success) {
        feedback = (
            <span className="card-title-feedback --success">
              <ArrowUpwardIcon 
                fontSize="small" 
                style={{ color: 'white' }} 
              />
            </span>
        );
    }

    return  feedback;
}

export default arrowFeedback;