import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import '../../style.css';

const removeFilter = ({ visible, clicked }) => {
    let content = null;
    if(visible) {
        content = (
            <span className="card-remove-filter" onClick={ clicked }>
                <CloseIcon style={{color: 'white'}} />
            </span>
        );
    }

    return content;
}

export default removeFilter;