import { ProductGroup } from '../../../Addressing/types';
import { StoreAreaLabel } from '../../../../../core/types/store-area-label.enum';
import { CalendarPeriodTableRow } from '../../../common/types';
import { PromotionalSpotTypeLabel } from '../../../../../core/types/promotional-spot/promotional-spot-type-label.enum';
import { RetailEntityType } from '../../../../../core/types/retail/retail-entity.type';
import { sortTextList } from '../../../../../core/strings';
import { formatWithoutTimezone } from '../../../../../core/constants/dates';

export type FormFilters = {
  year: string;
  month: string;
  period?: CalendarPeriodTableRow[];
  type?: PromotionalSpotTypeLabel[];
  spotName?: string;
  sector: ProductGroup[];
  area: StoreAreaLabel[];
  isOpen: boolean;
  retailId: string;
  retail: RetailEntityType;
};

export const monthArray = Array(12)
  .fill(12)
  .map((x, i) => (i += 1));

export const storeAreas = sortTextList([
  StoreAreaLabel.BUTCHER_SHOP,
  StoreAreaLabel.CELLAR,
  StoreAreaLabel.BAZAAR,
  StoreAreaLabel.CHECKOUT,
  StoreAreaLabel.CENTRAL_HALL,
  StoreAreaLabel.FRONT_HALL,
  StoreAreaLabel.REAR_HALL,
  StoreAreaLabel.ELECTRO,
  StoreAreaLabel.ENTRY_HALL,
  StoreAreaLabel.PRODUCE,
  StoreAreaLabel.BAKERY,
  StoreAreaLabel.ROTISSERIA,
  StoreAreaLabel.FLOWER_SHOP,
]);

export const parseYearOptionsFromPeriods = (
  periodOptions: CalendarPeriodTableRow[]
): string[] =>
  Array.from(
    new Set(
      periodOptions.map((period) => {
        const date = new Date(`${period.startAt}T00:00:00Z`);
        return date.getUTCFullYear().toString();
      })
    )
  );

type FilterPeriodOptionsBySelectedParams = {
  year?: string;
  month?: string;
  periods: CalendarPeriodTableRow[];
};

export const extractDateStringInfo = (date: Date) => {
  const [day, month, year] = formatWithoutTimezone(date).split('/');
  return { day, month, year };
};

export const filterPeriodOptionsBySelected = ({
  year,
  month,
  periods = [],
}: FilterPeriodOptionsBySelectedParams) => {
  return periods.filter((item) => {
    const info = extractDateStringInfo(new Date(item.startAt as Date));
    if (year) {
      if (parseInt(info.year) !== parseInt(year)) {
        return false;
      }
    }
    if (month) {
      if (parseInt(info.month) !== parseInt(month)) {
        return false;
      }
    }
    return true;
  });
};
