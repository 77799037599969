import { SHELF_CHECK_MISSION_TYPE_DEFAULT_FILTER_VALUE } from "../../core/constants/shelf_check_mission_types";

const FILE_TITLE = 'Relatório de abastecimento';
const FILE_FORMAT = 'xlsx';

export const isOneDayInterval = (startDate, endDate) => {
  /**
   * @param: [string] Duas datas, no mesmo formato
   * @return: [boolean] Retorna true se os dois valores são iguais; false, caso contrário
   */
  return startDate === endDate;
};

export const formatFilenameDatePiece = (startDate, endDate) => {
  /**
   * @param: [string] Duas datas, no mesmo formato
   * @return: [string] Retorna a informação de data de início (e fim, se diferentes) no formato:
   *  '(DIA_INICIO)'              (valores iguais)
   *  '(DIA_INICIO - DIA_FIM)'    (valores diferentes)
   */
  let output = startDate;
  if (!isOneDayInterval(startDate, endDate)) {
    output += ` - ${endDate}`;
  }

  return `(${output})`;
};

export const formatFilename = (startDate, endDate) => {
  /**
   * @param: [string] Duas datas, no mesmo formato
   * @return: [string] Retorna o nome do arquivo completo ao realizar download do Blob
   */
  const date = formatFilenameDatePiece(startDate, endDate);

  return `${FILE_TITLE} ${date}.${FILE_FORMAT}`;
};

export const createInitialFilter = ({loggedUser}) => {
  return {
    retailId: loggedUser?.retailId,
    storeId: loggedUser?.storeId,
    productGroupId: null,
    startDate: new Date(),
    endDate: new Date(),
    checkType: [SHELF_CHECK_MISSION_TYPE_DEFAULT_FILTER_VALUE.id],
  };
};
