import React from 'react';
import BaseModal from '../BaseModal';
import { NEW_SUPPLIER } from '../../../../../core/constants/dictionary';
import { defaultFormValues } from '../../../utils';
import { RetailEntityType } from '../../../../../core/types/retail/retail-entity.type';

type Props = {
  isOpen: boolean;
  retails: RetailEntityType[],
  retailId: string;
  onClose: Function;
  onSubmit: Function;
};

const CreateAddressingModal = ({ isOpen, retailId, retails, onClose, onSubmit }: Props) => {
  return (
    <BaseModal 
      title={NEW_SUPPLIER}
      data={{
        ...defaultFormValues,
        retailId,
      }}
      retails={retails}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

export default CreateAddressingModal;
