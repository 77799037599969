import { useState } from 'react';
import { ModalType } from '../types/modals.type';

export const useModalHook = () => {
  const [modalType, setModalType] = useState<ModalType | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setModalType(null);
    setIsModalOpen(false);
  };

  const openModal = (type: ModalType) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  return {
    modalType,
    isModalOpen,
    openModal,
    closeModal,
  };
};
