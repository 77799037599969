import { AxiosResponse } from 'axios';
import API from '../api/config';
import { PromotionalCalendarDto } from '../../types/promotional-spot/promotional-calendar.dto';
import { PromotionalCalendarEntity } from '../../types/promotional-spot/promotional-calendar.entity';
import * as routes from '../api/routes';
import {
  CreatePromotionalCalendarPeriodsDto,
  DeletePromotionalCalendarPeriodsDto,
  GetAllPromotionalCalendarPeriodsParams,
  UpdatePromotionalCalendarPeriodsDto,
} from './types';
import { CalendarPeriodTableRow } from '../../../pages/promotional-spots/common/types';

export const getPromotionalCalendarPeriodsAPI = (
  calendarId: string
): Promise<AxiosResponse<CalendarPeriodTableRow[]>> => {
  const url = routes.API_PROMOTIONAL_CALENDAR_PERIODS.replace(
    ':id',
    calendarId
  );
  return API.get(url);
};

export const getAllPromotionalCalendarPeriodsAPI = (
  params: GetAllPromotionalCalendarPeriodsParams
): Promise<AxiosResponse<CalendarPeriodTableRow[]>> => {
  const url = routes.API_PROMOTIONAL_CALENDAR_PERIODS.replace('/:id', '');

  return API.get(url, { params });
};
export const addPromotionalCalendarPeriodsAPI = async (
  calendarId: string,
  periods: CreatePromotionalCalendarPeriodsDto
): Promise<AxiosResponse<PromotionalCalendarEntity>> => {
  const url = routes.API_PROMOTIONAL_CALENDAR_PERIODS.replace(
    ':id',
    calendarId
  );
  return API.post(url, periods);
};

export const editPromotionalCalendarPeriodsAPI = async (
  calendarId: string,
  periods: UpdatePromotionalCalendarPeriodsDto
): Promise<AxiosResponse<PromotionalCalendarEntity>> => {
  const url = routes.API_PROMOTIONAL_CALENDAR_PERIODS.replace(
    ':id',
    calendarId
  );
  return API.put(url, periods);
};

export const deletePromotionalCalendarPeriodsAPI = async (
  calendarId: string,
  periods: DeletePromotionalCalendarPeriodsDto
): Promise<AxiosResponse<PromotionalCalendarEntity>> => {
  const url = routes.API_PROMOTIONAL_CALENDAR_PERIODS.replace(
    ':id',
    calendarId
  );
  return API.put(url, periods);
};
