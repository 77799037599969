import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import classes from './ResumeCard.module.scss';
import FractionalValue from './FractionalValue';
import PercentValue from './PercentValue';

type ValueTypes = {
  count: number;
  total: number;
  showTotal?: boolean;
};

type Props = {
  title: string;
  label: {
    left: string;
    right: string;
  };
  values: {
    left: ValueTypes;
    right: number | ValueTypes;
  };
};

const ResumeCard = ({ title, label, values }: Props) => {
  return (
    <Paper className={classes.paper}>
      <Typography variant="h1" className={classes.title}>
        {title}
      </Typography>
      <Grid container>
        <Grid item className={classes.labelItem}>
          <Typography variant="h1" className={classes.label}>
            <FractionalValue {...values.left} />
          </Typography>
          <Typography variant="overline">{label.left}</Typography>
        </Grid>
        <Grid item>
          {typeof values.right === 'number' ? (
            <PercentValue value={values.right} />
          ) : (
            <FractionalValue {...values.right} />
          )}
          <Typography variant="overline">{label.right}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ResumeCard;
