import React, { useCallback } from 'react';
import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import * as dictionary from '../../../../core/constants/dictionary';
import ModalTitle from '../../../../components/Modal/Title';
import CancelButton from '../../../../components/Buttons/Cancel';
import DefaultButton from '../../../../components/Buttons/Default';
import { removeAgreement, loadAgreements } from '../../functions';
import { utils } from '../../../../core/utils';
import { mainStore } from '../../../../core/store/mainStore';
import { trackAgreementDelete } from '../../../../core/mixpanel/mixPanelEvents';

export const RemoveAgreement = ({ closeModalCallback, currentAgreement }) => {
  const handleRemoveAgreement = useCallback(
    async event => {
      event.preventDefault();
      try {
        const response = await removeAgreement(currentAgreement);
        if (response.status === 200) {
          await loadAgreements();
          trackAgreementDelete({
            code: currentAgreement.internalCode,
            suppliers: currentAgreement.supplierNames.split(','),
          });
          mainStore.requestLoading = false;
          closeModalCallback();
          utils.openSnackBar(dictionary.SUCCESS, dictionary.AGREEMENT_REMOVED);
        }
      } catch (err) {
        utils.openSnackBar(dictionary.ERROR, dictionary.UNKNOWN_ERROR);
      } finally {
        mainStore.requestLoading = false;
      }
    },
    [closeModalCallback, currentAgreement],
  );

  return (
    <>
      <ModalTitle title={dictionary.DELETE_AGREEMENT} />

      <DialogContent>
        <Typography variant="body2">
          {dictionary.CONFIRM_AGREEMENT_REMOVAL}
          <span style={{ fontWeight: 'bold', marginLeft: '0.1rem' }}>
            {currentAgreement.internalCode}
          </span>
        </Typography>
      </DialogContent>

      <DialogActions>
        <CancelButton
          onClick={() => {
            closeModalCallback();
          }}
        />

        <DefaultButton
          onClick={event => handleRemoveAgreement(event)}
          color="primary"
          style={{ width: 'auto' }}
          label={dictionary.DELETE}
        />
      </DialogActions>
    </>
  );
};
