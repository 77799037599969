import React from 'react';
import { view } from 'react-easy-state';
import { 
  ADD_CALENDAR_ERROR, 
  ADD_CALENDAR_SUCCESS, 
} from '../../../core/constants/dictionary';
import { PromotionalCalendarForm } from '../common/components';
import { pageTitle } from './utils';

const AddPromotionalCalendar = () => {
  return (
    <PromotionalCalendarForm 
      pageTitle={pageTitle}
      feedbackError={ADD_CALENDAR_ERROR}
      feedbackSuccess={ADD_CALENDAR_SUCCESS}
    />
  );
};

export default view(AddPromotionalCalendar);
