import React, { useCallback, useEffect, useRef } from 'react';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { Button, Grid, TextField as MUITextField } from '@material-ui/core';
import TextField from '../../../../../components/InputTextField';
import { mainStore } from '../../../../../core/store/mainStore';
import { ProductGroup } from '../../../Addressing/types';
import { globalTypeOptions } from '../../../common/globalTypeOptions';
import { getProductGroupsByRetail } from '../../../../../core/services/shelfMissions/shelfMissionsService';
import {
  getMonthTextInLocale,
  formatWithoutTimezone,
} from '../../../../../core/constants/dates';
import { CalendarPeriodTableRow } from '../../../common/types';
import { translateStoreAreaLabelToAPI } from '../../../Addressing/utils';
import { UserProfile } from '../../../../../core/types/user-profile.enum';
import { captalize } from '../../../../../core/strings';
import { useRetailList } from '../../../../../hooks/RetailHooks';
import * as dictionary from '../../../../../core/constants/dictionary';
import usePeriodCalendar from '../../../common/components/hooks/use-period-calendar';
import { useNegotiationPanelContext } from '../../context';
import { translatePromotionalSpotTypeToAPI } from '../../../common/utils';
import FormFilterCollapse from '../../../common/components/FormFilterCollapse';
import { FormRetailField } from '../../../../../components/Form/FormRetailField';
import {
  monthArray,
  storeAreas,
  FormFilters,
  parseYearOptionsFromPeriods,
  filterPeriodOptionsBySelected,
} from './utils';

type Props = {
  onClear: Function;
  onSubmit: Function;
  isLoading?: boolean;
};

const FormFilter = ({ onSubmit, onClear, isLoading = false }: Props) => {
  const { fetch: fetchPeriods, periods: periodOptions } = usePeriodCalendar();
  const {
    form: {
      values: { year, month, period, type, area, retail, spotName },
      isOpen,
      isSectorOptionsLoading,
      sectorOptions,
      selectedSector,
      setYear,
      setMonth,
      setPeriod,
      setType,
      setSpotName,
      setArea,
      setRetail,
      setIsOpen,
      setIsSectorOptionsLoading,
      setSectorOptions,
      setSelectedSector,
    },
  } = useNegotiationPanelContext();

  const mounted = useRef(false);
  const {
    retails,
    fetch: fetchRetails,
    isLoading: isLoadingRetails,
    retailInUser,
  } = useRetailList();

  const fetchProductGroupsByRetail = useCallback(async () => {
    try {
      if (!isSectorOptionsLoading) {
        setIsSectorOptionsLoading(true);
        const result = await getProductGroupsByRetail(
          retail?.id ?? retailInUser
        );
        setSectorOptions(result);
        setIsSectorOptionsLoading(false);
      }
    } catch (error) {
      setSectorOptions([]);
      setIsSectorOptionsLoading(false);
    }
  }, [isSectorOptionsLoading, retail, retailInUser]);

  const onHandleSubmit = useCallback(() => {
    onSubmit({
      year: year ? parseInt(year) : undefined,
      month: month ? month : undefined,
      periodIds: period.map((item: any) => item.id ?? ''),
      spotTypes: type.map(translatePromotionalSpotTypeToAPI),
      spotName: spotName ? spotName : undefined,
      sectorIds: selectedSector?.map((sector: any) => sector?.id),
      storeAreas: area.map(translateStoreAreaLabelToAPI),
      retailId: retail?.id ?? retailInUser,
    });
  }, [year, month, period, type, selectedSector, area, retail, retailInUser, spotName]);

  const onClearFilterHandler = useCallback(() => {
    setYear(null);
    setMonth(null);
    setPeriod([]);
    setType([]);
    setSpotName('');
    setArea([]);
    setSelectedSector([]);
    localStorage.setItem(
      'pexFilters',
      JSON.stringify({
        retailId: retail?.id ?? retailInUser,
      })
    );
    setTimeout(() => {
      if (onClear) {
        onClear();
      }
    }, 50);
  }, [retail, retailInUser]);

  useEffect(() => {
    const localStorageFilters = localStorage.getItem('pexFilters');
    if (localStorageFilters) {
      const pexFilters: FormFilters = JSON.parse(localStorageFilters);
      console.log('@pexFilters:', pexFilters);
      setYear(pexFilters.year);
      setMonth(parseInt(pexFilters.month));
      setIsOpen(pexFilters.isOpen);
      setArea(pexFilters.area ?? []);
      setType(pexFilters.type ?? []);
      setPeriod(pexFilters.period ?? []);
      setSpotName(pexFilters.spotName ?? '');
      setSelectedSector(pexFilters.sector ?? []);
      if (
        pexFilters.retail &&
        mainStore.loggedUser.profile === UserProfile.SUPER_ADMIN
      ) {
        setRetail(pexFilters.retail);
      }
    } else {
      setMonth(new Date().getMonth() + 1);
      setYear(new Date().getFullYear().toString());
    }
  }, []);

  useEffect(() => {
    fetchProductGroupsByRetail();
  }, []);

  useEffect(() => {
    if (!retailInUser && !isLoadingRetails) {
      fetchRetails();
    }
  }, [retailInUser, isLoadingRetails]);

  useEffect(() => {
    if (retail?.id || retailInUser) {
      fetchPeriods(retail?.id ?? retailInUser);
    }
  }, [retail, retailInUser, year, month]);

  useEffect(() => {
    if (mounted.current) {
      localStorage.setItem(
        'pexFilters',
        JSON.stringify({
          area,
          type,
          year,
          month,
          period,
          isOpen,
          spotName,
          sector: selectedSector,
          retailId: retail?.id ?? retailInUser,
          retail,
        })
      );
    } else {
      mounted.current = true;
    }
  }, [
    area,
    year,
    type,
    month,
    period,
    retail,
    spotName,
    retailInUser,
    selectedSector,
    isOpen,
  ]);

  return (
    <FormFilterCollapse
      buttons={
        <>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={onHandleSubmit}
            disabled={isLoading}
          >
            {dictionary.SEARCH}
          </Button>
          <Button
            disableElevation
            color="primary"
            onClick={onClearFilterHandler}
            disabled={isLoading}
          >
            {dictionary.CLEAR}
          </Button>
        </>
      }
    >
      {!retailInUser && (
        <Grid container spacing={2}>
          <Grid item sm={4} md={2}>
            <FormRetailField
              disabled={isLoading || isLoadingRetails}
              value={retail}
              key={retail?.id}
              options={retails ?? []}
              onChange={(retail) => {
                setRetail(retail);
                const localStorageFilters = localStorage.getItem('pexFilters');
                let currentForm = {};
                if (localStorageFilters) {
                  currentForm = JSON.parse(localStorageFilters);
                }
                localStorage.setItem(
                  'pexFilters',
                  JSON.stringify({
                    ...currentForm,
                    retailId: retail?.id ?? retailInUser,
                  })
                );
              }}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item sm={4} md={2}>
          <Autocomplete
            id="year"
            size="small"
            value={year}
            disabled={isLoading}
            options={parseYearOptionsFromPeriods(periodOptions)}
            getOptionLabel={(option: string) => option}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <MUITextField {...params} label={dictionary.YEAR} variant="outlined" />
            )}
            onChange={(_, value: string | null, reason: string) => {
              setYear(value);
            }}
            style={{}}
          />
        </Grid>
        <Grid item sm={4} md={2}>
          <Autocomplete
            id="month"
            disabled={isLoading}
            size="small"
            value={month}
            options={monthArray}
            getOptionLabel={(option: number) =>
              captalize(getMonthTextInLocale(new Date(2022, option - 1, 10)))
            }
            renderInput={(params: AutocompleteRenderInputParams) => (
              <MUITextField
                {...params}
                label={dictionary.MONTH}
                variant="outlined"
              />
            )}
            onChange={(_, value: number | null) => {
              setMonth(value);
            }}
            style={{}}
          />
        </Grid>
        <Grid item sm={4} md={2}>
          <Autocomplete
            multiple
            disabled={isLoading || !year || !month}
            id="period"
            size="small"
            value={period}
            options={filterPeriodOptionsBySelected({
              year,
              month,
              periods: periodOptions,
            })}
            getOptionLabel={(period: CalendarPeriodTableRow) =>
              formatWithoutTimezone(new Date(period?.startAt as Date))
            }
            renderInput={(params: AutocompleteRenderInputParams) => (
              <MUITextField
                {...params}
                label={dictionary.PERIOD}
                variant="outlined"
              />
            )}
            onChange={(_, value: CalendarPeriodTableRow[]) => {
              setPeriod(value);
            }}
            style={{}}
          />
        </Grid>
        <Grid item sm={6} md={3}>
          <Autocomplete
            multiple
            disabled={isLoading}
            id="type"
            size="small"
            value={type}
            options={globalTypeOptions}
            getOptionLabel={(option: string) => option}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <MUITextField
                {...params}
                label={dictionary.PROMOTIONAL_SPOT_TYPE}
                variant="outlined"
              />
            )}
            onChange={(_, value: string[]) => setType(value)}
            style={{}}
          />
        </Grid>
        <Grid item sm={6} md={3}>
          <TextField
            id="promotionalSpotName"
            required={false}
            value={spotName}
            label={dictionary.PROMOTIONAL_SPOT_NAME}
            onChange={(_e: any) => setSpotName(_e.target.value)}
            style={{ marginTop: 0, marginBottom: 0, width: '100%'}}
            //className={classes.textfield}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={6} md={2}>
          <Autocomplete
            multiple
            disabled={isLoading}
            id="sector"
            size="small"
            value={selectedSector}
            options={sectorOptions}
            getOptionLabel={(option: ProductGroup) => option.name}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <MUITextField
                {...params}
                label={dictionary.SECTOR}
                variant="outlined"
                style={{ width: '100% '}}
              />
            )}
            onChange={(_, value: ProductGroup[]) => setSelectedSector(value)}
            style={{}}
          />
        </Grid>
        <Grid item sm={6} md={2}>
          <Autocomplete
            multiple
            disabled={isLoading}
            id="area"
            size="small"
            value={area}
            options={storeAreas}
            getOptionLabel={(option: string) => option}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <MUITextField
                {...params}
                label={dictionary.STORE_AREA}
                variant="outlined"
                style={{ width: '100% '}}
              />
            )}
            onChange={(_, value: string[]) => setArea(value)}
            style={{}}
          />
        </Grid>
      </Grid>
    </FormFilterCollapse>
  );
};

export default FormFilter;
