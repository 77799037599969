import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { IconButton as IconButtonMUI } from '@material-ui/core';
import { colors } from '../../../Theme/colors';

const CssButton = withStyles({
  root: {
    '&:hover': { backgroundColor: 'transparent', color: colors.black_500 },
  },
})(IconButtonMUI);

const useStyles = makeStyles({
  defaultStyle: {
    color: colors.black_400,
  },
});

const IconButton = ({ disabled, style, onClick, children }) => {
  const classes = useStyles();

  return (
    <CssButton
      className={classes.defaultStyle}
      style={style}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </CssButton>
  );
};

export default IconButton;
