import DangerStore from './DangerStore';
import CheckinDone from './CheckInDone';
import WarningStore from './WarningStore';

const Vector = {
  DangerStore,
  CheckinDone,
  WarningStore,
} 

export default Vector;