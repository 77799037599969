import React, { useState } from 'react';
import { Link, Typography } from '@material-ui/core';
import ModalTitle from '../../../components/Modal/Title';
import TextField from '../../../components/InputTextField';
import DefaultButton from '../../../components/Buttons/Default';
import { handleSubmit, isValidForm } from '../utils';
import { LOGIN as LOGIN_ROUTE } from '../../../core/constants/routes';
import * as dictionary from '../../../core/constants/dictionary';
import classes from '../styles.module.scss';
import rootClasses from '../../../containers/InitialPage/styles.module.scss';

const ResetPasswordForm = ({ history, finishedHandler }) => {
    const [email, setEmail] = useState('');

    const onClickHandler = async (event) => {
        await handleSubmit(event, email, history);
        finishedHandler(true);
    }

    return (
        <div className={classes.formContainer}>
            <div className={classes.formTitle}>
                <ModalTitle title={dictionary.FORGOT_MY_PASSWORD} />
            </div>

            <div className={rootClasses.input}>
                <Typography variant="body1">{dictionary.EMAIL}</Typography>

                <TextField
                    value={email}
                    name={dictionary.EMAIL_NAME}
                    placeholder={dictionary.TYPE_EMAIL}
                    style={{ width: '100%' }}
                    onChange={e => setEmail(e.target.value)}
                />
            </div>

            <div className={rootClasses.submit}>
                <DefaultButton
                    label={dictionary.SEND}
                    disabled={!isValidForm(email)}
                    onClick={e => onClickHandler(e)}
                />
            </div>

            <div className={rootClasses.changePage}>
                <Typography variant="body2">
                    <Link
                        className={rootClasses.clickable}
                        onClick={() => history.push(LOGIN_ROUTE)}
                    >
                        {dictionary.LOGIN_INSTEAD}
                    </Link>
                </Typography>
            </div>
        </div>
    );
}

export default ResetPasswordForm;
