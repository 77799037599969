import { AxiosError } from 'axios';
import { CalendarPeriodError } from '../../../../../core/types/promotional-spot/errors/promotional-calendar-period-error.enum';
import { CalendarPeriodTableRow } from '../../types';
import { 
  DUPLICATED_PERIOD_IN_CALENDAR_ERROR,
  PROMOTIONAL_CALENDAR_NOT_EXIST_ERROR,
  INCONSISTENT_SEQUENCE_OF_PERIODS_ERROR,
  PROMOTIONAL_CALENDAR_DUPLICATED_NAME_ERROR,
  NOT_VALID_PROMOTIONAL_CALENDAR_STATUS_ERROR,
  PROMOTIONAL_CALENDAR_PERIOD_NOT_EXIST_ERROR,
} from '../../../../../core/constants/dictionary';

export const sortPeriodsByStartAtAsc = (
  a: CalendarPeriodTableRow,
  b: CalendarPeriodTableRow
) => {
  if (a.startAt && b.startAt) {
    return new Date(a.startAt) > new Date(b.startAt) ? 1 : -1;
  }
  return 0;
};

export const getFriendlyFeedbackError = (catchError: AxiosError): string => {
  const { error } = catchError.response?.data;
  if (!error) {
    return '';
  }

  switch (error) {
    case CalendarPeriodError.DUPLICATED_PERIOD_IN_TRANSACTION: 
    case CalendarPeriodError.DUPLICATED_PERIOD_IN_CALENDAR: 
      return DUPLICATED_PERIOD_IN_CALENDAR_ERROR;

    case CalendarPeriodError.INCONSISTENT_SEQUENCE_OF_PERIODS: 
      return INCONSISTENT_SEQUENCE_OF_PERIODS_ERROR;

    case CalendarPeriodError.NOT_VALID_PROMOTIONAL_CALENDAR_STATUS: 
      return NOT_VALID_PROMOTIONAL_CALENDAR_STATUS_ERROR;

    case CalendarPeriodError.PROMOTIONAL_CALENDAR_DUPLICATED_NAME: 
      return PROMOTIONAL_CALENDAR_DUPLICATED_NAME_ERROR;

    case CalendarPeriodError.PROMOTIONAL_CALENDAR_NOT_EXIST: 
      return PROMOTIONAL_CALENDAR_NOT_EXIST_ERROR;

    case CalendarPeriodError.PROMOTIONAL_CALENDAR_PERIOD_NOT_EXIST: 
      return PROMOTIONAL_CALENDAR_PERIOD_NOT_EXIST_ERROR;

    default: return '';
  }
}
