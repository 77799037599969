import React from 'react';
import imageSource from '../../assets/general/checkInDone.png';
import { ACCESS_CLEARED } from '../../core/constants/dictionary';

type Props = {
  className?: string;
}

const CheckInDone = ({ className, ...restProps }: Props) => (
  <img 
    src={imageSource}
    alt={ACCESS_CLEARED}
    className={className}
    {...restProps}
  />
)

export default CheckInDone;
