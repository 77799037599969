import React, { useCallback, useState } from 'react';
import { getAllPromotionalCalendarPeriods } from '../../../../../core/services/promotionalSpots/promotionalCalendarPeriodsService';
import { CalendarPeriodTableRow } from '../../types';

const usePeriodCalendar = () => {
  const [periodOptions, setPeriodOptions] = useState<CalendarPeriodTableRow[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchPeriods = useCallback(
    async (retailId: string) => {
      try {
        if (!isLoading && retailId) {
          setIsLoading(true);
          const { data } = await getAllPromotionalCalendarPeriods({
            retailId,
          });
          setPeriodOptions(data);
        }
      } catch (error) {
        setPeriodOptions([]);
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading]
  );
  return {
    periods: periodOptions,
    fetch: fetchPeriods,
    isLoading,
  };
};

export default usePeriodCalendar;
