import React from 'react';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import { StoreDto } from '../../../../../Addressing/types';
import { PromotionalNegotiationDto } from '../../../../../common/types';
import { PromotionalSpotAvaliableAddresingInStore } from '../../../../types/promotional-negotiation-panel.entity';
import * as dictionary from '../../../../../../../core/constants/dictionary';
import AvaliableRowItem from './AvaliableRowItem';
import {
  totalExecutions,
  totalExecutionsInStore,
  totalAvaliableAddressings,
  totalAvaliableAddressingsInStore,
} from './utils';
import classes from '../../NegotiationTable.module.scss';

type Props = {
  metrics?: PromotionalSpotAvaliableAddresingInStore[];
  stores?: StoreDto[];
  negotiations?: PromotionalNegotiationDto[];
};

const AvailableRow = ({ 
  metrics, 
  negotiations, 
  stores = [], 
}: Props) => {
  return (
    <TableRow>
      <TableCell className={classes.sticky}>
        <div className={classes.shadow}>
          <TableCell className={classes.footerResume}>
            <Typography variant="overline" align="right">
              {dictionary.AVAILABILITY_ON_STORE}
            </Typography>
          </TableCell>
        </div>
      </TableCell>

      <TableCell align='center'>
        {totalAvaliableAddressings(metrics) - totalExecutions(negotiations)}
      </TableCell>

      {stores?.map((store) => (
        <AvaliableRowItem
          key={store.id}
          storeName={`${store.name} (${store.storeCode})`}
          total={totalAvaliableAddressingsInStore(
            store,
            totalExecutionsInStore({store, negotiations}),
            metrics,
          )}
        />
      ))}
    </TableRow>
  );
};

export default AvailableRow;
