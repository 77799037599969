import React from 'react';
import involvesDoorsLogoSrc from '../../assets/logos/full-logo-dark.svg';
import { INVOLVES_DOORS_LOGO } from '../../core/constants/dictionary';
import classes from './styles.module.scss';

export default (props) => {
    let classNames = [classes.container];
    if(props.className) {
        classNames.push(props.className);
    }
    
    return (
        <div className={ classNames.join(' ')}>
            <img 
                src={involvesDoorsLogoSrc} 
                alt={INVOLVES_DOORS_LOGO} 
                style={{
                    ...props.style,
                    width: props.width,
                }}
            />
        </div>
    );
}