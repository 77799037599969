import JsBarcode from 'jsbarcode';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';
import * as R from 'ramda';
import { mainStore } from '../../core/store/mainStore';
import { exportShelfBreaksXls } from '../../core/services/shelfMissions/shelfMissionsService';
import {
  trackExportRestockReport,
  trackPrintRestockReport,
} from '../../core/mixpanel/mixPanelEvents';
import { utils } from '../../core/utils';
import { profileCheck } from '../../core/profiles';
import { normalizeStockValue } from '../../core/shared/stock';
import { exist, findValueInArray } from '../../core/validation';
import { getStringValueWith2Digits } from '../../core/constants/dates';
import {
  NO_STOCK,
  PENDING,
  SHELF_RESTOCKED,
} from '../../core/constants/restock';
import Restore from '../../assets/tables/restore.svg';
import RedEllipse from '../../assets/tables/redEllipse.svg';
import GreenEllipse from '../../assets/tables/greenEllipse.svg';
import blackEllipse from '../../assets/general/black-ellipse.png';
import blankEllipse from '../../assets/general/blank-ellipse.png';
import YellowEllipse from '../../assets/tables/yellowEllipse.svg';
import blackRectangle from '../../assets/general/black-rectangle.png';
import blankRectangle from '../../assets/general/blank-rectangle.png';
import * as dictionary from '../../core/constants/dictionary';

export const defaultFilter = {
  retail: null,
  store: null,
  group: null,
  subgroup: null,
  startDate: new Date(),
  endDate: new Date(),
  status: [],
  shelfStatus: [],
};

export const isAdminProfile = () => {
  return profileCheck.isBuyer() || profileCheck.isRetailAdmin();
};

export const isRetailAssistantProfile = () => {
  return profileCheck.isRestocker() || profileCheck.isStoreManager();
};

export const textToBase64Barcode = text => {
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, text, { format: 'EAN13' });
  return canvas.toDataURL('image/png');
};

export const getPDFData = (filter, data, selectedDate) => {
  const groupSubgroupSort = R.sortWith([
    R.ascend(R.prop('productGroupName')),
    R.ascend(R.prop('productSubgroupName')),
    R.ascend(R.prop('productName')),
  ]);

  const items = groupSubgroupSort(data);

  const orderedFilter = items.reduce((acc, obj) => {
    const key = `${obj.productGroupName} - ${obj.productSubgroupName}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    let stockUpdatedAtDate = "-";
    if(obj.stockUpdatedAt) {
      stockUpdatedAtDate = format(new Date(obj.stockUpdatedAt), "dd/MM/yyyy 'às' HH:mm");
    }
    
    acc[key].push({
      productInternalCode: obj.productInternalCode,
      productName: obj.productName,
      productEan: obj.productEan,
      status: obj.status,
      virtualStock: obj.virtualStock,
      totalProductUnitsNeeded: obj.totalProductUnitsNeeded || 0,
      totalStock: normalizeStockValue(obj.totalStock),
      stockUpdatedAt: stockUpdatedAtDate,
    });

    return acc;
  }, {});

  const monthValue = selectedDate.getUTCMonth() + 1;
  const monthStringValue = getStringValueWith2Digits(monthValue);
  const dateStringValue = getStringValueWith2Digits(selectedDate.getUTCDate());
  const hourStringValue = getStringValueWith2Digits(selectedDate.getHours());
  const minutesStringValue = getStringValueWith2Digits(
    selectedDate.getMinutes(),
  );
  const docTitle = `${
    filter.store.name
  } - ${dateStringValue}/${monthStringValue}/${selectedDate.getFullYear()} - gerado às ${hourStringValue}:${minutesStringValue}`;

  const fileName = `Relatório de Rupturas - ${
    filter.store.name
  } - ${selectedDate.getUTCDate()}-${
    selectedDate.getUTCMonth() + 1
  }-${selectedDate.getFullYear()}.pdf`;

  const reportData = [];

  Object.keys(orderedFilter).forEach(key => {
    orderedFilter[key].forEach(shelfBreak => {
      const barcode =
        shelfBreak.productEan.length === 13
          ? textToBase64Barcode(shelfBreak.productEan)
          : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKEAAABNCAYAAADO1wRPAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGkSURBVHgB7dJRjcJAFIbRZhXgACQgAQlIQAIScAAOwAESkIAEJCBhNrfJJN1h92Gf/pdzkibNdGCm02+apqmdz+f2fr/bZrNpt9utlT7e3e/3eWx5PR6P9nq95vt63l0ul4+5Na87HA5tu93O98fj8WPu6Lc1TqfTvN+l9Xo9/99ov9+33W7X/lLPak+l9tXva7x+O85Zjvf9rVarH+9Q++t76mPP5/PjvX478/4txnMZz7yumlfza71x7VLz+3g/8+XayzO/Xq/ze9Szuu/P+nsv9fPva4/f4j++JggTIXEiJE6ExImQOBESJ0LiREicCIkTIXEiJE6ExImQOBESJ0LiREicCIkTIXEiJE6ExImQOBESJ0LiREicCIkTIXEiJE6ExImQOBESJ0LiREicCIkTIXEiJE6ExImQOBESJ0LiREicCIkTIXEiJE6ExImQOBESJ0LiREicCIkTIXEiJE6ExImQOBESJ0LiREicCIkTIXEiJE6ExImQOBESJ0LiREicCIkTIXEiJE6ExImQOBESJ0LiREicCIkTIXEiJE6ExH0DCNKygqqI644AAAAASUVORK5CYII=';

      const reportStatusShelfAnswer =
        shelfBreak.status === 0 || shelfBreak.status === 2
          ? blankRectangle
          : blackRectangle;
      const reportStatusOutOfStockAnswer =
        shelfBreak.status === 2 ? blackRectangle : blankRectangle;
      const reportStatusIsPresentAnswer =
        shelfBreak.status === 2 && shelfBreak.virtualStock
          ? blackEllipse
          : blankEllipse;
      const reportStatusIsNotPresentAnswer =
        shelfBreak.status === 2 && !shelfBreak.virtualStock
          ? blackEllipse
          : blankEllipse;

      reportData.push({
        ...shelfBreak,
        barcode,
        group: key,
        reportStatusShelfAnswer,
        reportStatusOutOfStockAnswer,
        reportStatusIsPresentAnswer,
        reportStatusIsNotPresentAnswer,
      });
    });
  });

  return {
    fileName,
    docTitle,
    reportData,
  };
};

export const getUserFilterDTO = () => {
  const retailParam = mainStore.loggedUser.retailId;
  return {
    retailId: retailParam,
    startDate: new Date(),
    endDate: new Date(),
  };
};

export const loadFirstFilterDTO = () => {
  let filterDTO = {};
  if (isAdminProfile()) {
    filterDTO = getUserFilterDTO();
    filterDTO.status = [0, 1, 2];
    filterDTO.virtualStock = [false, true];
  } else if (isRetailAssistantProfile()) {
    filterDTO = getUserFilterDTO();
    const storeParam = mainStore.loggedUser.storeId;
    filterDTO.storeId = exist(storeParam) ? storeParam : '';
    filterDTO.status = [0];
  }
  filterDTO.shelfStatus = [0, 1];
  return filterDTO;
};

export const reportStatusEnum = [
  dictionary.AWAITING_REPLACEMENT,
  dictionary.STOCKED,
  dictionary.OUT_OF_STOCK,
  dictionary.VIRTUAL_STOCK,
];

export const shelfStatusEnum = [
  dictionary.LOW_STOCKED,
  dictionary.IN_SHELF_BREAK,
];

const removeDuplicate = array => {
  return array.filter((item, pos) => array.indexOf(item) === pos);
};

export const getNumberValueOfStatus = statusValues => {
  const statusList = [];
  const virtualStock = [];
  statusValues.forEach(status => {
    if (status === dictionary.OUT_OF_STOCK) {
      statusList.push(2);
      virtualStock.push(false);
    } else if (status === dictionary.VIRTUAL_STOCK) {
      statusList.push(2);
      virtualStock.push(true);
    } else {
      statusList.push(reportStatusEnum.indexOf(status));
    }
  });
  return {
    status: removeDuplicate(statusList),
    virtualStock,
  };
};

export const getNumberValueOfShelfStatus = shelfStatusValues => {
  const shelfStatusList = [];
  shelfStatusValues.forEach(shelfStatus => {
    if (shelfStatus === dictionary.LOW_STOCKED) {
      shelfStatusList.push(0);
    } else if (shelfStatus === dictionary.IN_SHELF_BREAK) {
      shelfStatusList.push(1);
    }
  });
  return removeDuplicate(shelfStatusList);
};

export const getExportXlsMixpanelDTO = (filter, pdfData) => {
  let retailValue = filter.retail;
  if (!filter.retail) {
    retailValue = findValueInArray(
      mainStore.shelfBreaksReports.retails,
      'id',
      mainStore.loggedUser.retailId,
    );
  }
  return {
    reportData: pdfData ? pdfData.reportData : [],
    group: filter.group,
    subgroup: filter.subgroup,
    date: filter.date,
    retail: retailValue,
  };
};

export const getPrintPdfMixpanelDTO = (filter, pdfData) => {
  let retailValue = filter.retail;
  if (!filter.retail) {
    retailValue = findValueInArray(
      mainStore.shelfBreaksReports.retails,
      'id',
      mainStore.loggedUser.retailId,
    );
  }
  return {
    reportData: pdfData ? pdfData.reportData : [],
    group: filter.group,
    subgroup: filter.subgroup,
    store: filter.store,
    date: filter.date,
    retail: retailValue,
  };
};

export const exportXls = async (pdfData, filter, mixPanelFilter) => {
  const statusDTO = getNumberValueOfStatus(filter.status);
  const importFilter = {
    status: statusDTO.status,
    virtualStock: statusDTO.virtualStock,
  };

  if (exist(filter.retail)) {
    importFilter.retailId = filter.retail.id;
  } else if (isRetailAssistantProfile()) {
    importFilter.retailId = mainStore.loggedUser.retailId;
  }

  if (exist(filter.store)) {
    importFilter.storeId = filter.store.id;
  } else if (isRetailAssistantProfile()) {
    importFilter.storeId = mainStore.loggedUser.storeId;
  }

  if (exist(filter.group)) {
    importFilter.groupId = filter.group.id;
  }

  if (exist(filter.subgroup)) {
    importFilter.subgroupId = filter.subgroup.id;
  }

  if (exist(filter.startDate)) {
    importFilter.startDate = filter.startDate;
  }
  if (exist(filter.endDate)) {
    importFilter.endDate = filter.endDate;
  }
  if (exist(filter.shelfStatus)) {
    importFilter.shelfStatus = getNumberValueOfShelfStatus(filter.shelfStatus);
  }

  const mixpanelDTO = getExportXlsMixpanelDTO(
    mixPanelFilter.filter,
    mixPanelFilter.pdfData,
  );

  try {
    const res = await exportShelfBreaksXls(importFilter);
    trackExportRestockReport(mixpanelDTO);
    const blob = new Blob([res.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    saveAs(
      blob,
      `Rupturas-${day}-${month}-${date.getFullYear()}.xlsx`,
    );
  } catch (e) {
    utils.openSnackBar(dictionary.ERROR, dictionary.UNKNOWN_ERROR);
  }
};

export const printReport = async mixPanelFilter => {
  const mixpanelDTO = getPrintPdfMixpanelDTO(
    mixPanelFilter.filter,
    mixPanelFilter.pdfData,
  );

  try {
    trackPrintRestockReport(mixpanelDTO);
  } catch (e) {
    utils.openSnackBar(dictionary.ERROR, dictionary.UNKNOWN_ERROR);
  }
};

export const getUpdateDateValue = () => {
  const date = new Date();
  return `Última atualização em ${getStringValueWith2Digits(
    date.getDate(),
  )}/${getStringValueWith2Digits(
    date.getMonth() + 1,
  )}/${date.getFullYear()} às ${getStringValueWith2Digits(
    date.getHours(),
  )}:${getStringValueWith2Digits(date.getMinutes())}`;
};

export const inputStyle = {
  width: '100%',
  margin: 0,
};

export const getRestockIconStatus = (status, virtualStock) => {
  if (status === 2 && virtualStock) {
    return RedEllipse;
  }
  const restockStatus = [Restore, GreenEllipse, YellowEllipse, RedEllipse];
  return restockStatus[status];
};

export const getStatusValue = (status, virtualStock) => {
  if (status === PENDING) {
    return dictionary.AWAITING_REPLACEMENT;
  }

  if (status === SHELF_RESTOCKED) {
    return dictionary.STOCKED;
  }

  if (status === NO_STOCK && virtualStock) {
    return dictionary.VIRTUAL_STOCK;
  }

  if (status === NO_STOCK && !virtualStock) {
    return dictionary.OUT_OF_STOCK;
  }
  return '';
};
