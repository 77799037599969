import React, { useMemo, useCallback, useState } from 'react';
import { view } from 'react-easy-state';
import { Typography } from '@material-ui/core';
import { StyledStickyFooter } from '../../components/stickyFooter/stickyFooter';
import Loading from '../../components/Loading';
import ModalContainer from '../../components/Modal/Container';
import DefaultButton from '../../components/Buttons/Default';
import WelcomePage from '../../components/WelcomePage';
import PageContent from '../../components/PageContent';
import { SnackBars } from '../../containers';
import { CheckIn as CheckInModals } from './modals/checkIn/checkIn';
import { CheckOut } from './modals/checkout/checkout';
import { EvaluationModal } from './modals/evaluation';
import VisitsOfDayTable from './VisitsOfDayTable';
import visitsOfDayStore from './visitsOfDayStore';
import { mainStore } from '../../core/store/mainStore';
import * as dictionary from '../../core/constants/dictionary';
import { Visit } from './utils';

const VisitsOfDay = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState<string | null>(dictionary.CHECK_IN as string);
  const [currentVisit, setCurrentVisit] = useState<Visit | null>(null);
  const [evaluationOpen, setEvaluationOpen] = useState(false);
  
  const closeModal = useCallback(() => {
    setOpenModal(false);
    setModalType(null);
    setCurrentVisit(null);
  }, []);

  const reloadCallback = useCallback(async () => {
    visitsOfDayStore.refreshTable();
    closeModal();
  }, [closeModal]);

  const checkinModals = useMemo(() => {
    return (
      <CheckInModals
        isOpen={openModal}
        currentVisit={currentVisit}
        reloadCallback={reloadCallback}
        closeModalCallback={closeModal}
      />
    );
  }, [
    openModal, 
    currentVisit,
    closeModal,
    reloadCallback
  ]);

  const checkOutModal = useMemo(() => {
    return (
      <ModalContainer closeModalCallback={closeModal} isOpen={openModal}>
        <CheckOut
          closeModalCallback={closeModal}
          currentVisit={currentVisit}
          reloadCallback={reloadCallback}
        />
      </ModalContainer>
    );
  }, [closeModal, currentVisit, openModal, reloadCallback]);

  const renderModal = useMemo(() => {
    const modals = {
      [dictionary.CHECK_IN as string]: checkinModals,
      [dictionary.CHECK_OUT as string]: checkOutModal,
    };

    return modals[String(modalType)];
  }, [
    modalType,
    checkinModals, 
    checkOutModal,
  ]);

  const openModalHandler = useCallback((modalType, currentVisit = undefined) => {
    setOpenModal(true);
    setModalType(modalType);
    setCurrentVisit(currentVisit);
  }, []);

  const handleOpenEvaluation = useCallback(currentVisit => {
    setEvaluationOpen(true);
    setCurrentVisit(currentVisit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseEvaluation = () => {
    setEvaluationOpen(false);
    reloadCallback();
  };

  return (
    <React.Fragment>
      <PageContent title={dictionary.DAY_VISITS}>
        {
          visitsOfDayStore.total === 0 && !mainStore.requestLoading && (
            <WelcomePage>
              <Typography variant="body1">
                {dictionary.NO_VISITS_OF_DAY}
              </Typography>
            </WelcomePage>
          )
        }
        <div
          className="reverse-table"
          style={{ visibility: visitsOfDayStore.total > 0 ? 'visible' : 'hidden'}}
        >
          <VisitsOfDayTable
            handleOpenModal={openModalHandler}
            handleOpenEvaluation={handleOpenEvaluation}
          />
        </div>
      </PageContent>

      <StyledStickyFooter>
        <div className="button-create-container">
          <DefaultButton
            label={dictionary.DO_PROMOTER_CHECK_IN}
            style={{ width: 'auto' }}
            onClick={() => openModalHandler(dictionary.CHECK_IN)}
          />
        </div>
      </StyledStickyFooter>

      {openModal && renderModal}

      {evaluationOpen && (
        <EvaluationModal
          open={evaluationOpen}
          onClose={handleCloseEvaluation}
          visit={currentVisit as Visit}
        />
      )}

      <SnackBars />

      <Loading isOpen={mainStore.requestLoading} />
    </React.Fragment>
  );
};


export default view(VisitsOfDay);
