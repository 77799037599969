import React from 'react';
import WelcomePage from '../../../../components/WelcomePage';
import * as dictionary from '../../../../core/constants/dictionary';

type Props = {
  onClickButtonHandler: Function;
}

const CreatePromotionalCalendarPage = ({ onClickButtonHandler }: Props) => {
  return (
    <WelcomePage
      textButton={dictionary.GO_TO_PROMOTIONAL_CALENDAR}
      handleButton={onClickButtonHandler}
    >
      <span>
        {dictionary.MUST_CREATE_PROMOTIONAL_CALENDAR_PART1}
        <strong>{dictionary.CALENDAR}</strong>
        {dictionary.MUST_CREATE_PROMOTIONAL_CALENDAR_PART2}
      </span>
    </WelcomePage>
  )
};

export default CreatePromotionalCalendarPage;
