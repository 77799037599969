import React from 'react';
import { Typography } from '@material-ui/core';
import DangerModal from '../../../../containers/DangerModal';
import { MAX_MODAL_WIDTH } from '../utils';
import * as dictionary from '../../../../core/constants/dictionary';
import classes from '../AutoCheckinModal.module.scss';

type Props = {
	data: {
		promoterName: string;
	}
	isOpen: boolean,
	onContinueHandler: Function;
	onCloseModalHandler: Function,
};

const AutoCheckinSuspensionModal = ({
	data,
	isOpen,
	onContinueHandler,
	onCloseModalHandler,
}: Props) => (
	<DangerModal
		isOpen={isOpen}
		width={MAX_MODAL_WIDTH}
		onContinueHandler={onContinueHandler}
		onCloseModalHandler={onCloseModalHandler}
		notificationText={dictionary.ASK_STORE_MANAGER_TO_UNLOCK}
	>
		<div className={classes.body}>
			<Typography align="center" className={classes.title}>
				{`${dictionary.HI}, ${data.promoterName}!`}
			</Typography>

			<Typography align="center" className={[classes.bodyText, classes.semiBold].join(' ')}>
				{ dictionary.SUSPENDED_ACCESS }
			</Typography>
			<Typography align="center" className={classes.bodyText}>
				{`${dictionary.YOU} ${dictionary.EXCEEDED_AMOUNT_FORGETFULNESS}`}
			</Typography>
		</div>
	</DangerModal>
);

export default AutoCheckinSuspensionModal;
