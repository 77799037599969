import React, { ReactNode } from 'react';
import { Dialog } from '@material-ui/core';

type DialogClassesMapObject = object;

type Props = {
  isOpen: boolean,
  children: ReactNode,
  closeModalCallback: Function;
  classes?: DialogClassesMapObject;
  width?: number;
}

const ModalSmallContainer = ({ 
  isOpen, 
  children, 
  classes, 
  closeModalCallback, 
}: Props) => (
  <Dialog
    open={isOpen}
    classes={classes}
    PaperProps={{ 
      style: { 
        padding: '20px', 
        overflow: 'hidden' 
      }, 
    }}
    onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        closeModalCallback(event, reason);
      }
    }}
  >
    {children}
  </Dialog>
);

export default ModalSmallContainer;
