import React from 'react';
import ReactDOM from 'react-dom';
import { Route, HashRouter } from 'react-router-dom';
import App from './App';
import { unregister } from './serviceWorker';

const mixpanel = require('mixpanel-browser');

mixpanel.init('d6c40d5f8c5e4c616fc9d79618db9916');

ReactDOM.render(
  <HashRouter>
    <Route component={App} />
  </HashRouter>,
  document.getElementById('root'),
);
unregister();
