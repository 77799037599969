import React from 'react';
import { Typography } from '@material-ui/core';
import SuccessModal from '../../../../containers/SuccessModal';
import { MAX_MODAL_WIDTH } from '../../../VisitsAutoCheckin/components/utils';
import * as dictionary from '../../../../core/constants/dictionary';
import classes from '../PromoterModal.module.scss';

type ModalData = {
  promoterName?: string;
}

type Props = {
  data: ModalData;
  isOpen: boolean;
  onReloadHandler?: Function;
  onCloseModalHandler: Function;
}

const PromoterSuccessModal = ({ 
  data, 
  isOpen, 
  onReloadHandler, 
  onCloseModalHandler, 
}: Props) => {
  return (
    <SuccessModal
      isOpen={isOpen}
      width={MAX_MODAL_WIDTH}
      notificationText={dictionary.SECURITY_REMEMBER_CHECKOUT_THE_PROMOTER}
      onCloseModalHandler={() => {
        onReloadHandler && onReloadHandler();
        onCloseModalHandler();
      }}
    >
      <div className={classes.body}>
        <Typography variant="h1" className={classes.title}>
          {dictionary.ACCESS_RELEASED}
        </Typography>

        <Typography variant="body1" className={classes.semiBold}>
          {data.promoterName}
        </Typography>
        <Typography variant="body1">
          {dictionary.IS_FREE_TO_START}
        </Typography>
        <Typography variant="body1">
          {dictionary.WORK_AT_YOUR_STORE}
        </Typography>
      </div>
    </SuccessModal>
  );
}

export default PromoterSuccessModal;
