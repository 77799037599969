import { PROMOTER_TERMS_OF_USE } from '../../core/constants/routes';

export const CONTACT = {
    PHONE: "+55 48 3733 0500",
    MAIL_ADDRESS: "leonardo@involves.com",
    MAIL_CONTACT: "Leonardo Coelho",
};

export const PROMOTER_TERMS_OF_USE_URL = `/#${PROMOTER_TERMS_OF_USE}`;

export function getTelAnchor() {
    const normalizedPhone = CONTACT.PHONE.replace(/[^0-9]+/g, '');
    return `tel:${normalizedPhone}`;
}

export function getMailAnchor() {
    return `mailto:${CONTACT.MAIL_ADDRESS}`;
}
