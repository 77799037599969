import React from 'react';
import SelectMUI from '@material-ui/core/Select';
import { InputLabel, OutlinedInput, FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as dictionary from '../../core/constants/dictionary';
import { colors } from '../../Theme/colors';

const CssOutlinedInput = withStyles({
  root: {
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.blue_500,
    },
  },
})(OutlinedInput);

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

const Tag = ({
  label,
  style,
  value,
  onChange,
  disabled,
  multiple,
  children,
}) => {
  const labelId = `select-mui-${label ? label.toLowerCase() : ""}`;

  return (
    <FormControl variant="outlined" style={{ margin: 1 }}>
      <InputLabel htmlFor={labelId}>{label}</InputLabel>
      <SelectMUI
        value={value}
        label={label}
        disabled={disabled}
        multiple={multiple}
        MenuProps={MenuProps}
        renderValue={selected => selected.join(', ')}
        input={<CssOutlinedInput id={labelId} label={label} />}
        onChange={event => onChange(event)}
        style={style}
      >
        {children}
      </SelectMUI>
    </FormControl>
  );
};

Tag.defaultProps = {
  required: true,
  error: false,
  disabled: false,
  multiple: true,
  loadingText: dictionary.LOADING,
};

export default Tag;
