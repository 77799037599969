import React from 'react';
import { mainStore } from './store/mainStore';
import SnackBar from '../components/Snackbars';
import LinkSnackBar from '../components/LinkSnackBar';

const invertValue = value => (value === 0 ? 1 : 0);

const openLinkSnackBar = (type, message, messageAction, action) => {
  mainStore.linkSnackBars.typeSnackbar = type;
  mainStore.linkSnackBars.openSnackBar = true;
  mainStore.linkSnackBars.messageSnackBar = message;
  mainStore.linkSnackBars.action = action;
  mainStore.linkSnackBars.messageAction = messageAction;
};

const closeLinkSnackBar = () => {
  mainStore.linkSnackBars.typeSnackbar = null;
  mainStore.linkSnackBars.openSnackBar = false;
  mainStore.linkSnackBars.messageSnackBar = null;
  mainStore.linkSnackBars.action = null;
  mainStore.linkSnackBars.messageAction = null;
};

const getLinkSnackbar = () => {
  if (!mainStore.linkSnackBars.openSnackBar) {
    return '';
  }
  return (
    <LinkSnackBar
      openSnackBar={mainStore.linkSnackBars.openSnackBar}
      messageSnackBar={mainStore.linkSnackBars.messageSnackBar}
      typeSnackbar={mainStore.linkSnackBars.typeSnackbar}
      actionSnackbar={mainStore.linkSnackBars.action}
      messageActionSnackbar={mainStore.linkSnackBars.messageAction}
    />
  );
};

const openSnackBar = (type, message) => {
  mainStore.snackBars.typeSnackbar = type;
  mainStore.snackBars.openSnackBar = true;
  mainStore.snackBars.messageSnackBar = message;
};

const closeSnackBar = () => {
  mainStore.snackBars.typeSnackbar = null;
  mainStore.snackBars.openSnackBar = false;
  mainStore.snackBars.messageSnackBar = null;
};

const getSnackbar = () => {
  if (!mainStore.snackBars.openSnackBar) {
    return '';
  }
  return (
    <SnackBar
      openSnackBar={mainStore.snackBars.openSnackBar}
      messageSnackBar={mainStore.snackBars.messageSnackBar}
      typeSnackbar={mainStore.snackBars.typeSnackbar}
    />
  );
};

const capitalizeFirstLetter = value => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

export const offsetElementPosition = element => {
  const rect = element.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft,
    width: rect.width,
    height: rect.height,
    right: rect.right + scrollLeft,
    bottom: rect.bottom + scrollTop,
  };
};

export const serializeCpfAndNameSearch = (cpfOrName) => {
  if (cpfOrName.length < 3 || (! cpfOrName.match(/^[0-9]+/))) {
    return cpfOrName;
  }

  const onlyNumbersCpf = cpfOrName.replace(/\.|-/g, '');
  const cpfPiece1 = onlyNumbersCpf.substring(0, 3);
  if (onlyNumbersCpf.length === 3) {
    return `${cpfPiece1}.`;
  }

  const cpfPiece2 = onlyNumbersCpf.substring(3, 6);
  if (onlyNumbersCpf.length < 7) {
    return `${cpfPiece1}.${cpfPiece2}`;
  }

  const cpfPiece3 = onlyNumbersCpf.substring(6, 9);
  if (onlyNumbersCpf.length < 10) {
    return `${cpfPiece1}.${cpfPiece2}.${cpfPiece3}`;
  }

  const cpfPiece4 = onlyNumbersCpf.substring(9, 11);
  return `${cpfPiece1}.${cpfPiece2}.${cpfPiece3}-${cpfPiece4}`;
}

export const utils = {
  invertValue,
  openSnackBar,
  closeSnackBar,
  getSnackbar,
  openLinkSnackBar,
  closeLinkSnackBar,
  getLinkSnackbar,
  capitalizeFirstLetter,
  offsetElementPosition,
  serializeCpfAndNameSearch,
};
