import * as dictionary from '../constants/dictionary';
import { postUserCreatedTrackAPI } from './mixpanelAPI';
import {
  getPrintRestockReportDTO,
  getExportRestockReportDTO,
  getUserInvitedDTO,
  getUserCreatedDTO,
  getRetailCreateDTO,
  getRetailUpdateDTO,
  getRetailDeleteDTO,
  getStoreCreateDTO,
  getStoreEditDTO,
  getStoreDeleteDTO,
  getPromoterRetailAccessAuthorizationDTO,
  getPromoterRetailAccessRevokeDTO,
  getPromoterRetailAccessDeleteDTO,
  getAgreementCreateDTO,
  getAgreementCreateFrequencyStepDTO,
  getAgreementFrequencyEditDTO,
  getAgreementEditDTO,
  getSalesRepInviteSentDTO,
  getSalesRepAgreementDeleteDTO,
  getAgreementPromoterEditDTO,
  getPromoterInviteSentDTO,
  getAgreementPromoterDeleteDTO,
  getAgreementPromoterInactivatedDTO,
  getAgreementPromoterReleasedDTO,
  getAgreementDeleteDTO,
} from './trackers';

const mixpanel = require('mixpanel-browser');

const eventNames = {
  USER_INVITED: 'User Invited',
  USER_CREATED: 'User Created',
  USER_CLIENT_TRACKER: 'User Client Tracker',
  EXPORT_RESTOCK_REPORT: 'Export Restock Report',
  PRINT_RESTOCK_REPORT: 'Print Restock Report',
  RETAIL_CREATE: 'Retail Create',
  RETAIL_UPDATE: 'Retail Update',
  RETAIL_DELETE: 'Retail Delete',
  STORE_CREATE: 'Store Create',
  STORE_UPDATE: 'Store Update',
  STORE_DELETE: 'Store Delete',
  PROMOTER_ACCESS_AUTH: 'Promoter Retail Access Authorization',
  PROMOTER_ACCESS_REVOKE: 'Promoter Retail Access Revoke',
  PROMOTER_ACCESS_DELETE: 'Promoter Retail Access Delete',
};

export const trackUserInvited = async values => {
  const dto = await getUserInvitedDTO(values);
  mixpanel.track(eventNames.USER_INVITED, dto);
};

export const sendPullOfTrackInvites = async newInvites => {
  newInvites.forEach(invite => {
    trackUserInvited(invite);
  });
};

export const trackUserCreated = async (values, agreementId) => {
  const dto = getUserCreatedDTO(values, agreementId);
  if (dto['Creation Origin'] === dictionary.INVITED) {
    const userClientTrackerDto = await mixpanel.track(
      eventNames.USER_CLIENT_TRACKER,
      dto,
    );
    return postUserCreatedTrackAPI(userClientTrackerDto.properties);
  }
  return mixpanel.track(eventNames.USER_CREATED, dto);
};

export const trackExportRestockReport = async data => {
  const dto = await getExportRestockReportDTO(data);
  mixpanel.track(eventNames.EXPORT_RESTOCK_REPORT, dto);
};

export const trackPrintRestockReport = async data => {
  const dto = await getPrintRestockReportDTO(data);
  mixpanel.track(eventNames.PRINT_RESTOCK_REPORT, dto);
};

export const trackRetailCreate = async data => {
  const dto = await getRetailCreateDTO(data);
  mixpanel.track(eventNames.RETAIL_CREATE, dto);
};

export const trackRetailUpdate = async data => {
  const dto = await getRetailUpdateDTO(data);
  mixpanel.track(eventNames.RETAIL_UPDATE, dto);
};

export const trackRetailDelete = async data => {
  const dto = await getRetailDeleteDTO(data);
  mixpanel.track(eventNames.RETAIL_DELETE, dto);
};

export const trackStoreCreate = async data => {
  const dto = await getStoreCreateDTO(data);
  mixpanel.track(eventNames.STORE_CREATE, dto);
};

export const trackStoreEdit = async data => {
  const dto = await getStoreEditDTO(data);
  mixpanel.track(eventNames.STORE_UPDATE, dto);
};

export const trackStoreDelete = async data => {
  const dto = await getStoreDeleteDTO(data);
  mixpanel.track(eventNames.STORE_DELETE, dto);
};

export const trackPromoterRetailAccessAuthorization = async data => {
  const dto = await getPromoterRetailAccessAuthorizationDTO(data);
  mixpanel.track(eventNames.PROMOTER_ACCESS_AUTH, dto);
};

export const trackPromoterRetailAccessRevoke = async data => {
  const dto = await getPromoterRetailAccessRevokeDTO(data);
  mixpanel.track(eventNames.PROMOTER_ACCESS_REVOKE, dto);
};

export const trackPromoterRetailAccessDelete = async data => {
  const dto = await getPromoterRetailAccessDeleteDTO(data);
  mixpanel.track(eventNames.PROMOTER_ACCESS_DELETE, dto);
};

export const trackAgreementCreate = async (
  data,
  invitesCount,
  salesRepCount,
  suppliers,
) => {
  const dto = await getAgreementCreateDTO(
    data,
    invitesCount,
    salesRepCount,
    suppliers,
  );
  mixpanel.track('Agreement Create', dto);
};

export const trackAgreementCreateFrequencyStep = async (
  data,
  storeValuesDTO,
) => {
  const dto = await getAgreementCreateFrequencyStepDTO(data, storeValuesDTO);
  mixpanel.track('Agreement Create Frequency Step', dto);
};

export const trackAgreementFrequencyEdit = async (data, storeValuesDTO) => {
  const dto = await getAgreementFrequencyEditDTO(data, storeValuesDTO);
  mixpanel.track('Agreement Frequency Edit', dto);
};

export const trackAgreementEdit = async (
  data,
  invitesCount,
  salesRepCount,
  suppliers,
) => {
  const dto = await getAgreementEditDTO(
    data,
    invitesCount,
    salesRepCount,
    suppliers,
  );
  mixpanel.track('Agreement Edit', dto);
};

export const trackSalesRepInviteSent = async data => {
  const dto = await getSalesRepInviteSentDTO(data);
  mixpanel.track('Sales Rep Invite Sent', dto);
};

export const trackSalesRepAgreementDelete = async data => {
  const dto = await getSalesRepAgreementDeleteDTO(data);
  mixpanel.track('Sales Rep Agreement Delete', dto);
};

export const trackAgreementPromoterEdit = async (
  data,
  invitesCount,
  promotersCount,
  supplierNames,
) => {
  const dto = await getAgreementPromoterEditDTO(
    data,
    invitesCount,
    promotersCount,
    supplierNames,
  );
  mixpanel.track('Agreement Promoter Edit', dto);
};

export const trackPromoterInviteSent = async data => {
  const dto = await getPromoterInviteSentDTO(data);
  mixpanel.track('Promoter Invite Sent', dto);
};

export const trackAgreementPromoterDelete = async data => {
  const dto = await getAgreementPromoterDeleteDTO(data);
  mixpanel.track('Agreement Promoter Delete', dto);
};

export const trackAgreementPromoterReleased = async data => {
  const dto = await getAgreementPromoterReleasedDTO(data);
  mixpanel.track('Agreement Promoter Released', dto);
};

export const trackAgreementPromoterInactivated = async data => {
  const dto = await getAgreementPromoterInactivatedDTO(data);
  mixpanel.track('Agreement Promoter Inactivated', dto);
};

export const trackAgreementDelete = async data => {
  const dto = await getAgreementDeleteDTO(data);
  mixpanel.track('AgreementDelete', dto);
};
