import React, { useEffect, useState, useMemo } from 'react';
import { Button, Grid } from '@material-ui/core';
import { view } from 'react-easy-state';
import { History } from 'history';
import PageContent from '../../../components/PageContent';
import Loading from '../../../components/Loading';
import StickyFooter from '../../../components/stickyFooter/stickyFooter';
import { FormRetailField } from '../../../components/Form/FormRetailField';
import { DefaultButton, CancelButton } from '../../../components/Buttons';
import { utils } from '../../../core/utils';
import { useDebounce } from '../../../core/hooks';
import { profileCheck } from '../../../core/profiles';
import { useRetailList } from '../../../hooks/RetailHooks';
import { RetailEntityType } from '../../../core/types/retail/retail-entity.type';
import { PromotionalCalendarDto } from '../../../core/types/promotional-spot/promotional-calendar.dto';
import {
  getPromotionalCalendars,
  deletePromotionalCalendars,
} from '../../../core/services/promotionalSpots/promotionalCalendarsService';
import * as routes from '../../../core/constants/routes';
import * as dictionary from '../../../core/constants/dictionary';
import {
  EmptyPage,
  RemoveCalendarModal,
  PromotionalCalendarsTable,
} from './components';
import FormFilterCollapse from '../common/components/FormFilterCollapse';

type Props = {
  history: History;
};

const PromotionalCalendars = ({ history }: Props) => {
  const [calendars, setCalendars] = useState<PromotionalCalendarDto[]>([]);
  const [selectedRow, setSelectedRow] = useState<PromotionalCalendarDto>();
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [toolbarInputFocus, setToolbarInputFocus] = useState(false);
  const [hasInitialContent, setHasInitialContent] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRetail, setSelectedRetail] = useState<RetailEntityType>();
  const {
    retailInUser,
    fetch: fetchRetails,
    retails: retailsOptions,
    isLoading: isLoadingRetails,
  } = useRetailList();
  const isSuperAdmin = profileCheck.isSuperAdmin();
  const snackBar = utils.getSnackbar();

  const currentRetailId = useMemo(
    () => retailInUser || selectedRetail?.id,
    [selectedRetail?.id, retailInUser]
  );

  const fetchData = async (filters?: any) => {
    try {
      const limit = rowsPerPage;
      const offset = page * rowsPerPage;
      const retailId = isSuperAdmin ? { retailId: currentRetailId } : {};
      const { data } = await getPromotionalCalendars({
        limit,
        offset,
        ...retailId,
        ...filters,
      });
      setCalendars(data.items);
      setTotal(Number(data.total));
      setIsLoading(false);
    } catch (err) {
      if (err?.response?.status === 404) {
        setTotal(0);
        setCalendars([]);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, searchText]);

  useEffect(() => {
    if (calendars.length > 0 && !hasInitialContent) {
      setHasInitialContent(true);
    }
  }, [calendars, hasInitialContent]);

  const debouncedOnSearch = useDebounce((value: string[]) => {
    const inputValue = String(value);
    setSearchText(inputValue);
    setToolbarInputFocus(true);

    fetchData({ name: inputValue });
    setPage(0);
  }, 500);

  const memoizedCalendars = useMemo(() => calendars, [calendars]);
  const onClickAddCalendar = () =>
    history.push(routes.CREATE_PROMOTIONAL_CALENDAR);

  useEffect(() => {
    if (isSuperAdmin && !retailInUser) {
      fetchRetails();
    }
  }, [isSuperAdmin, retailInUser]);

  if (isLoading) {
    return <Loading isOpen={isLoading} />;
  }

  return (
    <PageContent title={dictionary.PROMOTIONAL_CALENDARS}>
      {hasInitialContent ? (
        <>
          {isSuperAdmin && !retailInUser && (
            <FormFilterCollapse
             actionPosition='grid'
              buttons={
                <>
                  <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setPage(0);
                      fetchData();
                    }}
                  >
                    {dictionary.SEARCH}
                  </Button>
                  <CancelButton
                    label={dictionary.CLEAR}
                    onClick={() => setSelectedRetail(undefined)}
                  />
                </>
              }
            >
              <Grid item sm={6} md={3}>
                <FormRetailField
                  key={currentRetailId}
                  value={selectedRetail}
                  disabled={isLoadingRetails}
                  options={retailsOptions ?? []}
                  onChange={setSelectedRetail}
                />
              </Grid>
            </FormFilterCollapse>
          )}
          <PromotionalCalendarsTable
            data={memoizedCalendars}
            page={page}
            total={total}
            rowsPerPage={rowsPerPage}
            searchTextValue={searchText}
            toolbarInputFocus={toolbarInputFocus}
            onChangePageHandler={(page: number) => setPage(page)}
            onChangeRowsPerPageHandler={(rowsPerPage: number) => {
              setPage(0);
              setRowsPerPage(rowsPerPage);
              setToolbarInputFocus(false);
            }}
            onSearchChangeHandler={(value: string[]) =>
              debouncedOnSearch(value)
            }
            onEditCalendarHandler={(data: any) =>
              history.push(`${routes.EDIT_PROMOTIONAL_CALENDAR}/${data.id}`)
            }
            onDeleteCalendarHandler={(data: any) => {
              setSelectedRow(data);
              setIsDeleteModalOpen(true);
            }}
          />
          <StickyFooter
            actionButton={
              <div className="button-create-container">
                <DefaultButton
                  label={dictionary.NEW_CALENDAR}
                  onClick={onClickAddCalendar}
                  style={{ width: 'auto' }}
                />
              </div>
            }
          />
        </>
      ) : (
        <EmptyPage onClickButton={onClickAddCalendar} />
      )}
      {snackBar}
      {isDeleteModalOpen && (
        <RemoveCalendarModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={async () => {
            try {
              if (selectedRow) {
                await deletePromotionalCalendars(selectedRow);
                await fetchData();
                setIsDeleteModalOpen(false);
              }
            } catch (err) {}
          }}
        />
      )}
    </PageContent>
  );
};

export default view(PromotionalCalendars);
