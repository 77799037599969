import MaterialTable from 'material-table';
import React, { useMemo, useRef } from 'react';
import PatchedPagination from '../../../../../components/PatchedPagination';
import { EmptyDataMessageRow } from '../../../../../components/Tables/DefaultTable';
import * as dictionary from '../../../../../core/constants/dictionary';

type Props = {
  report: any[];
  page: number;
  total: number;
  title?: string;
  rowsPerPage: number;
  onChangePageHandler: Function;
  columns: any;
  onChangeRowsPerPageHandler: Function;
  parseData: (data: any) => any;
  emptyMessage?: string;
};

const PromotionalSpotTableReport = ({
  report = [],
  total,
  page,
  rowsPerPage = 5,
  columns,
  onChangeRowsPerPageHandler,
  onChangePageHandler,
  title = '',
  emptyMessage = '',
  parseData,
}: Props) => {
  const tableRef = useRef(null);
  const data = useMemo(() => report.map(parseData), [report]);
  
  return (
    <MaterialTable
      options={{
        actionsColumnIndex: -1,
        pageSize: rowsPerPage,
        pageSizeOptions: [5, 10, 20],
        search: false,
      }}
      title={title}
      components={{
        Pagination: (props) => {
          return (
            <PatchedPagination
              {...props}
              count={total}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangePage={(evt: any, page: number) =>
                onChangePageHandler(page)
              }
              onChangeRowsPerPage={(
                event: React.ChangeEvent<HTMLInputElement>
              ) => {
                onChangeRowsPerPageHandler(parseInt(event.target.value));
                props.onChangeRowsPerPage(event);
              }}
            />
          );
        },
      }}
      data={data}
      totalCount={total}
      tableRef={tableRef}
      columns={columns}
      localization={{
        body: {
          emptyDataSourceMessage: (
            <EmptyDataMessageRow message={emptyMessage} />
          ),
        },
        pagination: {
          labelDisplayedRows: '{from}-{to} de {count}',
          labelRowsSelect: dictionary.LINES,
        },
      }}
    />
  );
};

export default PromotionalSpotTableReport;
