import { AxiosResponse } from 'axios';
import API from '../api/config';
import {
  API_PROMOTIONAL_NEGOTIATION,
  API_PROMOTIONAL_NEGOTIATION_RECURSIVE,
  API_PROMOTIONAL_NEGOTIATION_UPDATE,
  API_PROMOTIONAL_NEGOTIATION_UPDATE_PRODUCTS,
  API_PROMOTIONAL_NEGOTIATION_UPDATE_SUPPLIERS,
} from '../api/routes';
import {
  CreatePromotionalNegotiationParams,
  CreateRecursiveNegotiationDto,
  DeleteNegotiationParams,
  GetPromotionalNegotiationDto,
  GetPromotionalNegotiationParams,
  GetPromotionalNegotiationsDto,
  UpdatePromotionalNegotiationParams,
  UpsertPromotionalNegotiationProductsParams,
  UpsertPromotionalNegotiationSuppliersParams,
} from './types';

export const getNegotiationAPI = (
  params: Partial<GetPromotionalNegotiationParams>
): Promise<AxiosResponse<GetPromotionalNegotiationsDto>> => {
  const url = `${API_PROMOTIONAL_NEGOTIATION}`;

  return API.get(url, { params });
};

export const createNegotiationAPI = (
  params: CreatePromotionalNegotiationParams
): Promise<AxiosResponse<GetPromotionalNegotiationDto>> => {
  const url = API_PROMOTIONAL_NEGOTIATION;

  return API.post(url, { ...params });
};

export const updateNegotiationAPI = (
  params: UpdatePromotionalNegotiationParams
): Promise<AxiosResponse<GetPromotionalNegotiationDto>> => {
  const { id } = params;
  const url = `${API_PROMOTIONAL_NEGOTIATION_UPDATE}`.replace(':id', id);

  return API.put(url, { ...params });
};

export const upsertNegotiationSuppliersAPI = (
  params: UpsertPromotionalNegotiationSuppliersParams
): Promise<AxiosResponse<GetPromotionalNegotiationDto[]>> => {
  const { id } = params;
  const url = `${API_PROMOTIONAL_NEGOTIATION_UPDATE_SUPPLIERS}`.replace(
    ':id',
    id
  );
  return API.post(url, { ...params });
};

export const upsertNegotiationProductsAPI = (
  params: UpsertPromotionalNegotiationProductsParams
): Promise<AxiosResponse<GetPromotionalNegotiationDto[]>> => {
  const { id } = params;
  const url = `${API_PROMOTIONAL_NEGOTIATION_UPDATE_PRODUCTS}`.replace(
    ':id',
    id
  );
  return API.post(url, { ...params });
};

export const deleteNegotiationAPI = (
  params: DeleteNegotiationParams
): Promise<AxiosResponse<GetPromotionalNegotiationDto>> => {
  const { id, ...restParams } = params;
  const url = `${API_PROMOTIONAL_NEGOTIATION_UPDATE}`.replace(':id', id);

  return API.delete(url, { params: restParams });
};

export const createRecursiveNegotiationAPI = ({
  id,
  ...params
}: CreateRecursiveNegotiationDto): Promise<
  AxiosResponse<GetPromotionalNegotiationDto[]>
> => {
  const url = API_PROMOTIONAL_NEGOTIATION_RECURSIVE.replace(':id', id);

  return API.post(url, params);
};

export const removeRecursiveNegotiationAPI = (
  id: string
): Promise<AxiosResponse<GetPromotionalNegotiationDto[]>> => {
  const url = API_PROMOTIONAL_NEGOTIATION_RECURSIVE.replace(':id', id);

  return API.delete(url);
};
