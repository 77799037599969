import React, { useEffect, useState } from 'react';
import Loading from '../../components/Loading';
import PageContent from '../../components/PageContent';
import { ProductSuspension } from '../../core/types/product-suspension.type';
import { getProductSuspensions } from '../../core/services/shelfMissions/shelfMissionsService';
import { SuspiciousProductsTable } from './components';
import { SUSPICIOUS_PRODUCTS } from '../../core/constants/dictionary';
import { 
  defaultFilters, 
  ProductSuspensionFilter, 
  GetProductSuspensionsDTO,
} from './utils'; 

const SuspiciousProducts = () => {
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [suspiciousProducts, setSuspiciousProducts] = useState<ProductSuspension[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAllProductSuspensions = async (params: Partial<ProductSuspensionFilter> = {}) => {
    setIsLoading(true);
    const { count, items }: GetProductSuspensionsDTO = await getProductSuspensions({
      ...defaultFilters, 
      ...params,
    });
    setTotal(count);
    setSuspiciousProducts(items);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAllProductSuspensions();
  }, []);

  useEffect(()=> {
    fetchAllProductSuspensions({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
    });
  }, [page, rowsPerPage]);

  return (
    <React.Fragment>
      <PageContent title={SUSPICIOUS_PRODUCTS}>
        <SuspiciousProductsTable 
          data={suspiciousProducts} 
          count={total}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={(page: number)=> setPage(page)}
          onRowsPerPageChange={(rowsPerPage: number)=> setRowsPerPage(rowsPerPage)}
        />
      </PageContent>
      
      <Loading isOpen={isLoading} />
    </React.Fragment>
  );
};

export default SuspiciousProducts;
