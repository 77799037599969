import React, { useMemo } from 'react';
import { DialogActions, Typography } from '@material-ui/core';
import ModalTitle from '../../../../../../../components/Modal/Title';
import ReportProblemAlert from '../ReportProblemAlert';
import ModalSmallContainer from '../../../../../../../components/Modal/SmallContainer';
import { CancelButton } from '../../../../../../../components/Buttons';
import * as dictionary from '../../../../../../../core/constants/dictionary';
import classes from './Modals.module.scss';
import { PromotionalNegotiationExecutionProblemTypeLabel } from '../../../../../../../core/types/promotional-spot/promotional-negotiation-execution-problem-type-label.enum';
import { PromotionalNegotiationExecutionDto } from '../../../../../../../core/types/promotional-spot/promotional-negotiation-execution-dto';

type Props = {
  executions: PromotionalNegotiationExecutionDto[];
  isOpen: boolean;
  onClose: Function;
};

const ViewReportedProblemModal = ({ executions, isOpen, onClose }: Props) => {
  const executionRender = useMemo(
    () =>
      executions.map((execution) => (
        <div className={classes.overflowModalBody}>
          <Typography className={classes.caption}>
            {`${execution.addressing.store.name} - ${execution.addressing.physicalAddressing}`}
          </Typography>
          {execution.problems?.map((problem) => (
            <ReportProblemAlert
              user={problem.reportedBy.name}
              datetime={new Date(problem.createdAt)}
              type={
                PromotionalNegotiationExecutionProblemTypeLabel[
                  problem.type as keyof typeof PromotionalNegotiationExecutionProblemTypeLabel
                ]
              }
              product={
                problem.products?.map((product) => ({
                  ean: product.ean,
                  name: product.name,
                })) ?? []
              }
            />
          ))}
        </div>
      )),
    [executions]
  );

  return (
    <ModalSmallContainer isOpen={isOpen} closeModalCallback={onClose}>
      <ModalTitle
        title={dictionary.REPORTED_PROBLEMS}
        className={classes.modalTitle}
      />
      {executionRender}
      <div className={classes.footer}>
        <DialogActions>
          <CancelButton label={dictionary.CLOSE} onClick={onClose} />
        </DialogActions>
      </div>
    </ModalSmallContainer>
  );
};

export default ViewReportedProblemModal;
