import React, { useMemo } from 'react';
import { PromotionalNegotiationDto } from '../../../common/types';
import { PromotionalNegotiationExecutionDto } from '../../../../../core/types/promotional-spot/promotional-negotiation-execution-dto';
import { NegotiationCell } from '../NegotiationTable/components';
import { filterExecutionsInCurrentStore, parseExecutedMetrics } from './utils';

type Props<T> = {
  item: T;
  store: any;
  disabled?: boolean;
  totalAvaliableAddressings: number;
  isPending: boolean;
  onClickMore: (
    event: any,
    executions: PromotionalNegotiationExecutionDto[],
    store: any,
    negotiation: PromotionalNegotiationDto,
    showOptionAddmore?: boolean
  ) => void;
  onClickProblem: (
    event: any,
    executions: PromotionalNegotiationDto['executions']
  ) => void;
};

const NegotiationStoreCell = ({
  item,
  store,
  disabled = false,
  isPending = false,
  totalAvaliableAddressings = 0,
  onClickMore,
  onClickProblem,
}: Props<PromotionalNegotiationDto>) => {
  const executionsInStore = useMemo(
    () => filterExecutionsInCurrentStore(item.executions, store),
    [item.executions, store]
  );
  
  const { total, executed, problems } = parseExecutedMetrics(executionsInStore);
  const isExecuted = executed > 0;
  const hasProblems = problems > 0;
  const pending = total > 0 && total - executed > 0 && isPending;
  const showMoreOptions =
    totalAvaliableAddressings == 0
      ? false
      : executionsInStore.length > 0 || disabled;

  return (
    <NegotiationCell
      key={store?.id}
      pending={pending}
      problem={hasProblems}
      executed={isExecuted}
      showMoreOptions={showMoreOptions}
      count={executionsInStore?.length ?? 0}
      tooltip={`${store.name} (${store.storeCode})`}
      disabled={totalAvaliableAddressings <= 0 || !showMoreOptions}
      onClickMore={(event) =>
        onClickMore(event, executionsInStore, store, item, disabled)
      }
      onClickProblem={(event) =>
        onClickProblem(
          event,
          executionsInStore.filter((execution) => execution.problems?.length)
        )
      }
    />
  );
};

export default NegotiationStoreCell;
