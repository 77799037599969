import styled from 'styled-components';
import { theme, withProp } from 'styled-tools';
import { transparentize } from 'polished';
import { Form } from 'formik';
import { Button } from '@involves/violin';
import { FormInputDropdownCPF } from '../../../components/Form';

export const StyledForm = styled(Form)`
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
  flex-direction: column;
`;

export const StyledFormInputDropdownContainer = styled.div`
  position: relative;
`;

export const StyledFormInputDropdownCPF = styled(FormInputDropdownCPF)`
  border-radius: 8px;
  background: ${withProp(theme('palette.misc.white'), transparentize(0.85))};

  ::placeholder {
    color: ${props => props.theme.palette.black[50]};
  }

  :focus {
    ::placeholder {
      color: ${props => props.theme.palette.black[900]};
    }
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 40px;
  border-radius: 8px;
  :disabled {
    color: ${props => transparentize(0.8, props.theme.palette.black[50])};
    background: ${props =>
      transparentize(0.85, props.theme.palette.misc.white)};
  }
`;
