import React from 'react';
import { view } from 'react-easy-state';
import { UserInfo } from '../../utils';
import { BaseModal } from '../BaseModal';

type Props = {
  isOpen: boolean;
  currentUser: UserInfo | null;
  onClose: Function;
  onReload: Function;
}

export const EditUser = ({ isOpen, currentUser, onClose, onReload }: Props) => {
  if (!isOpen || !currentUser) {
    return null;
  }

  return (
    <BaseModal
      isEditMode
      currentUser={currentUser}
      onClose={onClose}
      onReload={onReload}
    />
  );
};
export default view(EditUser);
