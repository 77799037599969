import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button, Collapse, Grid, TextField } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import { ptBR } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import FilterListIcon from '@material-ui/icons/FilterList';
import Loading from '../../../components/Loading';
import { CancelButton } from '../../../components/Buttons';
import API from '../../../core/services/api/config';
import { StoreDto } from '../../promotional-spots/Addressing/types';
import { ProductDto } from '../../../core/services/products/types';
import { getProducts } from '../../../core/services/products/productsService';
import { profileCheck } from '../../../core/profiles';
import { useRetailList } from '../../../hooks/RetailHooks';
import { getActiveStoresByRetail } from '../../../core/services/stores/storesService';
import { FormRetailField } from '../../../components/Form/FormRetailField';
import { RetailEntityType } from '../../../core/types/retail/retail-entity.type';
import * as dictionary from '../../../core/constants/dictionary';
import {
  defaultFilter,
  RiskLevelLabel,
  ProductGroupDto,
  riskLevelOptions,
  mdGridSize,
  filterByGroupsAndTerm,
} from '../utils';
import classes from '../ExpirationPanel.module.scss';
import { useDebounce } from '../../../core/hooks';

type Props = {
  onSubmit: Function;
};

const Filter = ({ onSubmit }: Props) => {
  const [filter, setFilter] = useState(defaultFilter);
  const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [storeOptions, setStoreOptions] = useState<StoreDto[]>([]);
  const [productOptions, setProductOptions] = useState<ProductDto[]>([]);
  const [productGroupOptions, setProductGroupsOptions] = useState<
    ProductGroupDto[]
  >([]);
  const [selectedStore, setSelectedStore] = useState<StoreDto | null>(null);
  const [selectedProducts, setSelectedProducts] = useState<ProductDto[]>([]);
  const [selectedRiskLevel, setSelectedRiskLevel] = useState<RiskLevelLabel[]>(
    []
  );
  const [selectedProductGroups, setSelectedProductGroups] = useState<
    ProductGroupDto[]
  >([]);
  const [selectedRetail, setSelectedRetail] = useState<RetailEntityType>();
  const [isLoading, setIsLoading] = useState(false);
  const {
    fetch: fetchRetails,
    isLoading: isLoadingRetails,
    retailInUser,
    retails: retailsOptions,
  } = useRetailList();

  const isSuperAdmin = profileCheck.isSuperAdmin();
  const productList = useRef<ProductDto[]>([]);
  const lastValidProductList = useRef<ProductDto[]>([]);

  const currentRetailId = useMemo(
    () => retailInUser || selectedRetail?.id,
    [selectedRetail?.id, retailInUser]
  );

  const fetchProducts = useCallback(
    async (q?: string) => {
      if (!isLoadingProducts) {
        try {
          setIsLoadingProducts(true);
          const data = await getProducts({
            limit: 10,
            retailId: currentRetailId,
            q,
          });
          setProductOptions(data.items);
          filterProductList(data.items, q ?? '');
        } catch (error) {
        } finally {
          setIsLoadingProducts(false);
        }
      }
    },
    [currentRetailId, isLoadingProducts]
  );

  const fetchStores = useCallback(async () => {
    const stores = await getActiveStoresByRetail(currentRetailId);
    setStoreOptions(stores);
  }, [currentRetailId]);

  const fetchProductGroupsByRetail = useCallback(async () => {
    const { data: productGroups } = await API.get('/product-groups', {
      params: { retailId: currentRetailId },
    });
    setProductGroupsOptions(productGroups);
  }, [currentRetailId]);

  useEffect(() => {
    if (isSuperAdmin && !retailInUser) {
      fetchRetails();
    }
  }, [isSuperAdmin, retailInUser]);

  useEffect(() => {
    if (currentRetailId) {
      setFilter({ ...defaultFilter, retailId: currentRetailId });
      fetchProductGroupsByRetail();
      fetchStores();
      fetchProducts();
    }
  }, [currentRetailId]);

  const filterProductList = (
    products: ProductDto[] | undefined,
    searchTerm: string
  ): ProductDto[] => {
    if (searchTerm.length === 0) {
      lastValidProductList.current = [];
      return [];
    }

    if (searchTerm.length >= 3 && products) {
      const filteredProducts = filterByGroupsAndTerm({
        products,
        searchTerm,
        selectedProducts,
        selectedProductGroups,
      });
      lastValidProductList.current = [...filteredProducts];

      return filteredProducts;
    } else {
      return lastValidProductList.current;
    }
  };

  const fetchProductsFromInput = useDebounce(fetchProducts, 500);

  const onResetFilter = () => {
    setFilter(defaultFilter);
    setSelectedStore(null);
    setSelectedProducts([]);
    setSelectedRiskLevel([]);
    setSelectedProductGroups([]);
  };

  return (
    <Grid className={classes.filterGrid}>
      {isLoading && <Loading isOpen={isLoading} />}
      <Grid container item spacing={2} className={classes.buttonGrid}>
        <Grid item>
          <Button
            size="large"
            disableElevation
            startIcon={<FilterListIcon />}
            onClick={() => setIsFilterOpen((prevState) => !prevState)}
          >
            {dictionary.FILTERS}
          </Button>
        </Grid>
      </Grid>

      <Collapse in={isFilterOpen}>
        <Grid className={classes.formGap}>
          <Grid container spacing={2} className={classes.formGroupGap}>
            {isSuperAdmin && !retailInUser && (
              <Grid item sm={6} md>
                <FormRetailField
                  disabled={isLoadingRetails}
                  value={selectedRetail}
                  options={retailsOptions ?? []}
                  onChange={setSelectedRetail}
                />
              </Grid>
            )}
            {!profileCheck.isStoreManager() && (
              <Grid item sm={6} md>
                <Autocomplete
                  id="store"
                  size="small"
                  limitTags={2}
                  value={selectedStore}
                  options={storeOptions}
                  getOptionLabel={(option: StoreDto) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={dictionary.STORE}
                      variant="outlined"
                    />
                  )}
                  onChange={(_, store: StoreDto | null) => {
                    setFilter({
                      ...filter,
                      storeId: store?.id,
                    });
                    setSelectedStore(store);
                  }}
                />
              </Grid>
            )}
            <Grid item sm={6} md={mdGridSize}>
              <Autocomplete
                multiple
                size="small"
                id="productGroup"
                limitTags={2}
                options={productGroupOptions}
                value={selectedProductGroups}
                getOptionLabel={(option: ProductGroupDto) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={dictionary.SECTOR}
                    variant="outlined"
                  />
                )}
                onChange={(_, productGroup: ProductGroupDto[]) => {
                  setFilter((prevState) => ({
                    ...prevState,
                    productGroup,
                  }));
                  setSelectedProductGroups(productGroup);
                }}
              />
            </Grid>
            <Grid item sm={6} md={4}>
              <Autocomplete
                multiple
                id="name"
                size="small"
                limitTags={2}
                options={productOptions}
                loading={isLoadingProducts}
                loadingText={'Carregando...'}
                value={selectedProducts}
                getOptionLabel={(option: ProductDto) =>
                  `${option.name} (${option.internalCode})`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={dictionary.PRODUCT_NAME}
                    placeholder={
                      selectedProducts.length > 0
                        ? ''
                        : dictionary.TYPE_PRODUCT_NAME
                    }
                  />
                )}
                onInputChange={(_: ChangeEvent<{}>, value: string) => {
                  setTimeout(() => {
                    if (value && value.length > 0) {
                      fetchProductsFromInput(value);
                    }
                  }, 500);
                }}
                onChange={(_, product: ProductDto[]) => {
                  setFilter((prevState) => ({
                    ...prevState,
                    product,
                  }));
                  setSelectedProducts(product);
                }}
              />
            </Grid>
            <Grid item sm={6} md={mdGridSize}>
              <Autocomplete
                multiple
                id="name"
                size="small"
                limitTags={2}
                value={selectedRiskLevel}
                options={riskLevelOptions}
                getOptionLabel={(option: RiskLevelLabel) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={dictionary.CURRENT_RISK}
                    variant="outlined"
                  />
                )}
                onChange={(_, riskLevel: RiskLevelLabel[]) => {
                  setFilter((prevState) => ({
                    ...prevState,
                    riskLevel,
                  }));
                  setSelectedRiskLevel(riskLevel);
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="flex-end">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <Grid container item xs={4} md={2} justifyContent="flex-end">
                <KeyboardDatePicker
                  disableToolbar
                  id="startDate"
                  margin="none"
                  variant="inline"
                  className="--full"
                  format="dd/MM/yyyy"
                  value={filter.startDate}
                  label={dictionary.DATE_START_SELECT}
                  maxDate={filter.endDate || new Date()}
                  maxDateMessage={dictionary.MAX_START_DATE_MESSAGE}
                  invalidDateMessage={''}
                  onChange={(startDate) => {
                    setFilter((prevState) => ({
                      ...prevState,
                      startDate,
                    }));
                  }}
                />
              </Grid>
              <Grid container item xs={4} md={2} justifyContent="flex-end">
                <KeyboardDatePicker
                  disableToolbar
                  id="endDate"
                  margin="none"
                  variant="inline"
                  className="--full"
                  format="dd/MM/yyyy"
                  value={filter.endDate}
                  label={dictionary.DATE_END_SELECT}
                  minDate={filter.startDate}
                  minDateMessage={dictionary.MIN_END_DATE_MESSAGE}
                  maxDateMessage={dictionary.MAX_END_DATE_MESSAGE}
                  invalidDateMessage={''}
                  onChange={(endDate) => {
                    setFilter((prevState) => ({
                      ...prevState,
                      endDate,
                    }));
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <Grid container item xs={4} md spacing={2}>
              <Grid item>
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  onClick={(event: MouseEvent) => onSubmit(event, filter)}
                >
                  {dictionary.SEARCH}
                </Button>
              </Grid>
              <Grid item>
                <CancelButton
                  label={dictionary.CLEAR}
                  onClick={onResetFilter}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
};

export default Filter;
