import { format, differenceInDays } from 'date-fns';

const DEFAULT_TIMEZONE = 'America/Sao_Paulo';

export const getDateParameter = (date) =>
  date instanceof Date ? date : new Date(date);

export const parseDateFormat = ({
  date,
  config,
  timeZone = DEFAULT_TIMEZONE,
}) => {
  if (!date) {
    return '';
  }
  const dateValue = getDateParameter(date);

  let formattedDate = '';
  try {
    formattedDate = new Intl.DateTimeFormat('pt-BR', {
      ...config,
      timeZone,
    }).format(dateValue);
    return formattedDate;
  } catch (error) {
    return '';
  }
};

export const getDayOfWeek = (date, timeZone) =>
  parseDateFormat({
    date,
    timeZone,
    config: {
      weekday: 'long',
    },
  });

export const getFormattedDate = (date, timeZone) =>
  parseDateFormat({
    date,
    timeZone,
    config: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    },
  });

export const getTime = (date, timeZone) =>
  parseDateFormat({
    date,
    timeZone,
    config: {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
  });

export const getLocalDateFromISODate = (isoDate) => {
  if (isoDate) {
    const date = new Date(isoDate);
    return `${date.toLocaleDateString()}`;
  }
  return '';
};

export const getStringValueWith2Digits = (value) => {
  return value < 10 ? `0${value}` : value;
};

export const getHoursAndMinutesFromISODate = (isoDate) => {
  if (isoDate) {
    const date = new Date(isoDate);
    const hour = getStringValueWith2Digits(date.getHours());
    const minutes = getStringValueWith2Digits(date.getMinutes());
    return `${hour}h${minutes}`;
  }
  return '';
};

export const getDifferenceBetweenISODates = (initialIsoDate, finalIsoDate) => {
  if (initialIsoDate && finalIsoDate) {
    const initialDate = new Date(initialIsoDate);
    const finalDate = new Date(finalIsoDate);
    const diffDates = finalDate - initialDate;
    const diffHrs = Math.floor((diffDates % 86400000) / 3600000);
    const diffMins = Math.round(((diffDates % 86400000) % 3600000) / 60000);
    const diffSecs = Math.floor(diffDates / 1000);

    if (diffHrs > 0 && diffMins > 0) {
      return `${getStringValueWith2Digits(
        diffHrs
      )}h e ${getStringValueWith2Digits(diffMins)}m`;
    }
    if (diffHrs > 0) {
      return `${getStringValueWith2Digits(diffHrs)}h`;
    }
    if (diffMins > 0) {
      return `${getStringValueWith2Digits(diffMins)}m`;
    }
    return `${getStringValueWith2Digits(diffSecs)}s`;
  }
  return '';
};

export const timezone = () => {
  const defaultTimezone = 'America/Sao_Paulo';
  if (
    !Intl ||
    !Intl.DateTimeFormat ||
    !Intl.DateTimeFormat().resolvedOptions() ||
    !Intl.DateTimeFormat().resolvedOptions().timeZone
  ) {
    return defaultTimezone;
  }
  return Intl.DateTimeFormat().resolvedOptions().timeZone || defaultTimezone;
};

export const getMonthTextInLocale = (date, timeZone) =>
  parseDateFormat({
    date,
    timeZone,
    config: {
      month: 'long',
      timeZone,
    },
  });

export const daysBetweenDates = (start, end) => {
  if (!(start instanceof Date) && !!(end instanceof Date)) {
    return null;
  }
  const args = start > end ? [start, end] : [end, start];

  return differenceInDays(...args);
};

export const formatWithoutTimezone = (date, stringFormat = 'dd/MM/yyyy') => {
  if (!date) {
    throw new Error('Invalid Date value');
  }
  const tzOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const dateWithOffset = new Date(new Date(date).getTime() + tzOffset);

  return format(dateWithOffset, stringFormat);
};

export const dateForGMTWithoutTimezone = (date) =>
  new Date(new Date(date.toISOString()).setHours(0, 0, 0));
