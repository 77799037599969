import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useFormikContext } from 'formik';
import { Text } from '@involves/violin';
import { InputDropdownItem } from '../../../components/InputDropdown';
import { InputPlaceholder } from '../../../components/InputPlaceholder';
import { 
  INVALID_CPF,
  INCOMPLETE_CPF,
  DO_PDV_CHECK_IN,
  CPF_NOT_REGISTERED,
  ENTER_CPF_TO_CHECK_IN,
} from '../../../core/constants/dictionary';
import { usePromoter } from '../usePromoter';
import { 
  isCpfValid, 
  isCpfComplete, 
  getValidCpfSubstring, 
  isPromoterCpfRegistered,
} from '../utils';
import {
  StyledForm,
  StyledButton,
  StyledFormInputDropdownCPF,
  StyledFormInputDropdownContainer,
} from './style';

const FormVisitsAutoCheckin = ({ token, isDisabled }) => {
  const [selectedPromoter, setSelectedPromoter] = useState(null);
  const inputDropdownRef = useRef();
  const inputDropdownItemRef = useRef();
  const formik = useFormikContext();
  const fieldMetaCpf = formik.getFieldMeta('cpf');
  const fieldHelpersCpf = formik.getFieldHelpers('cpf');
  
  const validCpf = useMemo(() => {
    if (!fieldMetaCpf.value) {
      return '';
    }
    return fieldMetaCpf.value.replace('___.___.___-__', '');
  }, [fieldMetaCpf.value]);

  const promoter = usePromoter(validCpf, token);
  
  useEffect(() => {
    const { value } = fieldMetaCpf;
    const cpf = getValidCpfSubstring(value);
    
    if (selectedPromoter) {
      fieldHelpersCpf.setError(false);
      return;
    }
    
    let errorMessage = false;
    if (!isCpfComplete(cpf)) {
      if (cpf.length > 0) {
        errorMessage = INCOMPLETE_CPF;
      }
    }
    else if (!isPromoterCpfRegistered(promoter)) {
      errorMessage = CPF_NOT_REGISTERED;
      
      if (!isCpfValid(cpf)) {
        errorMessage = INVALID_CPF;
      }
    } 
    fieldHelpersCpf.setError(errorMessage);

  }, [promoter, fieldMetaCpf.value, fieldMetaCpf.error]);

  useEffect(() => {
    if (!fieldMetaCpf.value && selectedPromoter) {
      setSelectedPromoter(null);
    }
  }, [fieldMetaCpf.value, selectedPromoter]);

  const onClickDropdownHandler = () => {
    setSelectedPromoter(promoter);
    formik.getFieldHelpers('promoterUser').setValue({
      id: promoter.user.id,
      name: promoter.user.name,
    });
  };

  const onCloseDropdownHandler = () => {
    formik.resetForm();
    setSelectedPromoter(null);
  };

  return (
    <StyledForm noValidate>
      <StyledFormInputDropdownContainer>
        <StyledFormInputDropdownCPF
          deepRef={inputDropdownRef}
          placeholder={ENTER_CPF_TO_CHECK_IN}
          show={!!promoter && !!promoter.user && !selectedPromoter}
          onClose={onCloseDropdownHandler}
        >
          <InputDropdownItem ref={inputDropdownItemRef} onClick={onClickDropdownHandler}>
            {
              !!promoter && !!promoter.user && (
              <Text>{`${promoter.user.name} (${promoter.user.cpf})`}</Text>
            )}
          </InputDropdownItem>
        </StyledFormInputDropdownCPF>

        <InputPlaceholder show={!!selectedPromoter} onClear={onCloseDropdownHandler}>
          {
            selectedPromoter &&
            `${selectedPromoter.user.name} (${selectedPromoter.user.cpf})`
          }
        </InputPlaceholder>
      </StyledFormInputDropdownContainer>

      <StyledButton
        large
        block
        primary
        type="submit"
        disabled={!selectedPromoter || !selectedPromoter.exists || isDisabled}
      >
        {DO_PDV_CHECK_IN}
      </StyledButton>
    </StyledForm>
  );
};

export { FormVisitsAutoCheckin };
