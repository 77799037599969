export enum RetailFeature {
  PRODUCT_VALIDITY = 1,
  SUSPECT_PRODUCT = 2,
  SHELF_MISSIONS = 3,
  ACCESS_CONTROL = 4,
  PROMOTIONAL_SPOT = 5,
  PUSH_NOTIFICATION = 6,
}

export type RetailFeatureEntity = {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    version: number;
    //retail: RetailEntity;
    retail: object;
    retailId: string;
    feature: RetailFeature;
    //status: ActiveStatus;
    status: number;
}

export type UpdateFeatureParams = {
  data: {
    status: number;
  }
  retailId: string;
  featureCode: RetailFeature;
}

export type GetRetailFeaturesDto = {
  count: number;
  items: RetailFeatureEntity[]; 
}

export type GetFeatureByIdDto = {
  status: number;
  feature: RetailFeature;
}
