import API from '../services/api/config';
import * as routes from '../services/api/routes';
import { mainStore } from '../store/mainStore';

export const postUserCreatedTrackAPI = async data => {
  const url = `${routes.API_MIXPANEL}/user-created-invited`;
  const response = await API.post(url, data);
  mainStore.requestLoading = false;
  return response;
};
