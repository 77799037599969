import { 
  FormFieldObject, 
} from './types';

export const defaultFormFields: FormFieldObject = {
  name: {
    message: '',
    hasError: false,
  },
  startAt: {
    message: '',
    hasError: false,
  },
  endAt: {
    message: '',
    hasError: false,
  },
  negotiatedAt: {
    message: '',
    hasError: false,
  }
};
