import React from 'react';
import classes from '../styles.module.scss';

export default () => (
  <section className={classes.section}>
    <h2 className={classes.title}>
      ATUALIZAÇÕES
    </h2>

    <p>
      Nos reservamos o direito de alterar essa Política quantas vezes forem
      necessárias, visando fornecer aos nossos usuários cada vez mais
      segurança e conveniência.
    </p>
    <p>
      Quando realizarmos alterações relevantes na presente Política, você
      será devidamente notificado, conforme as circunstâncias, por exemplo,
      exibindo um aviso no Aplicativo ou enviando-lhe um e-mail. Podemos
      notificá-lo com antecedência.
    </p>
    <p>
      Para facilitar, sempre indicaremos no fim do documento a data da
      última atualização.
    </p>
    <p className={classes.doubleMargin}>
      Ao continuar a acessar ou usar nossos serviços após essas revisões
      entrarem em vigor, você concorda em ficar vinculado aos termos
      revisados.
    </p>
  </section>
);

    