import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { Grid } from '@material-ui/core';
import { format, differenceInMonths } from 'date-fns';
import API from '../../core/services/api/config';
import Loading from '../../components/Loading';
import PageContent from '../../components/PageContent';
import MissionsOverviewTable from './components/Table';
import MissionsOverviewFilter from './components/Filter';
import MissionsOverviewIndicators from './components/Indicators';
import FixedBottomFooter from '../../components/FixedBottomFooter';
import Button from '../../components/Buttons/Default';
import Hide from '../../components/Hide/index';
import { createInitialFilter, formatFilename } from './utils';
import { utils } from '../../core/utils';
import { mainStore } from '../../core/store/mainStore';

import * as dictionary from '../../core/constants/dictionary';

const MissionsOverviewPage = () => {
  const INITIAL_FILTER = useMemo(() =>
    createInitialFilter({
      loggedUser: mainStore.loggedUser,
    }),
  [mainStore]
  );

  const [indicators, setIndicators] = useState({});
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(INITIAL_FILTER);
  const [indicatorsFilter, setIndicatorsFilter] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [isLoadingIndicators, setIsLoadingIndicators] = useState(false);
  const [excelFileURL, setExcelFileURL] = useState('');
  const [exportAlert, setExportAlert] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const downloadExcelButton = useRef();
  const snackBar = useMemo(() => utils.getSnackbar(), []);
  const monthDiff = useMemo(
    () => Math.abs(differenceInMonths(filter.startDate, filter.endDate)),
    [filter.startDate, filter.endDate]
  );

  const getIndicators = async () => {
    setIndicators({});
    setIsLoadingIndicators(true);
    const params = {
      ...filter,
      startDate: format(filter.startDate, 'yyyy-MM-dd'),
      endDate: format(filter.endDate, 'yyyy-MM-dd'),
    };

    const response = await API.get('/missions/indicators', { params });
    setIndicators(response.data);
    setIsLoadingIndicators(false);
  };

  const getData = useCallback(async () => {
    setLoading(true);

    try {
      if (!loading && filter?.retailId) {
        const params = {
          ...filter,
          ...indicatorsFilter,
          offset: page * rowsPerPage,
          limit: rowsPerPage,
          startDate: format(filter.startDate, 'yyyy-MM-dd'),
          endDate: format(filter.endDate, 'yyyy-MM-dd'),
          order,
          orderBy,
        };

        const response = await API.get('/missions', { params });
        setItems(response.data.data);
        setTotal(response.data.total);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [loading, filter, indicatorsFilter, page, rowsPerPage, order, orderBy]);

  const onExportData = useCallback(async () => {
    setIsLoadingExport(true);
    if (!isLoadingExport) {
      const params = {
        ...filter,
        ...indicatorsFilter,
        startDate: format(filter.startDate, 'yyyy-MM-dd'),
        endDate: format(filter.endDate, 'yyyy-MM-dd'),
      };

      if (monthDiff >= 1) {
        setExportAlert(true);
      } else {
        setExportAlert(false);
        const response = await API.get('/missions/export', {
          params,
          responseType: 'arraybuffer',
        });
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });
        setExcelFileURL(window.URL.createObjectURL(blob));
      }

      setIsLoadingExport(false);
    }
  }, [isLoadingExport, filter, indicatorsFilter, monthDiff]);

  const onSortHandler = (orderByParam) => {
    let newOrderByParam = orderByParam;

    setOrder((prevState) => {
      if (orderBy === orderByParam) {
        if (prevState === 'asc') {
          return 'desc';
        }
        if (prevState === 'desc') {
          newOrderByParam = '';
          return undefined;
        }
        return 'asc';
      }
      return 'asc';
    });

    setOrderBy(newOrderByParam);
  };

  const onCloseExportAlert = () => setExportAlert(false);

  useEffect(() => {
    if (filter.retailId) {
      getIndicators();
    }
    setPage(0);
    if (monthDiff === 0 && exportAlert) {
      setExportAlert(false);
    }
  }, [filter, monthDiff]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPage(0);
  }, [indicatorsFilter]);

  useEffect(() => {
    getData();
  }, [page, rowsPerPage, filter, indicatorsFilter, order, orderBy]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (excelFileURL === '') return;

    // Trigger no link fantasma para download do Excel já formatado (this.onExportData)
    const startDate = format(filter.startDate, 'dd.MM.yyyy');
    const endDate = format(filter.endDate, 'dd.MM.yyyy');
    downloadExcelButton.current.download = formatFilename(startDate, endDate);
    downloadExcelButton.current.click();
  }, [excelFileURL]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PageContent title="Painel de abastecimento">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MissionsOverviewIndicators
              data={indicators}
              isLoading={isLoadingIndicators}
              onFilter={(filter) => setIndicatorsFilter(filter)}
            />
          </Grid>
          <Grid item xs={12}>
            <MissionsOverviewFilter
              initialFilter={INITIAL_FILTER}
              isExportAlertOpen={exportAlert}
              onCloseExportAlert={onCloseExportAlert}
              onFilter={(filter) => setFilter(filter)}
            />
          </Grid>
          <Grid item xs={12}>
            <MissionsOverviewTable
              loading={loading}
              items={items}
              total={total}
              page={page}
              rowsPerPage={rowsPerPage}
              order={order}
              orderBy={orderBy}
              onChangePage={(page) => setPage(page)}
              onSort={(orderBy) => onSortHandler(orderBy)}
              onChangeRowsPerPage={(value) => setRowsPerPage(value)}
            />
          </Grid>
        </Grid>
      </PageContent>

      {snackBar}

      <Loading isOpen={isLoadingExport} />

      <FixedBottomFooter>
        <Hide>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={excelFileURL}
            ref={downloadExcelButton}
          >
            {' '}
          </a>
        </Hide>

        <Button
          color="primary"
          label={dictionary.EXPORT}
          disabled={isLoadingExport}
          onClick={onExportData}
          style={{ width: '94px' }}
        />
      </FixedBottomFooter>
    </>
  );
};

export default MissionsOverviewPage;
