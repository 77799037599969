import React from 'react';
import { 
  Paper, 
  Table, 
  TableContainer, 
  TablePagination,
} from '@material-ui/core';
import { ProductSuspension } from '../../../core/types/product-suspension.type';
import { TableBody, TableHeader, } from './index';

type SuspiciousProductsTableProps = {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: Function;
  onRowsPerPageChange: Function;
  data?: ProductSuspension[];
}

const SuspiciousProductsTable = ({ 
  data,
  count,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
}: SuspiciousProductsTableProps) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHeader />
        <TableBody data={data}/>
        <TablePagination
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={(event, page) => onPageChange(page)}
          onRowsPerPageChange={(event) => onRowsPerPageChange(event.target.value)}
        />
      </Table>
    </TableContainer>
  );
};

export default SuspiciousProductsTable;
