import React from 'react';
import classes from '../styles.module.scss';

export default () => (
  <section className={classes.section}>
    <h2 className={classes.title}>DADOS COLETADOS</h2>

    <p className={classes.doubleMargin}>
      Pelo compromisso com a transparência, nas listas abaixo estabelecemos
      as categorias de dados pessoais que coletamos e usamos e como os
      coletamos:
    </p>

    <h2 className={classes.subtitle}>
      Descrição dos dados pessoais coletados ao se cadastrar no Aplicativo:
    </h2>

    <p className={classes.noMargin}>
      <strong>Categorias de dados pessoais: </strong>Dados do Usuário
    </p>
    <p>
      <strong>Descrição: </strong>Estes são os dados pessoais fornecidos por
      você ou coletados pela Involves para permitir a utilização do
      Aplicativo. Para esses fins, coletamos os seguintes dados:{' '}
    </p>

    <ul>
      <li>Nome Completo;</li>
      <li>E-mail;</li>
      <li>CPF;</li>
      <li>RG;</li>
      <li>Número de Telefone;</li>
      <li>Foto de Perfil;</li>
      <li>Foto do RG;</li>
      <li>Foto do CPF.</li>
    </ul>

    <p>
      Ao coletar as fotos para fins de cadastro e validação da conta, apenas
      iremos acessar a sua câmera ou fotos do seu dispositivo se nos
      autorizar a fazê-lo e acessaremos apenas a imagens que decida
      especificamente compartilhar conosco e os metadados relacionados às
      referidas imagens, tais como o tipo de arquivo e o tamanho da imagem.
      Em hipótese alguma iremos digitar ou importar a biblioteca de
      fotografias do seu dispositivo ou rolo de câmera.
    </p>

    <p className={classes.doubleMargin}>
      Você também possui a opção de nos prover dados pessoais adicionais
      para tornar a sua conta mais personalizada.
    </p>

    <h2 className={classes.subtitle}>
      Descrição dos dados pessoais coletados ao utilizar o Aplicativo:
    </h2>

    <p className={classes.noMargin}>
      <strong>Categorias de dados pessoais: </strong>Dados de Utilização
    </p>
    <p>
      <strong>Descrição: </strong>Estes são os dados pessoais coletados
      sobre você quando acessa e/ou usa o Aplicativo, ou seja:
    </p>

    <ul className={classes.doubleMargin}>
      <li>Informação sobre o seu tipo conta;</li>
      <li>IP (sendo os últimos 4 dígitos ignorados para remover precisão);</li>
      <li>
        Informação sobre as suas interações com o Aplicativo, tais como as
        suas pesquisas (incluindo a data e a hora de quaisquer solicitações
        que efetuar) e histórico de navegação;
      </li>
      <li>Métricas de performance do SDK;</li>
      <li>Resolução da tela;</li>
      <li>Foto de Perfil;</li>
      <li className={classes.singleMargin}>Apps instalados;</li>
      
      <li className={[classes.singleMargin, classes.noMarker].join(' ')}>
        Alguns dados pessoais técnicos, que podem incluir:
      </li>
      
      <li>Informação de URL;</li>
      <li>Identificadores online, incluindo dados de cookies e endereços IP;</li>
      <li>
        Informação sobre os tipos de dispositivos que utiliza, como IDs de
        dispositivos exclusivos, tipo de conexão de rede (por exemplo, wifi,
        3G, LTE, Bluetooth), prestador, desempenho da rede e do dispositivo,
        tipo de navegador, idioma, informações para gerenciamento de
        direitos digitais, sistema operacional e versão do Aplicativo;
      </li>
      <li>
        Atributos de dispositivos na sua rede wifi disponíveis para se
        conectar ao Aplicativo;
      </li>
      <li>
        A sua localização pode ser detectada ou inferida a partir de certos
        dados técnicos (por exemplo, seu endereço IP, configuração de idioma
        do seu dispositivo ou moeda de pagamento).
      </li>
    </ul>

    <h2 className={classes.subtitle}>
      Descrição dos dados pessoais que você optou por nos fornecer e que nos
      permitem fornecer recursos/funcionalidade adicionais no contexto do
      Aplicativo:
    </h2>

    <p className={classes.noMargin}>
      <strong>Categorias de dados pessoais: </strong>Dados de Pesquisa
    </p>
    <p className={classes.doubleMargin}>
      <strong>Descrição: </strong>Quando preencher um formulário, responder
      a uma pesquisa ou questionário, recolhemos os dados pessoais que você
      autorizar.
    </p>

    <h2 className={classes.subtitle}>
      Descrição dos dados pessoais coletados com terceiros:
    </h2>

    <p className={classes.noMargin}>
      <strong>Categorias de dados pessoais: </strong>Parceiros de serviços
      técnicos
    </p>
    <p className={classes.doubleMargin}>
      <strong>Descrição: </strong>Trabalhamos com parceiros de serviços
      técnicos para medir e acompanhar o uso dos usuários no sistema e para
      entender o comportamento do usuário perante a ferramenta, que nos
      permitem fornecer o Aplicativo com suas funcionalidades.
    </p>
  </section>
);
