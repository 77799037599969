import React from 'react';
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale';
import { VictoryBar, VictoryLine, } from 'victory';
import { colors } from '../../../../../Theme/colors';
import { ChartData, ChartType } from './types';

export const hasValidData = (data: ChartData[], type: ChartType) => {
	return (
		(data.length > 0 && type !== ChartType.LINE) ||
		(data.length > 1 && type === ChartType.LINE)
	);
}

export const hasOneEntryForLineChart = (data: ChartData[], type: ChartType) => {
	return (data.length === 1 && type === ChartType.LINE);
}

export const formatChartCaption = (datetime: Date) => {
	const pattern = "LLL/yyyy - 'SEMANA' ww";
	return `${format(datetime, pattern, { locale: ptBR })}`;
}

export const VictoryBarStyle = {
	data: { fill: colors.black_900 },
	labels: { fill: "white", fontSize: 6, fontFamily: 'Open Sans' }
}

export const VictoryLineStyle = {
	data: { stroke: colors.black_900, },
	labels: {
		fill: colors.black_400,
		fontSize: 7,
		fontFamily: 'Open Sans',
		padding: 5,
	},
}

export const victoryAxisStyle = {
	axis: { stroke: "#BDC7D3" },
	tickLabels: {
		fontSize: 8,
		fontWeight: 400,
		fontFamily: 'Open Sans',
		fill: '#A3ABBD',
		padding: 6,
	},
	labels: {
		padding: 10,
	},
	axisLabel: { fontsize: 23 },
};

export const victoryDependentAxisStyle = {
	axis: { stroke: "#fff" },
	grid: { stroke: '#E1E5EB' },
	tickLabels: {
		fontSize: 8,
		fontWeight: 400,
		fontFamily: 'Open Sans',
		fill: '#A3ABBD',
		padding: 6,
	}
};

export const reduceChartComponent = (data: ChartData[], type: ChartType) => {
	switch (type) {
		default:
		case ChartType.BAR:
			return (
				<VictoryBar
					data= { data }
					x = "x"
					y = "y"
					barRatio = { 1}
					barWidth = { 15}
					style = { VictoryBarStyle }
				/>
			);
		case ChartType.LINE:
			return (
				<VictoryLine
					data= { data }
					x = "x"
					y = "y"
					style = { VictoryLineStyle }
				/>
			);
  }
}
