import React from 'react';
import WelcomePage from '../../../../../components/WelcomePage';
import { 
  AND, 
  CLICK_ON, 
  NEW_PROMOTIONAL_CALENDAR,
  FILL_PROMOTIONAL_CALENDAR_PERIODS,
} from '../../../../../core/constants/dictionary';

type Props = {
  onClickButton: Function;
}

const EmptyPage = ({ onClickButton }: Props) => {
  return (
    <WelcomePage
      textButton={NEW_PROMOTIONAL_CALENDAR}
      handleButton={onClickButton}
    >
      <span>
        {CLICK_ON} <strong>{NEW_PROMOTIONAL_CALENDAR}</strong> {AND} {FILL_PROMOTIONAL_CALENDAR_PERIODS}
      </span>
    </WelcomePage>
  );
}

export default EmptyPage;
