import React, { useEffect, useState, ChangeEvent } from 'react';
import { Grid, TextField } from '@material-ui/core';
import LoginModal from '../../../../containers/LoginModal';
import Autocomplete from '../../../../components/Autocomplete';
import { UserInfo } from '../../../Users/utils';
import { serializeCpfAndNameSearch } from '../../../../core/utils';
import { getAllStoreManagersByStoreId } from '../../../../core/services/users/usersService';
import { mainStore } from '../../../../core/store/mainStore';
import { useDebounce } from '../../../../core/hooks';
import * as dictionary from '../../../../core/constants/dictionary';
import { appendLoggedStoreManager } from '../../utils';
import classes from './LoginModal.module.scss';

type Props = {
  isOpen: boolean,
  title: string;
  onReloadHandler?: Function;
  onCloseModalHandler: Function;
  onSubmitFormHandler: Function;
};

const PromoterLoginModal = ({ 
  isOpen, 
  title, 
  onReloadHandler,
  onCloseModalHandler,
  onSubmitFormHandler,
}: Props) => {
  const [password, setPassword] = useState<string>("");
  const [storeManagers, setStoreManagers] = useState<UserInfo[]>([]);
  const [selectedStoreManager, setSelectedStoreManager] = useState<UserInfo>();
  const [noOptionsText, setNoOptionsText] = useState(dictionary.TYPE_A_NAME_OR_CPF);
  const [hasErrorOnLogin, setHasErrorOnLogin] = useState(false);
  const isButtonDisabled = (!selectedStoreManager || password.length === 0);
  
  useEffect(() => {
    setPassword("");
    setSelectedStoreManager(undefined);
  }, [isOpen]);

  const debouncedOnSearch = useDebounce(async (query?: string[]) => {
    const queryString = String(query);
    if(queryString === "") {
      setStoreManagers([]);
      setNoOptionsText(dictionary.TYPE_A_NAME_OR_CPF);
      return;
    }

    try {
      const { items } = await getAllStoreManagersByStoreId({
        limit: 100,
        query: serializeCpfAndNameSearch(queryString),
        storeId: mainStore?.loggedUser.storeId ?? '',
      });
      const itemsWithLoggedUser = appendLoggedStoreManager(items, queryString);
      itemsWithLoggedUser.sort((a, b) => a.name > b.name ? 1 : -1 );
      setStoreManagers(itemsWithLoggedUser);

      if (itemsWithLoggedUser.length === 0) {
        setNoOptionsText("");
      }
    }
    catch (err) {}
  });

  const onReleaseAccessHandler = async () => {
    try {
      const result = await onSubmitFormHandler(selectedStoreManager?.cpf, password);
      setHasErrorOnLogin(!result);
    }
    catch (err) {
      setHasErrorOnLogin(true);
    }
  }

  return (
    <LoginModal
      isOpen={isOpen}
      title={title ?? dictionary.RELEASE_PROMOTER_ACCESS}
      labels={{ submitButton: dictionary.LIBERATE_ACCESS }}
      isSubmitButtonDisabled={!!isButtonDisabled}
      onCloseModalHandler={() => {
        onReloadHandler && onReloadHandler();
        onCloseModalHandler();
      }}
      onSubmitFormHandler={onReleaseAccessHandler}
    >
      <div className={classes.Modal}>
        <Grid item className={classes.Input}>
          <Autocomplete
            size="small"
            id="cpf-store-manager"
            options={storeManagers}
            value={selectedStoreManager}
            noOptionsText={noOptionsText}
            getOptionLabel={(option: UserInfo) => `${option.name} (${option.cpf})`}
            filterOptions={(options: UserInfo[], state: any) => options }
            renderInput={(params: any) => (
              <TextField 
                variant="outlined" 
                error={hasErrorOnLogin}
                label={dictionary.STORE_MANAGER_CPF} 
                helperText={hasErrorOnLogin && dictionary.INVALID_USER_OR_PASSWORD}
                {...params} 
              />
            )}
            onChange={(_: never, value: any) => {
              if (hasErrorOnLogin) {
                setHasErrorOnLogin(false);
              }
              setSelectedStoreManager(value);
            }}
            onInputChange={(event: ChangeEvent<HTMLInputElement>) => debouncedOnSearch(event.target.value)}
            style={{width: '100%'}}
          />
        </Grid>
        <Grid item className={classes.Input}>
          <TextField 
            fullWidth
            size="small"
            type="password"
            variant="outlined"
            value={password}
            label={dictionary.PASSWORD} 
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setPassword(event.target.value)
            }}
          />
        </Grid>
      </div>
    </LoginModal>
  )
}

export default PromoterLoginModal;
