import * as dictionary from '../../../../../../../core/constants/dictionary';
import { formatWithoutTimezone } from '../../../../../../../core/constants/dates';
import { GetProductParams } from '../../../../../../../core/services/familyProduct/types';
import {
  ProductEntity,
  ProductFamilyEntity,
  SuppliersEntity,
} from '../../../../../../../core/types/promotional-spot/product-family-entity.type';
import { PromotionalNegotiationExecutionStatusEnum } from '../../../../types/promotional-negotiation-status-enum';
import { PromotionalNegotiationExecutionInConflictQueryDto } from './types';
import { PromotionalNegotiationStatusLabel } from '../../../../../../../core/types/promotional-spot/promotional-negotiation-execution-dto';

export const parseProductsFromFamily = (
  families: ProductFamilyEntity[] = []
): ProductEntity[] => {
  let productsInFamilies: ProductEntity[] = [];
  for (const item of families) {
    for (const productItem of item.products) {
      productsInFamilies.push({
        ean: productItem.ean,
        id: productItem.id,
        name: productItem.name,
        internalCode: productItem.internalCode,
      });
    }
  }
  return removeProductsDuplicated(productsInFamilies);
};

export const removeProductsDuplicated = (
  products: ProductEntity[] = []
): ProductEntity[] => {
  const data = new Map<string, ProductEntity>();
  for (const product of products) {
    data.set(product.id, product);
  }

  return Array.from(data.values());
};

export const parseProductQueryParamsFromText = (
  query: string = ''
): GetProductParams => {
  const regex = new RegExp('^[0-9]+$');
  let result: GetProductParams = {};
  if (regex.test(query)) {
    result.internalCode = query;
  } else {
    result.q = query;
  }
  return result;
};

export const parseSupplierLabel = (supplier: SuppliersEntity) =>
  `(${supplier.internalCode}) ${supplier.name}`;

export const parseProductLabel = (product: ProductEntity) =>
  `(${product.internalCode}) ${product.name}`;

export const groupStoresByExecution = (
  executions: PromotionalNegotiationExecutionInConflictQueryDto[] = []
): Map<string, string[]> => {
  const groupByDate = new Map<string, string[]>();

  for (const iterator of executions) {
    const key = `${iterator.negotiation.startAt};${iterator.negotiation.endAt}`;
    let currentDates: string[] | undefined = [
      `(${iterator.addressing.store.code}) ${iterator.addressing.store.name}`,
    ];

    if (groupByDate.has(key)) {
      currentDates = currentDates.concat(groupByDate.get(key) ?? []);
    }

    groupByDate.set(key, currentDates);
  }

  return groupByDate;
};

export const parseLabelForConflict = (data: string) => {
  const [start, end] = data
    .split(';')
    .map((item) => new Date(item))
    .map((item) => formatWithoutTimezone(item));

  return `${start} à ${end}`;
};

export const familiesToRemove = (
  oldFamilies: ProductFamilyEntity[],
  newFamilies: ProductFamilyEntity[]
) => {
  let familiesToRemove: ProductFamilyEntity[] = [];

  for (const family of oldFamilies) {
    if (!newFamilies.find((item) => item.code === family.code)) {
      familiesToRemove.push(family);
    }
  }

  return familiesToRemove;
};

export const parseExecutionStatus = (
  isExpired: boolean,
  status: PromotionalNegotiationExecutionStatusEnum
) =>
  isExpired && status === PromotionalNegotiationExecutionStatusEnum.PENDING
    ? dictionary.EXPIRED_NEGOTIATIONS
    : PromotionalNegotiationStatusLabel[
        PromotionalNegotiationExecutionStatusEnum[
          status
        ] as keyof typeof PromotionalNegotiationStatusLabel
      ];
