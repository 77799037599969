import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { view } from 'react-easy-state';
import { History } from 'history';
import Loading from '../../../components/Loading';
import PageContent from '../../../components/PageContent';
import StickyFooter from '../../../components/stickyFooter/stickyFooter';
import ModalSmallContainer from '../../../components/Modal/SmallContainer';
import { CancelButton, DefaultButton } from '../../../components/Buttons';
import { useDebounce } from '../../../core/hooks';
import { entityEmpty } from '../../../core/validation';
import { getPromotionalCalendars } from '../../../core/services/promotionalSpots/promotionalCalendarsService';
import {
  getPromotionalSpots,
  removePromotionalSpot,
} from '../../../core/services/promotionalSpots/promotionalSpotsServices';
import { PromotionalSpotEntity } from '../../../core/types/promotional-spot/promotional-spot-entity';
import { PromotionalCalendarDto } from '../../../core/types/promotional-spot/promotional-calendar.dto';
import * as routes from '../../../core/constants/routes';
import * as dictionary from '../../../core/constants/dictionary';
import {
  PromotionalSpotsTable,
  EditPromotionalSpotModal,
  CreatePromotionalSpotModal,
  RemovePromotionalSpotModal,
  CreatePromotionalSpotPage,
  CreatePromotionalCalendarPage,
} from './components';
import { emptyPromotionalSpot } from './utils';
import { useRetailList } from '../../../hooks/RetailHooks';
import { profileCheck } from '../../../core/profiles';
import { RetailEntityType } from '../../../core/types/retail/retail-entity.type';
import { Button, Grid, TextField } from '@material-ui/core';
import { FormRetailField } from '../../../components/Form/FormRetailField';
import Autocomplete from '../../../components/Autocomplete';
import { globalTypeOptions } from '../common/globalTypeOptions';
import { AutocompleteRenderInputParams } from '@material-ui/lab';
import { translatePromotionalSpotTypeToAPI } from '../common/utils';
import { useSnackBar } from '../../../context/Snackbars';
import FormFilterCollapse from '../common/components/FormFilterCollapse';

type Props = {
  history: History;
};

const PromotionalSpots = ({ history }: Props) => {
  const [calendars, setCalendars] = useState<PromotionalCalendarDto[]>([]);
  const [promotionalSpots, setPromotionalSpots] = useState<
    PromotionalSpotEntity[]
  >([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState<string>('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalType, setModalType] = useState([]);
  const [toolbarInputFocus, setToolbarInputFocus] = useState(false);
  const [selectedRetail, setSelectedRetail] = useState<RetailEntityType>();
  const [currentPromotionalSpot, setCurrentPromotionalSpot] =useState(emptyPromotionalSpot);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [hasInitialContent, setHasInitialContent] = useState(false);
  const {
    retailInUser,
    fetch: fetchRetails,
    retails: retailsOptions,
    isLoading: isLoadingRetails,
  } = useRetailList();
  const { openSnackBar } = useSnackBar();
  const isSuperAdmin = profileCheck.isSuperAdmin();
  const [type, setType] = useState<string | undefined>(undefined);
  const [calendar, setCalendar] = useState<
    PromotionalCalendarDto | undefined
  >();

  const currentRetailId = useMemo(
    () => retailInUser || selectedRetail?.id,
    [selectedRetail?.id, retailInUser]
  );

  useEffect(() => {
    if (promotionalSpots.length > 0 && !hasInitialContent) {
      setHasInitialContent(true);
    }
  }, [promotionalSpots, hasInitialContent]);

  const fetchInitialData = useCallback(async () => {
    if (!isLoading) {
      try {
        setIsLoading(true);
        const { data: periods } = await getPromotionalCalendars({
          offset: 0,
          limit: 1000,
          retailId: currentRetailId,
        });
        setCalendars(periods.items);

        const { data } = await getPromotionalSpots({
          retailId: currentRetailId,
        });
        setTotal(data.total);
        setPromotionalSpots(data.items);
      } catch (err) {
        openSnackBar(dictionary.ERROR, dictionary.UNKNOWN_ERROR);
      } finally {
        setIsLoading(false);
      }
    }
  }, [isLoading, currentRetailId]);

  const fetchData = useCallback(async () => {
    if (!isLoading) {
      try {
        setIsLoading(true);
        const limit = rowsPerPage;
        const offset = page * rowsPerPage;
        const { data } = await getPromotionalSpots({
          limit,
          offset,
          name: searchText,
          type: type ? translatePromotionalSpotTypeToAPI(type) : undefined,
          retailId: currentRetailId,
          calendarId: calendar?.id,
        });
        setTotal(data.total);
        setPromotionalSpots(data.items);
      } catch (error) {
        openSnackBar(dictionary.ERROR, dictionary.UNKNOWN_ERROR);
      } finally {
        setIsLoading(false);
      }
    }
  }, [
    type,
    page,
    calendar,
    isLoading,
    searchText,
    rowsPerPage,
    currentRetailId,
  ]);

  const onDeleteHandler = async () => {
    try {
      await removePromotionalSpot(currentPromotionalSpot.id);
      setIsOpenModal(false);
      openSnackBar(
        dictionary.SUCCESS,
        dictionary.DELETE_PROMOTIONAL_SPOT_SUCCESS
      );
      fetchData();
    } catch (e) {
      openSnackBar(dictionary.ERROR, dictionary.DELETE_PROMOTIONAL_SPOT_ERROR);
    }
  };

  const closeModal = useCallback(() => {
    setIsOpenModal(false);
    setModalType([]);
    setCurrentPromotionalSpot(emptyPromotionalSpot);
  }, []);

  const createModal = useMemo(() => {
    return (
      <ModalSmallContainer isOpen={isOpenModal} closeModalCallback={closeModal}>
        <CreatePromotionalSpotModal
          calendars={calendars}
          onClose={() => closeModal()}
          onCreate={() => {
            closeModal();
            fetchData();
          }}
        />
      </ModalSmallContainer>
    );
  }, [calendars, isOpenModal, closeModal]);

  const editModal = useMemo(() => {
    return (
      currentPromotionalSpot && (
        <ModalSmallContainer
          isOpen={isOpenModal}
          closeModalCallback={closeModal}
        >
          <EditPromotionalSpotModal
            data={currentPromotionalSpot}
            calendars={calendars}
            onClose={() => closeModal()}
            onEdit={() => {
              closeModal();
              fetchData();
            }}
          />
        </ModalSmallContainer>
      )
    );
  }, [isOpenModal, currentPromotionalSpot, closeModal]);

  const deleteModal = useMemo(() => {
    return (
      <ModalSmallContainer isOpen={isOpenModal} closeModalCallback={closeModal}>
        <RemovePromotionalSpotModal
          onClose={closeModal}
          onDelete={onDeleteHandler}
        />
      </ModalSmallContainer>
    );
  }, [isOpenModal, closeModal]);

  const renderModal = useMemo(() => {
    const modals = {
      [dictionary.CREATE]: createModal,
      [dictionary.EDIT]: editModal,
      [dictionary.DELETE]: deleteModal,
    };
    // @ts-ignore
    return modals[modalType];
  }, [createModal, editModal, deleteModal, modalType]);

  const isOpenModalHandler = useCallback(
    (modalType, currentPromotionalSpot?) => {
      setModalType(modalType);
      setIsOpenModal(true);
      setCurrentPromotionalSpot(currentPromotionalSpot);
    },
    []
  );

  const debouncedOnSearch = useDebounce((value: string[]) => {
    const inputValue = String(value);
    setSearchText(inputValue);
    setToolbarInputFocus(true);
  }, 500);

  const StickyFooterButton = useMemo(
    () => (
      <div className="button-create-container">
        <DefaultButton
          label={dictionary.NEW_PROMOTIONAL_SPOT}
          onClick={() => isOpenModalHandler(dictionary.CREATE)}
          style={{ width: 'auto' }}
        />
      </div>
    ),
    [isOpenModalHandler]
  );

  const determinePageContent = useCallback(() => {
    if (calendars?.length === 0) {
      return (
        <CreatePromotionalCalendarPage
          onClickButtonHandler={() =>
            history.push(routes.CREATE_PROMOTIONAL_CALENDAR)
          }
        />
      );
    }
    if (promotionalSpots?.length === 0 && !hasInitialContent) {
      return (
        <CreatePromotionalSpotPage
          onClickButtonHandler={() => isOpenModalHandler(dictionary.CREATE)}
        />
      );
    }

    return (
      <>
        <PromotionalSpotsTable
          page={page}
          total={total}
          data={promotionalSpots}
          rowsPerPage={rowsPerPage}
          searchTextValue={searchText}
          toolbarInputFocus={toolbarInputFocus}
          handleOpenModal={isOpenModalHandler}
          onChangePageHandler={(page: number) => setPage(page)}
          onChangeSearchHandler={(value: string[]) => debouncedOnSearch(value)}
          onChangeRowsPerPageHandler={(rowsPerPage: number) => {
            setPage(0);
            setRowsPerPage(rowsPerPage);
            setToolbarInputFocus(false);
          }}
        />
        <StickyFooter actionButton={StickyFooterButton} />
      </>
    );
  }, [
    calendars,
    promotionalSpots,
    selectedRetail,
    type,
    rowsPerPage,
    page,
    currentRetailId,
    isLoading,
  ]);

  const content = useMemo(() => {
    return entityEmpty(promotionalSpots) ? '' : determinePageContent();
  }, [determinePageContent, promotionalSpots]);

  useEffect(() => {
    if (isSuperAdmin && !retailInUser) {
      fetchRetails();
    }
  }, [isSuperAdmin, retailInUser]);

  useEffect(() => {
    fetchInitialData();
  }, [currentRetailId]);

  useEffect(() => {
    fetchData();
  }, [page, searchText, rowsPerPage]);

  return (
    <>
      <PageContent title={dictionary.PROMOTIONAL_SPOTS}>
        <FormFilterCollapse
          actionPosition="grid"
          buttons={
            <>
              <Button
                disabled={isLoading}
                disableElevation
                color="primary"
                variant="contained"
                onClick={fetchData}
              >
                {dictionary.SEARCH}
              </Button>
              <CancelButton
                label={dictionary.CLEAR}
                onClick={() => {
                  setSelectedRetail(undefined);
                  setType(undefined);
                  setCalendar(undefined);
                  setSearchText('');
                  fetchInitialData();
                }}
              />
            </>
          }
        >
          {isSuperAdmin && !retailInUser && (
            <Grid item sm={6} md={3}>
              <FormRetailField
                disabled={isLoadingRetails}
                value={selectedRetail}
                options={retailsOptions ?? []}
                onChange={setSelectedRetail}
              />
            </Grid>
          )}
          <Grid item sm={6} md={3}>
            <Autocomplete
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  {...params}
                  label={dictionary.PROMOTIONAL_SPOT_TYPE}
                  variant="outlined"
                />
              )}
              disabled={isLoading}
              id="type"
              size="small"
              value={type}
              key={type}
              required={false}
              options={globalTypeOptions}
              getOptionLabel={(option: string) => option}
              onChange={(_: any, value: string) => {
                setType(value);
              }}
              style={{}}
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <Autocomplete
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  {...params}
                  label={dictionary.CALENDAR}
                  variant="outlined"
                />
              )}
              disabled={isLoading}
              id="calendar"
              size="small"
              value={calendar}
              key={calendar?.id}
              required={false}
              options={calendars}
              getOptionLabel={(option: PromotionalCalendarDto) => option.name}
              onChange={(_: any, value: PromotionalCalendarDto) => {
                setCalendar(value);
              }}
              style={{}}
            />
          </Grid>
        </FormFilterCollapse>
        {content}
        {isOpenModal && renderModal}
        <Loading isOpen={isLoading} />
      </PageContent>
    </>
  );
};

export default view(PromotionalSpots);
