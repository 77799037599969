import { Button } from '@material-ui/core';
import React from 'react';
import classes from './ButtonDowload.module.scss';
type Props = {
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  label: string;
};
const ButtonDownload = ({ label, disabled, onClick }: Props) => {
  return (
    <div className={classes.buttonContainer}>
      <Button
        className={classes.buttonDownload}
        disableElevation
        color="primary"
        disabled={disabled}
        variant="contained"
        onClick={onClick}
      >
        {label}
      </Button>
    </div>
  );
};

export default ButtonDownload;
