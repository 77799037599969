import { CSSProperties } from "react";

type StyleSheet = {
  [key: string]: CSSProperties;
}

export const styles: StyleSheet = {
  autocomplete: { 
    width: '93%',
    marginBottom: '17px',
  },
  defaultButton: {
    width: 'auto',
  },
  dialog: {
    width: 'auto',
    margin: 'auto',
    padding: 'auto'
  },
}
