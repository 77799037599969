import React from 'react';
import classes from '../styles.module.scss';

export default () => (
  <section className={classes.section}>
    <h2 className={classes.title}>
      PARA QUE FINS UTILIZAMOS OS SEUS DADOS PESSOAIS?
    </h2>

    <p className={classes.doubleMargin}>
      Apresentamos na lista abaixo os motivos pelos quais processamos os
      seus dados pessoais, os fundamentos legais em que nos baseamos para
      podermos processar de forma legal os dados pessoais e as categorias de
      dados pessoais (identificadas no item acima, usados para tais fins:
    </p>

    <h2 className={classes.subtitle}>
      Descrição dos motivos pelos quais a Involves processa os seus dados
      pessoais (‘finalidade do processamento‘):
    </h2>

    <p className={classes.noMargin}>
      <strong>Prestar e personalizar o Involves Doors;</strong>
    </p>

    <ul>
      <li>
        <span>
          Categorias de dados pessoais utilizadas pela Involves para a
          finalidade do processamento:
        </span>
      </li>
      <li>Dados de Usuário;</li>
      <li>Dados de Utilização;</li>
      <li>Dados de Pesquisa;</li>
      <li>
        <span>Base Legal para a finalidade do processamento:</span>
      </li>
      <li>Execução de um Contrato;</li>
      <li>Interesse Legítimo;</li>
    </ul>

    <p className={classes.noMargin}>
      <strong>
        Entender, diagnosticar, solucionar e corrigir problemas do Involves
        Doors;
      </strong>
    </p>

    <ul>
      <li>
        <span>
          Categorias de dados pessoais utilizadas pela Involves para a
          finalidade do processamento:
        </span>
      </li>
      <li>Dados de Usuário;</li>
      <li>Dados de Utilização;</li>
      <li>Dados de Pesquisa;</li>
      <li>
        <span>Base Legal para a finalidade do processamento:</span>
      </li>
      <li>Execução de um Contrato;</li>
      <li>Interesse Legítimo;</li>
    </ul>

    <p className={classes.noMargin}>
      <strong>
        Avaliar e desenvolver novas funcionalidades, tecnologias e melhorias
        para o Involves Doors;
      </strong>
    </p>

    <ul>
      <li>
        <span>
          Categorias de dados pessoais utilizadas pela Involves para a
          finalidade do processamento:
        </span>
      </li>
      <li>Dados de Usuário;</li>
      <li>Dados de Utilização;</li>
      <li>Dados de Pesquisa;</li>
      <li>
        <span>Base Legal para a finalidade do processamento:</span>
      </li>
      <li>Interesse Legítimo;</li>
    </ul>

    <p className={classes.noMargin}>
      <strong>
        Cumprir as obrigações legais e os pedidos de observância da lei;
      </strong>
    </p>

    <ul>
      <li>
        <span>
          Categorias de dados pessoais utilizadas pela Involves para a
          finalidade do processamento:
        </span>
      </li>
      <li>Dados de Usuário;</li>
      <li>Dados de Utilização;</li>
      <li>Dados de Pesquisa;</li>
      <li>
        <span>Base Legal para a finalidade do processamento:</span>
      </li>
      <li>Cumprimento de Obrigações Legais;</li>
      <li>Interesse Legítimo;</li>
    </ul>

    <p className={classes.noMargin}>
      <strong>Cumprir obrigações contratuais com terceiros;</strong>
    </p>

    <ul>
      <li>
        <span>
          Categorias de dados pessoais utilizadas pela Involves para a
          finalidade do processamento:
        </span>
      </li>
      <li>Dados de Usuário;</li>
      <li>Dados de Utilização;</li>
      <li>Dados de Pesquisa;</li>
      <li>
        <span>Base Legal para a finalidade do processamento:</span>
      </li>
      <li>Interesse Legítimo;</li>
    </ul>

    <p className={classes.noMargin}>
      <strong>Efetuar, exercer ou defender ações judiciais;</strong>
    </p>

    <ul>
      <li>
        <span>
          Categorias de dados pessoais utilizadas pela Involves para a
          finalidade do processamento:
        </span>
      </li>
      <li>Dados de Usuário;</li>
      <li>Dados de Utilização;</li>
      <li>Dados de Pesquisa;</li>
      <li>
        <span>Base Legal para a finalidade do processamento:</span>
      </li>
      <li>Interesse Legítimo;</li>
    </ul>

    <p className={classes.noMargin}>
      <strong>
        Conduzir o planejamento de negócios, relatórios e previsões;
      </strong>
    </p>

    <ul>
      <li>
        <span>
          Categorias de dados pessoais utilizadas pela Involves para a
          finalidade do processamento:
        </span>
      </li>
      <li>Dados de Usuário;</li>
      <li>Dados de Utilização;</li>
      <li>Dados de Pesquisa;</li>
      <li>
        <span>Base Legal para a finalidade do processamento:</span>
      </li>
      <li>Interesse Legítimo;</li>
    </ul>

    <p className={classes.noMargin}>
      <strong>
        Detectar fraudes, incluindo pagamentos fraudulentos e uso
        fraudulento do Involves Doors;
      </strong>
    </p>

    <ul>
      <li>
        <span>
          Categorias de dados pessoais utilizadas pela Involves para a
          finalidade do processamento:
        </span>
      </li>
      <li>Dados de Usuário;</li>
      <li>Dados de Utilização;</li>
      <li>Dados de Pesquisa;</li>
      <li>
        <span>Base Legal para a finalidade do processamento:</span>
      </li>
      <li>Execução de um Contrato;</li>
      <li>Cumprimento de Obrigações Legais;</li>
      <li>Interesse Legítimo;</li>
    </ul>

    <p className={classes.noMargin}>
      <strong>Conduzir pesquisas e estudos;</strong>
    </p>

    <ul>
      <li>
        <span>
          Categorias de dados pessoais utilizadas pela Involves para a
          finalidade do processamento:
        </span>
      </li>
      <li>Dados de Usuário;</li>
      <li>Dados de Pesquisa;</li>
      <li>
        <span>Base Legal para a finalidade do processamento:</span>
      </li>
      <li>Interesse Legítimo;</li>
      <li>Consentimento ;</li>
    </ul>
  </section>
);

    