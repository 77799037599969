import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Button, Collapse, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import FilterListIcon from '@material-ui/icons/FilterList';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import DefaultButton from '../../../../components/Buttons/Default';
import { mainStore } from '../../../../core/store/mainStore';
import { getStore } from '../../../../core/services/stores/storesService';
import { useDebounce } from '../../../../core/hooks';
import { ACTIVE } from '../../../../core/constants/status';
import * as dictionary from '../../../../core/constants/dictionary';
import { isRetailAdmin } from './utils';
import * as styles from './styles';
import { getAgreement } from '../../../../core/services/agreements/agreementsService';
import { useRetailList } from '../../../../hooks/RetailHooks';
import { RetailEntityType } from '../../../../core/types/retail/retail-entity.type';
import { profileCheck } from '../../../../core/profiles';
import { FormRetailField } from '../../../../components/Form/FormRetailField';

type Props = {
  initialFilters: any;
  onSubmit: Function;
};

const Filter = ({ initialFilters, onSubmit }: Props) => {
  const isSuperAdmin = profileCheck.isSuperAdmin();
  const [filters, setFilters] = useState(initialFilters);
  const [stores, setStores] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedStores, setSelectedStores] = useState<any[]>([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [isFilterExpanded, setIsFilterExpanded] = useState(true);
  const [retail, setRetail] = useState<RetailEntityType>();
  const {
    fetch: fetchRetails,
    isLoading: isLoadingRetails,
    retailInUser,
    retails,
  } = useRetailList();

  const currentRetail = useMemo(
    () => retailInUser ?? retail?.id,
    [retailInUser, retail]
  );

  const fetchStores = useCallback(async () => {
    if (currentRetail) {
      const data = await getStore(currentRetail, ACTIVE);
      setStores(data);
    }
  }, [currentRetail]);

  const onClickSearchButtonHandler = useCallback(() => {
    onSubmit({ ...filters, retailId: [currentRetail] });
  }, [filters, currentRetail]);

  const onClickResetButtonHandler = () => {
    setSelectedStores([]);
    setSelectedSuppliers([]);
    setFilters(initialFilters);
    onSubmit(initialFilters);
  };

  const onFilterExpandedHandler = () => {
    setIsFilterExpanded((prevState) => !prevState);
  };

  const onSetSuppliersHandler = (selectedSuppliers: any) => {
    const supplierId = selectedSuppliers.map((supplier: any) => supplier.id);
    setFilters({
      ...filters,
      supplierId,
    });
    setSelectedSuppliers(selectedSuppliers);
  };

  const onSetStoresHandler = (selectedStores: any) => {
    const storeId = selectedStores.map((store: any) => store.id);
    setFilters({
      ...filters,
      storeId,
    });
    setSelectedStores(selectedStores);
  };

  const fetchSuppliers = async (query = null) => {
    const data = await getAgreement(
      mainStore.loggedUser.retailId,
      ACTIVE,
      0,
      10,
      query
    );
    setSuppliers(data.items);
  };

  const debounceOnInputChange = useDebounce(fetchSuppliers, 200);

  useEffect(() => {
    if (!retailInUser && isSuperAdmin) {
      fetchRetails();
    }
  }, [retailInUser, isSuperAdmin]);

  useEffect(() => {
    if (isSuperAdmin || isRetailAdmin()) {
      fetchStores();
    } else {
      const { store: storeInUser } = mainStore.loggedUser;
      if (storeInUser) {
        setSelectedStores([() => [storeInUser]]);
      }
    }

    fetchSuppliers();
  }, [currentRetail, isSuperAdmin]);

  return (
    <Grid container>
      <Grid container item>
        <Button
          size="large"
          disableElevation
          startIcon={<FilterListIcon />}
          onClick={onFilterExpandedHandler}
        >
          {dictionary.FILTERS}
        </Button>
      </Grid>
      <Collapse in={isFilterExpanded} style={styles.collapse}>
        <Grid container item spacing={2} alignItems="center">
          {(isSuperAdmin || isRetailAdmin()) && (
            <>
              <Grid item md={4} sm={6} xs={12}>
                <FormRetailField
                  disabled={isLoadingRetails}
                  value={retail}
                  options={retails ?? []}
                  onChange={setRetail}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Autocomplete
                  multiple
                  id="store"
                  size="small"
                  options={stores}
                  value={selectedStores}
                  getOptionLabel={(option: any) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={dictionary.STORE}
                      variant="outlined"
                    />
                  )}
                  onChange={(_, stores) => {
                    if (stores) {
                      onSetStoresHandler(stores);
                    }
                  }}
                />
              </Grid>
            </>
          )}
          <Grid item md={4} sm={6} xs={12}>
            <Autocomplete
              multiple
              id="supplier"
              size="small"
              options={suppliers}
              value={selectedSuppliers}
              getOptionLabel={(option: any) => option.supplierNames}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={dictionary.SUPPLIER}
                  variant="outlined"
                />
              )}
              onChange={(_, supplier) => {
                if (supplier) {
                  onSetSuppliersHandler(supplier);
                }
              }}
              onInputChange={(e, query) => debounceOnInputChange(query)}
              getOptionSelected={(a: any, b: any) => {
                return a.id === b.id;
              }}
              filterSelectedOptions
            />
          </Grid>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Grid item md={2} sm={6} xs={12}>
              <KeyboardDatePicker
                disableToolbar
                id="startDate"
                label={dictionary.DATE_START_SELECT}
                value={filters.startDate || new Date()}
                format="dd/MM/yyyy"
                variant="inline"
                maxDate={filters.endDate || new Date()}
                maxDateMessage={dictionary.MAX_START_DATE_MESSAGE}
                onChange={(date) => {
                  setFilters({
                    ...filters,
                    startDate: date,
                  });
                }}
                style={styles.fullWidth}
              />
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <KeyboardDatePicker
                id="endDate"
                disableToolbar
                label={dictionary.DATE_END_SELECT}
                value={filters.endDate || new Date()}
                format="dd/MM/yyyy"
                variant="inline"
                minDate={filters.startDate}
                maxDate={new Date()}
                minDateMessage={dictionary.MIN_END_DATE_MESSAGE}
                maxDateMessage={dictionary.MAX_END_DATE_MESSAGE}
                onChange={(date) => {
                  setFilters({
                    ...filters,
                    endDate: date,
                  });
                }}
                style={styles.fullWidth}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container item spacing={2} style={styles.buttonsGrid}>
          <Grid item>
            <DefaultButton
              label={dictionary.SEARCH}
              onClick={onClickSearchButtonHandler}
            />
          </Grid>
          <Grid item>
            <Button
              color="primary"
              disableElevation
              onClick={onClickResetButtonHandler}
            >
              {dictionary.CLEAR}
            </Button>
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
};

export default Filter;
