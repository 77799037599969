import React, { ReactNode } from 'react';
import { Grid, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info'
import Vector from '../../components/Vector';
import CancelButton from '../../components/Buttons/Cancel';
import ModalContainer from '../Modal/Container';
import { CLOSE } from '../../core/constants/dictionary';
import classes from './SuccessModal.module.scss';

type Props = {
  isOpen: boolean;
  children: ReactNode;
  width?: number; 
  notificationText?: string;
  onCloseModalHandler: Function;
};

export const SuccessModal = ({ 
  isOpen, 
  children, 
  width,
  notificationText,
  onCloseModalHandler,
}: Props) => (
  <ModalContainer
    isOpen={isOpen}
    width={width}
    closeModalCallback={onCloseModalHandler}
  >
    <Grid
      container
      direction="column"
      alignItems="center"
    >
      <Grid item>
        <Vector.CheckinDone />
      </Grid>
      { children }
      {
        notificationText && (
          <Grid 
            container 
            alignItems="center"
            justifyContent="center"
            className={classes.notificationGrid}
          >
            <InfoIcon className={classes.notificationIcon} />
            <Typography className={classes.notificationText}>
              { notificationText }
            </Typography>
          </Grid>
        )
      }
      <Grid 
        container
        direction="row"
        justifyContent="flex-end"
        className={classes.footer}
      >
        <CancelButton 
          label={CLOSE} 
          onClick={onCloseModalHandler} 
        />
      </Grid>
    </Grid>
  </ModalContainer>
);

export default SuccessModal;
