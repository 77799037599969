import API from '../api/config';
import * as routes from '../api/routes';
import { exist } from '../../validation';

export const postRetailAPI = async data => {
  const url = routes.API_RETAILS;
  const response = await API.post(url, data);
  return response;
};

export const putRetailAPI = async data => {
  const url = `${routes.API_RETAILS}/${data.id}`;
  const response = await API.put(url, data);
  return response;
};

export const getRetailAPI = async (status, offset = 0, limit = 99999, order, orderBy) => {
  const queryParams = new URLSearchParams({
    limit,
    offset,
  });
  if (status) {
    queryParams.append('status', status);
  }
  if (order) {
    queryParams.append('order', order);
  }
  if (orderBy) {
    queryParams.append('orderBy', orderBy);
  }
  const url = `${routes.API_RETAILS}?${queryParams.toString()}`;
  const response = await API.get(url);
  
  return response.data.items;
};

export const deleteRetailAPI = async retail => {
  const url = `${routes.API_RETAILS}/${retail.id}`;
  const response = await API.delete(url);
  return response;
};

export const getRetailByIdAPI = async id => {
  const url = `${routes.API_RETAILS}/${id}`;
  const response = await API.get(url, id);
  return response.data;
};