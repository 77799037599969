import { PromotionalNegotiationDto } from '../../../common/types';

export const parsePromotionalExecutionProductsToText = (
  products: PromotionalNegotiationDto['products']
) =>
  products?.map((product) => product?.product?.name).filter((item) => !!item) ??
  [];

export const parsePromotionalExecutionSuppliersToText = (
  suppliers: PromotionalNegotiationDto['suppliers']
) =>
  suppliers
    ?.map((supplier) => supplier.supplier.name)
    .filter((item) => !!item) ?? [];

export const parseNameWithDots = (names: string[]) => {
  if (!names.length) {
    return null;
  }
  const textInEnd = names.length > 1 ? `+${names.length - 1}` : '';
  return `${names[0]} ${textInEnd}`;
};
