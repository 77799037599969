export enum StoreAreaLabel {
  ENTRY_HALL = "Entrada",
  FRONT_HALL = "Corredor Frontal",
  CENTRAL_HALL = "Corredor Central",
  REAR_HALL = "Corredor Traseiro",
  BUTCHER_SHOP = "Açougue",
  PRODUCE = "Hortifruti",
  CELLAR = "Adega",
  ROTISSERIA = "Rotisseria",
  BAKERY = "Padaria",
  CHECKOUT = "Checkout",
  BAZAAR = "Bazar",
  ELECTRO = "Eletro",
  FLOWER_SHOP = "Floricultura",
  PERFUMERY =  "Perfumaria",
  DRINKS = "Bebidas",
  COLD = "Frios",
  HEALTHINESS = "Saudabilidade",
  CLEANING = "Limpeza",
  GROCERY = "Mercearia"
};
