import React, { useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import * as dictionary from '../../../../../../../core/constants/dictionary';
import { AddressingDto } from '../../../../../../../core/types/promotional-spot/promotional-addressing.dto';
import { PromotionalNegotiationExecutionDto } from '../../../../../../../core/types/promotional-spot/promotional-negotiation-execution-dto';
import ImageCard from '../ImageCard';
import TitleAndSubtitle from '../TitleAndSubtitle';

import Autocomplete from '../../../../../../../components/Autocomplete';

type Props = {
  physicalAddressing?: AddressingDto;
  execution?: PromotionalNegotiationExecutionDto;
  setRefuseReason: any;
};

type Reason = {
  label: string;
  value: string;
};

const RefuseExecutionForm = ({ physicalAddressing, execution, setRefuseReason }: Props) => {
  const [reason, setReason] = useState<Reason | undefined>();
  const options = [
    { label: 'Montagem incorreta', value: 'incorrect_assembly' },
    { label: 'Produto incorreto', value: 'incorrect_product' },
    { label: 'Imagem ilégivel', value: 'illegible_image' },
  ];

  return (
    <>
      <Grid
        direction="row"
        container
        justifyContent="space-between"
        alignItems="stretch"
        spacing={2}
      >
        <Autocomplete
          style={{width: '100%', paddingBottom:'24px'}}
          required
          size="small"
          options={options}
          getOptionLabel={(option: Reason) => option.label}
          value={reason}
          onChange={(event: any, newReason: Reason) => {
            if (newReason) {
              setReason(newReason);
              setRefuseReason(newReason.value);
            }
          }}
          renderInput={(params: Reason) => (
            <TextField
              {...params}
              label={dictionary.SELECT_REFUSE_REASON}
              variant="outlined"
            />
          )}
        />
      </Grid>
      <Grid
        direction="row"
        container
        justifyContent="space-between"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item xs>
          {physicalAddressing && (
            <TitleAndSubtitle
              title={dictionary.ADDRESSING}
              subtitle={physicalAddressing.physicalAddressing}
            />
          )}
        </Grid>

        {execution?.id && (
          <Grid item xs>
            <TitleAndSubtitle
              title={dictionary.EXECUTED_FOR}
              subtitle={execution?.solvedUser?.name ?? '--'}
            />
          </Grid>
        )}
      </Grid>

      <Grid direction="row" container justifyContent="space-around" spacing={2}>
        {execution?.image && (
          <Grid item xs>
            <ImageCard
              title={dictionary.EXECUTION_PICTURE}
              image={execution.image}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default RefuseExecutionForm;
