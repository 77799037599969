import React, { useMemo } from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import * as dictionary from '../../../core/constants/dictionary';
import { colors } from '../../../Theme/colors';

const CssButton = withStyles({
  root: {
    '&:hover': { backgroundColor: colors.black_100 },
  },
})(Button);

const useStyles = makeStyles({
  defaultStyle: {
    textTransform: 'none',
    background: colors.black_500,
    color: colors.blue_500,
    width: '100%',
    height: '40px',
  },
});

const CancelButton = ({ 
  disabled = false, 
  onClick = undefined, 
  label = "", 
  style={}
}) => {
  const classes = useStyles();

  const labelText = useMemo(() => {
    return label || dictionary.CANCEL;
  }, [label]);

  return (
    <CssButton
      style={style}
      className={classes.mainButtonStyle}
      disabled={disabled}
      onClick={onClick}
    >
      <Typography variant="subtitle1">{labelText}</Typography>
    </CssButton>
  );
};

export default CancelButton;
