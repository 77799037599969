import React from "react";
import {TimelineDot} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";

export type DotProps = {
  color: string;
}

const useStyles = (props: DotProps) => {
  return makeStyles({
    root: {
      display: 'inline-block',
      margin: 0,
      padding: 3,
      boxShadow: 'none',
      backgroundColor: props.color
    },
  });
};

const Dot = (props: DotProps) => {
    const classes = useStyles(props)();

    return (
        <TimelineDot classes={classes}/>
    );
};

export default Dot;
