import React, { useState } from 'react';
import { Formik } from 'formik';
import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import ModalSmallContainer from '../../components/Modal/SmallContainer';
import { CancelButton } from '../../components/Buttons';
import { CheckinErrorCode } from '../../core/types/visits/checkin-error-code.enum';
import { MAX_PROMOTER_WARNING_BEFORE_SUSPENSION } from '../../core/constants/visits';
import { 
  checkInVisit, 
  liberateAccessStoreManager 
} from '../../core/services/autoCheckin/autoCheckinService';
import {
  CLOSE,
  GOOD_TO_SEE_YOU,
  BACKGROUND_IMG_ALT,
  INVOLVES_DOORS_LOGO,
  ACCESS_NOT_RELEASED,
  MORE_DETAILS_ASK_TO,
  PLEASE_TRY_AGAIN_SOON,
  CANNOT_INFER_USER_STORE,
  DO_CHECK_IN_TO_CONTINUE,
  LIBERATE_PROMOTOR_ACCESS,
  CONTACT_RESPONSIBLE_PERSON,
  UNEXPECTED_ERROR_OCCURRED_ON_CHECKIN,
  AN_UNEXPECTED_ERROR_OCCURRED_AT_ACTION,
  PROMOTER_DONT_HAVE_ACCESS_TO_AGREEMENT,
} from '../../core/constants/dictionary';
import {
  LoginModal,
  SuccessModal,
  WarningModal,
  SuspensionModal,
} from './components/index';
import { useAutoCheckInToken } from './useAutoCheckInToken';
import { FormVisitsAutoCheckin } from './FormVisitsAutoCheckin';
import { intialFormValues } from './utils';
import doorsLogo from '../../assets/logos/full-logo-white.svg';
import checkOutFail from '../../assets/general/checkOutFail.svg';
import backgroundImage from '../../assets/general/auto-checkin-background.png';
import {
  styles,
  StyledRow,
  StyledLogo,
  StyledTitle,
  StyledMessage,
  StyledContainer,
  StyledBackgroundContainer,
} from './style';

const VisitsAutoCheckin = () => {
  const token = useAutoCheckInToken();
  const [promoter, setPromoter] = useState(null);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [isSuspensionModalOpen, setIsSuspensionModalOpen] = useState(false);
  const [promoterWarning, setPromoterWarning] = useState({
    lastVisit: { storeName: '', checkinAt: '' },
    totalWarnings: -1,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(false); 

  const onSubmitFormHandler = async (values, actions) => {
    setIsDisabled(true);
    setPromoter(values.promoterUser);
    
    try {
      const { resetForm } = actions;
      const result = await checkInVisit(values.promoterUser.id, token);
      if (!result) {
        throw new Error();
      }
      resetForm();
      
      const {
        accesses,
        visits,
        lastVisit,
        totalWarnings,
        isLastVisitWithoutCheckout,
      } = result;
      
      setPromoterWarning({
        lastVisit,
        totalWarnings,
      });
      
      if (accesses === 0) {
        setIsDisabled(false);
        setIsErrorModalOpen(true);
        setErrorMessage(
          `${PROMOTER_DONT_HAVE_ACCESS_TO_AGREEMENT}.\n
          ${MORE_DETAILS_ASK_TO}\n${CONTACT_RESPONSIBLE_PERSON}`
        )
        return;
      }

      if (visits.length === 0) {
        setIsDisabled(false);
        setIsErrorModalOpen(true);
        setErrorMessage(`${MORE_DETAILS_ASK_TO}\n${CONTACT_RESPONSIBLE_PERSON}`);
        return;
      }
      if (totalWarnings === MAX_PROMOTER_WARNING_BEFORE_SUSPENSION) {
        setIsDisabled(false);
        setIsSuspensionModalOpen(true);
        return;
      }
      if (totalWarnings > 0 && isLastVisitWithoutCheckout) {
        setIsDisabled(false);
        setIsWarningModalOpen(true);
        return;
      }
      
      setIsDisabled(false);
      setIsSuccessModalOpen(true);
    } 
    catch (err) {
      setIsDisabled(false);
      setErrorMessage(err.response.data.code === CheckinErrorCode.CANNOT_INFER_STORE 
        ? `${CANNOT_INFER_USER_STORE}.`
        : (
          `${UNEXPECTED_ERROR_OCCURRED_ON_CHECKIN}.\n
          ${MORE_DETAILS_ASK_TO}\n${CONTACT_RESPONSIBLE_PERSON}`
        )
      )
      setIsErrorModalOpen(true);
    }
  };

  const onReleaseAccessHandler = async (cpf, password) => {
    try {
      const result = await liberateAccessStoreManager({ 
        cpf, 
        token,
        password, 
        promoterId: promoter.id
      });
      
      if (result) {
        setIsSuspensionModalOpen(false);
        setIsLoginModalOpen(false);
        setIsSuccessModalOpen(true);
      }
      return result;
    } catch (err) {
      const { response: { data: { statusCode }}} = err;
      
      if (statusCode !== 401 && statusCode !== 403) {
        setErrorMessage(`
          ${AN_UNEXPECTED_ERROR_OCCURRED_AT_ACTION}.\n
          ${PLEASE_TRY_AGAIN_SOON}.
        `)
        setIsErrorModalOpen(true);
      }
      return false;
    }
  }

  return (
    <StyledContainer>
      <StyledRow>
        <StyledLogo src={doorsLogo} alt={INVOLVES_DOORS_LOGO} />
        <div>
          <StyledTitle size="h2">
            {GOOD_TO_SEE_YOU}
          </StyledTitle>
          <StyledMessage size="h5">
            {DO_CHECK_IN_TO_CONTINUE}
          </StyledMessage>
        </div>

        <Formik
          enableReinitialize
          initialValues={intialFormValues}
          onSubmit={onSubmitFormHandler}
        >
          {(actions) => <FormVisitsAutoCheckin token={token} isDisabled={isDisabled} />}
        </Formik>
      </StyledRow>

      <StyledBackgroundContainer>
        <img src={backgroundImage} alt={BACKGROUND_IMG_ALT} />
      </StyledBackgroundContainer>

      <SuccessModal
        isOpen={isSuccessModalOpen}
        promoterName={promoter?.name}
        onCloseModalHandler={() => setIsSuccessModalOpen(false)}
      />

      <WarningModal
        isOpen={isWarningModalOpen}
        data={{
          promoterName: promoter?.name,
          store: promoterWarning?.lastVisit?.storeName,
          suspensionAt: promoterWarning?.lastVisit?.checkinAt,
          totalWarnings: promoterWarning?.totalWarnings,
        }}
        onCloseModalHandler={() => {
          setIsWarningModalOpen(false);
          setIsSuccessModalOpen(true);
        }}
      />

      <SuspensionModal
        isOpen={isSuspensionModalOpen}
        data={{ promoterName: promoter?.name }}
        onCloseModalHandler={() => setIsSuspensionModalOpen(false)}
        onContinueHandler={() => {
          setIsSuspensionModalOpen(false);
          setIsLoginModalOpen(true);
        }}
      />

      <LoginModal
        isOpen={isLoginModalOpen}
        title={LIBERATE_PROMOTOR_ACCESS}
        onCloseModalHandler={() => setIsLoginModalOpen(false)}
        onReleaseAccessHandler={onReleaseAccessHandler}
      />

      <ModalSmallContainer 
        isOpen={isErrorModalOpen}
        closeModalCallback={() => setIsErrorModalOpen(false)} 
      >
        <DialogContent style={styles.errorModalDialog}>
          <img
            alt="check in check"
            src={checkOutFail}
            style={styles.errorModalImg}
          />
          <Typography variant="h1" style={styles.errorModaltext}>
            {ACCESS_NOT_RELEASED}
          </Typography>

          <Typography variant="body1" align='center'>
            {errorMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <CancelButton 
            label={CLOSE} 
            onClick={() => setIsErrorModalOpen(false)} 
          />
        </DialogActions>
      </ModalSmallContainer>
    </StyledContainer>
  );
};

export { VisitsAutoCheckin };
