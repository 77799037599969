import React, { ChangeEventHandler } from 'react';
import { useHistory } from 'react-router';
import { Checkbox, DialogActions, FormControlLabel } from '@material-ui/core';
import { CancelButton, DefaultButton } from '../../../../../components/Buttons';
import * as routes from '../../../../../core/constants/routes';
import * as dictionary from '../../../../../core/constants/dictionary';

type Props = {
  isDisabled: boolean;
  isCheckboxChecked?: boolean;
  onClickHandler: Function;
  onChangeCheckbox: ChangeEventHandler;
};

const CalendarFooter = ({
  isDisabled,
  isCheckboxChecked,
  onClickHandler,
  onChangeCheckbox,
}: Props) => {
  const history = useHistory();

  const footerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    margin: '24px 0',
  };

  return (
    <section style={footerStyle}>
      <FormControlLabel
        label="Calendário ativo"
        control={
          <Checkbox
            color="primary"
            checked={isCheckboxChecked}
            onChange={onChangeCheckbox}
          />
        }
      />

      <DialogActions>
        <CancelButton
          label={dictionary.CANCEL}
          onClick={() => history.push(routes.PROMOTIONAL_CALENDARS)}
        />
        <DefaultButton
          disabled={isDisabled}
          label={dictionary.SAVE_CALENDAR}
          onClick={onClickHandler}
          style={{ width: 'auto' }}
        />
      </DialogActions>
    </section>
  );
};

export default CalendarFooter;
