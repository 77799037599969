import React from 'react';
import { format } from 'date-fns';
import { Divider, Typography } from '@material-ui/core';
import  classes from '../NegotiationTable.module.scss';
import * as dictionary from '../../../../../../core/constants/dictionary';

type Props = {
  user: string;
  type: string;
  datetime: Date;
  product?: Array<{
    ean: string;
    name: string;
  }>;
}
const ReportProblemAlert = ({ user, type, datetime, product}: Props) => {
  return (
    <div className={classes.reportProblemAlert}>
      <Typography className={classes.title}>
        {`${dictionary.REPORTED_AT}: ${format(datetime, 'dd/MM/yyyy')} - ${dictionary.BY}: ${user}`}
      </Typography>
      <ul>
        {
          product && product.length > 0 ? (
            <li>
              <Typography className={classes.productType}>
                {dictionary.LACK_OF_PRODUCT}:
              </Typography>
              {
                product.map(p => (
                  <div>
                    <Typography variant='overline' className={classes.ean}>
                      EAN: {p.ean}
                    </Typography>
                    <Typography className={classes.name}>
                      { p.name }
                    </Typography>
                    <Divider />
                  </div>
                ))
              }
            </li>
          ) : (
            <li>
              <Typography>{ type }</Typography>
            </li>
          )
        }
      </ul>
    </div>
  );
}

export default ReportProblemAlert;
