import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { colors } from '../../../Theme/colors';

const useStyles = makeStyles({
  defaultStyle: {
    borderColor: colors.blue_500,
    color: colors.blue_500,
  },
});

const OutlinedButton = ({ 
  label, 
  disabled = false, 
  onClick = undefined, 
  style= {}
}) => {
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      className={classes.defaultStyle}
      style={style}
      disabled={disabled}
      onClick={onClick}
    >
      <Typography variant="subtitle1" style={style}>
        {label}
      </Typography>
    </Button>
  );
};

export default OutlinedButton;
