import React, { useState } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import {
  DashCard,
  DashCardTitle,
  DashRow,
  DashCol,
  DashCardNumber,
  DashCardSubtitle,
  DashCardNumberSmall,
  DashCardNumberTiny,
  DashCardCashValue,
} from '../../../../components/Cards';
import CardDetail from './CardDetail/';
import ArrowFeedback from './CardDetail/ArrowFeedback';
import * as fetchData from './utils';
import './style.css';

const Indicators = (props) => {
  const [selectedDashCard, setSelectedDashCard] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const { supply, check } = props.data;
  const indicators = fetchData.formatIndicators(props.data);

  // Bindings & handlers
  const dispatchDashCardFilter = (index) => {
    let payload = null;
    switch (index) {
      // Empty payload
      default:
      case 1:
        break;

      case 2:
        payload = { supplyStatus: [fetchData.Restock.SHELF_RESTOCKED] };
        break;

      case 3:
        payload = {
          supplyStatus: [fetchData.Restock.PENDING, fetchData.Restock.NO_STOCK],
        };
        break;
    }

    props.onFilter(payload);
  };

  const clickedHandlerDashcard = (index) => {
    setSelectedDashCard((prevState) => {
      if (prevState !== index) {
        setSelectedFilter(null);
        dispatchDashCardFilter(index);
      }
      return index;
    });
  };

  const clickedHandlerFilter = (
    dashCardIndex,
    filterIndex,
    filterPayload,
    event
  ) => {
    event.stopPropagation();
    setSelectedDashCard(dashCardIndex);
    setSelectedFilter(filterIndex);
    props.onFilter(filterPayload);
  };

  const resetFilter = (event) => {
    event.stopPropagation();
    setSelectedFilter(null);
    dispatchDashCardFilter(selectedDashCard);
  };

  const loadingValueHandler = () => {
    return props.isLoading ? <CircularProgress /> : 0;
  };

  // Renderização do componenente
  return (
    <Grid container spacing={3}>
      <Grid item lg md={6} xs={12}>
        <DashCard
          selected={selectedDashCard === 1}
          onClick={() => clickedHandlerDashcard(1)}
        >
          <DashCardTitle>Conferências</DashCardTitle>
          <DashRow>
            <DashCol>
              {props.isLoading ? (
                <CircularProgress />
              ) : (
                <DashCardNumber>
                  {fetchData.getParcialCheck(check) ?? 0}
                  <DashCardNumberSmall>
                    /{fetchData.getTotalCheck(check) ?? 0}
                  </DashCardNumberSmall>
                </DashCardNumber>
              )}
              <DashCardSubtitle>Produtos</DashCardSubtitle>
            </DashCol>
            <DashCol>
              {indicators &&
                indicators.check.map((indicator, index) => {
                  return (
                    <CardDetail
                      key={`1${index}`}
                      filterIndex={index}
                      filter={selectedFilter}
                      clicked={(e) =>
                        clickedHandlerFilter(1, index, indicator.filter, e)
                      }
                      detail={{
                        item: indicator.item,
                        value: indicator.value,
                        sellout: indicator.sellout,
                      }}
                      reset={(e) => resetFilter(e)}
                    />
                  );
                })}
            </DashCol>
          </DashRow>
        </DashCard>
      </Grid>

      <Grid item lg md={6} xs={12}>
        <DashCard
          selected={selectedDashCard === 2}
          onClick={() => clickedHandlerDashcard(2)}
        >
          <DashCardTitle>
            Abastecidos
            <ArrowFeedback success />
          </DashCardTitle>
          <DashRow>
            <DashCol>
              <DashCardNumber>
                {supply ? supply.done.count : loadingValueHandler()}
              </DashCardNumber>
              <DashCardSubtitle>Produtos</DashCardSubtitle>
            </DashCol>
            <DashCol>
              <DashCardNumber variant="cash">
                <DashCardNumberTiny>+R$ </DashCardNumberTiny>
                <DashCardCashValue>
                  {supply
                    ? fetchData.formatRecoveredSale(supply)
                    : loadingValueHandler()}
                </DashCardCashValue>
              </DashCardNumber>
              <DashCardSubtitle>Venda recuperada</DashCardSubtitle>
            </DashCol>
          </DashRow>
        </DashCard>
      </Grid>

      <Grid item lg={5} md={8} xs={12}>
        <DashCard
          lostSale
          selected={selectedDashCard === 3}
          onClick={() => clickedHandlerDashcard(3)}
        >
          <DashCardTitle>
            Não abastecidos
            <ArrowFeedback />
          </DashCardTitle>
          <DashRow>
            <DashCol>
              <DashCardNumber>
                {supply
                  ? fetchData.getTotalSupply(supply)
                  : loadingValueHandler()}
              </DashCardNumber>
              <DashCardSubtitle>Produtos</DashCardSubtitle>
            </DashCol>
            <DashCol>
              <DashCardNumber variant="cash">
                <DashCardNumberTiny>-R$ </DashCardNumberTiny>
                <DashCardCashValue>
                  {supply
                    ? fetchData.formatLostSale(supply)
                    : loadingValueHandler()}
                </DashCardCashValue>
              </DashCardNumber>
              <DashCardSubtitle>Venda perdida</DashCardSubtitle>
            </DashCol>
            <DashCol>
              {indicators &&
                indicators.supply.map((indicator, index) => {
                  const offset = 3;

                  return (
                    <CardDetail
                      key={`2${index + offset}`}
                      filterIndex={index + offset}
                      filter={selectedFilter}
                      clicked={(e) =>
                        clickedHandlerFilter(
                          offset,
                          index + offset,
                          indicator.filter,
                          e
                        )
                      }
                      detail={{
                        item: indicator.item,
                        value: indicator.value,
                        sellout: indicator.sellout,
                      }}
                      reset={(e) => resetFilter(e)}
                    />
                  );
                })}
            </DashCol>
          </DashRow>
        </DashCard>
      </Grid>
    </Grid>
  );
};

export default Indicators;
