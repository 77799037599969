import React, { useMemo } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { amber, green } from '@material-ui/core/colors';
import {
  IconButton,
  Snackbar,
  SnackbarContent,
  Link,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { view } from 'react-easy-state';
import { utils } from '../../core/utils';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStylesContainer = makeStyles(theme => ({
  success: { backgroundColor: green[600] },
  error: { backgroundColor: theme.palette.error.dark },
  info: { backgroundColor: theme.palette.primary.main },
  warning: { backgroundColor: amber[700] },
  icon: { fontSize: 20 },
  iconVariant: { opacity: 0.9, marginRight: '8px' },
  message: { display: 'flex', alignItems: 'center' },
}));

const MySnackbarContentWrapper = ({
  className,
  message,
  onClose,
  variant,
  renderAction,
  ...other
}) => {
  const classes = useStylesContainer();
  const Icon = variantIcon[variant];

  const iconSnackBar = useMemo(() => {
    return (
      <span id="client-snackbar" className={classes.message}>
        <Icon className={`${classes.icon} ${classes.iconVariant}`} />
        {message}
      </span>
    );
  }, [classes, message]);

  return (
    <SnackbarContent
      className={`${classes[variant]} ${className}`}
      aria-describedby="client-snackbar"
      message={iconSnackBar}
      action={[
        renderAction,
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
};

export const CustomizedLinkSnackbars = ({
  openSnackBar,
  messageSnackBar,
  typeSnackbar,
  actionSnackbar,
  messageActionSnackbar,
}) => {
  const marginStyle = { margin: '8px' };

  const renderButton = useMemo(() => {
    return (
      <Typography variant="body2">
        <Link
          style={{ color: '#ffffff', textDecoration: 'underline' }}
          className="clickable"
          onClick={() => actionSnackbar()}
        >
          {messageActionSnackbar}
        </Link>
      </Typography>
    );
  }, [actionSnackbar, messageActionSnackbar]);

  return (
    openSnackBar && (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={utils.closeLinkSnackBar}
      >
        <MySnackbarContentWrapper
          variant={typeSnackbar}
          style={marginStyle}
          message={messageSnackBar}
          onClose={utils.closeLinkSnackBar}
          renderAction={renderButton}
        />
      </Snackbar>
    )
  );
};

export default view(CustomizedLinkSnackbars);
