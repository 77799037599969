import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledInputPlaceholder,
  StyledInputPlaceholderClearButton,
  StyledInputPlaceholderContent,
} from './style';

const InputPlaceholder = ({ children, onClear, ...props }) => {
  return (
    <StyledInputPlaceholder {...props}>
      <StyledInputPlaceholderContent>{children}</StyledInputPlaceholderContent>
      <StyledInputPlaceholderClearButton
        secondary
        small
        icon="close"
        type="button"
        onClick={onClear}
      />
    </StyledInputPlaceholder>
  );
};

InputPlaceholder.propTypes = {
  onClear: PropTypes.func,
};

InputPlaceholder.defaultProps = {
  onClear: () => {},
};

export { InputPlaceholder };
