import React from 'react';
import { format, parseISO } from 'date-fns';
import { Typography } from '@material-ui/core';
import WarningModal from '../../../../containers/WarningModal';
import { MAX_MODAL_WIDTH } from '../utils';
import { MAX_PROMOTER_WARNING_BEFORE_SUSPENSION } from '../../../../core/constants/visits';
import * as dictionary from '../../../../core/constants/dictionary';
import classes from '../AutoCheckinModal.module.scss';

type Props = {
	isOpen: boolean,
	data: {
		store: string;
		promoterName: string;
		suspensionAt: string;
		totalWarnings: number;
	};
	onCloseModalHandler: Function;
};

const AutoCheckinWarningModal = ({
	isOpen,
	data,
	onCloseModalHandler,
}: Props) => {
	const suspensionAt = data.suspensionAt ? format(parseISO(data.suspensionAt), 'dd/MM/yyyy - HH:mm') : '';

	return (
		<WarningModal
			isOpen={isOpen}
			width={MAX_MODAL_WIDTH}
			notificationText={(
				<span className={classes.notificationText}>
					<strong>{ data.totalWarnings}/{ MAX_PROMOTER_WARNING_BEFORE_SUSPENSION }. </strong>
					{ dictionary.INFORM_AVERT_BLOCK_MESSAGE }
				</span>
			)}
			onCloseModalHandler={onCloseModalHandler}
		>
			<div className={classes.body}>
        <Typography align="center" className={classes.title}>
          {`${dictionary.HI}, ${data.promoterName}!`}
        </Typography>

        <Typography align="center" className={classes.bodyText}>
          { dictionary.PROMOTER_CHECKOUT_FORGET_WARNING_MESSAGE + ':'}
        </Typography>
				
				<Typography align="center" className={[classes.bodyText, classes.semiBold].join(' ')}>
          { suspensionAt } ({ data.store })
        </Typography>
      </div>
		</WarningModal>
	);
}

export default AutoCheckinWarningModal;
