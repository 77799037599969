import React from 'react';
import { Typography } from '@material-ui/core';
import './style.scss';

const PageContent = ({
	title,
	children,
	className = '',
	style = {},
	titleStyle = {},
	contentStyle = {}
}) => {
	const classNames = ["page-container"];
	if (className) {
		classNames.push(className);
	}

	return (
		<div className="pages-content" style={contentStyle}>
			<div className={classNames.join(" ")} style={style}>
				<div className="page-title-row">
					<Typography variant="h1" style={titleStyle}>
						{title}
					</Typography>
				</div>
				{children}
			</div>
		</div>
	);
}

export default PageContent;
