import { AxiosResponse } from 'axios';
import { Pagination } from '../../types/pagination.type';
import { PromotionalNegotiationExecutionDto } from '../../types/promotional-spot/promotional-negotiation-execution-dto';
import API from '../api/config';
import {
  API_PROMOTIONAL_NEGOTIATION_EXECUTION,
  API_PROMOTIONAL_NEGOTIATION_EXECUTION_DECREMENT,
  API_PROMOTIONAL_NEGOTIATION_EXECUTION_INCREMENT,
  API_PROMOTIONAL_NEGOTIATION_EXECUTION_UPDATE,
} from '../api/routes';
import {
  AutoIncrementPromotionalNegotiationExecutionDto,
  CreatePromotionalNegotiationExecutionDto,
  CreatePromotionalNegotiationExecutionParams,
  GetPromotionalNegotiationExecutionParams,
  IncrementPromotionalNegotiationExecutionParams,
  RefuseExecutionParams,
} from './types';

export const createPromotionalSpotNegotiationAPI = (
  params: CreatePromotionalNegotiationExecutionParams
): Promise<AxiosResponse<CreatePromotionalNegotiationExecutionDto>> => {
  const url = `${API_PROMOTIONAL_NEGOTIATION_EXECUTION}`;

  return API.post(url, { ...params });
};

export const deletePromotionalSpotNegotiationExecutionAPI = (
  id: string
): Promise<AxiosResponse<CreatePromotionalNegotiationExecutionDto>> => {
  const url = `${API_PROMOTIONAL_NEGOTIATION_EXECUTION_UPDATE}`.replace(
    ':id',
    id
  );

  return API.delete(url);
};

export const IncrementPromotionalNegotiationExecutionAPI = (
  params: IncrementPromotionalNegotiationExecutionParams
): Promise<AxiosResponse<AutoIncrementPromotionalNegotiationExecutionDto>> => {
  const url = `${API_PROMOTIONAL_NEGOTIATION_EXECUTION_INCREMENT}`.replace(
    ':id',
    params.negotiationId
  );

  const data = {
    storeIds: params.storeIds,
  };

  return API.post(url, { ...data });
};

export const DecrementPromotionalNegotiationExecutionAPI = (
  id: string
): Promise<AxiosResponse<AutoIncrementPromotionalNegotiationExecutionDto>> => {
  const url = `${API_PROMOTIONAL_NEGOTIATION_EXECUTION_DECREMENT}`.replace(
    ':id',
    id
  );

  return API.delete(url);
};

export const getPromotionalNegotiationExecutionAPI = async (
  params: GetPromotionalNegotiationExecutionParams
): Promise<AxiosResponse<Pagination<PromotionalNegotiationExecutionDto>>> => {
  const url = `${API_PROMOTIONAL_NEGOTIATION_EXECUTION}`;

  return API.get(url, { params });
};

export const refuseExecution = async (
  params: RefuseExecutionParams
): Promise<void> => {
  const url = `${API_PROMOTIONAL_NEGOTIATION_EXECUTION}/${params.executionId}/refuse`;
  await API.post(url, {reason: params.reason});
};
