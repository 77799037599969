import { AxiosResponse } from 'axios';
import { 
  RetailFeatureEntity, 
  UpdateFeatureParams, 
  GetRetailFeaturesDto,
  RetailFeature, 
} from './types';
import {
  createFeatureAPI,
  updateFeatureAPI,
  getAllFeaturesAPI,
  getFeatureByCodeAPI,
} from './retailFeaturesAPI';
import { mainStore } from '../../store/mainStore';

export const getAllFeatures = (
  retailId: string
): Promise<GetRetailFeaturesDto> => {
  return getAllFeaturesAPI(retailId);
}

export const getFeatureByCode = (retailId: string, featureCode: RetailFeature) => {
  return getFeatureByCodeAPI(retailId, featureCode);
}

export const getAllLoggedUserRetailFeatures = () => {
  if (mainStore?.loggedUser?.retailId) {
    return getAllFeaturesAPI(mainStore.loggedUser.retailId);
  }
  return false;
}

export const getLoggedUserRetailFeatureByCode = (featureCode: RetailFeature) => {
  if (mainStore?.loggedUser?.retailId) {
    return getFeatureByCodeAPI(mainStore.loggedUser.retailId, featureCode);
  }
  return false;
}

export const createFeature = (retailId: string, data: Partial<RetailFeatureEntity>) => {
  return createFeatureAPI(retailId, data);
}

export const updateFeature = ({
  data,
  retailId,
  featureCode,
}: UpdateFeatureParams): Promise<AxiosResponse<RetailFeatureEntity>> => {
  return updateFeatureAPI({ retailId, featureCode, data });
}
