import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
} from 'react';
import { AutocompleteRenderInputParams } from '@material-ui/lab';
import {
  DialogActions,
  DialogContent,
  TextField as MUITextField,
} from '@material-ui/core';
import TextField from '../../../../../../components/InputTextField';
import ModalTitle from '../../../../../../components/Modal/Title';
import Autocomplete from '../../../../../../components/Autocomplete';
import {
  CancelButton,
  DefaultButton,
} from '../../../../../../components/Buttons';
import { utils } from '../../../../../../core/utils';
import { mainStore } from '../../../../../../core/store/mainStore';
import { profileCheck } from '../../../../../../core/profiles';
import { ProductGroup, StoreDto } from '../../../types';
import { AddressingDto } from '../../../../../../core/types/promotional-spot/promotional-addressing.dto';
import { StoreAreaLabel } from '../../../../../../core/types/store-area-label.enum';
import { defaultFormFields } from '../utils';
import { globalTypeOptions } from '../../../../common/globalTypeOptions';
import { PromotionalSpotDto } from '../../../../../../core/types/promotional-spot/promotional-spot.dto';
import { PromotionalSpotTypeLabel } from '../../../../../../core/types/promotional-spot/promotional-spot-type-label.enum';
import {
  translateAPIToStoreAreaLabel,
  translateStoreAreaLabelToAPI,
  PromotionalSpotAddressingAreaOptions,
} from '../../../utils';
import { getStoreById } from '../../../../../../core/services/stores/storesService';
import { sortTextList } from '../../../../../../core/strings';
import { useDebounce } from '../../../../../../core/hooks';
import { getPromotionalSpots } from '../../../../../../core/services/promotionalSpots/promotionalSpotsServices';
import { getProductGroupsByRetail } from '../../../../../../core/services/shelfMissions/shelfMissionsService';
import { DEBOUNCE_DELAY_ON_FETCH_USER } from '../../../../../../core/constants/user';
import {
  translateAPIToPromotionalSpotType,
  translatePromotionalSpotTypeToAPI,
} from '../../../../common/utils';
import * as dictionary from '../../../../../../core/constants/dictionary';
import { errorMessageToFeedBack } from './utils';
import { styles } from '../styles';

type Props = {
  title?: string;
  stores: StoreDto[];
  currentStore?: StoreDto;
  promotionalSpots: PromotionalSpotDto[];
  storeSectors?: ProductGroup[];
  currentAddressing?: AddressingDto;
  onClose: Function;
  onAfterSubmit: Function;
  onSubmitAction: Function;
};

const CreateAddressingModal = ({
  title = dictionary.NEW_ADDRESS,
  currentStore,
  stores,
  promotionalSpots,
  onClose,
  onSubmitAction,
  currentAddressing,
  onAfterSubmit,
}: Props) => {
  const [isLoadingSectors, setIsLoadingSectors] = useState<boolean>(false);
  const [isLoadingSpots, setIsLoadingSpots] = useState<boolean>(false);
  const [promotionalSpotOptions, setPromotionalSpotOptions] =
    useState(promotionalSpots);

  const [type, setType] = useState<string | null>(
    currentAddressing?.spot?.type
      ? translateAPIToPromotionalSpotType(currentAddressing?.spot?.type)
      : null
  );
  const [store, setStore] = useState<StoreDto | null>(currentStore ?? null);
  const [storeArea, setStoreArea] = useState(
    currentAddressing?.storeArea
      ? translateAPIToStoreAreaLabel(currentAddressing?.storeArea)
      : null
  );
  const [sectorsOptions, setSectorsOptions] = useState<ProductGroup[]>([]);
  const [storeSector, setStoreSector] = useState(currentAddressing?.sector);
  const [promotionalSpot, setPromotionalSpot] = useState<
    AddressingDto['spot'] | null
  >(currentAddressing?.spot ?? null);
  const [physicalAddressing, setPhysicalAddressing] = useState(
    currentAddressing?.physicalAddressing
  );
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [promotionalSpotName, setPromotionalSpotName] = useState('');

  const fetchSectors = useCallback(
    async (retailId) => {
      setIsLoadingSectors(true);
      if (!isLoadingSectors) {
        let currentRetailId = retailId;
        if (!currentRetailId) {
          const storeData = await getStoreById(store?.id);
          currentRetailId = storeData?.retailId;
        }
        const data = await getProductGroupsByRetail(currentRetailId);
        setSectorsOptions(data);
        setIsLoadingSectors(false);
      }
    },
    [isLoadingSectors, store]
  );

  const fetchPromotionalSpots = useCallback(
    async (type?: string, name?: string) => {
      try {
        if (!isLoadingSpots) {
          const { data } = await getPromotionalSpots({
            type,
            name,
            retailId: store?.retailId,
          });
          setPromotionalSpotOptions(data.items);
        }
      } catch (error) {
      } finally {
        setIsLoadingSpots(false);
      }
    },
    [isLoadingSpots, store]
  );

  useEffect(() => {
    if (store) {
      fetchSectors(store?.retailId);
    }
  }, [store]);

  useEffect(() => {
    if (type) {
      fetchPromotionalSpots(
        translatePromotionalSpotTypeToAPI(type),
        promotionalSpotName,
      );
    }
  }, [type, store, promotionalSpotName]);

  const onSubmitHandler = async () => {
    if (!physicalAddressing) {
      setFormFields((prevState) => ({
        ...prevState,
        physicalAddressing: {
          showError: true,
          errorText: dictionary.FIELD_NOT_EMPTY,
        },
      }));
    }
    if (!type) {
      setFormFields((prevState) => ({
        ...prevState,
        type: {
          showError: true,
          errorText: dictionary.FIELD_NOT_EMPTY,
        },
      }));
    }
    if (!store) {
      setFormFields((prevState) => ({
        ...prevState,
        store: {
          showError: true,
          errorText: dictionary.FIELD_NOT_EMPTY,
        },
      }));
    }
    if (!storeArea) {
      setFormFields((prevState) => ({
        ...prevState,
        storeArea: {
          showError: true,
          errorText: dictionary.FIELD_NOT_EMPTY,
        },
      }));
    }
    if (!storeSector) {
      setFormFields((prevState) => ({
        ...prevState,
        sector: {
          showError: true,
          errorText: dictionary.FIELD_NOT_EMPTY,
        },
      }));
    }
    if (!promotionalSpot) {
      setFormFields((prevState) => ({
        ...prevState,
        promotionalSpot: {
          showError: true,
          errorText: dictionary.FIELD_NOT_EMPTY,
        },
      }));
    }
    if (isButtonDisabled) {
      return;
    }

    try {
      await onSubmitAction({
        physicalAddressing,
        id: currentAddressing?.id,
        storeArea: storeArea && translateStoreAreaLabelToAPI(storeArea),
        sectorId: storeSector?.id,
        spotId: promotionalSpot?.id,
        storeId: store && store.id ? store.id : mainStore.loggedUser.storeId,
      });
      onAfterSubmit();
      setFormFields(defaultFormFields);
      utils.openSnackBar(
        dictionary.SUCCESS,
        dictionary.CREATE_PROMOTIONAL_SPOT_SUCCESS
      );
    } catch (e) {
      const updateFormFields = errorMessageToFeedBack(
        e?.response?.data.error ?? ''
      );
      setFormFields(updateFormFields);
    }
  };

  const isButtonDisabled = useMemo(
    () =>
      !type ||
      !store ||
      !storeArea ||
      !storeSector ||
      !promotionalSpot ||
      !physicalAddressing,
    [type, store, storeArea, storeSector, promotionalSpot, physicalAddressing]
  );

  const debounceOnSearch = useDebounce((value: string[]) => { 
    setPromotionalSpotName(value[0]);
  }, DEBOUNCE_DELAY_ON_FETCH_USER);
    
  useEffect(() => {
    if (formFields.snackError.show) {
      utils.openSnackBar(
        formFields.snackError.type,
        formFields.snackError.message
      );
    }
  }, [formFields]);

  return (
    <>
      <ModalTitle title={title} />
      <DialogContent style={styles.dialog}>
        {!profileCheck.isStoreManager() && (
          <Autocomplete
            required
            id="store"
            size="small"
            value={store}
            options={stores}
            getOptionLabel={(option: AddressingDto['store']) =>
              `${option.name} (${option?.storeCode})`
            }
            renderInput={(params: AutocompleteRenderInputParams) => (
              <MUITextField
                {...params}
                variant="outlined"
                label={dictionary.STORE}
                error={formFields.store.showError}
                helperText={formFields.store.errorText}
              />
            )}
            style={styles.autocomplete}
            onChange={(e: any, value: AddressingDto['store']) =>
              setStore(value)
            }
            onInputChange={() =>
              setFormFields((prevState) => ({
                ...prevState,
                store: {
                  errorText: '',
                  showError: false,
                },
              }))
            }
          />
        )}
        <Autocomplete
          required
          value={type}
          size="small"
          id="type"
          options={globalTypeOptions}
          disabled={!store}
          label={dictionary.PROMOTIONAL_SPOT_TYPE}
          getOptionLabel={(option: PromotionalSpotTypeLabel) => option}
          renderInput={(params: any) => (
            <MUITextField
              {...params}
              variant="outlined"
              error={formFields.type.showError}
              helperText={formFields.type.errorText}
              label={dictionary.PROMOTIONAL_SPOT_TYPE}
            />
          )}
          onChange={(e: any, value: PromotionalSpotTypeLabel) => setType(value)}
          onInputChange={() =>
            setFormFields((prevState) => ({
              ...prevState,
              type: {
                errorText: '',
                showError: false,
              },
            }))
          }
          style={styles.autocomplete}
        />
        <Autocomplete
          required
          size="small"
          id="promotionalSpot"
          options={promotionalSpotOptions}
          value={promotionalSpot}
          disabled={!store}
          key={promotionalSpot?.id}
          getOptionLabel={(option: AddressingDto['spot']) => option.name}
          renderInput={(params: any) => (
            <MUITextField
              {...params}
              variant="outlined"
              label={dictionary.PROMOTIONAL_SPOT_NAME}
              error={formFields.promotionalSpot.showError}
              helperText={formFields.promotionalSpot.errorText}
            />
          )}
          onChange={(_: any, value: AddressingDto['spot']) => 
            setPromotionalSpot(value)
          }
          onInputChange={(_: any, query: any) => {
            debounceOnSearch(query);
            setFormFields((prevState) => ({
              ...prevState,
              promotionalSpot: {
                errorText: '',
                showError: false,
              },
            }))
          }}
          style={styles.autocomplete}
        />
        <Autocomplete
          required
          size="small"
          id="storeSector"
          options={sectorsOptions}
          key={store?.id}
          value={storeSector}
          disabled={!store}
          getOptionLabel={(option: AddressingDto['sector']) => option.name}
          renderInput={(params: any) => (
            <MUITextField
              {...params}
              variant="outlined"
              label={dictionary.STORE_SECTOR}
              error={formFields.sector.showError}
              helperText={formFields.sector.errorText}
            />
          )}
          onChange={(e: any, value: AddressingDto['sector']) =>
            setStoreSector(value)
          }
          onInputChange={() =>
            setFormFields((prevState) => ({
              ...prevState,
              sector: {
                errorText: '',
                showError: false,
              },
            }))
          }
          style={styles.autocomplete}
        />
        <Autocomplete
          required
          size="small"
          id="storeArea"
          value={storeArea}
          disabled={!store}
          options={sortTextList(PromotionalSpotAddressingAreaOptions)}
          getOptionLabel={(option: StoreAreaLabel) => option}
          renderInput={(params: any) => (
            <MUITextField
              {...params}
              variant="outlined"
              label={dictionary.STORE_AREA}
              error={formFields.storeArea.showError}
              helperText={formFields.storeArea.errorText}
            />
          )}
          onChange={(e: any, value: StoreAreaLabel) => setStoreArea(value)}
          onInputChange={() =>
            setFormFields((prevState) => ({
              ...prevState,
              storeArea: {
                errorText: '',
                showError: false,
              },
            }))
          }
          style={styles.autocomplete}
        />
        <TextField
          required
          variant="outlined"
          value={physicalAddressing}
          label={dictionary.ADDRESSING}
          errorText={formFields.physicalAddressing.errorText}
          showError={formFields.physicalAddressing.showError}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            if (value.length > 0) {
              setFormFields((prevState) => ({
                ...prevState,
                physicalAddressing: {
                  errorText: '',
                  showError: false,
                },
              }));
            }
            setPhysicalAddressing(value);
          }}
          style={{ width: 'calc(100% - 44px)' }}
        />
      </DialogContent>

      <DialogActions>
        <CancelButton onClick={onClose} />
        <DefaultButton
          label={dictionary.SAVE}
          onClick={onSubmitHandler}
          style={styles.defaultButton}
        />
      </DialogActions>
    </>
  );
};

export default CreateAddressingModal;
