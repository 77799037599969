import React, { useCallback, useState } from 'react';
import ReactHoverObserver from 'react-hover-observer';
import { Route } from 'react-router-dom';
import { Drawer } from '@material-ui/core';
import DrawerHeader from './DrawerHeader';
import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import useStyles from './material-style-override'
import styles from './style.module.scss';
import { isPublicRoute } from '../../core/authorization';

const Sidebar = ({ history }) => {
  const [isOpened, setIsOpened] = useState(true);
  const classes = useStyles();
  
  const getRenderMenuContent = useCallback((propsR) => {
    let classNames = [styles.drawer, classes.doorsMenuStyle];
    if(isOpened) {
      classNames = [...classNames, classes.drawerOpen];
    } else {
      classNames = [...classNames, classes.drawerClose];
    }
    classNames = classNames.join(' ');

    return (
      <div className={classes.root}>
        <ReactHoverObserver>
          {({ isHovering }) => {
            setIsOpened(isHovering);

            return (
              <Drawer 
                open={isOpened} 
                variant="permanent"
                className={classNames}
                classes={{ paper: classNames }}
              >
                <DrawerHeader isOpened={isOpened} />

                <DrawerBody 
                  isOpened={isOpened}
                  history={history}
                  onChangeRoute={(isOpened) => setIsOpened(isOpened)}
                />

                <DrawerFooter 
                  isOpened={isOpened}
                  history={history}
                />
              </Drawer>
            )
          }}
        </ReactHoverObserver>
      </div>
    );
  }, [classes, history, isOpened]);
  
  if (isPublicRoute(history.location.pathname)) {
    return null;
  }

  return <Route render={propsR => getRenderMenuContent(propsR)} />;
}

export default Sidebar;
