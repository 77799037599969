import { exist } from '../validation';
import * as dictionary from '../constants/dictionary';

const { cnpj, cpf } = require('cpf-cnpj-validator');

export const invalid = helperText => ({ hasError: true, helperText });

export const valid = () => ({ hasError: false, helperText: '' });

const setValidity = (event, valid) => {
  if (event && event.target && event.target.setCustomValidity) {
    event.target.setCustomValidity(valid ? '' : ' ');
  }
};

const notEmpty = event => {
  const { value } = event.target;
  if (exist(value)) {
    setValidity(event, true);
    return valid();
  }
  setValidity(event, false);
  return invalid(dictionary.FIELD_NOT_EMPTY);
};

const validEmail = event => {
  const { value } = event.target;
  if (!exist(value) || /\S+@\S+\.\S+/.test(String(value).toLowerCase())) {
    setValidity(event, true);
    return valid();
  }
  setValidity(event, false);
  return invalid(dictionary.INVALID_EMAIL);
};

const zipCode = event => {
  const { value } = event.target;
  const exp = /\d{2}\.\d{3}-\d{3}/;
  if (!exp.test(value)) {
    setValidity(event, false);
    return invalid(dictionary.INVALID_ZIP_CODE);
  }
  setValidity(event, true);
  return valid();
};

const validCpf = event => {
  const { value } = event.target;

  if (cpf === '') {
    return false;
  }

  if (cpf.isValid(value)) {
    setValidity(event, true);
    return valid();
  }

  setValidity(event, false);
  return invalid(dictionary.INVALID_CPF);
};

const validCNPJ = event => {
  const { value } = event.target;

  if (cnpj === '') {
    return false;
  }

  if (cnpj.isValid(value)) {
    setValidity(event, true);
    return valid();
  }

  setValidity(event, false);
  return invalid(dictionary.INVALID_CNPJ);
};

export const SyncValidations = {
  notEmpty,
  zipCode,
  validCpf,
  validEmail,
  validCNPJ,
};
