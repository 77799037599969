import SnackBar from '../../components/Snackbars';
import React, { createContext, useContext } from 'react';
import { useSnackBarState } from '../../hooks/SnackBarHooks';

export type SnackBarType = 'success' | 'error';

export enum SnackBarTypeEnum {
  SUCCESS = 'success',
  ERROR = 'error',
}

type SnackBarContextProps = {
  type?: SnackBarType;
  open?: boolean;
  message?: string;
  action?: Function;
  messageAction?: string;
  openSnackBar: (
    type: SnackBarType,
    message: string,
    messageAction?: string,
    action?: Function
  ) => void;
  closeSnackBar: Function;
};

export const defautValueSnackBar = {
  open: false,
  type: undefined,
  message: undefined,
  action: () => {},
  messageAction: undefined,
  openSnackBar: (
    type: SnackBarType,
    message: string,
    messageAction?: string,
    action?: Function
  ) => {},
  closeSnackBar: () => {},
};

const SnackBarContext =
  createContext<SnackBarContextProps>(defautValueSnackBar);

export const useSnackBar = () => useContext(SnackBarContext);

export const SnackbarProvider = ({ children }: any) => {
  const { snackBar, closeSnackBar, openSnackBar } = useSnackBarState();
  return (
    <SnackBarContext.Provider
      value={{ ...snackBar, openSnackBar, closeSnackBar }}
    >
      {children}
      {snackBar.open && snackBar.type && (
        <SnackBar
          openSnackBar={snackBar.open}
          messageSnackBar={snackBar.message}
          typeSnackbar={snackBar.type}
        />
      )}
    </SnackBarContext.Provider>
  );
};
