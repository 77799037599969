import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Typography } from '@material-ui/core';
import { view } from 'react-easy-state';
import Loading from '../../components/Loading';
import WelcomePage from '../../components/WelcomePage';
import DefaultButton from '../../components/Buttons/Default';
import StickyFooter from '../../components/stickyFooter/stickyFooter';
import ModalContainer from '../../components/Modal/Container';
import ModalSmallContainer from '../../components/Modal/SmallContainer';
import TitleContainer from '../../components/Modal/TitleContainer';
import PageContent from '../../components/PageContent';
import { CreateRetail } from './modals/createRetail/createRetail';
import { EditRetail } from './modals/editRetail/editRetail';
import { RemoveRetail } from './modals/removeRetail/removeRetail';
import { RetailTable } from './components/RetailTable/RetailTable';
import { utils } from '../../core/utils';
import { mainStore } from '../../core/store/mainStore';
import { entityEmpty } from '../../core/validation';
import * as dictionary from '../../core/constants/dictionary';
import { loadInitialData } from './functions';
import '../../Theme/main.css';

const Retail = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [currentRetail, setCurrentRetail] = useState(null);

  const retailsData = mainStore.retails.data;

  useEffect(() => {
    (async function load() {
      await loadInitialData();
    })();
  }, []);

  const closeModal = useCallback(() => {
    setOpenModal(false);
    setModalType(null);
    setCurrentRetail(null);
  }, []);

  const createModal = useMemo(() => {
    return (
      <ModalContainer closeModalCallback={closeModal} isOpen={openModal}>
        <TitleContainer title={dictionary.ADD_NEW_RETAIL}>
          <CreateRetail closeModalCallback={closeModal} />
        </TitleContainer>
      </ModalContainer>
    );
  }, [closeModal, openModal]);

  const editModal = useMemo(() => {
    return (
      <ModalContainer closeModalCallback={closeModal} isOpen={openModal}>
        <TitleContainer title={dictionary.EDIT_RETAIL}>
          <EditRetail
            currentRetail={currentRetail}
            closeModalCallback={closeModal}
          />
        </TitleContainer>
      </ModalContainer>
    );
  }, [closeModal, currentRetail, openModal]);

  const removeModal = useMemo(() => {
    return (
      <ModalSmallContainer closeModalCallback={closeModal} isOpen={openModal}>
        <RemoveRetail
          currentRetail={currentRetail}
          closeModalCallback={closeModal}
        />
      </ModalSmallContainer>
    );
  }, [closeModal, currentRetail, openModal]);

  const renderModal = useMemo(() => {
    const modals = {
      [dictionary.CREATE]: createModal,
      [dictionary.EDIT_RETAIL]: editModal,
      [dictionary.REMOVE_RETAIL]: removeModal,
    };
    return modals[modalType];
  }, [createModal, editModal, modalType, removeModal]);

  const handleOpenModal = useCallback((modalType, currentRetail) => {
    setModalType(modalType);
    setOpenModal(true);
    setCurrentRetail(currentRetail);
  }, []);

  const snackBar = utils.getSnackbar();

  const actionsButtons = useMemo(
    () => (
      <div className="button-create-container">
        <DefaultButton
          onClick={() => handleOpenModal(dictionary.CREATE)}
          color="primary"
          style={{ width: 'auto' }}
          label={dictionary.ADD_RETAIL}
        />
      </div>
    ),
    [handleOpenModal],
  );

  const getPageContent = useCallback(() => {
    if (retailsData.length === 0) {
      return (
        <WelcomePage
          handleButton={() => handleOpenModal(dictionary.CREATE)}
          textButton={dictionary.ADD_RETAIL}
        >
          <Typography variant="body1">
            {dictionary.WELCOME_TO_DOORS_ADD_RETAILS_HERE}
          </Typography>
        </WelcomePage>
      );
    }
    return (
      <div className="reverse-table">
        <RetailTable data={retailsData} handleOpenModal={handleOpenModal} />
      </div>
    );
  }, [handleOpenModal, retailsData]);

  const content = useMemo(() => {
    return entityEmpty(retailsData) ? '' : getPageContent();
  }, [getPageContent, retailsData]);

  const renderStickyFooter = useMemo(() => {
    if (retailsData && retailsData.length > 0) {
      return <StickyFooter actionButton={actionsButtons} />;
    }
    return '';
  }, [actionsButtons, retailsData]);

  return (
    <React.Fragment>
      <PageContent title={dictionary.RETAIL}>
        {content}
      </PageContent>

      {renderStickyFooter}

      {openModal && renderModal}
      {snackBar}

      <Loading isOpen={mainStore.requestLoading} />
    </React.Fragment>
  );
};

export default view(Retail);
