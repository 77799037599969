import React, { useCallback, useMemo, useState } from 'react';
import { userProfile } from '../core/profiles';
import { getActiveStoresByRetail } from '../core/services/stores/storesService';
import { mainStore } from '../core/store/mainStore';
import { StoreDto } from '../pages/promotional-spots/Addressing/types';

export const useStoreData = () => {
  const [storeOptions, setStoreOptions] = useState<StoreDto[]>([]);
  const [isLoadingStores, setIsLoadingStores] = useState<boolean>(false);
  const isStoreManager = useMemo(
    () => mainStore?.loggedUser?.profile === userProfile.STORE_MANAGER,
    [mainStore?.loggedUser]
  );
  const fetchStores = useCallback(
    async (retailId: string) => {
      if (!isLoadingStores) {
        try {
          setIsLoadingStores(true);
          const stores = await getActiveStoresByRetail(retailId);
          setStoreOptions(stores);
        } catch (error) {
          setStoreOptions([]);
        } finally {
          setIsLoadingStores(false);
        }
      }
    },
    [isLoadingStores]
  );

  return {
    stores: storeOptions,
    isLoading: isLoadingStores,
    fetch: fetchStores,
    isStoreManager,
  };
};

