import React from 'react';
import { Typography } from '@material-ui/core';
import WelcomePage from "../../../components/WelcomePage";
import { 
  CLICK_ON, 
  NO_SUPPLIERS_YET, 
  REGISTER_SUPPLIER, 
} from '../../../core/constants/dictionary';
import classes from '../Supplier.module.scss';

type Props = {
  onClick: Function;
}

const EmptySupplierTable = ({ onClick }: Props) => {
  return (
    <WelcomePage
      textButton={REGISTER_SUPPLIER}
      classes={{ container: classes.container }}
      handleButton={()=>{}}
    >
      <Typography variant="body1">
        {`${NO_SUPPLIERS_YET}. ${CLICK_ON} `}
        <span style={{fontWeight:600}}>
          {REGISTER_SUPPLIER}
        </span>.
      </Typography>
    </WelcomePage>
  );
}

export default EmptySupplierTable;
