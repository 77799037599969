import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { pdf } from '@react-pdf/renderer';
import { view } from 'react-easy-state';
import { saveAs } from 'file-saver';
import {
  Box,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import brLocale from 'date-fns/locale/pt-BR';
import Tag from '../../components/Tag';
import Loading from '../../components/Loading';
import StickyFooter from '../../components/stickyFooter/stickyFooter';
import Autocomplete from '../../components/Autocomplete';
import CancelButton from '../../components/Buttons/Cancel';
import DefaultButton from '../../components/Buttons/Default';
import { ShelfBreaksReport } from './reports/restockReports';
import { RestockReportsTable } from './restockReportsTable';
import { utils } from '../../core/utils';
import { mainStore } from '../../core/store/mainStore';
import { profileCheck } from '../../core/profiles';
import { getStoresByRetail } from '../../core/services/stores/storesService';
import { getActiveRetails } from '../../core/services/retails/retailsService';
import { exist, findValueInArray } from '../../core/validation';
import {
  getProductGroupsByRetail,
  getProductSubGroupsByGroup,
  getShelfBreaksReports,
} from '../../core/services/shelfMissions/shelfMissionsService';
import * as dictionary from '../../core/constants/dictionary';
import {
  loadFirstFilterDTO,
  exportXls,
  reportStatusEnum,
  shelfStatusEnum,
  getPDFData,
  isRetailAssistantProfile,
  getNumberValueOfStatus,
  getNumberValueOfShelfStatus,
  printReport,
  getUpdateDateValue,
  defaultFilter,
  inputStyle,
} from './functions';
import '../../Theme/main.css';
import './styles.scss';

const ShelfBreaksReports = () => {
  const [filter, setFilter] = useState(defaultFilter);
  const [retails, setRetails] = useState([]);
  const [stores, setStores] = useState([]);
  const [groups, setGroups] = useState([]);
  const [subGroups, setSubGroups] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [pdfData, setPdfData] = useState(null);
  const [date, setDate] = useState(null);
  const [mixPanelFilter, setMixPanelFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const user = mainStore.loggedUser;
  
  useEffect(() => {
    mainStore.requestLoading = isLoading;
  }, [isLoading]);

  const clearRetailSubFilter = useCallback(() => {
    setStores([]);
    setGroups([]);
    setSubGroups([]);
  }, []);
  
  const clearFilter = useCallback(() => {
    const filter = defaultFilter;
    if (isRetailAssistantProfile()) {
      filter.retail = {
        id: user.retailId,
      };
      filter.store = findValueInArray(stores, 'id', user.storeId);
    } else if (profileCheck.isSuperAdmin()) {
      clearRetailSubFilter();
    }
    setFilter(filter);
    setDate(null);
  }, [user, stores, clearRetailSubFilter]);
  
  const pickerStyle = { margin: '9px 0 0', width: '100%', }

  const onChangeDataFilterHandle = useCallback(dateObject => {
    const newFilter = { ...filter, ...dateObject };
    setFilter(newFilter);
  }, [filter]);
  
  
  const handleReportStatus = useCallback(
    event => {
      const newFilter = { ...filter, status: event.target.value };
      setFilter(newFilter);
    },
    [filter],
  );

  const handleShelfStatus = useCallback(
    event => {
      const newFilter = { ...filter, shelfStatus: event.target.value };
      setFilter(newFilter);
    },
    [filter],
  );

  const loadStoresByRetail = useCallback(
    async retail => {
      mainStore.requestLoading = true;
      
      if(retail?.id) {
        const storeResult = await getStoresByRetail(retail.id);
        const groupsResult = await getProductGroupsByRetail(retail.id);
        setStores(storeResult);
        setGroups(groupsResult);
      }
      else {
        setStores([]);
        setGroups([]);
      }

      setSubGroups([]);
      setFilter({
        ...filter,
        retail,
        store: null,
        group: null,
        subgroup: null,
      });

      mainStore.requestLoading = false;
    },
    [filter],
  );

  const loadGroupsByStore = useCallback(
    store => {
      let resetGroupInfo = {}
      if(!store) {
        resetGroupInfo = {
          group: null,
          subgroup: null,
        }
        setSubGroups([]);
      }
      
      setFilter({
        ...filter,
        ...resetGroupInfo,
        store,
      });
    },
    [filter],
  );

  const onSubmitForm = useCallback(async () => {
    mainStore.requestLoading = true;
    const newFilter = filter;
    const statusDTO = getNumberValueOfStatus(filter.status);
    const shelfStatusDTO = getNumberValueOfShelfStatus(filter.shelfStatus);
    const filterDTO = {
      status: statusDTO.status,
      virtualStock: statusDTO.virtualStock,
      shelfStatus: shelfStatusDTO,
    };
    
    if (exist(filter.retail)) {
      filterDTO.retailId = filter.retail.id;
      
    } 
    else if (isRetailAssistantProfile()) {
      newFilter.retail = { id: user.retailId };
      filterDTO.retailId = user.retailId;    
    }

    if (exist(filter.store)) {
      filterDTO.storeId = filter.store.id;
    } 
    else if (isRetailAssistantProfile()) {
      newFilter.store = mainStore.shelfBreaksReports.store;
      filterDTO.storeId = mainStore.loggedUser.storeId;
    }

    if (exist(filter.group)) {
      filterDTO.groupId = filter.group.id;
    }

    if (exist(filter.subgroup)) {
      filterDTO.subgroupId = filter.subgroup.id;
    }
    if (exist(filter.startDate)) {
      filterDTO.startDate = filter.startDate;
    }
    if (exist(filter.endDate)) {
      filterDTO.endDate = filter.endDate;
    }

    try {
      const result = await getShelfBreaksReports(filterDTO);
      setTableData(result);

      if (exist(filter.store) && !profileCheck.isSuperAdmin()) {
        const pdfDataValue = getPDFData(newFilter, result, filter.date);
        setPdfData(pdfDataValue);
        setMixPanelFilter({ pdfData: pdfDataValue, filter: newFilter });
      } else {
        setPdfData(null);
        setMixPanelFilter({ pdfData: null, filter: newFilter });
      }
      setFilter(newFilter);
      setDate(getUpdateDateValue());
    } catch (err) {
      setDate(null);
      utils.openSnackBar(dictionary.ERROR, dictionary.SEARCH_ERROR);
    } finally {
      mainStore.requestLoading = false;
    }
  }, [filter, user]);

  useEffect(() => {
    (async function load() {
      try {
        mainStore.requestLoading = true;
        const retails = await getActiveRetails();

        if (profileCheck.isSuperAdmin()) {
          setRetails(retails);
          setTableData([]);
          const startFilter = {
            ...defaultFilter,
            status: [
              dictionary.AWAITING_REPLACEMENT,
              dictionary.STOCKED,
              dictionary.OUT_OF_STOCK,
              dictionary.VIRTUAL_STOCK,
            ],
            shelfStatus: [dictionary.LOW_STOCKED, dictionary.IN_SHELF_BREAK],
          };
          setFilter(startFilter);
        } else {
          mainStore.shelfBreaksReports.retails = retails;

          const firstFilterDTO = loadFirstFilterDTO();
          const data = await getShelfBreaksReports(firstFilterDTO);
          const storesResult = await getStoresByRetail(firstFilterDTO.retailId);
          let initialStatus;

          if (isRetailAssistantProfile()) {
            initialStatus = [dictionary.AWAITING_REPLACEMENT];
          } else {
            initialStatus = [
              dictionary.AWAITING_REPLACEMENT,
              dictionary.STOCKED,
              dictionary.OUT_OF_STOCK,
              dictionary.VIRTUAL_STOCK,
            ];
          }

          const startFilter = {
            ...defaultFilter,
            date: firstFilterDTO.startDate,
            status: initialStatus,
            shelfStatus: [dictionary.IN_SHELF_BREAK, dictionary.LOW_STOCKED],
          };

          if (firstFilterDTO.storeId) {
            const findStore = findValueInArray(
              storesResult,
              'id',
              firstFilterDTO.storeId,
            );

            startFilter.store = findStore;
            mainStore.shelfBreaksReports.store = findStore;
          }

          const groupsResult = await getProductGroupsByRetail(
            firstFilterDTO.retailId,
          );

          setFilter(startFilter);
          setStores(storesResult);
          setGroups(groupsResult);
          setTableData(data);

          if (isRetailAssistantProfile()) {
            const pdfInitialData = getPDFData(
              startFilter,
              data,
              startFilter.date,
            );
            setPdfData(pdfInitialData);
            setMixPanelFilter({ pdfData: pdfInitialData, filter: startFilter });
          } else {
            setMixPanelFilter({ pdfData: null, filter: startFilter });
          }
        }
        setDate(getUpdateDateValue());
      } catch (err) {
        utils.openSnackBar(
          dictionary.ERROR,
          dictionary.SHELF_BREAK_REPORT_ERROR,
        );
      } finally {
        mainStore.requestLoading = false;
      }
    })();
  }, []);

  const generatePdfDocument = useCallback(async pdfData => {
    setIsLoading(true);
    const blob = await pdf(<ShelfBreaksReport data={pdfData} />).toBlob();
    setIsLoading(false);
    
    saveAs(blob, pdfData.fileName);
  }, []);

  const renderPdfDownloadButton = useMemo(() => {
    if (exist(pdfData)) {
      return (
        <DefaultButton
          label={dictionary.PRINT_SHELF_BREAKS_REPORTS}
          color="primary"
          onClick={() => {
            generatePdfDocument(pdfData);
            printReport(mixPanelFilter);
          }}
          style={{ width: 'auto', marginRight: '14px' }}
        />
      );
    }
    return '';
  }, [generatePdfDocument, mixPanelFilter, pdfData]);

  const actionsButtons = useMemo(() => {
    return (
      <div className="button-create-container">
        <div>{renderPdfDownloadButton}</div>

        <DefaultButton
          color="primary"
          label={dictionary.EXPORT_SHELF_BREAKS_REPORTS}
          onClick={async () => {
            setIsLoading(true);
            try {
              await exportXls(pdfData, filter, mixPanelFilter);
            } catch (err) {}
            finally {
              setIsLoading(false);
            }
          }}
          style={{ width: 'auto' }}
        />
      </div>
    );
  }, [renderPdfDownloadButton, pdfData, filter, mixPanelFilter]);

  const snackBar = utils.getSnackbar();

  const pageTitle = useMemo(() => {
    return exist(filter.store)
      ? `${dictionary.RESTOCK_REPORTS} - ${filter.store.name}`
      : dictionary.RESTOCK_REPORTS;
  }, [filter]);

  const renderDate = useMemo(() => {
    if (exist(date)) {
      return <Typography variant="h1">{date}</Typography>;
    }
    return '';
  }, [date]);

  const renderRetailFilter = useMemo(() => {
    if (profileCheck.isSuperAdmin()) {
      return (
        <Grid container spacing={3}>
          <Grid item md={3} sm={4} id="retail-filter">
            <Autocomplete
              required={false}
              label={dictionary.RETAIL}
              value={filter.retail}
              onChange={(e, value) => {
                loadStoresByRetail(value);
              }}
              options={retails}
              style={inputStyle}
            />
          </Grid>
        </Grid>
      );
    }
    return '';
  }, [filter, loadStoresByRetail, retails]);

  const renderStoreFilter = useMemo(() => {
    if (isRetailAssistantProfile()) {
      return '';
    }
    return (
      <Grid item md={3} sm={4}>
        <Autocomplete
          label={dictionary.STORE}
          value={filter.store}
          options={stores}
          required={false}
          disabled={stores.length === 0}
          onChange={(e, store) => loadGroupsByStore(store)}
          style={inputStyle}
        />
      </Grid>
    );
  }, [filter, loadGroupsByStore, stores]);

  const reportStatusCheckboxs = useMemo(() => {
    if (filter.status) {
      return reportStatusEnum.map((report, index) => {
        return (
          <MenuItem key={index} value={report}>
            <Checkbox checked={filter.status.indexOf(report) > -1} />
            <ListItemText primary={report} />
          </MenuItem>
        );
      });
    }
    return '';
  }, [filter.status]);

  const shelfStatusCheckboxs = useMemo(() => {
    if (filter.shelfStatus) {
      return shelfStatusEnum.map((shelfStatus, index) => {
        return (
          <MenuItem key={index} value={shelfStatus}>
            <Checkbox checked={filter.shelfStatus.indexOf(shelfStatus) > -1} />
            <ListItemText primary={shelfStatus} />
          </MenuItem>
        );
      });
    }
    return '';
  }, [filter.shelfStatus]);

  const renderStickyFooter = useMemo(() => {
    if (tableData && tableData.length > 0) {
      return <StickyFooter actionButton={actionsButtons} />;
    }
    return '';
  }, [actionsButtons, tableData]);

  return (
    <div className="pages-content" id="relatorios-abastecimento">
      <div className="page-container">
        <div className="page-title-row">
          <Typography variant="h1">{pageTitle}</Typography>

          {renderDate}
        </div>
        <div className="reverse-table">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '26px',
            }}
          >
            <FilterList style={{ color: '#68788C', marginRight: '3px' }} />
            <Typography variant="h1">{dictionary.FILTERS}</Typography>
          </div>

          
          {renderRetailFilter}

          <Grid container spacing={3}>
            {renderStoreFilter}
            
            <Grid item md={3} sm={4}>
              <Autocomplete
                required={false}
                label={dictionary.SECTOR}
                value={filter.group}
                options={groups}
                disabled={groups.length === 0}
                onChange={async (e, value) => {
                  setFilter({ ...filter, group: value, subgroup: null });
                  
                  if (exist(value)) {
                    mainStore.requestLoading = true;
                    const { data } = await getProductSubGroupsByGroup(
                      value.id,
                      filter.date,
                    );
                    setSubGroups(data);
                    mainStore.requestLoading = false;
                  } 
                  else {
                    setSubGroups([]);
                  }
                }}
                style={inputStyle}
              />
            </Grid>
            
            <Grid item md={3} sm={4}>
            <Autocomplete
                disabled={subGroups.length === 0}
                required={false}
                label={dictionary.CATEGORY}
                value={filter.subgroup}
                onChange={(e, value) => setFilter({ ...filter, subgroup: value })}
                options={subGroups}
                style={inputStyle}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item md={2} sm={4} id="datepicker-filter">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
                <KeyboardDatePicker
                  autoOk
                  invalidDateMessage={dictionary.INVALID_DATE}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label={dictionary.DATE_START_SELECT}
                  maxDate={filter.endDate || new Date()}
                  maxDateMessage={'Deve ser igual ou anterior à data de fim'}
                  value={filter.startDate|| new Date()}
                  onChange={value => onChangeDataFilterHandle({startDate:value})}
                  style={pickerStyle}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={2} sm={4} id="datepicker-filter">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
                <KeyboardDatePicker
                  autoOk
                  invalidDateMessage={dictionary.INVALID_DATE}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label={dictionary.DATE_END_SELECT}
                  minDate={filter.startDate}
                  maxDate={new Date()}
                  minDateMessage={'Deve ser igual ou posterior à data de início'}
                  maxDateMessage={'Deve ser uma data no futuro'}
                  value={filter.endDate|| new Date()}
                  onChange={value => onChangeDataFilterHandle({endDate:value})}
                  style={pickerStyle}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Box id="disponibility-filter" width="auto">
              <Tag
                id="e1"
                label={dictionary.AVAILABILITY}
                value={filter.shelfStatus}
                onChange={handleShelfStatus}
                style={{ width: 'fit-content', minWidth: '200px' }}
                >
                {shelfStatusCheckboxs}
              </Tag>
            </Box>
            <Box id="status-filter" width="auto">
            <Tag
                id="e2"
                label={dictionary.RESTOCK_STATUS}
                value={filter.status}
                onChange={handleReportStatus}
                style={{ minWidth: '220px', }}
                >
                {reportStatusCheckboxs}
              </Tag>
            </Box>
          </Grid>
          

          <Grid container>
            <Grid item xs style={{ margin: '26px 0' }}>
            <DefaultButton
                label={dictionary.SEARCH}
                color="primary"
                onClick={onSubmitForm}
                style={{ width: 'auto' }}
              />
              <CancelButton
                label={dictionary.CLEAR}
                onClick={() => clearFilter()}
                style={{ marginLeft: '30px' }}
              />
            </Grid>
          </Grid>
          
          <RestockReportsTable data={tableData} />
        </div>
      </div>

      {renderStickyFooter}
      {snackBar}

      <Loading isOpen={mainStore.requestLoading} />
    </div>
  );
};

export default view(ShelfBreaksReports);
