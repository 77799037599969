import React from 'react';
import { Typography, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DashTooltipText } from '../../../../../../components/Cards';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      padding: '10px 15px',
      margin: '20px 0',
      fontSize: 12,
    },
    arrow: {
      width: '20px',
      height: '15px',
      marginTop: '-15px !important',
      "&:before": {
        backgroundColor: theme.palette.common.white,
      },
      color: theme.palette.common.white
    }
}))(Tooltip);

const formatCurrencyValue = currency => {
    let string = (currency >= 0) ? 'R$' : '-R$';
    string+= Math.abs(currency).toLocaleString(`pt-BR`, {
        minimumFractionDigits: 2
    });

    return string;
}

const cardDetailItem = props => {
    const { item, value, sellout } = props;
    
    return (
        <LightTooltip arrow placement="bottom-start" title={ 
            <React.Fragment>
                <Typography color="inherit">
                    Filtrar produtos
                </Typography>
                <DashTooltipText>
                    <strong>{ value }</strong> { item }
                </DashTooltipText>
                <DashTooltipText>
                    <strong>{ formatCurrencyValue(sellout) }</strong> venda estimada
                </DashTooltipText>
            </React.Fragment>
        }>
            <span>
                <strong>{ value }</strong> { item }
            </span>
        </LightTooltip>
    );
}

export default cardDetailItem;