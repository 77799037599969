import React, { useEffect, useState } from 'react';
import { view } from 'react-easy-state';
import { useParams } from 'react-router';
import { CalendarPeriodTableRow } from '../common/types';
import { PromotionalCalendarForm } from '../common/components';
import { getPromotionalCalendarById } from '../../../core/services/promotionalSpots/promotionalCalendarsService';
import { getPromotionalCalendarPeriods } from '../../../core/services/promotionalSpots/promotionalCalendarPeriodsService';
import * as dictionary from '../../../core/constants/dictionary';
import { pageTitle, parseResultsPeriodsForCurrentTimezone } from './utils';

type RouterParams = {
  id: string;
};

const EditPromotionalCalendar = () => {
  const { id } = useParams<RouterParams>();
  const [name, setName] = useState('');
  const [periods, setPeriods] = useState<CalendarPeriodTableRow[]>([]);
  const [isActiveChecked, setIsActiveChecked] = useState(false);

  const fetchData = async () => {
    {
      const { data } = await getPromotionalCalendarById(id);
      setName(data.name);
      setIsActiveChecked(!!data.status);
    }
    {
      const { data } = await getPromotionalCalendarPeriods(id);

      setPeriods(data.map(parseResultsPeriodsForCurrentTimezone));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PromotionalCalendarForm
      isEditMode
      pageTitle={pageTitle}
      data={{
        name,
        periods,
        isActiveChecked,
      }}
      feedbackSuccess={dictionary.EDIT_CALENDAR_SUCCESS}
      feedbackError={dictionary.EDIT_CALENDAR_ERROR}
    />
  );
};

export default view(EditPromotionalCalendar);
