import API from '../api/config';
import * as routes from '../api/routes';
import { exist } from '../../validation';

export const getDocumentsAPI = (userId, type) => {
  const typeParam = exist(type) ? `?download=true&type=${type}` : '';

  const url = `${routes.API_USERS}/${userId}${routes.API_DOCUMENTS}${typeParam}`;

  return API.get(url);
};

export const getUserAPI = async (
  retailId,
  profile,
  status,
  offset,
  limit,
  ignoreProfiles,
  nameOrCpfSearched,
  storeId,
  retailName,
  cancelToken
) => {
  const params = new URLSearchParams({
    offset: offset || 0,
    limit: limit || 0,
  });

  if (exist(nameOrCpfSearched)) {
    params.set('q', nameOrCpfSearched);
  }

  if (ignoreProfiles && ignoreProfiles.length > 0) {
    params.set('ignoreProfiles', ignoreProfiles);
  }

  const otherParams = {
    retailId,
    status,
    profile,
    retailName,
    storeId,
  };

  for (const key in otherParams) {
    if (exist(otherParams[key])) {
      params.set(key, otherParams[key])
    }
  }

  // TODO: transformar em queryParams do navgador
  const url = `${routes.API_USERS}?${params.toString()}`;
  const response = await API.get(url, {cancelToken});

  return response.data;
};

export const postUserAPI = async (data, token) => {
  const url = routes.API_USERS;
  const tokenParam = token;
  const options = { headers: { Authorization: tokenParam } };
  const response = await API.post(url, data, options);
  return response;
};

export const putUserAPI = async (data) => {
  const url = `${routes.API_USERS}/${data.id}`;
  const response = await API.put(url, data);
  return response;
};

export const deleteUserAPI = async (user) => {
  const url = `${routes.API_USERS}/${user.id}`;
  const response = await API.delete(url);
  return response;
};

export const getPromotersByAgreementAPI = async (
  agreementId,
  offset = 0,
  limit = 999999,
  query = null
) => {
  const queryParam = exist(query) ? `&q=${query}` : '';
  const url = `${routes.API_AGREEMENTS}/${agreementId}${routes.API_PROMOTERS}?offset=${offset}&limit=${limit}${queryParam}`;
  const response = await API.get(url);
  return response.data;
};

export const getAswerablesByAgreementAPI = async (agreementId, status) => {
  const statusParam = exist(status) ? `&status=${status}` : '';
  const url = `${routes.API_AGREEMENTS}/${agreementId}${routes.API_ANSWERABLE_USER}?offset=0&limit=999999${statusParam}`;
  const response = await API.get(url);
  return response.data.items;
};

export const getSuppliersByAgreementAPI = async (agreementId, status) => {
  const statusParam = exist(status) ? `&status=${status}` : '';
  const url = `${routes.API_AGREEMENTS}/${agreementId}${routes.API_SUPPLIERS}?offset=0&limit=999999${statusParam}`;
  const response = await API.get(url);
  return response.data.items;
};

export const getPromoterAccessesAPI = async ({
  params,
  retailId,
  offset,
  limit,
  query,
}) => {
  params.set('offset', offset);
  params.set('limit', limit);
  if (exist(retailId)) {
    params.set('retailId', `${retailId}`);
  }
  if (exist(query)) {
    params.set('query', query);
  }
  const options = { ...{ params } };
  const response = await API.get(routes.API_PROMOTER_ACCESSES, options);
  return response.data;
};

export const getAccessesAPI = async (retailId, promoterUserId) => {
  const {
    data: { items },
  } = await API.get('/accesses', {
    params: {
      retailId,
      promoterUserId,
    },
  });
  return items;
};

export const getPromoterByIdOrCpfAPI = async (idOrCpf) => {
  const { data } = await API.get(`${routes.API_PROMOTERS}/user`, {
    params: {
      q: idOrCpf,
    },
  });
  return data;
};
