import React from 'react';
import MaskedInput from 'react-text-mask';
import { cpfMask, cnpjMask, cepMask } from './masksRegex';

export const CpfTextMaskCustom = props => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={cpfMask}
      placeholderChar={'\u2000'}
      showMask
    />
  );
};

export function CnpjTextMaskCustom(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={cnpjMask}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

export function CepTextMaskCustom(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={cepMask}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
