import React, { useState, useMemo, useCallback } from 'react';
import {
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
} from '@material-ui/core';
import TextField from '../../../../components/InputTextField';
import * as dictionary from '../../../../core/constants/dictionary';
import { mainStore } from '../../../../core/store/mainStore';
import CancelButton from '../../../../components/Buttons/Cancel';
import DefaultButton from '../../../../components/Buttons/Default';
import { utils } from '../../../../core/utils';
import { SyncValidations } from '../../../../core/validator/validations';
import { CnpjTextMaskCustom } from '../../../../core/masks/masks';
import { editRetail, getRetails } from '../../functions';
import { trackRetailUpdate } from '../../../../core/mixpanel/mixPanelEvents';

export const EditRetail = ({ closeModalCallback, currentRetail }) => {
  const [retail, setRetail] = useState(currentRetail);

  const changeValueField = useCallback(
    (field, value) => {
      setRetail({ ...retail, [field]: value });
    },
    [retail],
  );

  const checkBoxStatus = useMemo(() => {
    return (
      <Checkbox
        checked={retail.status === 1}
        onChange={() => {
          changeValueField('status', utils.invertValue(retail.status));
        }}
      />
    );
  }, [changeValueField, retail.status]);

  const submitRetail = useCallback(
    async event => {
      event.preventDefault();
      if (event.currentTarget.form.reportValidity()) {
        try {
          await editRetail(retail);

          const retailMixpanelDTO = {
            retail: {
              name: retail.name,
              status: retail.status,
              cnpj: retail.cnpj,
            },
          };

          trackRetailUpdate(retailMixpanelDTO);
          const result = await getRetails();
          setRetail({});
          mainStore.retails.data = result;
          closeModalCallback();
          utils.openSnackBar(dictionary.SUCCESS, dictionary.RETAIL_EDITED);
        } catch (e) {
          if (e.response.data.code === 2001) {
            utils.openSnackBar(dictionary.ERROR, dictionary.DUPLICATED_CNPJ);
          } else {
            utils.openSnackBar(dictionary.ERROR, dictionary.UNKNOWN_ERROR);
          }
        } finally {
          mainStore.requestLoading = false;
        }
      } else {
        utils.openSnackBar(dictionary.ERROR, dictionary.FORM_INVALID);
      }
    },
    [closeModalCallback, retail],
  );

  return (
    <form noValidate>
      <DialogContent
        style={{
          style: {
            flexDirection: 'column',
            display: 'flex',
            overflow: 'hidden',
          },
        }}
      >
        <TextField
          validations={[SyncValidations.notEmpty]}
          style={{ width: '100%' }}
          label={dictionary.RETAIL_NAME}
          value={retail.name}
          onChange={e => changeValueField('name', e.target.value)}
        />

        <TextField
          validations={[SyncValidations.notEmpty, SyncValidations.validCNPJ]}
          style={{ width: '100%' }}
          label={dictionary.RETAIL_CNPJ}
          value={retail.cnpj}
          onChange={e => changeValueField('cnpj', e.target.value)}
          InputProps={{ inputComponent: CnpjTextMaskCustom }}
        />

        <FormControlLabel
          style={{ margin: '9px 0 9px 0' }}
          control={checkBoxStatus}
          label={dictionary.ACTIVE_RETAIL}
        />
      </DialogContent>

      <DialogActions>
        <CancelButton
          onClick={() => {
            closeModalCallback();
          }}
        />

        <DefaultButton
          onClick={event => submitRetail(event)}
          color="primary"
          style={{ width: 'auto' }}
          label={dictionary.SAVE}
        />
      </DialogActions>
    </form>
  );
};
