import {
  getVisitsAPI,
  getVisitsPaginatedAPI,
  checkOutVisitAPI,
  checkInVisitAPI,
  sendEvaluationAPI,
  getPromotersEngagementAPI,
  getVisitsIndicatorsAPI,
  getVisitsHistoryAPI
} from './visitsAPI';

export const getVisits = params => {
  return getVisitsAPI(params);
};

export const getVisitsPaginated = params => {
  return getVisitsPaginatedAPI(params);
};

export const getVisitsIndicators = params => {
  return getVisitsIndicatorsAPI(params);
};

export const checkOutVisit = dto => {
  return checkOutVisitAPI(dto);
};

export const checkInVisit = dto => {
  return checkInVisitAPI(dto);
};

export const sendEvaluation = ({
  score,
  description = '',
  reason = null,
  visitId,
}) => {
  return sendEvaluationAPI({
    score,
    description,
    reason,
    visitId,
  });
};

export const getPromotersEngagement = params => {
  return getPromotersEngagementAPI(params);
};

export const getVisitsHistory = params => {
  return getVisitsHistoryAPI(params);
};