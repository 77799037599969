
import React from 'react';
import { IconButton, MenuItem } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import styles from '../style.module.scss';

const OpenedMenuItem = ({ item, isActive, onClickItem=()=>{} }) => {
  const classNameValue = `
    ${styles.menuItem} 
    ${styles.menuItemOpen} 
    ${isActive ? styles.active : ''} 
    ${item.goBack ? styles.goBack : ''}
  `;
  
  return (
    <div className={classNameValue} key={item.name}>
      {
        item.icon && (
          <div className={styles.menuItemWithIcon}>
            { 
              item?.goBack && (
              <IconButton
                className={styles.goBackAnchor}
                onClick={() => item?.onClick && item.onClick()}
              >
                <ArrowBackIosIcon style={{ color: 'white' }} />
              </IconButton>
            )}
            <img alt={item.name} src={item.icon} />
          </div>
      )}
      <MenuItem
        key={item.name}
        className={classNameValue}
        onClick={(item) => onClickItem(item)}
      >
        {item.name}
      </MenuItem>
    </div>
  );
};

export default OpenedMenuItem;