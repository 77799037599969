import React, { useEffect } from 'react';
import { useField } from 'formik';
import { FormGroup } from '@involves/violin';
import InputMask from 'react-input-mask';
import { InputDropdown } from '../../InputDropdown';

const FormInputDropdown = ({ children, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { setValue: setFieldValue } = helpers;
  const { id, name, label, required, helpLabel, helpText, validateOnTouch } = props;
  const hasError = validateOnTouch ? (!!meta.error && meta.touched) : (!!meta.error);
  
  useEffect(() => {
    let mounted = true;
    if (field.value === null && mounted) {
      setFieldValue('');
    }

    return () => { mounted = false };
  }, [field.value, setFieldValue]);

  return (
    <FormGroup
      htmlFor={id || name}
      label={label}
      helpLabel={required ? 'Campo obrigatório' : helpLabel}
      error={hasError}
      helpText={(hasError && meta.error) || helpText}
    >
      <InputMask
        {...field}
        {...props}
        value={field.value || ''}
        error={hasError}
        required={required}
      >
        {inputProps => {
          return (
            <InputDropdown {...props} {...inputProps} value={field.value || ''}>
              {children}
            </InputDropdown>
          );
        }}
      </InputMask>
    </FormGroup>
  );
};

export { FormInputDropdown };
