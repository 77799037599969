import React from 'react';
import { mainStore } from '../../../../../core/store/mainStore';
import { FormFilters } from '../../../NegotiationsPanel/components/FormFilter/utils';

const useRetailInUser = (context = 'pexFilters') => {
  const retailInUser = () => {
    const localStorageFilters = localStorage.getItem(context);
    if (localStorageFilters) {
      const pexFilters: FormFilters = JSON.parse(localStorageFilters);
      return pexFilters?.retailId ?? mainStore?.loggedUser?.retailId;
    }
    return mainStore?.loggedUser?.retailId;
  };
  return retailInUser();
};

export default useRetailInUser;
