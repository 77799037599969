import { colors } from '../../../../Theme/colors';
import { SortDirection } from '@material-ui/core';
import { Item } from '../../../../core/shared/missions';
import { CheckStatus } from '../../../../core/types/check-status.enum';
import { SaleStatus } from '../../../../core/types/sale-status.enum';
import { ShelfStatus } from '../../../../core/types/shelf.status.enum';
import { SupplyStatus } from '../../../../core/types/supply-status.enum';

type MissionOverviewItem = Omit<Item, 'restock'> & {
  supply: {
    status: SupplyStatus | null;
    virtualStock: number | null;
  };
};

export type MissionsOverviewTableProps = {
  items: MissionOverviewItem[];
  total: number;
  onChangePage: Function;
  onChangeRowsPerPage: Function;
  page: number;
  rowsPerPage: number;
  loading: boolean;
  order: SortDirection,
  orderBy: string,
  onSort: Function,
};


export const getSaleSignal = (saleStatus: SaleStatus) => {
  if (saleStatus === SaleStatus.LOSS) {
    return '-';
  }

  if (saleStatus === SaleStatus.GAIN) {
    return '+';
  }

  return '';
};

export const getDotColor = (saleStatus: SaleStatus) => {
  if (saleStatus === SaleStatus.LOSS) {
    return colors.red_600;
  }

  if (saleStatus === SaleStatus.GAIN) {
    return colors.green_500;
  }

  return colors.black_500;
};

export const calculateSaleStatus = ({ check, restock }: Item) => {
  if (
    check.status === CheckStatus.PENDING ||
    check.status === CheckStatus.FULL_SHELF
  ) {
    return SaleStatus.NEUTRAL;
  }

  if (restock.status === SupplyStatus.DONE) {
    return SaleStatus.GAIN;
  }

  return SaleStatus.LOSS;
};

export const getShelfStatusDescription = ({ check }: Item): string => {
  switch (check.status) {
    case CheckStatus.PENDING:
      return 'Sem conferência';
    case CheckStatus.FULL_SHELF:
      return 'Gôndola cheia';
    case CheckStatus.NEED_SUPPLY:
      if (check.shelfStatus === ShelfStatus.LOW_QUANTITY) {
        return 'Pouco abastecido';
      }
      return 'Em ruptura';
  }
  return '-';
};

export const formatShoppersPerDay = (value: number): string => {
  let valueHandler = 0;
  if(value) {
    valueHandler = value;
  }
  
  return valueHandler.toFixed(2);
}