import React from 'react';
import { Dialog } from '@material-ui/core';

const ModalSmallContainer = ({
  isOpen,
  children,
  closeModalCallback,
  overflowY = 'hidden',
}) => (
  <Dialog
    fullWidth
    open={isOpen}
    PaperProps={{ style: { padding: '20px', overflowY } }}
    onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        closeModalCallback(event, reason);
      }
    }}
  >
    {children}
  </Dialog>
);

export default ModalSmallContainer;
