import { startOfDay, endOfDay, subDays, format, parseISO } from 'date-fns';
import { mainStore } from '../../core/store/mainStore';
import { 
  CheckinAmount, 
  VisitHistoryDTO, 
  CheckinAmountPerWeekDay,
  FormattedVisitHistoryDTO, 
} from './types';

export const initialVisitsHistoryState = {
  checkinAmount: [], 
  averageCheckinAmountPerWeekDay: [],
}

export const initialVisitsIndicatorsState = {
  averageDuration: 0,
  checkinAmount: 0,
  checkoutAmount: 0,
  checkoutPercentage: null,
  suppliersAmount: 0,
}

export const INITIAL_FILTERS = {
  retailId:
    mainStore.loggedUser && mainStore.loggedUser.retailId
      ? [mainStore.loggedUser.retailId]
      : [],
  storeId:
    mainStore.loggedUser && mainStore.loggedUser.storeId
      ? [mainStore.loggedUser.storeId]
      : [],
  supplierId: [],
  query: null,
  startDate: startOfDay(subDays(new Date(), 7)),
  endDate: endOfDay(new Date()),
};

const castValue = (value: string) => {
  return Number(parseFloat(value).toFixed(2));
};

const formatCheckinAmountChart = (data: CheckinAmount[]) => {
  return data.map((row) => ({
    x: format(parseISO(row.date), 'dd/MM'),
    y: castValue(row.value),
  }));
};

const formatAverageCheckinAmountChart = (data: CheckinAmountPerWeekDay[]) => {
  const days = [
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
    'Domingo',
  ];
  return data.map((row) => ({
    x: days[row.weekDay],
    y: castValue(row.value),
  }));
};

export const formatChart = (apiResponse: VisitHistoryDTO): FormattedVisitHistoryDTO => {
  const { averageCheckinAmountPerWeekDay, checkinAmount } = apiResponse;
  return {
    checkinAmount: formatCheckinAmountChart(checkinAmount),
    averageCheckinAmountPerWeekDay: formatAverageCheckinAmountChart(
      averageCheckinAmountPerWeekDay
    ),
  };
};

const secondsToHms = (secs: number) => {
  secs = Number(secs);
  const hours = Math.floor(secs / 3600);
  const minutes = Math.floor((secs % 3600) / 60);
  const seconds = Math.floor((secs % 3600) % 60);

  return { hours, minutes, seconds };
};

export const parseDuration = (seconds: number) => {
  const duration = secondsToHms(seconds);
  let { hours, minutes } = duration;
  const hoursString = hours ? hours + 'h' : '';
  const minutesString = minutes ? ' ' + String(minutes).padStart(2, '0') + 'min' : '';

  return (hours || minutes) ? `${hoursString}${minutesString}` : '0';
};

export const styles = {
  tableTitle: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '20px',
    color: '#322550',
    paddingLeft: '16px',
  },
  positionColumn: {
    width: '50px',
  },
  agreementCodeColumn: {
    width: '80px',
  },
  supplierColumn: {
    width: '400px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tablePagination: {
    paddingLeft: '26px',
  },
};
