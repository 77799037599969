import React from 'react';

type Props = {
  inputName?: string;
}

const DisableGoogleAutocomplete = ({ inputName = "fake-password" }: Props) => (
  <input 
    type="password" 
    name={inputName}
    autoComplete='new-password' 
    tabIndex={-1} 
    style={{
      width: 0,
      height: 0, 
      opacity: 0, 
      float: 'left', 
      border: 'none', 
    }} 
  />
);

export default DisableGoogleAutocomplete;