import React, { MouseEventHandler, ReactNode } from 'react';
import { Button, DialogActions } from '@material-ui/core';
import ModalTitle from '../../../../../../../components/Modal/Title';
import ModalSmallContainer from '../../../../../../../components/Modal/SmallContainer';
import { CancelButton } from '../../../../../../../components/Buttons';
import classes from './Modals.module.scss';
import * as dictionary from '../../../../../../../core/constants/dictionary';

type Props = {
  title: string;
  isOpen: boolean;
  children: ReactNode;
  isDisabled?: boolean;
  onClose: Function;
  onClick?: MouseEventHandler;
  onDelete?: MouseEventHandler;
  label?: {
    cancel?: string;
    submit?: string;
    delete?: string;
  }
  actions?: {
    cancel?: boolean;
    submit?: boolean;
    delete?: boolean;
  }
}

const BaseModal = ({ 
  title, 
  label, 
  isOpen, 
  actions,
  children, 
  onClose, 
  onClick,
  onDelete,
  isDisabled = false,
}: Props) => {
  const baseLabels = {
    submit: dictionary.SAVE,
    delete: dictionary.DELETE,
    cancel: dictionary.CANCEL,
    ...label,
  };
  const baseActions = {
    submit: true,
    delete: false,
    cancel: true,
    ...actions,
  };

  return (
    <ModalSmallContainer isOpen={isOpen} closeModalCallback={onClose}>
      <ModalTitle 
        title={title}
        className={classes.modalTitle}
      />
      <div className={classes.formBody}>
        { children }
      </div>
      {
        baseActions.delete ? (
          <div className={classes.footer}>
            <DialogActions style={{ justifyContent: 'space-between' }}>
              <Button onClick={onDelete} className={classes.deleteButton}>
                { baseLabels.delete }
              </Button>
              <CancelButton label={baseLabels.cancel} onClick={onClose} />
            </DialogActions>
          </div>
        ) : (
          <DialogActions className={classes.footer}>
            {
              baseActions.cancel && (
              <CancelButton
                label={baseLabels.cancel}
                onClick={onClose} 
              />)
            }
            {
              baseActions.submit && (
              <Button 
                color='primary' 
                variant='contained' 
                disabled={isDisabled}
                onClick={onClick}
              >
                { baseLabels.submit }
              </Button>)
            }
          </DialogActions>
        )
      }
    </ModalSmallContainer>
  );
}

export default BaseModal;
