import React, { ChangeEvent, useMemo, useRef } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import Edit from '../../../../assets/tables/edit.svg';
import Remove from '../../../../assets/tables/remove.svg';
import IconTable from '../../../../components/Tables/IconTable';
import PatchedPagination from '../../../../components/PatchedPagination';
import { profileCheck } from '../../../../core/profiles';
import { AddressingDto } from '../../../../core/types/promotional-spot/promotional-addressing.dto';
import { EmptyDataMessageRow } from '../../../../components/Tables/DefaultTable';
import { translateAPIToPromotionalSpotType } from '../../common/utils';
import * as dictionary from '../../../../core/constants/dictionary';
import { ModalType } from '../types';

type Props = {
  data: AddressingDto[];
  page: number;
  total: number;
  rowsPerPage: number;
  searchTextValue: string;
  toolbarInputFocus: boolean;
  handleOpenModal: Function;
  onChangePageHandler: Function;
  onChangeSearchHandler?: Function;
  onChangeRowsPerPageHandler: Function;
  showEdit?: boolean;
};

const AddressingsTable = ({
  data,
  handleOpenModal,
  page,
  total,
  rowsPerPage,
  searchTextValue,
  toolbarInputFocus,
  onChangePageHandler = () => {},
  onChangeSearchHandler = () => {},
  onChangeRowsPerPageHandler = () => {},
  showEdit = false,
}: Props) => {
  const columns = [
    {
      field: 'type',
      title: dictionary.PROMOTIONAL_SPOT_TYPE,
      type: 'string' as 'string',
      render: (data: any) => translateAPIToPromotionalSpotType(data.spot.type),
    },
    {
      field: 'storeName',
      title: dictionary.PROMOTIONAL_SPOT_NAME,
      type: 'string' as 'string',
      render: (data: any) => data.spot.name,
    },
    {
      field: 'physicalAddressing',
      title: dictionary.ADDRESSING,
      type: 'string' as 'string',
    },
  ];

  const renderStoreColumn = useMemo(() => {
    const storeColumn = {
      field: 'store',
      title: dictionary.STORE,
      type: 'string' as 'string',
      render: (data: any) => data.store.name,
    };
    if (!profileCheck.isStoreManager()) {
      columns.unshift(storeColumn);
      return columns;
    }
    return columns;
  }, [columns]);

  const tableRef = useRef(null);
  const actions = useMemo(
    () =>
      showEdit
        ? [
            (rowData: object) => {
              return {
                icon: () => <IconTable icon={Edit} />,
                tooltip: dictionary.EDIT_ADDRESSING,
                onClick: (_evt: any, actionData: any) =>
                  handleOpenModal(ModalType.EDIT, actionData),
              };
            },
            (rowData: object) => {
              return {
                icon: () => <IconTable icon={Remove} />,
                tooltip: dictionary.DELETE_ADDRESSING,
                onClick: (_evt: any, rowData: any) =>
                  handleOpenModal(ModalType.DELETE, rowData),
              };
            },
          ]
        : [],
    [handleOpenModal, showEdit]
  );

  return (
    <MaterialTable
      title={''}
      data={data}
      page={page}
      key={data.length}
      actions={actions}
      tableRef={tableRef}
      columns={renderStoreColumn}
      options={{
        actionsColumnIndex: -1,
        toolbarButtonAlignment: 'left',
        pageSize: rowsPerPage,
        pageSizeOptions: [5, 10, 20],
      }}
      localization={{
        header: {
          actions: '',
        },
        body: {
          emptyDataSourceMessage: (
            <EmptyDataMessageRow
              message={dictionary.EMPTY_CALENDAR_PERIODS_TABLE}
            />
          ),
        },
        toolbar: {
          searchPlaceholder: dictionary.SEARCH,
        },
      }}
      components={{
        Pagination: (props) => (
          <PatchedPagination
            {...props}
            page={page}
            count={total}
            rowsPerPage={rowsPerPage}
            onChangePage={(evt: any, page: number) => onChangePageHandler(page)}
            onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
              onChangeRowsPerPageHandler(parseInt(event.target.value));
              props.onChangeRowsPerPage(event);
            }}
          />
        ),
        Toolbar: (props) => {
          if (onChangeSearchHandler) {
            return (
              <MTableToolbar
                {...props}
                searchText={searchTextValue}
                searchAutoFocus={toolbarInputFocus}
                onSearchChanged={(value: string) =>
                  onChangeSearchHandler(value)
                }
              />
            );
          }

          return <MTableToolbar {...props} />;
        },
      }}
    />
  );
};

export default AddressingsTable;
