import React, { useMemo } from 'react';
import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import * as dictionary from '../../../../core/constants/dictionary';
import ModalTitle from '../../../../components/Modal/Title';
import CancelButton from '../../../../components/Buttons/Cancel';
import DefaultButton from '../../../../components/Buttons/Default';

type Props = {
  currentPromoter: any;
  closeModalCallback: Function;
  removePromoter: Function;
}
export const DeleteContentModal = ({
  currentPromoter,
  closeModalCallback,
  removePromoter,
}: Props) => {
  const content = useMemo(() => {
    return (
      <>
        <ModalTitle title={dictionary.DELETE_PROMOTER_ACCESS} />

        <DialogContent>
          <Typography variant="body2">
            {dictionary.CONFIRM_PROMOTER_ACCESS_REMOVAL}
            <span style={{ fontWeight: 'bold', marginLeft: '0.1rem' }}>
              {currentPromoter.promoterUserName}
            </span>
            <span>{dictionary.TO_RETAIL}</span>
            <span style={{ fontWeight: 'bold', marginLeft: '0.1rem' }}>
              {currentPromoter.retailName}
            </span>
          </Typography>
        </DialogContent>

        <DialogActions>
          <CancelButton onClick={() => closeModalCallback()}/>

          <DefaultButton
            label={dictionary.DELETE}
            style={{ width: 'auto' }}
            onClick={() => {
              removePromoter(currentPromoter);
              closeModalCallback();
            }}
          />
        </DialogActions>
      </>
    );
  }, [closeModalCallback, currentPromoter, removePromoter]);

  return content;
};
