import React, { useCallback, useMemo, useState } from 'react';
import { mainStore } from '../core/store/mainStore';
import { getAllRetails } from '../core/services/retails/retailsService';
import { OrderCriteriaNum } from '../core/constants/order-criteria';
import { RetailEntityType } from '../core/types/retail/retail-entity.type';

export const useRetailList = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [retails, setRetails] = useState<RetailEntityType[]>();
  const [error, setError] = useState<any>();
  
  const retailInUser = useMemo(
    () => mainStore?.loggedUser?.retailId,
    [mainStore?.loggedUser]
  );

  const fetch = useCallback(async () => {
    if (!isLoading) {
      try {
        const retails = await getAllRetails({
          orderBy: 'name',
          order: OrderCriteriaNum.ASC,
        });
        setRetails(retails);
        setError(undefined);
      } catch (e) {
        setError(e);
        setRetails([]);
      } finally {
        setIsLoading(false);
      }
    }
  }, [isLoading]);

  return { retails, isLoading, error, fetch, retailInUser };
};
