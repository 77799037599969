import React from 'react';
import doorsLogo from '../../assets/logos/doors-logo.svg';
import doorsLogoWhite from '../../assets/logos/full-logo-white.svg';
import { INVOLVES_DOORS_LOGO } from '../../core/constants/dictionary';
import useStyles from './material-style-override';
import styles from './style.module.scss';

const DrawerHeader = ({ isOpened }) => {
  const classes = useStyles();

  return (
    <div className={`${styles.header} ${classes.openToolbar}`}>
      <img
        src={isOpened ? doorsLogoWhite : doorsLogo}
        alt={INVOLVES_DOORS_LOGO}
        className={styles.logo}
      />
    </div>
  );
}

export default DrawerHeader;
