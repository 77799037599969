import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { view } from 'react-easy-state';
import { Typography } from '@material-ui/core';
import WelcomePage from '../../components/WelcomePage';
import Loading from '../../components/Loading';
import BoldText from '../../components/TextsElements/BoldText';
import PageContent from '../../components/PageContent';
import DefaultButton from '../../components/Buttons/Default';
import StickyFooter from '../../components/stickyFooter/stickyFooter';
import ModalContainer from '../../components/Modal/Container';
import ModalSmallContainer from '../../components/Modal/SmallContainer';
import { UserTable } from './components/userTable';
import CreateUser from './modals/createUser/createUser';
import EditUser from './modals/editUser/editUser';
import RemoveUser from './modals/removeUser/removeUser';
import { mainStore } from '../../core/store/mainStore';
import { useDebounce } from '../../core/hooks';
import { serializeCpfAndNameSearch } from '../../core/utils';
import { DEBOUNCE_DELAY_ON_FETCH_USER } from '../../core/constants/user';
import * as dictionary from '../../core/constants/dictionary';
import { UserInfo, fetchAllNotPromotersUsers } from './utils';

const Users = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState<string | null>(null);
  const [currentUser, setCurrentUser] = useState<UserInfo | null>(null);
  const [usersData, setUsersData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [nameOrCpfPart, setNameOrCpfPart] = useState('');
  const [retailFilter, setRetailFilter] = useState<string>('');
  const [toolbarInputFocus, setToolbarInputFocus] = useState(false);
  const [hasInitialContent, setHasInitialContent] = useState(false);

  useEffect(() => {
    if (usersData.length > 0 && !hasInitialContent) {
      setHasInitialContent(true);
    }
  }, [usersData, hasInitialContent]);

  const fetchUsers = async (
    query = nameOrCpfPart,
    retailName = retailFilter
  ) => {
    mainStore.requestLoading = true;
    const offset = page * rowsPerPage;
    const limit = rowsPerPage;
    const queryString = serializeCpfAndNameSearch(query);

    const response = await fetchAllNotPromotersUsers({
      offset,
      limit,
      queryString,
      retailName,
    });
    setTotal(response.count);
    setUsersData(response.items);
    mainStore.requestLoading = false;
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, nameOrCpfPart, retailFilter]);

  const closeModal = useCallback(() => {
    setModalType(null);
    setOpenModal(false);
    setCurrentUser(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createModal = useMemo(() => {
    return (
      <ModalContainer closeModalCallback={closeModal} isOpen={openModal}>
        <CreateUser
          isOpen={openModal}
          onClose={closeModal}
          onReload={fetchUsers}
        />
      </ModalContainer>
    );
  }, [closeModal, openModal]);

  const editModal = useMemo(() => {
    return (
      <ModalContainer closeModalCallback={closeModal} isOpen={openModal}>
        <EditUser
          isOpen={openModal}
          currentUser={currentUser}
          onClose={closeModal}
          onReload={fetchUsers}
        />
      </ModalContainer>
    );
  }, [closeModal, currentUser, openModal, fetchUsers]);

  const removeModal = useMemo(() => {
    if (!currentUser) {
      return null;
    }

    return (
      <ModalSmallContainer closeModalCallback={closeModal} isOpen={openModal}>
        <RemoveUser
          currentUser={currentUser}
          onClose={closeModal}
          onReload={fetchUsers}
        />
      </ModalSmallContainer>
    );
  }, [closeModal, currentUser, openModal]);

  const renderModal = useMemo(() => {
    const modals = {
      [dictionary.CREATE as string]: createModal,
      [dictionary.EDIT_USER as string]: editModal,
      [dictionary.REMOVE_USER as string]: removeModal,
    };

    if (modalType !== null) {
      return modals[modalType];
    }

    return null;
  }, [createModal, editModal, modalType, removeModal]);

  const handleOpenModal = useCallback((modalType, currentUser = null) => {
    setModalType(modalType);
    setOpenModal(true);
    setCurrentUser(currentUser);
  }, []);

  const debouncedOnSearch = useDebounce((inputValue: string[]) => {
    const queryString = String(inputValue[0]);
    if (queryString === '' || queryString.length >= 3) {
      setPage(0);
      setNameOrCpfPart(serializeCpfAndNameSearch(queryString));
    }
  }, DEBOUNCE_DELAY_ON_FETCH_USER);

  const onChangeRetailFilter = useCallback(
    useDebounce((value: string[]) => {
      const search = String(value[0]);
      if (search === '' || search.length >= 3) {
        setPage(0);
        setRetailFilter(search);
      }
    }, DEBOUNCE_DELAY_ON_FETCH_USER),
    [retailFilter]
  );

  const content = useMemo(() => {
    if (usersData.length === 0 && !hasInitialContent) {
      return (
        <WelcomePage
          textButton={dictionary.ADD_USER}
          handleButton={() => handleOpenModal(dictionary.CREATE)}
        >
          <Typography variant="body1">
            {dictionary.WELCOME_ADD_STORE}
            <BoldText value={dictionary.NEW_USER} />
          </Typography>
        </WelcomePage>
      );
    }
    return (
      <div className="reverse-table">
        <UserTable
          key="users-table"
          data={usersData}
          count={total}
          pageIndex={page}
          rowsPerPage={rowsPerPage}
          searchTextValue={nameOrCpfPart}
          toolbarInputFocus={toolbarInputFocus}
          handleOpenModal={handleOpenModal}
          onChangePageHandler={(page: number) => setPage(page)}
          onChangeRowsPerPageHandler={(value: number) => {
            setPage(0);
            setRowsPerPage(value);
          }}
          onChangeRetailFilter={(value: string) => onChangeRetailFilter(value)}
          onSearchChangeHandler={(value: string) => debouncedOnSearch(value)}
        />
      </div>
    );
  }, [
    page,
    total,
    usersData,
    rowsPerPage,
    nameOrCpfPart,
    hasInitialContent,
    toolbarInputFocus,
    handleOpenModal,
    debouncedOnSearch,
  ]);

  const defaultButton = useMemo(
    () => (
      <div className="button-create-container">
        <DefaultButton
          label={dictionary.ADD_USER}
          onClick={() => handleOpenModal(dictionary.CREATE)}
          style={{ width: 'auto' }}
        />
      </div>
    ),
    [handleOpenModal]
  );

  const renderStickyFooter = useMemo(() => {
    if (usersData && usersData.length > 0) {
      return <StickyFooter actionButton={defaultButton} />;
    }
    return '';
  }, [defaultButton, usersData]);

  useEffect(() => {
    const shouldFocus = nameOrCpfPart.length > 0;
    setToolbarInputFocus(shouldFocus);
  }, [nameOrCpfPart]);

  return (
    <React.Fragment>
      <PageContent title={dictionary.USER}>{content}</PageContent>

      {renderStickyFooter}

      {openModal && renderModal}

      <Loading isOpen={mainStore.requestLoading} />
    </React.Fragment>
  );
};

export default view(Users);
