import React from 'react';
import InvolvesDoorsLogo from '../../components/DoorsLogo';
import classes from './styles.module.scss';

export default (props) => (
    <div className={classes.wrapper}>
        <div className={classes.container}>
            <InvolvesDoorsLogo className={classes.logo} />
            
            <h1 className={classes.title}>{ props.title }</h1>
            { 
                ! props.updateAtBottom && 
                <p className={classes.lastUpdate}>{ props.lastUpdate }</p>
            }
            { props.children }
            { 
                props.updateAtBottom && 
                <p className={[classes.lastUpdate, classes.atBottom].join(' ')}>{ props.lastUpdate }</p>
            }
        </div>
    </div>
);