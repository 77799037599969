import {
  GetPromotionalSpotReportDto,
  GetPromotionalSpotReportParams,
  GetPromotionalSpotReportProblemsParams,
} from './types';
import * as routes from '../api/routes';
import API from '../api/config';
import { AxiosResponse, ResponseType } from 'axios';
import {
  PromotionalSpotReportDto,
  PromotionalSpotReportProblemDto,
} from '../../types/promotional-spot/promotional-spot-repot-data-dto';

export const getPromotionalSpotReportProductsAPI = (
  params?: GetPromotionalSpotReportParams
): Promise<
  AxiosResponse<GetPromotionalSpotReportDto<PromotionalSpotReportDto>>
> => {
  const url = `${routes.API_PROMOTIONAL_SPOT_REPORT_PRODUCTS}`;

  return API.get(url, { params });
};

export const getPromotionalSpotReportReportProductsXLSAPI = (
  params?: GetPromotionalSpotReportParams
) => {
  const url = `${routes.API_PROMOTIONAL_SPOT_REPORT_PRODUCTS}/export`;

  return API.get(url, {
    params: { ...params, limit: undefined },
    responseType: 'arraybuffer' as ResponseType,
  });
};

export const getPromotionalSpotReportProblemsAPI = (
  params?: GetPromotionalSpotReportProblemsParams
): Promise<
  AxiosResponse<GetPromotionalSpotReportDto<PromotionalSpotReportProblemDto>>
> => {
  const url = `${routes.API_PROMOTIONAL_SPOT_REPORT_PROBLEMS}`;

  return API.get(url, { params });
};

export const getPromotionalSpotReportReportProblemsXLSAPI = (
  params?: GetPromotionalSpotReportProblemsParams
) => {
  const url = `${routes.API_PROMOTIONAL_SPOT_REPORT_PROBLEMS}/export`;

  return API.get(url, {
    params: { 
      ...params, 
      offset: undefined, 
      limit: undefined, 
    },
    responseType: 'arraybuffer' as ResponseType,
  });
};
