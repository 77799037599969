import { CSSProperties } from "react";

type StyleSheet = {
  [key: string]: CSSProperties;
}

export const styles: StyleSheet = {
  autocomplete: {
    width: '500px'
  },
  textStyle: {
    fontSize: 16,
    fontWeight: 600,
    paddingLeft: '11px'
  },
  filterTitle: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    padding: '0px 0px 38px 0px'
  },
  filterDiv: { 
    display: 'flex',
    flexDirection: 'row' as 'row',
    gap: '20px',
    paddingBottom: '40px'
  },
  defaultButtonStyle: {
    width: '95px'
  }
}
