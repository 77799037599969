import React, { useCallback } from 'react';
import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import ModalTitle from '../../../../components/Modal/Title';
import { CancelButton, DefaultButton } from '../../../../components/Buttons';
import { utils } from '../../../../core/utils';
import { mainStore } from '../../../../core/store/mainStore';
import { getAllNotPromotersUsers } from '../../../../core/services/users/usersService';
import * as dictionary from '../../../../core/constants/dictionary';
import { removeUser, UserInfo } from '../../utils';

type Props = {
  currentUser: UserInfo;
  onClose: Function;
  onReload: Function;
}

const RemoveUser = ({ currentUser, onClose, onReload }: Props) => {
  const handleRemoveUser = useCallback(async event => {
    event.preventDefault();
    try {
      const response = await removeUser(currentUser);
      if (response.status === 200) {
        const usersResponse = await getAllNotPromotersUsers({
          retailId: mainStore.loggedUser.retailId,
        });
        mainStore.users.data = usersResponse.items
        onClose();
        onReload();
        utils.openSnackBar(dictionary.SUCCESS, dictionary.USER_REMOVED);
      }
    } catch (err) {
      utils.openSnackBar(dictionary.ERROR, dictionary.UNKNOWN_ERROR);
    } finally {
      mainStore.requestLoading = false;
    }
  }, [currentUser, onClose, onReload]);

  return (
    <>
      <ModalTitle title={dictionary.DELETE_USER} />

      <DialogContent>
        <Typography variant="body2">
          {dictionary.CONFIRM_USER_REMOVAL}
          <span style={{ fontWeight: 'bold', marginLeft: '0.1rem' }}>
            {currentUser.name}
          </span>
        </Typography>
      </DialogContent>

      <DialogActions>
        <CancelButton onClick={onClose} />
        <DefaultButton
          style={{ width: 'auto' }}
          label={dictionary.SAVE}
          onClick={(event: any) => handleRemoveUser(event)}
        />
      </DialogActions>
    </>
  );
};

export default RemoveUser;
