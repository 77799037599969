import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { GetPromotionalSpotReportDto } from '../../../../../core/services/promotionalSpots/types';

type Props<RequestParams, Response> = {
  fetchDataRequest: (
    params: RequestParams & { limit: number; offset: number }
  ) => Promise<AxiosResponse<GetPromotionalSpotReportDto<Response>>>;
  fetchXLS: (params?: RequestParams) => Promise<AxiosResponse<any>>;
  fileName: string;
};
function useReport<Response, RequestParams>({
  fetchDataRequest,
  fetchXLS,
  fileName,
}: Props<RequestParams, Response>) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
  const [report, setReport] = useState<Response[]>([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [excelFileURL, setExcelFileURL] = useState('');
  const [filter, setFilter] = useState<RequestParams>();
  const downloadXlsxButton = useRef<HTMLAnchorElement>(null);

  const resetStates = useCallback(() => {
    setReport([]);
    setTotal(0);
    setPage(0);
    setIsLoading(false);
  }, []);

  const fetchData = useCallback(
    async (params: RequestParams) => {
      if (!isLoading) {
        const limit = rowsPerPage;
        const offset = page * rowsPerPage;
        const queryParams = {
          ...params,
          limit,
          offset,
        };
        try {
          setIsLoading(true);
          const { data } = await fetchDataRequest(queryParams);
          setReport(data.items);
          setTotal(data.total);
        } catch (error) {
          resetStates();
        } finally {
          setIsLoading(false);
        }
      }
    },
    [isLoading, rowsPerPage, total, page]
  );

  const fetchXls = useCallback(async () => {
    if (!isLoadingExport) {
      try {
        setIsLoadingExport(true);
        const response = await fetchXLS(filter);
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });
        setExcelFileURL(window.URL.createObjectURL(blob));
      } catch (error) {
      } finally {
        setIsLoadingExport(false);
      }
    }
  }, [isLoadingExport, filter]);

  useEffect(() => {
    if (excelFileURL === '') return;

    if (downloadXlsxButton.current) {
      downloadXlsxButton.current.download = fileName;
      downloadXlsxButton.current.click();
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [excelFileURL, fileName]);

  return {
    isLoading,
    fetchData,
    fetchXls,
    isLoadingExport,
    report,
    page,
    setPage,
    total,
    rowsPerPage,
    setRowsPerPage,
    excelFileURL,
    filter,
    setFilter,
    downloadXlsxButton,
  };
}

export default useReport;
