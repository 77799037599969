import API from '../api/config';
import StoreManagerLoginAPI from '../api/configStoreManagerLogin';
import * as routes from '../api/routes';
import { exist } from '../../validation';

export const getVisitsAPI = async ({ retailId, startDate, endDate, offset = 0, limit = 999999 } = {}) => {
  const offsetParam = `?offset=${offset}`;
  const limitParam = `&limit=$${limit}`;
  const retailParam = exist(retailId) ? `&retailId=${retailId}` : '';
  const startDateParam = exist(startDate) ? `&startDate=${startDate}` : '';
  const endDateParam = exist(endDate) ? `&endDate=${endDate}` : '';
  const url = `${routes.API_VISITS}${offsetParam}${limitParam}${retailParam}${startDateParam}${endDateParam}`;
  const response = await API.get(url);
  return response.data.items;
};

export const getVisitsPaginatedAPI = params => {
  return API.get(routes.API_VISITS, { params });
};

export const getVisitsIndicatorsAPI = async (params) => {
  const url = routes.API_VISITS + routes.API_VISITS_INDICATORS;
  const response = await API.get(url, { params });
  return response.data;
};

export const checkOutVisitAPI = async dto => {
  const url = `${routes.API_VISITS}/checkout`;
  const response = await API.patch(url, dto);
  return response;
};

export const checkInVisitAPI = async dto => {
  const url = `${routes.API_VISITS}`;
  const response = await API.post(url, dto);
  return response;
};

export const sendEvaluationAPI = ({
  score,
  description = '',
  reason = null,
  visitId,
}) => {
  return API.post(`${routes.API_VISITS}/${visitId}/ratings`, {
    score,
    description,
    reason,
    visitId,
  });
};

export const getPromotersEngagementAPI = async (params) => {
  const url = routes.API_VISITS + routes.API_PROMOTERS_ENGAGEMENT;
  const response = await API.get(url, { params });
  return response.data;
};

export const getVisitsHistoryAPI = async (params) => {
  const url = routes.API_VISITS + routes.API_VISITS_HISTORY;
  const response = await API.get(url, { params });
  return response.data;
};

export const liberateAccessByStoreManagerAPI = async (params) => {
  const url = routes.API_VISITS + routes.API_VISITS_LIBERATE_ACCESS;
  const response = await StoreManagerLoginAPI.post(url, { params });
  
  return response;
}