import { startOfDay, endOfDay, subDays } from 'date-fns';

export const marginLeftOffset = 16;

export const styles = {
	autocompleteSupplierFilter: {
		width: 316,
		marginTop: 12,
		marginBottom: 7,
		marginLeft: marginLeftOffset,
	},
	flex: {
		display: 'flex',
	},
	defaultButton: {
		width: 'auto',
	},
	cancelButton: {
		width: 'auto',
		marginLeft: marginLeftOffset,
	},
	datePickerStart: {
		width: 150,
		marginBottom: 27,
	},
	datePickerEnd: {
		width: 150,
		marginLeft: marginLeftOffset,
		marginBottom: 27,
	},
	autocompleteFilter: {
		width: 316,
		marginTop: 12,
		marginBottom: 7,
	},
}

export const parseFilters = filters => {
	return {
		retailId: filters.retail ? filters.retail.id : '',
		storeId: filters.store ? filters.store.id : '',
		supplierId: filters.supplier ? filters.supplier.id : '',
		startDate: filters.startDate,
		endDate: filters.endDate,
	};
}

export const INITIAL_FILTERS = {
	retail: null,
	store: null,
	supplier: null,
	q: null,
	startDate: startOfDay(subDays(new Date(), 7)),
	endDate: endOfDay(new Date()),
};

export const FETCH_VISITS_DEFAULT_PARAM = {
	orderBy: 'checkinAt',
	orderDirection: 'DESC',
};

export const XLSX_REPORT = {
	type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	fileName: 'Visitas.xlsx',
};