import React, { useMemo, } from 'react';
import { Grid, Box, Paper, Typography } from '@material-ui/core';
import { VictoryChart, VictoryAxis, } from 'victory';
import { title as titleStyle } from '../../../styles';
import EmptyChart from './EmptyChart';
import {
	YOU_MUST_CHOOSE_AT_LEAST_TWO_DAYS_WITH_VISITS,
	NO_REGISTERED_VISITS_FOR_APPLIED_FILTERS,
}  from '../../../../../core/constants/dictionary';
import { ChartData, ChartType } from './types';
import { 
	hasValidData,
	reduceChartComponent,
	hasOneEntryForLineChart,
	victoryAxisStyle, 
	victoryDependentAxisStyle,
} from './utils';


type Props = {
	title: string;
	type: ChartType;
	data: ChartData[];
}

const ChartCard = ({ type, title, data = [] }: Props) => {
	const victoryChartComponent = useMemo(
		() => reduceChartComponent(data, type), 
		[data, type]
	);

	const chartContainerComponent = useMemo(() => {
		if (hasValidData(data, type)) {
			return (
				<VictoryChart
					height={280}
					domainPadding={{ x: 20, y: 10 }}
				>
					<VictoryAxis
						tickCount={7}
						style={victoryAxisStyle as object}
					/>
					<VictoryAxis
						dependentAxis
						crossAxis={false}
						tickCount={data.length < 7 ? (data.length + 1) : 7}
						tickFormat={(tick) => `${Math.round(tick)}`}
						style={victoryDependentAxisStyle}
					/>
					{victoryChartComponent}
				</VictoryChart>
			);
		}
		if (hasOneEntryForLineChart(data, type)) {
			return (
				<EmptyChart>
					{ YOU_MUST_CHOOSE_AT_LEAST_TWO_DAYS_WITH_VISITS }
				</EmptyChart>
			);
		}

		return (
			<EmptyChart>
				{ NO_REGISTERED_VISITS_FOR_APPLIED_FILTERS }
			</EmptyChart>
		);
	}, [data, type, victoryChartComponent]);

	return (
		<Paper>
			<Box p={3}>
				<Box mb={2}>
					<Grid
						container
						alignItems="center"
						justifyContent="space-between"
					>
						<Typography variant="subtitle2" style={titleStyle}>
							{title}
						</Typography>
					</Grid>
				</Box>

				{ chartContainerComponent }
			</Box>
		</Paper>
	);
}

export default ChartCard;
