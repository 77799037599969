import React from 'react';
import imageSource from '../../assets/general/warningStore.svg';
import { WARNING_FORGET } from '../../core/constants/dictionary';

type Props = {
  className?: string;
}

const WarningStore = ({ className, ...restProps }: Props) => (
  <img 
    src={imageSource}
    alt={WARNING_FORGET}
    className={className}
    {...restProps}
  />
)

export default WarningStore;