import axios from 'axios';

export const getAxiosInstance = token => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      'doors-platform': 'web',
      Authorization: `Bearer ${token}`,
    },
  });
};
