import React, { useRef } from 'react';
import Icon from '@material-ui/core/Icon';
import { MTableToolbar } from 'material-table';
import FilterTable from '../../../components/Tables/FilterTable';
import PatchedPagination from '../../../components/PatchedPagination';
import { EmptyDataMessageRow } from '../../../components/Tables/DefaultTable';
import { colors } from '../../../Theme/colors';
import * as dictionary from '../../../core/constants/dictionary';
import { serializeCpfAndNameSearch } from '../../../core/utils';

export const PromotersTable = (props) => {
  const {
    data,
    handleOpenModal,
    handleOpenDeleteModal,
    count,
    rowsPerPage,
    pageIndex,
    searchTextValue,
    onChangePageHandler = () => {},
    onChangeRowsPerPageHandler = () => {},
    onSearchChangeHandler = () => {},
    toolbarInputFocus,
  } = props;
  const tableRef = useRef(null);

  return (
    <FilterTable
      data={data}
      ref={tableRef}
      rowsPerPage={rowsPerPage}
      columns={[
        {
          title: dictionary.PROMOTER_NAME,
          field: 'name',
        },
        {
          title: dictionary.PROMOTER_CPF,
          field: 'cpf',
        },
        {
          title: dictionary.STATUS,
          field: 'status',
          lookup: { 0: dictionary.INACTIVE, 1: dictionary.ACTIVE },
          cellStyle: rowData => {
            return rowData === 'Inativo'
              ? { color: colors.red_600 }
              : { color: colors.green_600 };
          },
          render: ({ status }) => {
            return status === 1 ? dictionary.ACTIVE : dictionary.INACTIVE;
          },
        },
      ]}
      actions={[
        rowData => ({
          icon: () => <Icon style={{ color: '#a2abbc' }}>create</Icon>,
          tooltip: dictionary.DETAILS,
          onClick: () => handleOpenModal(rowData),
        }),
        rowData => ({
          icon: () => <Icon style={{ color: '#a2abbc' }}>delete</Icon>,
          tooltip: dictionary.DETAILS,
          onClick: () => handleOpenDeleteModal(rowData),
        }),
      ]}
      localization={{
        body: {
          emptyDataSourceMessage: (
            <EmptyDataMessageRow 
              message={dictionary.EMPTY_PROMOTER_TABLE}
            />
          ),
        },
      }}
      components={{
        Pagination: (props) => {
          return (
            <PatchedPagination
              {...props}
              count={count}
              page={pageIndex}
              rowsPerPage={rowsPerPage}
              onChangePage={(_evt, page) => onChangePageHandler(page)}
              onChangeRowsPerPage={(event) => onChangeRowsPerPageHandler(parseInt(event.target.value))}
            />
          );
        },
        Toolbar: (props) => {
          if(onSearchChangeHandler) {
            return (
              <MTableToolbar 
                {...props} 
                searchText={searchTextValue}
                searchAutoFocus={toolbarInputFocus}
                onSearchChanged={(value) => onSearchChangeHandler(
                  serializeCpfAndNameSearch(value)
                )}
              />
            );  
          }
          else return <MTableToolbar {...props} />;  
        },
      }}
    />
  );
};
