import React from 'react';
import { Typography, DialogTitle } from '@material-ui/core';
import { styles } from './utils';

const ModalTitle = ({ title, style = {}, className = '' }) => (
  <DialogTitle
    style={{ ...styles.dialogTitle, ...style }}
    className={className}
  >
    <Typography style={styles.typography}>{title}</Typography>
  </DialogTitle>
);
export default ModalTitle;
