import React from 'react';
import { Backdrop as BackdropMUI, CircularProgress } from '@material-ui/core';
import { view } from 'react-easy-state';
import { mainStore } from '../../core/store/mainStore';
import { colors } from '../../Theme/colors';

const Backdrop = () => {
  return (
    <BackdropMUI
      style={{ zIndex: '9999', color: colors.white }}
      open={mainStore.requestLoading}
    >
      <CircularProgress color="inherit" />
    </BackdropMUI>
  );
};

export default view(Backdrop);
