import { sortTextList } from '../../../core/strings';
import { PromotionalSpotTypeLabel } from '../../../core/types/promotional-spot/promotional-spot-type-label.enum';

export const globalTypeOptions = sortTextList([
  PromotionalSpotTypeLabel.CHECKSTAND,
  PromotionalSpotTypeLabel.CROSS_MERCHANDISING,
  PromotionalSpotTypeLabel.EXHIBITOR,
  PromotionalSpotTypeLabel.FREEZER,
  PromotionalSpotTypeLabel.ISLAND,
  PromotionalSpotTypeLabel.GONDOLA_SIDE,
  PromotionalSpotTypeLabel.GONDOLA_TIP,
]);
