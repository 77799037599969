import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import React, { useState, useCallback } from 'react';
import * as dictionary from '../../core/constants/dictionary';
import { SyncValidations } from '../../core/validator/validations';
import TextField from '../InputTextField';
import CancelButton from '../Buttons/Cancel';
import DefaultButton from '../Buttons/Default';

export const UserInviteModal = ({
  open,
  agreementId,
  userProfile,
  onSubmit,
  onCancel,
}) => {
  const [email, setEmail] = useState('');

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      if (event.currentTarget.form.reportValidity()) {
        const data = {
          agreementId,
          userProfile,
          email: email.replace(/\s/g, ''),
        };
        setEmail('');
        await onSubmit(data);
      }
    },
    [agreementId, email, onSubmit, userProfile],
  );

  return (
    <Dialog
      PaperProps={{ style: { padding: '20px', overflow: 'hidden' } }}
      fullWidth
      open={open}
    >
      <form noValidate>
        <DialogContent>
          <TextField
            variant="standard"
            validations={[SyncValidations.notEmpty, SyncValidations.validEmail]}
            value={email}
            style={{ width: '100%', marginBottom: '24px' }}
            label={dictionary.USER_EMAIL}
            onChange={e => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={onCancel} />
          <DefaultButton
            onClick={handleSubmit}
            color="primary"
            style={{ width: 'auto' }}
            label={dictionary.SEND}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};
