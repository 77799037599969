import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import * as dictionary from '../../../core/constants/dictionary';
import ModalTitle from '../../../components/Modal/Title';
import { deleteUser } from '../../../core/services/users/usersService';

export const DeleteContentModal = ({ onClose, onDismiss, promoterDelete }) => {
  const handleSubmit = async () => {
    await deleteUser(promoterDelete);
    await onClose();
  };

  return (
    <>
      <ModalTitle title={dictionary.DELETE_PROMOTER_REGISTER} />
      <form noValidate>
        <DialogContent>
          <Typography>
            {`Atenção, você tem certeza que deseja excluir o cadastro do promotor ${promoterDelete.name} e suspendê-lo em todos os acordos que está vinculado?`}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={onDismiss} color="primary">
            {dictionary.CANCEL}
          </Button>

          <Button onClick={handleSubmit} variant="contained" color="primary">
            {dictionary.DELETE}
          </Button>
        </DialogActions>
      </form>
    </>
  );
};
