import { AxiosResponse } from 'axios';
import API from '../api/config';
import * as routes from '../api/routes';
import { 
  RetailFeature, 
  GetFeatureByIdDto, 
  GetRetailFeaturesDto, 
  RetailFeatureEntity, 
  UpdateFeatureParams, 
} from './types';

export const getAllFeaturesAPI = (
  retailId: string
): Promise<GetRetailFeaturesDto> => {
  const url = routes.API_RETAIL_FEATURES.replace(':retailId', retailId);
  return API.get(url);
}

export const getFeatureByCodeAPI = (
  retailId: string,
  featureCode: RetailFeature,
): Promise<GetFeatureByIdDto> => {
  const url = `${routes.API_RETAIL_FEATURES.replace(':retailId', retailId)}/${featureCode}`;
  return API.get(url);
}

export const createFeatureAPI = (
  retailId: string, 
  data: Partial<RetailFeatureEntity>,
): Promise<AxiosResponse<RetailFeatureEntity>> => {
  const url = routes.API_RETAIL_FEATURES.replace(':retailId', retailId);
  return API.post(url, data);
}

export const updateFeatureAPI = ({
  data,
  retailId, 
  featureCode,
}: UpdateFeatureParams): Promise<AxiosResponse<RetailFeatureEntity>> => {
  const url = `${routes.API_RETAIL_FEATURES.replace(':retailId', retailId)}/${featureCode}`;
  return API.put(url, data);
}
