import React from 'react';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  imageIcon: { height: '100%' },
  iconRoot: { textAlign: 'center' },
});

const IconTable = ({ alt, icon, color }) => {
  const classes = useStyles();

  return (
    <Icon classes={{ root: classes.iconRoot }} color={color}>
      <img alt={alt} className={classes.imageIcon} src={icon} />
    </Icon>
  );
};

IconTable.defaultProps = {
  color: 'inherit',
  alt: 'Icon Table',
};

export default IconTable;
