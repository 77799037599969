import React, { forwardRef } from 'react';
import { StyledInputDropdownItem } from './style';

const InputDropdownItem = forwardRef(({ children, ...props }, ref) => {
  return (
    <StyledInputDropdownItem {...props} tabIndex="0" role="button" ref={ref}>
      {children}
    </StyledInputDropdownItem>
  );
});

export { InputDropdownItem };
