import React from 'react';
import FormModal from './FormModal';

type Props = {
  isOpen: boolean;
  isEditable: boolean;
  onClose: Function;
  onSubmit: Function;
  data: {
    title?: string;
    dateTime?: string;
  };
};

const CreateModal = ({
  isOpen,
  onClose,
  onSubmit,
  data,
  isEditable,
}: Props) => {
  return (
    <FormModal
      isEditable={isEditable}
      isOpen={isOpen}
      onSubmit={onSubmit}
      onClose={onClose}
      datetime={data.dateTime}
      title={data.title}
    />
  );
};

export default CreateModal;
