import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { DialogActions, DialogContent, } from '@material-ui/core';
import Autocomplete from '../../../../components/Autocomplete';
import TextField from '../../../../components/InputTextField';
import DefaultButton from '../../../../components/Buttons/Default';
import CancelButton from '../../../../components/Buttons/Cancel';
import { getSuppliers } from '../../../Retails/functions';
import { UserInviteModal } from '../../../../components/UserInviteModal/UserInviteModal';
import { mainStore } from '../../../../core/store/mainStore';
import { utils } from '../../../../core/utils';
import { exist } from '../../../../core/validation';
import { getSuppliersByRetail } from '../../../../core/services/suppliers/suppliersService';
import { getInvitesByAgreementAndProfile } from '../../../../core/services/invites/invitesService';
import { getSuppliersByAgreement, getAnswerablesByAgreement, } from '../../../../core/services/users/usersService';
import { sendPullOfTrackInvites, trackSalesRepInviteSent, } from '../../../../core/mixpanel/mixPanelEvents';
import { profileCheck, userProfile } from '../../../../core/profiles';
import * as dictionary from '../../../../core/constants/dictionary';
import {
  autocompleteSupplierStyle,
  getChipsSuppliers,
  getOptionLabelSuppliers,
  submitAgreement,
} from '../../functions';

export const EditAgreement = ({
  approval,
  closeModalCallback,
  hideActionButton = false,
  currentAgreement,
  modalType,
  retailsData,
  nextStepCallback,
}) => {
  const [showingInviteAnswerableModal, setShowingInviteAnswerableModal] = useState(false);
  const [retail, setRetail] = useState({});
  const [suppliers, setSuppliers] = useState([]);
  const [description, setDescription] = useState('');
  const [internalCode, setInternalCode] = useState(null);
  const [salesRep, setSalesRep] = useState([]);
  const [invites, setInvites] = useState([]);
  const [newInvites, setNewInvites] = useState([]);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [retailOptions, setRetailOptions] = useState([]);

  const getRenderId = useCallback(() => {
    if (mainStore.loggedUser.retailId) {
      return mainStore.loggedUser.retailId;
    }
    return currentAgreement.retailId;
  }, [currentAgreement.retailId]);

  const profilesDisabled = useMemo(() => {
    return profileCheck.isSalesRep() || modalType !== dictionary.EDIT;
  }, [modalType]);

  const disabledInput = useMemo(() => {
    return approval || profilesDisabled;
  }, [approval, profilesDisabled]);

  const loadSelectedSuppliers = useCallback(async agreement => {
    setSuppliers(await getSuppliersByAgreement(agreement.id));
  }, []);

  const loadSelectedAnswerables = useCallback(async agreement => {
    setSalesRep(await getAnswerablesByAgreement(agreement.id));
  }, []);

  const loadUserInvites = useCallback(async agreement => {
    setInvites(
      await getInvitesByAgreementAndProfile(
        agreement.id,
        userProfile.SALES_REP,
      ),
    );
  }, []);

  const loadSupplierDropdownOptions = useCallback(
    async (retailId, search, requestLoading = false) => {
      if(requestLoading) mainStore.requestLoading = true;

      const suppliersOpts = await getSuppliers({
        retailId, 
        status: 1, 
        q: search,
      });
      
      if(requestLoading) {
        mainStore.requestLoading = false;
      }

      setSupplierOptions(
        suppliersOpts.items.filter(
          s => !suppliers.map(selected => selected.id).includes(s.id)
        )
      );
    },
    [suppliers],
  );

  useEffect(() => {
    mainStore.requestLoading = false;
  }, [supplierOptions]);

  const loadDropdownOptions = useCallback(
    async retailId => {
      mainStore.requestLoading = true;
      await loadSupplierDropdownOptions(retailId);
      mainStore.requestLoading = false;
    },
    [loadSupplierDropdownOptions],
  );

  useEffect(() => {
    (async function loadDropdown() {
      if (retail) {
        await loadDropdownOptions(retail.id);
      }
    })();
  }, [loadDropdownOptions, retail]);

  useEffect(() => {
    (async function init() {
      mainStore.requestLoading = true;
      
      setRetailOptions(retailsData);
      setDescription(currentAgreement.description);
      setInternalCode(currentAgreement.internalCode);
      await loadSelectedSuppliers(currentAgreement);
      await loadSelectedAnswerables(currentAgreement);
      await loadUserInvites(currentAgreement);
      
      mainStore.requestLoading = false;
    })();
  }, [
    loadSelectedAnswerables,
    loadSelectedSuppliers,
    loadUserInvites,
    currentAgreement,
    retailsData,
  ]);

  useEffect(() => {
    (async function setRetailDropdownSelection() {
      const retailId = getRenderId();
      const item = retailOptions.find(item => item.id === retailId);
      setRetail(item);
    })();
  }, [getRenderId, retailOptions]);

  const handleOnChangeRetail = useCallback(async value => {
    setRetail(value);
    setSuppliers([]);
    setSupplierOptions([]);
    
    if (value) {
      const suppliersOpts = await getSuppliersByRetail({
        status: 1,
        retailId: value.id, 
      });
      setSupplierOptions(suppliersOpts.items);
    }
  }, []);

  const retailSelect = useMemo(() => {
    if (profileCheck.isSuperAdmin()) {
      return (
        <Autocomplete
          defaultValue={retail}
          label={dictionary.AGREEMENT_RETAIL}
          style={{ width: '50%', marginTop: '3px', marginBottom: '20px' }}
          disabled={approval}
          value={retail}
          onChange={async (e, value) => handleOnChangeRetail(value)}
          options={mainStore.agreements.retails}
          getOptionSelected={(option, value) => {
            if (value) {
              return option.id === value.id;
            }
            return null;
          }}
        />
      );
    }
    return '';
  }, [approval, handleOnChangeRetail, retail]);

  const agreementCode = useMemo(() => {
    return internalCode !== null && internalCode !== undefined ? (
      <TextField
        required={false}
        label={dictionary.AGREEMENT_CODE}
        disabled
        value={internalCode}
      />
    ) : (
      ''
    );
  }, [internalCode]);

  const serializeAgreement = useCallback(() => {
    return {
      id: currentAgreement.id,
      retailId: retail ? retail.id : undefined,
      answerableUserIds: salesRep.map(a => a.id),
      supplierIds: suppliers.map(s => s.id),
      description,
      invites,
      status: currentAgreement.status,
      acceptanceStatus: 1,
    };
  }, [currentAgreement, description, invites, retail, salesRep, suppliers]);

  const serializeAndSubmit = useCallback(
    async event => {
      event.preventDefault();

      if (event.currentTarget.form.reportValidity() && suppliers.length > 0) {
        try {
          mainStore.requestLoading = true;
          const agreement = serializeAgreement();
          await submitAgreement(
            agreement,
            dictionary.AGREEMENT_EDITED,
            null,
            null,
            suppliers,
            currentAgreement,
          );
          await sendPullOfTrackInvites(newInvites);
          setNewInvites([]);
        } catch (err) {
          utils.openSnackBar(dictionary.ERROR, dictionary.EDIT_AGREEMENT_ERROR);
        } finally {
          mainStore.requestLoading = false;
        }
      } else {
        utils.openSnackBar(dictionary.ERROR, dictionary.FORM_INVALID);
      }
    },
    [currentAgreement, newInvites, serializeAgreement, suppliers],
  );

  const approveAgreement = useCallback(
    async event => {
      event.preventDefault();
      mainStore.requestLoading = true;
      const agreement = serializeAgreement();
      agreement.acceptanceStatus = 1;
      await submitAgreement(agreement, dictionary.AGREEMENT_APPROVED);
      mainStore.requestLoading = false;
      if (nextStepCallback) {
        nextStepCallback();
      } else {
        closeModalCallback();
      }
    },
    [closeModalCallback, nextStepCallback, serializeAgreement],
  );

  const actionButton = useMemo(() => {
    return approval ? (
      <DefaultButton
        color="primary"
        style={{ width: 'auto' }}
        onClick={approveAgreement}
        label={dictionary.APPROVE_AGREEMENT}
      />
    ) : (
      <DefaultButton
        color="primary"
        style={{ width: 'auto' }}
        onClick={serializeAndSubmit}
        label={dictionary.SAVE}
      />
    );
  }, [approval, approveAgreement, serializeAndSubmit]);

  const cancelButtonLabel = useMemo(() => {
    return currentAgreement.acceptanceStatus === 1 ? dictionary.CLOSE : '';
  }, [currentAgreement.acceptanceStatus]);

  const renderActionButton = useMemo(() => {
    return hideActionButton ? '' : actionButton;
  }, [actionButton, hideActionButton]);

  const handleInvite = useCallback(
    async data => {
      if (!invites.map(i => i.email).includes(data.email)) {
        try {
          setNewInvites([...newInvites, data]);
          setInvites([...invites, data]);
          mainStore.snackBars.typeSnackbar = dictionary.SUCCESS;
          mainStore.snackBars.openSnackBar = true;
          mainStore.snackBars.messageSnackBar =
            dictionary.SALES_REP_INVITED_AGREEMENT;
          trackSalesRepInviteSent({
            suppliers,
            salesRepEmail: data.email,
            code: currentAgreement.internalCode,
          });
        } catch (error) {
          mainStore.snackBars.typeSnackbar = dictionary.ERROR;
          mainStore.snackBars.openSnackBar = true;
          if (
            exist(error.response) &&
            error.response.data.message === dictionary.ALREADY_USER_EMAIL_ERROR
          ) {
            mainStore.snackBars.messageSnackBar =
              dictionary.ERROR_INVITE_USER_EMAIL;
          } else {
            mainStore.snackBars.messageSnackBar = dictionary.ERROR_INVITE_USER;
          }
        }
      }
      setShowingInviteAnswerableModal(false);
    },
    [invites, newInvites, currentAgreement, suppliers],
  );

  return (
    <>
      <form noValidate>
        <DialogContent>
          <div className="form-two-columns">
            {retailSelect}
            {agreementCode}
          </div>
          <div className="form-two-columns">
            <Autocomplete
              multiple
              label={dictionary.AGREEMENT_SUPPLIERS}
              value={suppliers}
              required={false}
              disabled={disabledInput}
              options={supplierOptions}
              renderTags={getChipsSuppliers}
              filterOptions={opts => {
                if (opts.length > 0) {
                  return opts.filter(
                    opt => !suppliers.map(item => item.id).includes(opt.id),
                  );
                }
                return opts;
              }}
              getOptionLabel={getOptionLabelSuppliers}
              getOptionSelected={(option, value) => option.internalCode === value.internalCode}
              onChange={(e, value) => setSuppliers(value)}
              onInputChange={(e, value) => loadSupplierDropdownOptions(retail.id, value, true)}
              style={autocompleteSupplierStyle}
            />
          </div>
          <TextField
            required={false}
            variant="standard"
            value={description}
            style={{ width: '100%', marginBottom: '24px' }}
            label={dictionary.AGREEMENT_DESCRIPTION}
            multiline
            onChange={e => {
              setDescription(e.target.value);
            }}
            disabled={disabledInput}
          />
        </DialogContent>

        <DialogActions>
          <CancelButton
            label={cancelButtonLabel}
            onClick={() => {
              closeModalCallback();
            }}
          />
          {renderActionButton}
        </DialogActions>
      </form>

      <UserInviteModal
        open={showingInviteAnswerableModal}
        userProfile={userProfile.SALES_REP}
        onCancel={() => setShowingInviteAnswerableModal(false)}
        onSubmit={handleInvite}
      />
    </>
  );
};
