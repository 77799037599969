import React from 'react';
import { colors } from '../../../../Theme/colors';
import { UserInfo } from '../../../Users/utils';

export const getOptionLabel = (option: UserInfo ) => {
  if (Object.keys(option).length === 0) {
    return '';
  }

  return `${option.name} (${option.cpf})`;
};

export const getOptionSelected = (option: UserInfo, value: UserInfo) => {
  if (value) {
    return option.id === value.id;
  }

  return null;
}

export const getOptionDisabled = ({ status }: UserInfo) => {
  return status === 0;
}

export const renderOption = ({name, status}: UserInfo) => (
  <div style={{
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  }}>
    <div style={{ color: (status === 0) ? colors.black_200 : ''}}>
      {name}
    </div> 
    <div style={{ color: colors.red_600}}>
      { (status === 0) ? 'Inativo' : '' }
    </div>
  </div>
);
