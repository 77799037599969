import { mainStore } from '../../core/store/mainStore';
import {
  getStore,
  postStore,
  putStore,
  deleteStore,
} from '../../core/services/stores/storesService';
import { getSalesReps } from '../../core/services/users/usersService';
import { getActiveRetails } from '../../core/services/retails/retailsService';
import { profileCheck } from '../../core/profiles';
import { findValueInArray } from '../../core/validation';

export const getInitialStateValues = () => {
  let startRetail = null;

  if (mainStore.loggedUser.retailId) {
    startRetail = findValueInArray(
      mainStore.stores.retails,
      'id',
      mainStore.loggedUser.retailId,
    );
  }

  return {
    status: 1,
    name: null,
    cpf: null,
    password: null,
    city: null,
    address: null,
    number: null,
    neighborhood: null,
    retail: startRetail,
  };
};

export const getEditInicialForm = currentRetail => {
  return {
    ...currentRetail,
    retail: findValueInArray(
      mainStore.stores.retails,
      'id',
      currentRetail.retailId,
    ),
  };
};

export const getStores = (retailId, status) => {
  mainStore.requestLoading = true;
  return getStore(retailId, status);
};

export const loadInitialData = async () => {
  mainStore.requestLoading = true;
  mainStore.stores.data = await getStores(mainStore.loggedUser.retailId);
  mainStore.stores.answerables = await getSalesReps();
  mainStore.stores.retails = await getActiveRetails();
  mainStore.requestLoading = false;
};

export const registerStore = store => {
  mainStore.requestLoading = true;

  const dto = {
    name: store.name,
    storeCode: store.storeCode,
    cep: store.cep,
    state: store.state,
    city: store.city,
    address: store.address,
    number: store.number,
    neighborhood: store.neighborhood,
    status: store.status,
    retailId: profileCheck.isRetailAdmin()
      ? mainStore.loggedUser.retailId
      : store.retail.id,
  };

  return postStore(dto);
};

export const editStore = store => {
  mainStore.requestLoading = true;

  const dto = {
    id: store.id,
    name: store.name,
    storeCode: store.storeCode,
    cep: store.cep,
    state: store.state,
    city: store.city,
    address: store.address,
    number: store.number,
    neighborhood: store.neighborhood,
    status: store.status,
    retailId: profileCheck.isRetailAdmin()
      ? mainStore.loggedUser.retailId
      : store.retail.id,
  };

  return putStore(dto);
};

export const removeStore = async store => {
  mainStore.requestLoading = true;
  return deleteStore(store);
};
