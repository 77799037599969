import styled from 'styled-components';
import { theme, ifProp } from 'styled-tools';

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: row;
  width: 28px;
  height: 28px;
  cursor: ${ifProp('disabled', 'not-allowed', 'pointer')};
`;

export const StyledStar = styled.span`
  color: ${ifProp(
    'selected',
    theme('palette.yellow.300'),
    theme('palette.black.400'),
  )};
  opacity: ${ifProp('disabled', '0.5', '1')};
  transition: ${theme('common.transition')};
`;

export const StyledHiddenInput = styled.input`
  visibility: hidden;
`;
