import API from '../api/config';
import * as routes from '../api/routes';
import { exist } from '../../validation';

export const sendInviteAPI = async data => {
  return API.post(routes.API_INVITES, data);
};

export const getInvitesAPI = async id => {
  const url = `${routes.API_INVITES}/${id}`;
  const response = await API.get(url);
  return response.data;
};

export const getInvitesByAgreementAPI = async (agreementId, profile) => {
  const profileParam = exist(profile) ? `profile=${profile}` : '';
  const url = `${routes.API_AGREEMENTS}/${agreementId}${routes.API_INVITES}?${profileParam}`;
  const response = await API.get(url);
  return response.data.items;
};

export const removeInviteByIdAPI = async id => {
  const url = `${routes.API_INVITES}/${id}`;
  const response = await API.delete(url);
  return response;
};

export const resendInviteByIdAPI = async id => {
  const url = `${routes.API_INVITES}/${id}${routes.API_RESEND}`;
  const response = await API.post(url, null);
  return response;
};
