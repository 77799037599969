import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../Theme/colors';

const drawerWidth = 240;
export default makeStyles(theme => ({
  root: {
    display: 'flex',
    '& .MuiMenuItem-root': { color: colors.white },
    '& .MuiListItem-button:hover': {
      color: colors.green_600,
      backgroundColor: 'none',
    },
  },
  doorsMenuStyle: {
    backgroundColor: colors.black_900,
    alignItems: 'center',
  },
  leftAppBar: { left: 0, right: 'auto', width: 'inherit' },
  appBar: {
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {},
  hide: { display: 'none' },
  drawer: { width: drawerWidth, flexShrink: 0, whiteSpace: 'nowrap' },
  drawerOpen: {
    overflowX: 'hidden',
    width: drawerWidth,
    maxWidth: drawerWidth,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '72px',
    maxWidth: '72px',
  },
  toolbar: {
    marginTop: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  openToolbar: {
    marginTop: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: { flexGrow: 1, padding: theme.spacing(3) },
}));