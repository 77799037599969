import React from 'react';
import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import ModalTitle from '../../../../../components/Modal/Title';
import ModalSmallContainer from '../../../../../components/Modal/SmallContainer';
import { CancelButton, DefaultButton } from '../../../../../components/Buttons';
import * as dictionary from '../../../../../core/constants/dictionary';

type Props = {
  isOpen: boolean;
  onClose: Function;
  onConfirm: Function;
}

const RemoveCalendarModal = ({ isOpen, onClose, onConfirm }: Props) => {
  return (
    <ModalSmallContainer 
      isOpen={isOpen}
      closeModalCallback={onClose} 
    >
      <ModalTitle title={dictionary.DELETE_CALENDAR} />
      <DialogContent>
        <Typography>
          {dictionary.ALERT_ON_DELETE_PROMOTIONAL_CALENDAR}
        </Typography>
      </DialogContent>

      <DialogActions>
        <CancelButton onClick={onClose} />
        <DefaultButton
          label={dictionary.CONFIRM}
          style={{ width: 'auto' }}
          onClick={onConfirm}
        />
      </DialogActions>
    </ModalSmallContainer>
  );
};

export default RemoveCalendarModal;
 