import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { mainStore } from '../../../core/store/mainStore';
import { signInAuthCheckinTokenService } from '../../../core/services/auth/authService';

const useAutoCheckInToken = () => {
  const history = useHistory();
  const { token } = useParams();

  useEffect(() => {
    let mounted = true;
    if (mainStore.loggedUser && !token) {
      const getToken = async () => {
        try {
          const { jwt } = await signInAuthCheckinTokenService();
          if (mounted) {
            history.push(`/AutoCheckin/${jwt}`);
          }
        } catch (error) {
          console.error(error);
        }
      };

      getToken();
      return () => {
        mounted = false;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainStore.loggedUser, token]);

  return token;
};

export { useAutoCheckInToken };
