import { AxiosResponse } from 'axios';
import API from '../api/config';
import { exist } from '../../validation';
import { PromotionalSpotDto } from '../../types/promotional-spot/promotional-spot.dto';
import * as routes from '../api/routes';
import { 
  CreatePromotionalSpotDto, 
  UpdatePromotionalSpotDto, 
  GetPromotionalSpotsParams, 
  GetPromotionalSpotQueryDto, 
} from './types';

export const getPromotionalSpotsAPI = ({
  name,
  type,
  limit = 10,
  offset = 0,
  retailId,
  calendarId,
}: GetPromotionalSpotsParams): Promise<AxiosResponse<GetPromotionalSpotQueryDto>> => {
  let routeParams = `?offset=${offset}&limit=${limit}`;
  if (exist(name)) {
    routeParams += `&name=${name}`;
  }
  if (exist(type)) {
    routeParams += `&type=${type}`;
  }
  if (exist(retailId)) {
    routeParams += `&retailId=${retailId}`;
  }
  if (exist(calendarId)) {
    routeParams += `&calendarId=${calendarId}`;
  }
  const url = `${routes.API_PROMOTIONAL_SPOTS}${routeParams}`;
  
  return API.get(url);
};

export const getPromotionalSpotByIdAPI = (
  promotionalSpotId: string
): Promise<AxiosResponse<PromotionalSpotDto>> => {
  const url = `${routes.API_PROMOTIONAL_SPOTS}/${promotionalSpotId}`;
  return API.get(url);
};

export const createPromotionalSpotAPI = (
  data: Partial<CreatePromotionalSpotDto>
): Promise<AxiosResponse<PromotionalSpotDto>> => {
  return API.post(routes.API_PROMOTIONAL_SPOTS, data);
};

export const updatePromotionalSpotAPI = (
  data: Partial<UpdatePromotionalSpotDto>
): Promise<AxiosResponse<PromotionalSpotDto>> => {
  const url = `${routes.API_PROMOTIONAL_SPOTS}/${data.id}`;
  return API.put(url, data);
};

export const removePromotionalSpotAPI = (
  promotionalSpotId: string
): Promise<AxiosResponse<PromotionalSpotDto>> => {
  const url = `${routes.API_PROMOTIONAL_SPOTS}/${promotionalSpotId}`;
  return API.delete(url);
};
