import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { AutocompleteRenderInputParams, Autocomplete } from '@material-ui/lab';
import { useRetailList } from '../../../../../hooks/RetailHooks';
import { useStoreData } from '../../../../../hooks/StoreHooks';
import * as dictionary from '../../../../../core/constants/dictionary';
import {
  formatWithoutTimezone,
  getMonthTextInLocale,
} from '../../../../../core/constants/dates';
import { captalize } from '../../../../../core/strings';
import { mainStore } from '../../../../../core/store/mainStore';
import { RetailEntityType } from '../../../../../core/types/retail/retail-entity.type';
import { GetPromotionalSpotReportParams } from '../../../../../core/services/promotionalSpots/types';
import FormFilterCollapse from '../../../common/components/FormFilterCollapse';
import { globalTypeOptions } from '../../../common/globalTypeOptions';
import { translatePromotionalSpotTypeToAPI } from '../../../common/utils';
import { FormRetailField } from '../../../../../components/Form/FormRetailField';
import { CalendarPeriodTableRow } from '../../../common/types';
import usePeriodCalendar from '../../../common/components/hooks/use-period-calendar';
import { StoreDto } from '../../../Addressing/types';
import {
  filterPeriodOptionsBySelected,
  monthArray,
  parseYearOptionsFromPeriods,
} from '../../../NegotiationsPanel/components/FormFilter/utils';

type Props = {
  onSubmit: (args?: GetPromotionalSpotReportParams) => void;
  isLoading: boolean;
};
const FormFilter = ({ onSubmit, isLoading }: Props) => {
  const {
    retails,
    fetch: fetchRetails,
    isLoading: isLoadingRetails,
    retailInUser,
  } = useRetailList();

  const {
    fetch: fetchPeriods,
    isLoading: isLoadingPeriods,
    periods: periodOptions,
  } = usePeriodCalendar();

  const {
    fetch: fetchStores,
    isLoading: isLoadingStores,
    stores: storeOptions,
    isStoreManager,
  } = useStoreData();

  const [retail, setRetail] = useState<RetailEntityType>();

  const [store, setStore] = useState<StoreDto | null>();
  const [spotTypes, setSpotTypes] = useState<string[]>([]);
  const [month, setMonth] = useState<number | null>(new Date().getMonth() + 1);
  const [year, setYear] = useState<string | null>(
    new Date().getFullYear().toString()
  );
  const [period, setPeriod] = useState<CalendarPeriodTableRow[]>([]);
  const retailId = useMemo(
    () => mainStore?.loggedUser?.retailId ?? retailInUser ?? retail?.id,
    [retail, retailInUser]
  );

  const onClearForm = useCallback(() => {
    setStore(undefined);
    setSpotTypes([]);
    setMonth(null);
    setYear(null);
    setPeriod([]);
  }, []);

  const onHandleSubmit = useCallback(() => {
    onSubmit({
      retailId,
      storeId: mainStore.loggedUser.storeId ?? store?.id,
      spotTypes: spotTypes.map(translatePromotionalSpotTypeToAPI),
      month: month && !period.length ? month : undefined,
      year: year && !period.length ? parseInt(year, 10) : undefined,
      periodIds: period.filter((item) => item.id).map((item) => item.id ?? ''),
    });
  }, [
    retailId,
    store,
    spotTypes,
    store,
    mainStore.loggedUser,
    month,
    year,
    period,
  ]);

  useEffect(() => {
    fetchRetails();
  }, []);

  useEffect(() => {
    if (retailId) {
      fetchPeriods(retailId);
      fetchStores(retailId);
      onHandleSubmit();
    }
  }, [retailId]);

  return (
    <FormFilterCollapse
      actionPosition="grid"
      buttons={
        <>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            disabled={!retailId || (!year || !month) || isLoading}
            onClick={onHandleSubmit}
          >
            {dictionary.SEARCH}
          </Button>
          <Button
            disableElevation
            color="primary"
            onClick={onClearForm}
            disabled={isLoading}
          >
            {dictionary.CLEAR}
          </Button>
        </>
      }
    >
      {!retailInUser && (
        <Grid item sm={6} md={2}>
          <FormRetailField
            disabled={isLoadingRetails}
            value={retail}
            key={retail?.id}
            options={retails}
            onChange={setRetail}
          />
        </Grid>
      )}
      {!isStoreManager && (
        <Grid item sm={6} md={2}>
          <Autocomplete
            disabled={isLoadingStores}
            id="store"
            size="small"
            value={store}
            key={store?.id}
            options={storeOptions}
            getOptionLabel={(store: StoreDto) => store.name}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField
                {...params}
                label={dictionary.STORES}
                variant="outlined"
              />
            )}
            onChange={(_: any, value: StoreDto | null) => {
              setStore(value);
            }}
            style={{}}
          />
        </Grid>
      )}

      <Grid item md={2} xs={1}>
        <Autocomplete
          id="year"
          size="small"
          value={year}
          disabled={isLoading || isLoadingPeriods}
          options={parseYearOptionsFromPeriods(periodOptions)}
          getOptionLabel={(option: string) => option}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField {...params} label={dictionary.YEAR} variant="outlined" />
          )}
          onChange={(_: any, value: string | null, reason: string) => {
            setYear(value);
          }}
          style={{}}
        />
      </Grid>
      <Grid item sm={2} md={2}>
        <Autocomplete
          id="month"
          disabled={isLoading || isLoadingPeriods}
          size="small"
          value={month}
          options={monthArray}
          getOptionLabel={(option: number) =>
            captalize(getMonthTextInLocale(new Date(2022, option - 1, 10)))
          }
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              label={dictionary.MONTH}
              variant="outlined"
            />
          )}
          onChange={(_: any, value: number | null) => {
            setMonth(value);
          }}
          style={{}}
        />
      </Grid>
      <Grid item sm={6} md={2}>
        <Autocomplete
          multiple
          disabled={isLoading || isLoadingPeriods || !year || !month}
          id="period"
          size="small"
          value={period}
          options={filterPeriodOptionsBySelected({
            periods: periodOptions,
            month: month ? month.toString() : undefined,
            year: year ? year.toString() : undefined,
          })}
          getOptionLabel={(period: CalendarPeriodTableRow) =>
            formatWithoutTimezone(new Date(period?.startAt as Date))
          }
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              label={dictionary.PERIOD}
              variant="outlined"
            />
          )}
          onChange={(_: any, value: CalendarPeriodTableRow[]) => {
            setPeriod(value);
          }}
          style={{}}
        />
      </Grid>
      <Grid item sm={2} md={3}>
        <Autocomplete
          multiple
          disabled={isLoading}
          id="spotTypes"
          size="small"
          value={spotTypes}
          options={globalTypeOptions}
          getOptionLabel={(option: string) => option}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              label={dictionary.PROMOTIONAL_SPOT_TYPE}
              variant="outlined"
            />
          )}
          onChange={(_, value: string[]) => {
            setSpotTypes(value);
          }}
        />
      </Grid>
    </FormFilterCollapse>
  );
};

export default FormFilter;
