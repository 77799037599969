import { Pagination } from "../../types/pagination.type";

export enum RiskLevel {
  HIGH = 'high',
  LOW = 'low',
  EXPIRED = 'expired'
}

export type GetExpirationReportParams = {
  offset?: number;
  limit?: number;
  productId?: string[];
  storeId?: string;
  endDate?: string;
  startDate?: string;
  productGroupId?: string[];
  expirationRisk?: RiskLevel[];
}

export type ExpirationReportDTO = {
  id: string;
  expirationRisk: RiskLevel;
  quantity: number;
  lastCheckAt: Date;
  expirationAt: Date;
  unitsInStock: number;
  store: {
    id: string;
    name: string;
    code: string;
  };
  productGroup: {
    id: string;
    name: string;
  };
  product: {
    id: string;
    name: string;
    internalCode: string;
  }
}

export type ExpirationReportResult = Pagination<ExpirationReportDTO>;
