import React, { ReactNode } from 'react';
import classes from './EmptyChart.module.scss';

type Props = {
  height?: number;
  children: ReactNode;
}

const EmptyChart = ({ height = 280, children }: Props) => (
  <div className={classes.container} style={{height}}>{ children }</div>
);

export default EmptyChart;
