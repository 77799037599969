import API from '../api/config';
import * as routes from '../api/routes';

export const getSuppliersByRetailAPI = async ({
  q,
  limit,
  status,
  offset,
  retailId,
}) => {
  const queryParams = new URLSearchParams();
  if (offset) {
    queryParams.append('offset', offset);
  }
  if (limit) {
    queryParams.append('limit', limit);
  }
  if (status) {
    queryParams.append('status', status);
  }
  if (retailId) {
    queryParams.append('retailId', retailId);
  }
  if (q) {
    queryParams.append('q', q);
  }
  
  const url = `${routes.API_SUPPLIERS}?${queryParams.toString()}`;
  const response = await API.get(url);
  return response.data;
};
