import React, { useState } from 'react';
import { view } from 'react-easy-state';
import { mainStore } from '../../core/store/mainStore';
import { redirectToHome } from '../../core/authorization';
import ForgotPasswordBox from '../../containers/InitialPage';
import FinishedForm from './components/FinishedForm';
import ResetPasswordForm from './components/ResetPasswordForm';

const CreatePasswordResetToken = ({ history }) => {
  const [finished, setFinished] = useState(false);
  const { token } = mainStore;

  if (token) {
    return redirectToHome();
  }

  const renderFinishedForm = () => {
    return <FinishedForm history={history} />;
  }

  const renderResetPasswordForm = () => {
    return (
      <ResetPasswordForm 
        history={history}
        finishedHandler={setFinished}
      />
    );
  }

  return (
    <ForgotPasswordBox>
      { finished ? renderFinishedForm() : renderResetPasswordForm() }
    </ForgotPasswordBox>
  );
};

export default view(CreatePasswordResetToken);
