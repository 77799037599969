import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { format } from 'date-fns';
import Loading from '../../components/Loading';
import PageContent from '../../components/PageContent';
import Filter from './components/Filter';
import EngagementTable from './components/EngagementTable';
import { ChartCard, OverviewCard } from './components/Card';
import { ChartType } from './components/Card/ChartCard/types';
import { useDebounce } from '../../core/hooks';
import {
  getVisitsHistory,
  getVisitsIndicators,
  getPromotersEngagement,
} from '../../core/services/visits/visitsService';
import * as dictionary from '../../core/constants/dictionary';
import {
  FormattedVisitHistoryDTO,
  PromotersEngagementDTO,
  PromotersEngagementItem,
  VisitHistoryDTO,
} from './types';
import {
  formatChart,
  parseDuration,
  INITIAL_FILTERS,
  initialVisitsHistoryState,
  initialVisitsIndicatorsState,
} from './utils';

const PromoterVisitsDashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [promotersEngagement, setPromotersEngagement] = useState<
    PromotersEngagementItem[]
  >([]);
  const [visitsHistory, setVisitsHistory] = useState<FormattedVisitHistoryDTO>(
    initialVisitsHistoryState
  );
  const [visitsIndicators, setVisitsIndicators] = useState(
    initialVisitsIndicatorsState
  );
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [supplierNameQuery, setSupplierNameQuery] = useState('');
  const [toolbarInputFocus, setToolbarInputFocus] = useState(false);

  const fetchPromotersEngagement = async (queryString = '') => {
    setIsLoading(true);

    try {
      const params = {
        ...filters,
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        query: queryString ? queryString : supplierNameQuery,
        startDate: format(filters.startDate, 'yyyy-MM-dd'),
        endDate: format(filters.endDate, 'yyyy-MM-dd'),
      };
      const response: PromotersEngagementDTO = await getPromotersEngagement(
        params
      );
      setTotal(response.count);
      setPromotersEngagement(response.items);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const fetchVisitsIndicators = async () => {
    setIsLoading(true);

    try {
      const params = {
        ...filters,
        startDate: format(filters.startDate, 'yyyy-MM-dd'),
        endDate: format(filters.endDate, 'yyyy-MM-dd'),
      };
      const response = await getVisitsIndicators(params);
      response.averageDuration = parseDuration(response.averageDuration);
      setVisitsIndicators(response);
    } catch (e) {
      console.log('Error: ', e);
    }
    setIsLoading(false);
  };

  const fetchVisitsHistory = async () => {
    setIsLoading(true);

    try {
      const params = {
        ...filters,
        startDate: format(filters.startDate, 'yyyy-MM-dd'),
        endDate: format(filters.endDate, 'yyyy-MM-dd'),
      };
      const response: VisitHistoryDTO = await getVisitsHistory(params);
      setVisitsHistory(formatChart(response));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onTooltipSearchHandler = useDebounce((value: string[]) => {
    const queryParam = String(value);
    setPage(0);
    setSupplierNameQuery(queryParam);
  }, 500);

  useEffect(() => {
    const shouldFocus = supplierNameQuery.length > 0;
    setToolbarInputFocus(shouldFocus);
  }, [supplierNameQuery]);

  const onSubmitFilterHandler = (filters: any) => {
    setFilters(filters);
  };

  useEffect(() => {
    if (filters.retailId.length) {
      fetchVisitsHistory();
      fetchVisitsIndicators();
      fetchPromotersEngagement();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, page, rowsPerPage, supplierNameQuery]);

  return (
    <>
      <PageContent title={dictionary.VISITS_DASHBOARD}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Filter
              initialFilters={filters}
              onSubmit={(filters: any) => onSubmitFilterHandler(filters)}
            />
          </Grid>

          <Grid item lg={3} sm={6}>
            <OverviewCard
              title={dictionary.CHECK_IN_TOTAL}
              value={visitsIndicators.checkinAmount}
            />
          </Grid>
          <Grid item lg={3} sm={6}>
            <OverviewCard
              title={dictionary.CHECKOUT_AMOUNT}
              value={visitsIndicators.checkoutAmount}
              description={dictionary.CHECKOUTS_ACCOUNTED}
              percentage={visitsIndicators.checkoutPercentage}
            />
          </Grid>
          <Grid item lg={3} sm={6}>
            <OverviewCard
              title={dictionary.SUPPLIERS_AMOUNT}
              value={visitsIndicators.suppliersAmount}
            />
          </Grid>
          <Grid item lg={3} sm={6}>
            <OverviewCard
              title={dictionary.AVERAGE_DURATION_VISITS}
              value={visitsIndicators.averageDuration}
            />
          </Grid>

          <Grid item md={6}>
            <ChartCard
              type={ChartType.LINE}
              title={dictionary.VISIT_HISTORY}
              data={visitsHistory.checkinAmount}
            />
          </Grid>
          <Grid item md={6}>
            <ChartCard
              type={ChartType.BAR}
              title={dictionary.AVERAGE_DAILY_VISITS}
              data={visitsHistory.averageCheckinAmountPerWeekDay}
            />
          </Grid>

          <Grid item xs={12}>
            <EngagementTable
              data={promotersEngagement}
              count={total}
              pageIndex={page}
              rowsPerPage={rowsPerPage}
              searchTextValue={supplierNameQuery}
              toolbarInputFocus={toolbarInputFocus}
              onChangePageHandler={(page: number) => setPage(page)}
              onChangeRowsPerPageHandler={(value: number) => {
                setPage(0);
                setRowsPerPage(value);
              }}
              onSearchChangeHandler={(value: string) =>
                onTooltipSearchHandler(value)
              }
            />
          </Grid>
        </Grid>
      </PageContent>

      <Loading isOpen={isLoading} />
    </>
  );
};

export default PromoterVisitsDashboard;
