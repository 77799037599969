import React from 'react';
import Dot from './components/Dot';
import * as dictionary from '../../../../core/constants/dictionary';
import { getSupplyStatusAndProblemsDescription, getformattedCurrency, getFormattedDate } from '../../../../core/shared/missions';
import {
  getShelfStatusDescription,
  MissionsOverviewTableProps,
  calculateSaleStatus,
  getSaleSignal,
  getDotColor,
  formatShoppersPerDay,
} from './utils';
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { EmptyDataMessageRow } from '../../../../components/Tables/DefaultTable';

const MissionsOverviewTable = ({
  items,
  total,
  page,
  rowsPerPage,
  loading,
  order,
  orderBy,
  onChangePage,
  onChangeRowsPerPage,
  onSort,
}: MissionsOverviewTableProps) => {
  const tableHeaders = [
    { id: 'store', label: dictionary.STORE, isOrderable: true },
    { id: 'checkDate', label: dictionary.RESTOCK_DATE, isOrderable: true },
    { id: 'productName', label: dictionary.PRODUCT_NAME, isOrderable: true },
    { id: 'daysWithoutSell', label: dictionary.DAYS_WITHOUT_SALE, isOrderable: true },
    { id: 'shoppersPerDay', label: dictionary.SHOPPERS_PER_DAY, isOrderable: true },
    { id: 'estimatedSale', label: dictionary.ESTIMATED_SALE, isOrderable: true },
    { id: 'check', label: dictionary.CONFERENCES, isOrderable: false },
    { id: 'statusAndProblems', label: dictionary.STATUS_AND_PROBLEMS, isOrderable: false },
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaders.map((cell) => {
              const active = orderBy === cell.id;
              const showIcon = order !== undefined;
              const orderParam = order ? order : 'asc';
              const direction = orderBy === cell.id ? orderParam : 'asc';

              return (
                <TableCell key={cell.id} sortDirection={active ? order : false}>
                  {
                    // Campo ordenável
                    cell.isOrderable && (
                      <TableSortLabel
                        active={active && showIcon}
                        direction={direction}
                        onClick={() => onSort(cell.id)}
                      >
                        {cell.label}
                      </TableSortLabel>
                    )
                  }
                  {
                    // Campo não ordenável
                    !cell.isOrderable && cell.label
                  }
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            loading ? (
              <TableCell colSpan={tableHeaders.length}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress />
                </div>
              </TableCell>
            ) : (
              items.length > 0 ? (
                items.map((item) => {
                  const saleStatus = calculateSaleStatus({ ...item, restock: item.supply });
                  const dotColor = getDotColor(saleStatus);
                  const storeCodeAndName = `(${item.store.code}) ${item.store.name}`;
                  const createdAt = getFormattedDate(item.check.createdAt);
                  const productCodeAndName = `(${item.product.code}) ${item.product.name}`;
                  const shelfStatus = getShelfStatusDescription({ ...item, restock: item.supply });
                  const statusAndProblems = getSupplyStatusAndProblemsDescription({ ...item, restock: item.supply });
                  const estimatedSale = `${getSaleSignal(saleStatus)} ${getformattedCurrency(item.estimatedSale)}`;
                  const shoppersPerDay = formatShoppersPerDay(item.shoppersPerDay);

                  return (
                    <TableRow key={item.product.code}>
                      <TableCell>
                        {storeCodeAndName}
                      </TableCell>
                      <TableCell>
                        {createdAt}
                      </TableCell>
                      <TableCell>
                        {productCodeAndName}
                      </TableCell>
                      <TableCell>
                        {item.daysWithoutSale}
                      </TableCell>
                      <TableCell>
                        {shoppersPerDay}
                      </TableCell>
                      <TableCell>
                        <Dot color={dotColor} />
                        <span>&nbsp;&nbsp;</span>
                        {estimatedSale}
                      </TableCell>
                      <TableCell>
                        {shelfStatus}</TableCell>
                      <TableCell>
                        {item.supply.status !== null && <Dot color={dotColor} />}
                        <span>&nbsp;&nbsp;</span>
                        {statusAndProblems}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow style={{ height: '120px' }}>
                  <TableCell
                    colSpan={tableHeaders.length}
                    style={{ textAlign: 'center' }}
                  >
                    <EmptyDataMessageRow
                      message={dictionary.EMPTY_SHELF_CHECK_MISSION_TABLE}
                    />
                  </TableCell>
                </TableRow>
              )
            )
          }
        </TableBody>
        <TablePagination
          count={total}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={() => { }}
          onChangePage={(_, page) => onChangePage(page)}
          onChangeRowsPerPage={(event) => onChangeRowsPerPage(event.target.value)}
        />
      </Table>
    </TableContainer>
  );
};

export default MissionsOverviewTable;
