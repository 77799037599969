import API from '../api/config';
import * as routes from '../api/routes';

export const postStoreAPI = async (data) => {
  const url = routes.API_STORES;
  const response = await API.post(url, data);
  return response;
};

export const getStoreAPI = async (
  retailId,
  status,
  offset = 0,
  limit = 9999,
  orderBy,
  order
) => {
  const url = `${routes.API_STORES}`;
  const response = await API.get(url, {
    params: {
      offset,
      limit,
      retailId,
      status,
      order,
      orderBy,
    },
  });
  return response.data.items;
};

export const putStoreAPI = async (data) => {
  const url = `${routes.API_STORES}/${data.id}`;
  const response = await API.put(url, data);
  return response;
};

export const deleteStoreAPI = async (store) => {
  const url = `${routes.API_STORES}/${store.id}`;
  const response = await API.delete(url);
  return response;
};

export const getStoreByIdAPI = async (id) => {
  const url = `${routes.API_STORES}/${id}`;
  const response = await API.get(url, id);
  return response.data;
};
