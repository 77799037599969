import React, { CSSProperties, MouseEventHandler } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { colors } from '../../../Theme/colors';

const useStyles = makeStyles({
  button: {
    color: colors.red_600, 
    borderColor: colors.red_600,
  }
});

type Props = {
  label: string;
  disabled?: boolean;
  onClick?: MouseEventHandler;
  style?: CSSProperties;
}

const OutlinedFailedButton = ({ 
  label, 
  onClick, 
  disabled = false, 
  style = {},
  ...restProps
}: Props) => {
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      disabled={disabled}
      className={classes.button}
      style={style}
      onClick={onClick}
      {...restProps}
    >
      <Typography variant="subtitle1" style={{ color: colors.red_600 }}>
        {label}
      </Typography>
    </Button>
  );
};
export default OutlinedFailedButton;
