import { mainStore } from '../../store/mainStore';
import {
  getAddressingsAPI,
  createAddressingAPI,
  updateAddressingAPI,
  removeAddressingAPI,
  getAddressingByIdAPI,
} from './addressingsAPI';
import { 
  GetPromotionalSpotAddressingsParams, 
  CreatePromotionalSpotAddressingParams, 
  UpdatePromotionalSpotAddressingParams, 
} from './types';

export const getAddressings = ({
  spotType,
  offset = 0,
  limit = 10,
  spotId = '',
  storeId = '',
  retailId = '',
  spotName = '',
  physicalAddressing = '',
  periodStartAt 
}: GetPromotionalSpotAddressingsParams) => {
  return getAddressingsAPI({
    offset,
    limit,
    spotId,
    spotType,
    spotName,
    storeId: storeId ? storeId : mainStore?.loggedUser?.storeId,
    retailId: retailId ? retailId : mainStore?.loggedUser?.retailId,
    physicalAddressing,
    periodStartAt
  });
};

export const getAddressingById = (id: string) => {
  return getAddressingByIdAPI(id);
};

export const createAddressing = (addressing: Partial<CreatePromotionalSpotAddressingParams>) => {
  return createAddressingAPI(addressing);
};

export const updateAddressing = (addressing: Partial<UpdatePromotionalSpotAddressingParams>) => {
  return updateAddressingAPI(addressing);
};

export const removeAddressing = (id: string) => {
  return removeAddressingAPI(id);
};
