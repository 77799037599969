import { getProfileValue } from '../profiles';
import { mainStore } from '../store/mainStore';
import { exist, findValueInArray } from '../validation';
import { getSuppliersByAgreement } from '../services/users/usersService';
import * as dictionary from '../constants/dictionary';
import { LOGGED_USER_KEY } from '../authorization';

const userWasCreated = values => values.creationOrigin === dictionary.CREATED;

const userWasInvited = values => values.creationOrigin === dictionary.INVITED;

const getStoredStore = async () => {
  const user = await localStorage.getItem(LOGGED_USER_KEY);
  return exist(user.store) ? JSON.parse(user.store) : false;
};

const getStoredRetail = async () => {
  const user = await localStorage.getItem(LOGGED_USER_KEY);
  return exist(user.retail) ? JSON.parse(user.retail) : false;
};

const getDefaultProperties = async () => {
  const userValues = mainStore.loggedUser;
  const store = userValues.store ? userValues.store : await getStoredStore();
  const retail = userValues.retail
    ? userValues.retail
    : await getStoredRetail();

  return {
    'User Profile': getProfileValue(userValues.profile),
    'Store Number': store.number,
    'Store Name': store.name,
    Platform: 'Web',
    'Retail Name': retail.name,
  };
};

export const getPrintRestockReportDTO = async data => {
  const defaultProperties = await getDefaultProperties(data);

  const coutDto = {
    productShelfRestockPendingCount: 0,
    productShelfRestockedCount: 0,
    productDepotBreakCount: 0,
    productVirtualStockCount: 0,
  };

  data.reportData.forEach(report => {
    if (report.status === 0) {
      coutDto.productShelfRestockPendingCount += 1;
    } else if (report.status === 1) {
      coutDto.productShelfRestockedCount += 1;
    } else if (report.status === 2 && report.virtualStock) {
      coutDto.productVirtualStockCount += 1;
    } else {
      coutDto.productDepotBreakCount += 1;
    }
  });

  return {
    ...defaultProperties,
    'Group Name': exist(data.group) ? data.group.name : '',
    'Sub Group Name': exist(data.subgroup) ? data.subgroup.name : '',
    'Date Filter': data.date.toISOString(),
    'Product Shelf Restock Pending Count':
      coutDto.productShelfRestockPendingCount,
    'Product Shelf Restocked Count': coutDto.productShelfRestockedCount,
    'Product Depot Break Count': coutDto.productDepotBreakCount,
    'Product Virtual Stock Count': coutDto.productVirtualStockCount,
    'Product Restock Shelf Audit Total Count': data.reportData.length,
  };
};

export const getExportRestockReportDTO = async data => {
  const defaultProperties = await getDefaultProperties(data);

  const coutDto = {
    productShelfRestockPendingCount: 0,
    productShelfRestockedCount: 0,
    productDepotBreakCount: 0,
    productVirtualStockCount: 0,
  };

  data.reportData.forEach(report => {
    if (report.status === 0) {
      coutDto.productShelfRestockPendingCount += 1;
    } else if (report.status === 1) {
      coutDto.productShelfRestockedCount += 1;
    } else if (report.status === 2 && report.virtualStock) {
      coutDto.productVirtualStockCount += 1;
    } else {
      coutDto.productDepotBreakCount += 1;
    }
  });

  return {
    ...defaultProperties,
    'Group Name': exist(data.group) ? data.group.name : '',
    'Sub Group Name': exist(data.subgroup) ? data.subgroup.name : '',
    'Retail Name': exist(data.retail) ? data.retail.name : '',
    'Date Filter': data.date.toISOString(),
    'Product Shelf Restock Pending Count':
      coutDto.productShelfRestockPendingCount,
    'Product Shelf Restocked Count': coutDto.productShelfRestockedCount,
    'Product Depot Break Count': coutDto.productDepotBreakCount,
    'Product Virtual Stock Count': coutDto.productVirtualStockCount,
    'Product Restock Shelf Audit Total Count': data.reportData.length,
  };
};

export const getUserInvitedDTO = async values => {
  const agreement = findValueInArray(
    mainStore.agreements.data,
    'id',
    values.agreementId,
  );
  const retail = findValueInArray(
    mainStore.agreements.retails,
    'id',
    agreement.retailId,
  );
  const suppliers = await getSuppliersByAgreement(values.agreementId);
  const event = new Date();
  const agreementSuppliersList = suppliers.map(supplier => supplier.name);

  return {
    'Event Owner Type': getProfileValue(mainStore.loggedUser.profile),
    'Event Owner ID': mainStore.loggedUser.id,
    'Event Owner CPF': mainStore.loggedUser.cpf,
    'Invited User Type': getProfileValue(values.userProfile),
    'Invited User ID': values.email,
    'Invited Date': event.toISOString(),
    'Agreement ID': values.agreementId,
    'Agreement Internal Code': agreement.internalCode,
    'Agreement Retail Owner': retail.name,
    'Agreement Suppliers List': agreementSuppliersList,
  };
};

export const getUserCreatedDTO = (values, agreementId) => {
  const wasCreated = userWasCreated(values);
  const wasInvited = userWasInvited(values);

  const event = new Date();
  let dto;
  if (wasInvited) {
    dto = {
      'Creation Origin': values.creationOrigin,
      'Invited Date': wasInvited ? event.toISOString() : '',
      'User E-mail': values.email,
      'User Created Date': event.toISOString(),
      'Agreement ID': wasInvited ? agreementId : '',
    };
  } else {
    dto = {
      'Event Owner Type': wasCreated
        ? getProfileValue(mainStore.loggedUser.profile)
        : '',
      'Event Owner ID': wasCreated ? mainStore.loggedUser.id : '',
      'Event Owner CPF': wasCreated ? mainStore.loggedUser.cpf : '',
      'Creation Origin': values.creationOrigin,
      'Invited Date': wasInvited ? event.toISOString() : '',
      'Invited User ID': values.email,
      'User Created Date': event.toISOString(),
      'Agreement ID': wasInvited ? agreementId : '',
    };
  }
  return dto;
};

export const getRetailCreateDTO = async data => ({
  'User Profile': getProfileValue(mainStore.loggedUser.profile),
  'Retail Name': exist(data.retail) ? data.retail.name : '',
  Platform: 'Web',
  'Activation Status': exist(data.retail) ? data.retail.status : '',
  'Retail CNPJ': exist(data.retail) ? data.retail.cnpj : '',
});

export const getRetailUpdateDTO = async data => ({
  'User Profile': getProfileValue(mainStore.loggedUser.profile),
  'Retail Name': exist(data.retail) ? data.retail.name : '',
  Platform: 'Web',
  'Activation Status': exist(data.retail) ? data.retail.status : '',
  'Retail CNPJ': exist(data.retail) ? data.retail.cnpj : '',
});

export const getRetailDeleteDTO = async data => ({
  'User Profile': getProfileValue(mainStore.loggedUser.profile),
  'Retail Name': exist(data.retail) ? data.retail.name : '',
  Platform: 'Web',
  'Activation Status': exist(data.retail) ? data.retail.status : '',
  'Retail CNPJ': exist(data.retail) ? data.retail.cnpj : '',
});

export const getStoreCreateDTO = async data => {
  const hasStore = exist(data.store);
  return {
    'User Profile': getProfileValue(mainStore.loggedUser.profile),
    'Retail Name': exist(data.retail) ? data.retail.name : '',
    Platform: 'Web',
    'Activation Status': hasStore ? data.store.status : '',
    'Store Number': hasStore ? data.store.number : '',
    'Store Name': hasStore ? data.store.name : '',
    'Store City': hasStore ? data.store.city : '',
    'Store State': hasStore ? data.store.state : '',
  };
};

export const getStoreEditDTO = async data => {
  const hasStore = exist(data.store);
  return {
    'User Profile': getProfileValue(mainStore.loggedUser.profile),
    'Retail Name': exist(data.retail) ? data.retail.name : '',
    Platform: 'Web',
    'Activation Status': hasStore ? data.store.status : '',
    'Store Number': hasStore ? data.store.number : '',
    'Store Name': hasStore ? data.store.name : '',
    'Store City': hasStore ? data.store.city : '',
    'Store State': hasStore ? data.store.state : '',
  };
};

export const getStoreDeleteDTO = async data => {
  const hasStore = exist(data.store);
  return {
    'User Profile': getProfileValue(mainStore.loggedUser.profile),
    'Retail Name': exist(data.retail) ? data.retail.name : '',
    Platform: 'Web',
    'Activation Status': hasStore ? data.store.status : '',
    'Store Number': hasStore ? data.store.number : '',
    'Store Name': hasStore ? data.store.name : '',
    'Store City': hasStore ? data.store.city : '',
    'Store State': hasStore ? data.store.state : '',
  };
};

export const getPromoterRetailAccessAuthorizationDTO = async data => {
  const userValues = mainStore.loggedUser;
  const retail = userValues.retail
    ? userValues.retail
    : await getStoredRetail();

  return {
    'User Profile': getProfileValue(userValues.profile),
    Platform: 'Web',
    'Retail Name': retail.name,
    'Promoter CPF': data.cpf,
    'Promoter Suppliers': data.suppliers,
  };
};

export const getPromoterRetailAccessRevokeDTO = async data => {
  const userValues = mainStore.loggedUser;
  const retail = userValues.retail
    ? userValues.retail
    : await getStoredRetail();

  return {
    'User Profile': getProfileValue(userValues.profile),
    Platform: 'Web',
    'Retail Name': retail.name,
    'Promoter CPF': data.cpf,
    'Promoter Suppliers': data.suppliers,
  };
};

export const getPromoterRetailAccessDeleteDTO = async data => {
  const userValues = mainStore.loggedUser;
  const retail = userValues.retail
    ? userValues.retail
    : await getStoredRetail();

  return {
    'User Profile': getProfileValue(userValues.profile),
    Platform: 'Web',
    'Retail Name': retail.name,
    'Promoter CPF': data.cpf,
    'Promoter Suppliers': data.suppliers,
    'Promoter Access Status': data.status,
  };
};

export const getAgreementCreateDTO = async (
  data,
  invitesCount,
  salesRepCount,
  suppliers,
) => {
  const userValues = mainStore.loggedUser;
  const retail = userValues.retail
    ? userValues.retail
    : await getStoredRetail();

  const suppliersNameList = suppliers.map(supplier => {
    return supplier.name;
  });

  return {
    'User Profile': getProfileValue(userValues.profile),
    Platform: 'Web',
    'Retail Name': retail.name,
    'Agreement Type': 'Promoter',
    'Description Filled': data.description.length > 0,
    'Agreement Sales Rep Invited Count': invitesCount,
    'Agreement Sales Rep Added Count': salesRepCount,
    'Agreement Sales Rep Total Count': invitesCount + salesRepCount,
    'Agreement Code': data.internalCode,
    'Agreement Suppliers': suppliersNameList,
  };
};

export const getAgreementCreateFrequencyStepDTO = async (
  data,
  storeValuesDTO,
) => {
  const userValues = mainStore.loggedUser;
  const retail = userValues.retail
    ? userValues.retail
    : await getStoredRetail();

  return {
    'User Profile': getProfileValue(userValues.profile),
    'Agreement Type': 'Promoter',
    Platform: 'Web',
    'Retail Name': retail.name,
    'Agreement Code': data.internalCode,
    'Retail Stores Count': storeValuesDTO.storesCount,
    'Agreement Stores Frequency Count': storeValuesDTO.storesFrequencyCount,
  };
};

export const getAgreementFrequencyEditDTO = async (data, storeValuesDTO) => {
  const userValues = mainStore.loggedUser;
  const retail = userValues.retail
    ? userValues.retail
    : await getStoredRetail();

  return {
    'User Profile': getProfileValue(userValues.profile),
    'Agreement Type': 'Promoter',
    Platform: 'Web',
    'Retail Name': retail.name,
    'Agreement Code': data.internalCode,
    'Retail Stores Count': storeValuesDTO.storesCount,
    'Agreement Stores Frequency Count': storeValuesDTO.storesFrequencyCount,
  };
};

export const getAgreementEditDTO = async (
  data,
  invitesCount,
  salesRepCount,
  suppliers,
) => {
  const userValues = mainStore.loggedUser;
  const retail = userValues.retail
    ? userValues.retail
    : await getStoredRetail();

  const suppliersNameList = suppliers.map(supplier => {
    return supplier.name;
  });

  return {
    'User Profile': getProfileValue(userValues.profile),
    Platform: 'Web',
    'Retail Name': retail.name,
    'Agreement Type': 'Promoter',
    'Description Filled': data.description.length > 0,
    'Agreement Sales Rep Invited Count': invitesCount,
    'Agreement Sales Rep Added Count': salesRepCount,
    'Agreement Sales Rep Total Count': invitesCount + salesRepCount,
    'Agreement Code': data.internalCode,
    'Agreement Suppliers': suppliersNameList,
  };
};

export const getSalesRepInviteSentDTO = async data => {
  const userValues = mainStore.loggedUser;
  const retail = userValues.retail
    ? userValues.retail
    : await getStoredRetail();

  const suppliersNameList = data.suppliers.map(supplier => {
    return supplier.name;
  });

  return {
    'User Profile': getProfileValue(userValues.profile),
    'Agreement Type': 'Promoter',
    Platform: 'Web',
    'Retail Name': retail.name,
    'Sales Rep E-mail': data.salesRepEmail,
    'Agreement Suppliers': suppliersNameList,
    'Agreement Code': data.code,
  };
};

export const getSalesRepAgreementDeleteDTO = async data => {
  const userValues = mainStore.loggedUser;
  const retail = userValues.retail
    ? userValues.retail
    : await getStoredRetail();

  const suppliersNameList = data.suppliers.map(supplier => {
    return supplier.name;
  });

  return {
    'User Profile': getProfileValue(userValues.profile),
    'Agreement Type': 'Promoter',
    Platform: 'Web',
    'Retail Name': retail.name,
    'Sales Rep E-mail': data.salesRepEmail,
    'Agreement Suppliers': suppliersNameList,
    'Agreement Code': data.code,
  };
};

export const getAgreementPromoterEditDTO = async (
  data,
  invitesCount,
  promotersCount,
  supplierNames,
) => {
  const userValues = mainStore.loggedUser;
  const retail = userValues.retail
    ? userValues.retail
    : await getStoredRetail();

  return {
    'User Profile': getProfileValue(userValues.profile),
    'Agreement Type': 'Promoter',
    Platform: 'Web',
    'Retail Name': retail.name,
    'Agreement Promoter Invited Count': invitesCount,
    'Agreement Promoter Added Count': promotersCount,
    'Agreement Promoter Total Count': invitesCount + promotersCount,
    'Agreement Code': data.internalCode,
    'Agreement Suppliers': supplierNames,
  };
};

export const getPromoterInviteSentDTO = async data => {
  const userValues = mainStore.loggedUser;
  const retail = userValues.retail
    ? userValues.retail
    : await getStoredRetail();

  return {
    'User Profile': getProfileValue(userValues.profile),
    'Agreement Type': 'Promoter',
    Platform: 'Web',
    'Retail Name': retail.name,
    'Promoter E-mail': data.promoterEmail,
    'Agreement Suppliers': data.suppliers,
    'Agreement Code': data.code,
  };
};

export const getAgreementPromoterDeleteDTO = async data => {
  const userValues = mainStore.loggedUser;
  const retail = userValues.retail
    ? userValues.retail
    : await getStoredRetail();

  return {
    'User Profile': getProfileValue(userValues.profile),
    'Agreement Type': 'Promoter',
    Platform: 'Web',
    'Retail Name': retail.name,
    'Promoter E-mail': data.salesRepEmail,
    'Agreement Suppliers': data.suppliers,
    'Agreement Code': data.code,
  };
};

export const getAgreementPromoterReleasedDTO = async data => {
  const userValues = mainStore.loggedUser;
  const retail = userValues.retail
    ? userValues.retail
    : await getStoredRetail();

  return {
    'User Profile': getProfileValue(userValues.profile),
    'Agreement Type': 'Promoter',
    Platform: 'Web',
    'Retail Name': retail.name,
    'Promoter E-mail': data.salesRepEmail,
    'Agreement Suppliers': data.suppliers,
    'Agreement Code': data.code,
  };
};

export const getAgreementPromoterInactivatedDTO = async data => {
  const userValues = mainStore.loggedUser;
  const retail = userValues.retail
    ? userValues.retail
    : await getStoredRetail();

  return {
    'User Profile': getProfileValue(userValues.profile),
    'Agreement Type': 'Promoter',
    Platform: 'Web',
    'Retail Name': retail.name,
    'Promoter E-mail': data.salesRepEmail,
    'Agreement Suppliers': data.suppliers,
    'Agreement Code': data.code,
  };
};

export const getAgreementDeleteDTO = async data => {
  const userValues = mainStore.loggedUser;
  const retail = userValues.retail
    ? userValues.retail
    : await getStoredRetail();

  return {
    'User Profile': getProfileValue(userValues.profile),
    'Agreement Type': 'Promoter',
    Platform: 'Web',
    'Retail Name': retail.name,
    'Agreement Suppliers': data.suppliers,
    'Agreement Code': data.code,
  };
};
