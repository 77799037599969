export enum ModalType {
  VIEW = 'VIEW',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  ADDRESSING = 'ADDRESSING',
  FIX_NEGOTIATION = 'FIX_NEGOTIATION',
  UNFIX_NEGOTIATION = 'UNFIX_NEGOTIATION',
  VIEW_REPORTED_PROBLEMS = 'VIEW_REPORTED_PROBLEMS',
  OVERRIDE_EXECUTIONS = 'OVERRIDE_EXECUTIONS',
}
