import React, {useMemo, useRef} from 'react';
import MaterialTable, {MTableToolbar} from 'material-table';
import Edit from '../../../assets/tables/edit.svg';
import Remove from '../../../assets/tables/remove.svg';
import IconTable from '../../../components/Tables/IconTable';
import PatchedPagination from '../../../components/PatchedPagination';
import {useDebounce} from '../../../core/hooks';
import { profileCheck } from '../../../core/profiles';
import {EmptyDataMessageRow} from '../../../components/Tables/DefaultTable';
import {MaterialTableColumnProps} from '../../../core/types/custom-material-table.type';
import {DEBOUNCE_DELAY_ON_FETCH_USER} from '../../../core/constants/user';
import { 
  LINES,
  SEARCH,
  ACTIVE,
  RETAIL,
  INACTIVE,
} from '../../../core/constants/dictionary';
import {ModalType} from '../utils';

type Props = {
  data?: any;
  page?: any;
  total?: any;
  rowsPerPage?: any;
  searchTextValue?: any;
  toolbarInputFocus?: any;
  onOpenModal: Function;
  onChangePage?: any;
  onChangeSearch?: any;
  onChangeRowsPerPage?: any;
}

const colorControl = (rowData: any) => rowData.status !== 0 ? 'inherit' : 'disabled';

const SupplierTable = ({
  data,
  page,
  total,
  rowsPerPage,
  searchTextValue,
  toolbarInputFocus,
  onOpenModal,
  onChangePage = () => {},
  onChangeSearch = () => {},
  onChangeRowsPerPage = () => {},
}: Props) => {
  const tableRef = useRef(null);

  const columns: MaterialTableColumnProps[] = ([
    {
      field: 'cnpj',
      title: 'Cnpj',
      render: (data: any) => {
        const rg = new RegExp('^[0-9]+$', 'g');
        if (data?.cnpj && data.cnpj.match(rg)) {
          return data.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1$2$3/$4-$5");
        }
        return '';
      },
    },
    {
      field: 'internalCode',
      title: 'Código interno',
    },
    {
      field: 'name',
      title: 'Razão social',
    },
    {
      field: 'internalName',
      title: 'Nome interno',
    },
    {
      field: 'active',
      title: 'Status',
      sorting: false,
      render: (data: any) => data.status === 1 ? ACTIVE : INACTIVE,
    },
  ]);

  const formattedColumns: MaterialTableColumnProps[] = useMemo(() => {
    if (profileCheck.isSuperAdmin()) {
      const retail: MaterialTableColumnProps  = {
        type: 'string',
        field: 'retailName',
        title: RETAIL,
      }
      return [retail].concat(columns);
    }

    return columns;
  }, [columns]);

  const onChangeSearchHandler = useDebounce((value: string[]) => {
    onChangeSearch(value[0]);
  }, DEBOUNCE_DELAY_ON_FETCH_USER);

  return (
    <MaterialTable
        title={''}
        data={data}
        page={page}
        key={data.length}
        tableRef={tableRef}
        columns={formattedColumns}
        options={{
          actionsColumnIndex: -1,
          toolbarButtonAlignment: 'left',
          pageSize: rowsPerPage,
          pageSizeOptions: [5, 10, 20],
        }}
        actions={[
          rowData => ({
            icon: () => (
              <IconTable
                icon={Edit}
                color={colorControl(rowData)}
              />
            ),
            tooltip: 'Editar fornecedor',
            onClick: (event, rowData) => onOpenModal(ModalType.UPDATE, rowData),
          }),
          rowData => ({
            icon: () => (
              <IconTable icon={Remove} color={colorControl(rowData)} />
            ),
            tooltip: 'Excluir fornecedor',
            onClick: (event, rowData) => onOpenModal(ModalType.DELETE, rowData),
          }),
        ]}
        localization={{
          header: { actions: '' },
          body: {
            emptyDataSourceMessage:
              <EmptyDataMessageRow
                message={'Não foram encontrados fornecedores para a rede'}
              />,
          },
          pagination: {
            labelRowsSelect: LINES,
            labelDisplayedRows: '{from}-{to} de {count}',
          },
          toolbar: {
            searchPlaceholder: SEARCH
          }
        }}
        components={{
          Pagination: props => (
            <PatchedPagination
              {...props}
              page={page}
              count={total}
              rowsPerPage={rowsPerPage}
              onChangePage={(evt: any, page: number) => onChangePage(page)}
              onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChangeRowsPerPage(parseInt(event.target.value));
              }}
            />
          ),
          Toolbar: (props) => (
            <MTableToolbar
              {...props}
              searchText={searchTextValue}
              searchAutoFocus={toolbarInputFocus}
              onSearchChanged={(value: string) => onChangeSearchHandler(value)}
            />
          ),
        }}
      />
  );
}

export default SupplierTable;
