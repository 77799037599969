import React, { useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ModalTitle from '../Modal/Title';
import { colors } from '../../Theme/colors';

const useStyles = makeStyles(theme => ({
  ...theme.sizes.defaultInputsMarginSize,
  icon: { color: `${colors.blue_500} !important` },
}));

function ModalSteps({ steps, title, maxWidth, open, currentStep }) {
  const classes = useStyles();

  const renderSteps = useMemo(() => {
    return steps.map(({ label }) => (
      <Step key={label}>
        <StepLabel StepIconProps={{ classes: { active: classes.icon } }}>
          {label}
        </StepLabel>
      </Step>
    ));
  }, [classes.icon, steps]);

  const renderStepContent = useMemo(() => {
    return steps[currentStep].content;
  }, [currentStep, steps]);

  return (
    <Dialog maxWidth={maxWidth} fullWidth open={open}>
      <div className="form-two-columns step-title">
        <ModalTitle title={title} />

        <Stepper activeStep={currentStep}>{renderSteps}</Stepper>
      </div>
      <DialogContent>{renderStepContent}</DialogContent>
    </Dialog>
  );
}

ModalSteps.defaultProps = {
  title: '',
  maxWidth: 'md',
  open: false,
};

export default ModalSteps;
