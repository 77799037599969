import React from 'react';
import { TableHead, TableRow, TableCell } from '@material-ui/core';
import {
  STORE,
  RESTOCK_DATE,
  SKU_NAME,
  DAYS_WITHOUT_SALE,
  SHOPPERS_PER_DAY,
  ESTIMATED_SALE,
  STATUS_AND_PROBLEMS,
  SUSPENSION_DEADLINE,
} from '../../../core/constants/dictionary';

const SuspiciousProductsTableHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell>{STORE}</TableCell>
      <TableCell>{RESTOCK_DATE}</TableCell>
      <TableCell>{SKU_NAME}</TableCell>
      <TableCell>{DAYS_WITHOUT_SALE}</TableCell>
      <TableCell>{SHOPPERS_PER_DAY}</TableCell>
      <TableCell>{ESTIMATED_SALE}</TableCell>
      <TableCell>{STATUS_AND_PROBLEMS}</TableCell>
      <TableCell>{SUSPENSION_DEADLINE}</TableCell>
    </TableRow>
  </TableHead>
);

export default SuspiciousProductsTableHeader;
