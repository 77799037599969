import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { PromotionalNegotiationPanelDto } from '../../../../../core/types/promotional-spot/promotional-negotiation-panel.dto';
import * as dictionary from '../../../../../core/constants/dictionary';
import Accordion from '../Accordion';
import classes from './AccordionList.module.scss';

type Props = {
  data: PromotionalNegotiationPanelDto[];
  stores: any[];
};

const AccordionList = ({ data, stores }: Props) => {
  const [openedItemIndex, setOpenedItemIndex] = useState<string | null>(() =>
    data?.length ? `${data[0].spot.id}-${data[0].period.id}` : null
  );

  useEffect(() => {
    if (data?.[0]) {
      setOpenedItemIndex(`${data[0].spot.id}-${data[0].period.id}`);
    }
  }, [data]);

  return (
    <div className={classes.container}>
      {data?.length ? (
        data.map((accordion) => {
          const indexItem = `${accordion.spot.id}-${accordion.period.id}`;
          const isOpen = indexItem === openedItemIndex;
          return (
            <Accordion
              stores={stores}
              key={indexItem}
              data={accordion}
              isOpen={isOpen}
              onToggle={() =>
                setOpenedItemIndex((prevState) =>
                  prevState === indexItem ? null : indexItem
                )
              }
              className={[classes.item, isOpen && classes.open].join(' ')}
            />
          );
        })
      ) : (
        <div className={classes.empty}>
          <Typography variant="body1">
            {dictionary.EMPTY_NEGOTIATION_PANEL}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default AccordionList;
