import {
  getScheduledVisitsAPI,
  addPromoterToAgreementAPI,
  removePromoterByAgreementAPI,
  getAgreementAPI,
  postAgreementAPI,
  putAgreementAPI,
  putVisitsAPI,
  deleteAgreementAPI,
  updatePromoterByAgreementAPI,
} from './agreementsAPI';

export const getScheduledVisits = agreementId => {
  return getScheduledVisitsAPI(agreementId);
};

export const addPromoterToAgreement = (agreementId, promoterId) => {
  return addPromoterToAgreementAPI(agreementId, promoterId);
};

export const removePromoterByAgreement = (agreementId, promoterId) => {
  return removePromoterByAgreementAPI(agreementId, promoterId);
};

export const updatePromoterByAgreement = (agreementId, promoterId, data) => {
  return updatePromoterByAgreementAPI(agreementId, promoterId, data);
};

export const getAgreement = (retailId, status, offset = 0, limit = 1000, query = null) => {
  return getAgreementAPI(retailId, status, offset, limit, query);
};

export const postAgreement = data => {
  return postAgreementAPI(data);
};

export const putAgreement = data => {
  return putAgreementAPI(data);
};

export const putVisits = (data, agreementId) => {
  return putVisitsAPI(data, agreementId);
};

export const deleteAgreement = agreement => {
  return deleteAgreementAPI(agreement);
};
