import styled from 'styled-components';
import { theme, withProp } from 'styled-tools';
import { transparentize } from 'polished';
import { Form } from 'formik';
import { Title, Button, Text, Flex } from '@involves/violin';
import { FormInputDropdownCPF } from '../../components/Form';
import { colors } from '../../Theme/colors';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.palette.black[900]};
  height: 100vh;
  width: 100vw;
  padding-left: 26px;
  padding-right: 26px;

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const StyledRow = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    align-self: start;
    justify-self: center;
    z-index: ${props => props.theme.zIndex.z1};
  }
`;

export const StyledLogo = styled.img`
  width: 140px;
  height: 40px;
  padding-top: 96px;
  padding-bottom: 80px;
`;

export const StyledTitle = styled(Title)`
  color: ${props => props.theme.palette.black[50]};
  padding-bottom: 16px;
`;

export const StyledMessage = styled(StyledTitle)`
  font-weight: normal;
  padding-bottom: 40px;
`;

export const StyledForm = styled(Form)`
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
  flex-direction: column;
`;

export const StyledFormInputDropdownContainer = styled.div`
  position: relative;
`;

export const StyledFormInputDropdownCPF = styled(FormInputDropdownCPF)`
  border-radius: 8px;
  background: ${withProp(theme('palette.misc.white'), transparentize(0.85))};

  ::placeholder {
    color: ${props => props.theme.palette.black[50]};
  }

  :focus {
    ::placeholder {
      color: ${props => props.theme.palette.black[900]};
    }
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 40px;
  border-radius: 8px;
  :disabled {
    color: ${props => transparentize(0.8, props.theme.palette.black[50])};
    background: ${props =>
      transparentize(0.85, props.theme.palette.misc.white)};
  }
`;

export const StyledBackgroundContainer = styled.div`
  display: none;
  position: fixed;
  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    display: initial;
  }
`;

export const StyledFeedbackFailImage = styled.img`
  margin-bottom: 48px;
`;

export const StyledText = styled(Text)`
  font-weight: ${theme('typography.fontWeight.semiBold')};
  padding-top: 16px;
  line-height: 21px;
`;

export const StyledModalTextContainer = styled(Flex)`
  justify-content: space-between;
`;

export const styles = {
  errorModaltext: {
    color: colors.black_900,
    fontWeight: 'bold',
    marginBottom: '24px',
  },
  errorModalImg: {
    width: '96px',
    marginTop: '120px',
    marginBottom: '56px',
  },
  errorModalDialog: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  }
}
