import { colors } from '../../../../../Theme/colors';


export const card = {
    height: '134px',
    padding: '16px',
}

export const gridTitle = {
    height: '25%',
}

export const value = {
    color: colors.black_700,
    fontWeight: 700,
    fontSize: '34px',
    lineHeight: '36px'
}

export const percentage = {
    color: colors.black_700,
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '26px',
    marginLeft: '4px',
}

export const gridDescription = {
    height: '8%'
}

export const description = {
    color: colors.black_500,
    paddingTop: '4px',
    fontWeight: 700,
    fontSize: '11px',
    lineHeight: '16px'
}
