import { mainStore } from '../../core/store/mainStore';

import {
  deleteRetail,
  getAllRetails,
  postRetail,
  putRetail,
} from '../../core/services/retails/retailsService';
import { getSalesReps } from '../../core/services/users/usersService';
import { getSuppliersByRetail } from '../../core/services/suppliers/suppliersService';

export const getRetails = () => {
  mainStore.requestLoading = true;
  return getAllRetails();
};

export const loadInitialData = async () => {
  mainStore.requestLoading = true;
  mainStore.retails.data = await getRetails();
  mainStore.retails.answerables = await getSalesReps();
  mainStore.requestLoading = false;
};

export const registerRetail = retail => {
  mainStore.requestLoading = true;
  const dto = { name: retail.name, cnpj: retail.cnpj, status: retail.status };
  return postRetail(dto);
};

export const editRetail = retail => {
  mainStore.requestLoading = true;
  const dto = {
    id: retail.id,
    name: retail.name,
    cnpj: retail.cnpj,
    status: retail.status,
  };
  return putRetail(dto);
};

export const getSuppliers = async (params) => {
  return getSuppliersByRetail(params);
};

export const removeRetail = async retail => {
  mainStore.requestLoading = true;
  return deleteRetail(retail);
};
