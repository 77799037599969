import React, { MouseEventHandler } from 'react';
import { Grid } from '@material-ui/core';
import * as dictionary from '../../../../../../../core/constants/dictionary';

import { AddressingDto } from '../../../../../../../core/types/promotional-spot/promotional-addressing.dto';
import { translateAPIToStoreAreaLabel } from '../../../../../Addressing/utils';
import { PromotionalNegotiationExecutionDto } from '../../../../../../../core/types/promotional-spot/promotional-negotiation-execution-dto';
import ImageCard from '../ImageCard';
import TitleAndSubtitle from '../TitleAndSubtitle';
import { parseExecutionStatus } from './utils';
import { formatWithoutTimezone } from '../../../../../../../core/constants/dates';
import { OutlinedFailedButton } from '../../../../../../../components/Buttons';
import { mainStore } from '../../../../../../../core/store/mainStore';
import { userProfile } from '../../../../../../../core/profiles';

type Props = {
  physicalAddressing?: AddressingDto;
  execution?: PromotionalNegotiationExecutionDto;
  isExpired: boolean;
  onRefuse: any;
};

const ExecutionForm = ({
  physicalAddressing,
  execution,
  isExpired,
  onRefuse,
}: Props) => {
  return (
    <>
      <Grid
        direction="row"
        container
        justifyContent="space-between"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item xs>
          {physicalAddressing && (
            <TitleAndSubtitle
              title={dictionary.ADDRESSING}
              subtitle={physicalAddressing.physicalAddressing}
            />
          )}
          {physicalAddressing?.sector && (
            <TitleAndSubtitle
              title={dictionary.STORE_SECTOR}
              subtitle={physicalAddressing.sector.name}
            />
          )}
          {physicalAddressing?.storeArea && (
            <TitleAndSubtitle
              title={dictionary.STORE_AREA}
              subtitle={translateAPIToStoreAreaLabel(
                physicalAddressing.storeArea
              )}
            />
          )}
        </Grid>

        {execution?.id && (
          <Grid item xs>
            {execution.status && (
              <TitleAndSubtitle
                title={dictionary.STATUS}
                subtitle={parseExecutionStatus(isExpired, execution.status)}
              />
            )}
            <TitleAndSubtitle
              title={dictionary.EXECUTED_FOR}
              subtitle={execution?.solvedUser?.name ?? '--'}
            />
            <TitleAndSubtitle
              title={dictionary.EXECUTED_IN}
              subtitle={
                execution?.id && execution.solvedAt
                  ? formatWithoutTimezone(execution.solvedAt)
                  : '--'
              }
            />
          </Grid>
        )}
      </Grid>
      <Grid direction="row" container justifyContent="space-around" spacing={2}>
        {physicalAddressing?.imageUrl && (
          <Grid item xs>
            <ImageCard
              title={dictionary.ADDRESSING_PLACE}
              image={physicalAddressing.imageUrl}
            />
          </Grid>
        )}
        {execution?.image && (
          <Grid item xs>
            <Grid item>
              <ImageCard
                title={dictionary.EXECUTION_PICTURE}
                image={execution.image}
              />
            </Grid>
            {[userProfile.RETAIL_ADMIN, userProfile.SUPER_ADMIN].includes(
              mainStore.loggedUser.profile
            ) && (
              <Grid item>
                <OutlinedFailedButton
                  label={dictionary.REFUSE_EXECUTION}
                  onClick={() => onRefuse()}
                />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ExecutionForm;
