import React, { useState, useCallback, useMemo } from 'react';
import {
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
} from '@material-ui/core';
import * as dictionary from '../../../../core/constants/dictionary';
import ModalTitle from '../../../../components/Modal/Title';
import TextField from '../../../../components/InputTextField';
import CancelButton from '../../../../components/Buttons/Cancel';
import { mainStore } from '../../../../core/store/mainStore';
import DefaultButton from '../../../../components/Buttons/Default';
import Autocomplete from '../../../../components/Autocomplete';
import { utils } from '../../../../core/utils';
import { SyncValidations } from '../../../../core/validator/validations';
import { profileCheck } from '../../../../core/profiles';
import { CepTextMaskCustom } from '../../../../core/masks/masks';
import {
  getEditInicialForm,
  editStore,
  getStores,
  getInitialStateValues,
} from '../../functions';
import { trackStoreEdit } from '../../../../core/mixpanel/mixPanelEvents';

export const EditStore = ({ closeModalCallback, currentStore }) => {
  const [store, setStore] = useState(getEditInicialForm(currentStore));

  const changeValueField = useCallback(
    (field, value) => {
      setStore({ ...store, [field]: value });
    },
    [store, setStore],
  );

  const checkboxStatus = useMemo(
    () => (
      <Checkbox
        checked={store.status === 1}
        onChange={() => {
          changeValueField('status', utils.invertValue(store.status));
        }}
      />
    ),
    [changeValueField, store.status],
  );

  const submitStore = useCallback(
    async event => {
      event.preventDefault();
      if (event.currentTarget.form.reportValidity()) {
        try {
          await editStore(store);

          const storeMixpanelDTO = {
            store: {
              name: store.name,
              status: store.status,
              number: store.number,
              city: store.city,
              state: store.state,
            },
            retail: {
              name: store.retail.name,
            },
          };

          trackStoreEdit(storeMixpanelDTO);
          const result = await getStores(mainStore.loggedUser.retailId);
          setStore(getInitialStateValues());
          mainStore.stores.data = result;
          closeModalCallback();
          utils.openSnackBar(dictionary.SUCCESS, dictionary.STORE_EDITED);
        } catch (err) {
          utils.openSnackBar(dictionary.ERROR, dictionary.UNKNOWN_ERROR);
        } finally {
          mainStore.requestLoading = false;
        }
      } else {
        utils.openSnackBar(dictionary.ERROR, dictionary.FORM_INVALID);
      }
    },
    [closeModalCallback, store],
  );

  const renderRetailSelect = useMemo(() => {
    if (!profileCheck.isRetailAdmin()) {
      return (
        <div className="form-two-columns">
          <Autocomplete
            validations={[SyncValidations.notEmpty]}
            label={dictionary.RETAIL}
            style={{ width: '50%', marginTop: '3px', marginBottom: '20px' }}
            value={store.retail}
            onChange={(e, value) => changeValueField('retail', value)}
            options={mainStore.stores.retails}
          />
        </div>
      );
    }
    return '';
  }, [changeValueField, store]);

  return (
    <>
      <ModalTitle title={dictionary.EDIT_STORE} />
      <form noValidate>
        <DialogContent>
          <div className="form-two-columns">
            <TextField
              validations={[SyncValidations.notEmpty]}
              label={dictionary.STORE_NAME}
              value={store.name}
              onChange={e => changeValueField('name', e.target.value)}
            />

            <TextField
              validations={[SyncValidations.notEmpty]}
              label={dictionary.STORE_CODE}
              value={store.storeCode}
              onChange={e => changeValueField('storeCode', e.target.value)}
            />
          </div>

          <div className="form-two-columns">
            <TextField
              validations={[SyncValidations.notEmpty]}
              label={dictionary.CEP}
              value={store.cep}
              onChange={e => changeValueField('cep', e.target.value)}
              InputProps={{ inputComponent: CepTextMaskCustom }}
            />

            <TextField
              validations={[SyncValidations.notEmpty]}
              label={dictionary.ADDRESS}
              value={store.address}
              onChange={e => changeValueField('address', e.target.value)}
            />
          </div>

          <div className="form-two-columns">
            <TextField
              validations={[SyncValidations.notEmpty]}
              type="number"
              label={dictionary.NUMBER}
              value={store.number}
              onChange={e => changeValueField('number', e.target.value)}
            />

            <TextField
              validations={[SyncValidations.notEmpty]}
              label={dictionary.NEIGHBORHOOD}
              value={store.neighborhood}
              onChange={e => changeValueField('neighborhood', e.target.value)}
            />
          </div>

          <div className="form-two-columns">
            <TextField
              validations={[SyncValidations.notEmpty]}
              label={dictionary.CITY}
              value={store.city}
              onChange={e => changeValueField('city', e.target.value)}
            />

            <TextField
              validations={[SyncValidations.notEmpty]}
              label={dictionary.STATE}
              value={store.state}
              onChange={e => changeValueField('state', e.target.value)}
            />
          </div>

          {renderRetailSelect}

          <FormControlLabel
            style={{ margin: '9px 0 9px 0' }}
            control={checkboxStatus}
            label={dictionary.ACTIVE_STORE}
          />
        </DialogContent>

        <DialogActions>
          <CancelButton
            onClick={() => {
              setStore(getInitialStateValues());
              closeModalCallback();
            }}
          />

          <DefaultButton
            onClick={event => {
              submitStore(event);
            }}
            color="primary"
            style={{ width: 'auto' }}
            label={dictionary.SAVE}
          />
        </DialogActions>
      </form>
    </>
  );
};
