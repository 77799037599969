import React from 'react';
import { colors } from '../../../../Theme/colors';
import { MAX_PROMOTER_WARNING_BEFORE_SUSPENSION } from '../../../../core/constants/visits';
import classes from './SuspensionTracker.module.scss';

type DotProps = {
  color: string;
}

const Dot = ({ color }: DotProps) => (
  <div 
    className={classes.Shape}
    style={{backgroundColor: color}}
  />
);

type Props = {
  total: number;
}

const SuspensionTracker = ({ total }: Props) => {
  const isSuspended = total >= MAX_PROMOTER_WARNING_BEFORE_SUSPENSION;
  
  return (
    <div className={classes.Container}>
      {
        Array.from({
          length: MAX_PROMOTER_WARNING_BEFORE_SUSPENSION
        }).map((_, index) => {
          const currentColor = (total > index) ? colors.yellow_400 : colors.black_100;
          
          return (
            <Dot 
              key={`#suspension-dot-color-${index}`}
              color={ isSuspended ? colors.red_600 : currentColor} 
            />
          );
        })
      }
    </div>
  );
}

export default SuspensionTracker;
