import React, { useEffect } from 'react';
import { view } from 'react-easy-state';
import { Grid } from '@material-ui/core';
import { History } from 'history';
import PageContent from '../../../components/PageContent';
import * as dictionary from '../../../core/constants/dictionary';
import { ResumeCard, FormFilter, AccordionList } from './components';
import classes from './NegotiationsPanel.module.scss';

import {
  parsePanelMetrics,
  translatePromotionalSpotTypeToAPI,
} from '../common/utils';
import { FormFilters } from './components/FormFilter/utils';
import Loading from '../../../components/Loading';
import {
  useNegotiationPanelContext,
  withNegotiationPanelContext,
} from './context';
import { defaultMonthAndYear } from './utils';
import { utils } from '../../../core/utils';

type Props = {
  history: History;
};

const NegotiationPanel = ({ history }: Props) => {
  const {
    fetchData,
    fetchStores,
    isLoading,
    onClearFilterHandler,
    onSubmitFilterHandler,
    panelMetrics,
    periodsSpot,
    retailInUser,
    stores,
  } = useNegotiationPanelContext();

  const snackBar = utils.getSnackbar();
  useEffect(() => {
    const localStorageFilters = localStorage.getItem('pexFilters');
    if (localStorageFilters) {
      const pexFilters: FormFilters = JSON.parse(localStorageFilters);

      Promise.all([
        fetchData({
          ...pexFilters,
          year: pexFilters.year ? parseInt(pexFilters.year) : undefined,
          month: pexFilters.month ? parseInt(pexFilters.month) : undefined,
          spotTypes: pexFilters.type?.map(translatePromotionalSpotTypeToAPI),
          retailId: pexFilters?.retailId,
        }),
        fetchStores(pexFilters?.retailId),
      ]);
    } else {
      Promise.all([
        fetchData({
          retailId: retailInUser,
          ...defaultMonthAndYear(),
        }),
        fetchStores(retailInUser),
      ]);
    }
  }, []);

  if (isLoading) {
    return <Loading isOpen={isLoading} />;
  }

  return (
    <PageContent title={dictionary.NEGOTIATIONS_PANEL}>
      <Grid container wrap="nowrap" className={classes.resumeCardGrid}>
        {parsePanelMetrics(panelMetrics).map((metric, index) => (
          <ResumeCard {...metric} key={index} />
        ))}
      </Grid>

      <FormFilter
        isLoading={isLoading}
        onClear={onClearFilterHandler}
        onSubmit={onSubmitFilterHandler}
      />

      <AccordionList data={periodsSpot} stores={stores} />
      {snackBar}
    </PageContent>
  );
};

export default view(withNegotiationPanelContext(NegotiationPanel));
