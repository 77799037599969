import {
  CreatePromotionalCalendarPeriodsDto,
  UpdatePromotionalCalendarPeriodsDto,
  DeletePromotionalCalendarPeriodsDto,
  GetAllPromotionalCalendarPeriodsParams,
} from './types';
import {
  addPromotionalCalendarPeriodsAPI,
  getPromotionalCalendarPeriodsAPI,
  editPromotionalCalendarPeriodsAPI,
  deletePromotionalCalendarPeriodsAPI,
  getAllPromotionalCalendarPeriodsAPI,
} from './promotionalCalendarPeriodsAPI';

export const getPromotionalCalendarPeriods = (calendarId: string) => {
  return getPromotionalCalendarPeriodsAPI(calendarId);
};

export const getAllPromotionalCalendarPeriods = (
  params: GetAllPromotionalCalendarPeriodsParams
) => {
  return getAllPromotionalCalendarPeriodsAPI(params);
};

export const addPromotionalCalendarPeriods = (
  calendarId: string,
  periods: CreatePromotionalCalendarPeriodsDto
) => {
  return addPromotionalCalendarPeriodsAPI(calendarId, periods);
};

export const editPromotionalCalendarPeriods = (
  calendarId: string,
  periods: UpdatePromotionalCalendarPeriodsDto
) => {
  return editPromotionalCalendarPeriodsAPI(calendarId, periods);
};

export const deletePromotionalCalendarPeriods = (
  calendarId: string,
  periods: DeletePromotionalCalendarPeriodsDto
) => {
  return deletePromotionalCalendarPeriodsAPI(calendarId, periods);
};
