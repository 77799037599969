import { mainStore } from '../../core/store/mainStore';
import * as dictionary from '../../core/constants/dictionary';
import { postUser } from '../../core/services/users/usersService';
import { getInvitesById } from '../../core/services/invites/invitesService';
import { utils } from '../../core/utils';
import { trackUserCreated } from '../../core/mixpanel/mixPanelEvents';
import { exist } from '../../core/validation';

export const loadInitialData = params => {
  mainStore.promoters.queryParams = params.toString();
  if (params.has('invId')) {
    const invId = params.get('invId');
    return getInvitesById(invId);
  }
};

export const createSellerUser = async data => {
  const newData = { ...data, status: 1, profile: 3 };
  return postUser(newData, process.env.REACT_APP_ADD_USER_FIXED_TOKEN);
};

export const handleSubmit = async (
  event,
  {
    cpf,
    email,
    password,
    name,
    setFinished,
    setInvite,
    setEmail,
    setName,
    invite,
  },
) => {
  event.preventDefault();
  if (event.currentTarget.form.reportValidity()) {
    try {
      const values = {
        cpf,
        email,
        password,
        name,
      };
      const result = await createSellerUser(values);
      const origin = exist(invite) ? dictionary.INVITED : dictionary.REGISTERED;
      const trackerValues = { ...result.data, creationOrigin: origin };
      const inviteAgreement = exist(invite) ? invite.agreementId : null;
      trackUserCreated(trackerValues, inviteAgreement);
      setFinished(true);
      setInvite(null);
      setEmail(null);
      setName(null);
      utils.openSnackBar(dictionary.SUCCESS, dictionary.USER_CREATED);
    } catch (e) {
      if (exist(e.response)) {
        if (
          e.response.data.message ===
          'There is an existing user with the given cpf'
        ) {
          utils.openSnackBar(dictionary.ERROR, dictionary.HAS_USER_CFP);
        }
        if (
          e.response.data.message ===
          'There is an existing user with the given email'
        ) {
          utils.openSnackBar(dictionary.ERROR, dictionary.HAS_USER_EMAIL);
        }
      }
      utils.openSnackBar(dictionary.ERROR, dictionary.FORM_INVALID);
    }
  } else {
    utils.openSnackBar(dictionary.ERROR, dictionary.FORM_INVALID);
  }
};
