import {
  getInvitesByAgreementAPI,
  getInvitesAPI,
  removeInviteByIdAPI,
  resendInviteByIdAPI,
  sendInviteAPI,
} from './invitesAPI';

export const getInvitesByAgreementAndProfile = (agreementId, profile) => {
  return getInvitesByAgreementAPI(agreementId, profile);
};

export const getInvitesById = inviteId => {
  return getInvitesAPI(inviteId);
};

export const removeInviteById = id => {
  return removeInviteByIdAPI(id);
};

export const resendInviteById = id => {
  return resendInviteByIdAPI(id);
};

export const sendInvite = id => {
  return sendInviteAPI(id);
};
