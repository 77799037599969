export enum UserProfile {
  SUPER_ADMIN = 0,
  RETAIL_ADMIN = 1,
  BUYER = 2,
  SALES_REP = 3,
  PROMOTER = 4,
  PROMOTER_APPROVER = 5,
  STORE_MANAGER = 6,
  SECURITY = 7,
  RESTOCKER = 8,
}
