import React, { useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { MTableToolbar, MTablePagination} from 'material-table';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import Filterlist from '@material-ui/icons/FilterList';
import { colors } from '../../../Theme/colors';
import { profileCheck } from '../../../core/profiles';
import * as dictionary from '../../../core/constants/dictionary';
import FilterTable from '../../../components/Tables/FilterTable';
import PatchedPagination from '../../../components/PatchedPagination';
import { EmptyDataMessageRow } from '../../../components/Tables/DefaultTable';
import { getProfileFilter } from './utils';

type Props = {
  data: any[];
  key: string;
  count: number;
  pageIndex: number;
  rowsPerPage: number;
  searchTextValue: string;
  handleOpenModal: Function;
  toolbarInputFocus: boolean;
  onChangePageHandler?: Function;
  onChangeRetailFilter?: Function;
  onSearchChangeHandler?: Function;
  onChangeRowsPerPageHandler?: Function;
};

type RowData = object & {
  status: number;
};

const profileFilter = getProfileFilter();

export const UserTable = (props: Props) => {
  const {
    data,
    key,
    count,
    pageIndex,
    rowsPerPage,
    searchTextValue,
    handleOpenModal,
    toolbarInputFocus,
    onChangePageHandler = () => {},
    onSearchChangeHandler = () => {},
    onChangeRetailFilter = () => {},
    onChangeRowsPerPageHandler = () => {},
  } = props;

  const [retailFilter, setRetailFilter] = useState('');

  useEffect(() => {
    onChangeRetailFilter(retailFilter);
  }, [retailFilter]);
  
  return (
    <FilterTable
      data={data}
      key={key}
      columns={[
        {
          title: dictionary.RETAIL,
          field: 'retailName',
          hidden: !profileCheck.isSuperAdmin(),
          filterComponent: (props: HTMLInputElement) => {
            
            return (
              <TextField
                key="textinput"
                type={'search'}
                autoFocus={true}
                value={retailFilter}
                InputProps={{startAdornment: (
                  <InputAdornment position="start">
                    <Filterlist />
                  </InputAdornment>
                )}}
                className="MuiTextField-root"
                onChange={(event: any) => setRetailFilter(event?.target.value)}
              />
            );
          },
        },
        { title: dictionary.CPF, field: 'cpf' },
        { title: dictionary.NAME, field: 'name' },
        {
          title: dictionary.PROFILE,
          field: 'profile',
          lookup: profileFilter,
        },
        { title: dictionary.EMAIL, field: 'email' },
        {
          title: dictionary.STATUS,
          field: 'status',
          cellStyle: (rowData: string) =>
            rowData === 'Inativo'
              ? { color: colors.red_600 }
              : { color: colors.green_600 },
          lookup: {
            0: dictionary.INACTIVE,
            1: dictionary.ACTIVE,
          },
        },
      ]}
      rowsPerPage={rowsPerPage}
      actions={[
        {
          icon: () => <Edit style={{ width: '16px' }} />,
          tooltip: dictionary.EDIT_USER,
          onClick: (event: any, rowData: RowData) => {
            handleOpenModal(dictionary.EDIT_USER, rowData);
          },
        },
        (rowData: RowData) => ({
          icon: () => (
            <Delete
              style={{ width: '16px' }}
              color={rowData.status !== 0 ? 'inherit' : 'disabled'}
            />
          ),
          tooltip: dictionary.REMOVE_USER,
          onClick: (event: any, rowData: RowData) => {
            if (rowData.status !== 0) {
              handleOpenModal(dictionary.REMOVE_USER, rowData);
            }
            return null;
          },
        }),
      ]}
      localization={{
        body: {
          emptyDataSourceMessage: (
            <EmptyDataMessageRow message={dictionary.EMPTY_USER_TABLE} />
          ),
        },
      }}
      components={{
        Pagination: (props: typeof MTablePagination) => {
          return (
            <PatchedPagination
              {...props}
              count={count}
              page={pageIndex}
              rowsPerPage={rowsPerPage}
              onChangePage={(_: any, page: number) => onChangePageHandler(page)}
              onChangeRowsPerPage={(
                event: React.ChangeEvent<HTMLInputElement>
              ) => onChangeRowsPerPageHandler(parseInt(event.target.value))}
            />
          );
        },
        Toolbar: (props: typeof MTableToolbar) => {
          if (onSearchChangeHandler) {
            return (
              <MTableToolbar
                {...props}
                searchAutoFocus={toolbarInputFocus}
                searchText={searchTextValue}
                onSearchChanged={(value: string) =>
                  onSearchChangeHandler(value)
                }
              />
            );
          } else return <MTableToolbar {...props} />;
        },
      }}
    />
  );
};
