import React from 'react';
import classes from './ProgressBar.module.scss';

type Props = {
  total: number;
  color?: string;
}

const ProgressBar = ({ total, color }: Props) => {
  return (
    <div className={classes.container}>
      <div className={classes.fill} style={{ 
        width: `${total}%`,
        backgroundColor: color,
      }}></div>
    </div>
  );
}

export default ProgressBar;
