import { mainStore } from "../../core/store/mainStore";
import { profileCheck } from "../../core/profiles";
import { VisitCheckoutError } from "../../core/types/visit-checkout-error.enum";
import { MAX_PROMOTER_WARNING_BEFORE_SUSPENSION } from "../../core/constants/visits";
import * as dictionary from '../../core/constants/dictionary';

export type Visit = {
  agreementId: string;
  agreementOwnerId: string;
  agreementOwnerName: string;
  checkinAt: string;
  checkinStatus: number;
  checkoutAt: string;
  checkoutStatus: number;
  duration: string;
  id: string;
  onSchedule: number;
  promoterCpf: string;
  promoterName: string;
  promoterUserId: string;
  ratingScore: string | number | null;
  ratingReason: string | number | null;
  ratingDescription: string | undefined;
  retailId: string;
  retailName: string;
  status: number;
  storeCode: string;
  storeId: string;
  storeName: string;
  supplierNames: string;
  tableData: {
    id: number
  }
  timezone: string;
  totalWarnings: number;
  isLastPaidVisitWithWarning: boolean;
};

export type RowData = Visit;

export type MTableRowDataType = object;

export const shouldHighlightVisitElement = ({ isLastPaidVisitWithWarning, totalWarnings }: RowData): boolean => {
  return (
    isLastPaidVisitWithWarning && 
    totalWarnings >= MAX_PROMOTER_WARNING_BEFORE_SUSPENSION
  );
}

export const statusOptionsText = [
  dictionary.AT_STORE,
  dictionary.FINISHED_LABEL,
  dictionary.WITHOUT_CHECK_OUT,
];

type AutocompleteOption = {
  cpf: string;
  name: string;
}

export const appendLoggedStoreManager = (items: AutocompleteOption[], query: string) => {
  let loggedStoreManager = null;
  if (query && profileCheck.isStoreManager()) {
    const { cpf, name } = mainStore.loggedUser;
    const containsSearch = name.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0;
    const startsWithSearch = cpf.replace(/\.|-/g, '').indexOf(query.replace(/\.|-/g, '')) === 0;
    
    if (containsSearch || startsWithSearch) {
      loggedStoreManager = { cpf, name };
    }
  }

  return items.concat(loggedStoreManager ?? []);
}

export const errorMessageToFeedback = (errorMessage: string) => {
  switch (errorMessage) {
    case VisitCheckoutError.VISIT_ALREADY_EXISTS:
    case VisitCheckoutError.VISIT_ALREADY_CHECKOUT_OUT:
      return dictionary.VISIT_ALREADY_HAS_REGISTERED_CHECKOUT;
    case VisitCheckoutError.VISIT_DUE_DATE_HAS_EXPIRED:
      return dictionary.VISIT_DUE_DATE_EXPIRED;
    case VisitCheckoutError.VISIT_NOT_THE_NEWEST_ONE:
      return dictionary.ALREAY_EXIST_A_MORE_RECENT_VISIT;
    
    default: return dictionary.CHECKOUT_ERROR_PROBLEM;
  }
}
