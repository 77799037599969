import {
    getShelfMissionsRestockAPI,
    getProductGroupsByRetailAPI,
    getProductSubGroupsByGroupAPI,
    exportShelfBreaksXlsAPI,
    getProductSuspensionsAPI,
} from './shelfMissionsAPI';

export const getShelfBreaksReports = filter => {
    return getShelfMissionsRestockAPI(filter);
};

export const getProductGroupsByRetail = retailId => {
    return getProductGroupsByRetailAPI(retailId);
};

export const getProductSubGroupsByGroup = groupId => {
    return getProductSubGroupsByGroupAPI(groupId);
};

export const exportShelfBreaksXls = filter => {
    return exportShelfBreaksXlsAPI(filter);
};

export const getProductSuspensions = (params) => {
    return getProductSuspensionsAPI(params);
};