import { StoreDto } from '../../../../../Addressing/types';
import { PromotionalNegotiationDto } from '../../../../../common/types';
import { PromotionalSpotAvaliableAddresingInStore } from '../../../../types/promotional-negotiation-panel.entity';

export const totalAvaliableAddressings = (
  metrics: PromotionalSpotAvaliableAddresingInStore[] = []
) => {
  let total = 0;
  for (const metric of metrics) {
    total += metric.total;
  }
  return total;
};

export const totalExecutions = (
  negotiations: PromotionalNegotiationDto[] = []
) => {
  let count = 0;
  for (const negotiation of negotiations) {
    if (negotiation?.executions) {
      count += negotiation.executions.length;
    }
  }
  return count;
};

export const totalExecutionsInStore = ({
  negotiations = [],
  store,
}: {
  negotiations?: PromotionalNegotiationDto[];
  store: StoreDto;
}) => {
  let count = 0;
  for (const negotiation of negotiations) {
    if (negotiation?.executions) {
      for (const execution of negotiation?.executions) {
        if (execution.addressing.store.id === store.id) {
          count++;
        }
      }
    }
  }
  return count;
};

export const totalAvaliableAddressingsInStore = (
  store: StoreDto,
  inUse: number,
  metrics: PromotionalSpotAvaliableAddresingInStore[] = []
) => {
  const currentMetric =
    metrics.find((metric) => metric.storeId === store.id)?.total ?? 0;
  return currentMetric - inUse;
};
