import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { view } from 'react-easy-state';
import Loading from '../../components/Loading';
import WelcomePage from '../../components/WelcomePage';
import PageContent from '../../components/PageContent';
import DefaultButton from '../../components/Buttons/Default';
import ModalContainer from '../../components/Modal/Container';
import StickyFooter from '../../components/stickyFooter/stickyFooter';
import ModalSteps from '../../components/ModalSteps/ModalSteps';
import ModalSmallContainer from '../../components/Modal/SmallContainer';
import TitleContainer from '../../components/Modal/TitleContainer';
import { AgreementsTable } from './components/AgreementsTable/AgreementsTable';
import { NewAgreementFirstStep } from './modals/newAgreement/firstStep';
import { NewAgreementSecondStep } from './modals/newAgreement/secondStep';
import { EditAgreement } from './modals/editAgreement/editAgreement';
import { EditPromoters } from './modals/editPromoters/editPromoters';
import { EditFrequency } from './modals/editFrequency/editFrequency';
import { RemoveAgreement } from './modals/removeAgreement/removeAgreement';
import { mainStore } from '../../core/store/mainStore';
import { entityEmpty } from '../../core/validation';
import { profileCheck } from '../../core/profiles';
import { utils } from '../../core/utils';
import { useDebounce } from '../../core/hooks';
import * as dictionary from '../../core/constants/dictionary';
import { getWelcomeText, fetchRetails, fetchAgreements } from './functions';
import '../../Theme/main.css';

const AgreementsPage = () => {
  const [agreementsData, setAgreementsData] = useState([]);
  const [retailsData, setRetailsData] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [currentAgreement, setCurrentAgreement] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [nameOrCpfPart, setNameOrCpfPart] = useState("");
  const [toolbarInputFocus, setToolbarInputFocus] = useState(false);
  const [hasInitialContent, setHasInitialContent] = useState(false);

  useEffect(() => {
    if(agreementsData.length > 0 && !hasInitialContent) {
      setHasInitialContent(true);
    }
  }, [agreementsData, hasInitialContent]);

  const fetchPromotersAndAgreements = async () => {
    mainStore.requestLoading = true;
    const offset = page * rowsPerPage;
    const limit = rowsPerPage;
    const retailsResponse = await fetchRetails();
    const agreementsResponse = await fetchAgreements(offset, limit, nameOrCpfPart);
    
    setTotal(agreementsResponse.count)
    setAgreementsData(agreementsResponse.items)
    setRetailsData(retailsResponse)
    mainStore.requestLoading = false;
  }

  const closeModal = useCallback(() => {
    if (modalType === dictionary.CREATE || dictionary.APPROVE_AGREEMENT) {
      setCurrentStep(0);
    }
    fetchPromotersAndAgreements()
    setOpenModal(false);
    setModalType(null);
    setCurrentAgreement(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalType]);

  const nextStep = useCallback(() => {
    const nextStep = currentStep + 1;
    if (nextStep >= 2) {
      closeModal();
    } else {
      setCurrentStep(nextStep);
    }
  }, [closeModal, currentStep]);

  const handleOpenModal = useCallback((modalType, currentAgreement) => {
    setModalType(modalType);
    setOpenModal(true);
    setCurrentAgreement(currentAgreement);

    if (modalType === dictionary.CREATE || dictionary.APPROVE_AGREEMENT) {
      setCurrentStep(0);
    }
  }, []);

  const createModal = useMemo(() => {
    return (
      <ModalSteps
        title={
          currentStep === 0
            ? dictionary.ADD_AGREEMENT
            : dictionary.REGISTER_WEEK_VISITS
        }
        currentStep={currentStep}
        open
        steps={[
          {
            label: dictionary.AGREEMENT_DESCRIPTION,
            content: (
              <NewAgreementFirstStep
                nextStepCallback={nextStep}
                closeModalCallback={closeModal}
                setCurrentAgreementCallback={setCurrentAgreement}
                retailsData={retailsData}
              />
            ),
          },
          {
            label: dictionary.WEEK_VISITS,
            content: (
              <NewAgreementSecondStep
                currentAgreement={currentAgreement}
                nextStepCallback={nextStep}
                closeModalCallback={closeModal}
              />
            ),
          },
        ]}
      />
    );
  }, [currentStep, nextStep, closeModal, retailsData, currentAgreement]);

  const editModal = useMemo(() => {
    return (
      <ModalContainer closeModalCallback={closeModal} isOpen={openModal}>
        <TitleContainer title={dictionary.EDIT_AGREEMENT}>
          <EditAgreement
            modalType={modalType}
            currentAgreement={currentAgreement}
            retailsData={retailsData}
            closeModalCallback={closeModal}
          />
        </TitleContainer>
      </ModalContainer>
    );
  }, [closeModal, currentAgreement, modalType, openModal, retailsData]);

  const editPromoterModal = useMemo(() => {
    return (
      <ModalContainer closeModalCallback={closeModal} isOpen={openModal}>
        <TitleContainer title={dictionary.LINK_PROMOTERS}>
          <EditPromoters
            currentAgreement={currentAgreement}
            closeModalCallback={closeModal}
            handleOpenModal={handleOpenModal}
          />
        </TitleContainer>
      </ModalContainer>
    );
  }, [closeModal, currentAgreement, openModal, handleOpenModal]);

  const editFrequencyModal = useMemo(() => {
    return (
      <ModalContainer closeModalCallback={closeModal} isOpen={openModal}>
        <TitleContainer title={dictionary.EDIT_WEEK_VISITS}>
          <EditFrequency
            currentAgreement={currentAgreement}
            closeModalCallback={closeModal}
          />
        </TitleContainer>
      </ModalContainer>
    );
  }, [closeModal, currentAgreement, openModal]);

  const aproveModal = useMemo(() => {
    return (
      <ModalSteps
        title={
          currentStep === 0
            ? dictionary.APROVE_AGREEMENT
            : dictionary.LINK_PROMOTERS
        }
        currentStep={currentStep}
        open
        steps={[
          {
            label: dictionary.APROVE_AGREEMENT,
            content: (
              <EditAgreement
                approval
                modalType={modalType}
                nextStepCallback={nextStep}
                currentAgreement={currentAgreement}
                retailsData={retailsData}
                closeModalCallback={closeModal}
              />
            ),
          },
          {
            label: dictionary.LINK_PROMOTERS,
            content: (
              <EditPromoters
                nextStepCallback={nextStep}
                closeModalCallback={closeModal}
                currentAgreement={currentAgreement}
                handleOpenModal={handleOpenModal}
              />
            ),
          },
        ]}
      />
    );
  }, [
    currentStep,
    modalType,
    nextStep,
    currentAgreement,
    retailsData,
    closeModal,
    handleOpenModal,
  ]);

  const detailModal = useMemo(() => {
    return (
      <ModalContainer closeModalCallback={closeModal} isOpen={openModal}>
        <TitleContainer title={dictionary.VIEW_AGREEMENT}>
          <EditAgreement
            approval
            hideActionButton
            currentAgreement={currentAgreement}
            closeModalCallback={closeModal}
            modalType={modalType}
            retailsData={retailsData}
          />
        </TitleContainer>
      </ModalContainer>
    );
  }, [closeModal, currentAgreement, modalType, openModal, retailsData]);

  const removeModal = useMemo(() => {
    return (
      <ModalSmallContainer closeModalCallback={closeModal} isOpen={openModal}>
        <RemoveAgreement
          currentAgreement={currentAgreement}
          closeModalCallback={closeModal}
        />
      </ModalSmallContainer>
    );
  }, [closeModal, currentAgreement, openModal]);

  const renderModal = useMemo(() => {
    const modals = {
      [dictionary.CREATE]: createModal,
      [dictionary.EDIT]: editModal,
      [dictionary.EDIT_PROMOTER]: editPromoterModal,
      [dictionary.EDIT_FREQUENCY]: editFrequencyModal,
      [dictionary.APPROVE_AGREEMENT]: aproveModal,
      [dictionary.DETAILS]: detailModal,
      [dictionary.DELETE]: removeModal,
    };
    return modals[modalType];
  }, [
    aproveModal,
    createModal,
    detailModal,
    editFrequencyModal,
    editModal,
    editPromoterModal,
    modalType,
    removeModal,
  ]);

  const actionButton = useMemo(
    () => (
      <div className="button-create-container">
        <DefaultButton
          onClick={() => handleOpenModal(dictionary.CREATE, null)}
          color="primary"
          style={{ width: 'auto' }}
          label={dictionary.ADD_AGREEMENT}
        />
      </div>
    ),
    [handleOpenModal],
  );

  const snackBar = utils.getSnackbar();
  const linkSnackBar = utils.getLinkSnackbar();

  const renderActionButton = useMemo(
    () =>
      profileCheck.isSuperAdmin() ||
      profileCheck.isRetailAdmin() ||
      profileCheck.isBuyer(),
    [],
  );

  useEffect(() => {
    fetchPromotersAndAgreements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const triggerGetPromoterUsers = async (inputValue) => {
    try {
      const searchTerm = String(inputValue[0]);
      setNameOrCpfPart(searchTerm);
      mainStore.requestLoading = true;
      const offset = page * rowsPerPage;
      const limit = rowsPerPage;
      const agreementsResponse = await fetchAgreements(offset, limit, searchTerm);
      
      setPage(0);
      setTotal(agreementsResponse.count);
      setAgreementsData(agreementsResponse.items);
      mainStore.requestLoading = false;
    }
    catch(err) {
      mainStore.requestLoading = false;
    }
  }

  const debouncedOnSearch = useDebounce(triggerGetPromoterUsers, 500);

  const getPageContent = useCallback(() => {
    if (agreementsData.length === 0 && !hasInitialContent) {
      let handleButton = () => handleOpenModal(dictionary.CREATE, null);
      let textButton = dictionary.ADD_AGREEMENT;
      if (profileCheck.isSalesRep()) {
        handleButton = null;
        textButton = null;
      }

      return (
        <WelcomePage
          textButton={textButton}
          handleButton={handleButton}
        >
          { getWelcomeText() }
        </WelcomePage>
      );
    }
    return (
      <div className="reverse-table">
        <AgreementsTable
          data={agreementsData}
          retailsData={retailsData}
          count={total}
          pageIndex={page}
          rowsPerPage={rowsPerPage}
          handleOpenModal={handleOpenModal}
          searchTextValue={nameOrCpfPart}
          toolbarInputFocus={toolbarInputFocus}
          onChangePageHandler={page => setPage(page)}
          onChangeRowsPerPageHandler={(value) => {
            setPage(0);
            setRowsPerPage(value);
          }}
          onSearchChangeHandler={value => debouncedOnSearch(value)}
        />
      </div>
    );
  }, [
    page, 
    total, 
    rowsPerPage, 
    retailsData, 
    nameOrCpfPart, 
    agreementsData, 
    toolbarInputFocus,
    hasInitialContent,
    handleOpenModal, 
    debouncedOnSearch, 
  ]);

  const content = useMemo(() => {
    return entityEmpty(agreementsData) ? '' : getPageContent();
  }, [getPageContent, agreementsData]);

  const renderStickyFooter = useMemo(() => {
    return agreementsData && agreementsData.length > 0 && renderActionButton;
  }, [renderActionButton, agreementsData]);

  useEffect(() => {
    const shouldFocus = nameOrCpfPart.length > 0;
    setToolbarInputFocus(shouldFocus);
  }, [nameOrCpfPart]);

  return (
    <React.Fragment>
      <PageContent title={dictionary.PROMOTERS_AGREEMENTS}>
        {content}
      </PageContent>

      {openModal && renderModal}
      {snackBar}
      {linkSnackBar}

      {renderStickyFooter && <StickyFooter actionButton={actionButton} />}

      <Loading isOpen={mainStore.requestLoading} />
    </React.Fragment>
  );
};

export default view(AgreementsPage);
