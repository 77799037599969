import React from 'react';
import { DialogActions, Typography } from '@material-ui/core';
import ModalTitle from '../../../../../components/Modal/Title';
import ModalContainer from '../../../../../containers/Modal/Container';
import {
  CancelButton,
  DefaultButton,
} from '../../../../../components/Buttons';
import {
  CONFIRM,
  DELETE_SUPPLIER, 
  SUPPLIER_WILL_BE_INACTIVATED_ON_ALL_AGREEMENTS,
} from '../../../../../core/constants/dictionary';
import classes from '../../../Supplier.module.scss';

type Props = {
  isOpen: boolean;
  onClose: Function;
  onSubmit: Function;
};

const RemoveSupplierModal = ({ isOpen, onClose, onSubmit }: Props) => {
  return (
    <ModalContainer isOpen={isOpen} closeModalCallback={onClose}>
      <div className={classes.modal}>
        <ModalTitle 
          title={DELETE_SUPPLIER} 
          className={classes.modalTitle} 
          style={{marginBottom: '40px'}}
        />
        <Typography className={classes.message}>
          {SUPPLIER_WILL_BE_INACTIVATED_ON_ALL_AGREEMENTS}
        </Typography>

        <DialogActions className={classes.footer}>
          <CancelButton onClick={onClose} />
          <DefaultButton
            label={CONFIRM}
            onClick={onSubmit}
            style={{width:'auto'}}
          />
        </DialogActions>
      </div>
    </ModalContainer>
  );
};

export default RemoveSupplierModal;
