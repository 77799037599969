import { Typography } from '@material-ui/core';
import React from 'react';
import classes from './ImageCard.module.scss';
type Props = {
  title?: string;
  label?: string;
  image: string;
};
const ImageCard = ({ label, image, title }: Props) => {
  return (
    <div className={classes.imageCard}>
      {title && <Typography className={classes.subtitle}>{title}:</Typography>}
      <a href={image} target="_blank" rel="opener">
        <div
          className={classes.photo}
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'contain',
            backgroundPosition: 'center',
          }}
        >
          {label && <Typography className={classes.hint}>{label}</Typography>}
        </div>
      </a>
    </div>
  );
};

export default ImageCard;
