import { createTheme } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import { colors } from './colors';
import { fonts } from './fonts';

const theme = createTheme(
  {
    overrides: {
      MuiTouchRipple: {
        root: {
          display: 'none',
        },
      },
      MuiCheckbox: {
        root: {
          color: colors.blue_500,
          '&$checked': {
            color: colors.blue_500,
          },
        },
      },
      MuiButton: {
        text: {
          color: colors.black_500,
          fontWeight: 600,
        },
        textSizeLarge: {
          fontSize: 16,
        },
      },
      MuiIconButton: {
        root: {
          '&:hover': { backgroundColor: 'transparent' },
          color: colors.black_500,
        },
      },
      MuiInputLabel: {
        root: {
          color: colors.black_500,
        },
      },
      MuiInput: {
        input: {
          color: colors.black_900,
        },
        underline: {
          borderColor: colors.black_300,
        },
      },
      MuiOutlinedInput: {
        root: {
          '&:hover': {
            '& .MuiOutlinedInput-input:focus.MuiOutlinedInput-notchedOutline': {
              borderColor: colors.black_900,
            },
          },
        },
        notchedOutline: {
          borderColor: colors.black_300,
        },
      },
      MuiTablePagination: {
        root: {
          color: colors.black_400,
        },
        spacer: {
          flex: 'none',
        },
        toolbar: {
          paddingLeft: 40,
        },
      },
      MuiTableCell: {
        root: {
          '&:first-child': {
            paddingLeft: 40,
          },
          color: colors.black_800,
        },
        head: {
          color: colors.black_400,
          fontWeight: 700,
        },
      },
      MuiAlert: {
        standardInfo: {
          backgroundColor: colors.blue_100,
          color: colors.blue_600,
          '& .MuiAlert-icon': {
            color: colors.blue_600,
          },
        },
      },
    },
    sizes: {
      defaultInputsMarginSize: {
        marginTop: 3,
        marginBottom: 20,
        width: '100%',
        height: 'fit-content',
        minHeight: '40px',
      },
      multiTextField: {
        root: {
          '& .MuiInputBase-root': {
            paddingTop: 0,
            paddingBottom: 0,
            height: 'auto',
            minHeight: '40px',
            borderRadius: '4px',
          },
          '& .MuiInputBase-input': { paddingBottom: 17, paddingTop: 17 },
          '& .MuiFormLabel-root': { fontSize: '15px' },
          '& .MuiInputLabel-formControl': {},
          '& .MuiInputLabel-outlined': { top: '-7.5px' },
          '& .MuiInputLabel-outlined.MuiInputLabel-shrink': { 
            top: 'unset',
            padding: '0 4px 0 0', 
            backgroundColor: 'white', 
          },
          '& label.Mui-focused': { color: colors.blue_500 },
          '& .MuiInput-underline:before': {
            borderBottomColor: colors.black_900,
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: colors.green_600,
          },
          '& .MuiIconButton-root': { padding: '8px' },
          '& .MuiAutocomplete-endAdornment': { top: 0 },
        },
      },
      defaultTextField: {
        root: {
          '& .MuiInputBase-root': {
            borderRadius: '4px',
            paddingBottom: 0,
            paddingTop: 0,
            height: '40px',
          },
          '& .MuiInputBase-input': { paddingBottom: 17, paddingTop: 17 },
          '& .MuiFormLabel-root': { fontSize: '15px', color: colors.black_500 },
          '& .MuiInputLabel-outlined': { top: '-7.5px' },
          '& .MuiInputLabel-outlined.MuiInputLabel-shrink': { 
            top: 'unset', 
            padding: '0 4px 0 0', 
            backgroundColor: 'white', 
          },
          '& .MuiInputLabel-outlined.MuiInputLabel-shrink.Mui-error': { 
            color: colors.red_600
          },
          '& label.Mui-focused': { color: colors.blue_500 },
          '& .MuiInput-underline:before': {
            borderBottomColor: colors.black_900,
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: colors.green_600,
          },
          '& .MuiIconButton-root': { padding: '8px' },
          '& .MuiAutocomplete-endAdornment': { top: 0 },
        },
      },
      multiInputsMarginSize: { marginTop: 3, marginBottom: 20, width: '100%' },
    },
    palette: {
      primary: { main: colors.blue_500 },
      secondary: { main: colors.green_600 },
      error: { main: colors.red_600 },
    },
    typography: {
      fontFamily: "'Open Sans', 'sans-serif'",
      fontSize: 14,
      color: colors.black,
      h1: { ...fonts.h1 },
      h2: { ...fonts.h2 },
      body1: { ...fonts.body1 },
      body2: { ...fonts.body2 },
      button: { ...fonts.button },
      subtitle1: { ...fonts.subtitle1 },
      overline: { ...fonts.overline },
    },
  },
  ptBR
);

export default theme;
