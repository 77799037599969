import React, { ChangeEvent, useMemo, useRef } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import PatchedPagination from '../../../components/PatchedPagination';
import { EmptyDataMessageRow } from '../../../components/Tables/DefaultTable';
import { profileCheck } from '../../../core/profiles';
import { normalizeStockValue } from '../../../core/shared/stock';
import { ExpirationReportDTO } from '../../../core/services/expiration/types';
import { formatWithoutTimezone } from '../../../core/constants/dates';
import { 
  CustomMaterialTable, 
  MaterialTableColumnProps, 
} from '../../../core/types/custom-material-table.type';
import * as dictionary from '../../../core/constants/dictionary';
import { RiskLevelLabel, smallCellStyle, translateAPIToRiskLevelLabel } from '../utils';
import classes from '../ExpirationPanel.module.scss';

type Props = CustomMaterialTable<ExpirationReportDTO>;

const ExpirationTable = ({ 
  data,
  page,
  total,
  rowsPerPage,
  searchTextValue,
  toolbarInputFocus,
  onChangePageHandler = () => {},
  onChangeSearchHandler = () => {},
  onChangeRowsPerPageHandler = () => {},
 }: Props) => {
  const tableRef = useRef(null);

  const columns: MaterialTableColumnProps[] = ([
    {
      field: 'expirationAt',
      title: dictionary.EXPIRATION_DATE,
      sorting: false,
      cellStyle: smallCellStyle,
      headerStyle: smallCellStyle,
      render: (data: any) => formatWithoutTimezone(data.expirationAt),
    },
    {
      field: 'product',
      title: dictionary.PRODUCT_NAME,
      sorting: false,
      cellStyle: { width: '25%', minWidth: '25%' },
      render: (data: any) => `(${data.product.internalCode}) ${data.product.name}`,
    },
    {
      field: 'expirationRisk',
      title: dictionary.CURRENT_RISK,
      sorting: false,
      cellStyle: smallCellStyle,
      headerStyle: smallCellStyle,
      render: (({ expired, expirationRisk }: any) => {
        if (expired) {
          return RiskLevelLabel.EXPIRED;
        }
        return translateAPIToRiskLevelLabel(expirationRisk.toUpperCase());
      }),
    },
    {
      field: 'quantity',
      title: dictionary.QUANTITY_TO_EXPIRE,
      sorting: false,
      render: ((data: any) => String(data.quantity).padStart(2, '0')),
      headerStyle: smallCellStyle,
      cellStyle: { 
        ...smallCellStyle,
        textAlign: 'center',
      },
    },
    {
      field: 'unitsInStock',
      title: dictionary.QUANTITY_ON_STOCK,
      sorting: false,
      render: (data: any) => normalizeStockValue(data.unitsInStock).padStart(2, '0'),
      headerStyle: smallCellStyle,
      cellStyle: { 
        ...smallCellStyle,
        textAlign: 'center',
      },
    },
    {
      field: 'productGroup',
      title: dictionary.SECTOR,
      sorting: false,
      render: (data: any) => data.productGroup.name,
      cellStyle: { width: '20%', minWidth: '20%' },
    },
    {
      field: 'lastCheckAt',
      title: dictionary.LAST_CHECK,
      sorting: false,
      render: (data: any) => formatWithoutTimezone(data.lastCheckAt),
      headerStyle: smallCellStyle,
      cellStyle: smallCellStyle,
    },
  ]);

  const formattedColumns: MaterialTableColumnProps[] = useMemo(() => {
    if (!profileCheck.isStoreManager()) {
      const storeColumn: MaterialTableColumnProps  = {
        type: 'string',
        field: 'store',
        title: dictionary.STORE,
        render: ((data: any) => `(${data.store.code}) ${data.store.name}`)
      }
      return [storeColumn, ...columns];
    }

    return columns;
  }, [columns]);

  return (
    <div className={classes.tableContainer}>
      <MaterialTable
        title={''}
        data={data}
        page={page}
        key={data.length}
        tableRef={tableRef}
        columns={formattedColumns}
        options={{
          actionsColumnIndex: -1,
          toolbarButtonAlignment: 'left',
          pageSize: rowsPerPage,
          pageSizeOptions: [5, 10, 20],
        }}
        localization={{
          header: { actions: '' },
          body: {
            emptyDataSourceMessage:
              <EmptyDataMessageRow
                message={dictionary.EMPTY_EXPIRATION_REPORTS_TABLE}
              />,
          },
          pagination: {
            labelRowsSelect: dictionary.LINES,
            labelDisplayedRows: '{from}-{to} de {count}',
          },
          toolbar: {
            searchPlaceholder: dictionary.SEARCH
          }
        }}
        components={{
          Pagination: props => (
            <PatchedPagination
              {...props}
              page={page}
              count={total}
              rowsPerPage={rowsPerPage}
              onChangePage={(_evt: any, page: number) => onChangePageHandler(page)}
              onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
                onChangeRowsPerPageHandler(parseInt(event.target.value));
                props.onChangeRowsPerPage(event);
              }}
            />
          ),
          Toolbar: (props) => {
            if (onChangeSearchHandler) {
              return (
                <MTableToolbar
                  {...props}
                  searchText={searchTextValue}
                  searchAutoFocus={toolbarInputFocus}
                  onSearchChanged={(value: string) => onChangeSearchHandler(value)}
                />
              );
            }

            return <MTableToolbar {...props} />;
          },
        }}
      />
    </div>
  );
};

export default ExpirationTable;
