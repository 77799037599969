import { format } from 'date-fns';
import { ProductDto } from '../../core/services/products/types';
import { profileCheck } from '../../core/profiles';
import {
  GetExpirationReportParams,
  RiskLevel,
} from '../../core/services/expiration/types';
import { EXPIRATION_REPORT } from '../../core/constants/dictionary';

export type ExpirationProductDto = {
  store: {
    code: string;
    name: string;
  };
  expirationAt: string;
  product: {
    sku: string;
    name: string;
  };
  risk: RiskLevel;
  quantity: number;
  unitsInStock: number;
  productGroup: {
    name: string;
  };
  lastCheckAt: string;
};

export enum RiskLevelLabel {
  HIGH = 'Alto',
  LOW = 'Baixo',
  EXPIRED = 'Vencido',
}

export type ProductGroupDto = {
  id: string;
  retailId: string;
  name: string;
};

export type ExpirationFilter = {
  product?: ProductDto[];
  storeId?: string;
  endDate: Date | null;
  startDate: Date | null;
  productGroup?: ProductGroupDto[];
  riskLevel?: RiskLevelLabel[];
  retailId?: string;
};

export const defaultFilter: ExpirationFilter = {
  product: [],
  storeId: '',
  riskLevel: [],
  productGroup: [],
  endDate: new Date(),
  startDate: new Date(),
};

export const riskLevelOptions = [
  RiskLevelLabel.HIGH,
  RiskLevelLabel.LOW,
  RiskLevelLabel.EXPIRED,
];

export const translateRiskLevelLabelToAPI = (searchValue: RiskLevelLabel) => {
  const key = Object.keys(RiskLevelLabel)[
    Object.values(RiskLevelLabel).findIndex(
      (currentObjValue) => currentObjValue === searchValue
    )
  ] as keyof typeof RiskLevel;

  return RiskLevel[key];
};

export const translateAPIToRiskLevelLabel = (type: keyof typeof RiskLevel) => {
  return RiskLevelLabel[type];
};

export const mapFilterToQueryParams = (
  filter: ExpirationFilter
): GetExpirationReportParams => {
  return {
    productId: filter.product?.map((p) => p.id),
    storeId: filter.storeId,
    productGroupId: filter.productGroup?.map(group => group.id),
    expirationRisk: filter.riskLevel?.map(risk => translateRiskLevelLabelToAPI(risk)),
    endDate: format(filter?.endDate ?? new Date(), 'yyyy-MM-dd'),
    startDate: format(filter?.startDate ?? new Date(), 'yyyy-MM-dd'),
  }
}

export const initialFilter: ExpirationFilter = {
  startDate: new Date(),
  endDate: new Date(),
}

export const smallCellStyle = {
  width: '10%',
  minWidth: '10%',
  maxWidth: '10%',
};

export const mdGridSize = profileCheck.isStoreManager() ? 3 : true;

export const formatFilename = (startDate: Date, endDate: Date) => {
  const dateInterval = `${format(startDate, 'dd.MM.yyyy')}-${format(
    endDate,
    'dd.MM.yyyy'
  )}`;
  return `${EXPIRATION_REPORT} (${dateInterval}).xlsx`;
};

type FilterByGroupAndTermParams = {
  products: ProductDto[] | undefined; 
  searchTerm: string;
  selectedProducts: ProductDto[];
  selectedProductGroups: ProductGroupDto[];
}

export const filterByGroupsAndTerm = ({ 
  products, 
  searchTerm,
  selectedProducts, 
  selectedProductGroups,
}: FilterByGroupAndTermParams): ProductDto[] => {
  if (!products) {
    return [];
  }

  const productGroupIds = new Set(
    selectedProductGroups.map((spg: ProductGroupDto) => (spg.id))
  );
  return products.filter((p) => {
    if (
      productGroupIds.size === 0 ||
      productGroupIds.has(p.subgroup.group.id)
    ) {
      const productFields = `${p.name} ${p.internalCode}`;
      
      if (productFields.search(new RegExp(searchTerm, "i")) >= 0) {
        if (! selectedProducts.find(sp => sp.id === p.id)) {
          return true;
        }
      }
    }
    
    return false;
  });
}
