export function findValueInArray(array, field, value) {
  const indexOfValue = array.findIndex(i => i[field] === value);
  return array[indexOfValue];
}

export function valueAlreadyExistInArray(array, value, field) {
  const indexOfValue = array.findIndex(i => i[field] === value);
  return indexOfValue !== -1;
}

export function exist(value) {
  const valueToTest = typeof value === 'string' ? value.trim() : value;
  return (
    valueToTest !== undefined && valueToTest !== null && valueToTest !== ''
  );
}

export function entityEmpty(data) {
  return data === null || data === undefined;
}
