import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@involves/violin';
import { StyledInputDropdownContainer, StyledInputDropdownCard } from './style';

const InputDropdown = ({
  show,
  onClose,
  onBlur: onBlurCallback,
  onFocus: onFocusCallback,
  onMouseDown: onMouseDownCallback,
  children,
  ...props
}) => {
  const [hasFocus, setHasFocus] = useState(false);
  const inputRef = useRef();
  const dropdownCardRef = useRef();

  useEffect(() => {
    const closeOnClickOut = event => {
      if (inputRef.current.contains(event.target)) {
        return;
      }

      if (dropdownCardRef.current.contains(event.target)) {
        return;
      }

      if (hasFocus || show) {
        onClose();
      }
    };

    document.addEventListener('click', closeOnClickOut, true);
    return () => {
      document.removeEventListener('click', closeOnClickOut, true);
    };
  }, [hasFocus, onClose, show]);

  const focusInputAndSelect = () => {
    inputRef.current.focus();
    setHasFocus(true);
  };

  const onMouseDown = event => {
    focusInputAndSelect();
    if (onMouseDownCallback) {
      onMouseDownCallback(event);
    }
  };

  const onFocus = event => {
    focusInputAndSelect();
    if (onFocusCallback) {
      onFocusCallback(event);
    }
  };

  const onBlur = event => {
    setHasFocus(false);
    if (onBlurCallback) {
      onBlurCallback(event);
    }
  };

  return (
    <StyledInputDropdownContainer>
      <TextField
        {...props}
        deepRef={inputRef}
        onMouseDown={onMouseDown}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <StyledInputDropdownCard show={show} ref={dropdownCardRef}>
        {children}
      </StyledInputDropdownCard>
    </StyledInputDropdownContainer>
  );
};

InputDropdown.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

InputDropdown.defaultProps = {
  show: false,
  onClose: () => {},
};

export { InputDropdown };
