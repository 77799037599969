const numeric = /[0-9]/;
const dot = '.';
const dash = '-';
const bar = '/';

export const cpfMask = () => [
  numeric,
  numeric,
  numeric,
  dot,
  numeric,
  numeric,
  numeric,
  dot,
  numeric,
  numeric,
  numeric,
  dash,
  numeric,
  numeric,
];

export const cnpjMask = () => [
  numeric,
  numeric,
  dot,
  numeric,
  numeric,
  numeric,
  dot,
  numeric,
  numeric,
  numeric,
  bar,
  numeric,
  numeric,
  numeric,
  numeric,
  dash,
  numeric,
  numeric,
];

export const cepMask = () => [
  numeric,
  numeric,
  numeric,
  numeric,
  numeric,
  dash,
  numeric,
  numeric,
  numeric,
];
