import { mainStore, resetStoreState } from '../../core/store/mainStore';
import {
  isLoggedUserAuthorizedInRoute,
  setLoggedUser,
  setToken,
} from '../../core/authorization';
import * as routes from '../../core/constants/routes';
import sidebarMenuitems from './routes';
const mixpanel = require('mixpanel-browser');

export const getAllRoutes = () => {
  let routesMap = [],
    i = 0;

  for (const menuItem of sidebarMenuitems) {
    if (menuItem.submenu) {
      for (const submenu of menuItem.submenu) {
        if (submenu.route) {
          routesMap.push({
            route: submenu.route,
            index: i,
          });
        }
      }
    } else {
      routesMap.push({
        route: menuItem.route,
        index: i,
      });
    }
    i++;
  }

  return routesMap;
};

export const getMenuItems = (history, selectedRoute) => {
  let menus = [...sidebarMenuitems];

  if (isSubmenuSelected(menus, selectedRoute)) {
    return menus[selectedRoute].submenu.map((item, index) => {
      const isSelectedRoute = history.location.pathname.match(item.route);
      if (isSelectedRoute) {
        mainStore.menu.selectedRoute = selectedRoute;
      }

      const { name, icon, submenu, route, goBack, groupRoute, target } = item;
      const pathname = route ? route : groupRoute;
      const isAuthorized = isLoggedUserAuthorizedInRoute({ pathname });

      return {
        name,
        icon,
        index,
        route,
        submenu,
        goBack,
        isAuthorized,
        target,
      };
    });
  }

  return menus.map((item, index) => {
    const { name, icon, submenu, route, goBack, groupRoute } = item;
    const pathname = route ? route : groupRoute;
    const isAuthorized = isLoggedUserAuthorizedInRoute({ pathname });

    return {
      name,
      icon,
      index,
      submenu,
      route,
      goBack,
      isAuthorized,
    };
  });
};

const isSubmenuSelected = (sidebarMenu, selectedRoute) => {
  return (
    !!selectedRoute &&
    selectedRoute >= 0 &&
    sidebarMenu[selectedRoute] &&
    sidebarMenu[selectedRoute].submenu
  );
};

export const signOutUser = (history) => {
  mixpanel.reset();
  setToken(null);
  setLoggedUser(null);
  resetStoreState();
  localStorage.removeItem('pexFilters');

  if (history) {
    history.push(routes.LOGIN);
  }
};
