import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { Typography } from '@material-ui/core';
import { view } from 'react-easy-state';
import * as R from 'ramda';
import Loading from '../../../components/Loading';
import WelcomePage from '../../../components/WelcomePage';
import StickyFooter from '../../../components/stickyFooter/stickyFooter';
import DefaultButton from '../../../components/Buttons/Default';
import ModalContainer from '../../../components/Modal/Container';
import { CreateContentModal } from './createPromoter';
import { DeleteContentModal } from './deletePromoter';
import { PromotersTable } from './promotersTable';
import { getAllPromoterUsers } from '../../../core/services/users/usersService';
import { mainStore } from '../../../core/store/mainStore';
import { useDebounce } from '../../../core/hooks';
import { serializeCpfAndNameSearch, utils } from '../../../core/utils';
import * as dictionary from '../../../core/constants/dictionary';
import '../styles.scss';
import { DEBOUNCE_DELAY_ON_FETCH_USER } from '../../../core/constants/user';

const RegisterPromoters = ({ history }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [currentPromoter, setCurrentPromoter] = useState(null);
  const [promoterDelete, setPromoterDelete] = useState(null);
  const [promotersData, setPromotersData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [nameOrCpfPart, setNameOrCpfPart] = useState("");
  const [hasInitialContent, setHasInitialContent] = useState(false);
  const [toolbarInputFocus, setToolbarInputFocus] = useState(false);
  
  const fetchAllPromoters = async () => {
    mainStore.requestLoading = true;
    const offset = page * rowsPerPage;
    const limit = rowsPerPage;
    const response = await getAllPromoterUsers(
      mainStore.loggedUser.retailId, 
      offset, 
      limit, 
      nameOrCpfPart,
    );
    setTotal(response.count);
    setPromotersData(response.items)
    mainStore.requestLoading = false;
  }

  useEffect(() => {
    fetchAllPromoters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (nameOrCpfPart === '' || nameOrCpfPart.length >= 3) {
      fetchAllPromoters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameOrCpfPart]);

  const handleCloseModal = async () => {
    setOpenModal(false);
    fetchAllPromoters();
  };

  const handleCloseModalDelete = async () => {
    setOpenModalDelete(false);
    fetchAllPromoters();
  };

  const handleOpenModal = useCallback(currentPromoter => {
    setOpenModal(true);
    setCurrentPromoter(currentPromoter);
  }, []);

  const handleOpenDeleteModal = useCallback(promoter => {
    setOpenModalDelete(true);
    setPromoterDelete(promoter);
  }, []);

  const handleDismissModal = () => setOpenModal(false);
  const handleDismissModalDelete = () => setOpenModalDelete(false);

  const snackBar = utils.getSnackbar();

  const stickFooter = useMemo(
    () => (
      <div className="button-create-container">
        <DefaultButton
          label={dictionary.REGISTER_NEW_PROMOTER}
          color="primary"
          onClick={() => handleOpenModal()}
          style={{ width: 'auto' }}
        />
      </div>
    ),
    [handleOpenModal],
  );

  useEffect(() => {
    if(promotersData.length > 0 && !hasInitialContent) {
      setHasInitialContent(true);
    }
  }, [promotersData, hasInitialContent]);

  useEffect(() => {
    const shouldFocus = nameOrCpfPart.length > 0;
    setToolbarInputFocus(shouldFocus);
  }, [nameOrCpfPart])

  const triggerGetPromoterUsers = async (inputValue) => {
    const queryString = String(inputValue[0]);
    setPage(0);
    setNameOrCpfPart(queryString);
  }

  const debouncedOnSearch = useDebounce(triggerGetPromoterUsers, DEBOUNCE_DELAY_ON_FETCH_USER);
  
  return (
    <div className="pages-content">
      <div className="page-container">
        <div className="page-title">
          <Typography variant="h1">{dictionary.REGISTER_PROMOTER}</Typography>
        </div>

        {
          R.isEmpty(promotersData) && !mainStore.requestLoading && !hasInitialContent && (
          <WelcomePage
            handleButton={handleOpenModal}
            textButton={dictionary.ADD_PROMOTER}
          >
            <Typography variant="body1">
              {dictionary.NO_PROMOTERS_WAS_CREATED_YET}
            </Typography>
          </WelcomePage>
        )}

        {
          hasInitialContent && (
          <>
            <div className="reverse-table">
              <PromotersTable
                key={`#${promotersData.length}${nameOrCpfPart}`}
                data={promotersData}
                count={total}
                pageIndex={page}
                rowsPerPage={rowsPerPage}
                searchTextValue={serializeCpfAndNameSearch(nameOrCpfPart)}
                toolbarInputFocus={toolbarInputFocus}
                handleOpenModal={handleOpenModal}
                handleOpenDeleteModal={handleOpenDeleteModal}
                onChangePageHandler={page => setPage(page)}
                onChangeRowsPerPageHandler={(value) => {
                  setPage(0);
                  setRowsPerPage(value);
                }}
                onSearchChangeHandler={value => debouncedOnSearch(value)}
              />
            </div>
            <StickyFooter actionButton={stickFooter} />
          </>
        )}
      </div>

      <ModalContainer isOpen={openModal}>
        <CreateContentModal
          promoterEdit={currentPromoter}
          onClose={handleCloseModal}
          onDismiss={handleDismissModal}
          history={history}
        />
      </ModalContainer>

      <ModalContainer isOpen={openModalDelete}>
        <DeleteContentModal
          promoterDelete={promoterDelete}
          onClose={handleCloseModalDelete}
          onDismiss={handleDismissModalDelete}
          history={history}
        />
      </ModalContainer>

      {snackBar}

      <Loading isOpen={mainStore.requestLoading} />
    </div>
  );
};

export default view(RegisterPromoters);
