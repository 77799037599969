import { AxiosResponse } from 'axios';
import API from '../api/config';
import * as routes from '../api/routes';
import { Pagination } from '../../types/pagination.type';
import {
  ProductEntity,
  ProductFamilyEntity,
} from '../../types/promotional-spot/product-family-entity.type';
import { GetProductFamiliesParams, GetProductParams } from './types';

export const getFamiliesProductsInRetailAPI = (
  params: GetProductFamiliesParams
): Promise<AxiosResponse<ProductFamilyEntity[]>> => {
  let finalParams: GetProductFamiliesParams = {};

  if ('retailId' in params) {
    finalParams = { ...params, retail: params.retailId };
    delete finalParams['retailId'];
  } else {
    finalParams = params;
  }

  return API.get(routes.API_FAMILIES, {
    params: finalParams,
  });
};

export const getProductsInRetailAPI = (
  params: GetProductParams
): Promise<AxiosResponse<Pagination<ProductEntity>>> => {
  return API.get(routes.API_FAMILIES_PRODUCT, {
    params,
  });
};
