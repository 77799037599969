import React from 'react';
import Autocomplete from '../../components/Autocomplete';
import { useDebounce } from '../../core/hooks';
import * as dictionary from '../../core/constants/dictionary';
import { styles } from './utils';

const SupplierFilter = ({
    value,
    options,
    disabled,
    loading,
    onChange,
    onSearch,
  }) => {
    const debouncedOnSearch = useDebounce(onSearch, 300);
    const getOptionLabelSuppliers = option => {
      if (Object.keys(option).length === 0) {
        return '';
      }
      const name = option.internalName ? option.internalName : option.name;
      const code = option.internalCode ? ` (${option.internalCode})` : '';
      return `${name}${code}`;
    };
  
    return (
      <Autocomplete
        required={false}
        label={dictionary.SUPPLIER}
        value={value}
        loading={loading}
        disabled={disabled}
        options={options}
        getOptionLabel={getOptionLabelSuppliers}
        onChange={onChange}
        onInputChange={(e, query) => debouncedOnSearch(query)}
        style={styles.autocompleteSupplierFilter}
      />
    );
};

export default SupplierFilter;