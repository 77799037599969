export type AddCalendarPeriodForm = {
  negotiatedAt?: Date;
  startAt?: Date;
  endAt?: Date;
  duration: number;
  status: number;
  id?: string;
  tableData?: {
    id: number;
  };
};

export const defaultPeriod: AddCalendarPeriodForm = {
  duration: 0,
  status: 1,
  endAt: undefined,
  startAt: undefined,
  negotiatedAt: undefined,
};
