export function useDebounce(fn, delay = 500) {
  let timeoutRef = null;

  function debouncedFn(...args) {
    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }

    timeoutRef = setTimeout(() => fn(args), delay);
  }

  return debouncedFn;
}