import React from 'react';
import PrivatePolicyLayout from '../../containers/SimplePublicLayout';
import About from './components/About';
import Summary from './components/Summary';
import CollectedData from './components/CollectedData';
import PersonalDataUse from './components/PersonalDataUse';
import PersonalDataSharing from './components/PersonalDataSharing';
import Recipient from './components/Recipient';
import DataRetention from './components/DataRetention';
import InformationSecurity from './components/InformationSecurity';
import MinorsPrivacyProtection from './components/MinorsPrivacyProtection';
import Updates from './components/Updates';
import ContactUs from './components/ContactUs';

export default function PrivacityPolicy() {
  return (
    <PrivatePolicyLayout
      title="Política de privacidade Involves Doors"
      lastUpdate="Última versão: 10 de fevereiro de 2020."
      updateAtBottom
    >
      <About />
      <Summary />
      <CollectedData />
      <PersonalDataUse />
      <PersonalDataSharing />
      <Recipient />
      <DataRetention />

      <InformationSecurity />
      <MinorsPrivacyProtection />
      <Updates />
      <ContactUs />
    </PrivatePolicyLayout>
  );
}
