import { 
  checkInVisitAPI, 
  getPromoterByIdOrCpfAPI,
  liberateAccessStoreManagerAPI, 
} from './autoCheckinAPI';

export const getPromoterByIdOrCpf = (idOrCpf, token) => {
  return getPromoterByIdOrCpfAPI(idOrCpf, token);
};

export const checkInVisit = (promoterUserId, token) => {
  return checkInVisitAPI(promoterUserId, token);
};

export const liberateAccessStoreManager = ({ cpf, password, token, promoterId }) => {
  return liberateAccessStoreManagerAPI({ 
    cpf, 
    token,
    password, 
    promoterId,
  });
};


