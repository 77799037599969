import {
  resetPassword,
  getPasswordResetTokenInfo,
} from '../../core/services/passwordReset/passwordResetService';

export function isValidForm(password, confirmPassword) {
  return password !== '' && password === confirmPassword;
}

export const handleSubmit = async (event, passwordResetToken, newPassword) => {
  event.preventDefault();
  await resetPassword({ passwordResetToken, newPassword });
};

export const isTokenValid = async passwordResetToken => {
  try {
    await getPasswordResetTokenInfo(passwordResetToken);
    return true;
  } catch (e) {
    return false;
  }
};
