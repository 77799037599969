import React, { useCallback, useEffect, useState } from 'react';
import { Typography, TextField } from '@material-ui/core';
import { AutocompleteRenderInputParams } from '@material-ui/lab';
import Autocomplete from '../../../../../../../components/Autocomplete';
import * as dictionary from '../../../../../../../core/constants/dictionary';
import BaseModal from './BaseModal';
import classes from './Modals.module.scss';
import {
  PromotionalNegotiationPanelPeriod,
  PromotionalNegotiationPanelSpot,
} from '../../../../types/promotional-negotiation-panel.entity';
import { CalendarPeriodTableRow } from '../../../../../common/types';
import { formatWithoutTimezone } from '../../../../../../../core/constants/dates';
import { getAllPromotionalCalendarPeriods } from '../../../../../../../core/services/promotionalSpots/promotionalCalendarPeriodsService';
import { getPromotionalSpotById } from '../../../../../../../core/services/promotionalSpots/promotionalSpotsServices';

type Props = {
  isOpen: boolean;
  onClose: Function;
  onSubmit: Function;
  currentPeriod: PromotionalNegotiationPanelPeriod;
  spot: PromotionalNegotiationPanelSpot;
};

const FixNegotiationModal = ({
  isOpen,
  onClose,
  onSubmit,
  currentPeriod,
  spot,
}: Props) => {
  const [periodOptions, setPeriodOptions] = useState<CalendarPeriodTableRow[]>(
    []
  );
  const [isLoadingPeriods, setIsLoadingPeriods] = useState<boolean>(false);
  const [period, setPeriod] = useState<PromotionalNegotiationPanelPeriod>();
  const [formFields, setFormFields] = useState({
    errorText: '',
    showError: false,
  });

  const fetchPeriods = useCallback(async () => {
    try {
      if (!isLoadingPeriods) {
        setIsLoadingPeriods(true);
        const { data: spotData } = await getPromotionalSpotById(spot.id);

        const { data } = await getAllPromotionalCalendarPeriods({
          endAfterAt: currentPeriod.endAt,
          calendarId: spotData.calendar.id,
        });
        setIsLoadingPeriods(false);
        setPeriodOptions(data.filter((p) => currentPeriod.id !== p.id));
      }
    } catch (error) {
      setIsLoadingPeriods(false);
      setPeriodOptions([]);
    }
  }, [isLoadingPeriods, currentPeriod, spot]);

  useEffect(() => {
    fetchPeriods();
    return () => {
      setIsLoadingPeriods(false);
      setPeriodOptions([]);
      setPeriod(undefined);
    };
  }, []);

  return (
    <BaseModal
      title={dictionary.FIX_NEGOTIATION}
      isOpen={isOpen}
      isDisabled={!period}
      onClose={onClose}
      onClick={() =>
        onSubmit(
          periodOptions.slice(
            0,
            periodOptions.findIndex((p) => p.id === period?.id) + 1
          )
        )
      }
    >
      <div className={classes.formBody}>
        <Typography className={classes.caption}>
          {dictionary.SELECT_LIMIT_DATE_TO_FIX_NEGOTIATION}
        </Typography>
        <Autocomplete
          size="small"
          id="promotionalSpotCalendar"
          value={period}
          options={periodOptions}
          getOptionLabel={(period: PromotionalNegotiationPanelPeriod) =>
            formatWithoutTimezone(new Date(period.endAt))
          }
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              variant="outlined"
              label={dictionary.SELECT_A_FINAL_DATE}
              error={formFields.showError}
              helperText={formFields.errorText}
            />
          )}
          onChange={(_e: any, value: PromotionalNegotiationPanelPeriod) =>
            setPeriod(value)
          }
          onInputChange={() =>
            setFormFields({
              errorText: '',
              showError: false,
            })
          }
          className={classes.input}
          style={{}}
        />
      </div>
    </BaseModal>
  );
};

export default FixNegotiationModal;
