import React, { ReactNode } from 'react';
import { Dialog } from '@material-ui/core';

type Props = {
  width?: number;
  isOpen: boolean;
  children: ReactNode;
  closeModalCallback?: Function;
  classes?: object;
}

const ModalContainer = ({
  isOpen,
  children,
  width = 800,
  closeModalCallback,
  classes,
}: Props) => (
  <Dialog
    fullWidth
    open={isOpen}
    maxWidth="lg"
    PaperProps={{
      style: {
        width: width,
        overflow: 'hidden',
        padding: '48px',
      },
    }}
    classes={classes}
    onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        closeModalCallback && closeModalCallback(event, reason);
      }
    }}
  >
    {children}
  </Dialog>
);

export default ModalContainer;
