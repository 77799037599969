import {
  getPromotionalSpotReportProblemsAPI,
  getPromotionalSpotReportProductsAPI,
  getPromotionalSpotReportReportProblemsXLSAPI,
  getPromotionalSpotReportReportProductsXLSAPI,
} from './promotionalSpotReportAPI';
import {
  GetPromotionalSpotReportParams,
  GetPromotionalSpotReportProblemsParams,
} from './types';

export const getPromotionalSpotReportProducts = (
  params?: GetPromotionalSpotReportParams
) => {
  return getPromotionalSpotReportProductsAPI(params);
};

export const getPromotionalSpotReportReportProductsXLS = (
  params?: GetPromotionalSpotReportParams
) => {
  return getPromotionalSpotReportReportProductsXLSAPI(params);
};

export const getPromotionalSpotReportProblems = (
  params?: GetPromotionalSpotReportProblemsParams
) => {
  return getPromotionalSpotReportProblemsAPI(params);
};
export const getPromotionalSpotReportReportProblemsXLS = (
  params?: GetPromotionalSpotReportProblemsParams
) => {
  return getPromotionalSpotReportReportProblemsXLSAPI(params);
};
