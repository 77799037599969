import { store } from 'react-easy-state';
import { startOfToday, endOfToday } from 'date-fns';
import { mainStore } from '../../core/store/mainStore';
import { getVisitsPaginated } from '../../core/services/visits/visitsService';

const visitsOfDayStore = store({
  items: [],
  count: 0,
  total: 0,
  query: {
    startDate: startOfToday(),
    endDate: endOfToday(),
  },
  tableRef: null,

  refreshTable() {
    // @ts-ignore
    visitsOfDayStore.tableRef.onQueryChange(visitsOfDayStore.query);
  },

  async fetchItems(query: any) {
    visitsOfDayStore.query = {
      ...visitsOfDayStore.query,
      ...query,
    };
    mainStore.requestLoading = true;

    const params = {
      q: query.search,
      offset: query.page * query.pageSize,
      limit: query.pageSize,
      orderBy: query.orderBy ? query.orderBy.orderByField : 'checkinAt',
      orderDirection: query.orderDirection
        ? query.orderDirection.toUpperCase()
        : 'DESC',
      startDate: startOfToday(),
      endDate: endOfToday(),
    };

    query.filters.forEach((filter: any) => {
      //@ts-ignore
      params[filter.column.id] = filter.value;
    });

    let response = {};

    try {
      const { data } = await getVisitsPaginated(params);

      visitsOfDayStore.items = data.items;
      visitsOfDayStore.count = data.count;
      visitsOfDayStore.total = data.total;

      response = {
        data: data.items,
        page: query.page,
        totalCount: data.count,
      };
    } catch (error) {
      console.error(error);
    }

    mainStore.requestLoading = false;

    return response;
  },
});

export default visitsOfDayStore;
