import {
  getDocumentsAPI,
  getUserAPI,
  getAswerablesByAgreementAPI,
  getSuppliersByAgreementAPI,
  getPromotersByAgreementAPI,
  postUserAPI,
  deleteUserAPI,
  putUserAPI,
  getPromoterAccessesAPI,
  getAccessesAPI,
  getPromoterByIdOrCpfAPI,
} from './usersAPI';
import { documentsTypes } from '../../constants/enums';
import { userProfile, valueStatus } from '../../profiles';

export const loadProfilePicture = async userId => {
  return getDocumentsAPI(userId, documentsTypes.PROFILE_PICTURE);
};

export const loadCpfDocument = async userId => {
  return getDocumentsAPI(userId, documentsTypes.CPF);
};

export const loadRgDocument = async userId => {
  return getDocumentsAPI(userId, documentsTypes.RG);
};

export const getSalesReps = () => {
  const response = getUserAPI(undefined, userProfile.SALES_REP);
  return response.items;
};

export const getAnswerablesByAgreement = agreementId => {
  return getAswerablesByAgreementAPI(agreementId, valueStatus.ACTIVE);
};

export const getSuppliersByAgreement = agreementId => {
  return getSuppliersByAgreementAPI(agreementId, valueStatus.ACTIVE);
};

export const getUserByRetail = retailId => {
  const response = getUserAPI(retailId);
  return response.items;
};

export const getPromoterByIdOrCpf = idOrCpf => {
  return getPromoterByIdOrCpfAPI(idOrCpf);
};

export const getPromotersByAgreement = (agreementId, offset=0, limit=999999, query=null) => {
  return getPromotersByAgreementAPI(agreementId, offset, limit, query);
};

export const getPromoterUsers = async (retailId, offset=0, limit=999999, nameOrCpfSearched) => {
  const response = await getUserAPI(
    retailId,
    userProfile.PROMOTER,
    valueStatus.ACTIVE,
    offset,
    limit,
    null,
    nameOrCpfSearched
  );

  return response;
};

export const getAllPromoterUsers = (retailId, offset = 0, limit = 99999, query = null) => {
  return getUserAPI(retailId, userProfile.PROMOTER, undefined, offset, limit, null, query);
};

export const getAllStoreManagers = ({
  retailId = undefined, 
  offset = 0, 
  limit = 99999, 
  query = "",
}) => {
  return getUserAPI(retailId, userProfile.STORE_MANAGER, undefined, offset, limit, null, query);
};

export const getAllStoreManagersByStoreId = ({
  offset = 0, 
  limit = 9999, 
  query = "",
  retailId = undefined, 
  storeId = undefined,
}) => {
  return getUserAPI(retailId, userProfile.STORE_MANAGER, undefined, offset, limit, null, query, storeId);
};

export const getAllNotPromotersUsers = ({ retailId = '', offset = 0, limit = 10, queryString = '', retailName = '', cancelToken = undefined}) => {
  const response = getUserAPI(
    retailId, 
    null, 
    null, 
    offset, 
    limit, 
    [userProfile.PROMOTER],
    queryString,
    null,
    retailName,
    cancelToken
  );
  return response;
};

export const postUser = (data, token) => {
  return postUserAPI(data, token);
};

export const putUser = data => {
  return putUserAPI(data);
};

export const postOrPutUser = async (data, token) => {
  if (data.id) {
    await putUserAPI(data);
  } else {
    await postUserAPI(data, token);
  }
};

export const deleteUser = data => {
  return deleteUserAPI(data);
};

export const getPromoterAccesses = ({ params, retailId, offset = 0, limit = 999999, query= "" }) => {
  return getPromoterAccessesAPI({ params, retailId, offset, limit, query });
};

export const getAccesses = async (retailId, promoterUserId) => {
  return await getAccessesAPI(retailId, promoterUserId);
};
