import React from 'react';
import classes from '../styles.module.scss';

export default () => (
  <section className={classes.section}>
    <h2 className={classes.title}>
      PROTEÇÃO DE PRIVACIDADE DE MENORES
    </h2>

    <p className={classes.doubleMargin}>
      Não coletamos intencionalmente informações de identificação pessoal de
      menores de 18 anos. Se você é pai/mãe ou responsável legal e está
      ciente de que seus filhos nos forneceram dados pessoais, entre em
      contato conosco. Se ficarmos cientes de que coletamos dados pessoais
      de crianças sem a verificação do consentimento dos pais, tomamos
      medidas para remover essas informações de nossos servidores.
    </p>
  </section>
);

    