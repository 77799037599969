import React from 'react';
import WelcomePage from '../../../../components/WelcomePage';
import * as dictionary from '../../../../core/constants/dictionary';

type Props = {
  onClickButtonHandler: Function;
}

const CreateAddressPage = ({ onClickButtonHandler }: Props) => {
  return (
    <WelcomePage
      // @ts-ignore
      textButton={dictionary.NEW_ADDRESS}
      // @ts-ignore
      handleButton={onClickButtonHandler}
    >
      <span>
        {dictionary.CLICK_ON}
        <strong>{dictionary.NEW_ADDRESS}</strong>
        {dictionary.FILL_IN_THE_DATA_REQUESTED}
      </span>
    </WelcomePage>
  );
}

export default CreateAddressPage;
