export const colors = {
  black: '#111111',
  white: '#ffffff',
  //
  black_50: '#FAFBFC',
  black_100: '#E1E5EB',
  black_200: '#BDC7D3',
  black_300: '#A3ABBD',
  black_400: '#8A90A7',
  black_500: '#6F7390',
  black_600: '#59577A',
  black_700: '#443C63',
  black_800: '#322550',
  black_900: '#1f0e3d',
  //
  red_50: '#FFF6F6',
  red_100: '#FFDFDF',
  red_200: '#FEC8C8',
  red_300: '#FDA9A9',
  red_400: '#FDB9B9',
  red_500: '#FD6969',
  red_600: '#FC4949',
  red_700: '#D63E47',
  red_800: '#AF3445',
  red_900: '#612041',
  //
  blue_50: '#F6FAFF',
  blue_100: '#DFECFE',
  blue_200: '#C7DFFD',
  blue_300: '#87B9FB',
  blue_400: '#66A6F9',
  blue_500: '#4693F8',
  blue_600: '#3F7BD7',
  blue_700: '#3864B7',
  blue_800: '#324D96',
  blue_900: '#2B3675',
  //
  green_50: '#F5FFF8',
  green_100: '#DCFFE6',
  green_200: '#C3FED4',
  green_300: '#7CFCA3',
  green_400: '#36FA71',
  green_500: '#33DF6B',
  green_600: '#31C365',
  green_700: '#2EA75F',
  green_800: '#2A7E56',
  green_900: '#26554D',
  //
  yellow_50: '#FFFAE3',
  yellow_100: '#FEEBAF',
  yellow_200: '#FDDC7C',
  yellow_300: '#FCCE48',
  yellow_400: '#FBBF14',
  yellow_500: '#E7A80D',
  yellow_600: '#D39007',
  yellow_700: '#BF7800',
  yellow_800: '#9F5600',
  yellow_900: '#7F3300',
};
