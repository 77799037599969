import { getAxiosInstance } from '../api/configAutoCheckin';
import { timezone } from '../../constants/dates';
import * as routes from '../api/routes';

export const getPromoterByIdOrCpfAPI = async (idOrCpf, token) => {
  const { data } = await getAxiosInstance(token).get('/promoters/user', {
    params: {
      q: idOrCpf,
    },
  });
  return data;
};

export const checkInVisitAPI = async (promoterUserId, token) => {
  const { data } = await getAxiosInstance(token).post('/visits', {
    promoterUserId,
    qrcodeId: '',
    timezone: timezone(),
  });

  return data;
};

export const liberateAccessStoreManagerAPI = async ({ cpf, password, token, promoterId }) => {
  const url = routes.API_VISITS + routes.API_VISITS_LIBERATE_ACCESS;
  const payload = { cpf, password, promoterId };
  const config = { 
    headers: { 
      'doors-platform': 'web' 
    }, 
  };
  
  return await getAxiosInstance(token).post(url, payload, config);
};
