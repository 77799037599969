import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
  Button,
  Checkbox,
  Typography,
  DialogActions,
  DialogContent,
  FormControlLabel,
} from '@material-ui/core';
import * as R from 'ramda';
import TextField from '../../../components/InputTextField';
import ModalTitle from '../../../components/Modal/Title';
import Autocomplete from '../../../components/Autocomplete';
import { MemorizedSearchField } from '../../../components/SearchField';
import { utils } from '../../../core/utils';
import { mainStore } from '../../../core/store/mainStore';
import { SyncValidations } from '../../../core/validator/validations';
import { ACTIVE, INACTIVE } from '../../../core/constants/status';
import { CpfTextMaskCustom } from '../../../core/masks/masks';
import { profileCheck, userProfile } from '../../../core/profiles';
import { CreateUserErrorCode } from '../../../core/types/user/create-user-error-code.enum';
import {
  getAgreement,
  addPromoterToAgreement,
  updatePromoterByAgreement,
} from '../../../core/services/agreements/agreementsService';
import {
  getAccesses,
  postOrPutUser,
} from '../../../core/services/users/usersService';
import * as dictionary from '../../../core/constants/dictionary';
import { fetchActiveRetails } from '../../Users/utils';
import { emptyNewPromoterFormValue, promoterStatus } from '../utils';
import { PromoterAgreementsTable } from './promoterAgreementsTable';

export const CreateContentModal = ({ onClose, onDismiss, promoterEdit }) => {
  const mounted = useRef(false);
  const [promoter, setPromoter] = useState(
    promoterEdit || emptyNewPromoterFormValue,
  );
  const [editing] = useState(promoter.id && promoter.id.length > 0);
  const [agreements, setAgreements] = useState([]);
  const [promoterAgreements, setPromoterAgreements] = useState([]);
  const [fetchingAgreements, setFetchingAgreements] = useState(false);
  const [refreshPromoterAgreements, setRefreshPromoterAgreements] = useState(editing);
  const [fetchingPromoterAgreements, setFetchingPromoterAgreements] = useState(editing);
  const [retails, setRetails] = useState([]);
  const [selectedRetail, setSelectedRetail] = useState(null);

  useEffect(() => {
    mounted.current = true;
    
    (async () => {
      try {
        const result = await fetchActiveRetails();
        setRetails(result);
  
        if (promoter?.retailId) {
          setSelectedRetail(
            result.find(r => r.id === promoter.retailId)
          );
        }
      } catch {}
    })();

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchAgreements = async () => {
      mounted.current && setFetchingAgreements(true);
      try {
        const response = await getAgreement(mainStore.loggedUser.retailId, ACTIVE);
        const items = response.items;

        mounted.current && setAgreements([...items]);
      } catch (error) {
        console.error(error);
      }
      mounted.current && setFetchingAgreements(false);
    };
    fetchAgreements();
  }, []);

  useEffect(() => {
    if (!refreshPromoterAgreements) {
      return;
    }

    const fetchPromoterAgreements = async () => {
      mounted.current && setFetchingPromoterAgreements(true);

      try {
        const items = await getAccesses(
          mainStore.loggedUser.retailId,
          promoter.id,
        );

        if (!R.isEmpty(items)) {
          const parsedItems = items.map(item => ({
            id: item.agreementId,
            agreementId: item.agreementId,
            promoterId: item.promoterId,
            internalCode: item.agreementInternalCode,
            supplierNames: item.agreementSupplierNames,
            status: item.status,
          }));

          mounted.current && setPromoterAgreements(parsedItems);
        }
      } catch (error) {
        console.error(error);
      }

      if (mounted.current) {
        setFetchingPromoterAgreements(false);
        setRefreshPromoterAgreements(false);
      }
    };

    fetchPromoterAgreements();
  }, [refreshPromoterAgreements, promoter.id]);

  const renderRetailField = useMemo(() => {
    if (! profileCheck.isSuperAdmin()) {
      return null;
    }
    
    return (
      <Autocomplete
        options={retails}
        disabled={editing}
        value={selectedRetail}
        label={dictionary.RETAIL}
        validations={[SyncValidations.notEmpty]}
        renderOption={(option) => option.name}
        onChange={(e, retail) => setSelectedRetail(retail)}
        style={{width: '100%'}}
      />
    )
  }, [retails, selectedRetail]);

  const handleRemovePromoterAgreement = async promoterAgreement => {
    if (editing) {
      const status = promoterAgreement.status === promoterStatus.ACTIVE 
        ? promoterStatus.INACTIVE 
        : promoterStatus.ACTIVE;
      
      try {
        await updatePromoterByAgreement(
          promoterAgreement.agreementId,
          promoterAgreement.promoterId,
          { status },
        );

        let feedbackMessage = "Promotor liberado com sucesso!";
        if (status === promoterStatus.INACTIVE) {
          feedbackMessage = "Promotor suspenso com sucesso!";
        } 
        utils.openSnackBar('success', feedbackMessage);

        setRefreshPromoterAgreements(true);
      } catch (error) {
        if (
          R.hasPath(['response', 'data'], error) &&
          R.propEq(
            'message',
            'Inactive users can not be approved into linked  agreements',
          )(error.response.data)
        ) {
          utils.openSnackBar(
            'error',
            'Não é possível liberar um promotor inativo em um acordo',
          );
        } else {
          utils.openSnackBar(dictionary.ERROR, dictionary.UNKNOWN_ERROR);
        }
      }
    } else {
      setPromoterAgreements(
        promoterAgreements.filter(
          ({ agreementId }) => agreementId !== promoterAgreement.agreementId,
        ),
      );
    }
  };

  const handleAssociateAgreement = async agreement => {
    const alreadyIn = R.includes(
      R.prop('id', agreement),
      R.pluck('agreementId', promoterAgreements),
    );

    if (alreadyIn) {
      return;
    }

    if (editing) {
      await addPromoterToAgreement(agreement.id, promoter.id);
      setRefreshPromoterAgreements(true);
      utils.openSnackBar(
        'success',
        'Promotor vinculado ao acordo com sucesso!',
      );
    } else {
      setPromoterAgreements([
        ...promoterAgreements,
        {
          id: agreement.id,
          agreementId: agreement.id,
          internalCode: agreement.internalCode,
          supplierNames: agreement.supplierNames,
          status: agreement.status,
        },
      ]);
    }
  };

  const handleChangeUserStatus = () => {
    setPromoter({
      ...promoter,
      status: promoter.status === 1 ? 0 : 1,
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    
    if (!event.currentTarget.form.reportValidity()) {
      return utils.openSnackBar(dictionary.ERROR, dictionary.FORM_INVALID);
    }

    let retailId = mainStore?.loggedUser?.retailId;
    if (selectedRetail) {
      retailId = selectedRetail.id;
    }

    try {
      const payload = {
        retailId,
        id: promoter.id,
        name: promoter.name,
        cpf: promoter.cpf,
        rg: promoter.rg,
        email: promoter.email,
        status: promoter.status,
        profile: userProfile.PROMOTER,
        agreements: promoterAgreements.map(({ id }) => id),
      };

      await postOrPutUser(payload);
      await onClose();

      if (promoter.id && promoter.id.length > 0) {
        utils.openSnackBar('success', dictionary.PROMOTER_EDITED);
      } else if (!promoterAgreements || promoterAgreements.length === 0) {
        utils.openSnackBar('success', dictionary.PROMOTER_CREATED);
      } else {
        utils.openSnackBar('success', dictionary.PROMOTER_CREATED_AND_LINKED);
      }
    } catch (err) {
      let errorFeedbackMessage = dictionary.UNKNOWN_ERROR;
      const { code } = err.response.data;

      switch (code) {
        case CreateUserErrorCode.PROMOTER_DUPLICATED_CPF:
        case CreateUserErrorCode.PROMOTER_DUPLICATED_CPF_RETAIL:
          errorFeedbackMessage = dictionary.ALREADY_REGISTERED_PROMOTER_ON_RETAIL;
          break;

        case CreateUserErrorCode.DUPLICATED_CPF:
        case CreateUserErrorCode.DUPLICATED_CPF_RETAIL:
          errorFeedbackMessage = dictionary.ALREADY_REGISTERED_USER_ON_RETAIL;
          break;

        case CreateUserErrorCode.DUPLICATED_EMAIL:
        case CreateUserErrorCode.DUPLICATED_EMAIL_RETAIL:
          errorFeedbackMessage = dictionary.EMAIL_ALREADY_REGISTERED;
          break;
        case CreateUserErrorCode.INVALID_CPF:
          errorFeedbackMessage = dictionary.INVALID_CPF;
          break;
      }
      
      utils.openSnackBar('error', errorFeedbackMessage);
    }
  };

  return (
    <>
      <ModalTitle title={promoterEdit ? dictionary.UPDATE_PROMOTER : dictionary.NEW_PROMOTER} />
      <form noValidate style={{ overflowY: 'auto' }} class='create-promoter-form'>
        <DialogContent>
          <div style={{width: 'calc(50% - 0.5rem)'}}>
            {renderRetailField}
          </div>
          <div className="form-two-columns input-validation">
            <TextField
              label={dictionary.NAME}
              value={promoter.name}
              validations={[SyncValidations.notEmpty]}
              onChange={e => {
                setPromoter({ ...promoter, name: e.target.value })
              }}
            />

            <TextField
              disabled={editing}
              value={promoter.cpf}
              label={dictionary.CPF}
              validations={[SyncValidations.notEmpty, SyncValidations.validCpf]}
              InputProps={{ inputComponent: CpfTextMaskCustom }}
              onChange={e => {
                setPromoter({ ...promoter, cpf: e.target.value });
              }}
            />
          </div>
          
          <div className="form-two-columns">
            <TextField
              value={promoter.rg}
              label={dictionary.USER_RG}
              onChange={e => setPromoter({ ...promoter, rg: e.target.value })}
              required={false}
            />

            <TextField
              value={promoter.email}
              label={dictionary.USER_EMAIL}
              validations={[SyncValidations.validEmail]}
              onChange={e => {
                setPromoter({ ...promoter, email: e.target.value });
              }}
              required={false}
            />
          </div>

          <div>
            <MemorizedSearchField
              options={agreements}
              loading={fetchingAgreements}
              handleUpdate={handleAssociateAgreement}
              getOptionLabel={opt => {
                return `(${opt.internalCode}) ${opt.supplierNames}`;
              }}
              label={dictionary.LINK_PROMOTER_TO_ONE_OR_MORE_AGREEMENTS}
              disabled={promoter.status === INACTIVE}
            />
          </div>

          <div className="reverse-table">
            <PromoterAgreementsTable
              data={promoterAgreements}
              handleRemove={handleRemovePromoterAgreement}
              editing={editing}
              loading={fetchingPromoterAgreements}
            />
          </div>

          <FormControlLabel
            control={(
              <Checkbox
                color="primary"
                checked={promoter.status === 1}
                onChange={handleChangeUserStatus}
              />
            )}
            label={dictionary.ACTIVE_USER}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onDismiss} color="primary">
            {dictionary.CANCEL}
          </Button>

          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={R.isEmpty(promoter.name) || R.isEmpty(promoter.cpf)}
          >
            {dictionary.SAVE}
          </Button>
        </DialogActions>
      </form>
    </>
  );
};
