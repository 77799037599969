import React, { useState, MouseEvent, useEffect, useCallback } from 'react';
import { Button, Table, TableBody, TableContainer } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import * as dictionary from '../../../../../core/constants/dictionary';
import {
  CreateModal,
  UpdateModal,
  DeleteModal,
  MoreOptionsMenu,
  AddressingModal,
  FixNegotiationModal,
  ViewReportedProblemModal,
  AvailableRow,
  NegotiationHeader,
} from './components';
import classes from './NegotiationTable.module.scss';
import { PromotionalNegotiationDto } from '../../../common/types';
import { NegotiationTableItem } from '../NegotiationTableItem';
import {
  createNegotiation,
  createRecursiveNegotiation,
  deleteNegotiation,
  updateNegotiation,
  upsertNegotiationProducts,
  upsertNegotiationSuppliers,
} from '../../../../../core/services/promotionalSpots/promotionalNegotiationService';
import { ModalType } from '../../types/modals.type';
import { AddressingDto } from '../../../../../core/types/promotional-spot/promotional-addressing.dto';
import {
  createPromotionalSpotNegotiation,
  DecrementPromotionalNegotiationExecution,
  deletePromotionalSpotNegotiationExecution,
  IncrementPromotionalNegotiationExecution,
} from '../../../../../core/services/promotionalSpots/promotionalNegotiationExecutionService';
import {
  PromotionalNegotiationPanelPeriod,
  PromotionalNegotiationPanelSpot,
} from '../../types/promotional-negotiation-panel.entity';
import {
  ProductEntity,
  SuppliersEntity,
} from '../../../../../core/types/promotional-spot/product-family-entity.type';
import { PromotionalNegotiationExecutionDto } from '../../../../../core/types/promotional-spot/promotional-negotiation-execution-dto';
import { getFormattedDate } from '../../../../../core/constants/dates';
import { removeRecursiveNegotiationAPI } from '../../../../../core/services/promotionalSpots/promotionalNegotiationAPI';
import useRetailInUser from '../../../common/components/hooks/use-retail-in-user.hook';
import {
  SnackBarTypeEnum,
  useSnackBar,
} from '../../../../../context/Snackbars';

import { StoreDto } from '../../../Addressing/types';
import { useNegotiationPanelContext } from '../../context';
import { useModalHook } from '../../hooks/ModalHook';
import { BlockPromotionalNegotiationError } from '../../types/promotional-spot-negotiation-error';
import {
  isAvaliableAddresingInStore,
  parseCurrentExecutionsInSpot,
  parseStoresMetrics,
} from './utils';
import OverrideModal from './components/modals/OverRideModal';
import { PromotionalNegotiationExecutionInConflictQueryDto } from './components/modals/types';
import UnFixNegotiationModal from './components/modals/UnFixNegotiationModal';
import { CreateExecutionErrors } from '../../../../../core/types/promotional-spot/errors/create-execution-error.enum';

type Props = {
  addressings: AddressingDto[];
  negotiations: PromotionalNegotiationDto[];
  spot: PromotionalNegotiationPanelSpot;
  period: PromotionalNegotiationPanelPeriod;
  onUpdateItem: Function;
  stores: StoreDto[];
};
const NegotiationTable = ({
  addressings,
  negotiations,
  onUpdateItem,
  stores,
  spot,
  period,
}: Props) => {
  const { openSnackBar } = useSnackBar();
  const [negotiationList, setNegotiationList] =
    useState<PromotionalNegotiationDto[]>(negotiations);

  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [updateItem, setUpdateItem] =
    useState<PromotionalNegotiationDto | null>(null);
  const [deleteItem, setDeleteItem] =
    useState<PromotionalNegotiationDto | null>(null);
  const [executionsProblems, setExecutionsProblems] = useState<
    PromotionalNegotiationExecutionDto[]
  >([]);
  const [executions, setExecutions] = useState<
    PromotionalNegotiationExecutionDto[]
  >([]);
  const [executionToView, setExecutionToView] =
    useState<PromotionalNegotiationExecutionDto>();
  const [store, setStore] = useState<StoreDto>();
  const [negotiation, setNegotiation] = useState<PromotionalNegotiationDto>();
  const [periodsToOverride, setPeriodsToOverride] = useState<
    PromotionalNegotiationPanelPeriod[] | undefined
  >();
  const [executionsToOverride, setExecutionsToOverride] = useState<
    PromotionalNegotiationExecutionInConflictQueryDto[] | undefined
  >();

  const [showAddExecution, setShowAddExecution] = useState<boolean>(false);
  const { reloadMetrics } = useNegotiationPanelContext();
  const { modalType, isModalOpen, closeModal, openModal } = useModalHook();
  const retailId = useRetailInUser();

  const metricsInStore = parseStoresMetrics(stores, addressings);
  const currentExecutions = parseCurrentExecutionsInSpot(negotiationList);

  const isExpired =
    new Date(period.negotiationLimitAt).getTime() < new Date().getTime();

  const isPending = new Date(period.startAt).getTime() < new Date().getTime();

  const handleOpenMoreOptions = useCallback(
    (
      event: MouseEvent<HTMLButtonElement>,
      executions: PromotionalNegotiationDto['executions'] = [],
      store: any,
      negotiation: PromotionalNegotiationDto,
      showOptionAddmore?: boolean
    ) => {
      event.stopPropagation();
      setMenuAnchorEl(event.currentTarget);
      setExecutions(executions);
      setStore(store);
      setNegotiation(negotiation);
      if (
        showOptionAddmore &&
        isAvaliableAddresingInStore({
          store,
          negotiations,
          metrics: metricsInStore,
        })
      ) {
        setShowAddExecution(true);
      }
    },
    [metricsInStore]
  );

  const isAvaliableOptionsInStore = useCallback(
    (store: any) => {
      return isAvaliableAddresingInStore({
        store,
        negotiations,
        metrics: metricsInStore,
      });
    },
    [metricsInStore]
  );

  const hanndleToggleLockAction = useCallback(
    (
      _: MouseEvent<HTMLButtonElement>,
      negotiation: PromotionalNegotiationDto
    ) => {
      if (!negotiation.locked) {
        setNegotiation(negotiation);
        openModal(ModalType.FIX_NEGOTIATION);
      } else {
        setNegotiation(negotiation);
        openModal(ModalType.UNFIX_NEGOTIATION);
      }
    },
    []
  );

  const onSelectToEditNegotiation = useCallback(
    (event: any, negotiation: PromotionalNegotiationDto) => {
      event.stopPropagation();
      setUpdateItem(negotiation);
      openModal(ModalType.UPDATE);
    },
    []
  );

  const onSelectToDeleteNegotiation = useCallback(
    (event: any, negotiation: PromotionalNegotiationDto) => {
      event.stopPropagation();
      setDeleteItem(negotiation);
      openModal(ModalType.DELETE);
    },
    []
  );

  const handleViewProblem = (
    event: MouseEvent<HTMLButtonElement>,
    executions: PromotionalNegotiationDto['executions'] = []
  ) => {
    setExecutionsProblems(executions);
    event.stopPropagation();
    openModal(ModalType.VIEW_REPORTED_PROBLEMS);
  };

  const onSelectRowHandler = (evt: any, index: string) => {
    evt?.stopPropagation();
    if (selectedRows.find((value) => value === index)) {
      setSelectedRows((prevState) =>
        prevState.filter((item) => item !== index)
      );
    } else {
      setSelectedRows((prevState) => [...prevState, index]);
    }
  };

  const onSubmitUpdateNegotiation = useCallback(
    async (
      id: string = '',
      supplier: SuppliersEntity[] = [],
      product: ProductEntity[] = [],
      description: string = '',
      familiesIds: string[]
    ) => {
      try {
        if (!isLoadingUpdate) {
          setIsLoadingUpdate(true);
          await updateNegotiation({
            id,
            observation: description,
            retailId,
            familiesIds,
          });
          await Promise.all([
            upsertNegotiationSuppliers({
              id,
              retailId,
              supplierIds: supplier.map(
                (supplier: SuppliersEntity) => supplier?.id
              ),
            }),
            upsertNegotiationProducts({
              id,
              retailId,
              productIds: product.map((product: ProductEntity) => product?.id),
            }),
          ]);
          await Promise.all([onUpdateItem(), reloadMetrics()]);
          setIsLoadingUpdate(false);
          onExecutionModalClose();
          openSnackBar(
            SnackBarTypeEnum.SUCCESS,
            dictionary.UPDATE_PROMOTIONAL_NEGOTIATION_SUCCESS
          );
        }
      } catch (err) {
        openSnackBar(
          SnackBarTypeEnum.ERROR,
          dictionary.UPDATE_PROMOTIONAL_NEGOTIATION_ERROR
        );
        setIsLoadingUpdate(false);
        onExecutionModalClose();
      }
    },
    [isLoadingUpdate, retailId]
  );

  const updateMetrics = async () => {
    await Promise.all([onUpdateItem(), reloadMetrics()]);
  };

  const onSubmitCreateNegotiation = useCallback(
    async (
      id: string = '',
      supplier: SuppliersEntity[] = [],
      product: ProductEntity[] = [],
      description: string = '',
      familiesIds: string[]
    ) => {
      try {
        if (!isLoadingUpdate) {
          setIsLoadingUpdate(true);
          await createNegotiation({
            observation: description,
            spotId: spot.id,
            periodId: period.id,
            retailId,
            productIds: product.map((product: ProductEntity) => product.id),
            supplierIds: supplier.map(
              (supplier: SuppliersEntity) => supplier.id
            ),
            familiesIds,
          });
          openSnackBar(
            SnackBarTypeEnum.SUCCESS,
            dictionary.CREATE_PROMOTIONAL_NEGOTIATION_SUCCESS
          );
          await Promise.all([onUpdateItem(), reloadMetrics()]);
          setIsLoadingUpdate(false);
          onExecutionModalClose();
        }
      } catch (err) {
        openSnackBar(
          SnackBarTypeEnum.ERROR,
          dictionary.CREATE_PROMOTIONAL_NEGOTIATION_ERROR
        );
        setIsLoadingUpdate(false);
        onExecutionModalClose();
      }
    },
    [isLoadingUpdate, spot, period, retailId]
  );

  const onSubmitCreateExecution = useCallback(
    async (physicalAddressing: AddressingDto) => {
      try {
        if (negotiation) {
          await createPromotionalSpotNegotiation({
            addressingId: physicalAddressing.id,
            negotiationId: negotiation?.id,
            retailId,
          });
          await Promise.all([onUpdateItem(), reloadMetrics()]);
          onExecutionModalClose();
          openSnackBar(
            SnackBarTypeEnum.SUCCESS,
            dictionary.CREATE_PROMOTIONAL_NEGOTIATION_EXECUTION_SUCCESS
          );
        }
      } catch (error) {
        const message =
          error.response.data.error === CreateExecutionErrors.ADDRESSING_IN_USE
            ? dictionary.INCREMENT_PROMOTIONAL_NEGOTIATION_EXECUTION_ERROR_ADRESSING_IN_USE
            : dictionary.CREATE_PROMOTIONAL_NEGOTIATION_EXECUTION_ERROR;
        openSnackBar(SnackBarTypeEnum.ERROR, message);
      }
    },
    [negotiation, retailId]
  );

  const onSubmitDeleteExecution = useCallback(async () => {
    try {
      if (executionToView) {
        await deletePromotionalSpotNegotiationExecution(executionToView.id);
        await Promise.all([onUpdateItem(), reloadMetrics()]);
        onExecutionModalClose();
        openSnackBar(
          SnackBarTypeEnum.SUCCESS,
          dictionary.DELETE_PROMOTIONAL_NEGOTIATION_EXECUTION_SUCCESS
        );
      }
    } catch (error) {
      openSnackBar(
        SnackBarTypeEnum.ERROR,
        dictionary.DELETE_PROMOTIONAL_NEGOTIATION_EXECUTION_ERROR
      );
      onExecutionModalClose();
    }
  }, [executionToView]);

  const onSubmitDeleteNegotiation = useCallback(async () => {
    if (!isLoadingUpdate && deleteItem?.id) {
      try {
        setIsLoadingUpdate(true);
        await deleteNegotiation({
          id: deleteItem.id,
        });
        await Promise.all([onUpdateItem(), reloadMetrics()]);
        setNegotiationList((currentNegotiations) =>
          currentNegotiations.filter((item) => item.id !== deleteItem.id)
        );
        setIsLoadingUpdate(false);
        closeModal();
        openSnackBar(
          SnackBarTypeEnum.SUCCESS,
          dictionary.DELETE_PROMOTIONAL_NEGOTIATION_SUCCESS
        );
      } catch (error) {
        openSnackBar(
          SnackBarTypeEnum.ERROR,
          dictionary.DELETE_PROMOTIONAL_NEGOTIATION_ERROR
        );
        setIsLoadingUpdate(false);
        onExecutionModalClose();
      }
    }
  }, [isLoadingUpdate, deleteItem]);

  const onExecutionModalClose = useCallback(() => {
    setExecutions([]);
    setStore(undefined);
    closeModal();
    setNegotiation(undefined);
    setExecutionToView(undefined);
  }, []);

  const onSubmitLockNegotiation = useCallback(
    async (
      periods: PromotionalNegotiationPanelPeriod[],
      isOverride = false
    ) => {
      const periodIds = periods.map((period) => period.id);
      try {
        setIsLoadingUpdate(true);
        await createRecursiveNegotiation({
          id: negotiation?.id ?? '',
          periodIds,
          isOverride,
        });
        await Promise.all([onUpdateItem(), reloadMetrics()]);
        openSnackBar(
          SnackBarTypeEnum.SUCCESS,
          dictionary.LOCK_PROMOTIONAL_NEGOTIATION_SUCCESS
        );
        setIsLoadingUpdate(false);
        onExecutionModalClose();
      } catch (error) {
        if (
          error.response.data.message ===
          BlockPromotionalNegotiationError.DUPLICATED_EXECUTIONS_IN_NEGOTIATION
        ) {
          setPeriodsToOverride(periods);
          setExecutionsToOverride(error.response.data.response);
          openModal(ModalType.OVERRIDE_EXECUTIONS);
        } else {
          openSnackBar(
            SnackBarTypeEnum.ERROR,
            dictionary.LOCK_PROMOTIONAL_NEGOTIATION_ERROR
          );
          setIsLoadingUpdate(false);
          onExecutionModalClose();
        }
      }
    },
    [negotiation]
  );

  const onUnLockNegotiation = useCallback(
    async (id: string) => {
      setIsLoadingUpdate(true);
      try {
        if (!isLoadingUpdate) {
          await removeRecursiveNegotiationAPI(id);
          await Promise.all([onUpdateItem(), reloadMetrics()]);
          openSnackBar(
            SnackBarTypeEnum.SUCCESS,
            dictionary.UNLOCK_PROMOTIONAL_NEGOTIATION_SUCCESS
          );
        }
      } catch (error) {
        openSnackBar(
          SnackBarTypeEnum.ERROR,
          dictionary.UNLOCK_PROMOTIONAL_NEGOTIATION_ERROR
        );
      } finally {
        setIsLoadingUpdate(false);
        onExecutionModalClose();
      }
    },
    [negotiation]
  );

  const onAddExecution = useCallback(
    async (negotiationId: string) => {
      if (!isLoadingUpdate) {
        try {
          setIsLoadingUpdate(true);
          const result = await IncrementPromotionalNegotiationExecution({
            negotiationId,
            storeIds: stores.map((store) => store.id),
          });
          await Promise.all([onUpdateItem(), reloadMetrics()]);
          if (result.data.length > 0) {
            openSnackBar(
              SnackBarTypeEnum.SUCCESS,
              dictionary.INCREMENT_PROMOTIONAL_NEGOTIATION_EXECUTION_SUCCESS
            );
          } else {
            openSnackBar(
              SnackBarTypeEnum.ERROR,
              dictionary.INCREMENT_PROMOTIONAL_NEGOTIATION_EXECUTION_ERROR_OUT_OF_LIMIT
            );
          }
        } catch (error) {
          setIsLoadingUpdate(false);
          openSnackBar(
            SnackBarTypeEnum.ERROR,
            dictionary.INCREMENT_PROMOTIONAL_NEGOTIATION_EXECUTION_ERROR
          );
        } finally {
          setIsLoadingUpdate(false);
        }
      }
    },
    [isLoadingUpdate]
  );

  const onRemoveExecution = useCallback(
    async (negotiationId: string) => {
      if (!isLoadingUpdate) {
        try {
          setIsLoadingUpdate(true);
          await DecrementPromotionalNegotiationExecution(negotiationId);
          await Promise.all([onUpdateItem(), reloadMetrics()]);
          setTimeout(() => {
            openSnackBar(
              SnackBarTypeEnum.SUCCESS,
              dictionary.DECREMENT_PROMOTIONAL_NEGOTIATION_EXECUTION_SUCCESS
            );
          }, 500);
        } catch (error) {
          openSnackBar(
            SnackBarTypeEnum.ERROR,
            dictionary.DECREMENT_PROMOTIONAL_NEGOTIATION_EXECUTION_ERROR
          );
        } finally {
          setIsLoadingUpdate(false);
        }
      }
    },
    [isLoadingUpdate]
  );

  const renderModal = ({
    isModalOpen = false,
    modalType = null,
    updateItem = null,
  }: {
    isModalOpen: boolean;
    modalType: ModalType | null;
    updateItem: PromotionalNegotiationDto | null;
  }) => {
    switch (modalType) {
      case ModalType.CREATE:
        return (
          <CreateModal
            isEditable={!isExpired}
            isOpen={isModalOpen}
            data={{
              title: spot.name,
              dateTime: `${getFormattedDate(
                new Date(period.startAt)
              )} à ${getFormattedDate(new Date(period.endAt))}`,
            }}
            onClose={() => {
              closeModal();
              setUpdateItem(null);
            }}
            onSubmit={onSubmitCreateNegotiation}
          />
        );
      case ModalType.UPDATE:
        return (
          <UpdateModal
            isEditable={!isExpired}
            data={{
              id: updateItem?.id,
              description: updateItem?.observation ?? '',
              supplier:
                updateItem?.suppliers?.map(
                  (supplier: any) => supplier.supplier
                ) ?? [],
              product:
                updateItem?.products?.map((product: any) => product.product) ??
                [],
              dateTime: `${getFormattedDate(
                new Date(period.startAt)
              )} á ${getFormattedDate(new Date(period.endAt))}`,
              title: spot.name,
              familiesIds: updateItem?.familiesIds,
            }}
            isOpen={isModalOpen}
            onClose={() => closeModal()}
            onSubmit={onSubmitUpdateNegotiation}
          />
        );
      case ModalType.OVERRIDE_EXECUTIONS:
        return (
          <OverrideModal
            executionsToOverride={executionsToOverride ?? []}
            isOpen={isModalOpen}
            onClose={() => {
              setPeriodsToOverride(undefined);
              setExecutionsToOverride(undefined);
              closeModal();
              setIsLoadingUpdate(false);
            }}
            onOverride={() => {
              onSubmitLockNegotiation(periodsToOverride ?? [], true);
            }}
          />
        );
      case ModalType.DELETE:
        return (
          <DeleteModal
            isOpen={isModalOpen}
            onClose={() => closeModal()}
            onDelete={onSubmitDeleteNegotiation}
          />
        );
      case ModalType.UNFIX_NEGOTIATION:
        return (
          <UnFixNegotiationModal
            isOpen={isModalOpen}
            onClose={() => closeModal()}
            onSubmit={() => {
              if (negotiation?.id) {
                onUnLockNegotiation(negotiation?.id);
              }
            }}
          />
        );
      case ModalType.FIX_NEGOTIATION:
        return (
          <FixNegotiationModal
            currentPeriod={period}
            spot={spot}
            isOpen={isModalOpen}
            onClose={() => {
              closeModal();
              setUpdateItem(null);
            }}
            onSubmit={onSubmitLockNegotiation}
          />
        );
      case ModalType.VIEW_REPORTED_PROBLEMS:
        return (
          <ViewReportedProblemModal
            executions={executionsProblems}
            isOpen={isModalOpen}
            onClose={() => {
              closeModal();
              setExecutionsProblems([]);
            }}
          />
        );
      case ModalType.ADDRESSING:
        return (
          <AddressingModal
            isOpen={isModalOpen}
            isExpired={isExpired}
            negotiation={negotiation}
            onSubmit={onSubmitCreateExecution}
            data={executionToView}
            store={store}
            onDelete={onSubmitDeleteExecution}
            title={`${store?.name} (${store?.storeCode}) - ${spot.name}`}
            executions={currentExecutions}
            onClose={onExecutionModalClose}
            onUpdate={updateMetrics}
          />
        );
    }
  };

  useEffect(() => {
    setNegotiationList(negotiations);

    return () => {
      setNegotiationList([]);
    };
  }, [negotiations]);

  return (
    <>
      <TableContainer className={classes.tableWrapper}>
        <Table className={classes.table}>
          <NegotiationHeader stores={stores} />
          <TableBody>
            {negotiationList?.map((item) => (
              <NegotiationTableItem
                item={item}
                stores={stores}
                isEnded={isExpired}
                metricsInStore={metricsInStore}
                isPending={isPending}
                isLoading={isLoadingUpdate}
                isAvaliableOptionsInStore={isAvaliableOptionsInStore}
                isSelected={
                  selectedRows.filter((value) => value === item.id)?.length > 0
                }
                onAdd={onAddExecution}
                onRemove={onRemoveExecution}
                onClickProblem={handleViewProblem}
                onClickMore={handleOpenMoreOptions}
                onToggleLock={hanndleToggleLockAction}
                onDeleted={onSelectToDeleteNegotiation}
                onSelectRowHandler={onSelectRowHandler}
                onSelectEdit={onSelectToEditNegotiation}
              />
            ))}
            <AvailableRow
              stores={stores}
              metrics={metricsInStore}
              negotiations={negotiationList}
            />
          </TableBody>
        </Table>
      </TableContainer>

      <div className={classes.footer}>
        <Button
          disabled={isExpired}
          variant="outlined"
          startIcon={<Add />}
          className={classes.button}
          onClick={() => {
            openModal(ModalType.CREATE);
          }}
        >
          {dictionary.NEW_NEGOTIATION}
        </Button>
      </div>

      <MoreOptionsMenu
        isPending={isPending}
        showAddExecution={showAddExecution && !isExpired}
        anchorEl={menuAnchorEl}
        onAdd={() => {
          openModal(ModalType.ADDRESSING);
          setMenuAnchorEl(null);
        }}
        executions={executions}
        onClose={() => {
          setMenuAnchorEl(null);
          setShowAddExecution(false);
        }}
        onClick={(
          evt: React.MouseEvent<HTMLButtonElement>,
          execution: PromotionalNegotiationExecutionDto
        ) => {
          openModal(ModalType.ADDRESSING);
          setExecutionToView(execution);
          setMenuAnchorEl(evt.currentTarget);
        }}
      />
      {renderModal({
        modalType,
        updateItem,
        isModalOpen,
      })}
    </>
  );
};

export default NegotiationTable;
