import { isSameDay } from 'date-fns';
import { PromotionalSpotType } from '../../../core/types/promotional-spot/promotional-spot-type.enum';
import { PromotionalSpotTypeLabel } from '../../../core/types/promotional-spot/promotional-spot-type-label.enum';
import {
  PromotionalNegotiationPanelMetricsAddressingsDto,
  PromotionalNegotiationPanelMetricsDto,
  PromotionalNegotiationPanelMetricsExecutionsDto,
} from '../../../core/types/promotional-spot/promotional-negotiation-panel-metrics.dto';
import { FormFieldObject, ValidateCalendarPeriodsDto } from './types';
import * as dictionary from '../../../core/constants/dictionary';
import { AddCalendarPeriodForm } from './defaultPeriod';
import { PromotionalNegotiationExecutionProblemTypeEnum } from '../../../core/types/promotional-spot/promotional-negotiation-execution-problem-type.enum';
import { PromotionalNegotiationExecutionProblemTypeLabel } from '../../../core/types/promotional-spot/promotional-negotiation-execution-problem-type-label.enum';

export const validateCalendarPeriods = (
  newPeriod: AddCalendarPeriodForm,
  periods: AddCalendarPeriodForm[]
): ValidateCalendarPeriodsDto => {
  let isStartAtValid = true;
  let isEndAtValid = true;

  for (const analyzePeriod of periods) {
    if (analyzePeriod.id !== newPeriod.id) {
      if (
        isSameDay(analyzePeriod?.startAt as Date, newPeriod.startAt as Date)
      ) {
        isStartAtValid = false;
      }
      if (isSameDay(analyzePeriod.endAt as Date, newPeriod.endAt as Date)) {
        isEndAtValid = false;
      }
    }
  }
  return { isStartAtValid, isEndAtValid };
};

export const getDatepickerErrorDto = (
  isStartAtValid: boolean,
  isEndAtValid: boolean,
  endAt?: Date
): Pick<FormFieldObject, 'startAt' | 'endAt'> => {
  const endAtProperty = { message: '', hasError: false };
  const startAtProperty = { message: '', hasError: false };

  if (!isStartAtValid) {
    startAtProperty.hasError = true;
    startAtProperty.message = dictionary.ALREADY_ADDED_DATE;
  }
  if (!endAt) {
    endAtProperty.hasError = true;
    endAtProperty.message = dictionary.INVALID_END_DATE;
  } else if (!isEndAtValid) {
    endAtProperty.hasError = true;
    endAtProperty.message = dictionary.ALREADY_ADDED_DATE;
  }

  return {
    endAt: endAtProperty,
    startAt: startAtProperty,
  };
};

export const translatePromotionalSpotTypeToAPI = (value: string) => {
  const key = Object.keys(PromotionalSpotTypeLabel)[
    Object.values(PromotionalSpotTypeLabel).findIndex(
      (currentObjValue) => currentObjValue === value
    )
  ] as keyof typeof PromotionalSpotType;

  return PromotionalSpotType[key];
};

export const translatePromotionalNegotiationProblemTypeToAPI = (
  value: string
): PromotionalNegotiationExecutionProblemTypeEnum => {
  const key = Object.keys(PromotionalNegotiationExecutionProblemTypeLabel)[
    Object.values(PromotionalNegotiationExecutionProblemTypeLabel).findIndex(
      (currentObjValue) => currentObjValue === value
    )
  ] as keyof typeof PromotionalNegotiationExecutionProblemTypeEnum;

  return PromotionalNegotiationExecutionProblemTypeEnum[key];
};

export const translateAPIToPromotionalSpotType = (
  type: keyof typeof PromotionalSpotType
) => {
  return PromotionalSpotTypeLabel[type];
};

export const parseMetricsAddressingToCard = (
  params: PromotionalNegotiationPanelMetricsAddressingsDto
) => {
  const total = params.total;
  const count = params.total - params.avaliable;

  return {
    left: {
      total,
      count: count,
      showTotal: true,
    },
    right:
      total && count
        ? parseFloat(((count / params.total) * 100).toFixed(1))
        : 0,
  };
};

export const parseMetricsExecutionToCard = (
  params: PromotionalNegotiationPanelMetricsExecutionsDto
) => ({
  left: {
    count: params.executed,
    total: params.total,
    showTotal: true,
  },
  right: params.total
    ? parseInt(((params.executed / params.total) * 100).toFixed(1))
    : 0,
});

export const parseMetricsNotExecutedToCard = (
  params: PromotionalNegotiationPanelMetricsDto
) => {
  const count = params.addressings.total - params.addressings.avaliable;

  return {
    left: {
      count: params.executions.expired,
      total: count - params.executions.executed,
      showTotal: false,
    },
    right: {
      count: params.executions.pending,
      total: count - params.executions.executed,
      showTotal: false,
    },
  };
};

export const parsePanelMetrics = (
  params: PromotionalNegotiationPanelMetricsDto
) => [
  {
    title: dictionary.PROMOTIONAL_SPOTS_HAS_NEGOTIATED,
    label: {
      left: dictionary.PROMOTIONAL_SPOTS_HAS_NEGOTIATED_OCUPATION,
      right: dictionary.PROMOTIONAL_SPOTS_HAS_NEGOTIATED_OCUPATION_PERCENT,
    },
    values: parseMetricsAddressingToCard(params.addressings),
  },
  {
    title: dictionary.PROMOTIONAL_EXECUTION,
    label: {
      left: dictionary.PROMOTIONAL_EXECUTION_OCUPATION,
      right: dictionary.PROMOTIONAL_EXECUTION_OCUPATION_PERCENT,
    },
    values: parseMetricsExecutionToCard(params.executions),
  },
  {
    title: dictionary.PROMOTIONAL_SPOTS_NOT_EXECUTED,
    label: {
      left: dictionary.PROMOTIONAL_SPOTS_EXPIRED,
      right: dictionary.PROMOTIONAL_SPOTS_PENDING,
    },
    values: parseMetricsNotExecutedToCard(params),
  },
];

export const translatePromotionalNegotiationExecutionProblemTypeAPIToString = (
  problemType: PromotionalNegotiationExecutionProblemTypeEnum | undefined
): string => {
  if (!problemType) {
    return '';
  }
  
  switch (problemType) {
    case PromotionalNegotiationExecutionProblemTypeEnum.SPOT_NOT_EXIST:
      return dictionary.NOT_EXISTENT_SPOT;
    case PromotionalNegotiationExecutionProblemTypeEnum.MARKET_MATERIAL_NOT_AVALIABLE:
      return dictionary.MISSION_MERCHANDISING_CONTENT;
    case PromotionalNegotiationExecutionProblemTypeEnum.PRODUCT_NOT_AVALIABLE:
      return dictionary.LACK_OF_PRODUCT;
    case PromotionalNegotiationExecutionProblemTypeEnum.NOT_VISITED_BY_PROMOTER:
      return dictionary.NO_PROMOTER_VISIT; 
    case PromotionalNegotiationExecutionProblemTypeEnum.INSUFFICIENT_STOCK:
      return dictionary.NO_PROMOTER_VISIT; 
  }
}
