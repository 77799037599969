import React, { useEffect, useState } from 'react';
import brLocale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import { differenceInDays } from 'date-fns';
import { DialogActions, DialogContent } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import ModalTitle from '../../../../../components/Modal/Title';
import ModalContainer from '../../../../../containers/Modal/Container';
import { CancelButton, DefaultButton } from '../../../../../components/Buttons';
import { CalendarPeriodTableRow } from '../../../common/types';
import {
  defaultFormFields,
  getDatepickerErrorDto,
  validateCalendarPeriods,
} from '../../../common';
import * as dictionary from '../../../../../core/constants/dictionary';
import classes from './EditCalendarPeriodModal.module.scss';
import { dateForGMTWithoutTimezone } from '../../../../../core/constants/dates';

type Props = {
  isOpen: boolean;
  period: CalendarPeriodTableRow;
  periods: CalendarPeriodTableRow[];
  onClose: Function;
  onUpdatePeriod: Function;
};

const EditCalendarPeriodModal = ({
  isOpen,
  period,
  periods,
  onClose,
  onUpdatePeriod,
}: Props) => {
  const [calendarPeriod, setCalendarPeriod] =
    useState<CalendarPeriodTableRow>(period);
  const [formFields, setFormFields] = useState(defaultFormFields);

  const onClickButtonHandler = () => {
    if (
      calendarPeriod.startAt &&
      calendarPeriod.endAt &&
      calendarPeriod.negotiatedAt
    ) {
      const { isEndAtValid, isStartAtValid } = validateCalendarPeriods(
        calendarPeriod,
        periods
      );

      if (isEndAtValid && isStartAtValid) {
        const { startAt, endAt } = calendarPeriod;
        calendarPeriod.duration = differenceInDays(endAt as Date, startAt);
        onUpdatePeriod(calendarPeriod);
      } else {
        setFormFields((prevState) => ({
          ...prevState,
          ...getDatepickerErrorDto(
            isStartAtValid,
            isEndAtValid,
            calendarPeriod.endAt
          ),
        }));
      }
    }
  };

  useEffect(() => {
    setCalendarPeriod(period);
    setFormFields(defaultFormFields);
  }, [isOpen]);

  return (
    <ModalContainer isOpen={isOpen} classes={{ paper: classes.dialog }}>
      <ModalTitle title={dictionary.UPDATE_CALENDAR_PERIOD} />
      <DialogContent>
        <div className={classes.datepickerGrid}>
          <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              disableToolbar
              margin="normal"
              variant="inline"
              format="dd/MM/yyyy"
              minDate={new Date()}
              label={dictionary.LIMIT_CALENDAR}
              value={calendarPeriod.negotiatedAt}
              error={formFields.negotiatedAt.hasError}
              helperText={formFields.negotiatedAt.message}
              invalidDateMessage={dictionary.INVALID_DATE}
              InputProps={{ readOnly: true }}
              onChange={(date: MaterialUiPickersDate) => {
                if (date) {
                  setCalendarPeriod((prevState: any) => ({
                    ...prevState,
                    negotiatedAt: dateForGMTWithoutTimezone(date),
                  }));
                }
              }}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              disableToolbar
              margin="normal"
              variant="inline"
              format="dd/MM/yyyy"
              minDate={new Date()}
              maxDate={calendarPeriod.endAt ? calendarPeriod.endAt : undefined}
              value={calendarPeriod.startAt}
              error={formFields.startAt.hasError}
              helperText={formFields.startAt.message}
              label={dictionary.DATE_START_SELECT}
              invalidDateMessage={dictionary.INVALID_DATE}
              InputProps={{ readOnly: true }}
              onChange={(date: MaterialUiPickersDate) => {
                if (date) {
                  setCalendarPeriod((prevState: any) => ({
                    ...prevState,
                    startAt: dateForGMTWithoutTimezone(date),
                  }));
                }
              }}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              disableToolbar
              margin="normal"
              variant="inline"
              format="dd/MM/yyyy"
              value={calendarPeriod.endAt}
              minDate={
                calendarPeriod.startAt ? calendarPeriod.startAt : undefined
              }
              error={formFields.endAt.hasError}
              helperText={formFields.endAt.message}
              invalidDateMessage={dictionary.INVALID_DATE}
              label={dictionary.DATE_END_SELECT}
              InputProps={{ readOnly: true }}
              onChange={(date: MaterialUiPickersDate) => {
                if (date) {
                  setCalendarPeriod((prevState: any) => ({
                    ...prevState,
                    endAt: dateForGMTWithoutTimezone(date),
                  }));
                }
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </DialogContent>

      <DialogActions classes={{ root: classes.dialogFooter }}>
        <CancelButton onClick={onClose} />
        <DefaultButton
          label={dictionary.CONFIRM}
          style={{ width: 'auto' }}
          onClick={onClickButtonHandler}
        />
      </DialogActions>
    </ModalContainer>
  );
};

export default EditCalendarPeriodModal;
