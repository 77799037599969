import React, { MouseEventHandler, useMemo } from 'react';
import { Button, DialogActions, Typography } from '@material-ui/core';
import classes from './Modals.module.scss';
import * as dictionary from '../../../../../../../core/constants/dictionary';
import { PromotionalNegotiationExecutionInConflictQueryDto } from './types';
import { groupStoresByExecution, parseLabelForConflict } from './utils';
import { CancelButton } from '../../../../../../../components/Buttons';
import ModalTitle from '../../../../../../../components/Modal/Title';
import ModalSmallContainer from '../../../../../../../components/Modal/SmallContainer';

type Props = {
  executionsToOverride: PromotionalNegotiationExecutionInConflictQueryDto[];
  isOpen: boolean;
  onClose: Function;
  onOverride: MouseEventHandler;
};

const ListItem = ({ item }: { item: string }) => (
  <li key={item} className={classes.item}>
    <Typography>{item}</Typography>
  </li>
);

type ListItemProps = {
  label: string;
  value: string[];
};

const List = ({ label, value = [] }: ListItemProps) => (
  <>
    <Typography className={classes.title}>
      {parseLabelForConflict(label)}
    </Typography>
    <ul>
      {value.map((item) => (
        <ListItem item={item} />
      ))}
    </ul>
  </>
);

const OverrideModal = ({
  isOpen,
  onClose,
  onOverride,
  executionsToOverride,
}: Props) => {
  const conflictListItems = useMemo(
    () =>
      Array.from(groupStoresByExecution(executionsToOverride).entries()).map(
        ([key, value]) => <List label={key} key={key} value={value} />
      ),
    [executionsToOverride]
  );

  return (
    <ModalSmallContainer isOpen={isOpen} closeModalCallback={onClose}>
      <ModalTitle
        title={dictionary.OVERRIDE_NEGOTIATION}
        className={classes.modalTitle}
      />
      <div className={classes.formHeader}>
        <Typography className={classes.caption}>
          {dictionary.OVERRIDE_NEGOTIATION_MESSAGE}
        </Typography>
      </div>
      <div className={classes.overflowModalBody}>
        <div className={classes.overrideModal}>{conflictListItems}</div>
      </div>

      <DialogActions className={classes.footer}>
        <CancelButton label={dictionary.CANCEL} onClick={onClose} />
        <Button color="primary" variant="contained" onClick={onOverride}>
          {dictionary.CONFIRM}
        </Button>
      </DialogActions>
    </ModalSmallContainer>
  );
};

export default OverrideModal;
