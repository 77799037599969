import React, { useMemo } from 'react';
import { view } from 'react-easy-state';
import { MTableToolbar } from 'material-table';
import SearchTable from '../../components/Tables/SearchTable';
import PatchedPagination from '../../components/PatchedPagination';
import { EmptyDataMessageRow } from '../../components/Tables/DefaultTable';
import {
  getDayOfWeek,
  getFormattedDate,
  getTime,
  getDifferenceBetweenISODates,
} from '../../core/constants/dates';
import { utils } from '../../core/utils';
import { profileCheck } from '../../core/profiles';
import * as dictionary from '../../core/constants/dictionary';

const VisitsHistoryTable = React.forwardRef(
  (
    {
      data,
      count,
      pageIndex,
      rowsPerPage,
      searchTextValue,
      toolbarInputFocus,
      onChangePageHandler,
      onSearchChangeHandler,
      onChangeRowsPerPageHandler,
    },
    ref) => {
    const columns = [
      {
        title: dictionary.STORE,
        field: 'storeName',
        id: 'storeName',
        orderByField: 'storeName',
      },
      {
        field: 'supplierNames',
        id: 'supplierNames',
        orderByField: 'supplierNames',
        title: dictionary.SUPPLIERS,
      },
      {
        field: 'checkinAt',
        id: 'checkinAt',
        orderByField: 'checkinAt',
        title: dictionary.DATE,
        render: (rowData) => {
          return getFormattedDate(new Date(rowData.checkinAt), rowData.timezone);
        },
      },
      {
        id: 'checkinAtWeekDay',
        field: 'checkinAt',
        orderByField: 'checkinAtWeekDay',
        title: dictionary.DAY_OF_WEEK,
        render: (rowData) => {
          const dayOfWeek = getDayOfWeek(
            new Date(rowData.checkinAt),
            rowData.timezone
          );
          return dayOfWeek ? utils.capitalizeFirstLetter(dayOfWeek) : '';
        },
      },
      {
        id: 'checkinAtTime',
        field: 'checkinAt',
        orderByField: 'checkinAtTime',
        title: dictionary.CHECK_IN,
        render: (rowData) => {
          return rowData.checkinAt
            ? getTime(new Date(rowData.checkinAt), rowData.timezone)
            : '';
        },
      },
      {
        id: 'checkoutAtTime',
        field: 'checkoutAt',
        orderByField: 'checkoutAt',
        title: dictionary.CHECK_OUT,
        render: (rowData) => {
          return rowData.checkoutAt
            ? getTime(new Date(rowData.checkoutAt), rowData.timezone)
            : '';
        },
      },
      {
        id: 'total',
        title: dictionary.TOTAL,
        orderByField: 'duration',
        render: (rowData) => {
          return getDifferenceBetweenISODates(
            rowData.checkinAt,
            rowData.checkoutAt
          );
        },
      },
      {
        title: dictionary.PROMOTER_NAME,
        field: 'promoterName',
        id: 'promoterName',
        orderByField: 'promoterName',
      },
      {
        id: 'ratingScore',
        field: 'ratingScore',
        orderByField: 'ratingScore',
        title: dictionary.SCORE,
      },
    ];

    const renderColumns = useMemo(() => {
      if (profileCheck.isSuperAdmin() || profileCheck.isSalesRep()) {
        columns.unshift({
          title: dictionary.RETAIL,
          field: 'retailName',
          id: 'retailName',
          orderByField: 'retailName',
        });
      }
      return columns;
    }, [columns]);

    return (
      <SearchTable
        ref={ref}
        data={data}
        columns={renderColumns}
        localization={{
          body: {
            emptyDataSourceMessage:
              <EmptyDataMessageRow
                message={dictionary.EMPTY_VISIT_TABLE}
              />,
          },
        }}
        components={{
          Pagination: (props) => {
            return (
              <PatchedPagination
                {...props}
                count={count}
                page={pageIndex}
                rowsPerPage={rowsPerPage}
                onChangePage={(evt, page) => onChangePageHandler(page)}
                onChangeRowsPerPage={(event) => onChangeRowsPerPageHandler(parseInt(event.target.value))}
              />
            );
          },
          Toolbar: (props) => {
            if (onSearchChangeHandler) {
              return (
                <MTableToolbar
                  {...props}
                  searchAutoFocus={toolbarInputFocus}
                  searchText={searchTextValue}
                  onSearchChanged={(value) => onSearchChangeHandler(value)}
                />
              );
            }
            else return <MTableToolbar {...props} />;
          },

        }}
      />
    );
  });

export default view(VisitsHistoryTable);
