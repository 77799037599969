export const PROMOTERS = '/Promotores';
export const AGREEMENTS = '/Acordos';
export const PROMOTERS_SUSPENDED = '/Promotores?status=2';
export const PROMOTERS_LIBERATE = '/Promotores?status=1';
export const PROMOTERS_PENDENT = '/Promotores?status=0';
export const PROMOTERS_REGISTER = '/CadastroDePromotores';
export const STORES = '/Lojas';
export const USERS = '/Usuarios';
export const LOGIN = '/Login';
export const REGISTER = '/CadastroSalesRep';
export const CREATE_PASSWORD_RESET_TOKEN = '/EsqueciMinhaSenha';
export const CHANGE_PASSWORD = '/MudarSenha/:passwordResetToken';
export const RETAILS = '/Redes';
export const VISITS = '/Visitas';
export const VISITS_HISTORY = '/HistoricoVisitas';
export const VISITS_AUTO_CHECK_IN = '/AutoCheckin';
export const PRIVACITY_POLICY = '/PoliticasDePrivacidade';
export const PROMOTER_TERMS_OF_USE = '/TermosDeUsoDoPromotor';
export const SALESREP_TERMS_OF_USE = '/TermosDeUsoDoSalesRepEIndustria';
export const CHANGE_PASSWORD_URL = '/MudarSenha';
export const RESTOCK = '/Abastecimento';
export const RESTOCK_REPORTS = '/RelatoriosDeAbastecimento';
export const RESTOCK_PANEL = '/PainelDeAbastecimento';
export const SUSPICIOUS_PRODUCTS = '/ProdutosSupeitos';
export const VISITS_PANEL = '/PainelDeVisitas';
export const PROMOTIONAL_SPOTS_GROUP = '/PontosPromocionaisGroup';
export const PROMOTIONAL_SPOTS = '/PontosPromocionais';
export const PROMOTIONAL_CALENDARS = '/CalendariosPromocionais';
export const ADDRESSINGS = '/Enderecamentos';
export const CREATE_PROMOTIONAL_CALENDAR = '/NovoCalendarioPromocional';
export const EDIT_PROMOTIONAL_CALENDAR = '/EditarCalendarioPromocional';
export const EXPIRATION_PANEL = '/PainelDeValidade';
export const NEGOTIATIONS_PANEL = '/PainelDeNegociacoes';
export const NEGOTIATIONS_REPORT = '/RelatorioDeNegociacoes';
export const SUPPLIERS = '/Fornecedores';
export const NEGOTIATIONS_PRODUCT_REPORT = '/RelatorioDeProdutosEmNegociacoes';
export const NEGOTIATIONS_PROBLEMS_REPORT =
  '/RelatorioDeProblemasEmNegociacoes';
