import React, { useMemo, useRef } from 'react';
import MaterialTable from 'material-table';
import Edit from '../../../../../assets/tables/edit.svg';
import Remove from '../../../../../assets/tables/remove.svg';
import IconTable from '../../../../../components/Tables/IconTable';
import { EmptyDataMessageRow } from '../../../../../components/Tables/DefaultTable';
import * as dictionary from '../../../../../core/constants/dictionary';
import { CalendarPeriodTableRow } from '../../types';
import classes from './CalendarPeriodsTable.module.scss';

type Props = {
  data: CalendarPeriodTableRow[];
  onEditCalendarHandler: Function;
  onDeleteCalendarHandler: Function;
};

const CalendarPeriodsTable = ({
  data,
  onEditCalendarHandler = () => {},
  onDeleteCalendarHandler = () => {},
}: Props) => {
  const tableRef = useRef(null);
  const actions = useMemo(() => {
    return [
      (rowData: object) => {
        return {
          icon: () => <IconTable icon={Edit} />,
          tooltip: dictionary.EDIT_REGISTERED_PERIOD,
          onClick: (evt: any, rowData: any) => onEditCalendarHandler(rowData),
        };
      },
      (rowData: object) => {
        return {
          icon: () => <IconTable icon={Remove} />,
          tooltip: dictionary.DELETE_REGISTERED_PERIOD,
          onClick: (evt: any, rowData: any) => onDeleteCalendarHandler(rowData),
        };
      },
    ];
  }, [onEditCalendarHandler, onDeleteCalendarHandler]);

  // to-do: ver overflowY e scrollbar
  // https://material.io/components/data-tables/web#style-customization
  return (
    <div className={classes.tableContainer}>
      <MaterialTable
        title={''}
        data={data}
        key={data.length}
        actions={actions}
        tableRef={tableRef}
        columns={[
          {
            field: 'negotiatedAt',
            title: dictionary.NEGOTIATION_LIMIT,
            // sorting: true,
            type: 'date',

            // defaultSort: 'asc',
            cellStyle: { paddingLeft: '0px' },
            headerStyle: {
              color: '#8A90A7',
              paddingLeft: '0px',
            },
          },
          {
            field: 'startAt',
            type: 'date',
            title: dictionary.DATE_START_SELECT,
          },
          {
            field: 'endAt',
            type: 'date',
            title: dictionary.DATE_END_SELECT,
          },
          {
            field: 'duration',
            title: dictionary.DAILY_DURATION,
            type: 'numeric',
            align: 'left',
            editable: 'never',
            sorting: false,
          },
        ]}
        options={{
          search: false,
          paging: false,
          toolbar: false,
          actionsColumnIndex: -1,
        }}
        localization={{
          header: { actions: '' },
          body: {
            emptyDataSourceMessage: (
              <EmptyDataMessageRow
                message={dictionary.EMPTY_CALENDAR_PERIODS_TABLE}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '180px',
                }}
              />
            ),
          },
        }}
        style={{ boxShadow: 'none' }}
      />
    </div>
  );
};

export default CalendarPeriodsTable;
