import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { view } from 'react-easy-state';
import { Typography } from '@material-ui/core';
// @ts-ignore
import * as R from 'ramda'; 
import { RouteComponentProps } from 'react-router-dom';
import Loading from '../../components/Loading';
import WelcomePage from '../../components/WelcomePage';
import ModalContainer from '../../components/Modal/Container';
import PageContent from '../../components/PageContent';
import { mainStore } from '../../core/store/mainStore';
import { useDebounce } from '../../core/hooks';
import { getStatusStringValue } from '../../core/profiles';
import { getPromoterAccesses } from '../../core/services/users/usersService';
import { DEBOUNCE_DELAY_ON_FETCH_USER } from '../../core/constants/user';
import { utils, serializeCpfAndNameSearch } from '../../core/utils';
import {
  updatePromoterByAgreement,
  removePromoterByAgreement,
} from '../../core/services/agreements/agreementsService';
import {
  trackPromoterRetailAccessAuthorization,
  trackPromoterRetailAccessRevoke,
  trackPromoterRetailAccessDelete,
} from '../../core/mixpanel/mixPanelEvents';
import * as dictionary from '../../core/constants/dictionary';
import { DeleteContentModal } from './modals/DeleteContentModal/DeleteContentModal';
import { DetailContentModal } from './modals/DetailContentModal/DetailContentModal';
import { PromotersAccessTable } from './components/PromoterAccessTable';
import './styles.scss';

type Props = {
  history: RouteComponentProps
}

const Promoters = ({ history }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [currentPromoter, setCurrentPromoter] = useState<any>(null);
  const [promotersData, setPromotersData] = useState<any[]>([]);
  const [status, setStatus] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [nameOrCpfPart, setNameOrCpfPart] = useState("");
  const [toolbarInputFocus, setToolbarInputFocus] = useState(false);
  const [hasInitialContent, setHasInitialContent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(promotersData.length > 0 && !hasInitialContent) {
      setHasInitialContent(true);
    }
  }, [promotersData, hasInitialContent]);

  const fetchPromoterAccesses = useCallback(async () => {
    setIsLoading(true);
    try {
      const offset = page * rowsPerPage;
      const limit = rowsPerPage;
      const urlParam = new URLSearchParams(history.location.search);
      mainStore.promoters.queryParams = urlParam.toString();
      const params = new URLSearchParams(mainStore.promoters.queryParams);
      
      const response = await getPromoterAccesses({
        params, 
        retailId: mainStore.loggedUser.retailId, 
        offset, 
        limit,
        query: serializeCpfAndNameSearch(nameOrCpfPart)
      });
      
      setTotal(response.count)
      setPromotersData(response.items);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }, [history, page, rowsPerPage, nameOrCpfPart]);

  useEffect(() => {
    fetchPromoterAccesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, status]);

  useEffect(() => {
    if (nameOrCpfPart.length >=3 || nameOrCpfPart.length === 0) {
      fetchPromoterAccesses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameOrCpfPart]);


  const updatePromoter = useCallback(async formData => {
    const payload = {
      promoterUserId: formData.promoterUserId,
      status: formData.status,
    };
    const res = await updatePromoterByAgreement(
      formData.agreementId,
      formData.promoterId,
      payload,
    );
    fetchPromoterAccesses();
    
    return res;
  }, [fetchPromoterAccesses]);

  const closeModal = useCallback(() => {
    setOpenModal(false);
    setModalType(null);
    setCurrentPromoter(null);
    //fetchPromoterAccesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitPromoter = useCallback(
    async (formData, successMsg) => {
      setIsLoading(true);
      try {
        const res = await updatePromoter(formData);
        if (res.status === 200) {
          closeModal();
          utils.openSnackBar(dictionary.SUCCESS, successMsg);
          if (successMsg === dictionary.PROMOTER_ACCESS_APROVED) {
            trackPromoterRetailAccessAuthorization({
              suppliers: formData.agreementSupplierNames.split(','),
              cpf: formData.promoterUserCpf,
            });
          }
          if (successMsg === dictionary.PROMOTER_ACCESS_BLOCKED) {
            trackPromoterRetailAccessRevoke({
              suppliers: formData.agreementSupplierNames.split(','),
              cpf: formData.promoterUserCpf,
            });
          }
        } else {
          utils.openSnackBar(dictionary.ERROR, dictionary.UNKNOWN_ERROR);
        }
        setIsLoading(false);
        return res;
      } catch (error) {
        if (
          R.hasPath(['response', 'data'], error) &&
          R.propEq(
            'message',
            'Inactive users can not be approved into linked  agreements',
          )(error.response.data)
        ) {
          utils.openSnackBar(
            'error',
            'Não é possível liberar um promotor inativo em um acordo',
          );
        } else {
          utils.openSnackBar(dictionary.ERROR, dictionary.UNKNOWN_ERROR);
        }
      }
    },
    [closeModal, updatePromoter],
  );

  const allowPromoterAccess = useCallback(
    async promoter => {
      const thePromoter = promoter;
      thePromoter.status = 1;
      return submitPromoter(
        thePromoter,
        dictionary.ACCESS_RELEASED_FOR_AGREEMENT,
      );
    },
    [submitPromoter],
  );

  const removePromoterAccess = useCallback(
    async promoter => {
      const thePromoter = promoter;
      thePromoter.status = 2;
      return submitPromoter(
        thePromoter,
        dictionary.ACCESS_SUSPENDED_FOR_AGREEMENT,
      );
    },
    [submitPromoter],
  );

  const handleDeleteAccess = useCallback(() => {
    const updatedPromotersData = promotersData.filter(
      p => currentPromoter.promoterId !== p.promoterId,
    );
    setPromotersData(updatedPromotersData);
  }, [currentPromoter, promotersData]);

  const removePromoter = useCallback(
    async currentPromoter => {
      try {
        setIsLoading(true);
        
        await removePromoterByAgreement(
          currentPromoter.agreementId,
          currentPromoter.promoterId,
        );
        trackPromoterRetailAccessDelete({
          suppliers: currentPromoter.agreementSupplierNames.split(','),
          cpf: currentPromoter.promoterUserCpf,
          status: getStatusStringValue(currentPromoter.status),
        });

        handleDeleteAccess();
        utils.openSnackBar(dictionary.SUCCESS, dictionary.PROMOTER_REMOVED);
      } catch (err) {
        utils.openSnackBar(dictionary.ERROR, dictionary.PROMOTOR_ACCESS_ERROR);
      } finally {
        setIsLoading(false);
      }
    },
    [handleDeleteAccess],
  );

  const promoterAccessDetailModal = useMemo(() => {
    return (
      <ModalContainer closeModalCallback={closeModal} isOpen={openModal}>
        <DetailContentModal
          allowPromoterAccess={allowPromoterAccess}
          removePromoterAccess={removePromoterAccess}
          promoter={currentPromoter}
          closeModalCallback={closeModal}
        />
      </ModalContainer>
    );
  }, [
    allowPromoterAccess,
    closeModal,
    currentPromoter,
    openModal,
    removePromoterAccess,
  ]);

  const deletePromoterAccessModal = useMemo(() => {
    return (
      <ModalContainer closeModalCallback={closeModal} isOpen={openModal}>
        <DeleteContentModal
          currentPromoter={currentPromoter}
          closeModalCallback={closeModal}
          removePromoter={removePromoter}
        />
      </ModalContainer>
    );
  }, [closeModal, currentPromoter, openModal, removePromoter]);

  const renderModal = useMemo(() => {
    const modals = {
      [dictionary.ACCESS as string]: promoterAccessDetailModal,
      [dictionary.DELETE as string]: deletePromoterAccessModal,
    };
    return modals[String(modalType)];
  }, [promoterAccessDetailModal, deletePromoterAccessModal, modalType]);

  const handleOpenModal = useCallback((modalType, currentPromoter) => {
    setModalType(modalType);
    setOpenModal(true);
    setCurrentPromoter(currentPromoter);
  }, []);

  const params = useMemo(() => {
    return new URLSearchParams(history.location.search);
  }, [history.location.search]);

  const pageTitle = useMemo(() => {
    if (params.has('status')) {
      const status = params.get('status');
      setStatus(status)
      if (status === '2') {
        return dictionary.SUSPENDED_ACCESSES;
      }
      if (status === '1') {
        return dictionary.LIBERATE_ACCESS;
      }
    }
    return dictionary.ACCESSES;
  }, [params]);

  const welcomeText = useMemo(() => {
    if (params.has('status')) {
      const status = params.get('status');
      if (status === '2') {
        return dictionary.NO_SUSPENDED_PROMOTER_ACCESSES;
      }
      if (status === '1') {
        return dictionary.NO_APROVED_PROMOTER_ACCESSES;
      }
    }
    return dictionary.NO_PROMOTER_ACCESSES;
  }, [params]);

  const snackBar = utils.getSnackbar();

  const debouncedOnSearch = useDebounce(async (inputValue: string[]) => {
    const searchValue = String(inputValue[0]);
    setPage(0);
    setNameOrCpfPart(searchValue);
  }, DEBOUNCE_DELAY_ON_FETCH_USER);

  const pageContent = useMemo(() => {
    if (promotersData.length === 0 && !hasInitialContent) {
      return (
        <WelcomePage>
          <Typography variant="body1">{ welcomeText }</Typography>
        </WelcomePage>
      );
    }

    return (
      <div className="reverse-table">
        <PromotersAccessTable
          key={`#${promotersData.length}${nameOrCpfPart}`}
          data={promotersData}
          handleOpenModal={handleOpenModal}
          pageType={pageTitle}
          count={total}
          pageIndex={page}
          rowsPerPage={rowsPerPage}
          searchTextValue={nameOrCpfPart}
          toolbarInputFocus={toolbarInputFocus}
          onChangePageHandler={(page: number) => setPage(page)}
          onChangeRowsPerPageHandler={(value: number) => {
            setPage(0);
            setRowsPerPage(value);
          }}
          onSearchChangeHandler={(value: any) => debouncedOnSearch(value)}
        />
      </div>
    );
  }, [
    page, 
    total,
    pageTitle, 
    rowsPerPage, 
    welcomeText,
    nameOrCpfPart,
    promotersData, 
    toolbarInputFocus,
    hasInitialContent,
    handleOpenModal, 
    debouncedOnSearch,
  ]);

  useEffect(() => {
    const shouldFocus = nameOrCpfPart.length > 0;
    setToolbarInputFocus(shouldFocus);
  }, [nameOrCpfPart]);


  return (
    <PageContent title={pageTitle}>
      {pageContent}

      {openModal && renderModal}
      {snackBar}
      
      <Loading isOpen={isLoading} />
    </PageContent>
  );
};

export default view(Promoters);
