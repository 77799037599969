import React from 'react';
import classes from '../styles.module.scss';

export default () => (
  <section className={classes.section}>
    <h2 className={classes.title}>RETENÇÃO E ELIMINAÇÃO DE DADOS</h2>

    <p className={classes.doubleMargin}>
      Nós conservamos os dados pessoais dos titulares apenas durante o
      período estritamente necessário à prossecução da finalidade para a
      qual estes foram recolhidos.
      <br />
      
      Se você se registrar no nosso Site e/ou Aplicativo, as informações da
      sua conta serão mantidas enquanto você mantiver ela ativa.
      <br />

      Em certos casos, a lei obriga à conservação de dados durante um
      período específico, nomeadamente no caso de dados necessários para
      informação à Autoridade Tributária, os quais serão conservados
      conforme legislação em vigor.
      <br />

      Ainda, se você solicitar a exclusão dos seus dados e/ou da sua conta,
      a Involves excluirá os seus dados do Aplicativo ou os sujeitarão à
      anonimização, a menos que a sua manutenção seja determinada com base
      em obrigações legais ou requisição de autoridades competentes, bem
      como se houver um problema não solucionado relacionado à sua conta,
      como uma reclamação ou litígio em curso, ao qual procederemos à
      retenção dos dados pessoais necessários até que a questão seja
      resolvida.
    </p>
  </section>
);
