import React, { useState } from 'react';
import { TextField, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import * as dictionary from '../../core/constants/dictionary';

const CssTextField = withStyles(theme => theme.sizes.defaultTextField)(
  TextField,
);
const useStyles = makeStyles(theme => ({
  margin: theme.sizes.defaultInputsMarginSize,
}));

export function SearchField({
  options,
  handleUpdate,
  getOptionLabel,
  label,
  filterOptions,
  disabled,
  loading,
  onInputChange,
  noOptionsText,
  ...props
}) {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');

  const emptyMessage = (noOptionsText) ? noOptionsText : dictionary.NO_OPTION


  return (
    <Autocomplete
      {...props}
      noOptionsText={emptyMessage}
      clearOnEscape
      style={{ width: '100%' }}
      options={options}
      getOptionLabel={option => getOptionLabel(option)}
      getOptionSelected={(option, value) => {
        return option.name === value.name;
      }}
      filterOptions={filterOptions}
      onInputChange={event => {
        if (event !== null) {
          setInputValue(event.target.value);
          if (onInputChange) {
            onInputChange(event.target.value)
          }
        }
      }}
      onChange={(event, autocompleteValue) => {
        handleUpdate(autocompleteValue);
      }}
      onClose={() => {
        setInputValue('');
      }}
      inputValue={inputValue}
      disabled={disabled}
      loading={loading}
      loadingText="Carregando..."
      renderInput={params => {
        return (
          <CssTextField
            {...params}
            style={{ width: '100%' }}
            className={classes.margin}
            label={label}
            variant="outlined"
            fullWidth
            inputProps={{
              ...params.inputProps,
              autoComplete: 'disabled',
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>{loading ? <CircularProgress size={20} /> : null}</>
              ),
            }}
          />
        );
      }}
    />
  );
}

export const MemorizedSearchField = React.memo(SearchField);
