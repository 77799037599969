import ReactPDF from '@react-pdf/renderer';
const { StyleSheet } = ReactPDF;

export default StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#FFFFFF',
      paddingTop: 30,
      justifyContent: 'space-between',
    },
    section: {
      margin: 1,
      padding: 1,
      marginBottom: 10,
    },
    regularText: {
      fontSize: '8pt',
      color: '#000000',
      fontFamily: 'OpenSansRegular',
    },
    boldText: {
      fontSize: '8pt',
      color: '#000000',
      fontFamily: 'OpenSansBold',
    },
    boldTitleText: {
      fontSize: '10pt',
      color: '#000000',
      fontFamily: 'OpenSansBold',
    },
    titleText: {
      fontSize: '9pt',
      color: '#000000',
      fontFamily: 'OpenSansRegular',
    },
    titleContainer: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    subTitleContainer: {
      flexDirection: 'column',
      width: '100%',
      paddingTop: 8,
      paddingHorizontal: 30,
    },
    dataSection: {
      flexDirection: 'column',
      width: '100%',
      paddingVertical: 10,
      paddingHorizontal: 20,
    },
    rowFormSection: {
      flexDirection: 'row',
      width: '100%',
      borderTop: '1pt solid #000',
      paddingVertical: 10,
    },
    footerSection: {
      flexDirection: 'column',
      width: '100%',
      paddingHorizontal: 20,
      marginBottom: 20,
      alignItems: 'flex-end',
      alignContent: 'flex-end',
      justifyContent: 'flex-end',
      justifyItems: 'flex-end',
    },
    barcodeColumn: {
        width: '110px',
    },
    barcodeImage: {
        width: '100px',
    },
    textColumn: {
        width: '250px',
        justifyItems: 'start',
        flexDirection: 'column',
    },
    productEanWrapper: {
        width: '100px',
        alignItems: 'center',
    },
    productEan: {
        fontSize: '8pt',
        color: '#000000',
        fontFamily: 'OpenSansRegular',
        marginTop: '2px',
    },
    productInternalCodeWrapper: { 
        justifyContent: 'start', 
        marginBottom: 10 
    },
    productNameWrapper: { 
        justifyContent: 'start', 
        marginBottom: 10 
    },
    productGroupWrapper: { 
        justifyContent: 'start', 
        marginBottom: 10 
    },
    totalProductUnitsNeededWrapper: { 
        justifyContent: 'start' 
    },
    totalStockWrapper: {
        marginTop: 10,
        marginBottom: 5,
    },
    checkboxAndRadioColumn: {
        width: '250px',
        alignItems: 'center',
        flexDirection: 'column',
    },
    checkboxWrapper: {
        width: '100%',
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: 5,
    },
    checkboxSquare: {
        width: '15px',
        justifyContent: 'start',
        marginRight: 10,
    },
    checkboxSquareImage: { 
        width: '15px', 
        padding: 2 
    },
    checkboxLabel: { 
        width: '80%', 
        justifyContent: 'start' 
    },
    checkboxWrapperLastChild: {
        width: '100%',
        alignItems: 'center',
        flexDirection: 'row',
    },
    radioOptionsWrapper: {
        width: '100%',
        alignItems: 'end',
        flexDirection: 'row',
        paddingLeft: 20,
        marginTop: 10,
    },
    radioOptionsTitle: { 
        width: '80%', 
        justifyContent: 'start' 
    },
    radioButtonImage: { 
        width: '15px', 
        padding: 2 
    },
    radioListWrapper: {
        width: '100%',
        alignItems: 'center',
        flexDirection: 'row',
        paddingLeft: 20,
        marginTop: 10,
    },
    radioWrapper: {
        width: '15px',
        justifyContent: 'start',
        marginRight: 5
    },
    radioLabelYes: { 
        width: '15px',
        justifyContent: 'start',
        marginRight: 10,
    },
    radioLabelNo: { 
        width: '15px', 
        justifyContent: 'start' 
    },
    footerLogoWrapper: { 
        width: '90px' 
    },
    footerLogoImage: { 
        width: '100%', 
        margin: '4px' 
    }, 
});