import { StoreAreaLabel } from '../../../../../core/types/store-area-label.enum';

export const storeAreas = [
  StoreAreaLabel.BUTCHER_SHOP,
  StoreAreaLabel.CELLAR,
  StoreAreaLabel.BAZAAR,
  StoreAreaLabel.CHECKOUT,
  StoreAreaLabel.CENTRAL_HALL,
  StoreAreaLabel.FRONT_HALL,
  StoreAreaLabel.REAR_HALL,
  StoreAreaLabel.ELECTRO,
  StoreAreaLabel.ENTRY_HALL,
  StoreAreaLabel.PRODUCE,
  StoreAreaLabel.BAKERY,
  StoreAreaLabel.ROTISSERIA,
  StoreAreaLabel.FLOWER_SHOP
];

export const defaultFormFields = {
  physicalAddressing: {
    errorText: '',
    showError: false,
  },
  store: {
    errorText: '',
    showError: false,
  },
  storeArea: {
    errorText: '',
    showError: false,
  },
  sector: {
    errorText: '',
    showError: false,
  },
  type: {
    errorText: '',
    showError: false,
  },
  promotionalSpot: {
    errorText: '',
    showError: false,
  },
  snackError: {
    show: false,
    type: '',
    message: '',
  },
};
