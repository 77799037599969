import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Typography } from '@material-ui/core';
import Autocomplete from '../../../../../../../components/Autocomplete';
import * as dictionary from '../../../../../../../core/constants/dictionary';
import { AddressingDto } from '../../../../../../../core/types/promotional-spot/promotional-addressing.dto';

import BaseModal from './BaseModal';
import classes from './Modals.module.scss';
import {
  PromotionalNegotiationDto,
  PromotionalNegotiationExecutionAddressingStoreDto,
} from '../../../../../common/types';
import {
  getAddressingById,
  getAddressings,
} from '../../../../../../../core/services/promotionalSpots/addressingsServices';
import {
  SnackBarTypeEnum,
  useSnackBar,
} from '../../../../../../../context/Snackbars';
import {
  GetPromotionalSpotAddressingsQueryDto,
} from '../../../../../../../core/services/promotionalSpots/types';
import * as routes from '../../../../../../../core/constants/routes';
import ExecutionForm from './ExecutionForm';
import { PromotionalNegotiationExecutionDto } from '../../../../../../../core/types/promotional-spot/promotional-negotiation-execution-dto';
import RefuseExecutionForm from './RefuseExecutionForm';
import { refuseExecution } from '../../../../../../../core/services/promotionalSpots/promotionalNegotiationExecutionsAPI';
import { useHistory } from 'react-router';
type Props = {
  title?: string;
  isOpen: boolean;
  onClose: Function;
  onUpdate: Function;
  onSubmit: (physicalAddressing: AddressingDto) => void;
  onDelete?: MouseEventHandler;
  data?: PromotionalNegotiationExecutionDto;
  negotiation?: PromotionalNegotiationDto;
  executions?: PromotionalNegotiationExecutionDto[];
  store?: PromotionalNegotiationExecutionAddressingStoreDto;
  isExpired: boolean;
};

enum CURRENT_MODAL {
  EXECUTION = 'EXECUTION',
  REFUSE_EXECUTION = 'REFUSE_EXECUTION',
}

const AddressingModal = ({
  title,
  isOpen,
  onClose,
  onUpdate,
  onSubmit,
  onDelete,
  negotiation,
  executions,
  store,
  data,
  isExpired,
}: Props) => {
  const [physicalAddressing, setPhysicalAddressing] = useState<AddressingDto>();
  const [currentModal, setCurrentModal] = useState('EXECUTION');
  const [refuseReason, setRefuseReason] = useState<string | undefined>();
  const { openSnackBar } = useSnackBar();

  const [
    isLoadingPhysicalAddressingOptions,
    setIsLoadingPhysicalAddressingOptions,
  ] = useState<boolean>(false);
  const [physicalAddressingOptions, setPhysicalAddressingOptions] = useState<
    GetPromotionalSpotAddressingsQueryDto['items']
  >([]);
  const history = useHistory();
  const currentExecution = useMemo(
    () =>
      executions?.find(
        (execution) => execution.addressing.id === physicalAddressing?.id
      ),
    [physicalAddressing]
  );

  const addressingOptions = useMemo(
    () =>
      !data
        ? physicalAddressingOptions.filter(
            (option) =>
              !executions?.find(
                (execution) => execution.addressing.id === option.id
              )
          )
        : physicalAddressingOptions.filter((option) =>
            executions?.find(
              (execution) => execution.addressing.id === option.id
            )
          ),
    [physicalAddressingOptions, data, executions]
  );

  const fetchData = useCallback(async () => {
    if (!isLoadingPhysicalAddressingOptions) {
      try {
        setIsLoadingPhysicalAddressingOptions(true);
        const { data } = await getAddressings({
          storeId: store?.id,
          spotId: negotiation?.spotId,
          limit: 999,
        });
        setPhysicalAddressingOptions(data.items);
        setIsLoadingPhysicalAddressingOptions(false);
      } catch (error) {
        setPhysicalAddressingOptions([]);
        setIsLoadingPhysicalAddressingOptions(false);
      }
    }
  }, [store, isLoadingPhysicalAddressingOptions, negotiation]);

  const refuseExecutionHandler = async (executionId: string, reason: string) => {
    try {
      await refuseExecution({executionId, reason});
      openSnackBar(
        SnackBarTypeEnum.SUCCESS,
        dictionary.REFUSED_SUCCESS
      );
    } catch (error) {
      openSnackBar(
        SnackBarTypeEnum.ERROR,
        dictionary.REFUSED_FAILED
      );
    }
  };

  const fetchDataItem = useCallback(async (id: string) => {
    try {
      const { data } = await getAddressingById(id);
      if (data) {
        setPhysicalAddressing(data);
      }
    } catch (error) {
      setPhysicalAddressing(undefined);
    }
  }, []);

  useEffect(() => {
    fetchData();
    return () => {
      setPhysicalAddressing(undefined);
      setIsLoadingPhysicalAddressingOptions(false);
      setPhysicalAddressingOptions([]);
    };
  }, [store]);

  useEffect(() => {
    if (data) {
      fetchDataItem(data.addressing.id);
    }
  }, [data?.id]);

  return (
    <BaseModal
      title={
        data
          ? currentModal == CURRENT_MODAL.EXECUTION
            ? dictionary.VIEW_PROMOTIONAL_SPOT
            : dictionary.REFUSE_EXECUTION
          : dictionary.ADD_PROMOTIONAL_SPOT
      }
      isOpen={isOpen}
      onClose={onClose}
      onDelete={onDelete}
      onClick={async(event) => {
        if (physicalAddressing && currentModal == CURRENT_MODAL.EXECUTION) {
          onSubmit(physicalAddressing);
        } else {
          if (currentExecution && refuseReason) {
            await refuseExecutionHandler(currentExecution.id, refuseReason);
            onClose();
            onUpdate();
          }else{
            openSnackBar(
              SnackBarTypeEnum.ERROR,
              dictionary.NEED_REASON
            );
          }
        }
      }}
      actions={{
        delete: (!!data && !isExpired && currentModal == CURRENT_MODAL.EXECUTION),
        submit:
          (!data && !isExpired) || currentModal == CURRENT_MODAL.REFUSE_EXECUTION,
        cancel: true,
      }}
    >
      <div className={classes.container}>
        <Typography className={classes.title}>{title}</Typography>
        <div className={classes.grayBox}>
          <>
            {!data && (
              <Autocomplete
                size="small"
                id="addressing"
                label={dictionary.ADDRESSING}
                key={physicalAddressing?.id ?? ''}
                value={physicalAddressing}
                options={addressingOptions}
                getOptionLabel={(option: AddressingDto) =>
                  option.physicalAddressing
                }
                onChange={(e: any, value: AddressingDto) =>
                  setPhysicalAddressing(value)
                }
                className={classes.input}
                style={{}}
              />
            )}
            {physicalAddressing ? (
              currentModal == CURRENT_MODAL.EXECUTION ? (
                <ExecutionForm
                  physicalAddressing={physicalAddressing}
                  execution={currentExecution}
                  isExpired={isExpired}
                  onRefuse={() =>
                    setCurrentModal(CURRENT_MODAL.REFUSE_EXECUTION)
                  }
                />
              ) : (
                <RefuseExecutionForm
                  physicalAddressing={physicalAddressing}
                  execution={currentExecution}
                  setRefuseReason={(reason: string) => setRefuseReason(reason)}
                />
              )
            ) : (
              <div className={classes.emptyData}>
                <Typography align="center" className={classes.subtitle}>
                  {dictionary.SELECT_AN_ADDRESSING_TO_SHOW_MORE_DETAILS}
                </Typography>
              </div>
            )}
          </>
        </div>
      </div>
    </BaseModal>
  );
};

export default AddressingModal;
