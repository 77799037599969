// @ts-nocheck
import styled, { css } from 'styled-components';
import { ifProp, theme } from 'styled-tools';
import { Modal, Subtitle, Row } from '@involves/violin';

export const StyledModal = styled(Modal)`
  > header {
    border-bottom-color: transparent;

    > h2 {
      color: ${theme('theme.palette.black.400')};
    }

    > button {
      display: none;
    }
  }

  > footer {
    background-color: transparent;
  }
`;

export const StyledContent = styled.div`
  display: grid;
  grid-row-gap: 45px;
`;

export const StyledNameContent = styled.div`
  display: grid;
  grid-row-gap: 8px;
`;

export const StyledSubtitle = styled(Subtitle)`
font-weight: ${theme('typography.fontWeight.semiBold')};
visibility: visible;
opacity: 1;
transition: ${theme('common.transition')};

${ifProp(
  'color',
  css`
      color: ${({ color }) => color};
    `,
  )}

  ${ifProp(
    'corporate',
    css`
      color: ${theme('palette.black.400')};
    `,
  )}

  ${ifProp(
    'invisible',
    css`
      visibility: hidden;
      opacity: 0;
    `,
  )}
`;

export const StyledRow = styled(Row)`
  margin-left: 0;
`;

export const StyledInlineGridRow = styled.div`
  display: inline-grid;
  grid-template-columns: minmax(160px, auto) auto;
  grid-column-gap: 32px;
`;

export const StyledRadioButtonsGrid = styled.div`
  display: grid;
  grid-row-gap: 16px;
`;
