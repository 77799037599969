import React, { useMemo, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { view } from 'react-easy-state';
import { theme } from 'styled-tools';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MaterialTable, { Column } from 'material-table';
import FilterListIcon from '@material-ui/icons/FilterList';
// @ts-ignore
import { Button } from '@involves/violin';
import { OutlinedButton, OutlinedSuccessButton} from '../../components/Buttons';
import { EmptyDataMessageRow } from '../../components/Tables/DefaultTable';
import { profileCheck } from '../../core/profiles';
import { getDifferenceBetweenISODates, getTime, } from '../../core/constants/dates';
import { mainFontFamily } from '../../Theme/fonts';
import visitsOfDayStore from './visitsOfDayStore';
import * as dictionary from '../../core/constants/dictionary';
import PatchedPagination from '../../components/PatchedPagination';
import SuspensionTracker from './components/SuspensionTracker';
import { colors } from '../../Theme/colors';
import {
  RowData,
  statusOptionsText,
  MTableRowDataType,
  shouldHighlightVisitElement,
} from './utils';

const StyledButton = styled(Button)`
  color: ${theme('palette.black.400')};
`;

type Props = {
  handleOpenModal: Function;
  handleOpenEvaluation: Function;
}

const VisitsOfDayTable = ({ handleOpenModal, handleOpenEvaluation }: Props) => {
  const [filter, setFilter] = useState(false);
  const tableRef = useRef(null);

  useEffect(() => {
    visitsOfDayStore.tableRef = tableRef.current;
  }, [tableRef]);

  const renderColumns = useMemo(() => {
    const columns = [
      {
        field: 'supplierNames',
        id: 'supplierNames',
        orderByField: 'supplierNames',
        title: dictionary.SUPPLIERS,
      },
      {
        id: 'checkinAtTime',
        field: 'checkinAt',
        orderByField: 'checkinAtTime',
        title: dictionary.CHECK_IN,
        filterComponent: (filterProps: any) => {
          return (
            <TextField
              type="time"
              inputProps={{ step: '1' }}
              onChange={event => {
                filterProps.onFilterChanged(
                  filterProps.columnDef.tableData.id,
                  event.target.value,
                );
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FilterListIcon />
                  </InputAdornment>
                ),
              }}
            />
          );
        },
        render: (rowData: RowData) => {
          if (shouldHighlightVisitElement(rowData)) {
            return '';
          }

          return rowData.checkinAt
            ? getTime(new Date(rowData.checkinAt), rowData.timezone)
            : '';
        },
      },
      {
        id: 'checkoutAtTime',
        field: 'checkoutAt',
        orderByField: 'checkoutAt',
        title: dictionary.CHECK_OUT,
        filterComponent: (filterProps: any) => {
          return (
            <TextField
              type="time"
              inputProps={{ step: '1' }}
              onChange={event => {
                filterProps.onFilterChanged(
                  filterProps.columnDef.tableData.id,
                  event.target.value,
                );
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FilterListIcon />
                  </InputAdornment>
                ),
              }}
            />
          );
        },
        render: (rowData: RowData) => {
          return rowData.checkoutAt
            ? getTime(new Date(rowData.checkoutAt), rowData.timezone)
            : '';
        },
      },
      {
        id: 'total',
        title: dictionary.TOTAL,
        orderByField: 'duration',
        render: (rowData: RowData) => {
          return getDifferenceBetweenISODates(
            rowData.checkinAt,
            rowData.checkoutAt,
          );
        },
      },
      {
        title: dictionary.STATUS,
        field: 'status',
        id: 'status',
        orderByField: 'status',
        render: (rowData: RowData) => {
          if (shouldHighlightVisitElement(rowData)) {
            return 'Suspenso';
          }
          
          return statusOptionsText[rowData.status];
        }
      },
      {
        id: 'totalWarning',
        title: dictionary.FORGETFULNESS,
        field: 'forgetfullness',
        align: "center",
        sorting: false,
        render: (rowData: RowData) => <SuspensionTracker total={rowData.totalWarnings} />,
      },
      {
        title: dictionary.PROMOTER_NAME,
        field: 'promoterName',
        id: 'promoterName',
        orderByField: 'promoterName',
      },
      {
        title: dictionary.CPF,
        field: 'promoterCpf',
        id: 'promoterCpf',
        orderByFild: 'promoterCpf',
      },
      {
        sorting: false,
        align: "center",
        render: (rowData: RowData) => {
          if (shouldHighlightVisitElement(rowData)) {
            return (
              <OutlinedSuccessButton
                label={dictionary.RELEASE_ACCESS}
                onClick={() => handleOpenModal(dictionary.CHECK_IN, rowData)}
              />
            );
          }
          
          if (rowData.status === 0) {
            return (
              <OutlinedButton
                label={dictionary.DO_CHECK_OUT}
                onClick={() => handleOpenModal(dictionary.CHECK_OUT, rowData)}
              />
            );
          }

          if (rowData.status > 0) {
            if (rowData.ratingScore) {
              return (
                <StyledButton 
                  small 
                  secondary
                  onClick={() => handleOpenEvaluation(rowData)}
                >
                  Ver avaliação
                </StyledButton>
              );
            }

            if (!rowData.ratingScore && !profileCheck.isSecurity()) {
              return (
                <Button 
                  small 
                  secondary
                  onClick={() => handleOpenEvaluation(rowData)}
                >
                  Avaliar visita
                </Button>
              );
            }
          }
        },
      },
    ];

    if (profileCheck.isSuperAdmin() || profileCheck.isRetailAdmin()) {
      columns.unshift({
        title: dictionary.STORE,
        field: 'storeName',
        id: 'storeName',
        orderByField: 'storeName',
      });
    }
    if (profileCheck.isSuperAdmin()) {
      columns.unshift({
        title: dictionary.RETAIL,
        field: 'retailName',
        id: 'retailName',
        orderByField: 'retailName',
      });
    }
    return columns;
  }, [handleOpenEvaluation, handleOpenModal]);

  return (
    <MaterialTable
      title=""
      tableRef={tableRef}
      columns={renderColumns as Column<MTableRowDataType>[]}
      //@ts-ignore
      data={visitsOfDayStore.fetchItems}
      options={{
        filtering: filter,
        actionsColumnIndex: -1,
        searchFieldAlignment: 'left',
        pageSize: 10,
        rowStyle: (rowData: RowData) => { 
          const rowStyle = {
            fontSize: '13px',
            fontFamily: mainFontFamily, 
            backgroundColor: '',
          }

          if (shouldHighlightVisitElement(rowData)) {
            rowStyle.backgroundColor = colors.red_50;
          }

          return rowStyle;
        },
      }}
      actions={[
        {
          icon: FilterListIcon,
          tooltip: dictionary.FILTERS,
          isFreeAction: true,
          onClick: () => setFilter(!filter),
        },
      ]}
      localization={{
        header: { actions: '' },
        toolbar: { searchPlaceholder: dictionary.SEARCH },
        body: {
          emptyDataSourceMessage: 
            <EmptyDataMessageRow 
              message={dictionary.EMPTY_VISIT_TABLE} 
            />,
        },
        pagination: {
          labelDisplayedRows: '{from}-{to} de {count}',
          labelRowsSelect: dictionary.LINES,
        },
      }}
      components={{
        OverlayLoading: props => (<div></div>),
        Pagination: props => <PatchedPagination {...props} />,
      }}
    />
  );
};

export default view(VisitsOfDayTable);
