import React from 'react';
import { view } from 'react-easy-state';
import styled from 'styled-components';

const style = {
  backgroundColor: '#FAFBFC',
  borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
  padding: '20px',
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  height: '30px',
  paddingTop: '9px',
};

const phantom = {
  display: 'block',
  paddingTop: '9px',
  height: '60px',
  width: '100%',
};

const StickyFooter = ({ actionButton }) => {
  return (
    <>
      {/* <div style={phantom} /> */}
      <div style={style}>{actionButton}</div>
    </>
  );
};

const StyledPhatom = styled.div`
  display: block;
  padding-top: 9px;
  height: 60px;
  width: 100%;
`;

const StyledStickyFooterContent = styled.div`
  background-color: #fafbfc;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 20px;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  height: 30px;
  padding-top: 9px;
`;

export const StyledStickyFooter = ({ children }) => {
  return (
    <div>
      <StyledPhatom />
      <StyledStickyFooterContent>{children}</StyledStickyFooterContent>
    </div>
  );
};

export default view(StickyFooter);
