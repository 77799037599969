import { CancelToken } from 'axios';
import { mainStore } from '../../core/store/mainStore';
import {
  postUser,
  deleteUser,
  putUser,
  getAllNotPromotersUsers,
} from '../../core/services/users/usersService';
import { exist } from '../../core/validation';
import { UserProfile } from '../../core/types/user-profile.enum';
import { profileCheck } from '../../core/profiles';
import { getActiveRetails } from '../../core/services/retails/retailsService';
import * as dictionary from '../../core/constants/dictionary';

type UserProfilesArray = Array<{ 
  name: string; 
  value: number;
}>

export type UserInfo = {
  id?: string;
  profile?: {
    value?: number;
  };
  rg?: string;
  cpf?: string;
  name?: string;
  email?: string;
  store?: any;
  retail?: any;
  status?: number;
  password?: string;
  confirmPassword?: string;
  storeId?: string;
  retailId?: string;
  isSuspended?: boolean;
}

export const emptyFormValue: UserInfo = {
  name: '',
  cpf: '',
  password: '',
  confirmPassword: '',
  profile: undefined,
  email: '',
  status: 1,
  store: undefined,
  retail: undefined,
};

export const getUsersProfiles = (): UserProfilesArray => {
  const profiles = [
    { name: dictionary.RETAIL_ADMIN, value: UserProfile.RETAIL_ADMIN },
    { name: dictionary.BUYER, value: UserProfile.BUYER },
    { name: dictionary.SALES_REP, value: UserProfile.SALES_REP },
    { name: dictionary.PROMOTERS_APPROVER, value: UserProfile.PROMOTER_APPROVER },
    { name: dictionary.STORE_MANAGER, value: UserProfile.STORE_MANAGER },
    { name: dictionary.DOOR_SECURITY, value: UserProfile.SECURITY },
    { name: dictionary.RESTOCKER, value: UserProfile.RESTOCKER },
  ];

  if (profileCheck.isSuperAdmin()) {
    profiles.push({ name: dictionary.SUPER_ADMIN, value: UserProfile.SUPER_ADMIN });
  }
  return profiles;
};

interface fetchAllNotPromotersUsersProps {
  offset: number,
  limit: number,
  queryString: string,
  retailName: string,
  cancelToken?: CancelToken
}

export const fetchAllNotPromotersUsers = async ({ 
  offset = 0, 
  limit = 999999, 
  queryString = '',
  retailName = '',
  cancelToken
}: fetchAllNotPromotersUsersProps) => {
  return await getAllNotPromotersUsers({
    retailId: mainStore.loggedUser.retailId,
    offset,
    limit,
    queryString,
    retailName,
    cancelToken,
  });
}

export const fetchActiveRetails = async () => {
  return await getActiveRetails();
}

export const loadInitialData = async () => {
  mainStore.requestLoading = true;
  mainStore.users.data = await getAllNotPromotersUsers({
    retailId: mainStore.loggedUser.retailId,
  });
  mainStore.users.retails = await getActiveRetails();
  mainStore.requestLoading = false;
};

export const getRetailFromCreator = () => {
  if (profileCheck.isRetailAdmin()) {
    return mainStore.loggedUser.retailId;
  }
  return null;
};

export const editUser = (user: UserInfo) => {
  mainStore.requestLoading = true;
  const dto = {
    id: user.id,
    name: user.name,
    cpf: user.cpf,
    profile: user.profile ? user.profile.value : undefined,
    email: user.email,
    status: user.status,
    rg: user.rg,
    retailId: exist(user.retail) ? user.retail.id : getRetailFromCreator(),
    storeId: exist(user.store) ? user.store.id : null,
  };

  return putUser(dto);
};

export const registerUser = (user: UserInfo) => {
  mainStore.requestLoading = true;
  const dto = {
    name: user.name,
    cpf: user.cpf,
    profile: user.profile ? user.profile.value : undefined,
    email: user.email,
    status: user.status ?? 0,
    rg: user.rg,
    retailId: exist(user.retail) ? user.retail.id : getRetailFromCreator(),
    storeId: exist(user.store) ? user.store.id : null,
    password: user.password,
  };

  return postUser(dto, mainStore.token);
};

export const removeUser = async (user: UserInfo) => {
  mainStore.requestLoading = true;
  return deleteUser(user);
};

export const halfWidthInputStyle = {
  width: '50%', 
  marginTop: '3px', 
  marginBottom: '20px', 
};
