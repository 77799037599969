import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {view} from 'react-easy-state';
import Loading from '../../components/Loading';
import PageContent from '../../components/PageContent';
import { utils } from '../../core/utils';
import { mainStore } from '../../core/store/mainStore';
import { profileCheck } from '../../core/profiles';
import { useRetailList } from '../../hooks/RetailHooks';
import { RetailEntityType } from '../../core/types/retail/retail-entity.type';
import { SnackBarTypeEnum } from '../../context/Snackbars';
import { 
  getSuppliers, 
  deleteSupplier,
} from '../../core/services/suppliers/suppliers-api';
import * as dictionary from '../../core/constants/dictionary';
import { 
  Footer, 
  SupplierTable, 
  SupplierFilter,
  EmptySupplierTable, 
  CreateSupplierModal,
  UpdateSupplierModal,
  RemoveSupplierModal,
} from './components';
import {ModalType} from './utils';
import classes from './Supplier.module.scss';

const Suppliers = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [selectedRetail, setSelectedRetail] = useState<RetailEntityType | null>(null);
  const [selectedSupplier, setSelectedSupplier] = useState<any>();
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [toolbarInputFocus, setToolbarInputFocus] = useState(false);
  const [modalType, setModalType] = useState<ModalType | null>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const mounted = useRef(false);
  const hasResults = useRef(false);
  const {
    fetch: fetchRetails,
    retails: retailsOptions,
  } = useRetailList();
  const userRetailId = mainStore?.loggedUser?.retailId ?? selectedRetail?.id;

  useEffect(() => {
    mounted.current = true;
    
    if (profileCheck.isSuperAdmin()) {
      fetchRetails();
    }

    return () => {
      mounted.current = false;
      hasResults.current = false;
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, searchText]);

  const openCreateModal = () => {
    setIsOpenModal(true);
    setModalType(ModalType.CREATE);
  }

  const onRemoveSupplier = useCallback(async () => {
    try {
      await deleteSupplier(selectedSupplier.id);
      fetchData();
      onCloseModal();
      utils.openSnackBar(
        SnackBarTypeEnum.SUCCESS, 
        dictionary.THE_SUPPLIER_WAS_DELETED,
      );
    } catch (err) {
      utils.openSnackBar(
        SnackBarTypeEnum.ERROR, 
        dictionary.ERROR_ON_DELETE_SUPPLIER,
      );
    } finally {
      onCloseModal();
    }
    
  }, [selectedSupplier]);

  const onCloseModal = useCallback(() => {
    setModalType(null);
    setIsOpenModal(false);
  }, []);

  const onOpenModal = (type: ModalType, data: any) => {
    setModalType(type);
    setIsOpenModal(true);
    setSelectedSupplier(data);
  };

  const upsertSupplierHandler = useCallback(
    (feedbackMessage: string) => {
      fetchData();
      onCloseModal();
      utils.openSnackBar(
        SnackBarTypeEnum.SUCCESS, 
        feedbackMessage,
      );
    }, [userRetailId, searchText, page, rowsPerPage]
  );

  const fetchData = async () => {
    if (!isLoading) {
      try {
        setIsLoading(true);
        const limit = rowsPerPage;
        const offset = page * rowsPerPage;
        const result = await getSuppliers({
          limit,
          offset,
          q: searchText,
          retailId: userRetailId,
        });
        
        if (mounted.current && result.items.length > 0) {
          hasResults.current = true;
        }
        setTotal(result.count);
        setSuppliers(result.items);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
  }

  const renderedModal = useMemo(() => {
    switch (modalType) {
      case ModalType.CREATE:
        return (
          <CreateSupplierModal
            isOpen={isOpenModal}
            retailId={userRetailId}
            retails={retailsOptions ?? []}
            onClose={onCloseModal}
            onSubmit={upsertSupplierHandler}
          />
        );
      case ModalType.UPDATE:
        return (
          <UpdateSupplierModal
            isOpen={isOpenModal}
            data={selectedSupplier}
            retails={retailsOptions ?? []}
            onClose={onCloseModal}
            onSubmit={upsertSupplierHandler}
          />
        );
      case ModalType.DELETE:
        return (
          <RemoveSupplierModal
            isOpen={isOpenModal}
            onClose={onCloseModal}
            onSubmit={onRemoveSupplier}
          />
        );
    }
  }, [
    modalType, 
    isOpenModal, 
    userRetailId,
    retailsOptions,
    onCloseModal,
    onRemoveSupplier, 
    upsertSupplierHandler,
  ]);

  const retailFilter = useMemo(() => {
    return profileCheck.isSuperAdmin() ? (
      <SupplierFilter 
        value={selectedRetail}
        options={retailsOptions ?? []}
        onSubmit={fetchData }
        onClear={() => setSelectedRetail(null)}
        onChange={(value: RetailEntityType) => {
          setSelectedRetail(value);
        }}
      />
    ) : null;
  }, [retailsOptions, selectedRetail]);

  const hasEmptyPage = useMemo(
    () => !hasResults.current, 
    [hasResults.current]
  );

  return (
    <>
      <PageContent title={dictionary.SUPPLIERS} className={classes.mainPage}>
        { isLoading ? (
          <Loading isOpen={isLoading}/>
        ) : (
            hasEmptyPage ? (
              <EmptySupplierTable onClick={openCreateModal} />
            ) : (
              <>
                { retailFilter }
                <SupplierTable
                  page={page}
                  data={suppliers}
                  total={total}
                  rowsPerPage={rowsPerPage}
                  searchTextValue={searchText}
                  toolbarInputFocus={toolbarInputFocus}
                  onOpenModal={onOpenModal}
                  onChangePage={(page: number) => {
                    setPage(page);
                  }}
                  onChangeRowsPerPage={(rowsPerPage: number) => {
                    setPage(0);
                    setRowsPerPage(rowsPerPage);
                    setToolbarInputFocus(false);
                  }}
                  onChangeSearch={(value: any) => {
                    setSearchText(value);
                    setToolbarInputFocus(true);
                  }}
                />
                <Footer onClick={openCreateModal} />
              </>
            )
        )}
        {renderedModal}
        {utils.getSnackbar()}
      </PageContent>
    </>
  );
}

export default view(Suppliers);
