import { PromotionalCalendarDto } from '../../types/promotional-spot/promotional-calendar.dto';
import {
  addPromotionalCalendarsAPI,
  getPromotionalCalendarsAPI,
  editPromotionalCalendarsAPI,
  deletePromotionalCalendarsAPI,
  getPromotionalCalendarByIdAPI,
} from './promotionalCalendarsAPI';
import { CreatePromotionalCalendarParams, GetPromotionalCalendarsParams, UpdatePromotionalCalendarParams } from './types';

export const getPromotionalCalendars = ({ 
  name = '',
  offset = 0, 
  limit = 10, 
  retailId,
}: GetPromotionalCalendarsParams) => {
  return getPromotionalCalendarsAPI({ offset, limit, name, retailId });
};

export const getPromotionalCalendarById = (calendarId: string) => {
  return getPromotionalCalendarByIdAPI(calendarId);
}

export const addPromotionalCalendar = (calendar: CreatePromotionalCalendarParams) => {
  return addPromotionalCalendarsAPI(calendar);
}

export const editPromotionalCalendars = (calendar: UpdatePromotionalCalendarParams) => {
  return editPromotionalCalendarsAPI(calendar);
}

export const deletePromotionalCalendars = (calendar: Partial<PromotionalCalendarDto>) => {
  return deletePromotionalCalendarsAPI(calendar);
}
