import {
  createResetPasswordTokenAPI,
  getPasswordResetTokenInfoAPI,
  resetPasswordAPI,
} from './passwordResetAPI';

export const createResetPasswordToken = email => {
  return createResetPasswordTokenAPI({ email });
};

export const getPasswordResetTokenInfo = passwordResetToken => {
  return getPasswordResetTokenInfoAPI(passwordResetToken);
};

export const resetPassword = data => {
  return resetPasswordAPI(data);
};
