import React, { useMemo, useCallback } from 'react';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { Typography } from '@material-ui/core';
import { profileCheck } from '../../../../core/profiles';

export function Frequency({ index, data, handleChange }) {
  const handleDays = useCallback((event, value) => handleChange(value, index), [
    handleChange,
    index,
  ]);

  const disabled = useMemo(
    () => profileCheck.isSalesRep() || profileCheck.isPromoterApprover(),
    [],
  );

  return (
    <ToggleButtonGroup
      value={data}
      onChange={handleDays}
      arial-label="days"
      style={{
        height: '40px',
        width: '50%',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <ToggleButton
        disabled={disabled}
        value="0"
        aria-label="italic"
        style={{ width: 'inherit' }}
      >
        <Typography>D</Typography>
      </ToggleButton>
      <ToggleButton
        disabled={disabled}
        value="1"
        aria-label="bold"
        style={{ width: 'inherit' }}
      >
        <Typography>S</Typography>
      </ToggleButton>
      <ToggleButton
        disabled={disabled}
        value="2"
        aria-label="italic"
        style={{ width: 'inherit' }}
      >
        <Typography>T</Typography>
      </ToggleButton>
      <ToggleButton
        disabled={disabled}
        value="3"
        aria-label="italic"
        style={{ width: 'inherit' }}
      >
        <Typography>Q</Typography>
      </ToggleButton>
      <ToggleButton
        disabled={disabled}
        value="4"
        aria-label="italic"
        style={{ width: 'inherit' }}
      >
        <Typography>Q</Typography>
      </ToggleButton>
      <ToggleButton
        disabled={disabled}
        value="5"
        aria-label="italic"
        style={{ width: 'inherit' }}
      >
        <Typography>S</Typography>
      </ToggleButton>
      <ToggleButton
        disabled={disabled}
        value="6"
        aria-label="italic"
        style={{ width: 'inherit' }}
      >
        <Typography>S</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
export default Frequency;
