import React, { useCallback, useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/core/styles';
import { CollapseProps } from '@material-ui/core/Collapse/Collapse';
import {
  Button,
  Collapse,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import './style.css';
import {
  SHELF_CHECK_MISSION_TYPES,
  SHELF_CHECK_MISSION_TYPE_DEFAULT_FILTER_VALUE,
} from '../../../../core/constants/shelf_check_mission_types';
import * as dictionary from '../../../../core/constants/dictionary';
import { RetailEntityType } from '../../../../core/types/retail/retail-entity.type';
import { useRetailList } from '../../../../hooks/RetailHooks';
import { FormRetailField } from '../../../../components/Form/FormRetailField';
import API from '../../../../core/services/api/config';
import { profileCheck } from '../../../../core/profiles';
import { mainStore } from '../../../../core/store/mainStore';

type Filter = {
  retailId?: string[] | null;
  storeId?: string[] | null;
  productGroupId?: string[] | null;
  checkType?: string[] | null;
  startDate?: Date | null;
  endDate?: Date | null;
};

type MissionsOverviewFilterProps = {
  initialFilter: Filter;
  onFilter: Function;
  isExportAlertOpen: boolean;
  onCloseExportAlert: Function;
};

type SelectOption = {
  id: string;
  name: string;
};

type Retail = SelectOption;
type Store = SelectOption;
type ProductGroup = SelectOption;
type CheckType = SelectOption;

const CONSISTENT_INFO_DATE = new Date('2021-04-01T03:00:00Z');
const CHECK_TYPES: CheckType[] = SHELF_CHECK_MISSION_TYPES;

const MissionsOverviewFilter = (props: MissionsOverviewFilterProps) => {
  const { loggedUser } = mainStore;
  const isSuperAdmin = profileCheck.isSuperAdmin();
  const [filter, setFilter] = useState(props.initialFilter);

  const {
    retails,
    fetch: fetchRetails,
    isLoading: isLoadingRetails,
  } = useRetailList();
  const [stores, setStores] = useState<Store[]>([]);
  const [productGroups, setProductGroups] = useState<ProductGroup[]>([]);
  const [checkTypes] = useState<CheckType[]>(CHECK_TYPES);

  const [selectedRetail, setSelectedRetail] = useState<RetailEntityType>();
  const [selectedStores, setSelectedStores] = useState<Store[]>([]);
  const [selectedProductGroups, setSelectedProductGroups] = useState<
    ProductGroup[]
  >([]);
  const [selectedCheckType, setSelectedCheckType] = useState<CheckType[]>([]);

  const [filterOpen, setFilterOpen] = useState(true);
  const [dateAlertOpen, setDateAlertOpen] = useState(false);
  const isRetailLevel = isSuperAdmin || profileCheck.isRetailVision();

  const loadRetails = async () => {
    if (isSuperAdmin) {
      fetchRetails();
    } else {
      const retail: RetailEntityType = {
        id: loggedUser.retailId,
        name: loggedUser.retail.name,
        cnpj: '',
        status: 1,
        createdAt: new Date(),
        updatedAt: new Date(),
      };
      setSelectedRetail(retail);
    }
  };

  const loadStores = async () => {
    if (isRetailLevel) {
      const result = await API.get('/stores', {
        params: { status: 1, retailId: selectedRetail?.id, limit: 999 },
      });
      setStores(result.data.items);
      return;
    }

    const store: Store = {
      id: loggedUser.storeId,
      name: loggedUser.store.name,
    };

    setStores([store]);
    setSelectedStores([store]);
  };

  const loadProductGroups = async () => {
    const result = await API.get('/product-groups', {
      params: { retailId: selectedRetail?.id },
    });
    setProductGroups(result.data);
  };

  const showDateAlert = (filter: Filter) => {
    const { startDate, endDate } = filter;
    let showAlert = false;

    if (startDate != null) {
      showAlert = startDate < CONSISTENT_INFO_DATE;
    }
    if (endDate != null) {
      showAlert = showAlert || endDate < CONSISTENT_INFO_DATE;
    }

    setDateAlertOpen(showAlert);
  };

  const submitFilter = () => {
    props.onFilter(filter);
    showDateAlert(filter);
  };

  const reset = () => {
    if (isSuperAdmin) {
      setSelectedRetail(undefined);
    }
    if (isRetailLevel) {
      setSelectedStores([]);
    }
    setSelectedProductGroups([]);
    setFilter(props.initialFilter);
    showDateAlert(props.initialFilter);
    props.onFilter(props.initialFilter);
  };

  useEffect(() => {
    loadRetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setStores([]);
    setProductGroups([]);

    if (selectedRetail) {
      setSelectedStores([]);
      setSelectedProductGroups([]);
      loadStores();
      loadProductGroups();
    }
  }, [selectedRetail]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2}>
        <Grid container item spacing={2}>
          <Button
            size="large"
            disableElevation
            startIcon={<FilterListIcon />}
            onClick={() => setFilterOpen(!filterOpen)}
          >
            {dictionary.FILTERS}
          </Button>
        </Grid>
      </Grid>
      <GridContainerCollapse in={filterOpen}>
        <Grid container item spacing={2}>
          <Grid container item spacing={2}>
            {isSuperAdmin && (
              <Grid
                container
                item
                sm={6}
                md={3}
                direction="column"
                justify="flex-end"
              >
                <FormRetailField
                  disabled={isLoadingRetails}
                  options={retails}
                  value={selectedRetail}
                  onChange={(retail: RetailEntityType) => {
                    setSelectedRetail(retail);
                    if (retail) {
                      setFilter({
                        ...filter,
                        retailId: [retail.id],
                      });
                    } else {
                      setSelectedStores([]);
                      setSelectedProductGroups([]);
                      setFilter({
                        ...filter,
                        retailId: null,
                      });
                    }
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid container item spacing={2}>
            <Grid
              container
              item
              sm={6}
              md={3}
              direction="column"
              justify="flex-end"
            >
              <Autocomplete
                multiple
                id="store"
                size="small"
                limitTags={2}
                options={stores}
                value={selectedStores}
                getOptionLabel={(option) => option.name}
                disabled={!isRetailLevel || selectedRetail === null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={dictionary.STORE}
                    variant="outlined"
                  />
                )}
                onChange={(_, stores: Store[]) => {
                  setSelectedStores(stores);
                  setFilter({
                    ...filter,
                    storeId: stores.map((store) => store.id),
                  });
                }}
              />
            </Grid>
            <Grid
              container
              item
              sm={6}
              md={3}
              direction="column"
              justify="flex-end"
            >
              <Autocomplete
                multiple
                size="small"
                id="productGroup"
                limitTags={2}
                options={productGroups}
                value={selectedProductGroups}
                disabled={selectedRetail === null}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Setor" variant="outlined" />
                )}
                onChange={(_, productGroups: ProductGroup[]) => {
                  setSelectedProductGroups(productGroups);
                  setFilter({
                    ...filter,
                    productGroupId: productGroups.map(
                      (productGroup) => productGroup.id
                    ),
                  });
                }}
              />
            </Grid>
            <Grid
              container
              item
              sm={6}
              md={3}
              direction="column"
              justify="flex-end"
            >
              <Autocomplete
                id="checkType"
                size="small"
                multiple
                limitTags={2}
                options={checkTypes}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={dictionary.MISSION_TYPE}
                    variant="outlined"
                  />
                )}
                value={selectedCheckType}
                onChange={(_, checkTypes: CheckType[]) => {
                  setSelectedCheckType(checkTypes);
                  setFilter({
                    ...filter,
                    checkType: checkTypes.map((checkType) => checkType.id),
                  });
                }}
              />
            </Grid>

            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <Grid
                container
                item
                xs={6}
                sm={3}
                md
                direction="column"
                justify="flex-end"
              >
                <KeyboardDatePicker
                  className="--full"
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="none"
                  id="startDate"
                  label={dictionary.DATE_START_SELECT}
                  maxDate={filter.endDate || new Date()}
                  maxDateMessage={dictionary.MAX_START_DATE_MESSAGE}
                  invalidDateMessage={''}
                  onChange={(date) => {
                    setFilter({
                      ...filter,
                      startDate: date,
                    });
                  }}
                  value={filter.startDate || new Date()}
                />
              </Grid>
              <Grid
                container
                item
                xs={6}
                sm={3}
                md
                direction="column"
                justify="flex-end"
              >
                <KeyboardDatePicker
                  disableToolbar
                  id="endDate"
                  margin="none"
                  variant="inline"
                  format="dd/MM/yyyy"
                  invalidDateMessage={''}
                  maxDate={new Date()}
                  minDate={filter.startDate}
                  label={dictionary.DATE_END_SELECT}
                  value={filter.endDate || new Date()}
                  minDateMessage={dictionary.MIN_END_DATE_MESSAGE}
                  maxDateMessage={dictionary.MAX_END_DATE_MESSAGE}
                  onChange={(date) => {
                    setFilter({
                      ...filter,
                      endDate: date,
                    });
                  }}
                  className="--full"
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={!selectedRetail}
                disableElevation
                onClick={() => submitFilter()}
              >
                {dictionary.SEARCH}
              </Button>
            </Grid>
            <Grid item>
              <Button color="primary" disableElevation onClick={() => reset()}>
                {dictionary.CLEAR}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </GridContainerCollapse>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <Collapse in={dateAlertOpen}>
            <Alert
              severity="info"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setDateAlertOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Para datas anteriores à{' '}
              {CONSISTENT_INFO_DATE.toLocaleDateString('pt-BR')}, não há
              registro das informações de <strong>Dias sem venda</strong>,{' '}
              <strong>Shoppers/dia</strong> e <strong>Venda estimada</strong>.
            </Alert>
          </Collapse>
        </Grid>

        <Grid item xs={12}>
          <Collapse in={props.isExportAlertOpen}>
            <Alert
              severity="warning"
              action={
                <IconButton
                  size="small"
                  color="inherit"
                  aria-label="close"
                  onClick={() => props.onCloseExportAlert()}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {dictionary.MAX_DATE_INTERVAL_EXPORT_REPORT}.
            </Alert>
          </Collapse>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    padding: 8,
  },
});

const GridContainerCollapse = (props: CollapseProps) => {
  const styles = useStyles();

  return (
    <Collapse
      {...props}
      classes={styles}
      component={(props) => <Grid container item spacing={2} {...props} />}
    />
  );
};

export default MissionsOverviewFilter;
