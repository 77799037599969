import { ActiveStatus } from '../../constants/active-status';
import { ACTIVE } from '../../constants/status';
import { getSuppliersByRetailAPI } from './suppliersAPI';

export const getSuppliersByRetail = ({
  retailId = '', 
  status = ActiveStatus.ACTIVE, 
  q = '', 
  limit = 10,
  offset = 0, 
}) => {
  return getSuppliersByRetailAPI({
    q, 
    limit,
    offset, 
    status, 
    retailId, 
  });
};
