import React from 'react';
import ModalTitle from '../Title';

const TitleContainer = ({ title, children }) => {
  return (
    <>
      <ModalTitle title={title} />
      {children}
    </>
  );
};

export default TitleContainer;
