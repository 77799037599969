import React, { useEffect, useState } from 'react';
import { view } from 'react-easy-state';
import { Link, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import logo from '../../assets/logos/full-logo-dark.svg';
import { isValidForm, handleSubmit, isTokenValid } from './functions';
import * as dictionary from '../../core/constants/dictionary';
import { mainStore } from '../../core/store/mainStore';
import DefaultButton from '../../components/Buttons/Default';
import TextField from '../../components/InputTextField';
import { redirectToHome } from '../../core/authorization';
import InitialPageContainer from '../../containers/InitialPage';
import rootClasses from '../../containers/InitialPage/styles.module.scss';

import * as routes from '../../core/constants/routes';
import ModalTitle from '../../components/Modal/Title';
import { colors } from '../../Theme/colors';
import classes from './ResetPassword.module.scss';

const ResetPassword = ({ history }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [finished, setFinished] = useState(false);
  const [valid, setValid] = useState(false);

  const { passwordResetToken } = useParams();

  useEffect(() => {
    (async () => {
      setValid(await isTokenValid(passwordResetToken));
    })();
  }, [passwordResetToken]);

  if (mainStore.token) {
    return redirectToHome();
  }

  const invalidContent = (
    <>
      <div align="center">
        <Typography variant="h2">
          {dictionary.PASSWORD_TOKEN_INVALID}
        </Typography>
      </div>
      <div className={rootClasses.submit}>
        <DefaultButton
          onClick={async () => {
            history.push(routes.LOGIN);
          }}
          label={dictionary.RETURN_TO_LOGIN}
        />
      </div>
    </>
  );

  const finishedContent = (
    <>
      <div align="center">
        <Typography variant="h2">
          {dictionary.PASSWORD_SUCCESSFULLY_RECOVERED}
        </Typography>
      </div>
      <div className={rootClasses.submit}>
        <DefaultButton
          onClick={async () => {
            history.push(routes.LOGIN);
          }}
          label={dictionary.RETURN_TO_LOGIN}
        />
      </div>
    </>
  );

  const formContent = (
    <>
      <div className={classes.formTitle}>
        <ModalTitle title={dictionary.CHANGE_PASSWORD} />
      </div>

      <div className={rootClasses.input}>
        <Typography variant="body1">{dictionary.NEW_PASSWORD_NAME}</Typography>
        <TextField
          style={{ width: '100%' }}
          placeholder={dictionary.TYPE_NEW_PASSWORD}
          value={newPassword}
          name={dictionary.NEW_PASSWORD_NAME}
          onChange={(e) => setNewPassword(e.target.value)}
          type="password"
        />
      </div>

      <div className={rootClasses.input}>
        <Typography variant="body1">
          {dictionary.CONFIRM_NEW_PASSWORD_NAME}
        </Typography>
        <TextField
          style={{ width: '100%' }}
          placeholder={dictionary.TYPE_NEW_PASSWORD}
          name={dictionary.NEW_PASSWORD_NAME}
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          type="password"
        />
      </div>

      <div className={rootClasses.submit}>
        <DefaultButton
          disabled={!isValidForm(newPassword, confirmNewPassword)}
          onClick={async (e) => {
            await handleSubmit(e, passwordResetToken, newPassword);
            setFinished(true);
          }}
          label={dictionary.SEND}
        />
      </div>

      <div className={rootClasses.changePage}>
        <Typography variant="body2">
          <Link
            className={rootClasses.clickable}
            onClick={() => history.push(routes.LOGIN)}
          >
            {dictionary.LOGIN_INSTEAD}
          </Link>
        </Typography>
      </div>
    </>
  );

  let content;
  if (!valid) {
    content = invalidContent;
  } else if (finished) {
    content = finishedContent;
  } else {
    content = formContent;
  }

  return (
    <InitialPageContainer>
      <div className={classes.formContainer}>{content}</div>
    </InitialPageContainer>
  );
};

export default view(ResetPassword);
