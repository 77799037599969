import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { DialogActions, DialogContent } from '@material-ui/core';
import useConstant from 'use-constant';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import TextField from '../../../../components/InputTextField';
import Autocomplete from '../../../../components/Autocomplete';
import { UserInviteModal } from '../../../../components/UserInviteModal/UserInviteModal';
import { CancelButton, DefaultButton } from '../../../../components/Buttons';
import { profileCheck, userProfile } from '../../../../core/profiles';
import { utils } from '../../../../core/utils';
import { mainStore } from '../../../../core/store/mainStore';
import { ActiveStatus } from '../../../../core/constants/active-status';
import { findValueInArray } from '../../../../core/validation';
import { getSuppliersByRetail } from '../../../../core/services/suppliers/suppliersService';
import {
  sendPullOfTrackInvites,
  trackSalesRepInviteSent,
} from '../../../../core/mixpanel/mixPanelEvents';
import * as dictionary from '../../../../core/constants/dictionary';
import {
  submitAgreement,
  getChipsSuppliers,
  getOptionLabelSuppliers,
  autocompleteSupplierStyle,
} from '../../functions';

export const NewAgreementFirstStep = ({
  retailsData,
  nextStepCallback,
  closeModalCallback,
  setCurrentAgreementCallback,
}) => {
  const [invites, setInvites] = useState([]);
  const [retail, setRetail] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [newInvites, setNewInvites] = useState([]);
  const [description, setDescription] = useState('');
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [showingInviteAnswerableModal, setShowingInviteAnswerableModal] = useState(false);

  const getRenderId = useCallback(() => {
    if (mainStore.loggedUser.retailId) {
      return mainStore.loggedUser.retailId;
    }
    return null;
  }, []);

  useEffect(() => {
    (async function init() {
      mainStore.requestLoading = true;
      
      const initialRetailId = getRenderId();
      if (!profileCheck.isSuperAdmin() && initialRetailId) {
        const initialRetail = findValueInArray(
          retailsData,
          'id',
          initialRetailId,
        );
        const suppliersOpts = await getSuppliersByRetail({
          status: ActiveStatus.ACTIVE,
          retailId: initialRetail.id, 
        });
        setSupplierOptions(suppliersOpts.items);
        setRetail(initialRetail);
      }

      mainStore.requestLoading = false;
    })();
  }, [getRenderId, retailsData]);

  const loadSupplierDropdownOptions = useCallback(
    async (retailId, search) => {
      let suppliersOpts = await getSuppliersByRetail({
        retailId, 
        status: ActiveStatus.ACTIVE, 
        q: search,
      });
      
      setSupplierOptions(
        suppliersOpts.items.filter(
          s => !suppliers.map(selected => selected.id).includes(s.id)
        )
      );
    },
    [suppliers],
  );

  const debouncedGetSuppliers = useConstant(() =>
    AwesomeDebouncePromise(
      (retailId, search) => loadSupplierDropdownOptions(retailId, search), 300
    )
  );

  const handleOnChangeRetail = useCallback(async (e, value) => {
    setRetail(value);
    setSuppliers([]);
    setSupplierOptions([]);
    
    if (value) {
      mainStore.requestLoading = true;
      const suppliersOpts = await getSuppliersByRetail({
        retailId: value.id, 
        status: ActiveStatus.ACTIVE,
      });
      setSupplierOptions(suppliersOpts.items);
      mainStore.requestLoading = false;
    }
  }, []);

  const retailSelect = useMemo(() => {
    if (profileCheck.isSuperAdmin()) {
      return (
        <Autocomplete
          value={retail}
          label={dictionary.AGREEMENT_RETAIL}
          options={mainStore.agreements.retails}
          onChange={async (e, value) => handleOnChangeRetail(e, value)}
          style={{ width: '50%', marginTop: '3px' }}
        />
      );
    }
    return '';
  }, [retail, handleOnChangeRetail]);

  const serializeAgreement = useCallback(() => {
    return {
      id: null,
      retailId: retail ? retail.id : null,
      answerableUserIds: [],
      supplierIds: suppliers.map(s => s.id),
      description,
      invites,
      status: 1,
      acceptanceStatus: 1,
      autoActivatePromoter: true,
    };
  }, [retail, suppliers, description, invites]);

  const serializeAndSubmit = useCallback(
    async event => {
      event.preventDefault();

      if (event.currentTarget.form.reportValidity() && suppliers.length > 0) {
        try {
          const agreement = serializeAgreement();
          await submitAgreement(
            agreement,
            dictionary.AGREEMENT_CREATED,
            setCurrentAgreementCallback,
            nextStepCallback,
            suppliers,
          );
          await sendPullOfTrackInvites(newInvites);
          setNewInvites([]);
        } catch (err) {
          utils.openSnackBar(
            dictionary.ERROR,
            dictionary.FIRST_STEP_AGREEMENT_ERROR,
          );
        } finally {
          mainStore.requestLoading = false;
        }
      } else {
        utils.openSnackBar(dictionary.ERROR, dictionary.FORM_INVALID);
      }
    },
    [
      suppliers,
      newInvites,
      nextStepCallback,
      serializeAgreement,
      setCurrentAgreementCallback,
    ],
  );

  const handleInvite = useCallback(
    async data => {
      if (!invites.map(i => i.email).includes(data.email)) {
        try {
          setNewInvites([...newInvites, data]);
          setInvites([...invites, data]);
          mainStore.snackBars.typeSnackbar = dictionary.SUCCESS;
          mainStore.snackBars.openSnackBar = true;
          mainStore.snackBars.messageSnackBar =
            dictionary.SALES_REP_INVITED_AGREEMENT;
          trackSalesRepInviteSent({
            suppliers,
            salesRepEmail: data.email,
            code: 'Em criação',
          });
        } catch (error) {
          mainStore.snackBars.typeSnackbar = dictionary.ERROR;
          mainStore.snackBars.openSnackBar = true;
          if (
            error.response &&
            error.response.data.message === dictionary.ALREADY_USER_EMAIL_ERROR
          ) {
            mainStore.snackBars.messageSnackBar =
              dictionary.ERROR_INVITE_USER_EMAIL;
          } else {
            mainStore.snackBars.messageSnackBar = dictionary.ERROR_INVITE_USER;
          }
        }
      }
      setShowingInviteAnswerableModal(false);
    },
    [invites, newInvites, suppliers],
  );

  return (
    <form noValidate>
      <DialogContent>
        <div className="form-two-columns">{retailSelect}</div>
        <div className="form-two-columns">
          <Autocomplete
            multiple
            required={false}
            value={suppliers}
            options={supplierOptions}
            disabled={retail === null}
            label={dictionary.AGREEMENT_SUPPLIERS}
            renderTags={getChipsSuppliers}
            filterOptions={opts => {
              if (opts.length > 0) {
                return opts.filter(
                  opt => !suppliers.map(item => item.id).includes(opt.id),
                );
              }
              return opts;
            }}
            getOptionLabel={getOptionLabelSuppliers}
            getOptionSelected={(option, value) => {
              return option.internalCode === value.internalCode;
            }}
            onChange={(_e, value) => setSuppliers(value)}
            onInputChange={(_e, value) => {
              debouncedGetSuppliers(retail.id, value);
            }}
            style={autocompleteSupplierStyle}
          />
        </div>

        <TextField
          multiline
          required={false}
          variant="standard"
          value={description}
          label={dictionary.AGREEMENT_DESCRIPTION}
          onChange={e => setDescription(e.target.value)}
          style={{ width: '100%', marginBottom: '24px' }}
        />
      </DialogContent>

      <DialogActions>
        <CancelButton onClick={closeModalCallback} />
        <DefaultButton
          color="primary"
          label={dictionary.SAVE_AND_CONTINUE}
          onClick={serializeAndSubmit}
          style={{ width: 'auto' }}
        />
      </DialogActions>

      <UserInviteModal
        open={showingInviteAnswerableModal}
        userProfile={userProfile.SALES_REP}
        onSubmit={handleInvite}
        onCancel={() => setShowingInviteAnswerableModal(false)}
      />
    </form>
  );
};
