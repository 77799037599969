import * as Restock from '../../../../core/constants/restock';
import * as Mission from '../../../../core/constants/mission';
export { Mission, Restock }

export function formatIndicators(indicators) {
    /**
     * @param: [object] Objeto indicadores retornado pelo fetch da API `/missions/indicators[?...filters]`
     * @return: [object] Objeto formatado de acordo com exibição dos dashcards para renderização via Array.map() no componente
     */

    if(indicators && indicators.check && indicators.supply) {
        return {
            check: [
                {
                    item:"gôndola cheia",
                    value: indicators.check.falsePositive.count,
                    sellout: indicators.check.falsePositive.sale,
                    filter: {
                        checkStatus: Mission.FALSE_POSITIVE
                    },
                },
                {
                    item:"abastecer",
                    value: indicators.check.confirmed.count,
                    sellout: indicators.check.confirmed.sale,
                    filter: {
                        checkStatus: Mission.CONFIRMED
                    },
                },
                {
                    item:"sem conferência",
                    value: indicators.check.pending.count,
                    sellout: indicators.check.pending.sale,
                    filter: {
                        checkStatus: Mission.PENDING
                    },
                }
            ],
            supply: [
                {
                    item:"falta no depósito",
                    value: indicators.supply.noStock.count,
                    sellout: -indicators.supply.noStock.sale,
                    filter: {
                        supplyStatus: [Restock.NO_STOCK],
                        supplyVirtualStock: 0,
                    },
                },
                {
                    item:"estoque virtual",
                    value: indicators.supply.virtualStock.count,
                    sellout: -indicators.supply.virtualStock.sale,
                    filter: {
                        supplyStatus: [Restock.NO_STOCK],
                        supplyVirtualStock: 1,
                    },
                },
                {
                    item:"sem reposição",
                    value: indicators.supply.pending.count,
                    sellout: -indicators.supply.pending.sale,
                    filter: {
                        supplyStatus: [Restock.PENDING],
                    },
                }
            ]
        }
    }
}

export function getTotalSupply(supply) {
    /**
     * @param: [object] Atributo supply do objeto indicadores retornado pelo fetch da API `/missions/indicators[?...filters]`
     * @return: [int] Soma dos contadores para produtos não abastecidos (falta em depósito, estoque virtual, sem reposição)
     */

    if(supply) {
        return supply.virtualStock.count + supply.pending.count + supply.noStock.count;
    }
}

export function getParcialCheck(check) {
    /**
     * @param: [object] Atributo check do objeto indicadores retornado pelo fetch da API `/missions/indicators[?...filters]`
     * @return: [int] Soma dos contadores para produtos conferidos (numerador da fração do dashcard de Conferências)
     */

    if(check) {
        return check.confirmed.count + check.falsePositive.count;
    }
}

export function getTotalCheck(check) {
    /**
     * @param: [object] Atributo check do objeto indicadores retornado pelo fetch da API `/missions/indicators[?...filters]`
     * @return: [int] Soma total dos contadores para produtos conferidos (denominador da fração do dashcard de Connferências)
     */

    if(check) {
        return check.confirmed.count + check.pending.count + check.falsePositive.count;
    }
}

export function formatRecoveredSale(supply) {
    /**
     * @param: [object] Atributo supply do objeto indicadores retornado pelo fetch da API `/missions/indicators[?...filters]`
     * @return: [String] Vendas recuperadas, em formato de moeda, com precisão de duas casas decimais (1.000,09)
     */

    if(supply) {
        return formatPrice(supply.done.sale);
    }
    return 0;
}

export function formatLostSale(supply) {
    /**
     * @param: [object] Atributo supply do objeto indicadores retornado pelo fetch da API `/missions/indicators[?...filters]`
     * @return: [String] Vendas perdidas, em formato de moeda, com precisão de duas casas decimais (1.000,09)
     */

    if(supply) {
        return formatPrice(supply.virtualStock.sale + supply.pending.sale + supply.noStock.sale);
    }
    return 0;
}

function formatPrice(price) {
    /**
     * @param: [Float] Valor a ser convertido em formato de moeda BRL, através do objeto Intl nativo do Javascript
     * @return: [String] Número, em formato de moeda, com precisão de duas casas decimais (1.000,09)
     */
    return Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    }).format(price).replace(/R\$/, '');
}
