import API from '../api/config';
import * as routes from '../api/routes';
import { 
  CreateSupplierParams,
  GetSuppliersParams, 
  UpdateSupplierParams, 
} from '../../types/supplier/supplier-service';

export const getSuppliers = async ({
  q,
  limit,
  status,
  offset,
  retailId,
}: GetSuppliersParams) => {
  const params = new URLSearchParams();
  if (offset && offset >= 0) {
    params.append('offset', `${offset}`);
  }
  if (limit && limit >= 0) {
    params.append('limit', `${limit}`);
  }
  if (status !== undefined) {
    params.append('status', `${status}`);
  }
  if (retailId) {
    params.append('retailId', retailId);
  }
  if (q) {
    params.append('q', q);
  }
  const response = await API.get(routes.API_SUPPLIERS, { params });
  
  return response.data;
};

export const createSupplier = async (params: CreateSupplierParams) => {
  const response = await API.post(routes.API_SUPPLIERS, params);
  return response;
}

export const updateSupplier = async (params: UpdateSupplierParams) => {
  const response = await API.put(`${routes.API_SUPPLIERS}/${params.id}`, params);
  return response;
}

export const deleteSupplier = async (id: string) => {
  const response = await API.delete(`${routes.API_SUPPLIERS}/${id}`);
  return response;
}

export enum SupplierAPIError {
  DUPLICATED_NAME = 'DuplicatedNameError',
  DUPLICATED_INTERNAL_CODE = 'DuplicatedInternalCodeError',
  DUPLICATED_CNPJ = 'DuplicatedCNPJError',
}
