import { formatWithoutTimezone } from '../../../core/constants/dates';
import { PromotionalSpotReportDto } from '../../../core/types/promotional-spot/promotional-spot-repot-data-dto';
import { PromotionalSpotTypeLabel } from '../../../core/types/promotional-spot/promotional-spot-type-label.enum';
import { PromotionalSpotType } from '../../../core/types/promotional-spot/promotional-spot-type.enum';
import * as dictionary from '../../../core/constants/dictionary';
import { translatePromotionalNegotiationExecutionProblemTypeAPIToString } from '../common/utils';
import {
  PromotionalSpotReportTableLineDto,
  PromotionalSpotProductReportStatusLabel,
} from './types/promotional-spot-report-table-line-dto';

export const columnsForPromotionalSpotTableReport = [
  {
    field: 'store',
    name: 'store',
    title: dictionary.PROMOTIONAL_SPOT_REPORT_STORE_LABEL,
    width: '10%',
  },
  {
    field: 'startAt',
    name: 'startAt',
    title: dictionary.PROMOTIONAL_SPOT_REPORT_DATE_START_AT_LABEL,
    width: '8%',
  },
  {
    field: 'endAt',
    name: 'endAt',
    title: dictionary.PROMOTIONAL_SPOT_REPORT_DATE_END_AT_LABEL,
    width: '7%',
  },

  {
    field: 'spotType',
    name: 'spotType',
    title: dictionary.PROMOTIONAL_SPOT_REPORT_SPOT_TYPE,
    width: '10%',
    lookup: PromotionalSpotTypeLabel,
  },
  {
    field: 'spotName',
    name: 'spotName',
    title: dictionary.PROMOTIONAL_SPOT,
    width: '15%',
  },
  {
    field: 'addressing',
    name: 'addressing',
    title: dictionary.ADDRESSING,
    width: '10%',
  },
  {
    field: 'suppliers',
    name: 'suppliers',
    title: dictionary.PROMOTIONAL_SPOT_REPORT_SUPPLIERS,
    width: '15%',
  },
  {
    field: 'product',
    name: 'product',
    title: dictionary.PRODUCT,
    width: '15%',
  },
  {
    field: 'status',
    name: 'status',
    title: dictionary.STATUS,
    width: '5%',
    lookup: PromotionalSpotProductReportStatusLabel,
  },
  {
    field: 'negotiationExecutionProblemType',
    name: 'negotiationExecutionProblemType',
    title: dictionary.LAST_REPORTED_PROBLEM,
    width: '10%',
    render: (data: any) => {
      if (data.negotiationExecutionProblemType) {
        return data.negotiationExecutionProblemType;
      }
      return '-';
    }
  },
  {
    field: 'negotiationObservation',
    name: 'negotiationObservation',
    title: dictionary.OBSERVATIONS,
    width: '10%',
  },
];

export const parseReportResultToTable = (
  data: PromotionalSpotReportDto
): PromotionalSpotReportTableLineDto => {
  
  return {
    addressing: data.addressing.physicalAddress,
    startAt: formatWithoutTimezone(data.negotiation.startAt),
    endAt: formatWithoutTimezone(data.negotiation.endAt),
    spotName: data.addressing.spot.name,
    product: `(${data.product.sku}) ${data.product.name}`,
    spotType: data.addressing.spot.type as PromotionalSpotType,
    store: `(${data.addressing.store.code}) ${data.addressing.store.name}`,
    suppliers: data.suppliers.map(
      (supplier) => `(${supplier.id}) ${supplier.name};`
    ),
    status: data.status,
    negotiationExecutionProblemType: 
      translatePromotionalNegotiationExecutionProblemTypeAPIToString(
        data.negotiationExecutionProblemType
      )
    ,
    negotiationObservation: data.negotiation?.observation ?? '',
  };
};
