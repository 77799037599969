import React from 'react';
import imageSource from '../../assets/general/suspensionStoreAccess.svg';
import { SUSPENDED_ACCESS } from '../../core/constants/dictionary';

type Props = {
  className?: string;
}

const DangerStore = ({ className, ...restProps }: Props) => (
  <img 
    src={imageSource}
    alt={SUSPENDED_ACCESS}
    className={className}
    {...restProps}
  />
)

export default DangerStore;