import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { formatWithoutTimezone } from '../../../../../core/constants/dates';
import * as dictionary from '../../../../../core/constants/dictionary';

export const parsePeriodsDateToAcordeonText = (dates: string[]): string => {
  const limitDate = new Date(dates[2]);
  return `${dates
    .slice(0, 2)
    .map((item) => formatWithoutTimezone(new Date(item)))
    .join(' à ')} | ${
    limitDate.getTime() < new Date().getTime()
      ? dictionary.EXPIRED_NEGOTIATIONS
      : `${dictionary.EXPIRE_IN} ${formatDistanceToNow(limitDate, {
          locale: ptBR,
        })}`
  }`;
};
