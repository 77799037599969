import React, { MouseEventHandler } from 'react';
import { ChatBubble } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import classes from './Icons.module.scss';
import * as dictionary from '../../../../../../../core/constants/dictionary';
import { colors } from '../../../../../../../Theme/colors';

type Props = {
  onClick: MouseEventHandler;
};

const ProblemIcon = ({ onClick }: Props) => {
  return (
    <Tooltip title={dictionary.VIEW_PROBLEMS}>
      <ChatBubble
        style={{ color: colors.yellow_400 }}
        onClick={onClick}
        className={classes.problemIcon}
      />
    </Tooltip>
  );
};

export default ProblemIcon;
