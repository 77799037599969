import React from 'react';
import { view } from 'react-easy-state';
import { BaseModal } from '../BaseModal';

type Props = {
  isOpen: boolean;
  onClose: Function;
  onReload: Function;
}

export const CreateUser = ({ isOpen, onClose, onReload }: Props) => {
  if (!isOpen) {
    return null;
  }

  return (
    <BaseModal
      onClose={onClose}
      onReload={onReload}
    />
  );
};

export default view(CreateUser);
