export const ADD_USER = 'Novo usuário';
export const ADD_RETAIL = 'Nova rede';
export const ADD_STORE = 'Nova loja';
export const ALLOW_ACCESS = 'Aprovar Acesso';
export const ADD_AGREEMENT = 'Novo Acordo';
export const DELETE_RETAIL = 'Excluir Rede';
export const DELETE_PROMOTER_ACCESS = 'Excluir Acesso de Promotor';
export const DELETE_PROMOTER_REGISTER = 'Excluir cadastro do promotor';
export const DELETE_STORE = 'Excluir loja';
export const DELETE_AGREEMENT = 'Excluir Acordo';
export const DELETE_USER = 'Excluir Usuário';
export const CPF = 'CPF';
export const CNPJ = 'CNPJ';
export const NAME = 'Nome';
export const DATE = 'Data';
export const EDIT = 'edit';
export const SAVE = 'Salvar';
export const DELETE = 'Excluir';
export const DETAILS = 'Detalhes';
export const EMAIL = 'Email';
export const LINES = 'Linhas';
export const USERS = 'Usuarios';
export const RETAIL = 'Rede';
export const ACTIVE = 'Ativo';
export const STORES = 'Lojas';
export const CREATE = 'create';
export const STATUS = 'Status';
export const ACCESS = 'Acessar';
export const SEARCH = 'Pesquisar';
export const CANCEL = 'Cancelar';
export const SELECT = 'Selecione';
export const FILTERS = 'Filtros';
export const SUCCESS = 'success';
export const ERROR = 'error';
export const WAITING = 'Aguardando';
export const CONFIRM_PASSWORD = 'Confirmar senha';
export const SEND = 'Enviar';
export const INACTIVE = 'Inativo';
export const APPROVED = 'Aprovado';
export const APPROVAL = 'Aprovação';
export const PROMOTERS = 'Promotores';
export const SUPPLIER = 'Fornecedor';
export const SUPPLIERS = 'Fornecedores';
export const AGREEMENTS = 'Acordos';
export const NO_OPTIONS = 'Sem Opções';
export const DISAPPROVED = 'Reprovado';
export const NUMBER = 'Número';
export const NEIGHBORHOOD = 'Bairro';
export const CITY = 'Cidade';
export const STATE = 'Estado';
export const RETAILS = 'Redes';
export const VISITS = 'Visitas';
export const PROFILE = 'Perfil';
export const SUPER_ADMIN = 'Super admin';
export const RETAIL_ADMIN = 'Admin rede';
export const PROMOTERS_APPROVER = 'Aprovador de promotores';
export const BUYER = 'Comprador';
export const STORE_MANAGER = 'Responsável de loja';
export const DOOR_SECURITY = 'Segurança de porta';
export const PROMOTER = 'Promotor';
export const SALES_REP = 'Vendedor';
export const CODE = 'Código';
export const OWNER = 'Proprietário';
export const DAYS_OF_WEEK = 'Dias da Semana';
export const BACK = 'Voltar';
export const STORE = 'Loja';
export const DAY_OF_WEEK = 'Dia da Semana';
export const HOUR = 'Hora';
export const PROMOTER_NAME = 'Nome do promotor';
export const PROMOTER_CPF = 'CPF do promotor';
export const RIGHT_DAY = 'Dia certo';
export const YES = 'Sim';
export const NO = 'Não';
export const SUNDAY = 'Domingo';
export const MONDAY = 'Segunda feira';
export const TUESDAY = 'Terça feira';
export const WEDNESDAY = 'Quarta feira';
export const THURSDAY = 'Quinta feira';
export const FRIDAY = 'Sexta feira';
export const SATURDAY = 'Sábado';
export const FORM_INVALID = 'Por favor, revise os campos';
export const HAS_USER_CFP = 'CPF já existente.';
export const HAS_USER_EMAIL = 'E-mail já existente.';
export const CLOSE = 'Fechar';
export const EXIT = 'Sair';
export const AGREEMENT_STORES = 'Lojas do acordo';
export const ERROR_INVITE_USER = 'Erro ao convidar usuário';
export const ERROR_INVITE_USER_EMAIL = 'Usuário com email cadastrado';
export const RESEND_INVITE_ERROR = 'Erro ao reenviar invite';
export const USER_NAME = 'Nome do usuário';
export const RETAIL_CNPJ = 'CNPJ da rede';
export const RETAIL_NAME = 'Nome da rede';
export const ACTIVE_RETAIL = 'Rede ativa';
export const ACTIVE_STORE = 'Loja ativa';
export const AGREEMENT_RETAIL = 'Rede do acordo';
export const AGREEMENT_SUPPLIERS = 'Fornecedores do acordo';
export const AGREEMENT_DESCRIPTION = 'Descrição do acordo';
export const SALES_REP_CPF = 'CPF do vendedor';
export const AGREEMENT_CODE = 'Código do acordo';
export const FORGOT_MY_PASSWORD = 'Esqueci a minha senha';
export const CHANGE_PASSWORD = 'Alterar senha';
export const RETURN_TO_LOGIN = 'Voltar para o login';
export const ACCESS_EMAIL_TO_RECOVER_PASSWORD =
  'Acesse o seu e-mail para recuperar a sua senha';
export const PASSWORD_SUCCESSFULLY_RECOVERED = 'Senha alterada com sucesso';
export const PASSWORD_TOKEN_INVALID =
  'Este link é inválido ou expirou, por favor reinicie o processo de recuperação de senha';
export const STORE_NAME = 'Nome da loja';
export const STORE_CODE = 'Número da loja';
export const USER_CPF = 'CPF do usuário';
export const USER_RG = 'RG';
export const CEP = 'CEP';
export const ADDRESS = 'Endereço';
export const PASSWORD = 'Senha';
export const USER_PROFILE = 'Perfil de acesso';
export const USER_EMAIL = 'E-mail do usuário';
export const ACTIVE_USER = 'Usuário ativo';
export const AGREEMENT_DETAILS = 'Detalhes do acordo';
export const REMOVE_ACCESS = 'Excluir acesso';
export const SUSPEND_ACCESS = 'Suspender acesso';
export const MANAGE_ACCESS = 'Gerenciar acesso';
export const REGISTER_SALES_REP = 'Cadastro de vendedores';
export const SALES_REP_REGISTERED = 'Vendedor cadastrado com sucesso!';
export const EXPORT_VISITS = 'Exportar histórico';
export const USER_TERMS = 'Li e aceito os termos de uso';
export const SUSPENDED_ACCESSES = 'Acessos Suspensos';
export const LIBERATE_ACCESS = 'Acessos Liberados';
export const ADD_PROMOTERS_VALUE = 'add promoters';
export const EDIT_FREQUENCY = 'edit frequency';
export const TYPE_CPF = 'Digite seu cpf';
export const TYPE_A_NAME_OR_CPF = 'Digite um nome ou CPF';
export const TYPE_EMAIL = 'Digite seu e-mail';
export const TYPE_NAME = 'Digite seu nome';
export const EDIT_RETAIL = 'Editar rede';
export const EDIT_STORE = 'Editar loja';
export const CREATE_USER = 'Criar usuário';
export const EDIT_USER = 'Editar usuário';
export const REMOVE_RETAIL = 'Excluir rede';
export const REMOVE_STORE = 'Excluir loja';
export const REMOVE_USER = 'Excluir usuário';
export const REMOVE_AGREEMENT = 'Excluir Acordo';
export const REMOVE_STORE_ANSWERABLE = 'Excluir responsável de loja';
export const TYPE_PASSWORD = 'Digite sua senha';
export const TYPE_NEW_PASSWORD = 'Digite sua nova senha';
export const RETAIL_EDITED = 'A rede foi editada com sucesso!';
export const EDIT_AGREEMENT = 'Editar Acordo';
export const APPROVE_AGREEMENT = 'Aprovar Acordo';
export const PROMOTER_ACCESS_APROVED = 'Acesso aprovado!';
export const PROMOTER_ACCESS_BLOCKED =
  'Acesso bloqueado e marcado como pendente!';
export const RETAIL_CREATED = 'A rede foi cadastrada com sucesso!';
export const USER_CREATED = 'O usuário foi cadastrado com sucesso!';
export const USER_EDITED = 'O usuário foi editado com sucesso!';
export const USER_REMOVED = 'O usuário foi excluído com sucesso!';
export const RETAIL_REMOVED = 'A rede foi excluída com sucesso!';
export const PROMOTER_REMOVED = 'O promotor foi removido com sucesso!';
export const UNKNOWN_ERROR =
  'Ocorreu um erro, tente novamente ou contate o suporte!';
export const FORGOT_PASSWORD = 'Esqueci minha senha';
export const AGREEMENT_EDITED = 'O acordo foi editado';
export const AGREEMENT_CREATED = 'O acordo foi criado';
export const STORE_CREATED = 'A loja foi cadastrada com sucesso!';
export const STORE_EDITED = 'A loja foi editada com sucesso!';
export const INVOLVES_DOORS_LOGO = 'Involves Doors Logo';
export const LINK_PROMOTER_TO_AGREEMENT = 'Vincular promotores ao acordo';
export const LINK_PROMOTER_TO_ONE_OR_MORE_AGREEMENTS =
  'Vincule o promotor a um ou mais acordos';
export const CONFIRM_RETAIL_REMOVAL =
  'Atenção, você tem certeza que deseja excluir a rede';
export const CONFIRM_PROMOTER_ACCESS_REMOVAL =
  'Atenção, você tem certeza que deseja excluir o acesso do promotor';
export const CONFIRM_AGREEMENT_REMOVAL =
  'Atenção, você tem certeza que deseja excluir o acordo';
export const INVALID_USER_OR_PASSWORD = 'Usuário ou senha não encontrados';
export const NO_AGREEMENTS_WAS_CREATED_YET =
  'Você ainda não tem acordos de promotores com a indústria. Clique para cadastrar um novo acordo.';
export const NO_PROMOTERS_WAS_CREATED_YET =
  'Você ainda não tem promotores cadastrados. Clique para cadastrar um novo promotor.';
export const WELCOME_TO_DOORS_ADD_RETAILS_HERE =
  'Bem vindo ao Involves Doors! Adicione as redes que serão nossos clientes.';
export const WELCOME_ADD_STORE = 'Clique e adicione uma ';
export const NO_SUSPENDED_PROMOTER_ACCESSES =
  'Não há acessos de promotores suspensos';
export const NO_APROVED_PROMOTER_ACCESSES =
  'Não há acessos de promotores liberados ';
export const SUSPENDED = 'Suspenso';
export const NO_PROMOTER_ACCESSES = 'Não há acessos de promotores ';
export const SUSPEND_PROMOTER_FOR_AGREEMENT =
  'Suspender acesso do promotor para o acordo';
export const ACCESS_SUSPENDED_FOR_AGREEMENT =
  'O acesso do promotor foi suspenso para o acordo';
export const ACCESS_RELEASED_FOR_AGREEMENT =
  'O acesso do promotor foi liberado para o acordo';
export const RELEASE_PROMOTER_FOR_AGREEMENT =
  'Liberar acesso do promotor para o acordo';
export const CONFIRM_PASSWORD_NAME = 'Confirmar senha ';
export const TYPE_CONFIRM_PASSWORD = 'Confirme sua senha ';
export const NEW_STORE = 'Nova loja';
export const NEW_USER = 'Novo usuário';
export const TO_RETAIL = ' à rede ';
export const NO_OPTION = 'Sem Opções';
export const AGREEMENT_REMOVED = 'O acordo foi removido';
export const STORE_REMOVED = 'A loja foi excluída com sucesso!';
export const CONFIRM_STORE_REMOVAL =
  'Atenção, você tem certeza que deseja excluir a loja';
export const CONFIRM_USER_REMOVAL =
  'Atenção, você tem certeza que deseja excluir o usuário';
export const CPF_NAME = 'cpf';
export const EMAIL_NAME = 'e-mail';
export const EMAIL_COLUMN = 'E-mail';
export const NEW_PASSWORD_NAME = 'Nova senha';
export const CONFIRM_NEW_PASSWORD_NAME = 'Confirme a Nova senha';
export const LOGIN_INSTEAD = 'Ao invés disso, fazer login.';
export const PASSWORD_NAME = 'password';
export const AGREEMENT_APPROVED = 'Acordo aprovado!';
export const WEEK_VISITS = 'Visitas na semana';
export const INVALID_ZIP_CODE = 'CEP inválido';
export const PASSWORD_NOT_MATCH = 'As senhas não coincidem';
export const FIELD_NOT_EMPTY = 'O campo deve ser preenchido';
export const INVALID_EMAIL = 'E-mail inválido';
export const INVALID_CPF = 'CPF inválido';
export const INCOMPLETE_CPF = 'CPF incompleto';
export const INVALID_CNPJ = 'CNPJ inválido';
export const CPF_ALREADY_REGISTERED = 'CPF já cadastrado';
export const ALREADY_REGISTERED_PROMOTER_ON_RETAIL =
  'Este promotor já está cadastrado para sua rede. Busque pelo CPF ou nome na lista de promotores.';
export const ALREADY_REGISTERED_USER_ON_RETAIL =
  'Este CPF já está cadastrado para sua rede com outro perfil. Busque pelo CPF ou nome na lista de usuários.';
export const CPF_NOT_REGISTERED = 'CPF não cadastrado';
export const EMAIL_ALREADY_REGISTERED = 'E-mail já cadastrado';
export const NOTHING_HERE = 'Parece que ainda há nada por aqui...';
export const WELCOME_TO = 'Bem vindo ao';
export const INVOLVES_DOOR = 'Involves Doors';
export const YOU_ARE_NOT_REGISTERED =
  '! Você ainda não está cadastrado em nenhum acordo.';
export const DUPLICATED_CNPJ =
  'Já existe uma rede cadastrada com o cnpj informado.';
export const NO_VISITS = 'Não há visitas realizadas.';
export const INVITE_PLUS = 'Convidar +';
export const OPTION_ALREADY_SELECTED = 'Opção já selecionada.';
export const EMAIL_ALREADY_EXIST = 'Email já existe no sistema.';
export const VIEW_AGREEMENT = 'Visualizar acordo';
export const RESEND_INVITE_SUCCESS = 'Convite reenviado com sucesso!';
export const RESEND_INVITE = 'Reenviar convite';
export const VISITS_EDITED = 'As visitas do acordo foram cadastradas';
export const ADD_PROMOTER_TO_AGREEMENT = 'Adicionar participante no acordo';
export const USER = 'Cadastro de usuários';
export const PROMOTERS_AGREEMENTS = 'Acordos de promotores';
export const ADD_NEW_RETAIL = 'Adicionar nova rede';
export const VIEW_WEEK_VISITS = 'Visualizar frequência de visitas';
export const EDIT_WEEK_VISITS = 'Editar frequência de visitas';
export const ACCESSES = 'Acessos';
export const PROMOTER_ACCESS_DETAILS = 'Acesso de promotor';
export const SEE_CPF = 'Ver CPF';
export const SEE_RG = 'Ver RG';
export const APROVE_AGREEMENT = 'Aprovação de acordo de promotores';
export const AGREEMENTS_REGISTER = 'Aprovação de acordo de promotores';
export const ADD_PROMOTERS = 'Adicionar promotores';
export const ALREADY_USER_EMAIL_ERROR = 'User already present with given email';
export const REGISTER_IN = 'Cadastrado em';
export const WATCH_RG_PICTURE = 'Ver foto do RG';
export const WATCH_CPF_PICTURE = 'Ver foto do CPF';
export const LOADING = 'Carregando...';
export const EDIT_VISITS_ERROR =
  'Erro ao editar visitas.Por favor aguarde alguns instantes e tente novamente.';
export const SECURITY = 'Segurança';
export const CREATED = 'Criado';
export const INVITED = 'Convidado';
export const REGISTERED = 'Cadastrado';
export const SCORE = 'Nota';
export const REASON = 'Motivo';
export const TIME_SPENT = 'Tempo de visita';
export const VALIDITY_CONTROL = 'Controle de validade';
export const SHELF_DISPLAY = 'Organização da gôndola';
export const OTHERS = 'Outros';
export const RESTOCKER = 'Repositor';
export const RESTOCK = 'Abastecimento';
export const RESTOCK_DASHBOARD = 'Painel de abastecimento';
export const RESTOCK_REPORTS = 'Relatórios';
export const PRINT_SHELF_BREAKS_REPORTS = 'Imprimir relatório de rupturas';
export const EXPORT_SHELF_BREAKS_REPORTS = 'Exportar relatório de rupturas';
export const RESTOCK_HOUR = 'Hora da conferência';
export const RESTOCK_DATE = 'Data da conferência';
export const SKU_NAME = 'Nome do SKU';
export const PRODUCT_NAME = 'Nome do produto';
export const RESTOCK_STATUS = 'Status do abastecimento';
export const PENDING = 'Pendente';
export const OUT_OF_STOCK = 'Em falta no estoque';
export const VIRTUAL_STOCK = 'Estoque virtual';
export const STOCKED = 'Abastecido';
export const SECTOR = 'Setor';
export const CATEGORY = 'Categoria';
export const DATE_SELECT = 'Selecione a data';
export const DATE_START_SELECT = 'Data de início';
export const DATE_END_SELECT = 'Data de fim';
export const CLEAR = 'Limpar';
export const INVALID_DATE = 'Data inválida';
export const SHELF_BREAK_REPORT_ERROR =
  'Erro ao carregar dados.Por favor aguarde alguns instantes e tente novamente.';
export const SEARCH_ERROR =
  'Erro ao pesquisar dados.Por favor aguarde alguns instantes e tente novamente.';
export const PENDING_CONFERENCE = 'Conferência Pendente';
export const AWAITING_REPLACEMENT = 'Aguardando reposição';
export const AVAILABILITY = 'Disponibilidade';
export const QAUNTITY = 'Quantidade';
export const IN_SHELF_BREAK = 'Em ruptura';
export const LOW_STOCKED = 'Pouco abastecido';
export const REGISTER_WEEK_VISITS = 'Cadastrar visitas na semana';
export const EDIT_PROMOTER = 'edit promoter';
export const UPDATE_PROMOTER = 'Editar promotor';
export const REMOVE_PROMOTER = 'Remover Promotor';
export const EDIT_AGREEMENT_ERROR =
  'Erro ao editar o acordo.Por favor aguarde alguns instantes e tente novamente.';
export const FIRST_STEP_AGREEMENT_ERROR =
  'Erro ao criar o acordo.Por favor aguarde alguns instantes e tente novamente.';
export const ADD_PROMOTER = 'Cadastrar promotores';
export const LINK_PROMOTERS = 'Vincular promotores';
export const ADD_PROMOTER_SUCCESS =
  'Os promotores do acordo foram vinculados com sucesso!';
export const AGREEMENT_APPROVED_PROMOTERS_ADDED =
  'O acordo foi aprovado e os promotores foram cadastrados com sucesso! ';
export const SEE_FREQUENCY_VISITS = 'Veja a frequência de visitas.';
export const PROMOTER_INVITED_AGREEMENT =
  'O promotor foi convidado para o acordo.';
export const SALES_REP_INVITED_AGREEMENT =
  'O vendedor foi convidado para o acordo.';
export const PROMOTOR_ACCESS_ERROR =
  'Erro ao remover o acesso do Promotor.Por favor aguarde alguns instantes e tente novamente.';
export const REGISTER_PROMOTER = 'Cadastro de promotores';
export const NO_REGISTER_PROMOTERS = 'Não há promotores cadastrados';
export const LINKED_AGREEMENTS = 'Acordos vinculados';
export const PROMOTER_DETAILS = 'Detalhes do promotor';
export const PROMOTER_CREATED = 'Promotor criado com sucesso.';
export const PROMOTER_CREATED_AND_LINKED =
  'O promotor foi cadastrado e vinculado a acordo com sucesso!';
export const PROMOTER_EDITED = 'O cadastro do promotor foi editado';
export const NEW_PROMOTER = 'Novo promotor';
export const REGISTER_NEW_PROMOTER = 'Cadastrar novo promotor';
export const SAVE_LINKED_AGREEMENT = 'Salvar e vincular a um acordo';
export const SAVE_AND_CONTINUE = 'Salvar e continuar';
export const ACCESS_CLEARED = 'Acesso liberado';
export const AUTO_ACTIVE_PROMOTER_TEXT =
  'Ao marcar “Acesso liberado” o acordo não passará pela aprovação da indústria e todos os promotores que forem vinculados estarão automaticamente liberados para visitar as lojas da rede.';
export const RELEASE_ACCESS = 'Liberar Acesso';
export const RELEASED = 'Liberado';
export const SEE_ACCESS = 'Ver Acesso';
export const CHECK_IN = 'Check-in';
export const CHECK_OUT = 'Check-out';
export const TOTAL = 'Total';
export const IN_PROGRESS = 'Em andamento';
export const ERROR_LABEL = 'Erro';
export const FINISHED_LABEL = 'Finalizada';
export const WITHOUT_CHECK_OUT = 'Sem check-out';
export const DO_CHECK_OUT = 'Fazer check-out';
export const DO_CHECK_IN = 'Fazer check-in';
export const DO_PROMOTER_CHECK_IN = 'Fazer check-in do promotor';
export const PROMOTER_CHECK_OUT = 'Check-out do promotor';
export const HOW_WAS_CHECK_OUT = 'Como foi o check-out?';
export const PROMOTER_SOUGHT = 'Promotor procurou';
export const CHECK_OUT_LEFT = 'Saiu sem fazer check-out';
export const CHECK_OUT_CONFIRMED = 'Check-out confirmado!';
export const CHECK_OUT_STORE_REALIZED = 'realizou o check-out na sua loja.';
export const ACCESS_NOT_RELEASED = 'Acesso não liberado!';
export const ACCESS_RELEASED = 'Acesso liberado!';
export const PROMOTER_CHECK_IN = 'Check-in do promotor';
export const TYPE_PROMOTOR_CPF_OR_NAME = 'Digite o nome ou CPF do promotor';
export const IS_FREE_TO_START = 'está liberado para começar';
export const WORK_AT_YOUR_STORE = 'o trabalho na sua loja.';
export const MORE_DETAILS_ASK_TO = 'Para mais detalhes, solicite ao';
export const CONTACT_RESPONSIBLE_PERSON = 'promotor contatar o responsável.';
export const PLEASE_TRY_AGAIN_OR_CONTACT_SUPPORT_TEAM =
  'Por favor, tente novamente ou, se o erro persistir, entre em contato com nossa equipe de suporte.';
export const CHECKOUT_ERROR_PROBLEM =
  'Desculpe, estamos com um problema para finalizar o check-out';
export const CHECK_OUT_ERROR = `${CHECKOUT_ERROR_PROBLEM}. ${PLEASE_TRY_AGAIN_OR_CONTACT_SUPPORT_TEAM}`;
export const CPF_NO_REGISTRATION =
  'O CPF digitado ainda não possui cadastro. Solicite ao promotor contatar o responsável.';
export const DAY_VISITS = 'Visitas do dia';
export const VISIT_HISTORY = 'Histórico de visitas';
export const NO_VISITS_OF_DAY =
  'Você ainda não tem visitas de promotores hoje.';
export const GOOD_TO_SEE_YOU = 'Que bom ver você!';
export const DO_CHECK_IN_TO_CONTINUE =
  'Faça o check-in para iniciar o trabalho no PDV.';
export const BACKGROUND_IMG_ALT = 'Background image';
export const DO_PDV_CHECK_IN = 'Fazer check-in no PDV';
export const ENTER_CPF_TO_CHECK_IN = 'Digite seu CPF para liberar o acesso';
export const EXPORT = 'Exportar';
export const SUSPICIOUS_PRODUCTS = 'Produtos suspeitos';
export const DAYS_WITHOUT_SALE = 'Dias sem vendas';
export const SHOPPERS_PER_DAY = 'Shoppers/Dia';
export const ESTIMATED_SALE = 'Venda estimada';
export const STATUS_AND_PROBLEMS = 'Status e problemas';
export const SUSPENSION_DEADLINE = 'Data limite da suspensão';
export const CONFERENCES = 'Conferências';
export const VERSION = 'Versão';
export const TYPE_A_FEW_CHARACTERS =
  'Digite alguns caracteres para realizar a busca';
export const EMPTY_AGREEMENT_TABLE = 'Não foi possível encontrar um acordo';
export const EMPTY_PROMOTER_TABLE = 'Não foi possível encontrar um promotor';
export const EMPTY_RESTOCK_REPORT_TABLE =
  'Não foi possível encontrar um registro de conferência';
export const EMPTY_RETAIL_TABLE = 'Não foi possível encontrar uma rede';
export const EMPTY_SHELF_CHECK_MISSION_TABLE =
  'Não foi possível encontrar uma missão';
export const EMPTY_STORE_TABLE = 'Não foi possível encontrar uma loja';
export const EMPTY_USER_TABLE = 'Não foi possível encontrar um usuário';
export const EMPTY_VISIT_TABLE = 'Não foi possível encontrar uma visita';
export const AUTO_CHECKIN = 'Auto check in';
export const EMPTY_ENGAGEMENT_TABLE =
  'Não foi possível encontrar um fornecedor';
export const PROMOTER_ANALYSIS = 'Análise de promotores';
export const VISITS_DASHBOARD = 'Painel de Visitas';
export const TOTAL_DURATION = 'Duração total';
export const DAILY_AVERAGE_DURATION = 'Duração média diária';
export const DAILY_DURATION = 'Duração (dias)';
export const AVERAGE_VISIT_DURATION = 'Duração média da visita';
export const POSITION = 'Posição';
export const PROMOTER_ENGAGEMENT = 'Engajamento de promotores';
export const CHECKIN_QUANTITY = 'Quantidade check-in';
export const VISITS_QUANTITY = 'Quantidade de visitas';
export const MAX_START_DATE_MESSAGE =
  'Deve ser igual ou anterior à data de fim';
export const MAX_END_DATE_MESSAGE = 'Deve ser uma data no futuro';
export const MIN_END_DATE_MESSAGE =
  'Deve ser igual ou posterior à data de início';
export const CHECK_IN_TOTAL = 'Total de check-in';
export const CHECKOUT_AMOUNT = 'Quantidade check-out';
export const SUPPLIERS_AMOUNT = 'Quantidade fornecedores';
export const AVERAGE_DURATION_VISITS = 'Duração média visitas';
export const CHECKOUTS_ACCOUNTED = 'Saídas contabilizadas';
export const AVERAGE_DAILY_VISITS = 'Média de visitas diárias';
export const YOU_MUST_CHOOSE_AT_LEAST_TWO_DAYS_WITH_VISITS =
  'Você precisa escolher ao menos dois dias com visitas diferentes';
export const NO_REGISTERED_VISITS_FOR_APPLIED_FILTERS =
  'Não há visitas registradas para os filtros aplicados';
export const FORGETFULNESS = 'Esquecimentos';
export const HI = 'Oi';
export const REMEMBER_CHECKOUT_PROMOTER_MESSAGE =
  'Não esqueça de realizar o check-out ao finalizar sua visita.';
export const SECURITY_REMEMBER_CHECKOUT_THE_PROMOTER =
  'Informe ao promotor para não esquecer de realizar o check-out ao finalizar a visita.';
export const SUCCESS_CHECKIN_MESSAGE_PROMOTER =
  'Seu acesso foi liberado. Bom trabalho!';
export const WARNING_FORGET = 'Alerta de esquecimento!';
export const SECURITY_CHECKOUT_FORGET_WARNING_THE_PROMOTER_MESSAGE =
  'não realizou o check-out na sua última visita em';
export const PROMOTER_CHECKOUT_FORGET_WARNING_MESSAGE =
  'Você não realizou o check-out na sua última visita em';
export const SECURITY_INFORM_AVERT_BLOCK_MESSAGE_THE_PROMOTER =
  'Informe ao promotor para evitar o seu bloqueio.';
export const INFORM_AVERT_BLOCK_MESSAGE =
  'Evite o seu bloqueio, não esqueça de realizar o check-out ao finalizar sua visita.';
export const CONTINUE = 'Continuar';
export const PROMOTER_RELEASED_TO_WORK =
  'está liberado para começar o trabalho na sua loja.';
export const SUSPENDED_ACCESS = 'Acesso suspenso';
export const EXCEEDED_AMOUNT_FORGETFULNESS =
  'ultrapassou a quantidade de esquecimentos.';
export const ASK_STORE_MANAGER_TO_UNLOCK =
  'Solicite para um Responsável de loja desbloqueá-lo.';
export const YOU = 'Você';
export const RELEASE_PROMOTER_ACCESS = 'Liberar acesso do promotor';
export const AT_STORE = 'Em loja';
export const POINT_OF_PURCHASE_DISPLAY = 'Pontos Extras';
export const PROMOTIONAL_SPOTS = 'Pontos Promocionais';
export const PROMOTIONAL_CALENDARS = 'Calendários promocionais';
export const ADDRESSINGS = 'Endereçamentos';
export const NEW_PROMOTIONAL_CALENDAR = 'Novo calendário promocional';
export const FILL_PROMOTIONAL_CALENDAR_PERIODS =
  'preencha os períodos que farão parte dos Pontos promocionais.';
export const CALENDAR_NAME = 'Nome do calendário';
export const PERIODS = 'Períodos';
export const NEW_PERIODS_PROMOTIONAL = 'Inserir novo período promocional';
export const PERIODS_REGISTER = 'Períodos registrados';
export const EDIT_CALENDAR = 'Editar calendário';
export const SAVE_CALENDAR = 'Salvar calendario';
export const ADD_CALENDAR = '+ Adicionar';
export const DELETE_CALENDAR = 'Deletar calendário';
export const NEW_CALENDAR = 'Novo calendário';
export const CONFIRM = 'Confirmar';
export const ALERT_ON_DELETE_PROMOTIONAL_CALENDAR =
  'Atenção! Ao confirmar, todos os pontos promocionais serão desvinculados deste calendário.';
export const LIMIT_CALENDAR = 'Limite negociação';
export const PROMOTIONAL_SPOT = 'Ponto promocional';
export const MUST_CREATE_PROMOTIONAL_CALENDAR_PART1 =
  'Para iniciar esta etapa, é preciso criar um ';
export const MUST_CREATE_PROMOTIONAL_CALENDAR_PART2 =
  ' em Calendários promocionais.';
export const CREATE_NEW_PROMOTIONAL_SPOT_PART1 = 'Clique em ';
export const CREATE_NEW_PROMOTIONAL_SPOT_PART2 =
  ' e preencha os dados solicitados.';
export const PROMOTIONAL_SPOT_TYPE = 'Tipo de ponto promocional';
export const CALENDAR = 'Calendário';
export const NEW_PROMOTIONAL_SPOT = 'Novo ponto promocional';
export const ASSOCIATED_CALENDAR = 'Calendário vinculado';
export const EDIT_PROMOTIONAL_SPOT = 'Editar ponto promocional';

export const DELETE_PROMOTIONAL_SPOT = 'Excluir ponto promocional';
export const EMPTY_PROMOTIONAL_SPOT_TABLE =
  'Não foi possível encontrar um ponto promocional.';
export const NEGOTIATION_LIMIT = 'Limite negociação';
export const PERIODS_REGISTERED = 'Períodos registrados';
export const EDIT_REGISTERED_PERIOD = 'Editar período';
export const DELETE_REGISTERED_PERIOD = 'Excluir período';
export const DELETE_PROMOTIONAL_SPOT_MESSAGE =
  'Atenção! Ao confirmar, todos os endereçamentos e negociações futuras a este ponto promocional serão desvinculados.';
export const GO_TO_PROMOTIONAL_CALENDAR = 'Ir para Calendário promocional';
export const CLICK_ON = 'Clique em ';
export const NEW_ADDRESS = 'Novo endereçamento';

export const FILL_IN_THE_DATA_REQUESTED = ' e preencha os dados solicitados.';
export const EDIT_ADDRESSING = 'Editar endereçamento';
export const DELETE_ADDRESSING = 'Excluir endereçamento';
export const PROMOTIONAL_SPOT_NAME = 'Nome ponto promocional';
export const ADDRESSING = 'Endereçamento';
export const CONFIRM_DELETE_ADDRESSING =
  'Atenção! Ao confirmar, qualquer negociação criada a partir de hoje não poderá ser vinculada a este endereçamento.'
export const CONFIRM_DELETE_ADDRESSING_EXTRA =
  'Negociações vinculadas anteriormente à exclusão do endereçamento não serão afetadas';
export const STORE_SECTOR = 'Setor da loja';
export const STORE_AREA = 'Área da loja';
export const EMPTY_CALENDAR_PERIODS_TABLE =
  'Nenhum período promocional registrado';
export const ALREADY_REGISTERED_CALENDAR_NAME =
  'Nome já cadastrado em outro calendário';
export const ALREADY_REGISTERED_PROMOTIONAL_SPOT_NAME =
  'Nome já cadastrado em outro ponto promocional';
export const ADD_CALENDAR_SUCCESS = 'O calendário foi cadastrado com sucesso';
export const ADD_CALENDAR_ERROR =
  'Erro ao criar calendário';
export const UPDATE_CALENDAR_PERIOD = 'Atualizar período promocional';
export const ALREADY_ADDED_DATE = 'Data já adicionada';
export const INVALID_END_DATE = 'Data de fim inválida';
export const ALREADY_ADDED_END_DATE = 'Data de fim já adicionada';
export const EDIT_CALENDAR_SUCCESS = 'O calendário foi editado com sucesso';
export const EDIT_CALENDAR_ERROR =
  'Erro ao editar calendário';
export const GET_PROMOTIONAL_SPOT_PANEL_ERROR =
  'Erro ao carregar painel, Por favor, aguarde alguns instantes e tente novamente.';
export const DELETE_PROMOTIONAL_SPOT_ERROR =
  'Erro ao excluir o ponto promocional. Por favor, aguarde alguns instantes e tente novamente.';
export const CREATE_PROMOTIONAL_SPOT_SUCCESS =
  'O ponto promocional foi criado com sucesso';
export const UPDATE_PROMOTIONAL_SPOT_SUCCESS =
  'O ponto promocional foi editado com sucesso';
export const UPDATE_PROMOTIONAL_SPOT_ERROR =
  'Erro ao editar o ponto promocional. Por favor, aguarde alguns instantes e tente novamente.';
export const DELETE_PROMOTIONAL_SPOT_SUCCESS =
  'O ponto promocional foi excluído com sucesso';
export const CREATE_PROMOTIONAL_SPOT_ERROR =
  'Erro ao criar o ponto promocional. Por favor, aguarde alguns instantes e tente novamente.';
export const CREATE_PROMOTIONAL_SPOT_ERROR_DUPLICATED =
  'Nome já cadastrado em outro endereçamento.';

export const AND = 'e';
export const EXPIRATION_REPORT = 'Relatório de validade';
export const EXPIRATION_PANEL = 'Painel de validade';
export const CURRENT_RISK = 'Risco atual';
export const EXPIRATION_DATE = 'Data de vencimento';
export const QUANTITY_ON_STOCK = 'Quantidade em estoque';
export const QUANTITY_TO_EXPIRE = 'Quantidade a vencer';
export const LAST_CHECK = 'Última conferência';
export const EMPTY_EXPIRATION_REPORTS_TABLE =
  'Não foram encontrados problemas de validade';
export const EMPTY_ADDRESSES_TABLE =
  'Não foi possível encontrar um endereçamento.';
export const NEGOTIATIONS_PANEL = 'Painel de Negociações';
export const PROMOTIONAL_SPOTS_HAS_NEGOTIATED = 'Ocupações';
export const PROMOTIONAL_SPOTS_HAS_NEGOTIATED_OCUPATION = 'Pontos Ocupados';
export const PROMOTIONAL_SPOTS_HAS_NEGOTIATED_OCUPATION_PERCENT = 'Ocupação';
export const PROMOTIONAL_EXECUTION = 'Execuções';
export const PROMOTIONAL_EXECUTION_PEDING = 'Pendentes';
export const PROMOTIONAL_SPOTS_NOT_EXECUTED = 'Não Execuções';
export const PROMOTIONAL_SPOTS_EXPIRED = 'Pontos Expirados';
export const PROMOTIONAL_SPOTS_PENDING = 'Pontos Pendentes';

export const PROMOTIONAL_EXECUTION_OCUPATION = 'Pontos executados';
export const PROMOTIONAL_EXECUTION_OCUPATION_PERCENT = 'Execução';
export const PROMOTIONAL_SPOT_REPORT = 'Relatório';
export const PROMOTIONAL_SPOT_NEGOTIATIONS_REPORTS_TITLE =
  'Relatório de Negociações';
export const PROMOTIONAL_SPOT_NEGOTIATIONS_REPORTS = 'Produtos Negociados';
export const PROMOTIONAL_SPOT_NEGOTIATIONS_PROBLEMS_REPORTS =
  'Relatório de Problemas';

export const MONTH = 'Mês';
export const YEAR = 'Ano';
export const PERIOD = 'Período';
export const PROBLEM_TYPE = 'Tipo de problema';
export const VIEW_ADDRESSING_SPOT = 'Visualizar local do endereçamento';

export const SELECT_AN_ADDRESSING_TO_SHOW_MORE_DETAILS =
  'Selecione um endereçamento para exibir mais detalhes';
export const ADD_PROMOTIONAL_SPOT = 'Adicionar ponto promocional';

export const VIEW_PROMOTIONAL_SPOT = 'Visualizar ponto promocional';
export const DELETE_NEGOTIATION = 'Deletar negociação';
export const OVERRIDE_NEGOTIATION = 'Conflitos de negociações';
export const OVERRIDE_NEGOTIATION_MESSAGE =
  'Atenção! Identificamos conflitos de negociações para os endereçamentos selecionados. Confira os períodos e lojas abaixo e caso queira prosseguir, as negociações conflitantes serão substituidas.';
export const ATENTION_WHEN_CONFIRM_ALL_STORES_NEGOTIATION_UNLINKED =
  'Atenção! Ao confirmar, todas as lojas desta negociação serão desvinculadas.';
export const FIX_NEGOTIATION = 'Fixar negociação';
export const SELECT_A_FINAL_DATE = 'Selecione uma data final';
export const SELECT_LIMIT_DATE_TO_FIX_NEGOTIATION =
  'Selecione até qual data deseja fixar essa negociação';
export const UNFIX_NEGOTIATION_TITLE = 'Desbloquear negociação';
export const UNFIX_NEGOTIATION =
  'Atenção! Ao confirmar, todas as negociações futuras que estão vinculadas a este acordo serão apagadas.';
export const NEW_NEGOTIATION = 'Nova negociação';
export const EDIT_NEGOTIATION = 'Editar negociação';
export const PROBLEMS = 'Problemas';
export const REPORTED_PROBLEMS = 'Problemas reportados';
export const VIEW_PROBLEMS = 'Visualizar problema';
export const REPORTED_AT = 'Reportado em';
export const REPORTED_BY = 'Reportado por';
export const MORE_OPTIONS = 'Mais opções';
export const BY = 'Por';
export const LACK_OF_PRODUCT = 'Falta de produto';
export const ADD_ONE_AT_EACH_STORE = 'Incluir 1 em cada loja';
export const CLEAR_ALL_SPOTS = 'Limpar todas ocupações';
export const TO_DELETE = 'Deletar';
export const UNBLOCK_NEGOTIATIONS = 'Desbloquear negociações';
export const BLOCK_NEGOTIATIONS = 'Bloquear negociações';
export const FAMILY = 'Família';

export const PRODUCT = 'Produto';
export const PRODUCTS = 'Produtos';
export const OBSERVATIONS = 'Observações';
export const AVAILABILITY_ON_STORE = 'Disponibilidade em loja';

export const EMPTY_NEGOTIATION_PANEL =
  'Não foi possível encontrar um Ponto Promocional';
export const AVALIABLE_SPOT_FOR_NEGOTIATION = 'espaços disponíveis';
export const CREATE_PROMOTIONAL_NEGOTIATION_SUCCESS =
  'A negociação foi criada.';
export const CREATE_PROMOTIONAL_NEGOTIATION_ERROR =
  'Erro ao criar negociação, Por favor aguarde alguns instantes e tente novamente.';
export const UPDATE_PROMOTIONAL_NEGOTIATION_SUCCESS =
  'A negociação foi editada.';
export const UPDATE_PROMOTIONAL_NEGOTIATION_ERROR =
  'Erro ao editar negociação, Por favor aguarde alguns instantes e tente novamente.';
export const DELETE_PROMOTIONAL_NEGOTIATION_SUCCESS =
  'A negociação foi deletada.';
export const DELETE_PROMOTIONAL_NEGOTIATION_ERROR =
  'Erro ao deletar negociação, Por favor aguarde alguns instantes e tente novamente.';
export const LOCK_PROMOTIONAL_NEGOTIATION_SUCCESS =
  'A negociação foi bloqueada.';
export const LOCK_PROMOTIONAL_NEGOTIATION_ERROR =
  'Erro ao bloquear negociação, Por favor aguarde alguns instantes e tente novamente.';
export const UNLOCK_PROMOTIONAL_NEGOTIATION_SUCCESS =
  'A negociação foi desbloquedada.';
export const UNLOCK_PROMOTIONAL_NEGOTIATION_ERROR =
  'Erro ao bloquear negociação, Por favor aguarde alguns instantes e tente novamente.';
export const CREATE_PROMOTIONAL_NEGOTIATION_EXECUTION_SUCCESS =
  'A execução foi criada.';
export const CREATE_PROMOTIONAL_NEGOTIATION_EXECUTION_ERROR =
  'Erro ao criar execução, Por favor aguarde alguns instantes e tente novamente.';
export const UPDATE_PROMOTIONAL_NEGOTIATION_EXECUTION_SUCCESS =
  'A execução foi editada.';
export const UPDATE_PROMOTIONAL_NEGOTIATION_EXECUTION_ERROR =
  'Erro ao editar execução, Por favor aguarde alguns instantes e tente novamente.';
export const DELETE_PROMOTIONAL_NEGOTIATION_EXECUTION_SUCCESS =
  'A execução foi deletada.';
export const DELETE_PROMOTIONAL_NEGOTIATION_EXECUTION_ERROR =
  'Erro ao deletar execução, Por favor aguarde alguns instantes e tente novamente.';
export const INCREMENT_PROMOTIONAL_NEGOTIATION_EXECUTION_SUCCESS =
  'Execuções criadas com sucesso';
export const INCREMENT_PROMOTIONAL_NEGOTIATION_EXECUTION_ERROR_OUT_OF_LIMIT =
  'Erro ao criar execuções. não há mais endereçamentos disponiveis';
export const INCREMENT_PROMOTIONAL_NEGOTIATION_EXECUTION_ERROR =
  'Erro ao criar execuções, Por favor aguarde alguns instantes e tente novamente.';

export const INCREMENT_PROMOTIONAL_NEGOTIATION_EXECUTION_ERROR_ADRESSING_IN_USE =
  'Erro ao criar execuções. O endereçamento selecionado já está sendo utilizado em outro ponto promocional. Por favor, remova o endereçamento do outro ponto e tente novamente.';

export const DECREMENT_PROMOTIONAL_NEGOTIATION_EXECUTION_SUCCESS =
  'Execuções removidas com sucesso';
export const DECREMENT_PROMOTIONAL_NEGOTIATION_EXECUTION_ERROR =
  'Erro ao remover execuções, Por favor aguarde alguns instantes e tente novamente.';

export const PROMOTIONAL_SPOT_REPORT_STORE = 'Loja';
export const PROMOTIONAL_SPOT_REPORT_STORE_LABEL = 'Nome da Loja';
export const PROMOTIONAL_SPOT_REPORT_DATE_PERIOD = 'Início/Fim da execução';
export const PROMOTIONAL_SPOT_REPORT_DATE_END_AT = 'Data final da execução';
export const PROMOTIONAL_SPOT_REPORT_SELLER = 'Vendedor';
export const PROMOTIONAL_SPOT_REPORT_SUPPLIERS = 'Fornecedores';
export const PROMOTIONAL_SPOT_REPORT_SPOT_TYPE = 'Tipo de ponto promocional';
export const PROMOTIONAL_SPOT_REPORT_DELAY = 'Atraso na execução';
export const PROMOTIONAL_SPOT_REPORT_EXECUTED_DATE = 'Data de execução';
export const PROMOTIONAL_SPOT_REPORT_IS_EXECUTED = 'Atraso na negociação';
export const PROMOTIONAL_SPOT_REPORT_DATE_START_AT_LABEL = 'Data de início';
export const PROMOTIONAL_SPOT_REPORT_DATE_END_AT_LABEL = 'Data de fim';
export const PROMOTIONAL_SPOT_REPORT_DATE_REPORTED_LABEL = 'Data de ocorrência';
export const PROMOTIONAL_SPOT_REPORT_DATE_SOLVED_LABEL = 'Data de solução';
export const PROMOTIONAL_SPOT_REPORT_PROBLEMS_EMPTY_DATA =
  'Não foi possível encontrar um registro de problemas';
export const PROMOTIONAL_SPOT_REPORT_PRODUCTS_EMPTY_DATA =
  'Não foi possível encontrar um registro de negociações';
export const PROMOTIONAL_SPOT_REPORT_PRODUCTS_MENU = 'Produtos negociados';
export const ALREAY_EXIST_A_MORE_RECENT_VISIT =
  'Já existe outra visita mais recente';
export const VISIT_DUE_DATE_EXPIRED = 'A data de vencimento da visita expirou';
export const VISIT_ALREADY_HAS_REGISTERED_CHECKOUT =
  'Visita já possui checkout registrado';
export const MISSION_TYPE = 'Tipo de missão';
export const MAX_DATE_INTERVAL_EXPORT_REPORT =
  'Você só pode exportar relatórios com intervalo máximo de 1 mês entre as datas de início e fim';
export const EXECUTED = 'Executado';
export const EXECUTED_FOR = 'Executado por';
export const EXECUTED_IN = 'Executado em';
export const EXECUTEDS = 'Executados';
export const NEGOTIATEDS = 'Negociados';
export const STORE_MANAGER_CPF = 'CPF chefe de loja';
export const TYPE_PRODUCT_NAME = 'Digite o nome do produto';
export const ALL_DAYS = 'Todos os dias';
export const EXPIRED_NEGOTIATIONS = 'Negociações encerradas';
export const EXPIRE_IN = 'Negociações encerram em';
export const CONFIRM_OVERRIDE_NEGOTIATIONS = 'Substituir negociações';
export const EXECUTION_PICTURE = 'Foto de execução';
export const REFUSE_EXECUTION = 'Recusar execução';
export const ADDRESSING_PLACE = 'Local de endereçamento';
export const NO_SUPPLIERS_YET = 'Você ainda não possui fornecedores';
export const REGISTER_SUPPLIER = 'Cadastrar fornecedor';
export const NEW_SUPPLIER = 'Novo fornecedor';
export const EDIT_SUPPLIER = 'Editar fornecedor';
export const DELETE_SUPPLIER = 'Deletar fornecedor';
export const SUPPLIER_WILL_BE_INACTIVATED_ON_ALL_AGREEMENTS =
  'Atenção! Ao confirmar, este fornecedor será inativado em todos os acordos que está vinculado';
export const INTERNAL_CODE = 'Código interno';
export const INTERNAL_NAME = 'Nome interno';
export const CORPORATE_NAME = 'Razão social';
export const ACTIVE_SUPPLIER = 'Fornecedor ativo';
export const THE_SUPPLIER_WAS_EDITED = 'O fornecedor foi editado';
export const THE_SUPPLIER_WAS_CREATED = 'O fornecedor foi cadastrado';
export const THE_SUPPLIER_WAS_DELETED = 'O fornecedor foi deletado';
export const ERROR_ON_DELETE_SUPPLIER =
  'Erro ao deletar fornecedor. Por favor, tente novamente';
export const LAST_REPORTED_PROBLEM = 'Último problema reportado';
export const NOT_EXISTENT_SPOT = 'Ponto não existe';
export const MISSION_MERCHANDISING_CONTENT = 'Falta de material de merchandising';
export const NO_PROMOTER_VISIT = 'Promotor não visitou a loja';
export const DUPLICATED_PERIOD_IN_CALENDAR_ERROR = 
  'Há um período duplicado no calendário';
export const INCONSISTENT_SEQUENCE_OF_PERIODS_ERROR = 
  'Há inconsistências nos períodos do calendário';
export const NOT_VALID_PROMOTIONAL_CALENDAR_STATUS_ERROR = 
  'O calendário não possui um status válido';
export const PROMOTIONAL_CALENDAR_DUPLICATED_NAME_ERROR = 
  'O nome do calendário está duplicado';
export const PROMOTIONAL_CALENDAR_NOT_EXIST_ERROR = 
  'O calendário não existe';
export const PROMOTIONAL_CALENDAR_PERIOD_NOT_EXIST_ERROR = 
  'Há um período não existente no calendário';
export const AN_UNEXPECTED_ERROR_OCCURRED_AT_ACTION = 
  'Um erro inesperado aconteceu ao executar esta ação';
export const PLEASE_TRY_AGAIN_SOON = 'Por favor, tente novamente em instantes.';
export const PROMOTER_DONT_HAVE_ACCESS_TO_AGREEMENT = 
  'O promotor não possui acesso a um acordo com visita agendada para esta loja';
export const CANNOT_INFER_USER_STORE = 'Não é possível inferir a loja do usuário atual';
export const UNEXPECTED_ERROR_OCCURRED_ON_CHECKIN = 
  'Um erro inesperado aconteceu ao realizar o check-in';
export const LIBERATE_PROMOTOR_ACCESS = 'Liberar acesso do promotor';
export const SELECT_REFUSE_REASON = 'Selecione o motivo da recusa';
export const REFUSED_SUCCESS = 'Execução recusada com sucesso!';
export const REFUSED_FAILED = 'Verifique as informações e tente novamente!';
export const NEED_REASON = 'Necessário selecionar um motivo a recusa!';


