import React from 'react';
import { Typography } from '@material-ui/core';
import classes from './ResumeCard.module.scss';

type Props = {
  total: number;
  count: number;
  showTotal?: boolean;
};

const FractionalValue = ({ count, total, showTotal = true }: Props) => {
  return (
    <Typography variant="h1" className={classes.label}>
      <span className={classes.highlight}>{count}</span>
      {showTotal ? (
        <span className={classes.sizeHighLight}>/{total}</span>
      ) : (
        <></>
      )}
    </Typography>
  );
};

export default FractionalValue;
