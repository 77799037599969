import { CSSProperties } from "react";

type StyleSheet = {
  [key: string]: CSSProperties;
}

export const styles: StyleSheet = {
  input: { 
    width: '100%',
  },
  textarea: {
    width: '100%',
  },
  autocomplete: { 
    width: '100%',
    marginBottom: '17px',
  },
  defaultButton: {
    width: 'auto',
  },
  dialog: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
  },
}
