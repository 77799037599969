import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { AutocompleteRenderInputParams } from '@material-ui/lab';
import { 
  Grid,
  Chip,
  Checkbox,
  DialogContent,
  DialogActions,
  FormControlLabel, 
  TextField as MUITextField,
} from '@material-ui/core';
import TextField from '../../../../components/InputTextField';
import ModalTitle from '../../../../components/Modal/Title';
import Autocomplete from '../../../../components/Autocomplete';
import ModalContainer from '../../../../containers/Modal/Container';
import {
  CancelButton,
  DefaultButton,
} from '../../../../components/Buttons';
import { SupplierForm } from '../../utils';
import { ActiveStatus } from '../../../../core/constants/active-status';
import { profileCheck } from '../../../../core/profiles';
import { RetailEntityType } from '../../../../core/types/retail/retail-entity.type';
import { CnpjTextMaskCustom } from '../../../../core/masks/masks';
import { 
  createSupplier, 
  updateSupplier, 
  SupplierAPIError, 
} from '../../../../core/services/suppliers/suppliers-api';
import {
  SAVE,
  CNPJ,
  INTERNAL_NAME,
  INTERNAL_CODE,
  CORPORATE_NAME,
  ACTIVE_SUPPLIER,
  FIELD_NOT_EMPTY,
  THE_SUPPLIER_WAS_EDITED,
  THE_SUPPLIER_WAS_CREATED,
} from '../../../../core/constants/dictionary';
import { defaultFormFields } from './utils';
import classes from '../../Supplier.module.scss';

type Props = {
  data?: SupplierForm;
  title: string;
  retails: RetailEntityType[],
  isOpen: boolean;
  isEdit?: boolean;
  onClose: Function;
  onSubmit: Function;
};

const BaseModal = ({
  data,
  title,
  retails,
  isOpen,
  isEdit,
  onClose,
  onSubmit,
}: Props) => {
  const [name, setName] = useState(data?.name ?? '');
  const [cnpj, setCnpj] = useState(data?.cnpj ?? '');
  const [internalCode, setInternalCode] = useState(data?.internalCode ?? '');
  const [internalName, setInternalName] = useState(data?.internalName ?? '');
  const [status, setStatus] = useState(data?.status ?? ActiveStatus.ACTIVE);
  const [retail, setRetail] = useState<RetailEntityType | null>(
    retails.find(r => r.id === data?.retailId) ?? null
  );
  const [formFields, setFormFields] = useState(defaultFormFields);
  
  useEffect(() => {
    if (data) {
      setName(data.name);
      setCnpj(data.cnpj);
      setInternalCode(data.internalCode);
      setInternalName(data.internalName);
      setStatus(data.status ?? ActiveStatus.ACTIVE);
      setRetail(
        retails.find(r => r.id === data.retailId) ?? null
      );
    }
  }, [data]);

  const retailField = useMemo(() => {
    return profileCheck.isSuperAdmin() ? (
      <Grid container>
        <Grid item xs={6}>
          <Autocomplete
            size='small'
            value={retail}
            options={retails}
            getOptionLabel={(retail: RetailEntityType) => retail.name}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <MUITextField 
                {...params} 
                label={'Rede'} 
                variant="outlined" 
                error={formFields.retail.hasError}
                helperText={formFields.retail.errorText}
                className={classes.autocompleteInput}
                style={{ width: 'calc(100% - 8px)' }}
              />
            )}
            renderTags={(
              value: RetailEntityType[],
              getTagProps: Function,
              closeInput: boolean,
            ) => {
              return value.map((option: RetailEntityType, index: number) => (
                <Chip
                  key={index}
                  label={option.name}
                  {...getTagProps({ index })}
                  disabled={closeInput}
                />
              ))
            }}
            onChange={(_: never, value: RetailEntityType) => {
              if (formFields.cnpj.hasError) {
                setFormFields(prevState => ({
                  ...prevState,
                  retail: {
                    hasError: false,
                    errorText: '',
                  }
                }));
              }
              setRetail(value);
            }}
            style={{width: '100%', marginBottom: '31px'}}
          />
        </Grid>
      </Grid>
    ) : null;
  }, [retail, retails, formFields]);
    
  const onSubmitHandler = async () => {
    let canSubmitRequest = true;

    if (profileCheck.isSuperAdmin() && retail === null) {
      canSubmitRequest = false;
      setFormFields(prevState => ({
        ...prevState,
        retail: {
          hasError: true,
          errorText: FIELD_NOT_EMPTY,
        }
      }));
    }
    if (! name) {
      canSubmitRequest = false;
      setFormFields(prevState => ({
        ...prevState,
        name: {
          hasError: true,
          errorText: FIELD_NOT_EMPTY,
        }
      }));
    }
    if (! cnpj) {
      canSubmitRequest = false;
      setFormFields(prevState => ({
        ...prevState,
        cnpj: {
          hasError: true,
          errorText: FIELD_NOT_EMPTY,
        }
      }));
    }
    if (! internalCode) {
      canSubmitRequest = false;
      setFormFields(prevState => ({
        ...prevState,
        internalCode: {
          hasError: true,
          errorText: FIELD_NOT_EMPTY,
        }
      }));
    }

    if (! canSubmitRequest) {
      return;
    }
    
    const supplierDto = {
      name,
      cnpj,
      status,
      internalCode,
      internalName,
      retailId: retail?.id  ??  '',
    };

    try {
      let feedbackMessage = '';
      if (isEdit && data?.id) {
        feedbackMessage = THE_SUPPLIER_WAS_EDITED;
        await updateSupplier({ 
          id: data.id,
          ...supplierDto, 
        });
      } else {
        feedbackMessage = THE_SUPPLIER_WAS_CREATED;
        await createSupplier(supplierDto);
      }
      onSubmit(feedbackMessage);
    } catch (err) {
      err.response.data?.message.map((message: string) => {
        switch (message) {
          case SupplierAPIError.DUPLICATED_NAME: 
            setFormFields(prevState => ({
              ...prevState,
              name: {
                hasError: true,
                errorText:'Razão social já cadastrada',
              },
            }));
            break;
          
          case SupplierAPIError.DUPLICATED_CNPJ: 
            setFormFields(prevState => ({
              ...prevState,
              cnpj: {
                hasError: true,
                errorText:'CNPJ já cadastrado',
              },
            }));
            break;

          case SupplierAPIError.DUPLICATED_INTERNAL_CODE: 
            setFormFields(prevState => ({
              ...prevState,
              internalCode: {
                hasError: true,
                errorText:'Código já cadastrado',
              },
            }));
            break;
        } 
      });
    }
  }

  return (
    <ModalContainer isOpen={isOpen} closeModalCallback={onClose}>
      <div className={classes.modal}>
        <ModalTitle 
          title={title} 
          className={classes.modalTitle} 
          style={{marginBottom: '40px'}}
        />
        <DialogContent className={classes.body}>
          { retailField }
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                required
                value={cnpj}
                label={CNPJ}
                showError={formFields.cnpj.hasError}
                errorText={formFields.cnpj.errorText}
                InputProps={{ inputComponent: CnpjTextMaskCustom }}
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                  if (formFields.cnpj.hasError) {
                    setFormFields(prevState => ({
                      ...prevState,
                      cnpj: {
                        hasError: false,
                        errorText: '',
                      }
                    }));
                  }
                  setCnpj(evt.currentTarget.value);
                }}
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                value={internalCode}
                label={INTERNAL_CODE}
                showError={formFields.internalCode.hasError}
                errorText={formFields.internalCode.errorText}
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                  if (formFields.internalCode.hasError) {
                    setFormFields(prevState => ({
                      ...prevState,
                      internalCode: {
                        hasError: false,
                        errorText: '',
                      }
                    }));
                  }
                  setInternalCode(evt.currentTarget.value);
                }}
                style={{ width: '100%' }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                required
                value={name}
                label={CORPORATE_NAME}
                showError={formFields.name.hasError}
                errorText={formFields.name.errorText}
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                  if (formFields.name.hasError) {
                    setFormFields(prevState => ({
                      ...prevState,
                      name: {
                        hasError: false,
                        errorText: '',
                      }
                    }));
                  }
                  setName(evt.currentTarget.value);
                }}
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                label={INTERNAL_NAME}
                value={internalName}
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                  setInternalName(evt.currentTarget.value);
                }}
                style={{ width: '100%' }}
              />
            </Grid>
          </Grid>
          { isEdit ? (
            <FormControlLabel
              label={ACTIVE_SUPPLIER}
              control={(
                <Checkbox 
                  color='primary'
                  checked={status === ActiveStatus.ACTIVE} 
                  onChange={(_, checked) => {
                    setStatus(checked ? ActiveStatus.ACTIVE : ActiveStatus.INACTIVE);
                }} />
              )}
            />
          ) : null}
        </DialogContent>
        <DialogActions className={classes.footer}>
          <CancelButton onClick={onClose} />
          <DefaultButton
            label={SAVE}
            onClick={onSubmitHandler}
            style={{width:'auto'}}
          />
        </DialogActions>
      </div>
    </ModalContainer>
  );
};

export default BaseModal;
