import React, { MouseEventHandler } from 'react';
import { MoreVert } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import classes from '../../NegotiationTable.module.scss';
import * as dictionary from '../../../../../../../core/constants/dictionary';

type Props = {
  onClick?: MouseEventHandler;
};

const MoreOptionsIcon = ({ onClick }: Props) => {
  return (
    <Tooltip title={dictionary.MORE_OPTIONS}>
      <MoreVert onClick={onClick} className={classes.moreOptionIcon} />
    </Tooltip>
  );
};

export default MoreOptionsIcon;
