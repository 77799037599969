import React, { MouseEvent } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import {
  PromotionalNegotiationDto,
  PromotionalNegotiationExecutionAddressingStoreDto,
} from '../../../common/types';
import { ActionButtons } from '../NegotiationTable/components';
import classes from '../NegotiationTable/NegotiationTable.module.scss';
import {
  parseNameWithDots,
  parsePromotionalExecutionProductsToText,
  parsePromotionalExecutionSuppliersToText,
} from './utils';
import NegotiationStoreCell from '../NegotiationStoreCell';
import { PromotionalSpotAvaliableAddresingInStore } from '../../types/promotional-negotiation-panel.entity';

type Props<T> = {
  item: T;
  isSelected: boolean;
  stores: any[];
  metricsInStore?: PromotionalSpotAvaliableAddresingInStore[];
  isLoading: boolean;
  isPending: boolean;
  isEnded: boolean;

  onSelectRowHandler: (event: any, rowId: string) => void;
  onSelectEdit: (event: any, negotiation: PromotionalNegotiationDto) => void;
  onDeleted: (event: any, negotiation: PromotionalNegotiationDto) => void;
  onAdd: (negotiationId: string) => void;
  onRemove: (negotiationId: string) => void;
  onClickMore: (
    event: MouseEvent<HTMLButtonElement>,
    executions: PromotionalNegotiationDto['executions'],
    store: PromotionalNegotiationExecutionAddressingStoreDto,
    negotiation: PromotionalNegotiationDto
  ) => void;
  onClickProblem: (
    event: MouseEvent<HTMLButtonElement>,
    executions: PromotionalNegotiationDto['executions']
  ) => void;
  onToggleLock: (event: any, negotiation: PromotionalNegotiationDto) => void;
  isAvaliableOptionsInStore: Function;
};

export const NegotiationTableItem = ({
  isSelected,
  onSelectRowHandler,
  onSelectEdit,
  onDeleted,
  onClickMore,
  onClickProblem,
  onToggleLock,
  onAdd,
  onRemove,
  item,
  stores,
  metricsInStore = [],
  isLoading = false,
  isPending = false,
  isEnded = false,
  isAvaliableOptionsInStore,
}: Props<PromotionalNegotiationDto>) => {
  const { products, id, suppliers } = item;
  const productsName = parseNameWithDots(
    parsePromotionalExecutionProductsToText(products)
  );
  const suppliersName = parseNameWithDots(
    parsePromotionalExecutionSuppliersToText(suppliers)
  );

  return (
    <TableRow
      className={
        item.locked ? classes.locked : isSelected ? classes.selected : ''
      }
      onClick={(event: any) => onSelectRowHandler(event, id)}
    >
      <TableCell className={classes.sticky}>
        <div className={classes.shadow}>
          <ActionButtons
            disabled={isLoading || isEnded}
            onAdd={() => onAdd(item.id)}
            onRemove={() => onRemove(item.id)}
            onDelete={(event) => onDeleted(event, item)}
            onToggleLock={(event) => onToggleLock(event, item)}
            isLocked={item.locked}
          />
          <TableCell
            className={classes.cellSuppliers}
            onClick={(event: any) => onSelectEdit(event, item)}
          >
            {suppliersName ?? 'Não possui'}
          </TableCell>
          <TableCell
            className={classes.cellProducts}
            onClick={(event: any) => onSelectEdit(event, item)}
          >
            {productsName ?? 'Não póssui'}
          </TableCell>
        </div>
      </TableCell>
      <TableCell className={classes.cellTotal} align="center">
        {item.executions?.filter((item) => item.addressing.store.id).length ??
          0}
      </TableCell>
      {stores.map((store) => (
        <NegotiationStoreCell
          key={store?.id}
          store={store}
          item={item}
          totalAvaliableAddressings={
            metricsInStore.find((metric) => metric.storeId === store?.id)
              ?.total ?? 0
          }
          disabled={isAvaliableOptionsInStore(store)}
          isPending={isPending}
          onClickMore={onClickMore}
          onClickProblem={onClickProblem}
        />
      ))}
    </TableRow>
  );
};
