import React from 'react';
import { Box, TablePagination, Typography } from '@material-ui/core';
import { MTableToolbar } from 'material-table';
import FilterTable from '../../../../components/Tables/FilterTable';
import { EmptyDataMessageRow } from '../../../../components/Tables/DefaultTable';
import { styles, parseDuration } from '../../utils';
import { title as titleStyle } from '../../styles';
import * as dictionary from '../../../../core/constants/dictionary';

type Props = {
  data: any;
  count: number;
  rowsPerPage: number;
  pageIndex: number;
  searchTextValue: string;
  toolbarInputFocus: boolean;
  onChangePageHandler: Function;
  onChangeRowsPerPageHandler: Function;
  onSearchChangeHandler: Function;
}

const EngagementTable = ({
  data,
  count,
  rowsPerPage,
  pageIndex,
  searchTextValue,
  toolbarInputFocus,
  onChangePageHandler = () => {},
  onChangeRowsPerPageHandler = () => {},
  onSearchChangeHandler = () => {},
}: Props) => {
  return (
    <FilterTable
      title={
        <Typography variant="subtitle2" style={titleStyle}>
          {dictionary.PROMOTER_ENGAGEMENT}
        </Typography>
      }
      data={data}
      options={{
        searchFieldAlignment: 'right',
      }}
      rowsPerPage={rowsPerPage}
      columns={[
        {
          title: dictionary.POSITION,
          field: 'position',
          headerStyle: styles.positionColumn,
          cellStyle: styles.positionColumn,
        },
        {
          title: dictionary.AGREEMENT_CODE,
          field: 'agreementCode',
          headerStyle: styles.agreementCodeColumn,
          cellStyle: styles.agreementCodeColumn,
        },
        {
          title: dictionary.SUPPLIER,
          field: 'supplierName',
          headerStyle: styles.supplierColumn,
          cellStyle: styles.supplierColumn,
        },
        {
          title: dictionary.TOTAL_DURATION,
          field: 'totalDuration',
          render: (row: any) => parseDuration(row.totalDuration),
        },
        {
          title: dictionary.AVERAGE_VISIT_DURATION,
          field: 'dailyAverageDuration',
          render: (row: any) => parseDuration(row.dailyAverageDuration),
        },
      ]}
      localization={{
        body: {
          emptyDataSourceMessage: (
            <EmptyDataMessageRow message={dictionary.EMPTY_ENGAGEMENT_TABLE} />
          ),
        },
      }}
      components={{
        Pagination: (props: any) => {
          return (
            <Box display="flex" justifyContent="flex-end">
              <TablePagination
                {...props}
                count={count}
                page={pageIndex}
                rowsPerPage={rowsPerPage}
                onChangePage={(evt, page) => onChangePageHandler(page)}
                onChangeRowsPerPage={(event) =>
                  onChangeRowsPerPageHandler(parseInt(event.target.value, 10))
                }
                style={styles.tablePagination}
              />
            </Box>
          );
        },
        Toolbar: (props: any) => {
          if (onSearchChangeHandler) {
            return (
              <MTableToolbar
                {...props}
                searchAutoFocus={toolbarInputFocus}
                searchText={searchTextValue}
                onSearchChanged={(value: string) => onSearchChangeHandler(value)}
              />
            );
          }

          return <MTableToolbar {...props} />;
        },
      }}
    />
  );
};

export default EngagementTable;
