import React, { useMemo, useRef, useState } from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import MaterialTable, { MTableToolbar } from 'material-table';
import IconTable from '../../../../../components/Tables/IconTable';
import PatchedPagination from '../../../../../components/PatchedPagination';
import { EmptyDataMessageRow } from '../../../../../components/Tables/DefaultTable';
import { colors } from '../../../../../Theme/colors';
import { mainFontFamily } from '../../../../../Theme/fonts';
import { PromotionalCalendarDto } from '../../../../../core/types/promotional-spot/promotional-calendar.dto';
import Edit from '../../../../../assets/tables/edit.svg';
import Remove from '../../../../../assets/tables/remove.svg';
import * as dictionary from '../../../../../core/constants/dictionary';

type Props = {
  data: PromotionalCalendarDto[];
  page: number;
  total: number;
  rowsPerPage: number;
  searchTextValue: string;
  toolbarInputFocus: boolean;
  onChangePageHandler: Function;
  onChangeRowsPerPageHandler: Function;
  onEditCalendarHandler: Function;
  onDeleteCalendarHandler: Function;
  onSearchChangeHandler?: Function;
}

const PromotionalCalendarsTable = ({ 
  data,
  total,
  page,
  rowsPerPage,
  searchTextValue,
  toolbarInputFocus,
  onChangePageHandler = () => {},
  onChangeRowsPerPageHandler = () => {},
  onSearchChangeHandler = () => {},
  onEditCalendarHandler = () => {},
  onDeleteCalendarHandler = () => {},
}: Props) => {
  const tableRef = useRef(null);
  const [filter, setFilter] = useState(false);
  
  const actions = useMemo(()=> {
    return [
      (rowData: object) => {
        return {
          icon: () => <IconTable icon={Edit} />,
          tooltip: dictionary.EDIT_CALENDAR,
          onClick: (evt: any, rowData: any) => onEditCalendarHandler(rowData),
        }
      },
      (rowData: object) => {
        return {
          icon: () => <IconTable icon={Remove} />,
          tooltip: dictionary.DELETE_CALENDAR,
          onClick: (evt: any, rowData: any) => onDeleteCalendarHandler(rowData),
        }
      },
      {
        icon: FilterListIcon,
        tooltip: dictionary.FILTERS,
        isFreeAction: true,
        onClick: () => setFilter(!filter),
      },
    ]
  }, [
    filter, 
    onEditCalendarHandler, 
    onDeleteCalendarHandler,
  ]);

  return (
    <MaterialTable
      key={data.length}
      data={data}
      title={''}
      tableRef={tableRef}
      columns={[
        {
          field: 'name',
          title: dictionary.CALENDAR_NAME,
          width: "50%",
        },
        {
          field: 'status',
          title: dictionary.STATUS,
          render: ({ status }: any) => status
            ? dictionary.ACTIVE
            : <span style={{color:colors.red_600}}>{dictionary.INACTIVE}</span>
          ,
        },
        {
          field: 'totalPeriods',
          title: dictionary.PERIODS,
          align: 'center',
          sorting: false,
          type: 'numeric',
        },
      ]}
      options={{
        filtering: filter,
        actionsColumnIndex: -1,
        toolbarButtonAlignment: 'left',
        pageSize: rowsPerPage,
        pageSizeOptions: [5, 10, 20],
        rowStyle: { fontFamily: mainFontFamily, fontSize: '13px' },
      }}
      actions={actions}
      localization={{
        header: { actions: '' },
        toolbar: { searchPlaceholder: dictionary.SEARCH },
        body: {
          emptyDataSourceMessage: 
            <EmptyDataMessageRow 
              message={dictionary.EMPTY_VISIT_TABLE} 
            />,
        },
        pagination: {
          labelDisplayedRows: '{from}-{to} de {count}',
          labelRowsSelect: dictionary.LINES,
        },
      }}
      components={{
        Pagination: props => (
          <PatchedPagination 
            {...props} 
            count={total}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangePage={(evt: any, page: number) => onChangePageHandler(page)}
            onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChangeRowsPerPageHandler(parseInt(event.target.value));
              props.onChangeRowsPerPage(event);
            }}
          />
        ),
        Toolbar: (props) => {
          if (onSearchChangeHandler) {
            return (
              <MTableToolbar 
                {...props} 
                searchText={searchTextValue}
                searchAutoFocus={toolbarInputFocus}
                onSearchChanged={(value: string) => onSearchChangeHandler(value)}
              />
            );
          }
          
          return <MTableToolbar {...props} />;
        },
      }}
    />
  );
};

export default PromotionalCalendarsTable;
 