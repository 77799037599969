import React, { MouseEventHandler } from 'react';
import { Grid, TableCell, Tooltip, Typography } from '@material-ui/core';
import {
  ProblemIcon,
  PendingIcon,
  ExecutedIcon,
  MoreOptionsIcon,
} from '../index';
import classes from '../../NegotiationTable.module.scss';

type Props = {
  count: number;
  pending?: boolean;
  executed?: boolean;
  disabled?: boolean;
  problem?: boolean;
  tooltip?: string;
  showMoreOptions?: boolean;
  onClickMore?: MouseEventHandler;
  onClickProblem?: MouseEventHandler;
};

const NegotiationCell = ({
  count,
  pending,
  problem,
  tooltip,
  executed,
  disabled,
  showMoreOptions,
  onClickMore,
  onClickProblem,
}: Props) => {
  
  return (
    <TableCell align="center">
      <Grid container className={classes.infoCellGrid}>
        <Grid item className={classes.moreOptionsGrid}>
          {onClickProblem && problem && (
            <ProblemIcon onClick={onClickProblem} />
          )}
          <div className={classes.avaliableInStoreCountLine}>
            <Tooltip title={tooltip ?? ''}>
              <Typography className={disabled ? classes.disabled : ''}>
                {count}
              </Typography>
            </Tooltip>
          </div>
          {executed && <ExecutedIcon />}
          {pending && <PendingIcon />}
        </Grid>
        {showMoreOptions && (
          <Grid item className={classes.moreOptionsIconGrid}>
            <MoreOptionsIcon onClick={onClickMore} />
          </Grid>
        )}
      </Grid>
    </TableCell>
  );
};

export default NegotiationCell;
