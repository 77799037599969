import React from 'react';
import { MenuItem, Typography } from '@material-ui/core';
import logoutIcon from '../../assets/general/exit.svg';
import * as dictionary from '../../core/constants/dictionary';
import { signOutUser } from './utils';
import styles from './style.module.scss';

const DrawerFooter = ({ isOpened, history}) => {
  const onClickLogoutHandler = () => {
    return signOutUser(history);
  };

  return (
    <div className={styles.signOut} key={dictionary.EXIT}>
      <img
        src={logoutIcon}
        alt={dictionary.EXIT}
      />
      { isOpened && (
        <MenuItem 
          key={dictionary.EXIT} 
          onClick={onClickLogoutHandler}
        >
          <Typography variant="h6" className={styles.label}>
            {dictionary.EXIT}
          </Typography>
        </MenuItem>
      )}
    </div>
  );
}

export default DrawerFooter;
