import React, { useState } from 'react';
import { view } from 'react-easy-state';
import {
  InputAdornment,
  Link,
  Typography,
  Icon,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { mainStore } from '../../core/store/mainStore';
import { redirectToHome } from '../../core/authorization';
import { CpfTextMaskCustom } from '../../core/masks/masks';
import { signIn } from '../../core/services/auth/authService';
import LoginBoxContainer from '../../containers/InitialPage';
import TextField from '../../components/InputTextField';
import DefaultButton from '../../components/Buttons/Default';
import IconButton from '../../components/Buttons/IconButton';
import ErrorIcon from '../../assets/general/warning.svg';
import { customStyles, isValidForm } from './utils';
import * as routes from '../../core/constants/routes';
import * as dictionary from '../../core/constants/dictionary';
import loginClasses from './styles.module.scss';
import rootClasses from '../../containers/InitialPage/styles.module.scss';

const Login = ({ history }) => {
  const classes = customStyles();
  const [cpf, setCpf] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { token } = mainStore;

  if (token) {
    return redirectToHome();
  }

  const passwordType = () => showPassword ? 'text' : 'password';
  
  const handleSubmit = async event => {
    event.preventDefault();
    mainStore.requestLoading = true;
    
    await signIn(cpf, password, setHasError, history);
  }

  const keyPressHandler = event => {
    if(event.key === "Enter") {
      handleSubmit(event);
    }
  }

  const goToCreatePasswordResetTokenScreen = history => {
    history.push(routes.CREATE_PASSWORD_RESET_TOKEN);
  }

  const renderInvalidPassword = () => {
    if (hasError) {
      return (
        <div className="icon-error-warning">
          <Icon classes={{ root: classes.iconRoot }}>
            <img className={classes.imageIcon} alt="error" src={ErrorIcon} />
          </Icon>
          <Typography variant="body1" color="error">
            {dictionary.INVALID_USER_OR_PASSWORD}
          </Typography>
        </div>
      );
    }

    return null;
  }

  return (
    <LoginBoxContainer>
      <div className={loginClasses.formContainer}>
        { renderInvalidPassword() }

        <div className={rootClasses.input}>
          <Typography variant="body1">{dictionary.CPF}</Typography>

          <TextField
            name={dictionary.CPF_NAME}
            style={{ width: '100%' }}
            placeholder={dictionary.TYPE_CPF}
            value={cpf}
            onChange={e => setCpf(e.target.value)}
            onKeyPress={e => keyPressHandler(e)}
            InputProps={{ inputComponent: CpfTextMaskCustom }}
          />
        </div>
        <div className={rootClasses.input}>
          <Typography variant="body1">{dictionary.PASSWORD}</Typography>

          <TextField
            type={passwordType()}
            name={dictionary.PASSWORD_NAME}
            style={{ width: '100%' }}
            placeholder={dictionary.TYPE_PASSWORD}
            value={password}
            onChange={e => setPassword(e.target.value)}
            onKeyPress={e => keyPressHandler(e)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    className="password-visibility-icon-button"
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className={rootClasses.submit}>
          <DefaultButton
            label={dictionary.ACCESS}
            disabled={!isValidForm(cpf, password)}
            onClick={e => handleSubmit(e)}
          />
        </div>

        <div className={rootClasses.changePage}>
          <Typography variant="body2">
            <Link
              className={rootClasses.clickable}
              onClick={() => goToCreatePasswordResetTokenScreen(history)}
            >
              {dictionary.FORGOT_PASSWORD}
            </Link>
          </Typography>
        </div>
      </div>
    </LoginBoxContainer>
  );
};

export default view(Login);
