export const collapse = {
    width: '100%',
    marginTop: 20,
};

export const buttonsGrid = {
    marginTop: 20,
};

export const fullWidth = {
    width: '100%',
};
