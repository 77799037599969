import React, { MouseEventHandler } from 'react';
import { Add } from '@material-ui/icons';
import { Button, Divider, Grid, Menu, MenuItem } from '@material-ui/core';
import { NEW_PROMOTIONAL_SPOT } from '../../../../../../core/constants/dictionary';
import classes from '../NegotiationTable.module.scss';
import ExecutedIcon from './icons/ExecutedIcon';
import PendingIcon from './icons/PendingIcon';
import { PromotionalNegotiationExecutionStatusEnum } from '../../../types/promotional-negotiation-status-enum';
import { PromotionalNegotiationExecutionDto } from '../../../../../../core/types/promotional-spot/promotional-negotiation-execution-dto';

type Props = {
  anchorEl: HTMLElement | null;
  onAdd?: MouseEventHandler;
  onClick: (
    evt: React.MouseEvent<HTMLButtonElement>,
    execution: PromotionalNegotiationExecutionDto
  ) => void;
  onClose: MouseEventHandler;
  executions?: PromotionalNegotiationExecutionDto[];
  showAddExecution?: boolean;
  isPending: boolean;
};

const MoreOptionsMenu = ({
  anchorEl,
  onAdd,
  onClick,
  onClose,
  executions,
  showAddExecution = false,
  isPending,
}: Props) => {
  const onClickHandler = (
    event: any,
    execution: PromotionalNegotiationExecutionDto
  ) => {
    onClick(event, execution);
    onClose(event);
  };

  return (
    <Menu
      keepMounted
      id="more-opt-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      className={classes.moreOptionsMenu}
    >
      {executions?.map((execution) => (
        <MenuItem onClick={(event) => onClickHandler(event, execution)}>
          {execution.status ===
          PromotionalNegotiationExecutionStatusEnum.SOLVED ? (
            <Grid md={2} className={classes.iconContainer}>
              <ExecutedIcon />
            </Grid>
          ) : isPending ? (
            <Grid md={2} className={classes.iconContainer}>
              <PendingIcon />
            </Grid>
          ) : (
            <></>
          )}
          <Grid>
            {execution.addressing?.physicalAddressing ??
              `${execution.addressing.spot.name} ${execution.addressing.store.name}`}
          </Grid>
        </MenuItem>
      ))}

      {onAdd && showAddExecution && (
        <>
          <Divider />
          <MenuItem>
            <Button
              startIcon={<Add />}
              className={classes.menuButton}
              onClick={onAdd}
            >
              {NEW_PROMOTIONAL_SPOT}
            </Button>
          </MenuItem>
        </>
      )}
    </Menu>
  );
};

export default MoreOptionsMenu;
