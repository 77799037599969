import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import DefaultTable from '../DefaultTable';
import { mainFontFamily } from '../../../Theme/fonts';

const SearchTable = React.forwardRef(({ data, columns, localization, components, rowsPerPage }, ref) => {
  const tableOptions = useMemo(() => {
    const pageSize = (rowsPerPage) ? rowsPerPage : 10;
    return {
      actionsColumnIndex: -1,
      searchFieldAlignment: 'left',
      pageSize,
      pageSizeOptions: [5, 10, 20],
      rowStyle: { fontFamily: mainFontFamily, fontSize: '13px' },
    };
  }, [rowsPerPage]);

  return (
    <DefaultTable
      ref={ref}
      columns={columns}
      data={data}
      options={tableOptions}
      components={components}
      localization={localization}
    />
  );
});

SearchTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
};

SearchTable.defaultProps = {
  data: [],
  columns: [],
};

export default SearchTable;
