import {
  createPromotionalSpotNegotiationAPI,
  DecrementPromotionalNegotiationExecutionAPI,
  deletePromotionalSpotNegotiationExecutionAPI,
  getPromotionalNegotiationExecutionAPI,
  IncrementPromotionalNegotiationExecutionAPI,
  refuseExecution,
} from './promotionalNegotiationExecutionsAPI';
import {
  CreatePromotionalNegotiationExecutionParams,
  GetPromotionalNegotiationExecutionParams,
  IncrementPromotionalNegotiationExecutionParams,
  RefuseExecutionParams,
} from './types';

export const createPromotionalSpotNegotiation = (
  params: CreatePromotionalNegotiationExecutionParams
) => {
  return createPromotionalSpotNegotiationAPI(params);
};

export const deletePromotionalSpotNegotiationExecution = (id: string) => {
  return deletePromotionalSpotNegotiationExecutionAPI(id);
};

export const refuseNegotiationExecution = (params: RefuseExecutionParams) => {
  return refuseExecution(params);
};

export const IncrementPromotionalNegotiationExecution = (
  params: IncrementPromotionalNegotiationExecutionParams
) => {
  return IncrementPromotionalNegotiationExecutionAPI(params);
};

export const DecrementPromotionalNegotiationExecution = (id: string) => {
  return DecrementPromotionalNegotiationExecutionAPI(id);
};

export const getPromotionalNegotiationExecution = async (
  params: GetPromotionalNegotiationExecutionParams
) => {
  return getPromotionalNegotiationExecutionAPI(params);
};
