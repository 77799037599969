import React, { MouseEventHandler } from 'react';
import { Typography } from '@material-ui/core';
import BaseModal from './BaseModal';
import classes from './Modals.module.scss';
import * as dictionary from '../../../../../../../core/constants/dictionary';

type Props = {
  isOpen: boolean;
  onClose: Function;
  onDelete: MouseEventHandler;
}

const DeleteModal = ({ isOpen, onClose, onDelete }: Props) => {
  return (
    <BaseModal 
      title={dictionary.DELETE_NEGOTIATION}
      isOpen={isOpen}
      onClose={onClose}
      onClick={onDelete}
      label={{ submit: dictionary.CONFIRM }}
    >
      <Typography className={classes.caption}>
        {dictionary.ATENTION_WHEN_CONFIRM_ALL_STORES_NEGOTIATION_UNLINKED}
      </Typography>
    </BaseModal>
  );
}

export default DeleteModal;
