import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';
import DefaultButton from '../../../components/Buttons/Default';
import { LOGIN as LOGIN_ROUTE } from '../../../core/constants/routes';
import { RETURN_TO_LOGIN, ACCESS_EMAIL_TO_RECOVER_PASSWORD } from '../../../core/constants/dictionary';
import classes from '../styles.module.scss';
import rootClasses from '../../../containers/InitialPage/styles.module.scss';

const FinishedForm = ({ history }) => {
    return (
        <Fragment>
            <div className={classes.successForm}>
                <Typography variant="h2">
                    {ACCESS_EMAIL_TO_RECOVER_PASSWORD}
                </Typography>
            </div>
            <div className={rootClasses.submit}>
                <DefaultButton
                    label={RETURN_TO_LOGIN}
                    onClick={ async () => history.push(LOGIN_ROUTE) }
                />
            </div>
        </Fragment>
    );
}

export default FinishedForm;
