import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as routes from '../constants/routes';
import Promotores from '../../pages/Promoters';
import RegistroPromotores from '../../pages/Promoters/registerPromoter';
import Acordos from '../../pages/Agreements';
import Lojas from '../../pages/Stores';
import Usuarios from '../../pages/Users/index';
import { VisitsHistory } from '../../pages/VisitsHistory';
import { VisitsOfDay } from '../../pages/VisitsOfDay/index';
import { VisitsAutoCheckin } from '../../pages/VisitsAutoCheckin';
import Login from '../../pages/Login';
import CreatePasswordResetToken from '../../pages/CreatePasswordResetToken';
import Retail from '../../pages/Retails';
import RestockReports from '../../pages/RestockReports';
import PrivateRoute from '../../components/PrivateRoute';
import ResetPassword from '../../pages/ResetPassword';
import Register from '../../pages/Register';
import PrivacityPolicy from '../../pages/PrivacityPolicy';
import PromoterTermsOfUser from '../../pages/TermsOfUse/PromoterTermsOfUser';
import SalesRepTermsOfUser from '../../pages/TermsOfUse/SalesRepTermsOfUser';
import MissionsOverviewPage from '../../pages/MissionsOverview/MissionsOverviewPage';
import SuspiciousProducts from '../../pages/SuspiciousProducts/index';
import PromoterVisitsDashboard from '../../pages/PromoterVisitsDashboard/index';
import PromotionalCalendars from '../../pages/promotional-spots/PromotionalCalendars';
import PromotionalSpots from '../../pages/promotional-spots/PromotionalSpots';
import PromotionalSpotProductReport from '../../pages/promotional-spots/PromotionalSpotProductReport';
import AddPromotionalCalendar from '../../pages/promotional-spots/AddPromotionalCalendar';
import EditPromotionalCalendar from '../../pages/promotional-spots/EditPromotionalCalendar';
import Addressing from '../../pages/promotional-spots/Addressing';
import ExpirationPanel from '../../pages/ExpirationPanel';
import NegotiationPanel from '../../pages/promotional-spots/NegotiationsPanel';
import Suppliers from '../../pages/Suppliers';
import PromotionalSpotProblemsReport from '../../pages/promotional-spots/PromotionalSpotProblemsReport';

const Routes = ({ history }) => {
  return (
    <Switch>
      {/* Private routes */}
      <PrivateRoute path={routes.PROMOTERS} component={Promotores} />
      <PrivateRoute
        path={routes.PROMOTERS_REGISTER}
        component={() => <RegistroPromotores history={history} />}
      />
      <PrivateRoute
        path={routes.AGREEMENTS}
        component={() => <Acordos history={history} />}
      />
      <PrivateRoute path={routes.STORES} component={Lojas} />
      <PrivateRoute path={routes.USERS} component={Usuarios} />
      <PrivateRoute path={routes.VISITS} component={VisitsOfDay} />
      <PrivateRoute path={routes.VISITS_HISTORY} component={VisitsHistory} />
      <PrivateRoute path={routes.RETAILS} component={Retail} />
      <PrivateRoute path={routes.RESTOCK_REPORTS} component={RestockReports} />
      <PrivateRoute
        path={routes.RESTOCK_PANEL}
        component={MissionsOverviewPage}
      />
      <PrivateRoute
        path={routes.SUSPICIOUS_PRODUCTS}
        component={SuspiciousProducts}
      />
      <PrivateRoute
        path={routes.VISITS_PANEL}
        component={PromoterVisitsDashboard}
      />
      <PrivateRoute
        path={routes.PROMOTIONAL_CALENDARS}
        component={PromotionalCalendars}
      />
      <PrivateRoute
        path={routes.PROMOTIONAL_SPOTS}
        component={PromotionalSpots}
      />

      <PrivateRoute
        path={routes.CREATE_PROMOTIONAL_CALENDAR}
        component={AddPromotionalCalendar}
      />
      <PrivateRoute
        path={`${routes.EDIT_PROMOTIONAL_CALENDAR}/:id`}
        component={EditPromotionalCalendar}
      />
      <PrivateRoute path={routes.ADDRESSINGS} component={Addressing} />
      <PrivateRoute
        path={routes.EXPIRATION_PANEL}
        component={ExpirationPanel}
      />
      <PrivateRoute
        path={routes.NEGOTIATIONS_PANEL}
        component={NegotiationPanel}
      />
      <PrivateRoute
        path={routes.SUPPLIERS}
        component={Suppliers}
      />
      <PrivateRoute
        path={routes.NEGOTIATIONS_PRODUCT_REPORT}
        component={PromotionalSpotProductReport}
      />
      <PrivateRoute
        path={routes.NEGOTIATIONS_PROBLEMS_REPORT}
        component={PromotionalSpotProblemsReport}
      />
      {/* Public routes */}
      <Route
        path={`${routes.VISITS_AUTO_CHECK_IN}/:token?`}
        component={() => <VisitsAutoCheckin />}
      />
      <Route
        exact
        path={routes.LOGIN}
        component={() => <Login history={history} />}
      />
      <Route
        path={routes.REGISTER}
        component={() => <Register history={history} />}
      />
      <Route
        exact
        path={routes.CREATE_PASSWORD_RESET_TOKEN}
        component={() => <CreatePasswordResetToken history={history} />}
      />
      <Route
        exact
        path={routes.CHANGE_PASSWORD}
        component={() => <ResetPassword history={history} />}
      />
      <Route
        exact
        path={routes.PRIVACITY_POLICY}
        component={() => <PrivacityPolicy history={history} />}
      />
      <Route
        exact
        path={routes.PROMOTER_TERMS_OF_USE}
        component={() => <PromoterTermsOfUser history={history} />}
      />
      <Route
        exact
        path={routes.SALESREP_TERMS_OF_USE}
        component={() => <SalesRepTermsOfUser history={history} />}
      />

      {/* Default (fallback) */}
      <Redirect from="*" to={routes.AGREEMENTS} />
    </Switch>
  );
};

export { Routes };
