import React, { useCallback } from 'react';
import { MTableToolbar } from 'material-table';
import { Icon, makeStyles, } from '@material-ui/core';
import FilterTable from '../../../components/Tables/FilterTable';
import PatchedPagination from '../../../components/PatchedPagination';
import OutlinedButton from '../../../components/Buttons/Outlined';
import { EmptyDataMessageRow } from '../../../components/Tables/DefaultTable';
import Remove from '../../../assets/tables/remove.svg';
import { profileCheck } from '../../../core/profiles';
import * as dictionary from '../../../core/constants/dictionary';

type Props = {
  data: any;
  count: number;
  pageType: string;
  pageIndex: number;
  rowsPerPage: number;
  searchTextValue: string;
  toolbarInputFocus: boolean;
  handleOpenModal: Function;
  onChangePageHandler: Function;
  onChangeRowsPerPageHandler: Function;
  onSearchChangeHandler: Function;
}

type RowData = {
  retailName?: string;
  promoterUserCpf?: string;
  promoterUserName?: string;
  agreementInternalCode?: string;
  agreementSupplierNames?: string[];
};

const controlStyle = {
  width: '15%',
  cellStyle: { whiteSpace: 'nowrap' },
  headerStyle: { whiteSpace: 'nowrap' },
};

const useStyles = makeStyles({
  imageIcon: { height: '100%' },
  iconRoot: { textAlign: 'center' },
});

export const PromotersAccessTable = (props: Props) => {
  const {
    data,
    handleOpenModal,
    pageType,
    count,
    rowsPerPage,
    pageIndex,
    searchTextValue,
    toolbarInputFocus,
    onChangePageHandler = () => {},
    onChangeRowsPerPageHandler = () => {},
    onSearchChangeHandler = () => {},
  } = props;
  const classes = useStyles();

  const getRemoveIcon = useCallback(
    rowData => {
      return (
        <>
          <Icon classes={{ root: classes.iconRoot }}>
            <img alt={rowData.id} className={classes.imageIcon} src={Remove} />
          </Icon>
        </>
      );
    },
    [classes],
  );

  const getReleaseAcessTooltip = useCallback(() => {
    let tooltip = dictionary.DETAILS;
    if (profileCheck.isSalesRep()) {
      tooltip = dictionary.SEE_ACCESS;
    } else if (pageType === dictionary.SUSPENDED_ACCESSES) {
      tooltip = dictionary.RELEASE_ACCESS;
    } else if (pageType === dictionary.LIBERATE_ACCESS) {
      tooltip = dictionary.MANAGE_ACCESS;
    }
    return tooltip;
  }, [pageType]);

  const getReleaseAcessButton = useCallback(() => {
    let buttonLabel = dictionary.DETAILS;
    if (profileCheck.isSalesRep()) {
      buttonLabel = dictionary.SEE_ACCESS;
    } else if (pageType === dictionary.SUSPENDED_ACCESSES) {
      buttonLabel = dictionary.RELEASE_ACCESS;
    } else if (pageType === dictionary.LIBERATE_ACCESS) {
      buttonLabel = dictionary.SUSPEND_ACCESS;
    }

    return (
      <>
        <OutlinedButton label={buttonLabel} />
      </>
    );
  }, [pageType]);

  return (
    <FilterTable
      data={data}
      rowsPerPage={rowsPerPage}
      columns={[
        {
          ...controlStyle,
          title: dictionary.PROMOTER_NAME,
          field: 'promoterUserName',
          render: (rowData: RowData) => rowData.promoterUserName,
        },
        {
          ...controlStyle,
          title: dictionary.PROMOTER_CPF,
          field: 'promoterUserCpf',
          render: (rowData: RowData) => rowData.promoterUserCpf,
        },
        {
          ...controlStyle,
          title: dictionary.AGREEMENT_CODE,
          field: 'agreementInternalCode',
          render: (rowData: RowData) => rowData.agreementInternalCode,
        },
        {
          ...controlStyle,
          title: dictionary.RETAIL,
          field: 'retailName',
          render: (rowData: RowData) => rowData.retailName,
        },
        {
          width: '25%',
          title: dictionary.SUPPLIERS,
          field: 'agreementSupplierNames',
          render: (rowData: RowData) => rowData.agreementSupplierNames,
        },
      ]}
      actions={[
        (rowData: RowData) => ({
          icon: () => getReleaseAcessButton(),
          tooltip: getReleaseAcessTooltip(),
          onClick: () => handleOpenModal(dictionary.ACCESS, rowData),
        }),
        (rowData: RowData) => ({
          icon: () => getRemoveIcon(rowData),
          tooltip: dictionary.REMOVE_ACCESS,
          onClick: () => handleOpenModal(dictionary.DELETE, rowData),
        }),
      ]}
      localization={{
        body: {
          emptyDataSourceMessage: (
            <EmptyDataMessageRow message={dictionary.EMPTY_PROMOTER_TABLE}/>
          ),
        },
      }}
      components={{
        Pagination: (props: any) => {
          return (
            <PatchedPagination
              {...props}
              count={count}
              page={pageIndex}
              rowsPerPage={rowsPerPage}
              onChangePage={(evt: any, page: number) => onChangePageHandler(page)}
              onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => 
                onChangeRowsPerPageHandler(parseInt(event.target.value))
              }
            />
          );
        },
        Toolbar: (props: any) => {
          if(onSearchChangeHandler) {
            return (
              <MTableToolbar 
                {...props} 
                searchAutoFocus={toolbarInputFocus}
                searchText={searchTextValue}
                onSearchChanged={(value: string) => onSearchChangeHandler(value)}
              />
            );  
          }
          else return <MTableToolbar {...props} />;  
        },
      }}
    />
  );
};
