import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import TextField from '../../../../components/InputTextField';
import * as dictionary from '../../../../core/constants/dictionary';
import Autocomplete from '../../../../components/Autocomplete';
import Frequency from '../../components/Frequency';
import { profileCheck } from '../../../../core/profiles';
import DefaultButton from '../../../../components/Buttons/Default';
import CancelButton from '../../../../components/Buttons/Cancel';
import {
  registerVisits,
  loadAgreements,
  getChipsSuppliers,
  getOptionLabelSuppliers,
  getAgreementSuppliers,
} from '../../functions';
import { mainStore } from '../../../../core/store/mainStore';
import { utils } from '../../../../core/utils';
import { getScheduledVisits } from '../../../../core/services/agreements/agreementsService';
import { getStores } from '../../../Stores/functions';
import { trackAgreementCreateFrequencyStep } from '../../../../core/mixpanel/mixPanelEvents';

export const NewAgreementSecondStep = ({
  nextStepCallback,
  closeModalCallback,
  currentAgreement,
}) => {
  const [suppliersOpts, setSuppliersOpts] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [scheduledVisits, setScheduledVisits] = useState([]);
  const [tick, setTick] = useState(false);

  const loadSuppliers = useCallback(async () => {
    mainStore.requestLoading = true;
    const selectedSuppliers = await getAgreementSuppliers(currentAgreement.id);
    setSuppliers(selectedSuppliers);
    setSuppliersOpts(selectedSuppliers);
    mainStore.requestLoading = false;
  }, [currentAgreement.id]);

  const loadVisits = useCallback(async () => {
    mainStore.requestLoading = true;
    let visits = await getScheduledVisits(currentAgreement.id);
    let stores = await getStores(currentAgreement.retailId, 1);
    const storesWithExistingVisits = visits.map(v => v.storeId);
    stores = stores.filter(
      store => !storesWithExistingVisits.includes(store.id),
    );
    for (const store of stores) {
      const visit = {
        daysOfWeek: [],
        storeId: store.id,
        storeName: store.name,
        storeCode: store.storeCode,
      };
      visits = [...visits, visit];
    }
    setScheduledVisits(visits);
    mainStore.requestLoading = false;
  }, [currentAgreement.id, currentAgreement.retailId]);

  useEffect(() => {
    (async function load() {
      mainStore.requestLoading = true;
      await loadSuppliers();
      await loadVisits();
      mainStore.requestLoading = false;
    })();
  }, [loadSuppliers, loadVisits]);

  const handleVisitToggle = useCallback(
    (value, index) => {
      const schedule = scheduledVisits[index];
      schedule.daysOfWeek = value;
      setScheduledVisits(scheduledVisits);
      setTick(!tick);
    },
    [scheduledVisits, tick],
  );

  const handleCancel = useCallback(() => {
    setScheduledVisits([]);
    closeModalCallback();
  }, [closeModalCallback]);

  const submitVisits = useCallback(
    async event => {
      event.preventDefault();
      try {
        const dto = {
          visits: scheduledVisits.map(element => ({
            storeId: element.storeId,
            daysOfWeek: element.daysOfWeek,
          })),
        };
        mainStore.requestLoading = true;
        await registerVisits(dto, currentAgreement.id);
        await loadAgreements();
        const storesFrequencyCount = scheduledVisits.reduce(
          (total, element) => {
            return element.daysOfWeek.length > 0 ? total + 1 : total;
          },
          0,
        );
        trackAgreementCreateFrequencyStep(currentAgreement, {
          storesFrequencyCount,
          storesCount: scheduledVisits.length,
        });
        mainStore.requestLoading = false;
        utils.openSnackBar(dictionary.SUCCESS, dictionary.VISITS_EDITED);
        nextStepCallback();
      } catch (e) {
        utils.openSnackBar(dictionary.ERROR, dictionary.EDIT_VISITS_ERROR);
      }
    },
    [currentAgreement, nextStepCallback, scheduledVisits],
  );

  const scheduleVisitsList = useMemo(() => {
    return scheduledVisits.map((element, index) => {
      return (
        <div
          key={element.storeId}
          className="form-two-columns"
          style={{ marginTop: '8px' }}
        >
          <TextField
            style={{ width: '50%', margin: 0, marginRight: '32px' }}
            required
            type="text"
            disabled
            value={`${element.storeName} (${element.storeCode})`}
          />
          <Frequency
            disabled={profileCheck.isSalesRep()}
            index={index}
            data={element.daysOfWeek}
            handleChange={handleVisitToggle}
          />
        </div>
      );
    });
  }, [scheduledVisits, handleVisitToggle]);

  const renderedActions = useMemo(() => {
    if (profileCheck.isSalesRep()) {
      return (
        <DefaultButton
          onClick={() => handleCancel()}
          color="primary"
          style={{ width: 'auto' }}
          label={dictionary.CLOSE}
        />
      );
    }
    return (
      <>
        <CancelButton onClick={() => handleCancel()} />

        <DefaultButton
          onClick={event => submitVisits(event)}
          color="primary"
          style={{ width: 'auto' }}
          label={dictionary.SAVE}
        />
      </>
    );
  }, [handleCancel, submitVisits]);

  return (
    <>
      <DialogContent>
        <div className="form-two-columns">
          <TextField
            style={{ width: '50%' }}
            required
            label={dictionary.AGREEMENT_CODE}
            type="text"
            disabled
            value={currentAgreement.internalCode}
          />
        </div>

        <div className="form-two-columns">
          <Autocomplete
            value={suppliers}
            renderTags={getChipsSuppliers}
            style={{ marginRight: 0, width: '100%' }}
            multiple
            getOptionLabel={getOptionLabelSuppliers}
            label={dictionary.AGREEMENT_SUPPLIERS}
            disabled
            options={suppliersOpts}
            placeholder=""
          />
        </div>

        <div className="form-two-columns" style={{ marginTop: '20px' }}>
          <div style={{ width: '50%' }}>
            <Typography variant="h1">{dictionary.STORES}</Typography>
          </div>
          <div style={{ width: '50%' }}>
            <Typography variant="h1">{dictionary.DAYS_OF_WEEK}</Typography>
          </div>
        </div>

        <div style={{ maxHeight: '290px', overflow: 'auto' }}>
          {scheduleVisitsList}
        </div>
      </DialogContent>

      <DialogActions>{renderedActions}</DialogActions>
    </>
  );
};
