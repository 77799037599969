import { timezone } from '../../constants/dates';
import { TOKEN_KEY } from '../../authorization';
import { signOutUser } from '../../../components/SideBar/utils';
import * as routes from '../../constants/routes';

export const useDefaultInterceptorRequest = (config) => {
  const token = localStorage.getItem(TOKEN_KEY);
  const theConfig = config;
  if (token) {
    theConfig.headers.Authorization = `Bearer ${token}`;
  }
  return theConfig;
}

export const useDefaultInterceptorResponse = (error) => {
  if (error.message === 'Network Error' || error.response.status === 401) {
    signOutUser();
    window.location.href = `/#${routes.LOGIN}`;
  }
  return Promise.reject(error);
}

export const axiosDefaultConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'doors-platform': 'web',
    timezone: timezone(),
  },
}
