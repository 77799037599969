import React from 'react';
import { Error } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import classes from './Icons.module.scss';
import * as dictionary from '../../../../../../../core/constants/dictionary';
import { colors } from '../../../../../../../Theme/colors';

const PendingIcon = () => {
  return (
    <Tooltip title={dictionary.PENDING}>
      <Error
        className={classes.pendingIcon}
        style={{ color: colors.red_600 }}
      />
    </Tooltip>
  );
};

export default PendingIcon;
