import React from 'react';
import { format, parseISO } from 'date-fns';
import { Typography } from '@material-ui/core';
import WarningModal from '../../../../containers/WarningModal';
import classes from '../PromoterModal.module.scss';
import { MAX_MODAL_WIDTH } from '../../../VisitsAutoCheckin/components/utils';
import * as dictionary from '../../../../core/constants/dictionary';
import { MAX_PROMOTER_WARNING_BEFORE_SUSPENSION } from '../../../../core/constants/visits';

type Props = {
  isOpen: boolean;
  data: {
		store: string;
		promoterName: string;
		suspensionAt: string;
		totalWarnings: number;
	};
  onReloadHandler?: Function;
  onCloseModalHandler: Function;
}

const PromoterWarningModal = ({ isOpen, data, onReloadHandler, onCloseModalHandler }: Props) => {
	const suspensionAt = data.suspensionAt ? format(parseISO(data.suspensionAt), 'dd/MM/yyyy - HH:mm') : '';
	
	return (
    <WarningModal
			isOpen={isOpen}
			width={MAX_MODAL_WIDTH}
			notificationText={(
				<span className={classes.notificationText}>
					<strong>{ data.totalWarnings}/{ MAX_PROMOTER_WARNING_BEFORE_SUSPENSION }. </strong>
					{ dictionary.SECURITY_INFORM_AVERT_BLOCK_MESSAGE_THE_PROMOTER }
				</span>
			)}
			onCloseModalHandler={() => {
				onReloadHandler && onReloadHandler();
				onCloseModalHandler();
			}}
		>
			<div className={classes.body}>
        <Typography align="center" className={classes.title}>
          { dictionary.WARNING_FORGET }
        </Typography>

        <Typography align="center" className={classes.bodyText}>
				<span className={classes.semiBold}>{ data.promoterName}</span>{' '}
          { dictionary.SECURITY_CHECKOUT_FORGET_WARNING_THE_PROMOTER_MESSAGE + ':'}
        </Typography>
				
				<Typography align="center" className={[classes.bodyText, classes.semiBold].join(' ')}>
          { suspensionAt } ({ data.store })
        </Typography>
      </div>
		</WarningModal>
  )
}

export default PromoterWarningModal;
