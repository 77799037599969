import API from '../api/config';
import * as routes from '../api/routes';

export function atStartOfDay(date) {
  date.setHours(0, 0, 0, 0);
  return date;
}

export function atEndOfDay(date) {
  date.setHours(23, 59, 59, 999);
  return date;
}

export const getShelfMissionsRestockAPI = async (data) => {
  const queryParams = mountRestockGetAndExportQueryParams(data);

  const url = `${routes.API_SHELF_MISSIONS_RESTOCK}?${queryParams}`;
  const response = await API.get(url);
  return response.data;
};

export const getProductGroupsByRetailAPI = async (retailId) => {
  const url = `${routes.API_PRODUCT_GROUPS}?retailId=${retailId}`;
  const response = await API.get(url);
  return response.data.filter((item) => item.name.toLowerCase() !== 'nan');
};

export const getProductSubGroupsByGroupAPI = (groupId) => {
  return API.get('/product-subgroups', { params: { groupId } });
};

export const exportShelfBreaksXlsAPI = async (data) => {
  const queryParams = mountRestockGetAndExportQueryParams(data);

  const url = `${routes.API_SHELF_MISSIONS_RESTOCK}/export?${queryParams}`;
  return API.get(url, {
    responseType: 'arraybuffer',
  });
};

export const getProductSuspensionsAPI = async (params) => {
  const response = await API.get(routes.API_PRODUCT_SUPENSIONS, { params });
  return response.data;
};

const mountRestockGetAndExportQueryParams = ({
  retailId,
  storeId,
  groupId,
  subgroupId,
  startDate,
  endDate,
  status,
  virtualStock,
  shelfStatus,
}) => {
  const queryParams = new URLSearchParams();
  queryParams.append(
    'startDate',
    startDate ? atStartOfDay(new Date(startDate)) : atStartOfDay(new Date())
  );
  queryParams.append(
    'endDate',
    endDate ? atStartOfDay(new Date(endDate)) : atStartOfDay(new Date())
  );
  if (retailId) {
    queryParams.append('retailId', retailId);
  }
  if (storeId) {
    queryParams.append('storeId', storeId);
  }
  if (groupId) {
    queryParams.append('groupId', groupId);
  }
  if (subgroupId) {
    queryParams.append('subgroupId', subgroupId);
  }
  if (status) {
    queryParams.append('status', status.join(','));
  }
  if (virtualStock) {
    queryParams.append('virtualStock', virtualStock.join(','));
  }
  if (shelfStatus) {
    queryParams.append('shelfStatus', shelfStatus.join(','));
  }

  return queryParams.toString();
};
