import { useState } from 'react';

import { RetailEntityType } from '../../../../core/types/retail/retail-entity.type';
import { ProductGroup } from '../../Addressing/types';
import { CalendarPeriodTableRow } from '../../common/types';

export const useFormField = () => {
  const [year, setYear] = useState<string | null>(null);
  const [month, setMonth] = useState<number | null>(null);
  const [period, setPeriod] = useState<CalendarPeriodTableRow[]>([]);
  const [type, setType] = useState<string[]>([]);
  const [area, setArea] = useState<string[]>([]);
  const [spotName, setSpotName] = useState('');
  const [retail, setRetail] = useState<RetailEntityType>();
  const [isOpen, setIsOpen] = useState(true);
  const [isSectorOptionsLoading, setIsSectorOptionsLoading] =
    useState<boolean>(false);
  const [sectorOptions, setSectorOptions] = useState<ProductGroup[]>([]);
  const [selectedSector, setSelectedSector] = useState<ProductGroup[]>([]);

  return {
    values: {
      year,
      month,
      period,
      type,
      area,
      retail,
      spotName,
    },
    isOpen,
    isSectorOptionsLoading,
    sectorOptions,
    selectedSector,
    setYear,
    setMonth,
    setPeriod,
    setType,
    setSpotName,
    setArea,
    setRetail,
    setIsOpen,
    setIsSectorOptionsLoading,
    setSectorOptions,
    setSelectedSector,
  };
};
