import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Modal, Flex } from '@involves/violin';

export const StyledModal = styled(Modal)`
  > header {
    border-bottom-color: transparent;
  }
  > footer {
    background-color: ${theme('palette.misc.white')};
  }
`;

export const StyledModalContentContainer = styled(Flex)`
  height: 347px;
`;
