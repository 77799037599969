import { colors } from './colors';

export const mainFontFamily = 'Open Sans, sans-serif';

export const fonts = {
  h1: {
    fontFamily: mainFontFamily,
    fontWeight: 'normal',
    fontSize: '18px',
    color: colors.black_400,
  },
  h2: {
    fontFamily: mainFontFamily,
    fontWeight: 'normal',
    fontSize: '16px',
    color: colors.black_400,
  },
  h3: {
    /* Free to use */
  },
  h4: {
    /* Free to use */
  },
  h5: {
    /* Free to use */
  },
  h6: {
    /* Free to use */
  },
  body1: {
    fontFamily: mainFontFamily,
    fontWeight: 'normal',
    fontSize: '14px',
    color: colors.black_700,
  },
  body2: {
    fontFamily: mainFontFamily,
    fontWeight: 'normal',
    fontSize: '12px',
    color: colors.black,
  },
  button: {
    fontFamily: mainFontFamily,
    fontSize: '14px',
    color: colors.white,
    textTransform: 'none',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  caption: {
    /* Free to use */
  },
  overline: {
    fontFamily: mainFontFamily,
    fontWeight: 'bold',
    fontSize: '11px',
    lineHeight: '16px',
    color: colors.black_300,
  },
  subtitle1: {
    fontFamily: mainFontFamily,
    fontSize: '14px',
    color: colors.blue_500,
    textTransform: 'none',
    fontWeight: 'normal',
    whiteSpace: 'nowrap',
  },
  subtitle2: {
    fontFamily: mainFontFamily,
    fontSize: '13px',
    color: colors.blue_800,
    textTransform: 'none',
    fontWeight: 'normal',
    whiteSpace: 'nowrap',
  },
};
