import { PromotionalNegotiationExecutionDto } from '../../../../../core/types/promotional-spot/promotional-negotiation-execution-dto';
import {
  PromotionalNegotiationExecutionAddressingStoreDto,
} from '../../../common/types';

export const filterExecutionsInCurrentStore = (
  executions: PromotionalNegotiationExecutionDto[] = [],
  store: PromotionalNegotiationExecutionAddressingStoreDto
) => {
  let result: PromotionalNegotiationExecutionDto[] = [];
  result = executions?.filter(
    (execution) => execution?.addressing.store.id === store.id
  );

  return result;
};

export const totalProblemsCount = (
  executions: PromotionalNegotiationExecutionDto[] = []
) => {
  let totalProblems = 0;
  for (const execution of executions) {
    totalProblems +=
      execution.problems?.filter((problem) => problem.status === 1).length ?? 0;
  }

  return totalProblems;
};

export const filterExecutionsIsNotExecuted = (
  executions: PromotionalNegotiationExecutionDto[] = []
) => executions.filter((execution) => execution?.status != 2);

export const parseExecutedMetrics = (
  executions: PromotionalNegotiationExecutionDto[] = []
) => {
  const totalProblems = totalProblemsCount(executions);
  const totalExecutions = executions.length;
  const pendingExecutions = filterExecutionsIsNotExecuted(executions).length;
  const executedExecutions = totalExecutions - pendingExecutions;

  return {
    total: totalExecutions,
    pending: pendingExecutions,
    executed: executedExecutions,
    problems: totalProblems,
  };
};
