import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import FilterListIcon from '@material-ui/icons/FilterList';
import DefaultTable from '../DefaultTable';
import { mainFontFamily } from '../../../Theme/fonts';
import * as dictionary from '../../../core/constants/dictionary';

const FilterTable = ({ title, data, columns, actions, components, rowsPerPage, localization, options = {} }) => {
  const [filter, setFilter] = useState(false);
  const tableOptions = useMemo(() => {
    const pageSize = (rowsPerPage) ? rowsPerPage : 10;
    
    return {
      filtering: filter,
      actionsColumnIndex: -1,
      searchFieldAlignment: 'left',
      pageSize,
      pageSizeOptions: [5, 10, 20],
      rowStyle: { fontFamily: mainFontFamily, fontSize: '13px' },
      ...options
    };
  }, [filter, rowsPerPage, options]);

  const actionsValue = useMemo(() => {
    return actions.concat({
      icon: FilterListIcon,
      tooltip: dictionary.FILTERS,
      isFreeAction: true,
      onClick: () => setFilter(!filter),
    });
  }, [actions, filter]);
  
  return (
    <DefaultTable
      title={title}
      data={data}
      columns={columns}
      actions={actionsValue}
      options={tableOptions}
      components={components}
      localization={localization}
    />
  );
};

FilterTable.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.array]),
  // eslint-disable-next-line react/forbid-prop-types
  components: PropTypes.object,
};

FilterTable.defaultProps = {
  title: '',
  data: [],
  columns: [],
  actions: [],
  components: {},
};

export default FilterTable;
