import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import * as dictionary from '../../../core/constants/dictionary';

const defaultLocalizationProps = {
  header: { actions: '' },
  toolbar: { searchPlaceholder: dictionary.SEARCH },
  pagination: {
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsSelect: dictionary.LINES,
  },
  body: { emptyDataSourceMessage: '' },
};

const DefaultTable = React.forwardRef(({ 
  title, 
  data, 
  columns, 
  options, 
  actions, 
  localization, 
  components, 
  style, 
  rowsPerPage 
}, ref) => {
    return (
      <MaterialTable
        ref={ref}
        key={ data.length }
        title={title}
        data={data}
        columns={columns}
        actions={actions}
        options={options}
        rowsPerPage={rowsPerPage}
        localization={{
          ...defaultLocalizationProps,
          ...localization,
        }}
        components={components}
        style={style}
      />
    );
  }
);

DefaultTable.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.array]),
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  localization: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  components: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

DefaultTable.defaultProps = {
  title: '',
  data: [],
  columns: [],
  actions: [],
  options: {},
  localization: defaultLocalizationProps,
  components: {},
  style: {},
};

export const EmptyDataMessageRow = ({ message, style={}}) => (
  <span style={{ ...style, fontSize: '14px' }}>{ message }</span>
);

export default DefaultTable;
