import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Checkbox, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { getScheduledVisits } from '../../../../core/services/agreements/agreementsService';
import { getStores } from '../../../Stores/functions';
import {
  registerVisits,
  loadAgreements,
  getAgreementSuppliers,
  getChipsSuppliers,
  getOptionLabelSuppliers,
} from '../../functions';
import TextField from '../../../../components/InputTextField';
import * as dictionary from '../../../../core/constants/dictionary';
import { mainStore } from '../../../../core/store/mainStore';
import CancelButton from '../../../../components/Buttons/Cancel';
import DefaultButton from '../../../../components/Buttons/Default';
import Autocomplete from '../../../../components/Autocomplete';
import Frequency from '../../components/Frequency';
import { profileCheck } from '../../../../core/profiles';
import { utils } from '../../../../core/utils';
import { trackAgreementFrequencyEdit } from '../../../../core/mixpanel/mixPanelEvents';
import { CheckboxState } from './checkboxState.enum';
import classes from './editFrequency.module.scss';

export const EditFrequency = ({ closeModalCallback, currentAgreement }) => {
  const [tick, setTick] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [suppliersOpts, setSuppliersOpts] = useState([]);
  const [scheduledVisits, setScheduledVisits] = useState([]);
  const [checkState, setCheckState] = useState(0);

  const allowOnlyView = useMemo(() => {
    return profileCheck.isSalesRep() || profileCheck.isPromoterApprover();
  }, []);

  const loadVisits = useCallback(async () => {
    let visits = await getScheduledVisits(currentAgreement.id);
    let stores = await getStores(currentAgreement.retailId, 1);
    const storesWithExistingVisits = visits.map(v => v.storeId);
    stores = stores.filter(
      store => !storesWithExistingVisits.includes(store.id),
    );
    for (const store of stores) {
      const visit = {
        daysOfWeek: [],
        storeId: store.id,
        storeName: store.name,
        storeCode: store.storeCode,
      };
      visits = [...visits, visit];
    }

    setScheduledVisits(visits);
  }, [currentAgreement]);

  const loadSuppliers = useCallback(async () => {
    const selectedSuppliers = await getAgreementSuppliers(currentAgreement.id);
    setSuppliers(selectedSuppliers);
    setSuppliersOpts(selectedSuppliers);
  }, [currentAgreement.id]);

  useEffect(() => {
    (async function load() {
      mainStore.requestLoading = true;
      await loadSuppliers();
      await loadVisits();
      mainStore.requestLoading = false;
    })();
  }, [loadSuppliers, loadVisits]);

  useEffect(() => {
    let daysOfWeek = [], shouldUpdate = true;
    switch (checkState) {
      case CheckboxState.DEFAULT:
        daysOfWeek = [];
        break;
      case CheckboxState.CHECKED:
        daysOfWeek = ['0', '1', '2', '3', '4', '5', '6'];
        break;
      case CheckboxState.INDETERMINATE:
        shouldUpdate = false;
        break;
    }

    if (shouldUpdate) {
      setScheduledVisits(prevState => [
        ...prevState.map(sv => ({
          ...sv,
          daysOfWeek,
        }))
      ]);
    }
  }, [checkState]);

  useEffect(() => {
    let noDaysAllStores = true;
    let allDaysAllStores = true;

    for (const sv of scheduledVisits) {
      if (!(noDaysAllStores || allDaysAllStores)) {
        break;
      }

      if (sv.daysOfWeek.length < 7) {
        allDaysAllStores = false;
      }
      if (sv.daysOfWeek.length > 0) {
        noDaysAllStores = false;
      }
    }

    if (allDaysAllStores) {
      setCheckState(CheckboxState.CHECKED);
    } else if (noDaysAllStores) {
      setCheckState(CheckboxState.DEFAULT);
    } else {
      setCheckState(CheckboxState.INDETERMINATE);
    }
  }, [scheduledVisits]);

  const handleVisitToggle = useCallback((value, index) => {
    const schedule = scheduledVisits[index];
    schedule.daysOfWeek = value;
    setTick(!tick);
    setScheduledVisits(scheduledVisits);
    setCheckState(CheckboxState.INDETERMINATE);
  }, [scheduledVisits, tick]);

  const submitVisits = useCallback(
    async event => {
      event.preventDefault();
      mainStore.requestLoading = true;
      try {
        const dto = {
          visits: scheduledVisits.map(element => ({
            storeId: element.storeId,
            daysOfWeek: element.daysOfWeek,
          })),
        };
        await registerVisits(dto, currentAgreement.id);
        await loadAgreements();
        const storesFrequencyCount = scheduledVisits.reduce(
          (total, element) => {
            return element.daysOfWeek.length > 0 ? total + 1 : total;
          },
          0,
        );
        trackAgreementFrequencyEdit(currentAgreement, {
          storesFrequencyCount,
          storesCount: scheduledVisits.length,
        });

        setScheduledVisits([]);
        closeModalCallback();
        utils.openSnackBar(dictionary.SUCCESS, dictionary.VISITS_EDITED);
      } catch (e) {
        utils.openSnackBar(dictionary.ERROR, dictionary.EDIT_VISITS_ERROR);
      } finally {
        mainStore.requestLoading = false;
      }
    },
    [closeModalCallback, currentAgreement, scheduledVisits],
  );

  const onChangeCheckbox = () => {
    setCheckState(prevState => (prevState === 0) ? 1 : 0);
  }

  const frequencyTable = useMemo(() => {
    return scheduledVisits.map((element, index) => {
      return (
        <div
          key={element.storeId}
          className="form-two-columns"
          style={{ marginTop: '8px' }}
        >
          <TextField
            style={{ width: '50%', margin: 0, marginRight: '32px' }}
            required
            type="text"
            disabled
            value={`${element.storeName} (${element.storeCode})`}
          />
          <Frequency
            index={index}
            data={element.daysOfWeek}
            handleChange={handleVisitToggle}
          />
        </div>
      );
    });
  }, [handleVisitToggle, scheduledVisits]);

  return (
    <>
      <DialogContent className={classes.frequencyModal}>
        <div className={classes.formRow}>
          <TextField
            disabled
            required
            type="text"
            label={dictionary.AGREEMENT_CODE}
            value={currentAgreement.internalCode}
          />
        </div>

        <div className={classes.formRow}>
          <Autocomplete
            disabled
            multiple
            value={suppliers}
            options={suppliersOpts}
            renderTags={getChipsSuppliers}
            label={dictionary.AGREEMENT_SUPPLIERS}
            getOptionLabel={getOptionLabelSuppliers}
            className={classes.fullWidthInput}
            style={{}}
          />
        </div>

        <div className={classes.frequencyGrid}>
          <div className={classes.stores}>
            <Typography variant="h1">{dictionary.STORES}</Typography>
          </div>
          <div className={classes.daysOfWeek}>
            <div className={classes.title}>
              <Typography variant="h1">{dictionary.DAYS_OF_WEEK}</Typography>
            </div>
            <div className={classes.checkbox}>
              <Checkbox 
                color="primary"
                checked={checkState === 1}
                indeterminate={checkState  === 2}
                onChange={onChangeCheckbox}
              />
              <Typography>{dictionary.ALL_DAYS}</Typography>
            </div>
          </div>
        </div>

        <div>
          {frequencyTable}
        </div>
      </DialogContent>

      <DialogActions>
        {allowOnlyView ? (
          <DefaultButton
            color="primary"
            label={dictionary.CLOSE}
            onClick={() => {
              setScheduledVisits([]);
              closeModalCallback();
            }}
            style={{ width: 'auto' }}
          />
        ) : (
          <>
            <CancelButton
              onClick={() => {
                setScheduledVisits([]);
                closeModalCallback();
              }}
            />

            <DefaultButton
              color="primary"
              label={dictionary.SAVE}
              onClick={event => submitVisits(event)}
              style={{ width: 'auto' }}
            />
          </>
        )}
      </DialogActions>
    </>
  );
};
