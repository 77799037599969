import React, { MouseEventHandler, useEffect, useState } from 'react';
import { Button, Collapse, Grid, Paper } from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons/';

import BarColumn from './BarColumn';
import ChipColumn from './ChipColumn';
import NegotiationTable from '../NegotiationTable';
import { colors } from '../../../../../Theme/colors';
import { AddressingDto } from '../../../../../core/types/promotional-spot/promotional-addressing.dto';
import { getAddressings } from '../../../../../core/services/promotionalSpots/addressingsServices';
import { getNegotiationPanel } from '../../../../../core/services/promotionalSpots/promotionalNegotiationService';
import { PromotionalNegotiationDto } from '../../../common/types';
import { PromotionalNegotiationPanel } from '../../types/promotional-negotiation-panel.entity';
import { getPromotionalNegotiationPanel } from '../../../../../core/services/promotionalSpots/promotionalNegotiationPanelService';
import { parsePeriodsDateToAcordeonText } from '../AccordionList/utils';
import * as dictionary from '../../../../../core/constants/dictionary';
import classes from './Accordion.module.scss';
import { FormFilters } from '../FormFilter/utils';
import { mainStore } from '../../../../../core/store/mainStore';

type Props = {
  data: PromotionalNegotiationPanel;
  stores: any[];
  isOpen: boolean;
  className?: string;
  retailId?: string;
  onToggle: MouseEventHandler;
};

const Accordion = ({ data, isOpen, className, onToggle, stores }: Props) => {
  const [isLoadingNegotiatios, setIsloadingNegotiations] = useState(false);
  const [isLoadingAddressing, setIsloadingAddressing] = useState(false);
  const [negotiations, setNegotiations] = useState<PromotionalNegotiationDto[]>(
    []
  );
  const [totalOcupation, setTotalOcupation] = useState(
    data.addressings.total ?? 0
  );
  const [totalAvaliable, setTotalAvaliable] = useState(
    data.addressings.avaliable ?? 0
  );
  const [totalExecutions, setTotalExecutions] = useState(
    data.executions.total ?? 0
  );
  const [totalExecuted, setTotalExecuted] = useState(
    data.executions.executed ?? 0
  );
  const [addressings, setAddressings] = useState<AddressingDto[]>([]);

  const fetchAddressings = async () => {
    if (!isLoadingAddressing) {
      try {
        const {
          data: { items },
        } = await getAddressings({
          limit: 999,
          spotId: data.spot.id,
          periodStartAt: data.period.startAt
        });
        setAddressings(items);
      } catch (error) {
      } finally {
        setIsloadingAddressing(false);
      }
    }
  };

  const fetchData = async () => {
    try {
      let retailId =  ''

      const localStorageFilters = localStorage.getItem('pexFilters');
      if (localStorageFilters) {
        const pexFilters: FormFilters = JSON.parse(localStorageFilters);
        retailId = pexFilters?.retailId ?? mainStore?.loggedUser?.retailId;
      }
      if (!isLoadingNegotiatios) {
        setIsloadingNegotiations(true);
        const { data: negotiations } = await getNegotiationPanel({
          periodId: data.period.id,
          spotId: data.spot.id,
          retailId: retailId
        });
        setNegotiations(negotiations);
      }
    } catch (err) {
      setNegotiations([]);
      setAddressings([]);
    } finally {
      setIsloadingNegotiations(false);
    }
  };

  const fetchMetrics = async () => {
    const localStorageFilters = localStorage.getItem('pexFilters');
    let panelFilters: any = {};
    if (localStorageFilters) {
      panelFilters = JSON.parse(localStorageFilters);
    }
    const { data: negotiationInfo } = await getPromotionalNegotiationPanel({
      spotIds: [data.spot.id],
      periodIds: [data.period.id],
      sectorIds: panelFilters?.sectorIds,
      startAt: undefined,
      vigentIn: undefined,
    });
    if (negotiationInfo.length > 0) {
      setTotalOcupation(negotiationInfo[0].addressings.total);
      setTotalAvaliable(negotiationInfo[0].addressings.avaliable);
      setTotalExecutions(negotiationInfo[0].executions.total);
      setTotalExecuted(negotiationInfo[0].executions.executed);
    }
  };

  useEffect(() => {
    if (isOpen) {
      Promise.all([fetchData(), fetchAddressings()]);
    }

    return () => {
      setNegotiations([]);
      setAddressings([]);
    };
  }, [isOpen]);

  return (
    <div className={[classes.container, className ?? ''].join(' ')}>
      <Paper className={[classes.paper, isOpen && classes.open].join(' ')}>
        <div
          className={[classes.header, classes.activeAsPointer].join(' ')}
          onClick={onToggle}
        >
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Grid
                container
                className={[classes.gridGap, classes.removeFontSizeGap].join(
                  ' '
                )}
              >
                <ChipColumn
                  showTooltip
                  title={data.spot.name}
                  label={`${totalAvaliable.toLocaleString()} ${
                    dictionary.AVALIABLE_SPOT_FOR_NEGOTIATION
                  }`}
                  color={
                    totalAvaliable > 0 ? colors.green_500 : colors.blue_400
                  }
                  className={classes.leftChipContainer}
                />

                <ChipColumn
                  title={parsePeriodsDateToAcordeonText([
                    data.period.startAt,
                    data.period.endAt,
                    data.period.negotiationLimitAt
                  ])}
                  label={
                    data.problems.total > 0
                      ? `${data.problems.total} ${dictionary.REPORTED_PROBLEMS}`
                      : undefined
                  }
                  color={colors.yellow_400}
                />

               
               
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" className={classes.gridGap}>
                <BarColumn
                  title={dictionary.NEGOTIATEDS}
                  count={totalExecutions}
                  total={totalOcupation}
                  color={colors.blue_400}
                />
                <BarColumn
                  title={dictionary.EXECUTEDS}
                  count={totalExecuted}
                  total={totalExecutions}
                  color={colors.green_400}
                />
                <Grid item className={classes.removeFontSizeGap}>
                  <Button className={classes.chevronButton}>
                    {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Collapse in={isOpen && !isLoadingNegotiatios}>
          <div className={classes.body}>
            <NegotiationTable
              spot={data.spot}
              stores={stores}
              period={data.period}
              addressings={addressings}
              negotiations={negotiations}
              onUpdateItem={async () => {
                await fetchData();
                await fetchMetrics();
              }}
            />
          </div>
        </Collapse>
      </Paper>
    </div>
  );
};

export default Accordion;
