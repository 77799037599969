import { store } from 'react-easy-state';
import { TOKEN_KEY, LOGGED_USER_KEY } from '../authorization';

const initialState = () => ({
  token: localStorage.getItem(TOKEN_KEY),
  loggedUser: localStorage.getItem(LOGGED_USER_KEY)
    ? JSON.parse(localStorage.getItem(LOGGED_USER_KEY))
    : null,
  isAuthenticating: false,
  requestLoading: false,
  snackBars: { typeSnackbar: null, openSnackBar: false, messageSnackBar: '' },
  linkSnackBars: {
    typeSnackbar: null,
    openSnackBar: false,
    messageSnackBar: '',
    action: null,
    messageAction: '',
  },
  retails: { data: null, answerables: [] },
  stores: {
    data: null,
    answerables: [],
    retails: [],
  },
  users: {
    data: null,
    retails: [],
  },
  visits: { data: null },
  agreements: {
    data: null,
    retails: [],
  },
  promoters: {
    data: [],
    queryParams: '',
  },
  shelfBreaksReports: { store: null, retails: [] },
  menu: { 
    selectedRoute: '', 
    submenu: false, 
    submenuName: '',
    submenuRoute: '',
  },
  resetPassword: { creatingToken: false },
  history: {},
});

export const mainStore = store(initialState());

export const resetStoreState = () => {
  const initial = initialState();
  Object.keys(mainStore).forEach(key => {
    mainStore[key] = initial[key];
  });
};
