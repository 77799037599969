import { AddressingDto } from '../../../../../core/types/promotional-spot/promotional-addressing.dto';
import { PromotionalNegotiationExecutionDto } from '../../../../../core/types/promotional-spot/promotional-negotiation-execution-dto';
import { StoreDto } from '../../../Addressing/types';
import {
  PromotionalNegotiationDto,
} from '../../../common/types';
import { PromotionalSpotAvaliableAddresingInStore } from '../../types/promotional-negotiation-panel.entity';
import {
  totalAvaliableAddressingsInStore,
  totalExecutionsInStore,
} from './components/table/utils';

type StoreMetrics = {
  total: number;
  storeId: string;
};
export const parseStoresMetrics = (
  stores: StoreDto[],
  addressings: AddressingDto[]
): StoreMetrics[] => {
  let result: StoreMetrics[] = [];

  for (const store of stores) {
    const totalAddressing =
      addressings.filter((addressing) => addressing.store.id === store.id)
        .length ?? 0;

    result.push({
      storeId: store.id,
      total: totalAddressing,
    });
  }

  return result;
};

interface IsAvaliableAddresingInStoreParams {
  store: StoreDto;
  negotiations: PromotionalNegotiationDto[];
  metrics: PromotionalSpotAvaliableAddresingInStore[];
}
export const isAvaliableAddresingInStore = ({
  store,
  negotiations,
  metrics,
}: IsAvaliableAddresingInStoreParams): boolean => {
  return (
    totalAvaliableAddressingsInStore(
      store,
      totalExecutionsInStore({
        store,
        negotiations,
      }),
      metrics
    ) > 0
  );
};

export const parseCurrentExecutionsInSpot = (
  negotiations: PromotionalNegotiationDto[]
): PromotionalNegotiationExecutionDto[] => {
  let result: PromotionalNegotiationExecutionDto[] = [];
  for (const negotiation of negotiations) {
    if (negotiation.executions) {
      result = result.concat(negotiation.executions);
    }
  }
  return result;
};
