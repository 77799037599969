import React, { useCallback } from 'react';
import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import * as dictionary from '../../../../core/constants/dictionary';
import ModalTitle from '../../../../components/Modal/Title';
import CancelButton from '../../../../components/Buttons/Cancel';
import DefaultButton from '../../../../components/Buttons/Default';
import { removeRetail, getRetails } from '../../functions';
import { trackRetailDelete } from '../../../../core/mixpanel/mixPanelEvents';
import { mainStore } from '../../../../core/store/mainStore';
import { utils } from '../../../../core/utils';

export const RemoveRetail = ({ closeModalCallback, currentRetail }) => {
  const handleRemoveRetail = useCallback(
    async event => {
      event.preventDefault();
      try {
        const response = await removeRetail(currentRetail);
        if (response.status === 200) {
          const retailDTO = {
            retail: {
              name: currentRetail.name,
              status: currentRetail.status,
              cnpj: currentRetail.cnpj,
            },
          };
          trackRetailDelete(retailDTO);
          mainStore.retails.data = await getRetails();
          closeModalCallback();
          utils.openSnackBar(dictionary.SUCCESS, dictionary.RETAIL_REMOVED);
        }
      } catch (err) {
        utils.openSnackBar(dictionary.ERROR, dictionary.UNKNOWN_ERROR);
      } finally {
        mainStore.requestLoading = false;
      }
    },
    [closeModalCallback, currentRetail],
  );

  return (
    <>
      <ModalTitle title={dictionary.DELETE_RETAIL} />

      <DialogContent>
        <Typography variant="body2">
          {dictionary.CONFIRM_RETAIL_REMOVAL}
          <span style={{ fontWeight: 'bold', marginLeft: '0.1rem' }}>
            {currentRetail.name}
          </span>
        </Typography>
      </DialogContent>

      <DialogActions>
        <CancelButton onClick={() => closeModalCallback()} />

        <DefaultButton
          onClick={event => handleRemoveRetail(event)}
          color="primary"
          style={{ width: 'auto' }}
          label={dictionary.DELETE}
        />
      </DialogActions>
    </>
  );
};
