import React from 'react';
import { TableCell, Tooltip, Typography } from '@material-ui/core';
import classes from '../../NegotiationTable.module.scss';

type Props = {
  total: number;
  storeName?: string;
};

const AvaliableRowItem = ({ storeName, total = 0 }: Props) => {
  return (
    <TableCell align="center">
      { storeName ? (
        <Tooltip title={storeName}>
          <Typography className={classes.avaliableInStore}>
            {total}
          </Typography>
        </Tooltip>
      ) : (
        <Typography className={classes.avaliableInStore}>
          {total}
        </Typography>
      )}
    </TableCell>
  );
};

export default AvaliableRowItem;
