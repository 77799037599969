import { AxiosResponse } from 'axios';
import API from '../api/config';
import { exist } from '../../validation';
import { Pagination } from '../../types/pagination.type';
import { PromotionalCalendarDto } from '../../types/promotional-spot/promotional-calendar.dto';
import { PromotionalCalendarEntity } from '../../types/promotional-spot/promotional-calendar.entity';
import * as routes from '../api/routes';
import { CreatePromotionalCalendarParams, GetPromotionalCalendarsParams, UpdatePromotionalCalendarParams } from './types';

export const getPromotionalCalendarsAPI = ({ 
  name,
  offset, 
  limit, 
  retailId,
}: GetPromotionalCalendarsParams): Promise<AxiosResponse<Pagination<PromotionalCalendarDto>>> => {
  let routeParams = `?offset=${offset}&limit=${limit}`;
  if (exist(retailId)) {
    routeParams += `&retailId=${retailId}`;
  }
  if (exist(name)) {
    routeParams += `&name=${name}`;
  }
  const url = `${routes.API_PROMOTIONAL_CALENDARS}${routeParams}`;
  
  return API.get(url);
};

export const getPromotionalCalendarByIdAPI = (
  calendarId: string
): Promise<AxiosResponse<PromotionalCalendarDto>> => {
  const url = `${routes.API_PROMOTIONAL_CALENDARS}/${calendarId}`;
  
  return API.get(url);
};

export const addPromotionalCalendarsAPI = async (
  calendarData: CreatePromotionalCalendarParams
): Promise<AxiosResponse<PromotionalCalendarEntity>> => {
  return await API.post(routes.API_PROMOTIONAL_CALENDARS, calendarData);
};

export const editPromotionalCalendarsAPI = async (
  calendarData: UpdatePromotionalCalendarParams
): Promise<AxiosResponse<PromotionalCalendarEntity>> => {
  const url = `${routes.API_PROMOTIONAL_CALENDARS}/${calendarData.id}`;
  return API.put(url, calendarData);
};

export const deletePromotionalCalendarsAPI = async (
  calendarData: Partial<PromotionalCalendarDto>
): Promise<AxiosResponse<PromotionalCalendarEntity>> => {
  const url = `${routes.API_PROMOTIONAL_CALENDARS}/${calendarData.id}`;
  return API.delete(url);
};
