import { AxiosResponse } from 'axios';
import API from '../api/config';
import * as routes from '../api/routes';
import {
  GetPromotionalNegotiationPanelDto,
  GetPromotionalNegotiationPanelMetricsDto,
  GetPromotionalNegotiationPanelParams,
} from './types';

export const getNegotiationPanelAPI = (
  params: Partial<GetPromotionalNegotiationPanelParams>
): Promise<AxiosResponse<GetPromotionalNegotiationPanelDto>> => {
  const url = `${routes.API_PROMOTIONAL_NEGOTIATION_PANEL}`;

  return API.get(url, { params });
};

export const getNegotiationPanelMetricsAPI = (
  params: Partial<GetPromotionalNegotiationPanelParams>
): Promise<AxiosResponse<GetPromotionalNegotiationPanelMetricsDto>> => {
  const url = `${routes.API_PROMOTIONAL_NEGOTIATION_PANEL}/metrics`;

  return API.get(url, { params });
};
