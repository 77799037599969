import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  DialogActions,
  DialogContent,
  TextField as MUITextField,
} from '@material-ui/core';
import ModalTitle from '../../../../../../components/Modal/Title';
import TextField from '../../../../../../components/InputTextField';
import Autocomplete from '../../../../../../components/Autocomplete';
import {
  CancelButton,
  DefaultButton,
} from '../../../../../../components/Buttons';
import { utils } from '../../../../../../core/utils';
import { globalTypeOptions } from '../../../../common/globalTypeOptions';
import {
  translateAPIToPromotionalSpotType,
  translatePromotionalSpotTypeToAPI,
} from '../../../../common/utils';
import {
  createPromotionalSpot,
  updatePromotionalSpot,
} from '../../../../../../core/services/promotionalSpots/promotionalSpotsServices';
import { PromotionalCalendarDto } from '../../../../../../core/types/promotional-spot/promotional-calendar.dto';
import * as dictionary from '../../../../../../core/constants/dictionary';
import { styles } from '../styles';
import { defaultFormFields } from '../../../utils';
import { PromotionalSpotTypeLabel } from '../../../../../../core/types/promotional-spot/promotional-spot-type-label.enum';
import { PromotionalSpotDto } from '../../../types';
import { PromotionalSpotType } from '../../../../../../core/types/promotional-spot/promotional-spot-type.enum';
import { PromotionalSpotErrorMessageEnum } from '../../../../common/errorTypes';
import { RetailEntityType } from '../../../../../../core/types/retail/retail-entity.type';
import { useRetailList } from '../../../../../../hooks/RetailHooks';
import { FormRetailField } from '../../../../../../components/Form/FormRetailField';
import { getPromotionalCalendars } from '../../../../../../core/services/promotionalSpots/promotionalCalendarsService';

type Props = {
  data?: PromotionalSpotDto;
  calendars: PromotionalCalendarDto[];
  isEditMode?: boolean;
  feedbackError: string;
  feedbackSuccess: string;
  onClose: Function;
  onSubmit: Function;
};

const BaseModal = ({
  data,
  calendars,
  feedbackError,
  feedbackSuccess,
  isEditMode = false,
  onClose,
  onSubmit,
}: Props) => {
  const [name, setName] = useState(data?.name);
  const [calendarsOptions, setCalendarsOptions] =
    useState<PromotionalCalendarDto[]>(calendars);
  const [calendar, setCalendar] = useState(data?.calendar);
  const [type, setType] = useState<PromotionalSpotTypeLabel>(
    translateAPIToPromotionalSpotType(data?.type as PromotionalSpotType)
  );
  const [description, setDescription] = useState(data?.description);
  const [retail, setRetail] = useState<RetailEntityType>();
  const {
    retails,
    fetch: fetchRetails,
    isLoading: isLoadingRetails,
    retailInUser,
  } = useRetailList();

  const [formFields, setFormFields] = useState(defaultFormFields);

  const onSubmitHandler = async () => {
    if (!name) {
      setFormFields((prevState) => ({
        ...prevState,
        name: {
          showError: true,
          errorText: dictionary.FIELD_NOT_EMPTY,
        },
      }));
    }

    if (!type) {
      setFormFields((prevState) => ({
        ...prevState,
        type: {
          showError: true,
          errorText: dictionary.FIELD_NOT_EMPTY,
        },
      }));
    }

    if (!calendar) {
      setFormFields((prevState) => ({
        ...prevState,
        calendar: {
          showError: true,
          errorText: dictionary.FIELD_NOT_EMPTY,
        },
      }));
    }

    if (!name || !type || !calendar) {
      return;
    }

    const body = {
      name,
      description,
      retailId: retail?.id,
      calendarId: calendar?.id,
      type: translatePromotionalSpotTypeToAPI(type),
    };

    try {
      if (isEditMode) {
        await updatePromotionalSpot({
          id: data?.id,
          ...body,
        });
      } else {
        await createPromotionalSpot(body);
      }

      onSubmit();
      utils.openSnackBar(dictionary.SUCCESS, feedbackSuccess);
    } catch (e) {
      const ErrorType = e?.response?.data?.error;
      switch (ErrorType) {
        case PromotionalSpotErrorMessageEnum.DUPLICATED_NAME:
          setFormFields((prevState) => ({
            ...prevState,
            name: {
              showError: true,
              errorText: dictionary.ALREADY_REGISTERED_CALENDAR_NAME,
            },
          }));
          break;

        default:
          utils.openSnackBar(dictionary.ERROR, feedbackError);

          break;
      }
    }
  };

  const fetchCalendars = async (retailId: string) => {
    const { data } = await getPromotionalCalendars({
      offset: 0,
      limit: 9999,
      retailId,
    });
    setCalendarsOptions(data.items);
  };

  useEffect(() => {
    if (!retailInUser && !isLoadingRetails) {
      fetchRetails();
    }
  }, [retailInUser, isLoadingRetails]);

  useEffect(() => {
    if (retail?.id) {
      fetchCalendars(retail.id);
    }
  }, [retail]);

  return (
    <form>
      <ModalTitle
        title={
          isEditMode
            ? dictionary.EDIT_PROMOTIONAL_SPOT
            : dictionary.NEW_PROMOTIONAL_SPOT
        }
      />
      <DialogContent style={styles.dialog}>
        {!retailInUser && !isEditMode && (
          <FormRetailField
            required
            disabled={isLoadingRetails}
            value={retail}
            key={retail?.id}
            options={retails ?? []}
            onChange={(retail) => {
              setRetail(retail);
              const localStorageFilters = localStorage.getItem('pexFilters');
              let currentForm = {};
              if (localStorageFilters) {
                currentForm = JSON.parse(localStorageFilters);
              }
              localStorage.setItem(
                'pexFilters',
                JSON.stringify({
                  ...currentForm,
                  retailId: retail?.id ?? retailInUser,
                })
              );
            }}
            style={styles.autocomplete}
          />
        )}
        <Autocomplete
          size="small"
          value={type}
          id="promotionalSpotType"
          options={globalTypeOptions}
          getOptionLabel={(option: string) => option}
          renderInput={(params: any) => (
            <MUITextField
              {...params}
              variant="outlined"
              error={formFields.type.showError}
              helperText={formFields.type.errorText}
              label={dictionary.PROMOTIONAL_SPOT_TYPE}
            />
          )}
          onChange={(_e: ChangeEvent, value: PromotionalSpotTypeLabel) =>
            setType(value)
          }
          onInputChange={() =>
            setFormFields((prevState) => ({
              ...prevState,
              type: {
                errorText: '',
                showError: false,
              },
            }))
          }
          style={styles.autocomplete}
        />

        <TextField
          required
          value={name}
          errorText={formFields.name.errorText}
          showError={formFields.name.showError}
          label={dictionary.PROMOTIONAL_SPOT_NAME}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            if (value.length > 0) {
              setFormFields((prevState) => ({
                ...prevState,
                name: {
                  errorText: '',
                  showError: false,
                },
              }));
            }
            setName(value);
          }}
          style={styles.input}
        />

        <Autocomplete
          size="small"
          value={calendar}
          options={calendarsOptions}
          id="promotionalSpotCalendar"
          renderInput={(params: []) => (
            <MUITextField
              {...params}
              variant="outlined"
              label={dictionary.CALENDAR}
              error={formFields.calendar.showError}
              helperText={formFields.calendar.errorText}
            />
          )}
          onChange={(_e: ChangeEvent, value: PromotionalCalendarDto) =>
            setCalendar(value)
          }
          onInputChange={() =>
            setFormFields((prevState) => ({
              ...prevState,
              calendar: {
                errorText: '',
                showError: false,
              },
            }))
          }
          style={styles.input}
        />

        <TextField
          multiline
          variant="standard"
          required={false}
          value={description}
          label={dictionary.OBSERVATIONS}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setDescription(e.target.value);
          }}
          style={styles.textarea}
        />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClose} />
        <DefaultButton
          label={dictionary.SAVE}
          onClick={(_e: ChangeEvent) => onSubmitHandler()}
          style={styles.defaultButton}
        />
      </DialogActions>
    </form>
  );
};

export default BaseModal;
