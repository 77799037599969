import React, { useMemo } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import Edit from '../../../../assets/tables/edit.svg';
import Remove from '../../../../assets/tables/remove.svg';
import { mainFontFamily } from '../../../../Theme/fonts';
import IconTable from '../../../../components/Tables/IconTable';
import * as dictionary from '../../../../core/constants/dictionary';
import PatchedPagination from '../../../../components/PatchedPagination';
import { EmptyDataMessageRow } from '../../../../components/Tables/DefaultTable';
import { PromotionalSpotDto } from '../types';
import { translateAPIToPromotionalSpotType } from '../../common/utils';

type Props = {
  data: PromotionalSpotDto[];
  page: number;
  total: number;
  rowsPerPage: number;
  searchTextValue: string;
  toolbarInputFocus: boolean;
  onChangePageHandler: Function;
  onChangeRowsPerPageHandler: Function;
  handleOpenModal: Function;
  onChangeSearchHandler?: Function;
}

const PromotionalSpotsTable = ({
  data,
  page,
  total,
  rowsPerPage,
  handleOpenModal,
  searchTextValue,
  toolbarInputFocus,
  onChangePageHandler = () => { },
  onChangeRowsPerPageHandler = () => { },
  onChangeSearchHandler = () => { }
}: Props) => {
  const actions = useMemo(() => {
    return [
      (rowData: object) => {
        return {
          icon: () => <IconTable icon={Edit} />,
          tooltip: dictionary.EDIT_PROMOTIONAL_SPOT,
          onClick: (event: any, rowData: any) => handleOpenModal(dictionary.EDIT, rowData),
        }
      },
      (rowData: object) => {
        return {
          icon: () => <IconTable icon={Remove} />,
          tooltip: dictionary.DELETE_PROMOTIONAL_SPOT,
          onClick: (event: any, rowData: any) => handleOpenModal(dictionary.DELETE, rowData)
        }
      }
    ]
  }, [handleOpenModal]);

  return (
    <MaterialTable
      title={''}
      data={data}
      actions={actions}
      columns={[
        { title: dictionary.NAME, field: 'name' },
        { 
          field: 'type',
          title: dictionary.PROMOTIONAL_SPOT_TYPE, 
          render: (data: any) => translateAPIToPromotionalSpotType(data.type),
        },
        { 
          field: 'calendar', 
          title: dictionary.ASSOCIATED_CALENDAR, 
          render: (data: any) => data.calendar.name,
        }
      ]}
      options={{
        actionsColumnIndex: -1,
        pageSize: rowsPerPage,
        pageSizeOptions: [5, 10, 20],
        rowStyle: { fontFamily: mainFontFamily, fontSize: '13px' },
      }}
      localization={{
        header: { actions: '' },
        toolbar: { searchPlaceholder: dictionary.SEARCH },
        body: {
          emptyDataSourceMessage:
            <EmptyDataMessageRow
              message={dictionary.EMPTY_PROMOTIONAL_SPOT_TABLE}
            />,
        },
        pagination: {
          labelDisplayedRows: '{from}-{to} de {count}',
          labelRowsSelect: dictionary.LINES,
        },
      }}
      components={{
        Pagination: props => (
          <PatchedPagination
            {...props}
            page={page}
            count={total}
            rowsPerPage={rowsPerPage}
            onChangePage={(evt: any, page: number) => onChangePageHandler(page)}
            onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChangeRowsPerPageHandler(parseInt(event.target.value));
              props.onChangeRowsPerPage(event);
            }}
          />
        ),
        Toolbar: (props) => {
          if (onChangeSearchHandler) {
            return (
              <MTableToolbar
                {...props}
                searchText={searchTextValue}
                searchAutoFocus={toolbarInputFocus}
                onSearchChanged={(value: string) => onChangeSearchHandler(value)}
              />
            );
          }

          return <MTableToolbar {...props} />;
        },
      }}
    />
  )
}

export default PromotionalSpotsTable;
