export const defaultFormFields = {
  name: {
    errorText: '',
    hasError: false,
  },
  cnpj: {
    errorText: '',
    hasError: false,
  },
  internalCode: {
    errorText: '',
    hasError: false,
  },
  internalName: {
    errorText: '',
    hasError: false,
  },
  retail: {
    errorText: '',
    hasError: false,
  },
};
