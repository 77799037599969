import React, { useState } from 'react';
import MaterialTable from 'material-table';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseRounded from '@material-ui/icons/CloseRounded';
import PatchedPagination from '../../../components/PatchedPagination';
import { Button, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import * as dictionary from '../../../core/constants/dictionary';
import { mainFontFamily } from '../../../Theme/fonts';
import { colors } from '../../../Theme/colors';

const useStyles = makeStyles({
  button: {
    fontWeight: '600',
    width: '100%',
  },
});

export const PromoterAgreementsTable = ({
  data,
  handleRemove,
  loading,
  editing,
}) => {
  const [filtering, setFiltering] = useState(false);
  const classes = useStyles();

  return (
    <MaterialTable
      title=""
      data={data}
      isLoading={loading}
      localization={{
        header: { actions: '' },
        body: {
          emptyDataSourceMessage:
            'Esse promotor não está vinculado a nenhum contrato',
        },
        pagination: {
          labelDisplayedRows: '{from}-{to} de {count}',
          labelRowsSelect: dictionary.LINES,
        },
      }}
      options={{
        filtering,
        actionsColumnIndex: -1,
        searchFieldAlignment: 'left',
        rowStyle: { fontFamily: mainFontFamily, fontSize: '13px' },
      }}
      columns={[
        { title: 'Fornecedores do acordo', field: 'supplierNames' },
        { title: 'Código', field: 'internalCode' },
        {
          title: dictionary.STATUS,
          field: 'status',
          lookup: {
            0: dictionary.PENDING,
            1: dictionary.RELEASED,
            2: dictionary.SUSPENDED,
          },
          defaultFilter: ['0', '1', '2'],
          cellStyle: rowData => {
            return rowData === dictionary.RELEASED
              ? { color: colors.green_600 }
              : '';
          },
          render: ({ status }) => {
            if (status === 0) return dictionary.PENDING;
            if (status === 1) return dictionary.RELEASED;
            return dictionary.SUSPENDED;
          },
        },
      ]}
      actions={[
        { isFreeAction: true, icon: '', onClick: ()=> {}},
        { icon: 'suspendAccess',  onClick: ()=> {}},
      ]}
      components={{
        Action: ({ action, data }) => {
          const { status } = data;
          if (action.icon === 'suspendAccess') {
            return (
              <>
                {!editing && (
                  <Tooltip title="Remover">
                    <IconButton onClick={() => handleRemove(data)}>
                      <CloseRounded />
                    </IconButton>
                  </Tooltip>
                )}
                {editing && status === 1 && (
                  <Tooltip title="Suspende acesso do promotor nesse contrato">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleRemove(data)}
                      className={classes.button}
                    >
                      {dictionary.SUSPEND_ACCESS}
                    </Button>
                  </Tooltip>
                )}
                {(status === 0 || status === 2) && (
                  <Tooltip title="Liberar acesso do promotor nesse contrato">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleRemove(data)}
                      className={classes.button}
                    >
                      {dictionary.RELEASE_ACCESS}
                    </Button>
                  </Tooltip>
                )}
              </>
            );
          }
          return (
            <Tooltip title="Filtrar">
              <IconButton onClick={() => setFiltering(!filtering)}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          );
        },
        Pagination: props => <PatchedPagination {...props} />,
      }}
    />
  );
};
