import React, { useMemo } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { amber, green } from '@material-ui/core/colors';
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { view } from 'react-easy-state';
import { utils } from '../../core/utils';
import { useSnackBar } from '../../context/Snackbars';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStylesContainer = makeStyles((theme) => ({
  success: { backgroundColor: green[600] },
  error: { backgroundColor: theme.palette.error.dark },
  info: { backgroundColor: theme.palette.primary.main },
  warning: { backgroundColor: amber[700] },
  icon: { fontSize: 20 },
  iconVariant: { opacity: 0.9, marginRight: '8px' },
  message: { display: 'flex', alignItems: 'center' },
}));

const MySnackbarContentWrapper = ({
  className,
  message,
  onClose,
  variant,
  ...other
}) => {
  const classes = useStylesContainer();
  const Icon = variantIcon[variant];

  const clientSnackBar = useMemo(() => {
    return (
      <span id="client-snackbar" className={classes.message}>
        <Icon className={`${classes.icon} ${classes.iconVariant}`} />
        {message}
      </span>
    );
  }, [classes, message]);

  return (
    <SnackbarContent
      className={`${classes[variant]} ${className}`}
      aria-describedby="client-snackbar"
      message={clientSnackBar}
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
};

export const CustomizedSnackbars = ({
  openSnackBar,
  messageSnackBar,
  typeSnackbar,
}) => {
  const customStyle = {
    margin: '8px',
    flexWrap: 'nowrap',
  };

  const { closeSnackBar } = useSnackBar();

  return (
    openSnackBar && (
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => {
          utils.closeSnackBar();
          closeSnackBar();
        }}
      >
        <MySnackbarContentWrapper
          variant={typeSnackbar}
          message={messageSnackBar}
          onClose={() => {
            utils.closeSnackBar();
            closeSnackBar();
          }}
          style={customStyle}
        />
      </Snackbar>
    )
  );
};

export default view(CustomizedSnackbars);
