import React from 'react';
import SnackBarsComponent from '../../components/Snackbars';
import { mainStore } from '../../core/store/mainStore';

const SnackBars = () => {
  return (
    <SnackBarsComponent
      openSnackBar={mainStore.snackBars.openSnackBar}
      messageSnackBar={mainStore.snackBars.messageSnackBar}
      typeSnackbar={mainStore.snackBars.typeSnackbar}
    />
  );
};

export default SnackBars;
