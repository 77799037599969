import React, {useEffect, useState} from 'react';
import { mainStore } from '../../core/store/mainStore';
import { OpenedMenuItem, ClosedMenuItem } from './MenuItem';
import { getMenuItems, getAllRoutes } from './utils';
import styles from './style.module.scss';

const DrawerBody = ({ isOpened, history, onChangeRoute = () => {} }) => {
  const [items, setItems] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [activeRoute, setActiveRoute] = useState(null);
  const [isSubmenuOpened, setIsSubmenuOpened] = useState(false);
  
  useEffect(() =>  {
    // to do: digita pagina manualmente no browser: n detecta mudança
    const pathname = window.location.hash.replace('#', '');
    const menuList = getMenuItems(history);
    const routes = getAllRoutes();
    const currentRoute = routes.find(item => pathname === item.route);
    setItems(menuList);
    setSelectedRoute(currentRoute ? currentRoute.index : null);
    setActiveRoute(currentRoute ? currentRoute.index : null);

  }, [mainStore.loggedUser, mainStore.features, history]);

  useEffect(() =>  {
    // to do: digita pagina manualmente no browser: n detecta mudança
  }, [history]);

  useEffect(() =>  {
    if(isSubmenuOpened) {
      setItems(getMenuItems(history, selectedRoute));
    } else {
      setItems(getMenuItems(history));
    }
  }, [selectedRoute, isSubmenuOpened, history]);

  useEffect(() =>  {
    if (!isOpened && isSubmenuOpened) {
      setIsSubmenuOpened(false);
      setSelectedRoute(activeRoute);
    }
  }, [isOpened, isSubmenuOpened, activeRoute]);
  
  const onClickItem = (item) => {
    const { route } = item;
    
    if (item.target && item.target === '_blank') {
      window.open(`#${item.route}`, item.target);
      return;
    }
    if (!!item.goBack) {
      setItems(getMenuItems(history));
      setIsSubmenuOpened(false);
      return;
    }

    if (route) {
      const routes = getAllRoutes();
      const currentRoute = routes.find(item => route === item.route);
      setActiveRoute(currentRoute.index);
    }
    
    setSelectedRoute(item.index);

    if (!item.submenu) {
      setIsSubmenuOpened(false);
      history.push(item.route);
      onChangeRoute(true);
    } else {
      setIsSubmenuOpened(true);
    }
  }

  return (
    <div className={styles.body}>
      {
        items.map((item) => {
          if (isOpened) {
            return (
              item.isAuthorized &&
              <OpenedMenuItem
                item={item}
                key={item.name}
                isActive={item.index === activeRoute && (!isSubmenuOpened)}
                onClickItem={() => onClickItem && onClickItem(item)}
              />
            );
          }

          return (
            item.isAuthorized &&
            <ClosedMenuItem 
              item={item} 
              key={item.name}
              isActive={item.index === activeRoute}
            />
          );
        })
      }
    </div>
  ); 
}

export default DrawerBody;
