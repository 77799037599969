import { makeStyles } from '@material-ui/core';

export const customStyles = makeStyles({
    imageIcon: { height: '100%' },
    iconRoot: {
        textAlign: 'center',
        marginRight: '0.5rem',
        width: 'fit-content',
    },
});
  
export function isValidForm(cpf, password) {
    return cpf.length > 0 && password.length > 0;
}