import React from 'react';
import classes from '../styles.module.scss';

export default () => (
  <section className={classes.section}>
    <h2 className={classes.title}>SEGURANÇA DA INFORMAÇÃO</h2>

    <p>
      A Involves empreenderá todos os esforços razoáveis de mercado para
      garantir a segurança dos seus dados. Portanto, tudo o que produzimos
      são desenvolvidos com recursos de segurança sólidos, buscando proteger
      continuamente suas informações.
    </p>

    <p className={classes.doubleMargin}>
      A segurança dos seus dados é importante para nós, mas lembre-se de que
      nenhum método de transmissão pela internet ou método de armazenamento
      eletrônico é 100% seguro. Embora nos esforcemos para usar meios
      comercialmente aceitáveis para proteger seus dados pessoais, não
      podemos garantir sua segurança absoluta.
      <br />
      
      Assim, além das proteções que a Involves aplica, é importante também
      que você tenha um comportamento seguro, identificando e evitando
      situações que possam ameaçar a segurança dos seus dados e de nossos
      produtos e serviços. Caso você identifique ou tome conhecimento de
      algo que comprometa a segurança dos seus dados, é essencial que entre
      em contato conosco.
    </p>
  </section>
);

    