import React, { MouseEvent } from 'react';
import { Box, Chip, Grid, TextField } from '@material-ui/core';
import { AutocompleteRenderInputParams } from '@material-ui/lab';
import Autocomplete from '../../../components/Autocomplete';
import { DefaultButton, CancelButton } from '../../../components/Buttons';
import { CLEAR, SEARCH } from '../../../core/constants/dictionary';
import { RetailEntityType } from '../../../core/types/retail/retail-entity.type';
import classes from '../Supplier.module.scss';

type Props = {
  value: RetailEntityType | null;
  options: RetailEntityType[];
  onClear: Function;
  onChange: (retail: RetailEntityType) => void;
  onSubmit: (event: MouseEvent<HTMLButtonElement>) => void;
};

const SupplierFilter = ({ value, options, onChange, onSubmit, onClear }: Props) => {
  return (
    <Box marginBottom={4}>
      <Grid container spacing={2}>
        <Grid item md={4} sm={6} xs={12}>
          <Autocomplete
            size='small'
            value={value}
            options={options}
            getOptionLabel={(retail: RetailEntityType) => retail.name}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField 
                {...params} 
                label={'Rede'} 
                variant="outlined" 
                className={classes.autocompleteInput}
              />
            )}
            renderTags={(
              value: RetailEntityType[],
              getTagProps: Function,
              closeInput: boolean,
            ) => {
              return value.map((option: RetailEntityType, index: number) => (
                <Chip
                  key={index}
                  label={option.name}
                  {...getTagProps({ index })}
                  disabled={closeInput}
                />
              ))
            }}
            onChange={(_: never, value: RetailEntityType) => onChange(value)}
            style={{width: '100%'}}
          />
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <DefaultButton
                label={SEARCH}
                //disableElevation
                onClick={onSubmit}
              />
            </Grid>
            <Grid item>
              <CancelButton
                label={CLEAR}
                onClick={onClear}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SupplierFilter;
