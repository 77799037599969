import * as dictionary from '../../../../../../core/constants/dictionary';
import { mainStore } from '../../../../../../core/store/mainStore';
import { CreatePromotionalSpotAddressingErrors } from '../../../../../../core/types/promotional-spot/errors/create-prmotional-spot-addressing-error';
import { defaultFormFields } from '../utils';

export const loggedUserStore = {
  id: mainStore?.loggedUser?.storeId,
  name: mainStore?.loggedUser?.storeName,
};

export const errorMessageToFeedBack = (error: string) => {
  if (
    error === CreatePromotionalSpotAddressingErrors.DUPLICATED_PHYSICAL_ADDRESS
  ) {
    return {
      ...defaultFormFields,
      physicalAddressing: {
        errorText: dictionary.CREATE_PROMOTIONAL_SPOT_ERROR_DUPLICATED,
        showError: true,
      },
    };
  }
  return {
    ...defaultFormFields,
    snackError: {
      show: true,
      type: dictionary.ERROR,
      message: dictionary.CREATE_PROMOTIONAL_SPOT_ERROR,
    },
  };
};
