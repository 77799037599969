import API from '../api/config';
import * as routes from '../api/routes';
import { exist } from '../../validation';

export const postAgreementAPI = async data => {
  const url = `${routes.API_AGREEMENTS}`;
  const response = await API.post(url, data);
  return response;
};

export const getAgreementAPI = async (retailId, status, offset = 0, limit = 999999, query = null) => {
  const offsetParam = `?offset=${offset}`;
  const limitParam = `&limit=${limit}`;
  const statusParam = exist(status) ? `&status=${status}` : '';
  const retailParam = exist(retailId) ? `&retailId=${retailId}` : '';
  const queryParam = exist(query) ? `&q=${query}` : '';
  const url = `${routes.API_AGREEMENTS}${offsetParam}${limitParam}${statusParam}${retailParam}${queryParam}`;
  const response = await API.get(url);
  
  return response.data;
};

export const putAgreementAPI = async data => {
  const url = `${routes.API_AGREEMENTS}/${data.id}`;
  const response = await API.put(url, data);
  return response;
};

export const deleteAgreementAPI = async agreement => {
  const url = `${routes.API_AGREEMENTS}/${agreement.id}`;
  const response = await API.delete(url);
  return response;
};

export const putVisitsAPI = async (data, id) => {
  const url = `${routes.API_AGREEMENTS}/${id}${routes.API_SCHEDULED_VISITS}`;
  const response = await API.put(url, data);
  return response;
};

export const getScheduledVisitsAPI = async id => {
  const url = `${routes.API_AGREEMENTS}/${id}${routes.API_SCHEDULED_VISITS}?offset=0&limit=999999`;
  const response = await API.get(url);
  return response.data.items;
};

export const addPromoterToAgreementAPI = async (agreementId, userId) => {
  const data = { promoterUserId: userId, status: 0 };
  const url = `${routes.API_AGREEMENTS}/${agreementId}${routes.API_PROMOTERS}`;
  return API.post(url, data);
};

export const removePromoterByAgreementAPI = async (agreementId, promoterId) => {
  const url = `${routes.API_AGREEMENTS}/${agreementId}${routes.API_PROMOTERS}/${promoterId}`;
  return API.delete(url);
};

export const updatePromoterByAgreementAPI = (agreementId, promoterId, data) => {
  const url = `${routes.API_AGREEMENTS}/${agreementId}${routes.API_PROMOTERS}/${promoterId}`;
  return API.put(url, data);
};
