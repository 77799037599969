import React from 'react';
import classes from '../styles.module.scss';
import { PROMOTER_TERMS_OF_USE_URL } from '../utils';

export default () => (
    <section className={classes.section}>
        <h2 className={classes.title}>Sobre esta política</h2>

        <p className={classes.doubleMargin}>
            Nós da <strong>INVOLVES SOLUÇÕES TECNOLÓGICAS S/A</strong>, empresa
            devidamente inscrita no CNPJ/MF 10.903.643/0001-07, com sede na Av.
            Patrício Caldeira de Andrade, nº. 1156, CEP 88.085-150, Capoeiras,
            Florianópolis/SC, (a “Involves”), reconhecemos que a sua privacidade é
            muito importante, portanto, tomamos todas as medidas possíveis para
            protegê-la, assumindo a responsabilidade de operar os seus dados
            pessoais de forma íntegra e confidencial. A presente Política de
            Privacidade (“Política”) define os detalhes essenciais relativos aos
            seus dados pessoais no contexto da utilização do aplicativo para
            celular e dispositivos móveis Involves Doors ("Involves Doors"). As
            condições que regem o uso do Aplicativo encontram-se definidas no
            nosso documento{' '}
            <a href={ PROMOTER_TERMS_OF_USE_URL } title="Termos e Condições de Uso">
                Termos e Condições de Uso
            </a>.
        </p>
    </section>
);