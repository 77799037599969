import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Chip,
  DialogActions,
  TextField,
  Typography,
} from '@material-ui/core';
import ModalTitle from '../../../../../../../components/Modal/Title';
import Autocomplete from '../../../../../../../components/Autocomplete';
import ModalSmallContainer from '../../../../../../../components/Modal/SmallContainer';
import { CancelButton } from '../../../../../../../components/Buttons';
import * as dictionary from '../../../../../../../core/constants/dictionary';
import classes from './Modals.module.scss';
import { getSuppliersByRetail } from '../../../../../../../core/services/suppliers/suppliersService';
import {
  getFamiliesProductsInRetail,
  getProductsInRetail,
} from '../../../../../../../core/services/familyProduct/familyProductService';
import {
  ProductEntity,
  ProductFamilyEntity,
  SuppliersEntity,
} from '../../../../../../../core/types/promotional-spot/product-family-entity.type';
import {
  familiesToRemove,
  parseProductLabel,
  parseProductQueryParamsFromText,
  parseProductsFromFamily,
  parseSupplierLabel,
  removeProductsDuplicated,
} from './utils';
import useRetailInUser from '../../../../../common/components/hooks/use-retail-in-user.hook';
import { useDebounce } from '../../../../../../../core/hooks';

type Props = {
  isUpdate?: boolean;
  isEditable: boolean;
  title?: string;
  datetime?: string;
  isOpen: boolean;
  onClose: Function;
  onSubmit: Function;
  data?: {
    id?: string;
    product?: ProductEntity[];
    supplier: SuppliersEntity[];
    description: string;
    familiesIds?: string[];
  };
};

const UpdateModal = ({
  isUpdate,
  title,
  datetime,
  data,
  isOpen,
  onClose,
  onSubmit,
  isEditable,
}: Props) => {
  const [product, setProduct] = useState<ProductEntity[]>(data?.product ?? []);
  const [supplier, setSupplier] = useState<SuppliersEntity[]>(
    data?.supplier ?? []
  );
  const [supplierOptions, setSupplierOptions] = useState<SuppliersEntity[]>([]);
  const [isLoadingProduct, setIsLoadingProduct] = useState<boolean>(false);
  const [productOptions, setProductOptions] = useState<ProductEntity[]>([]);
  const [familyOptionss, setFamilyOptions] = useState<ProductFamilyEntity[]>(
    []
  );
  const [family, setFamily] = useState<ProductFamilyEntity[]>([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState<boolean>(false);
  const [description, setDescription] = useState<string>(
    data?.description ?? ''
  );
  const [formFields, setFormFields] = useState({
    supplier: {
      showError: false,
      errorText: '',
    },
    product: {
      showError: false,
      errorText: '',
    },
    family: {
      showError: false,
      errorText: '',
    },
  });

  // TODO trazer retail na lista de spot
  const retailId = useRetailInUser();

  const fetchSuppliers = useCallback(
    useDebounce(async (query?: string) => {
      try {
        const supplierList = await getSuppliersByRetail({ 
          retailId, 
          status: 1,
          q: query,
        });
        setSupplierOptions(supplierList.items);
      } catch (err) {
        setSupplierOptions([]);
        setIsLoadingOptions(false);
      }
    }, 500),
    []
  );

  const fetchFamiliesInNegotiation = useCallback(
    async (familiesIds: string[]) => {
      try {
        const { data } = await getFamiliesProductsInRetail({
          retailId,
          familiesIds,
        });
        setFamily(data);
      } catch (error) {}
    },
    [retailId]
  );

  const fetchFamily = useCallback(
    useDebounce(async (query: string) => {
      try {
        const { data } = await getFamiliesProductsInRetail({
          q: query[0],
          retailId,
        });
        setFamilyOptions(data);
      } catch (error) {}
    }, 500),
    []
  );

  const fetchProducts = useCallback(
    useDebounce(async (query?: string[]) => {
      if (!isLoadingProduct) {
        try {
          if (query) {
            const params = parseProductQueryParamsFromText(query[0] || '');

            const {data} = await getProductsInRetail({ ...params, retailId, limit: 100 });
            setProductOptions(data.items);
            setIsLoadingProduct(false);
          }
        } catch (error) {
          setIsLoadingProduct(false);
        }
      }
    }, 500),
    [isLoadingProduct]
  );

  const fetchOptions = useCallback(async () => {
    if (!isLoadingOptions && retailId) {
      try {
        setIsLoadingOptions(true);
        await Promise.all([fetchProducts(), fetchSuppliers()]);
      } catch (err) {
        setSupplierOptions([]);
        setIsLoadingOptions(false);
      }
    }
  }, [isLoadingOptions, retailId]);

  const onSubmitHandler = (_evt: MouseEvent) => {
    let isValid = true;
    if (product && product.length === 0) {
      setFormFields((prevState) => ({
        ...prevState,
        product: {
          showError: true,
          errorText: dictionary.FIELD_NOT_EMPTY,
        },
      }));
      isValid = false;
    }
    if (supplier && supplier.length === 0) {
      setFormFields((prevState) => ({
        ...prevState,
        supplier: {
          showError: true,
          errorText: dictionary.FIELD_NOT_EMPTY,
        },
      }));
      isValid = false;
    }

    if (isValid) {
      onSubmit(
        data?.id ?? '',
        supplier,
        product,
        description,
        family.map((item) => item.code)
      );
    }
    setProduct([]);
    setSupplier([]);
    setFamily([]);
    setDescription('');
  };

  const isDisabledSubmit = useMemo(
    () => !supplier?.length || !product.length,
    [supplier, product]
  );

  const onChangeFamilues = (
    products: ProductEntity[],
    newFamilies?: ProductFamilyEntity[],
    oldFamilies?: ProductFamilyEntity[]
  ) => {
    const removeList = familiesToRemove(oldFamilies ?? [], newFamilies ?? []);
    const productsInFamilies = removeProductsDuplicated(
      products.concat(parseProductsFromFamily(newFamilies))
    );
    let tempProducts = [...productsInFamilies];

    for (const familyToRemove of removeList) {
      tempProducts = tempProducts.filter(
        (item) =>
          !familyToRemove.products.find(
            (i) => item.internalCode === i.internalCode
          )
      );
    }

    setProduct(removeProductsDuplicated(tempProducts));

    setFamily(newFamilies ?? []);
  };

  const onCloseModal = () => {
    onClose();
    setProduct(data?.product ?? []);
    setSupplier(data?.supplier ?? []);
    setDescription(data?.description ?? '');
    setFamily([]);
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    if (data?.familiesIds?.length) {
      fetchFamiliesInNegotiation(data.familiesIds);
    }
  }, [data?.familiesIds]);

  return (
    <ModalSmallContainer
      isOpen={isOpen}
      closeModalCallback={onCloseModal}
      overflowY="scroll"
    >
      <ModalTitle
        title={
          isUpdate ? dictionary.EDIT_NEGOTIATION : dictionary.NEW_NEGOTIATION
        }
        className={classes.modalTitle}
      />
      <div className={classes.formHeader}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography>{datetime}</Typography>
      </div>
      <div className={classes.formBody}>
        <Autocomplete
          multiple
          size="small"
          id="supplier"
          value={supplier}
          limitTags={2}
          options={supplierOptions}
          label={dictionary.SUPPLIER}
          renderTags={(
            value: SuppliersEntity[],
            getTagProps: Function,
            closeInput: boolean
          ) => {
            return value.map((option: SuppliersEntity, index: number) => (
              <Chip
                key={index}
                label={parseSupplierLabel(option)}
                {...getTagProps({ index })}
                disabled={closeInput}
              />
            ));
          }}
          onInputChange={(event: any) => {
            if (event !== null) {
              fetchSuppliers(event.target.value);
            }
          }}
          showError={formFields.supplier.showError}
          errorText={formFields.supplier.errorText}
          getOptionLabel={(option: SuppliersEntity) =>
            parseSupplierLabel(option)
          }
          getLimitTagsText={(value: number) => <Chip label={`+${value}`} />}
          onChange={(_: never, value: SuppliersEntity[]) => setSupplier(value)}
          style={{}}
          className={classes.input}
        />

        <Autocomplete
          multiple
          id="family"
          size="small"
          required={false}
          value={family}
          limitTags={2}
          options={familyOptionss}
          label={dictionary.FAMILY}
          getOptionLabel={(option: ProductFamilyEntity) => option.description}
          renderTags={(
            value: ProductFamilyEntity[],
            getTagProps: Function,
            closeInput: boolean
          ) => {
            return value.map((option: ProductFamilyEntity, index: number) => (
              <Chip
                key={option.code}
                label={option.description}
                {...getTagProps({ index })}
                disabled={closeInput}
              />
            ));
          }}
          onInputChange={(event: any) => {
            if (event !== null) {
              fetchFamily(event?.target?.value);
            }
          }}
          showError={formFields.family.showError}
          errorText={formFields.family.errorText}
          style={{}}
          className={classes.input}
          onChange={(_: never, value: ProductFamilyEntity[]) => {
            onChangeFamilues(product, value, family);
          }}
          getLimitTagsText={(value: number) => <Chip label={`+${value}`} />}
        />

        <Autocomplete
          multiple
          limitTags={2}
          id="product"
          size="small"
          value={product}
          options={productOptions.filter(
            (p) => !product.find((item) => item.id === p.id)
          )}
          label={dictionary.PRODUCTS}
          getOptionLabel={(option: ProductEntity) => parseProductLabel(option)}
          renderTags={(
            value: ProductEntity[],
            getTagProps: Function,
            closeInput: boolean
          ) => {
            return value.map((option: ProductEntity, index: number) => (
              <Chip
                key={option.id}
                label={parseProductLabel(option)}
                {...getTagProps({ index })}
                disabled={closeInput}
              />
            ));
          }}
          showError={formFields.product.showError}
          errorText={formFields.product.errorText}
          style={{}}
          className={classes.input}
          onInputChange={(event: any) => {
            if (event !== null) {
              fetchProducts(event.target.value);
            }
          }}
          loading={isLoadingProduct}
          loadingText={'Carregando...'}
          onChange={(_: never, value: any) => setProduct(value)}
          getLimitTagsText={(value: number) => <Chip label={`+${value}`} />}
        />

        <TextField
          multiline
          variant="outlined"
          label={dictionary.OBSERVATIONS}
          value={description}
          maxRows={4}
          className={classes.textarea}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            setDescription(e.currentTarget.value)
          }
        />
      </div>
      <DialogActions className={classes.footer}>
        <CancelButton label={dictionary.CANCEL} onClick={onCloseModal} />
        {isEditable && (
          <Button
            disabled={isDisabledSubmit}
            color="primary"
            variant="contained"
            onClick={onSubmitHandler}
          >
            {dictionary.SAVE}
          </Button>
        )}
      </DialogActions>
    </ModalSmallContainer>
  );
};

export default UpdateModal;
