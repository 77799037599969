import React from 'react';
import { PromotionalSpotDto } from '../../../../common/types';
import { PromotionalCalendarDto } from '../../../../../../core/types/promotional-spot/promotional-calendar.dto';
import * as dictionary from '../../../../../../core/constants/dictionary'
import BaseModal from '../BaseModal';

type Props = {
  data: PromotionalSpotDto,
  calendars: PromotionalCalendarDto[],
  onClose: Function,
  onEdit: Function,
}

const EditPromotionalSpotModal = ({
  data,
  calendars,
  onClose,
  onEdit,
}: Props) => {
  return (
    <BaseModal
      isEditMode
      data={data}
      feedbackError={dictionary.UPDATE_PROMOTIONAL_SPOT_ERROR}
      feedbackSuccess={dictionary.UPDATE_PROMOTIONAL_SPOT_SUCCESS}
      calendars={calendars}
      onSubmit={onEdit}
      onClose={onClose}
    />
  );
}

export default EditPromotionalSpotModal;
