import React from 'react';
import classes from '../styles.module.scss';

export default () => (
  <section className={classes.section}>
    <h2 className={classes.title}>
      COMPARTILHAMENTO OU DIVULGAÇÃO DE DADOS PESSOAIS
    </h2>

    <p>
      As informações ao qual a Involves possui acesso são de seu uso
      exclusivo, para as finalidades aqui referidas, portanto, não serão
      vendidas a terceiros.
      <br />
      No entanto, a Involves poderá divulgar os seus dados pessoais na
      crença de boa fé de que tal ação é necessária para:
    </p>
  </section>
);

    