import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Button, Text } from '@involves/violin';

export const StyledInputPlaceholder = styled.div`
  position: absolute;
  top: 6px;
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;

  height: 52px;
  width: 100%;

  border-radius: 8px;
  border: 2px solid;
  box-sizing: border-box;
  border-color: ${theme('palette.misc.white')};

  font-size: ${theme('typography.fontSize.h1')};
  background: ${theme('palette.misc.white')};

  opacity: ${props => (props.show ? 1 : 0)};
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  transition: ${({ theme }) => theme.common.transition};
`;

export const StyledInputPlaceholderContent = styled(Text)`
  padding-left: 21px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledInputPlaceholderClearButton = styled(Button)`
  justify-self: center;
`;
