import styled from 'styled-components';

export const StyledInputDropdownItem = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  padding-left: 21px;
  transition: ${({ theme }) => theme.common.transition};
  cursor: pointer;
  :hover {
    background: ${props => props.theme.palette.black[100]};
  }
`;
