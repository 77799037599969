// @ts-ignore
import { triggerBase64Download } from 'react-base64-downloader';
import { userProfile } from '../../core/profiles';
import { postUser } from '../../core/services/users/usersService';

type PromoterDTO = {
  name?: string;
  cpf?: string;
  profile?: object;
  email?: string;
  status?: number;
  rg?: string;
  password?: string;
}

export const downloadFile = (docs: any, docType: any) => {
  docs.forEach((doc: any, index: any) => {
    const base64 = `data:image/png;base64,${doc}`;
    triggerBase64Download(base64, `${docType}${index}`);
  });
};

export const emptyNewPromoterFormValue = {
  name: '',
  cpf: '',
  password: '',
  confirmPassword: '',
  profile: userProfile.PROMOTER,
  email: '',
  status: 1,
  rg: '',
};

export const registerPromoter = (promoter: PromoterDTO) => {
  const dto = {
    name: promoter.name,
    cpf: promoter.cpf,
    profile: promoter.profile,
    email: promoter.email,
    status: promoter.status,
    rg: promoter.rg,
    password: promoter.password,
  };

  return postUser(dto);
};

export const promoterStatus = {
  PENDING: 0,
  ACTIVE: 1,
  INACTIVE: 2,
}
