import React from 'react';
import { Typography } from '@material-ui/core';
import * as dictionary from '../../../../../../../core/constants/dictionary';
import BaseModal from './BaseModal';
import classes from './Modals.module.scss';

type Props = {
  isOpen: boolean;
  onClose: Function;
  onSubmit: Function;
};

const UnFixNegotiationModal = ({ isOpen, onClose, onSubmit }: Props) => {
  return (
    <BaseModal
      title={dictionary.UNFIX_NEGOTIATION_TITLE}
      isOpen={isOpen}
      onClose={onClose}
      onClick={() => onSubmit()}
      label={{ submit: dictionary.CONFIRM }}
    >
      <Typography className={classes.caption}>
        {dictionary.UNFIX_NEGOTIATION}
      </Typography>
    </BaseModal>
  );
};

export default UnFixNegotiationModal;
