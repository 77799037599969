import React from 'react';
import { TableCell, Tooltip } from '@material-ui/core';

type Props = {
  storeCode: string;
  storeName: string;
}

const NegotiationHeaderCell = ({ storeName, storeCode }: Props) => {
  return (
    <TableCell align='center'>
      <Tooltip title={storeName}>
        <span>{storeCode}</span>
      </Tooltip>
    </TableCell>
  );
}

export default NegotiationHeaderCell;
