import React from 'react';
import { Typography } from '@material-ui/core';
import SuccessModal from '../../../../containers/SuccessModal';
import { MAX_MODAL_WIDTH } from '../utils';
import * as dictionary from '../../../../core/constants/dictionary';
import classes from '../AutoCheckinModal.module.scss';

type Props = {
  isOpen: boolean,
  promoterName: string,
  userProfile: any,
  onCloseModalHandler: any
};

const AutoCheckinSuccessModal = ({ 
  isOpen, 
  promoterName, 
  onCloseModalHandler 
}: Props) => (
  <SuccessModal
    isOpen={isOpen}
    width={MAX_MODAL_WIDTH}
    notificationText={dictionary.REMEMBER_CHECKOUT_PROMOTER_MESSAGE}
    onCloseModalHandler={onCloseModalHandler}
  >
    <div className={classes.body}>
      <Typography align="center" className={classes.title}>
        {`${dictionary.HI}, ${promoterName}!`}
      </Typography>

      <Typography align="center" className={classes.bodyText}>
        { dictionary.SUCCESS_CHECKIN_MESSAGE_PROMOTER }
      </Typography>
    </div>
  </SuccessModal>
);

export default AutoCheckinSuccessModal;
