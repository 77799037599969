import { format } from 'date-fns';
import { CheckStatus } from '../types/check-status.enum';
import { ShelfProblems } from '../types/shelf-problems.enum';
import { ShelfStatus } from '../types/shelf.status.enum';
import { SupplyStatus } from '../types/supply-status.enum';

export const brlFormatter = Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export type Item = {
  store: {
    code: string;
    name: string;
  };
  product: {
    code: string;
    name: string;
  };
  check: MissionCheckItem;
  restock: MissionRestockItem;
  daysWithoutSale: number;
  shoppersPerDay: number;
  estimatedSale: number;
};

export type MissionCheckItem = {
  createdAt: string;
  avgRevenue: number;
  avgShoppersPerDay: number;
  daysWithoutSale: number;
  shoppersPerDay: number;
  status: CheckStatus;
  shelfStatus: ShelfStatus;
  shelfProblems: ShelfProblems[];
}

export type MissionRestockItem = {
  status: SupplyStatus | null;
  virtualStock: number | null;
}

export const getformattedCurrency = (value: number) =>{
  return brlFormatter.format(value);
};

export const getSupplyStatusAndProblemsDescription = (
  { check, restock }: Partial<{check: MissionCheckItem, restock: MissionRestockItem}>
): string => {
  if (check && check.status === CheckStatus.FULL_SHELF) {
    if (check.shelfProblems.length === 0) {
      return 'Sem problemas';
    }

    const problems = [];

    if (check.shelfProblems.includes(ShelfProblems.MISSING_PRICE_TAG)) {
      problems.push('Falta etiqueta de preço');
    }
    if (check.shelfProblems.includes(ShelfProblems.BAD_DISPLAY)) {
      problems.push('Gôndola desorganizada');
    }

    return problems.join(',');
  }

  if (restock) {
    switch (restock.status) {
      case SupplyStatus.PENDING:  return 'Sem reposição';
      case SupplyStatus.DONE:     return 'Abastecido';
      case SupplyStatus.NO_STOCK:
        if (restock.virtualStock === 1) {
          return 'Estoque virtual';
        }
        return 'Em falta no depósito';
    }
  }

  return '-';
};

export const getFormattedDate = (date:string, pattern = 'dd/MM/yyyy') => {
  const initialDate = new Date(date);
  return format(initialDate, pattern);
};