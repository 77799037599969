import React, { useEffect, useState, useMemo } from 'react';
import { view } from 'react-easy-state';
import {
  Link,
  Typography,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';
import logo from '../../assets/logos/full-logo-dark.svg';
import * as dictionary from '../../core/constants/dictionary';
import './styles.css';
import { mainStore } from '../../core/store/mainStore';
import DefaultButton from '../../components/Buttons/Default';
import TextField from '../../components/InputTextField';
import { redirectToHome } from '../../core/authorization';
import * as routes from '../../core/constants/routes';
import ModalTitle from '../../components/Modal/Title';
import {
  invalid,
  SyncValidations,
  valid,
} from '../../core/validator/validations';
import { handleSubmit, loadInitialData } from './functions';
import { CpfTextMaskCustom } from '../../core/masks/masks';

import { exist } from '../../core/validation';
import { utils } from '../../core/utils';
import { colors } from '../../Theme/colors';

const modal = utils.getSnackbar();

const Register = ({ history }) => {
  const [invite, setInvite] = useState(null);
  const params = new URLSearchParams(history.location.search);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [finished, setFinished] = useState(false);
  const [isAccept, setIsAccept] = useState(false);

  useEffect(() => {
    (async function load() {
      try {
        const result = await loadInitialData(params);
        if (exist(result)) {
          setInvite(result);
          setEmail(result.email);
        }
        setLoading(false);
        return;
      } catch (e) {
        history.push(routes.REGISTER);
      }
    })();
  }, [history.location.search, history, params]);

  const api = {
    name,
    setName,
    cpf,
    setCpf,
    email,
    setEmail,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    finished,
    setFinished,
    loading,
    setLoading,
    setInvite,
    invite,
  };

  const passwordMatch = event => {
    if (password !== confirmPassword) {
      event.target.setCustomValidity(' ');
      return invalid(dictionary.PASSWORD_NOT_MATCH);
    }
    event.target.setCustomValidity('');
    return valid();
  };

  const finishedContent = (
    <>
      <div className="logo-icon">
        <img
          alt={dictionary.INVOLVES_DOORS_LOGO}
          style={{ width: '200px' }}
          src={logo}
        />
      </div>
      <div align="center">
        <Typography variant="h2">{dictionary.SALES_REP_REGISTERED}</Typography>
      </div>
      <div className="submit-login">
        <DefaultButton
          style={{ height: '52px', marginBottom: '30px' }}
          onClick={async () => {
            history.push(routes.LOGIN);
          }}
          label={dictionary.RETURN_TO_LOGIN}
        />
      </div>
    </>
  );

  const submitForm = async e => {
    if (!isAccept) {
      return false;
    }
    return await handleSubmit(e, api);
  };

  const checkBoxSelection = useMemo(
    () => (
      <Checkbox checked={isAccept} onChange={() => setIsAccept(!isAccept)} />
    ),
    [isAccept],
  );

  const salesRepTermLink = useMemo(
    () => (
      <Link
        href={`${window.location.origin}/#${routes.SALESREP_TERMS_OF_USE}`}
        target="_blanck"
      >
        {dictionary.USER_TERMS}
      </Link>
    ),
    [],
  );

  if (mainStore.token) {
    return redirectToHome();
  }

  if (loading) {
    return <CircularProgress className="centered-loader" />;
  }

  const formContent = (
    <>
      <form className="login-form-div" noValidate>
        <div className="logo-icon">
          <img
            alt={dictionary.INVOLVES_DOORS_LOGO}
            style={{ width: '200px' }}
            src={logo}
          />
        </div>

        <ModalTitle title={dictionary.REGISTER_SALES_REP} />

        <div className="input-form">
          <Typography variant="body1">{dictionary.NAME}</Typography>
          <TextField
            style={{ width: '100%' }}
            validations={[SyncValidations.notEmpty]}
            placeholder={dictionary.TYPE_NAME}
            name={dictionary.TYPE_NAME}
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>

        {!exist(invite) && (
          <div className="input-form">
            <Typography variant="body1">{dictionary.EMAIL}</Typography>

            <TextField
              style={{ width: '100%' }}
              placeholder={dictionary.TYPE_EMAIL}
              name={dictionary.EMAIL_NAME}
              validations={[
                SyncValidations.notEmpty,
                SyncValidations.validEmail,
              ]}
              value={email}
              onChange={e => setEmail(e.target.value)}
              type="email"
            />
          </div>
        )}

        <div className="input-form">
          <Typography variant="body1">{dictionary.CPF}</Typography>
          <TextField
            validations={[SyncValidations.notEmpty]}
            style={{ width: '100%' }}
            placeholder={dictionary.TYPE_CPF}
            name={dictionary.CPF_NAME}
            value={cpf}
            onChange={e => setCpf(e.target.value)}
            InputProps={{
              inputComponent: CpfTextMaskCustom,
            }}
          />
        </div>

        <div className="input-form">
          <Typography variant="body1">{dictionary.PASSWORD}</Typography>
          <TextField
            style={{ width: '100%' }}
            validations={[SyncValidations.notEmpty]}
            name={dictionary.PASSWORD_NAME}
            placeholder={dictionary.TYPE_PASSWORD}
            onChange={e => setPassword(e.target.value)}
            value={password}
            type="password"
          />
        </div>

        <div className="input-form">
          <Typography variant="body1">{dictionary.CONFIRM_PASSWORD}</Typography>
          <TextField
            style={{ width: '100%' }}
            validations={[SyncValidations.notEmpty, passwordMatch]}
            placeholder={dictionary.TYPE_CONFIRM_PASSWORD}
            name={dictionary.CONFIRM_PASSWORD_NAME}
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            type="password"
          />
        </div>

        <div style={{ textAlign: 'center' }}>
          <FormControlLabel
            style={{ textAlign: 'center' }}
            control={checkBoxSelection}
            label={salesRepTermLink}
          />
        </div>

        <div id="submit-register">
          <DefaultButton
            style={{ height: '52px', marginTop: '29px' }}
            onClick={submitForm}
            disabled={!isAccept}
            label={dictionary.SEND}
          />
        </div>

        <div className="forgot-password">
          <Typography variant="body2">
            <Link
              className="clickable"
              onClick={() => history.push(routes.LOGIN)}
            >
              {dictionary.LOGIN_INSTEAD}
            </Link>
          </Typography>
        </div>
      </form>
    </>
  );

  let content;
  if (finished) {
    content = finishedContent;
  } else {
    content = formContent;
  }

  return (
    <div className="login-container" style={{ background: colors.black_900 }}>
      <div
        id="register-form"
        style={{
          background: colors.white,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {content}
      </div>
      {modal}
    </div>
  );
};

export default view(Register);
