import React from 'react';
import { Typography } from '@material-ui/core';
import DangerModal from '../../../../containers/DangerModal';
import classes from '../PromoterModal.module.scss';
import { MAX_MODAL_WIDTH } from '../../../VisitsAutoCheckin/components/utils';
import * as dictionary from '../../../../core/constants/dictionary';

type Props = {
  isOpen: boolean;
  data: {
		promoterName: string;
	};
  onReloadHandler?: Function;
  onContinueHandler: Function;
  onCloseModalHandler: Function;
}

const PromoterSuspensionModal = ({ 
  isOpen, 
  data, 
  onReloadHandler, 
  onContinueHandler,
  onCloseModalHandler,
}: Props) => {
  return (
    <DangerModal
			isOpen={isOpen}
			width={MAX_MODAL_WIDTH}
			notificationText={dictionary.ASK_STORE_MANAGER_TO_UNLOCK}
      onContinueHandler={onContinueHandler}
			onCloseModalHandler={() => {
        onReloadHandler && onReloadHandler();
        onCloseModalHandler();
      }}
		>
			<div className={classes.body}>
        <Typography align="center" className={classes.title}>
          {`${dictionary.SUSPENDED_ACCESS}!`}
        </Typography>

        <Typography align="center" className={classes.bodyText}>
          <span className={classes.semiBold}>{ data.promoterName}</span>{' '}
          { dictionary.EXCEEDED_AMOUNT_FORGETFULNESS }
        </Typography>
      </div>
		</DangerModal>
  )
}

export default PromoterSuspensionModal;
