import axios from 'axios';
import { 
  axiosDefaultConfig,
  useDefaultInterceptorRequest, 
  useDefaultInterceptorResponse,
} from './axiosConfig';

const API = axios.create(axiosDefaultConfig);

API.interceptors.request.use(
  async config => useDefaultInterceptorRequest(config),
  error => Promise.reject(error),
);

API.interceptors.response.use(
  async response => response,
  async error => useDefaultInterceptorResponse(error),
);

export default API;
