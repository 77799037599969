import React from 'react';
import PromoterTermsLayout from '../../containers/SimplePublicLayout';
import SectionBlock from './components/SectionBlock';
import SectionTerm from './components/SectionTerm';
import { HELP_INVOLVES_LINK, POLITICA_PRIVACIDADE_LINK } from './utils';
import classes from '../../containers/SimplePublicLayout/styles.module.scss';

export default function PromoterTermsOfUser() {
  return (
    <PromoterTermsLayout
      title="Termos de uso e condições gerais"
      lastUpdate=" Última versão: 14 de janeiro de 2020."
    >
      <h2 className={classes.subtitle}>Ao Usuário do Involves Doors,</h2>

      <SectionBlock>
        <p>
          Obrigado por dedicar este tempo para ler os Termos e Condições Gerais
          de Uso (“Termos”) que regem nossa plataforma e os serviços ali
          disponíveis.
        </p>
        <p>Sempre que for utilizado:</p>
      </SectionBlock>
      
      <SectionBlock>
        <ul>
          <li>
            <strong>Plataforma</strong>, refere-se a plataforma denominada{' '}
            <strong>Involves Doors</strong>, de propriedade da empresa{' '}
            <strong>INVOLVES SOLUÇÕES TECNOLÓGICAS S/A</strong>, ora denominada
            Involves. Para fins destes Termos, considera-se tanto o uso via web,
            como via aplicativo móvel.
          </li>
          <li>
            <strong>Serviços</strong>, refere-se ao software, na modalidade SaaS
            (Software as a Service), no qual propicia conexões de dados entre o
            setor <strong>VAREJISTA</strong>e a <strong>INDÚSTRIA</strong>, a
            fim de automatizar e controlar o acesso às informações referentes a
            Ponto de Vendas (PDV’s) e Promotores de Venda, visando maior
            velocidade, segurança e transparência para todos os envolvidos no
            processo de trade marketing.{' '}
          </li>
          <li>
            <strong>Varejistas</strong>, são fornecedores do comércio que
            disponibilizam espaços de PDV’s para a <strong>INDÚSTRIA</strong>{' '}
            vender seus produtos a consumidores finais.
          </li>
          <li>
            <strong>Indústria</strong>, são empresas que fabricam e destinam
            seus produtos por uma extensa malha de pontos de venda. Neste termo
            de uso, considera-se também representantes das{' '}
            <strong>INDÚSTRIAS</strong> como Agências de Promotores{' '}
            <strong>(AGÊNCIAS)</strong>, Distribuidores{' '}
            <strong>(DISTRIBUIDORES)</strong> e Atacados.
          </li>
          <li>
            <strong>Promotor de venda</strong>, é o profissional que presta
            serviços às
            <strong>INDÚSTRIAS, DISTRIBUIDORES</strong> ou{' '}
            <strong>AGÊNCIAS</strong> e que possui acesso ao estabelecimento da
            rede <strong>VAREJISTA</strong> para exercer suas funções, a exemplo
            de, mas não se limitando a: (i) revisão de produtos no estoque do{' '}
            <strong>VAREJISTA</strong>; e (iii) posicionamento de produtos em
            seus espaços nos pontos de venda.
          </li>
          <li>
            <strong>Usuários</strong>, qualquer pessoa física cadastrada como{' '}
            <strong>PROMOTOR DE VENDA</strong>
            que vier a utilizar a plataforma <strong>INVOLVES DOORS</strong> e
            os serviços ali disponíveis, a fim de participar da operação de
            trade marketing entre <strong>VAREJO</strong> e{' '}
            <strong>
              INDÚSTRIA. O Usuário concorda que qualquer eventual vinculação
              e/ou responsabilidade criminal, trabalhista, previdenciária, ou
              outra de qualquer natureza, que vier a reclamar deverá ser
              imputada, exclusivamente, à INDÚSTRIA.
            </strong>
          </li>
        </ul>
      </SectionBlock>
      
      <SectionBlock>
        <p>
          Ao usar a plataforma e os nossos serviços, você concorda, sem limitação ou qualificação, em cumprir estes Termos e a{' '} 
          <a href={POLITICA_PRIVACIDADE_LINK} target="_blank" rel="noreferrer noopener">
            Política de Privacidade
          </a>.
        </p>
        <p>
          <strong>
            Por favor, leia estes Termos cuidadosamente antes de começar a usar
            a nossa plataforma e os nossos serviços, isso porque, ao
            utilizá-los, você aceita estes Termos e concorda em obedecê-los. Se
            você não concorda com estes Termos, você não deve usar nossa
            plataforma e nossos serviços.
          </strong>
        </p>
      </SectionBlock>
      
      <SectionTerm title="01 - Aceitação dos Termos e Condições de Uso">
          <p>
            Estes Termos estabelecem as condições para que o Usuário utilize a
            plataforma e os serviços que pertencem à
            <strong>INVOLVES SOLUÇÕES TECNOLÓGICAS S/A</strong>, empresa
            devidamente inscrita no CNPJ/MF sob o n° 10.903.643/0001-07, situada
            na Av. Patrício Caldeira de Andrade, nº. 1156, Capoeiras,
            Florianópolis/SC, CEP 88.085-150, doravante denominada
            <strong>Involves.</strong>
          </p>
          <p>
            O Usuário fica ciente que para utilizar a plataforma e os serviços
            deve aceitar, expressamente, estes Termos.
            <strong>
              Tal aceite é absolutamente indispensável à utilização da plataforma
              e serviços ofertados pela Involves.
            </strong>
          </p>
          <p>
            O Usuário deverá ler, certificar-se de haver entendido e aceitar todas
            as condições estabelecidas nos Termos antes de finalizar seu cadastro
            como Usuário do Involves Doors. Ao aceitar estes Termos, o Usuário
            concorda e aceita a Política de Privacidade, bem como qualquer outro
            Termo que venha a regulamentar a utilização da plataforma e dos
            serviços.
          </p>
        </SectionTerm>
        
      <SectionTerm title="02 - Inexistência de vínculo com a Involves">
          <p>
            A Involves é uma empresa especializada em soluções de trade marketing
            e entre seus serviços está a comercialização do Software denominado
            “Involves Doors", na modalidade SaaS (Software as a Service). Por essa
            razão, a Involves não é parte de nenhuma negociação realizada através
            da plataforma, servindo apenas como intermediadora que realiza
            conexões inteligentes entre varejo, indústria e promotores de venda.
          </p>
          <p>
          O Usuário é o único responsável pela verificação das informações
          disponibilizadas na plataforma, assim como por quaisquer ações geradas
          através da plataforma.
        </p>
        </SectionTerm>

      <SectionTerm title="03 - Capacidade civil para utilização da plataforma">
        <p>
          Os serviços do Involves Doors estão disponíveis apenas para as pessoas
          físicas que tenham capacidade legal para cadastrar-se. É proibido o
          cadastro de Usuários que não tenham capacidade civil, bem como de
          Usuários que tenham sido suspensos da plataforma, temporária ou
          definitivamente, sem prejuízo da aplicação das sanções legais
          previstas no Código Civil Brasileiro, notadamente, art. 166, I; 171, I
          e 180.
        </p>
      </SectionTerm>
      
      <SectionTerm title="04 - Cadastro e acesso à plataforma">
        <p>
          O Usuário, obrigatoriamente, deve se cadastrar na plataforma, bem como
          aceitar os Termos de Uso da plataforma para utilizar os nossos
          serviços. Apenas será confirmado o cadastro do Usuário que preencher
          todos os campos obrigatórios solicitados pela plataforma, com as
          informações exatas, precisas e verdadeiras. O Usuário garante e
          responde, em qualquer caso, civil e criminalmente pela veracidade,
          exatidão e autenticidade de todos os dados fornecidos na plataforma no
          momento de seu cadastro e em qualquer atualização posterior. O Usuário
          se compromete em manter seus dados atualizados para a devida
          utilização da plataforma e dos serviços.
        </p>
        <p>
          A Involves possui o direito de, a qualquer tempo, a livre e exclusivo
          critério e sem aviso prévio, conferir os dados do Usuário no cadastro
          da plataforma, podendo solicitar dados e/ou documentos adicionais.
          Caso a Involves decida checar a veracidade dos dados cadastrais de um
          Usuário e se constate haver entre eles dados incorretos ou
          inverídicos, poderá recorrer a dados publicamente disponíveis ou a
          bases de dados comerciais para alterar ou enriquecer os dados
          cadastrais, ou, ainda, caso o Usuário se furte ou se negue a enviar os
          documentos requeridos, a Involves poderá suspender temporariamente ou
          definitivamente a conta, sem prejuízo de outras medidas que entender
          necessárias e oportunas. Havendo a aplicação de quaisquer das sanções
          acima referidas, será aberto procedimento de cancelamento do
          perfil/anúncios do respectivo Usuário, não lhe assistindo, por essa
          razão, qualquer indenização ou ressarcimento. A Involves não se
          responsabiliza pela correção dos dados cadastrais inseridos por seus
          Usuários.
        </p>
        <p>
          O Usuário será o único responsável pelas operações efetuadas em sua
          conta, uma vez que o acesso só será possível mediante a inclusão da
          senha, pessoal intransferível, que deverá ser de conhecimento e
          propriedade exclusiva do Usuário. A Involves, nem quaisquer de seus
          empregados ou prepostos solicitará, por qualquer meio, físico ou
          eletrônico, que seja informada sua senha. O Usuário compromete-se a
          notificar a Involves imediatamente, e por meio seguro, a respeito de
          qualquer uso não autorizado de sua conta, bem como seu acesso não
          autorizado por terceiros. A Involves não é responsável por qualquer
          dano ou perda relacionado a qualquer acesso não autorizado ou uso da
          conta do Usuário.
        </p>
        <p>
          Em nenhuma hipótese será permitida a cessão, venda, aluguel ou outra
          forma de transferência da conta. Também não se permitirá a manutenção
          de mais de um cadastro por uma mesma pessoa, ou ainda a criação de
          novos cadastros por pessoas cujos cadastros originais tenham sido
          suspensos temporária ou definitivamente por infrações às políticas da
          Involves.
        </p>
        <p>
          A Involves se reserva o direito de recusar qualquer solicitação de
          cadastro e de suspender um cadastro previamente aceito, que esteja em
          desacordo com as políticas e regras dos presentes Termos e condições
          gerais.
        </p>
      </SectionTerm>
        
      <SectionTerm title="05 – Serviços">
        <p>
          O Usuário fica ciente a respeito do uso e funcionamento da plataforma,
          conforme informações descritas abaixo.
        </p>
        <p>
          <strong>IMPORTANTE: </strong>Por ser uma plataforma de uso intuitivo,
          algumas explicações podem não estar abrangidas nestes Termos, neste
          caso, o Usuário é o responsável por entrar em contato com a Involves e
          esclarecer quaisquer possíveis dúvidas sobre o uso e/ou funcionamento
          da plataforma, ficando acordado que não poderá haver responsabilização
          da Involves por qualquer uso incorreto do Involves Doors.
        </p>
        <p>
          <strong>
            O Usuário está ciente e autoriza que a rede varejista, a indústria e
            a Involves tenham acesso às informações disponibilizadas na
            plataforma, inclusive, podendo consultá-las a qualquer momento,
            assim como utilizá-las para finalidades não previstas nestes Termos,
            a fim de garantir maior segurança na operação. O eventual uso dessas
            informações para as finalidades que não cumpram com a prerrogativa
            prevista nestes Termos será feito mediante autorização prévia do
            Usuário.
          </strong>
        </p>
      </SectionTerm>  

      <SectionTerm title="5.1 Inclusão do Usuário (Promotor) ao Acordo">
        <p>
          A indústria será a responsável por enviar o convite para adicionar o
          Usuário ao Acordo entre indústria e rede varejista. O Usuário está
          ciente que a indústria é a única responsável pela sua indicação e
          contratação, não existindo nenhum tipo de vínculo, seja empregatício,
          previdenciário, tributário, criminal e/ou outro de qualquer natureza,
          com a Involves, com a rede varejista e/ou qualquer terceiro. Por essa
          razão, o Usuário está ciente que utiliza a plataforma e os serviços
          por por sua própria conta e risco.{' '}
        </p>
        <p>
          O Usuário fica ciente que será responsabilidade da indústria incluir e
          excluir o Usuário do Involves Doors. A Involves, em nenhuma hipótese,
          poderá ser responsabilizada: (i) pela aprovação ou não de Acordos que
          envolvam o Usuário; (ii) pelo acesso negado e/ou pendente por parte da
          rede varejista para que o Usuário tenha acesso ao PDV, visto que atua
          apenas como intermediadora, possibilitando conexões inteligentes entre
          o segmento da indústria, varejo e Usuário.{' '}
        </p>
      </SectionTerm>  

      <SectionTerm title="5.2 Aprovação pela Rede Varejista">
        <p>
          <strong>
            O Usuário fica ciente que a rede varejista poderá aceitá-lo ou não
            em sua loja. O Usuário fica ciente que tal aprovação pela rede
            varejista não gera nenhum tipo de vínculo entre Usuário e Varejista.
            A indústria é quem indicará e contratará o Usuário para trabalhar no
            PDV, sendo ele o único e exclusivo responsável por qualquer vínculo
            trabalhista, previdenciário, tributário, criminal, consumerista,
            e/ou outra de qualquer natureza.
          </strong>
        </p>
      </SectionTerm>  

      <SectionTerm title="5.3 Liberação de acesso no PDV">
        <p>
          Através da verificação por QR Code, o Usuário saberá se a rede
          varejista autorizou ou não sua entrada no ponto de venda.
        </p>
      </SectionTerm>  

      <SectionTerm title="5.4 Acessos Liberados e Pendentes">
        <p>
          Através da plataforma, o Usuário poderá acompanhar quais acessos estão
          liberados e/ou pendentes pela rede varejista, permitindo, assim, maior
          velocidade, transparência e segurança na operação.
        </p>
      </SectionTerm>  

      <SectionTerm title="5.5 Atualização de documentos">
        <p>
          O Usuário está ciente que poderá ser solicitado a atualização dos
          seguintes documentos através da plataforma: (i) Nome Completo; (ii)
          E-mail; (iii) CPF; (iv) RG; (v) fotos frente e verso do CPF e RG; (vi)
          foto pessoal.
        </p>
        <p>
          O Usuário concorda que será através do seu CPF que todas as ações
          dentro da plataforma serão concretizadas, por essa razão, autoriza de
          forma expressa o uso dos seus dados sensíveis a Involves, a rede
          varejista e a indústria.
        </p>
      </SectionTerm>  

      <SectionTerm title="5.6 Isenção de pagamento">
        <p>
          O Usuário aceita e está ciente que a plataforma é disponibilizada de
          forma gratuita, todavia, a qualquer momento, a Involves poderá cobrar
          pelos serviços disponibilizados, desde que informe ao Usuário a
          respeito da cobrança com, no mínimo, 30 (trinta) dias de antecedência.
          Nessa hipótese, ocorrendo o inadimplemento dos valores que vierem a
          ser cobrados pela Involves, a seu critério, esta poderá cancelar a
          conta do Usuário. Ainda, a Involves se reserva o direito de tomar as
          medidas judiciais e extrajudiciais pertinentes para receber os valores
          devidos.
        </p>
      </SectionTerm>  

      <SectionTerm title="5.7 Responsabilidade pelo Usuário">
        <p>
          A responsabilidade pelo Usuário é exclusivamente da indústria, por
          essa razão, a Involves recomenda que o Usuário faça a investigação que
          julgar necessária ou apropriada antes de prosseguir com o aceite
          dentro da plataforma.
        </p>
      </SectionTerm>  

      <SectionTerm title="06 - Privacidade da informação e Proteção de Dados">
        <p>
          As Partes aceitam e concordam que observarão o regime legal da
          proteção de dados, empenhando-se em proceder a todo o tratamento de
          dados que venha a mostrar-se necessário ao uso da plataforma no
          estrito e rigoroso cumprimento das legislações aplicáveis.
        </p>
        <p>
          A Involves declara que não utilizará quaisquer dados pessoais a que
          tenha acesso para fins distintos e fora do escopo do presente Termo,
          sendo que o Usuário, por meio deste instrumento, declara que não
          colocará a Involves numa situação de violação de suas obrigações ao
          abrigo das legislações de proteção de dados.
        </p>
        <p>
          Salvo com relação às informações que são publicadas na plataforma, a
          Involves adotará as medidas possíveis para manter a confidencialidade
          e a segurança das informações sigilosas, porém não se responsabilizará
          por eventuais prejuízos que sejam decorrentes da divulgação de tais
          informações por parte de terceiros que utilizem as redes públicas ou a
          internet, subvertendo os sistemas de segurança para acessar as suas
          informações.
        </p>
        <p>
          Ainda, a Involves poderá divulgar dados pessoais e informações
          sigilosas do Usuário e/ou Promotores de Vendas, nos seguintes casos:
          a) por lei; b) por meio de uma ordem ou intimação de um órgão,
          autoridade ou tribunal com poderes para tanto e de jurisdição
          competente; c) para garantir a segurança dos sistemas, resguardar
          direitos e prevenir nossa responsabilidade; (d) adoção de medidas em
          caso de estado de emergência sanitária, por meio de solicitações de
          órgãos, entidades e/ou instituições competentes.
        </p>
        <p>
          O Usuário expressamente autoriza que suas informações e dados pessoais
          sejam compartilhados pela Involves com as demais empresas integrantes
          do grupo econômico, parceiros comerciais, membros do Programa de
          Proteção à Propriedade Intelectual, autoridades e pessoas físicas ou
          jurídicas que alegam ter sido lesadas por Usuários.
        </p>
        <p>
          A Involves, baseada no seu legítimo interesse e desde que tal
          interesse não prevaleça sobre os interesses ou direitos fundamentais
          dos titulares de dados, poderá tratar dados armazenados no seu banco
          de dados, de forma anonimizada e/ou agregada, com a finalidade de, mas
          não se limitando a (i) melhorar o seu desempenho; (ii) aumentar a
          acuracidade dos seus serviços e produtos, de modo a melhorar modelos e
          algoritmos subjacentes ao longo do tempo; (iii) realizar estudos e
          pesquisas internas, com possibilidade de transferi-los a terceiros
          (com exceção de terceiros que concorrem no mesmo segmento da
          Involves); e (iv) integrar dados e informações com outro(s)
          software(s) e/ou módulos contratado(s) por terceiros, por exemplo.
        </p>
        <p>
          O Usuário autoriza que a rede varejista tenha acesso aos seus dados,
          bem como sobre suas visitas no PDV, podendo utilizar tais informações,
          desde que respeitadas as finalidades destes Termos.
        </p>
        <p>
          O Usuário está ciente que ao aceitar estes Termos concorda com nossa
          política de privacidade. Em caso de dúvidas sobre a proteção a dados
          pessoais e informações sigilosas ou para obter maiores informações
          sobre dados pessoais, o Usuário deverá consultar a{' '}
          <strong>POLÍTICA DE PRIVACIDADE.</strong>
        </p>
      </SectionTerm>  
      
      <SectionTerm title="07 - Acesso e disponibilidade da plataforma">
        <p>
          O Usuário é o responsável por garantir que todas as pessoas que
          acessem a plataforma, por meio de sua conexão com a Internet, estejam
          cientes desses termos e que os cumpram.
        </p>
        <p>
          A Involves tem como objetivo manter a plataforma disponível o máximo
          possível, mas de vez em quando a plataforma pode ficar indisponível
          para manutenção ou devido a problemas técnicos. A Involves não será
          responsável pelo Usuário, se, por qualquer motivo, a plataforma ou
          qualquer parte dela estiver indisponível a qualquer momento ou por
          qualquer período e que o Usuário venha a ser prejudicado.
        </p>
        <p>
          A Involves informa que a plataforma pode ser fechada indefinidamente,
          inclusive, ser restrito o acesso a parte ou todo dela. O Usuário fica
          ciente que a qualquer momento a apresentação, configuração e
          disponibilização da plataforma e dos serviços poderão mudar.
        </p>
        <p>
          O Usuário reconhece que é responsável por quaisquer informações falsas
          ou criminosas que possam ser prestadas para a utilização da plataforma
          e dos serviços.
        </p>
      </SectionTerm>

      <SectionTerm title="08 - Propriedade Intelectual e links">
        <p>
          O uso comercial da expressão "Involves Doors" como marca, nome
          empresarial ou nome de domínio; os conteúdos das telas relativas aos
          serviços prestados pela Involves, os programas, look and feel do site,
          bancos de dados, redes, arquivos que permitem ao Usuário acessar e
          usar a sua Conta são propriedade da Involves, e estão protegidos pelas
          leis e tratados internacionais de direito autoral, marcas, patentes,
          modelos e desenhos industriais. O uso indevido e a reprodução total ou
          parcial dos referidos conteúdos são proibidos, salvo a autorização
          prévia e expressa por escrito da Involves.
        </p>
        <p>
          A plataforma pode manter links com outros sites, o que não significa
          que esses sites sejam de propriedade ou operados pela Involves. Não
          possuindo controle sobre esses sites de terceiros, razão pela qual a
          Involves não será responsável pelos seus conteúdos, práticas e
          serviços ofertados. A presença de links para outros sites não implica
          relação de sociedade, de supervisão, de cumplicidade ou solidariedade
          da Involves para com esses sites e seus conteúdos.
        </p>
      </SectionTerm>

      <SectionTerm title="09 - Violação no sistema ou da base de dados">
        <p>
          Não é permitida a utilização de nenhum dispositivo, software ou outro
          recurso que venha a interferir nas atividades e operações do Involves
          Doors. Qualquer intromissão, tentativa ou atividade que viole ou
          contrarie as leis de direito de propriedade intelectual e/ou as
          proibições estipuladas nestes Termos e condições gerais de uso,
          tornarão o responsável passível das ações legais pertinentes, bem como
          das sanções aqui previstas, sendo ainda responsável pelas indenizações
          por eventuais danos causados.
        </p>
      </SectionTerm>

      <SectionTerm title="10 - Isenção e Limitações à Responsabilidade">
        <p>
          Exceto no caso de conduta dolosa comprovada, a Involves não se
          responsabiliza por vícios ou defeitos técnicos e/ou operacionais
          oriundos do sistema do Usuário ou de terceiros.
        </p>
        <p>
          A Involves não possui responsabilidade por qualquer: (a) acordo não
          executado; (b) qualquer dano ocasionado ao Usuário; (c) ao acesso não
          autorizado, uso dos servidores ou de qualquer informação pessoal ou
          dados coletados do Usuário; (d) qualquer interrupção de transmissão
          para ou da plataforma ou serviços; (e) quaisquer bugs, vírus, cavalos
          troianos ou quais podem ser transmitidos no ou por meio da plataforma
          ou serviços por terceiros; ou (f) qualquer perda ou dano de qualquer
          tipo incorrido como resultado do uso da plataforma e serviços.
        </p>
        <p>
          Toda a responsabilidade da Involves agregada em relação às
          reclamações, perdas, danos e custos decorrentes ou relacionados a
          plataforma e aos dados (seja por ato ilícito, incluindo negligência,
          violação de dever contratual ou qualquer outro), em qualquer ano, será
          limitada e NÃO EXCEDERÁ o valor pago/ofertado pelo Usuário pelo
          serviço.
        </p>
        <p>
          O Usuário reconhece que a Involves não é obrigada a monitorar o acesso
          do Usuário (ou a de qualquer outra pessoa) ou o uso da plataforma ou
          serviços, entretanto poderá fazê-lo para operá-los, a fim de garantir
          a conformidade com estes Termos, ou para cumprir a lei aplicável ou a
          exigência de um Tribunal.
        </p>
      </SectionTerm>

      <SectionTerm title="11 - Sanções">
        <p>
          Sem prejuízo de outras medidas cabíveis, a Involves poderá advertir,
          suspender, temporária ou definitivamente, a conta de um Usuário ou
          aplicar uma sanção que impacte negativamente em sua reputação, a
          qualquer tempo, iniciando as ações legais cabíveis e/ou suspendendo a
          prestação de seus serviços se, mas não se limitando: a) o Usuário não
          cumprir qualquer dispositivo destes Termos e condições gerais de uso e
          demais políticas da Involves; b) se descumprir com seus deveres de
          Usuário; c) se praticar atos fraudulentos ou dolosos; d) se não puder
          ser verificada a identidade do Usuário ou se qualquer informação
          fornecida por ele estiver incorreta.
        </p>
      </SectionTerm>

      <SectionTerm title="12 - Indenização">
        <p>
          O Usuário concorda em indenizar e isentar a Involves de
          responsabilidade de e contra todas e quaisquer reclamações, custos,
          procedimentos, demandas, perdas, danos e despesas (incluindo, sem
          limitação, honorários advocatícios razoáveis e custos legais) de
          qualquer tipo ou natureza, decorrentes de, ou relacionadas a, (a)
          qualquer violação destes termos pelo Usuário ou por alguém que use sua
          conta, (b) violação de quaisquer leis ou regulamentos por Usuário ou
          por alguém que use sua conta, ou (c) sua negligência grave ou má
          conduta intencional. Se a Involves assumir a defesa de tal questão, o
          Usuário irá cooperar razoavelmente conosco em tal defesa.
        </p>
      </SectionTerm>

      <SectionTerm title="13 - Alcance dos serviços">
        <p>
          Estes Termos não geram nenhum contrato de sociedade, mandato, franquia
          ou relação de trabalho entre Involves, o Usuário, indústria e/ou
          qualquer terceiro. O Usuário manifesta ciência de que a Involves não é
          parte de nenhuma negociação realizada através da plataforma.
        </p>
      </SectionTerm>

      <SectionTerm title="14 - Outras provisões">
        <p>
          Se qualquer provisão destes Termos for considerada ilegal ou
          inexequível, tal provisão será considerada separável destes Termos e
          não afetará a aplicabilidade de quaisquer outras provisões.
        </p>
        <p>
          O não cumprimento pela Involves de qualquer direito ou disposição
          destes Termos não impede de valer tal direito ou disposição no futuro.
          A Involves poderá ceder os direitos e obrigações sob estes Termos,
          inclusive em relação a uma fusão, aquisição, venda de ativos ou
          patrimônio, ou por força de lei.
        </p>
      </SectionTerm>

      <SectionTerm title="15 - Alterações nestes Termos">
        <p>
          A Involves se compromete a comunicar ao Usuário sempre que estes
          Termos forem atualizados, todavia, o Usuário fica ciente que essas
          alterações entrarão em vigor na data de revisão mostrada nos Termos
          revisados. Ao continuar a usar a plataforma e os serviços, o Usuário
          concorda com os Termos revisados.
        </p>
      </SectionTerm>

      <SectionTerm title="16 - Cancelamento da conta">
        <p>
          O Usuário pode cancelar sua conta na plataforma a qualquer momento,
          através do portal de ajuda help.involves.com ou pelo telefone (48)
          3733.0500. A Involves se reserva o direito de cancelar o acesso do
          Usuário à plataforma e aos serviços a qualquer momento.
        </p>
      </SectionTerm>

      <SectionTerm title="17 - Legislação aplicável e Foro de eleição">
        <p>
          Todos os itens destes Termos e condições gerais de uso são regidos
          pelas leis vigentes na República Federativa do Brasil. Para todos os
          assuntos referentes à interpretação, ao cumprimento ou qualquer outro
          questionamento relacionado a estes Termos e condições gerais de uso,
          as partes concordam em se submeter ao Foro da Cidade de
          Florianópolis-SC, com exceção de reclamações apresentadas por Usuários
          que se enquadrem no conceito legal de consumidores, que poderão
          submeter tais reclamações ao foro de seu domicílio.
        </p>
      </SectionTerm>
        
      <SectionTerm title="18 - Fale Conosco">
        <p>
          Se você tiver alguma dúvida sobre nossos serviços ou estes Termos,
          entre em contato por meio do nosso portal de ajuda{' '}
          <a href={HELP_INVOLVES_LINK} target="_blank" rel="noreferrer noopener">
            help.involves.com
          </a>{' '}
          ou pelo telefone <strong>(48) 3733.0500</strong>.
          {' '}<strong>Obrigado por ler estes Termos.</strong>
        </p>
      </SectionTerm>
    </PromoterTermsLayout>
  );
}
