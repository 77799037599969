import {
  getRetailAPI,
  getRetailByIdAPI,
  deleteRetailAPI,
  postRetailAPI,
  putRetailAPI,
} from './retailsAPI';
import { valueStatus } from '../../profiles';

export const getActiveRetails = async () => {
  return await getRetailAPI(valueStatus.ACTIVE);
};


export const getRetailById = id => {
  return getRetailByIdAPI(id);
};

export const deleteRetail = retail => {
  return deleteRetailAPI(retail);
};

export const getAllRetails = (params) => {
  const offset = params?.offset ?? 0;
  const limit = params?.limit ?? 99999;
  const order = params?.order ?? 1;
  const orderBy = params?.orderBy ?? '';
  
  return getRetailAPI(null, offset, limit, order, orderBy);
};

export const postRetail = data => {
  return postRetailAPI(data);
};

export const putRetail = data => {
  return putRetailAPI(data);
};
