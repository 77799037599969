import { PromotionalNegotiationExecutionStatusEnum } from '../../../pages/promotional-spots/NegotiationsPanel/types/promotional-negotiation-status-enum';

type PromotionalNegotiationExecutionUserQueryDto = {
  name: string;
  id: string;
};

export enum PromotionalNegotiationStatusLabel {
  INACTIVE = 'Inativo',
  PENDING = 'Pendente',
  SOLVED = 'Executado',
}
export type PromotionalNegotiationExecutionDto = {
  id: string;
  negotiationId: string;
  status: PromotionalNegotiationExecutionStatusEnum;
  image?: string;
  problems?: PromotionalNegotiationExecutionProblemDto[];
  buyerUser: PromotionalNegotiationExecutionUserQueryDto;
  solvedUser?: PromotionalNegotiationExecutionUserQueryDto;
  solvedAt?: Date | string;
  addressing: PromotionalNegotiationExecutionAddressingQueryDto;
};

type PromotionalNegotiationExecutionProblemDto = {
  id: string;
  executionId: string;
  type: string;
  status: number;
  solvedAt?: Date;
  updatedAt: Date;
  createdAt: Date;
  reportedBy: {
    name: string;
    id: string;
  };
  products?: PromotionalNegotiationExecutionProblemProductDto[];
};
type PromotionalNegotiationExecutionProblemProductDto = {
  name: string;
  ean: string;
};

type PromotionalNegotiationExecutionAddressingQueryDto = {
  id: string;
  status: number;
  physicalAddressing: string;
  spot: {
    id: string;
    name: string;
    type: string;
  };
  store: {
    id: string;
    name: string;
  };
};
