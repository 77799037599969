import React from 'react';
import { DefaultButton } from '../../../components/Buttons';
import StickyFooter from '../../../components/stickyFooter/stickyFooter';
import { REGISTER_SUPPLIER } from '../../../core/constants/dictionary';
import classes from '../Supplier.module.scss';

type Props = {
  onClick: Function;
}

const Footer = ({ onClick }: Props) => {
  return (
    <StickyFooter actionButton={
      <div className={classes.footer}>
        <DefaultButton
          label={REGISTER_SUPPLIER}
          onClick={onClick}
          style={{ width: 'auto' }}
        />
      </div>
      }
    />
  );
}

export default Footer;
