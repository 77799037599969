import React from 'react';
import BaseModal from '../BaseModal';
import { EDIT_SUPPLIER } from '../../../../../core/constants/dictionary';
import { RetailEntityType } from '../../../../../core/types/retail/retail-entity.type';

type Props = {
  data: any;
  isOpen: boolean;
  retails: RetailEntityType[],
  onClose: Function;
  onSubmit: Function;
};

const UpdateSupplierModal = ({ data, isOpen, retails, onClose, onSubmit }: Props) => {
  return (
    <BaseModal 
      isEdit
      isOpen={isOpen}
      data={data}
      retails={retails}
      title={EDIT_SUPPLIER}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

export default UpdateSupplierModal;
