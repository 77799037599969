import React from 'react';
import { StyledLabel, StyledStar, StyledHiddenInput } from './style';

const StarRate = ({ onChange, selected, disabled, ...props }) => {
  return (
    <StyledLabel disabled={disabled}>
      <StyledStar
        className="material-icons"
        selected={selected}
        disabled={disabled}
      >
        {selected ? 'star' : 'star_border'}
      </StyledStar>
      <StyledHiddenInput
        type="checkbox"
        onChange={onChange}
        checked={selected}
        disabled={disabled}
        {...props}
      />
    </StyledLabel>
  );
};

export { StarRate };
