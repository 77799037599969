import React from 'react';
import { CircularProgress, Backdrop } from '@material-ui/core';

const Loading = ({ isOpen }) => (
	<Backdrop
		open={isOpen}
		style={{ zIndex: '9999', color: '#fff' }}
	>
		<CircularProgress color="inherit" />
	</Backdrop>
);
	
export default Loading;
