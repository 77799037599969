import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import { UserProfile } from '../../core/types/user-profile.enum';

type FormikValues = {
  cpf: string;
  promoterUser: {
    id: string;
    name: string;
  }
}

export const intialFormValues: FormikValues = {
  cpf: '',
  promoterUser: {
    id: '',
    name: '',
  },
};

type UserEntity = {
  id: string;
  rg: string;
  cpf: string;
  name: string;
  email: string;
  phone: string;
  createdAt: Date;
  updatedAt: Date;
  status: number;
  version: number;
  profile: UserProfile;
}

type GetPromoterByIdOrCpfResponse = {
  exists: boolean;
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  status?: number;
  version?: number,
  user?: UserEntity;
}

export const MODAL_MAX_WIDTH = 800;

export const getValidCpfSubstring = (cpf: string) => {
  return cpf.split('_')[0];
}

export const isPromoterCpfRegistered = (fetchedPromoter: GetPromoterByIdOrCpfResponse) => {
  return fetchedPromoter && fetchedPromoter.exists;
}

export const isCpfValid = (cpf: string) => {
  return (cpf.length === 14) && (cpfValidator.isValid(cpf));
}

export const isCpfComplete = (cpf: string) => {
  return cpf && cpf.length === 14;
}
