import { useCallback, useState } from 'react';
import { SnackBarType, defautValueSnackBar } from '../context/Snackbars';

export const useSnackBarState = () => {
  const [open, setOpen] = useState<boolean>(defautValueSnackBar.open);
  const [type, setType] = useState<SnackBarType | undefined>(
    defautValueSnackBar.type
  );
  const [message, setMessage] = useState<string | undefined>(
    defautValueSnackBar.message
  );
  const [action, setAction] = useState<Function>(defautValueSnackBar.action);
  const [messageAction, setMessageAction] = useState<string | undefined>(
    defautValueSnackBar.messageAction
  );

  const closeSnackBar = useCallback(() => {
    setOpen(defautValueSnackBar.open);
    setType(defautValueSnackBar.type);
    setMessage(defautValueSnackBar.message);
    setAction(defautValueSnackBar.action);
    setMessageAction(defautValueSnackBar.messageAction);
  }, []);

  const openSnackBar = useCallback(
    (
      type: SnackBarType,
      message: string,
      messageAction?: string,
      action?: Function
    ) => {

      setOpen(true);
      setType(type);
      setMessage(message);
      if (action) {
        setAction(action);
      }
      if (messageAction) {
        setMessageAction(messageAction);
      }
    },
    []
  );

  return {
    snackBar: {
      open,
      type,
      message,
      action,
      messageAction,
    },
    closeSnackBar,
    openSnackBar,
  };
};
