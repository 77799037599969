import React, { MouseEventHandler } from 'react';
import { TableCell, Tooltip } from '@material-ui/core';
import {
  Add,
  LockOutlined,
  Delete,
  Remove,
  LockOpen,
} from '@material-ui/icons';
import * as dictionary from '../../../../../../core/constants/dictionary';
import classes from '../NegotiationTable.module.scss';

type Props = {
  disabled: boolean;
  isLocked?: boolean;
  onAdd: MouseEventHandler;
  onRemove: MouseEventHandler;
  onDelete: MouseEventHandler;
  onToggleLock: MouseEventHandler;
};

const ActionButtons = ({
  disabled = false,
  isLocked = false,
  onAdd,
  onRemove,
  onDelete,
  onToggleLock,
}: Props) => {
  const color = disabled ? 'disabled' : 'inherit';

  const onActionDisabled = (action: {
    (event: React.MouseEvent<Element, MouseEvent>): void;
    (event: React.MouseEvent<Element, MouseEvent>): void;
    (event: React.MouseEvent<Element, MouseEvent>): void;
    (event: React.MouseEvent<Element, MouseEvent>): void;
    (event: React.MouseEvent<Element, MouseEvent>): void;
  }) => (disabled ? () => {} : action);

  return (
    <TableCell className={classes.actionContainer}>
      <div className={classes.actionColumn}>
        <Tooltip
          title={dictionary.ADD_ONE_AT_EACH_STORE}
          disableTouchListener={disabled}
        >
          <Add
            color={color}
            fontSize="small"
            className={classes.actionIcon}
            onClick={onActionDisabled(onAdd)}
          />
        </Tooltip>
        <Tooltip
          disableTouchListener={disabled}
          title={dictionary.CLEAR_ALL_SPOTS}
        >
          <Remove
            color={color}
            fontSize="small"
            className={classes.actionIcon}
            onClick={onActionDisabled(onRemove)}
          />
        </Tooltip>
        <Tooltip
          disableTouchListener={disabled}
          title={
            isLocked
              ? dictionary.UNBLOCK_NEGOTIATIONS
              : dictionary.BLOCK_NEGOTIATIONS
          }
        >
          {isLocked ? (
            <LockOutlined
              color={color}
              fontSize="small"
              className={classes.actionIcon}
              onClick={onActionDisabled(onToggleLock)}
            />
          ) : (
            <LockOpen
              color={color}
              fontSize="small"
              className={classes.actionIcon}
              onClick={onActionDisabled(onToggleLock)}
            />
          )}
        </Tooltip>
        <Tooltip title={dictionary.TO_DELETE} disableTouchListener={disabled}>
          <Delete
            color={color}
            fontSize="small"
            className={classes.actionIcon}
            onClick={onActionDisabled(onDelete)}
          />
        </Tooltip>
      </div>
    </TableCell>
  );
};

export default ActionButtons;
