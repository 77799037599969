import React from 'react';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import FilterTable from '../../../../components/Tables/FilterTable';
import { EmptyDataMessageRow } from '../../../../components/Tables/DefaultTable';
import { colors } from '../../../../Theme/colors';
import * as dictionary from '../../../../core/constants/dictionary';

export const RetailTable = ({ data, handleOpenModal }) => {
  return (
    <FilterTable
      columns={[
        { title: dictionary.NAME, field: 'name' },
        { title: dictionary.CNPJ, field: 'cnpj' },
        {
          itle: dictionary.STATUS,
          field: 'status',
          cellStyle: rowData =>
            rowData === dictionary.INACTIVE
              ? { color: colors.red_600 }
              : { color: colors.green_600 },
          lookup: { 0: dictionary.INACTIVE, 1: dictionary.ACTIVE },
          defaultFilter: ['1'],
        },
      ]}
      data={data}
      actions={[
        {
          title: '',
          icon: () => <Edit style={{ width: '16px' }} />,
          tooltip: dictionary.EDIT_RETAIL,
          onClick: (event, rowData) => {
            handleOpenModal(dictionary.EDIT_RETAIL, rowData);
          },
        },
        rowData => ({
          icon: () => (
            <Delete
              style={{ width: '16px' }}
              color={rowData.status !== 0 ? 'inherit' : 'disabled'}
            />
          ),
          tooltip: dictionary.REMOVE_RETAIL,
          onClick: (event, rowData) => {
            if (rowData.status !== 0) {
              handleOpenModal(dictionary.REMOVE_RETAIL, rowData);
            }
            return null;
          },
        }),
      ]}
      localization={{
        body: {
          emptyDataSourceMessage: (
            <EmptyDataMessageRow 
              message={dictionary.EMPTY_RETAIL_TABLE}
            />
          ),
        },
      }}
    />
  );
};
