import API from '../api/config';
import * as routes from '../api/routes';

export const createResetPasswordTokenAPI = async ({ email }) => {
  const url = routes.API_PASSWORD_RESET;
  const data = { email };
  return API.post(url, data);
};

export const resetPasswordAPI = async ({ passwordResetToken, newPassword }) => {
  const url = `${routes.API_PASSWORD_RESET}/${passwordResetToken}`;
  const data = { newPassword };
  return API.put(url, data);
};

export const getPasswordResetTokenInfoAPI = async passwordResetToken => {
  const url = `${routes.API_PASSWORD_RESET}/${passwordResetToken}`;
  return API.get(url);
};
