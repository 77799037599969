export enum StoreArea {
  ENTRY_HALL = 'ENTRY_HALL',
  FRONT_HALL = 'FRONT_HALL',
  CENTRAL_HALL = 'CENTRAL_HALL',
  REAR_HALL = 'REAR_HALL',
  BUTCHER_SHOP = 'BUTCHER_SHOP',
  PRODUCE = 'PRODUCE',
  CELLAR = 'CELLAR',
  ROTISSERIA = 'ROTISSERIA',
  BAKERY = 'BAKERY',
  CHECKOUT = 'CHECKOUT',
  BAZAAR = 'BAZAAR',
  ELECTRO = 'ELECTRO',
  FLOWER_SHOP = 'FLOWER_SHOP',
};
