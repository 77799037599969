import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { paperStyle } from '../utils';
import { title as titleStyle } from '../../../styles';
import * as styles from './style';

type Props = {
	title: string; 
	value: number;
	percentage?: number | null; 
	description?: string;
}
const OverviewCard = ({ title, value, percentage, description }: Props) => {
	return (
		<Paper style={paperStyle}>
			<Grid container direction="row" style={styles.card}>
				<Grid container item
					alignItems="center"
					justifyContent="flex-start"
					style={styles.gridTitle}
				>
					<Typography variant="subtitle2" style={titleStyle}>
						{title}
					</Typography>
				</Grid>
				<Grid container item direction='row' alignItems="flex-end">
					<Grid item>
						<Typography align="left" style={styles.value}>
							{value}
						</Typography>
					</Grid>
					<Grid item>
						{
							(Number(percentage) > 0) &&
							<Typography variant="h6" align="right" style={styles.percentage}>
								({percentage}%)
							</Typography>
						}
					</Grid>
				</Grid>
				<Grid container item justifyContent="flex-start" style={styles.gridDescription}>
					{
						(description) &&
						<Typography variant="overline" style={styles.description}>
							{description}
						</Typography>
					}
				</Grid>
			</Grid>
		</Paper>
	);
}

export default OverviewCard;
