import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Button, Collapse, Grid } from '@material-ui/core';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import { TextField as MUITextField } from '@material-ui/core';
import { view } from 'react-easy-state';
import Loading from '../../../components/Loading';
import TextField from '../../../components/InputTextField';
import PageContent from '../../../components/PageContent';
import StickyFooter from '../../../components/stickyFooter/stickyFooter';
import ModalSmallContainer from '../../../containers/Modal/SmallContainer';
import { CancelButton, DefaultButton } from '../../../components/Buttons';
import { utils } from '../../../core/utils';
import { mainStore } from '../../../core/store/mainStore';
import { useDebounce } from '../../../core/hooks';
import { profileCheck } from '../../../core/profiles';
import { AddressingDto } from '../../../core/types/promotional-spot/promotional-addressing.dto';
import { useRetailList } from '../../../hooks/RetailHooks';
import { FormRetailField } from '../../../components/Form/FormRetailField';
import { RetailEntityType } from '../../../core/types/retail/retail-entity.type';
import { globalTypeOptions } from '../common/globalTypeOptions';
import { getStoresByRetail } from '../../../core/services/stores/storesService';
import { PromotionalSpotDto } from '../../../core/types/promotional-spot/promotional-spot.dto';
import { getPromotionalSpots } from '../../../core/services/promotionalSpots/promotionalSpotsServices';
import {
  getAddressings,
  createAddressing,
  removeAddressing,
  updateAddressing,
} from '../../../core/services/promotionalSpots/addressingsServices';
import * as dictionary from '../../../core/constants/dictionary';
import { translatePromotionalSpotTypeToAPI } from '../common/utils';
import { gridSize } from './utils';
import {
  AddressingsTable,
  CreateAddressingPage,
  DeleteAddressingModal,
  CreateAddressingModal,
} from './components';
import { FetchDataParams, FormFilters, ModalType, StoreDto } from './types';
import { styles } from './components/styles';
import classes from './Addressings.module.scss';

const Addressing = () => {
  const [filters, setFilters] = useState<FormFilters>();
  const [modalType, setModalType] = useState<ModalType | null>(null);
  const [addressings, setAddressings] = useState<AddressingDto[]>([]);
  const [storeOptions, setStoreOptions] = useState<StoreDto[]>([]);
  const [selectedRetail, setSelectedRetail] = useState<RetailEntityType>();
  const [currentAddressing, setCurrentAddressing] = useState<AddressingDto>();
  const [promotionalSpots, setPromotionalSpots] = useState<PromotionalSpotDto[]>([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [toolbarInputFocus, setToolbarInputFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasInitialContent, setHasInitialContent] = useState(false);
  const {
    retailInUser,
    fetch: fetchRetails,
    retails: retailsOptions,
    isLoading: isLoadingRetails,
  } = useRetailList();
  const isSuperAdmin = profileCheck.isSuperAdmin();
  const snackBar = utils.getSnackbar();

  const currentRetailId = useMemo(
    () => retailInUser || selectedRetail?.id,
    [selectedRetail?.id, retailInUser]
  );

  const fetchInitialData = async (retailId?: string) => {
    setIsLoading(true);
    try {
      const storesArray = await getStoresByRetail(retailId);
      setStoreOptions(storesArray);

      const { data } = await getPromotionalSpots({ name: '' });
      setPromotionalSpots(data.items);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const fetchData = useCallback(
    async ({
      filters,
      page = 0,
      rowsPerPage = 10,
      physicalAddressing = searchText,
    }) => {
      setIsLoading(true);
      
      try {
        const limit = rowsPerPage;
        const offset = page * rowsPerPage;
        let requestParams: FetchDataParams = {
          limit,
          offset,
          physicalAddressing,
          retailId: currentRetailId,
        };
        if (filters?.store) {
          requestParams.storeId = filters.store.id;
        }
        if (filters?.type) {
          requestParams.spotType = translatePromotionalSpotTypeToAPI(
            filters.type
          );
        }
        if (filters?.retailId) {
          requestParams.retailId = currentRetailId;
        }
        if (filters?.spotName) {
          requestParams.spotName = filters?.spotName;
        }
        
        const { data } = await getAddressings(requestParams);
        setTotal(data.total);
        setAddressings(data.items);
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    },
    [
      page, 
      filters, 
      isLoading, 
      searchText,
      rowsPerPage, 
      currentRetailId,
    ]
  );

  const onDeleteHandler = useCallback(() => {
    (async () => {
      try {
        if (currentAddressing?.id) {
          await removeAddressing(currentAddressing.id);
          setIsModalOpen(false);
          utils.openSnackBar(
            dictionary.SUCCESS,
            dictionary.DELETE_PROMOTIONAL_SPOT_SUCCESS
          );
          fetchData({
            page,
            filters,
            searchText,
            rowsPerPage,
          });
        }
      } catch (e) {
        utils.openSnackBar(
          dictionary.ERROR,
          dictionary.DELETE_PROMOTIONAL_SPOT_ERROR
        );
      }
    })();
  }, [
    page, 
    filters, 
    searchText,
    rowsPerPage, 
    currentAddressing, 
  ]);

  const onCloseModalHandler = useCallback(() => {
    setIsModalOpen(false);
    setModalType(null);
  }, []);

  const createModal = useMemo(() => {
    return (
      <ModalSmallContainer
        isOpen={isModalOpen}
        closeModalCallback={onCloseModalHandler}
      >
        <CreateAddressingModal
          onSubmitAction={createAddressing}
          stores={storeOptions}
          promotionalSpots={promotionalSpots}
          onClose={onCloseModalHandler}
          onAfterSubmit={() => {
            onCloseModalHandler();
            fetchData({
              page,
              filters,
              searchText,
              rowsPerPage,
            });
          }}
        />
      </ModalSmallContainer>
    );
  }, [
    page,
    filters,
    searchText,
    rowsPerPage,
    isModalOpen,
    storeOptions,
    promotionalSpots,
    onCloseModalHandler,
  ]);

  const editModal = useMemo(() => {
    return (
      currentAddressing && (
        <ModalSmallContainer
          isOpen={isModalOpen}
          closeModalCallback={onCloseModalHandler}
        >
          <CreateAddressingModal
            title={dictionary.EDIT_ADDRESSING}
            onSubmitAction={updateAddressing}
            stores={storeOptions}
            currentStore={storeOptions.find(
              (store) => store.id === currentAddressing.store.id
            )}
            promotionalSpots={promotionalSpots}
            currentAddressing={currentAddressing}
            onClose={onCloseModalHandler}
            onAfterSubmit={() => {
              fetchData({
                filters,
                rowsPerPage,
                page,
                searchText,
              });
              onCloseModalHandler();
            }}
          />
        </ModalSmallContainer>
      )
    );
  }, [
    isModalOpen,
    currentAddressing,
    promotionalSpots,
    storeOptions,
    onCloseModalHandler,
    filters,
    rowsPerPage,
    page,
    searchText,
  ]);

  const deleteModal = useMemo(() => {
    return (
      <ModalSmallContainer
        isOpen={isModalOpen}
        closeModalCallback={onCloseModalHandler}
      >
        <DeleteAddressingModal
          onClose={onCloseModalHandler}
          onDelete={onDeleteHandler}
        />
      </ModalSmallContainer>
    );
  }, [isModalOpen, onCloseModalHandler, onDeleteHandler]);

  const renderModal = useMemo(() => {
    const modals = {
      [ModalType.EDIT]: editModal,
      [ModalType.CREATE]: createModal,
      [ModalType.DELETE]: deleteModal,
    };
    return modalType && modals[modalType];
  }, [modalType, deleteModal, createModal, editModal]);

  const enableEdit = useMemo(
    () =>
      profileCheck.isSuperAdmin() ||
      profileCheck.isRetailAdmin() ||
      profileCheck.isStoreManager(),
    [profileCheck]
  );

  const onOpenModalHandler = useCallback((modalType, currentAddressing) => {
    setModalType(modalType);
    setIsModalOpen(true);
    setCurrentAddressing(currentAddressing);
  }, []);

  const debouncedOnSearch = useDebounce(async (value: string[]) => {
    const inputValue = String(value[0]);
    setPage(0);
    setSearchText(inputValue);
    setToolbarInputFocus(true);
  }, 500);

  useEffect(() => {
    if (addressings.length > 0 && !hasInitialContent) {
      setHasInitialContent(true);
    }
  }, [addressings, hasInitialContent]);

  useEffect(() => {
    fetchData({
      page,
      filters,
      searchText,
      rowsPerPage,
    });
  }, [rowsPerPage, page, searchText]);

  useEffect(() => {
    fetchInitialData(mainStore?.loggedUser?.retailId ?? undefined);
  }, []);

  useEffect(() => {
    if (isSuperAdmin && !retailInUser) {
      fetchRetails();
    }
  }, [isSuperAdmin, retailInUser]);

  useEffect(() => {
    (async function () {
      const stores = await getStoresByRetail(currentRetailId);
      setStoreOptions(stores);
    })();
  }, [currentRetailId]);

  return (
    <>
      <PageContent title={dictionary.ADDRESSINGS}>
      {(addressings.length === 0 && !hasInitialContent) ? (
        <CreateAddressingPage 
          onClickButtonHandler={() => onOpenModalHandler(ModalType.CREATE, currentAddressing)}
        />
      ) : (
        <>
          <div className={classes.form}>
            <Button
              disableElevation
              size="large"
              startIcon={<FilterListIcon />}
              onClick={() => setIsFormOpen((prevState) => !prevState)}
            >
              {dictionary.FILTERS}
            </Button>
            <Collapse in={isFormOpen} className={classes.collapse}>
              <Grid container spacing={2}>
                {isSuperAdmin && !retailInUser && (
                  <Grid item md={gridSize} sm={6}>
                    <FormRetailField
                      value={selectedRetail}
                      disabled={isLoadingRetails}
                      options={retailsOptions ?? []}
                      onChange={setSelectedRetail}
                    />
                  </Grid>
                )}
                {!profileCheck.isStoreManager() && (
                  <Grid item md={gridSize} sm={6}>
                    <Autocomplete
                      id="store"
                      size="small"
                      key={filters?.store?.id}
                      value={filters?.store}
                      options={storeOptions}
                      getOptionLabel={(option: StoreDto) => option.name}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <MUITextField
                          {...params}
                          variant="outlined"
                          label={dictionary.STORE}
                        />
                      )}
                      onChange={(_e: ChangeEvent<{}>, value: StoreDto | null) =>
                        setFilters({
                          ...filters,
                          store: value,
                        })
                      }
                    />
                  </Grid>
                )}
                <Grid item md={gridSize} sm={6}>
                  <Autocomplete
                    size="small"
                    key={filters?.type}
                    id="promotionalSpotType"
                    value={filters?.type ?? ''}
                    options={globalTypeOptions}
                    getOptionLabel={(option: string) => option}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <MUITextField
                        {...params}
                        variant="outlined"
                        label={dictionary.PROMOTIONAL_SPOT_TYPE}
                      />
                    )}
                    onChange={(_e: ChangeEvent<{}>, value: string | null) =>
                      setFilters({
                        ...filters,
                        type: value,
                      })
                    }
                  />
                </Grid>
                <Grid item md={gridSize} sm={6}>
                  <TextField
                    id="promotionalSpotName"
                    required={false}
                    value={filters?.spotName ?? ''}
                    label={dictionary.PROMOTIONAL_SPOT_NAME}
                    onChange={(_e: any) => {
                      setToolbarInputFocus(false);
                      setFilters({
                        ...filters,
                        spotName: _e.target.value,
                      });
                    }}
                    style={{ marginTop: 0}}
                    className={classes.textfield}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.buttons}>
                <Grid item>
                  <DefaultButton
                    label={dictionary.SEARCH}
                    style={styles.defaultButtonStyle}
                    onClick={() => {
                      fetchData({ 
                        page, 
                        filters, 
                        searchText,
                        rowsPerPage, 
                      });
                    }}
                  />
                </Grid>
                <Grid item>
                  <CancelButton
                    label={dictionary.CLEAR}
                    onClick={() => {
                      setFilters({});
                      setTimeout(() => {
                        fetchData({});
                      }, 500);
                    }}
                  />
                </Grid>
              </Grid>  
            </Collapse>
          </div>
          
          <AddressingsTable
            page={page}
            total={total}
            data={addressings}
            rowsPerPage={rowsPerPage}
            searchTextValue={searchText}
            toolbarInputFocus={toolbarInputFocus}
            handleOpenModal={onOpenModalHandler}
            onChangePageHandler={(page: number) => setPage(page)}
            onChangeSearchHandler={(value: string[]) => {
              debouncedOnSearch(value);
            }}
            showEdit={enableEdit}
            onChangeRowsPerPageHandler={(rowsPerPage: number) => {
              setPage(0);
              setRowsPerPage(rowsPerPage);
              setToolbarInputFocus(false);
            }}
          />

          {enableEdit && (
            <StickyFooter
              actionButton={
                <div className="button-create-container">
                  <DefaultButton
                    label={dictionary.NEW_ADDRESS}
                    onClick={() => {
                      onOpenModalHandler(ModalType.CREATE, currentAddressing);
                    }}
                    style={{ width: 'auto' }}
                  />
                </div>
              }
            />
          )}
        </>

      )}
        {snackBar}
        {isModalOpen && renderModal}
        <Loading isOpen={isLoading} />
      </PageContent>
    </>
  );
};

export default view(Addressing);
