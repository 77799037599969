import React, { useMemo } from 'react';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import FilterTable from '../../../components/Tables/FilterTable';
import { EmptyDataMessageRow } from '../../../components/Tables/DefaultTable';
import { colors } from '../../../Theme/colors';
import { profileCheck } from '../../../core/profiles';
import * as dictionary from '../../../core/constants/dictionary';
import PatchedPagination from '../../../components/PatchedPagination';

export const StoreTable = ({ data, handleOpenModal }) => {
  const columns = useMemo(() => {
    const columns = [
      { title: dictionary.NUMBER, field: 'storeCode' },
      { title: dictionary.NAME, field: 'name' },
      { title: dictionary.NEIGHBORHOOD, field: 'neighborhood' },
      { title: dictionary.CITY, field: 'city' },
      { title: dictionary.STATE, field: 'state' },
      {
        title: dictionary.STATUS,
        field: 'status',
        cellStyle: rowData =>
          rowData === 'Inativo'
            ? { color: colors.red_600 }
            : { color: colors.green_600 },
        lookup: { 0: dictionary.INACTIVE, 1: dictionary.ACTIVE },
        defaultFilter: ['1'],
      },
    ];

    if (profileCheck.isSuperAdmin()) {
      columns.unshift({ title: dictionary.RETAIL, field: 'retailName' });
    }

    return columns;
  }, []);
  return (
    <FilterTable
      columns={columns}
      data={data}
      actions={[
        {
          title: '',
          icon: () => <Edit style={{ width: '16px' }} />,
          tooltip: dictionary.EDIT_STORE,
          onClick: (event, rowData) => {
            handleOpenModal(dictionary.EDIT_STORE, rowData);
          },
        },
        rowData => ({
          icon: () => (
            <Delete
              style={{ width: '16px' }}
              color={rowData.status !== 0 ? 'inherit' : 'disabled'}
            />
          ),
          tooltip: dictionary.REMOVE_STORE,
          onClick: (event, rowData) => {
            if (rowData.status !== 0) {
              handleOpenModal(dictionary.DELETE_STORE, rowData);
            }
            return null;
          },
        }),
      ]}
      localization={{
        body: {
          emptyDataSourceMessage: (
            <EmptyDataMessageRow 
              message={dictionary.EMPTY_STORE_TABLE}
            />
          ),
        },
      }}
      components={{
        Pagination: (props) => {
          return (
            <PatchedPagination {...props} />
          );
        },
      }}
    />
  );
};
