import { StyleSheet } from '../../../core/types/common/stylesheet.type';
import { colors } from '../../../Theme/colors';

export const styles: StyleSheet = {
  datepickerContainer: {
    margin: '0 -32px 8px',
    padding: '0 32px 18px',
    backgroundColor: '#FAFBFC',
  },
  inputContainer: {
    marginBottom: '16px',
  },
  datepickerGrid: {
    display: 'flex',
    gap: '32px',
    alignItems: 'flex-start',
  },
  text: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.black_500,
    paddingTop: '18px',
    paddingBottom: '18px',
  },
  paper: {
    width: '942px',
    //margin: 'auto auto -60px',
    margin: 'auto',
    padding: '7px 32px',
  },
  input: {
    width: '100%',
  },
  button: {
    width: 'auto',
  },
  buttonContainer: {
    marginTop: '22px',
  },
};
