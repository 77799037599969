import { valid } from './validations';

export const validator = arrayOfValidations => {
  return event => {
    let validatorObject = valid;
    arrayOfValidations
      .filter(v => !!v)
      .every(validation => {
        const errorObject = validation(event);
        if (errorObject.hasError) {
          validatorObject = errorObject;
          return false;
        }
        return true;
      });
    return validatorObject;
  };
};
