import { ActiveStatus } from "../../core/constants/active-status";

export enum ModalType {
  CREATE,
  UPDATE,
  DELETE,
}

export type SupplierForm = {
  id: string;
  name: string;
  cnpj: string;
  status: ActiveStatus;
  retailId: string;
  internalCode: string;
  internalName: string;
}

export type SubmitSupplierForm = SupplierForm & {
  isEdit: boolean;
  active?: boolean;
}

export const defaultFormValues: SupplierForm = {
  id: '',
  name: '',
  cnpj: '',
  retailId: '',
  internalCode: '',
  internalName: '',
  status: ActiveStatus.ACTIVE,
};
