import React from 'react';
import { Typography, Chip } from '@material-ui/core';
import { mainStore } from '../../core/store/mainStore';
import * as dictionary from '../../core/constants/dictionary';
import {
  getAgreement,
  postAgreement,
  putAgreement,
  putVisits,
  deleteAgreement,
} from '../../core/services/agreements/agreementsService';
import {
  sendInvite,
  resendInviteById,
} from '../../core/services/invites/invitesService';
import { getAllRetails } from '../../core/services/retails/retailsService';
import { getSuppliersByAgreement } from '../../core/services/users/usersService';
import { exist } from '../../core/validation';
import { profileCheck } from '../../core/profiles';
import BoldText from '../../components/TextsElements/BoldText';
import { utils } from '../../core/utils';
import {
  trackAgreementCreate,
  trackAgreementEdit,
} from '../../core/mixpanel/mixPanelEvents';
import { colors } from '../../Theme/colors';

export const registerVisits = (visits, id) => {
  mainStore.requestLoading = true;
  return putVisits(visits, id);
};

export const loadAgreements = async () => {
  mainStore.requestLoading = true;
  const response = await getAgreement(null, 1)
  mainStore.agreements.data = response.items;
};

export const registerAgreement = agreement => {
  mainStore.requestLoading = true;
  if (agreement.id === null) {
    return postAgreement(agreement);
  }
  return putAgreement(agreement);
};

export const removeAgreement = async agreement => {
  mainStore.requestLoading = true;
  return deleteAgreement(agreement);
};

export const submitAgreement = async (
  agreement,
  msg,
  setCurrentAgreementCallback,
  nextStepCallback,
  suppliers,
  currentAgreement,
) => {
  const { invites } = agreement;
  const newAgreement = (await registerAgreement(agreement)).data;
  for (const invite of invites) {
    if (!invite.id) {
      invite.agreementId = newAgreement.id;
      sendInvite(invite);
    }
  }
  await loadAgreements();
  utils.openSnackBar(dictionary.SUCCESS, msg || dictionary.AGREEMENT_EDITED);
  if (nextStepCallback) {
    setCurrentAgreementCallback(newAgreement);
    trackAgreementCreate(
      newAgreement,
      invites.length,
      agreement.answerableUserIds.length,
      suppliers,
    );
    nextStepCallback();
  } else if (msg === dictionary.AGREEMENT_EDITED) {
    trackAgreementEdit(
      {
        description: newAgreement.description,
        internalCode: currentAgreement.internalCode,
      },
      invites.length,
      agreement.answerableUserIds.length,
      suppliers,
    );
  }
};

export const handleSendInvite = async invite => sendInvite(invite);

export const getAnswerablesOptionLabel = option => {
  if (Object.keys(option).length === 0) {
    return '';
  }
  return `(${option.cpf}) ${option.name}`;
};

export const getRetails = () => {
  mainStore.requestLoading = true;
  return getAllRetails();
};

export const handleResend = async inviteId => {
  try {
    await resendInviteById(inviteId);
    utils.openSnackBar(dictionary.SUCCESS, dictionary.RESEND_INVITE_SUCCESS);
  } catch (e) {
    utils.openSnackBar(dictionary.ERROR, dictionary.RESEND_INVITE_ERROR);
  }
};

export const salesRepAlreadyExistInArray = (array, field, value) => {
  const indexOfValue = array.findIndex(salesRep => salesRep[field] === value);
  return indexOfValue !== -1;
};

export const loadRetails = async () => {
  mainStore.agreements.retails = await getRetails();
};

export const loadInitialData = async () => {
  await loadAgreements();
  await loadRetails();
};

export const fetchRetails = async () => {
  const response = await getRetails()
  mainStore.agreements.retails = response
  return response
}

export const fetchAgreements = async (offset, limit, query = null) => {
  const response = await getAgreement(null, 1, offset, limit, query);
  mainStore.agreements.data = response.items;
  return response;
}


export const getStyleAcceptanceStatus = rowData => {
  switch (rowData) {
    case dictionary.WAITING:
      return { color: colors.yellow_600 };
    case dictionary.DISAPPROVED:
      return { color: colors.red_600 };
    default:
      return { color: colors.green_600 };
  }
};

export const getWelcomeText = () => {
  if (profileCheck.isSalesRep()) {
    return (
      <>
        <Typography variant="body1">
          {dictionary.WELCOME_TO}
          <BoldText value={dictionary.INVOLVES_DOOR} />
          {dictionary.YOU_ARE_NOT_REGISTERED}
        </Typography>
      </>
    );
  }
  return (
    <Typography variant="body1">
      {dictionary.NO_AGREEMENTS_WAS_CREATED_YET}
    </Typography>
  );
};

export const getOptionLabelSuppliers = option => {
  if (Object.keys(option).length === 0) {
    return '';
  }
  const name = option.internalName ? option.internalName : option.name;
  const code = option.internalCode ? ` (${option.internalCode})` : '';
  return `${name}${code}`;
};

export const getChipsSuppliers = (value, getTagProps, closeInput) =>
  value.map((option, index) => {
    const name = option.internalName ? option.internalName : option.name;

    return (
      <Chip
        key={index}
        label={name}
        size="small"
        {...getTagProps({ index })}
        disabled={closeInput}
      />
    );
  });

export const getAgreementSuppliers = agreementId =>
  getSuppliersByAgreement(agreementId, 1);

export const findPromoterInList = (array, field, value) => {
  const indexOfValue = array.findIndex(i => i.user[field] === value);
  return array[indexOfValue];
};

export const promoterAlreadyExistInArray = (array, field, value) => {
  const indexOfValue = array.findIndex(promoter => {
    if (exist(promoter.user)) {
      return promoter.user[field] === value;
    }
    return promoter[field] === value;
  });
  return indexOfValue !== -1;
};

export const autocompleteSupplierStyle = {
  width: '100%',
  paddingTop: 0, 
  marginRight: 0,
}
