export const API_AGREEMENTS = '/agreements';
export const API_RETAILS = '/retails';
export const API_USERS = '/users';
export const API_STORES = '/stores';
export const API_SUPPLIERS = '/suppliers';
export const API_LOGIN = '/auth/login';
export const API_LOGIN_AUTO_CHECKIN = '/auth/login-auto-checkin';
export const API_PASSWORD_RESET = '/password-reset';
export const API_VISITS = '/visits';
export const API_EXPORT_VISITS = '/export-visits';
export const API_PROMOTER_ACCESSES = '/accesses';
export const API_SCHEDULED_VISITS = '/scheduled-visits';
export const API_ANSWERABLE_USER = '/answerableUsers';
export const API_INVITES = '/user-invites';
export const API_RESEND = '/resend';
export const API_DOCUMENTS = '/documents';
export const API_PROMOTERS = '/promoters';
export const API_MIXPANEL = '/mixpanel';
export const API_SHELF_MISSIONS_RESTOCK = '/shelf-missions/restock';
export const API_PRODUCT_GROUPS = '/product-groups';
export const API_PRODUCT_SUPENSIONS = '/product-suspensions';
export const API_PROMOTERS_ENGAGEMENT = '/promoters-engagement';
export const API_VISITS_INDICATORS = '/visits-indicators';
export const API_VISITS_HISTORY = '/visits-history';
export const API_VISITS_LIBERATE_ACCESS = '/liberate-access';
export const API_PROMOTIONAL_SPOT = '/promotional-spot';
export const API_PROMOTIONAL_SPOTS = '/promotional-spot/spots';
export const API_PROMOTIONAL_CALENDARS = '/promotional-spot/calendars';
export const API_PROMOTIONAL_CALENDAR_PERIODS =
  '/promotional-spot/calendars/:id/periods';
export const API_PROMOTIONAL_CALENDAR_ADDRESSES =
  '/promotional-spot/addressings';
export const API_PROMOTIONAL_NEGOTIATION = '/promotional-spot/negotiations';
export const API_PROMOTIONAL_NEGOTIATION_EXECUTION =
  '/promotional-spot/executions';
export const API_PROMOTIONAL_NEGOTIATION_EXECUTION_UPDATE = `${API_PROMOTIONAL_NEGOTIATION_EXECUTION}/:id`;
export const API_PROMOTIONAL_NEGOTIATION_EXECUTION_INCREMENT = `${API_PROMOTIONAL_NEGOTIATION_EXECUTION}/:id/increment`;
export const API_PROMOTIONAL_NEGOTIATION_EXECUTION_DECREMENT = `${API_PROMOTIONAL_NEGOTIATION_EXECUTION}/:id/decrement`;
export const API_PROMOTIONAL_NEGOTIATION_UPDATE = `${API_PROMOTIONAL_NEGOTIATION}/:id`;
export const API_PROMOTIONAL_NEGOTIATION_RECURSIVE = `${API_PROMOTIONAL_NEGOTIATION}/:id/recursive`;
export const API_PROMOTIONAL_NEGOTIATION_UPDATE_SUPPLIERS = `${API_PROMOTIONAL_NEGOTIATION_UPDATE}/suppliers`;
export const API_PROMOTIONAL_NEGOTIATION_UPDATE_PRODUCTS = `${API_PROMOTIONAL_NEGOTIATION_UPDATE}/products`;
export const API_PROMOTIONAL_NEGOTIATION_PANEL = '/promotional-spot/panel';
export const API_RETAIL_FEATURES = '/retails/:retailId/features';
export const API_PRODUCTS = '/products';
export const API_EXPIRATION = '/expiration';
export const API_EXPIRATION_REPORTS = `${API_EXPIRATION}/reports`;
export const API_EXPIRATION_REPORTS_EXPORT = `${API_EXPIRATION_REPORTS}/export`;
export const API_FAMILIES = '/families';
export const API_FAMILIES_PRODUCT = `${API_FAMILIES}/products`;
export const API_PROMOTIONAL_SPOT_REPORT_PRODUCTS = `${API_PROMOTIONAL_SPOT}/report/products`;
export const API_PROMOTIONAL_SPOT_REPORT_PROBLEMS = `${API_PROMOTIONAL_SPOT}/report/problems`;