import React, { useCallback, useEffect, useRef, useState } from 'react';
import Hide from '../../components/Hide';
import Loading from '../../components/Loading';
import PageContent from '../../components/PageContent';
import { useDebounce } from '../../core/hooks';
import FixedBottomFooter from '../../components/FixedBottomFooter';
import { DefaultButton } from '../../components/Buttons';
import {
  getExpirationReport,
  exportExpirationReport,
} from '../../core/services/expiration/expirationService';
import {
  ExpirationReportDTO,
  GetExpirationReportParams,
} from '../../core/services/expiration/types';
import * as dictionary from '../../core/constants/dictionary';
import { Filter, ExpirationTable } from './components';
import {
  initialFilter,
  formatFilename,
  ExpirationFilter,
  mapFilterToQueryParams,
} from './utils';

const ExpirationPanel = () => {
  const [products, setProducts] = useState<ExpirationReportDTO[]>([]);
  const [formFilter, setFormFilter] = useState<ExpirationFilter>(initialFilter);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [toolbarInputFocus, setToolbarInputFocus] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [excelFileURL, setExcelFileURL] = useState('');
  const mounted = useRef<boolean>();
  const downloadExcelButton = useRef<HTMLAnchorElement>(null);

  const fetchData = useCallback(async () => {
    if (!isLoading && (formFilter?.retailId || !mounted.current)) {
      setIsLoading(true);
      try {
        const limit = rowsPerPage;
        const offset = page * rowsPerPage;
        let params: GetExpirationReportParams = {
          limit,
          offset,
        };
        if (formFilter) {
          params = {
            ...params,
            ...mapFilterToQueryParams(formFilter),
          };
        }

        const { count, items } = await getExpirationReport(params);
        setTotal(count);
        setProducts(items);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    }
  }, [formFilter, isLoading, rowsPerPage, page]);

  const debouncedOnSearch = useDebounce(async (value: string[]) => {
    const inputValue = String(value[0]);
    setPage(0);
    setSearchText(inputValue);
    setToolbarInputFocus(true);
  });

  const onSubmitHandler = async (
    event: MouseEvent,
    filter: ExpirationFilter
  ) => {
    event.preventDefault();
    setFormFilter(filter);
  };

  const onExportData = useCallback(async () => {
    setIsLoadingExport(true);
    try {
      if (!isLoadingExport) {
        let params = {
          startDate: formFilter?.startDate ?? new Date(),
          endDate: formFilter?.endDate ?? new Date(),
        } as unknown as GetExpirationReportParams;

        if (formFilter) {
          params = {
            ...params,
            ...mapFilterToQueryParams(formFilter),
          };
        }

        const response = await exportExpirationReport(params);
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });
        setExcelFileURL(window.URL.createObjectURL(blob));
      }
    } catch (err) {
    } finally {
      setIsLoadingExport(false);
    }
  }, [isLoadingExport, formFilter]);

  useEffect(() => {
    if (excelFileURL === '') return;

    if (
      downloadExcelButton.current &&
      formFilter?.endDate &&
      formFilter?.startDate
    ) {
      downloadExcelButton.current.download = formatFilename(
        formFilter.startDate,
        formFilter.endDate
      );
      downloadExcelButton.current.click();
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [excelFileURL]);

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, searchText, formFilter]);

  useEffect(() => {
    mounted.current = true;
    fetchData();
  }, []);

  return (
    <PageContent title={dictionary.EXPIRATION_PANEL}>
      <Filter onSubmit={onSubmitHandler} />
      <ExpirationTable
        page={page}
        total={total}
        data={products}
        rowsPerPage={rowsPerPage}
        searchTextValue={searchText}
        toolbarInputFocus={toolbarInputFocus}
        onChangePageHandler={(page: number) => setPage(page)}
        onChangeSearchHandler={(value: string[]) => debouncedOnSearch(value)}
        onChangeRowsPerPageHandler={(rowsPerPage: number) => {
          setPage(0);
          setRowsPerPage(rowsPerPage);
          setToolbarInputFocus(false);
        }}
      />
      <FixedBottomFooter>
        <Hide>
          <a
            ref={downloadExcelButton}
            href={excelFileURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
          </a>
        </Hide>
        <DefaultButton
          loading={isLoadingExport}
          disabled={isLoadingExport}
          label={dictionary.EXPORT}
          onClick={onExportData}
          style={{ width: 'auto' }}
        />
      </FixedBottomFooter>
      {isLoading && <Loading isOpen />}
    </PageContent>
  );
};

export default ExpirationPanel;
