import React, {
  Children,
  cloneElement,
  ReactElement,
  ReactNode,
  useState,
} from 'react';
import { Button, Collapse, Grid } from '@material-ui/core';
import * as dictionary from '../../../../../core/constants/dictionary';
import FilterListIcon from '@material-ui/icons/FilterList';
import classes from './FormFilter.module.scss';

type Props = {
  children: ReactNode;
  buttons?: ReactNode;
  actionPosition?: 'button' | 'grid';
};
const FormFilterCollapse = ({
  children,
  buttons,
  actionPosition = 'button',
}: Props) => {
  const cloneButtons = Children.map(
    buttons as ReactElement<any[]>,
    (child, id) => {
      return child
        ? cloneElement(child, {
            ...child.props,
          })
        : null;
    }
  );
  const [isOpen, setIsOpen] = useState<boolean>(true);
  return (
    <>
      <Grid container>
        <Button
          disableElevation
          size="large"
          startIcon={<FilterListIcon />}
          onClick={() => setIsOpen((prevState) => !prevState)}
        >
          {dictionary.FILTERS}
        </Button>
      </Grid>
      <Collapse in={isOpen} className={classes.collapse}>
        <Grid container className={classes.formGrid}>
          {children}
          {actionPosition === 'grid' && (
            <Grid className={classes.gridActions}>{cloneButtons}</Grid>
          )}
        </Grid>
      </Collapse>
      <Grid container className={classes.formActions}>
        {actionPosition === 'button' && <>{cloneButtons}</>}
      </Grid>
    </>
  );
};

export default FormFilterCollapse;
