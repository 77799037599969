import {
  PROMOTIONAL_CALENDARS,
  EDIT_CALENDAR,
} from '../../../core/constants/dictionary';
import { CalendarPeriodTableRow } from '../common/types';

export const pageTitle = `${PROMOTIONAL_CALENDARS} > ${EDIT_CALENDAR}`;

export const parseResultsPeriodsForCurrentTimezone = (
  item: CalendarPeriodTableRow
) => ({
  ...item,
  startAt: new Date(`${item?.startAt} 00:00:00`),
  endAt: new Date(`${item?.endAt} 00:00:00`),
  negotiatedAt: new Date(`${item?.negotiatedAt} 00:00:00`),
});
