import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import { Button, Grid, TextField } from '@material-ui/core';
import { AutocompleteRenderInputParams, Autocomplete } from '@material-ui/lab';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { useStoreData } from '../../../../../hooks/StoreHooks';
import { useRetailList } from '../../../../../hooks/RetailHooks';
import * as dictionary from '../../../../../core/constants/dictionary';
import { mainStore } from '../../../../../core/store/mainStore';
import { RetailEntityType } from '../../../../../core/types/retail/retail-entity.type';
import { GetPromotionalSpotReportProblemsParams } from '../../../../../core/services/promotionalSpots/types';
import FormFilterCollapse from '../../../common/components/FormFilterCollapse';
import {
  translatePromotionalNegotiationProblemTypeToAPI,
  translatePromotionalSpotTypeToAPI,
} from '../../../common/utils';
import { FormRetailField } from '../../../../../components/Form/FormRetailField';

import { problemTypeOptions } from '../../../common/problemTypesOptions';
import { globalTypeOptions } from '../../../common/globalTypeOptions';
import { StoreDto } from '../../../Addressing/types';
import classes from './FormFilter.module.scss';

type Props = {
  onSubmit: (args?: GetPromotionalSpotReportProblemsParams) => void;
  isLoading: boolean;
};
const FormFilter = ({ onSubmit, isLoading }: Props) => {
  const {
    retails,
    fetch: fetchRetails,
    isLoading: isLoadingRetails,
    retailInUser,
  } = useRetailList();

  const {
    fetch: fetchStores,
    isLoading: isLoadingStores,
    stores: storeOptions,
    isStoreManager,
  } = useStoreData();

  const [retail, setRetail] = useState<RetailEntityType>();
  const [store, setStore] = useState<StoreDto | null>();
  const [spotTypes, setSpotTypes] = useState<string[]>([]);
  const [createdAt, setCreatedAt] = useState<Date | null>();
  const [createdTo, setCreatedTo] = useState<Date | null>();

  const [problemType, setProblemType] = useState<string | null>();

  const retailId = useMemo(
    () => mainStore?.loggedUser?.retailId ?? retailInUser ?? retail?.id,
    [retail, retailInUser]
  );

  const onClearForm = useCallback(() => {
    setCreatedAt(null);
    setCreatedTo(null);
    setStore(undefined);
    setProblemType(null);
    setSpotTypes([])
  }, []);

  const onHandleSubmit = useCallback((triggeredByRetail = false) => {
    onSubmit({
      retailId,
      storeId: mainStore.loggedUser.storeId ?? store?.id,
      problemType: problemType
        ? translatePromotionalNegotiationProblemTypeToAPI(problemType)
        : undefined,
      spotTypes: spotTypes.map(translatePromotionalSpotTypeToAPI),
      createdTo: createdTo ?? undefined,
      createdAt: createdAt ?? undefined,
      offset: triggeredByRetail,
    });
  }, [
    retailId,
    store,
    problemType,
    store,
    mainStore.loggedUser,
    spotTypes,
    createdAt,
    createdTo,
  ]);

  useEffect(() => {
    fetchRetails();
  }, []);

  useEffect(() => {
    if (retailId) {
      fetchStores(retailId);
      onHandleSubmit(true);
    }
  }, [retailId]);

  return (
    <FormFilterCollapse
      actionPosition="grid"
      buttons={
        <>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            disabled={!retailId || isLoading}
            onClick={onHandleSubmit}
          >
            {dictionary.SEARCH}
          </Button>
          <Button
            disableElevation
            color="primary"
            onClick={onClearForm}
            disabled={isLoading}
          >
            {dictionary.CLEAR}
          </Button>
        </>
      }
    >
      {!retailInUser && (
        <Grid item sm={6} md={2}>
          <FormRetailField
            disabled={isLoadingRetails}
            value={retail}
            key={retail?.id}
            options={retails}
            onChange={setRetail}
          />
        </Grid>
      )}
      {!isStoreManager && (
        <Grid item sm={6} md={2}>
          <Autocomplete
            disabled={isLoadingStores}
            id="store"
            size="small"
            value={store}
            key={store?.id}
            options={storeOptions}
            getOptionLabel={(store: StoreDto) => store.name}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField
                {...params}
                label={dictionary.STORES}
                variant="outlined"
              />
            )}
            onChange={(_: any, value: StoreDto | null) => {
              setStore(value);
            }}
            style={{}}
          />
        </Grid>
      )}

      <Grid item sm={6} md={4}>
        <Autocomplete
          multiple
          disabled={isLoading}
          id="spotTypes"
          size="small"
          value={spotTypes}
          options={globalTypeOptions}
          getOptionLabel={(option: string) => option}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              label={dictionary.PROMOTIONAL_SPOT_TYPE}
              variant="outlined"
            />
          )}
          onChange={(_, value: string[]) => {
            setSpotTypes(value);
          }}
        />
      </Grid>
      <Grid item sm={4} md={3}>
        <Autocomplete
          disabled={isLoading}
          id="type"
          size="small"
          value={problemType}
          options={problemTypeOptions}
          getOptionLabel={(option: string) => option}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              label={dictionary.PROBLEM_TYPE}
              variant="outlined"
            />
          )}
          onChange={(_, value: string | null) => {
            setProblemType(value);
          }}
        />
      </Grid>

      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <Grid item md={4} direction="row" container alignItems="flex-start">
          <Grid item sm={6} className={classes.datePickerGroup}>
            <KeyboardDatePicker
              autoOk
              disableToolbar
              className={classes.datePickerItem}
              margin="none"
              variant="inline"
              format="dd/MM/yyyy"
              id="createdAt"
              label={dictionary.DATE_START_SELECT}
              maxDate={createdTo ?? new Date()}
              maxDateMessage={dictionary.MAX_START_DATE_MESSAGE}
              invalidDateMessage={''}
              onChange={(date) => {
                setCreatedAt(date);
              }}
              value={createdAt ?? null}
            />
          </Grid>
          <Grid item sm={6} className={classes.datePickerGroup}>
            <KeyboardDatePicker
              autoOk
              className={classes.datePickerItem}
              disableToolbar
              margin="none"
              id="createdTo"
              variant="inline"
              format="dd/MM/yyyy"
              invalidDateMessage={''}
              maxDate={new Date()}
              minDate={createdAt ? createdAt : undefined}
              label={dictionary.DATE_END_SELECT}
              value={createdTo ?? null}
              minDateMessage={dictionary.MIN_END_DATE_MESSAGE}
              maxDateMessage={dictionary.MAX_END_DATE_MESSAGE}
              onChange={(date) => {
                setCreatedTo(date);
              }}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </FormFilterCollapse>
  );
};

export default FormFilter;
