import React from 'react';
import { view } from 'react-easy-state';
import { MuiThemeProvider } from '@material-ui/core';
import { GlobalStyle, withToastProvider, createTheme } from '@involves/violin';
import { ThemeProvider } from 'styled-components';
import Theme from './Theme/Theme';
import { mainStore } from './core/store/mainStore';
import MiniDrawer from './components/SideBar/index';
import { RootContainer } from './style';
import { Routes } from './core/router';
import './App.css';
import './Theme/main.css';
import './Theme/reset.css';
import { SnackbarProvider } from './context/Snackbars';

const customInvolvesTheme = createTheme({});

const App = ({ history }) => {
  return (
    !mainStore.isAuthenticating && (
      <>
        <ThemeProvider theme={customInvolvesTheme}>
          <GlobalStyle />
          <MuiThemeProvider theme={Theme}>
            <SnackbarProvider>
              <RootContainer>
                <MiniDrawer history={history} />
                <Routes history={history} />
              </RootContainer>
            </SnackbarProvider>
          </MuiThemeProvider>
        </ThemeProvider>
      </>
    )
  );
};

const idToastContainer = {
  id: 'violin-toast-container',
};

export default view(withToastProvider(App, idToastContainer));
