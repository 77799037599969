import React from 'react';
import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import ModalTitle from '../../../../../../components/Modal/Title';
import { CancelButton, DefaultButton } from '../../../../../../components/Buttons';
import * as dictionary from '../../../../../../core/constants/dictionary'
import { styles } from '../styles';

type Props = {
  onClose: Function;
  onDelete: Function;
}

const DeleteAddressingModal = ({ onClose, onDelete }: Props) => {
  return (
    <>
      <ModalTitle title={dictionary.DELETE_ADDRESSING} />
      <DialogContent style={styles.dialog}>
        <Typography>
          {`${dictionary.CONFIRM_DELETE_ADDRESSING} ${dictionary.CONFIRM_DELETE_ADDRESSING_EXTRA}`}
        </Typography>
      </DialogContent>

      <DialogActions>
        <CancelButton onClick={onClose} />
        <DefaultButton
          label={dictionary.CONFIRM}
          onClick={onDelete}
          style={styles.defaultButton}
        />
      </DialogActions>
    </>
  )
}

export default DeleteAddressingModal;
