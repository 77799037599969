import React, { useCallback, useState, useMemo } from 'react';
import {
  DialogActions,
  DialogContent,
  Typography,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Icon,
  makeStyles,
} from '@material-ui/core';
import * as dictionary from '../../../../core/constants/dictionary';
import ModalTitle from '../../../../components/Modal/Title';
import CancelButton from '../../../../components/Buttons/Cancel';
import DefaultButton from '../../../../components/Buttons/Default';
import { getHoursAndMinutesFromISODate } from '../../../../core/constants/dates';
import { colors } from '../../../../Theme/colors';
import { mainStore } from '../../../../core/store/mainStore';
import { checkOutVisit } from '../../../../core/services/visits/visitsService';
import { errorMessageToFeedback } from '../../utils';
import checkOutCheck from '../../../../assets/general/checkOutCheck.svg';
import ErrorIcon from '../../../../assets/general/warning.svg';

type Props = {
  currentVisit: any,
  closeModalCallback: Function,
  reloadCallback: Function,
}
const useStyles = makeStyles({
  imageIcon: { height: '100%' },
  iconRoot: {
    textAlign: 'center',
    marginRight: '0.5rem',
    width: 'fit-content',
  },
});

export const CheckOut = ({
  currentVisit,
  closeModalCallback,
  reloadCallback,
}: Props) => {
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const [visit, setVisit] = useState(currentVisit);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(dictionary.CHECK_OUT_ERROR);

  const handleCheckOut = useCallback(
    async event => {
      event.preventDefault();
      try {
        mainStore.requestLoading = true;
        const checkoutDTO = {
          visitId: visit.id,
          checkoutAt: new Date(),
          checkoutStatus: visit.checkoutStatus,
        };
        await checkOutVisit(checkoutDTO);
        setStep(1);
        setHasError(false);
      } catch (error) {
        const feedbackMessage = errorMessageToFeedback(error.response?.data?.message);
        setHasError(true);
        setErrorMessage(
          `${feedbackMessage}. ${dictionary.PLEASE_TRY_AGAIN_OR_CONTACT_SUPPORT_TEAM}`
        );
      } finally {
        mainStore.requestLoading = false;
      }
    },
    [visit],
  );

  const handleClose = useCallback(() => {
    setStep(0);
    setHasError(false);
    closeModalCallback();
  }, [closeModalCallback]);

  const renderError = useMemo(() => {
    if (hasError) {
      return (
        <div className="icon-error-warning">
          <Icon classes={{ root: classes.iconRoot }}>
            <img className={classes.imageIcon} alt="error" src={ErrorIcon} />
          </Icon>
          <Typography variant="body1" color="error">
            {errorMessage}
          </Typography>
        </div>
      );
    }
    
    return null;
  }, [classes, hasError, errorMessage]);

  const renderContent = useMemo(() => {
    if (step === 0) {
      return (
        <>
          <ModalTitle title={dictionary.PROMOTER_CHECK_OUT} />

          <DialogContent>
            {renderError}

            <Typography variant="h2">{visit.retailName}</Typography>

            <Typography variant="body1" style={{ marginTop: '8px' }}>
              {`Promotor: ${visit.promoterName}`}
            </Typography>

            <Typography variant="body1" style={{ marginTop: '8px' }}>
              {`Hora check-in: ${getHoursAndMinutesFromISODate(
                visit.checkinAt,
              )}`}
            </Typography>

            <FormControl component="fieldset" style={{ marginTop: '40px' }}>
              <FormLabel component="legend">
                {dictionary.HOW_WAS_CHECK_OUT}
              </FormLabel>
              <RadioGroup
                style={{ marginTop: '16px' }}
                aria-label="checkOutStatus"
                name="checkOutStatus"
                value={visit.checkoutStatus}
                onChange={event => {
                  setVisit({
                    ...visit,
                    checkoutStatus: parseInt(event.target.value, 2),
                  });
                }}
              >
                <FormControlLabel
                  value={0}
                  control={<Radio style={{ color: colors.blue_500 }} />}
                  label={dictionary.PROMOTER_SOUGHT}
                />
                <FormControlLabel
                  value={1}
                  control={<Radio style={{ color: colors.blue_500 }} />}
                  label={dictionary.CHECK_OUT_LEFT}
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>

          <DialogActions>
            <CancelButton onClick={handleClose} />

            <DefaultButton
              label={dictionary.DO_CHECK_OUT}
              style={{ width: 'auto' }}
              onClick={(event: any) => handleCheckOut(event)}
            />
          </DialogActions>
        </>
      );
    }
    if (step === 1) {
      return (
        <>
          <DialogContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img
              alt="check out check"
              style={{
                width: '96px',
                marginTop: '120px',
                marginBottom: '56px',
              }}
              src={checkOutCheck}
            />

            <Typography
              variant="h1"
              style={{
                color: colors.black_900,
                fontWeight: 'bold',
                marginBottom: '24px',
              }}
            >
              {dictionary.CHECK_OUT_CONFIRMED}
            </Typography>

            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              {visit.promoterName}
            </Typography>
            <Typography variant="body1">
              {dictionary.CHECK_OUT_STORE_REALIZED}
            </Typography>
          </DialogContent>

          <DialogActions>
            <CancelButton label={dictionary.CLOSE} onClick={reloadCallback} />
          </DialogActions>
        </>
      );
    }
    
    return null;
  }, [
    step, 
    visit,
    renderError, 
    reloadCallback, 
    handleClose, 
    handleCheckOut, 
  ]);

  return renderContent;
};
