
import React from 'react';
import styles from '../style.module.scss';

const ClosedMenuItem = ({ item, isActive }) => {
  const className = `${styles.menuItem} ${isActive ? styles.active : ''}`;

  return (
    item.icon ? (
      <div className={className} key={item.icon}>
        <img alt={item.name} src={item.icon} />
      </div>
    ) : null
  );
};

export default ClosedMenuItem;
