import React from 'react';
import { MTableToolbar } from 'material-table';
import IconTable from '../../../../components/Tables/IconTable';
import FilterTable from '../../../../components/Tables/FilterTable';
import { EmptyDataMessageRow } from '../../../../components/Tables/DefaultTable';
import Edit from '../../../../assets/tables/edit.svg';
import Group from '../../../../assets/tables/group.svg';
import Details from '../../../../assets/tables/details.svg';
import Promoters from '../../../../assets/tables/promoters.svg';
import ThumbUp from '../../../../assets/tables/thumbUp.svg';
import Visits from '../../../../assets/tables/visits.svg';
import Remove from '../../../../assets/tables/remove.svg';
import { getStyleAcceptanceStatus } from '../../functions';
import { findValueInArray } from '../../../../core/validation';
import { profileCheck } from '../../../../core/profiles';
import * as dictionary from '../../../../core/constants/dictionary';
import PatchedPagination from '../../../../components/PatchedPagination';

const colorControl = rowData => rowData.status !== 0 ? 'inherit' : 'disabled';

export const AgreementsTable = (props) => {
  const {
    data,
    retailsData,
    handleOpenModal,
    count,
    rowsPerPage,
    pageIndex,
    searchTextValue,
    toolbarInputFocus,
    onChangePageHandler = () => {},
    onChangeRowsPerPageHandler = () => {},
    onSearchChangeHandler = () => {},
  } = props;
  
  return (
    <FilterTable
      data={data}
      rowsPerPage={rowsPerPage}
      columns={[
        {
          title: dictionary.RETAIL,
          field: 'retailName',
          render: rowData => {
            const retail = findValueInArray(
              retailsData,
              'id',
              rowData.retailId,
            );
            return retail ? retail.name : '';
          },
        },
        { title: dictionary.CODE, field: 'internalCode' },
        {
          title: dictionary.DATE,
          field: 'createdAt',
          render: rowData => {
            const date = new Date(rowData.createdAt);
            return `${date.toLocaleDateString()}`;
          },
        },
        { title: dictionary.OWNER, field: 'ownerUserName' },
        { title: dictionary.SUPPLIERS, field: 'supplierNames' },
        {
          title: dictionary.APPROVAL,
          field: 'acceptanceStatus',
          hidden: profileCheck.isRetailVision(),
          cellStyle: rowData => getStyleAcceptanceStatus(rowData),
          lookup: {
            0: dictionary.WAITING,
            1: dictionary.APPROVED,
            2: dictionary.DISAPPROVED,
          },
        },
        { title: dictionary.PROMOTERS, field: 'promotersCount' },
        { title: dictionary.AGREEMENT_STORES, field: 'storesCount' },
      ]}
      actions={[
        rowData =>
          profileCheck.isSalesRep() && rowData.acceptanceStatus === 1
            ? {
              icon: () => (
                <IconTable icon={Details} color={colorControl(rowData)} />
              ),
              tooltip: dictionary.VIEW_AGREEMENT,
              onClick: (event, rowData) => {
                handleOpenModal(dictionary.DETAILS, rowData);
              },
            }
            : '',
        rowData =>
          (profileCheck.isSalesRep() || profileCheck.isSuperAdmin()) &&
            rowData.acceptanceStatus === 0
            ? {
              icon: () => (
                <IconTable icon={ThumbUp} color={colorControl(rowData)} />
              ),
              tooltip: dictionary.APPROVE_AGREEMENT,
              onClick: (event, rowData) => {
                handleOpenModal(dictionary.APPROVE_AGREEMENT, rowData);
              },
            }
            : '',
        rowData =>
          profileCheck.isWebUsersRole() || profileCheck.isPromoterApprover()
            ? {
              icon: () => (
                <IconTable icon={Promoters} color={colorControl(rowData)} />
              ),
              tooltip: dictionary.LINK_PROMOTER_TO_AGREEMENT,
              onClick: (event, rowData) => {
                handleOpenModal(dictionary.EDIT_PROMOTER, rowData);
              },
            }
            : '',
        rowData =>
          profileCheck.isWebUsersRole() || profileCheck.isPromoterApprover()
            ? {
              icon: () => (
                <IconTable icon={Visits} color={colorControl(rowData)} />
              ),
              tooltip:
                profileCheck.isSalesRep() || profileCheck.isPromoterApprover()
                  ? dictionary.VIEW_WEEK_VISITS
                  : dictionary.EDIT_WEEK_VISITS,
              onClick: (event, rowData) => {
                handleOpenModal(dictionary.EDIT_FREQUENCY, rowData);
              },
            }
            : '',
        rowData =>
          profileCheck.isWebUsersRole()
            ? {
              icon: () => (
                <IconTable
                  icon={profileCheck.isSalesRep() ? Group : Edit}
                  color={colorControl(rowData)}
                />
              ),
              tooltip: profileCheck.isSalesRep()
                ? dictionary.ADD_PROMOTER_TO_AGREEMENT
                : dictionary.EDIT_AGREEMENT,
              onClick: (event, rowData) => {
                handleOpenModal(dictionary.EDIT, rowData);
              },
            }
            : '',
        rowData =>
          profileCheck.isSuperAdmin() ||
            profileCheck.isRetailAdmin() ||
            profileCheck.isBuyer()
            ? {
              icon: () => (
                <IconTable icon={Remove} color={colorControl(rowData)} />
              ),
              tooltip: dictionary.REMOVE_AGREEMENT,
              onClick: (event, rowData) => {
                handleOpenModal(dictionary.DELETE, rowData);
              },
            }
            : '',
      ]}
      localization={{
        body: {
          emptyDataSourceMessage: (
            <EmptyDataMessageRow 
              message={dictionary.EMPTY_AGREEMENT_TABLE}
            />
          ),
        },
      }}
      components={{
        Pagination: (props) => {
          return (
            <PatchedPagination
              {...props}
              count={count}
              page={pageIndex}
              rowsPerPage={rowsPerPage}
              onChangePage={(evt, page) => onChangePageHandler(page)}
              onChangeRowsPerPage={(event) => onChangeRowsPerPageHandler(parseInt(event.target.value))}
            />
          );
        },
        Toolbar: (props) => {
          if(onSearchChangeHandler) {
            return (
              <MTableToolbar 
                {...props} 
                searchAutoFocus={toolbarInputFocus}
                searchText={searchTextValue}
                onSearchChanged={(value) => onSearchChangeHandler(value)}
              />
            );  
          }
          else return <MTableToolbar {...props} />;  
        },
      }}
    />
  );
};
