import React, { useEffect, useMemo } from 'react';
import { view } from 'react-easy-state';
import Hide from '../../../components/Hide';
import Loading from '../../../components/Loading';
import PageContent from '../../../components/PageContent';
import StickyFooter from '../../../components/stickyFooter/stickyFooter';
import {
  getPromotionalSpotReportProducts,
  getPromotionalSpotReportReportProductsXLS
} from '../../../core/services/promotionalSpots/promotionalSpotReportService';
import { useStoreData } from '../../../hooks/StoreHooks';
import { PromotionalSpotReportDto } from '../../../core/types/promotional-spot/promotional-spot-repot-data-dto';
import { GetPromotionalSpotReportParams } from '../../../core/services/promotionalSpots/types';
import * as dictionary from '../../../core/constants/dictionary';
import useReport from '../common/components/hooks/use-report';
import ButtonDownload from '../common/components/ButtonDowload';
import PromotionalSpotTableReport from '../common/components/ReportTable';
import FormFilter from './components/FormFilter';
import {
  parseReportResultToTable,
  columnsForPromotionalSpotTableReport,
} from './utils';

const PromotionalSpotProductReport = () => {
  const {
    page,
    total,
    report,
    filter,
    isLoading,
    rowsPerPage,
    excelFileURL,
    isLoadingExport,
    downloadXlsxButton,
    setPage,
    setFilter,
    setRowsPerPage,
    fetchXls,
    fetchData,
  } = useReport<PromotionalSpotReportDto, GetPromotionalSpotReportParams>({
    fetchDataRequest: getPromotionalSpotReportProducts,
    fetchXLS: getPromotionalSpotReportReportProductsXLS,
    fileName: `${dictionary.PROMOTIONAL_SPOT_NEGOTIATIONS_REPORTS_TITLE} - ${dictionary.PRODUCTS}`,
  });
  
  const { isStoreManager } = useStoreData();
  
  const dowloadButtonRender = useMemo(
    () => (
      <ButtonDownload
        label={dictionary.EXPORT}
        disabled={isLoading || total === 0}
        onClick={fetchXls}
      />
    ),
    [isLoading, total, isLoadingExport, filter]
  );

  useEffect(() => {
    if (filter?.retailId) {
      fetchData({ ...filter });
    }
  }, [rowsPerPage, total, filter, page]);

  return (
    <>
      <PageContent title={dictionary.PROMOTIONAL_SPOT_NEGOTIATIONS_REPORTS_TITLE}>
        <FormFilter
          onSubmit={(args?: GetPromotionalSpotReportParams) => {
            setFilter(args);
          }}
          isLoading={isLoading}
        />
        {isLoading && <Loading isOpen />}
        <PromotionalSpotTableReport
          report={report}
          total={total}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePageHandler={(page: number) => setPage(page)}
          onChangeRowsPerPageHandler={(rowsPerPage: number) => {
            setPage(0);
            setRowsPerPage(rowsPerPage);
          }}
          columns={
            isStoreManager
              ? columnsForPromotionalSpotTableReport.slice(1)
              : columnsForPromotionalSpotTableReport
          }
          parseData={parseReportResultToTable}
          emptyMessage={dictionary.PROMOTIONAL_SPOT_REPORT_PRODUCTS_EMPTY_DATA}
        />
      </PageContent>
      <Hide>
        <a
          ref={downloadXlsxButton}
          href={excelFileURL}
          target="_blank"
          rel="noopener noreferrer"
        />
      </Hide>
      <StickyFooter actionButton={dowloadButtonRender} />
    </>
  );
};

export default view(PromotionalSpotProductReport);
