import React, { useState, useCallback, useMemo } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextFieldMUI from '@material-ui/core/TextField';
import AutocompleteMUI from '@material-ui/lab/Autocomplete';
import * as dictionary from '../../core/constants/dictionary';
import { validator } from '../../core/validator/validator';
import { exist } from '../../core/validation';

const CssTextField = withStyles((theme) => theme.sizes.defaultTextField)(
  TextFieldMUI
);

const CssMultiTextField = withStyles((theme) => theme.sizes.multiTextField)(
  TextFieldMUI
);

const useStyles = makeStyles((theme) => ({
  margin: theme.sizes.defaultInputsMarginSize,
  multilineMargin: theme.sizes.multiInputsMarginSize,
}));

const Autocomplete = ({
  style = {},
  value = undefined,
  onChange = undefined,
  options = {},
  getOptionLabel = undefined,
  label = '',
  onInputChange = undefined,
  loading = undefined,
  loadingText = '',
  filterOptions = undefined,
  validations = [],
  required = true,
  disabled = false,
  multiple = false,
  renderTags = null,
  getOptionSelected = undefined,
  getLimitTagsText = undefined,
  placeholder = '',
  defaultValue = undefined,
  noOptionsText = '',
  renderOption = undefined,
  showError = false,
  errorText = '',
  ...rest
}) => {
  const classes = useStyles();
  const [hasError, setHasError] = useState(showError);
  const [helperText, setHelperText] = useState(errorText);

  const handleOnClose = useCallback((event, validatorValue) => {
    if (validatorValue) {
      const validatorObject = validatorValue(event);
      setHasError(validatorObject.hasError);
      setHelperText(validatorObject.helperText);
    }
  }, []);

  const getRenderTextField = useCallback(
    (params) => {
      if (multiple) {
        return (
          <CssMultiTextField
            {...params}
            variant="outlined"
            label={label}
            error={showError ? showError : hasError}
            helperText={errorText ? errorText : helperText}
            className={classes.multilineMargin}
            placeholder={placeholder}
            fullWidth
            required={required}
          />
        );
      }
      return (
        <CssTextField
          {...params}
          variant="outlined"
          label={label}
          error={showError ? showError : hasError}
          helperText={errorText ? errorText : helperText}
          className={classes.margin}
          placeholder={placeholder}
          fullWidth
          required={required}
        />
      );
    },
    [
      classes,
      hasError,
      helperText,
      label,
      multiple,
      placeholder,
      required,
      showError,
      errorText,
    ]
  );

  const validatorValue = useMemo(() => {
    return validations && validations.length > 0
      ? validator(validations)
      : undefined;
  }, [validations]);

  const rendertags = useMemo(() => {
    return exist(renderTags)
      ? (tagValue, getTagProps) => renderTags(tagValue, getTagProps, disabled)
      : () => {};
  }, [disabled, renderTags]);

  const noOptionsTextValue = useMemo(() => {
    return noOptionsText || dictionary.NO_OPTIONS;
  }, [noOptionsText]);

  return (
    <AutocompleteMUI
      getOptionSelected={getOptionSelected}
      disableClearable={false}
      disableCloseOnSelect={false}
      disabled={disabled}
      loading={loading}
      loadingText={loadingText}
      onBlur={(event) => handleOnClose(event, validatorValue)}
      onClose={(event) => handleOnClose(event, validatorValue)}
      style={style}
      value={value}
      onInputChange={onInputChange}
      onChange={(event, autocompleteValue) => {
        onChange(event, autocompleteValue);
      }}
      getLimitTagsText={getLimitTagsText}
      options={options}
      multiple={multiple}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      filterSelectedOptions
      noOptionsText={noOptionsTextValue}
      renderTags={rendertags}
      renderOption={renderOption}
      defaultValue={defaultValue}
      renderInput={(params) => getRenderTextField(params)}
      {...rest}
    />
  );
};

Autocomplete.defaultProps = {
  required: true,
  style: { width: '50%', marginTop: '3px' },
  error: false,
  getOptionLabel: (option) => (option.name ? option.name : ''),
  disabled: false,
  multiple: false,
  renderTags: null,
  loadingText: dictionary.LOADING,
  onInputChange: () => {},
  placeholder: dictionary.SELECT,
};

export default Autocomplete;
