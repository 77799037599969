import styled from 'styled-components';

export const DashCardTitle = styled.h2`
  ${({ theme }) => `
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    margin-bottom: 16px;
    color: ${theme.palette.black['500']};
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  `}
`;

export const DashCardNumber = styled.span`
  ${({ theme, variant }) => `
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: ${variant === 'cash' ? '18px' : '34px'};
    line-height: 36px;
    display: block;
    margin-top: 8px;
    color: ${theme.palette.black['700']};
  `}
`;

export const DashCardCashValue = styled.span``

export const DashCardNumberTiny = styled.span`
  ${({ theme }) => `
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-top: 0;
    color: ${theme.palette.black['700']};
  `}
`;

export const DashCardNumberSmall = styled(DashCardNumber)`
  font-size: 18px;
  line-height: 26px;
  margin-top: 0;
  display: inline-block;
`;

export const DashCardSubtitle = styled(DashCardNumber)`
  ${({ theme }) => `
    font-size: 11px;
    line-height: 16px;
    text-transform: uppercase;
    margin-top: 0;
    color: ${theme.palette.black['500']};
  `}
`;

export const DashTooltipText = styled.span`
  ${({ theme }) => `
    display:block;
    color: ${theme.palette.black['700']};    
    background:#fff;
    margin-top: 7px;
  `}
`;

export const DashCardStatus = styled.a`
  font: normal 11px Open Sans;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 6px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 4px;

  ${DashTooltipText} + ${DashTooltipText} {
    margin-top: 30px;
  }

  &.--selected {
    background-color: #59577a;
  }
`;

export const DashRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DashCol = styled.div`
  flex: 1;
  overflow-x: hidden;
`;

export const DashCard = styled.div`
  ${({ theme, selected, lostSale }) => `
    width: 100%;
    height: 160px;
    background: ${
      selected ? theme.palette.black['700'] : theme.palette.misc.white
    };
    box-shadow: ${selected ? 'none' : '0px 2px 8px rgba(31, 14, 61, 0.05)'};
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 16px;
    box-sizing: border-box;

    &:hover {
      background: ${
        selected ? theme.palette.black['700'] : 'rgba(225,229,235,0.5)'
      };
      border-color: ${selected ? 'transparent' : theme.palette.black['100']};
    }

    ${DashCardTitle}, ${DashCardStatus} {
      color: ${
        selected ? theme.palette.black['50'] : theme.palette.black['500']
      }
    }

    ${DashCardNumber}, ${DashCardNumberSmall}, ${DashCardNumberTiny} {
      color: ${
        selected ? theme.palette.green['300'] : theme.palette.black['700']
      }
    }
    ${lostSale ? `
      ${DashCardNumber}, ${DashCardNumberSmall}, ${DashCardNumberTiny} {
          color: ${
            selected ? theme.palette.blue['200'] : theme.palette.black['700']
          }
        }
      }`: ``
    }

    ${DashCardSubtitle} {
      color: ${
        selected ? theme.palette.black['50'] : theme.palette.black['500']
      }
    }

    ${DashCardStatus} {
      &:hover {
        color: ${
          selected ? theme.palette.green['300'] : theme.palette.black['900']
        }
      }
    }

    @media(max-width: 960px) {
      height: auto;
      min-height: 160px;
    }
    @media(max-width: 600px) {
      ${DashRow} {
        flex: 1 0 auto;
        flex-wrap: wrap;
      }

      ${DashCol} { min-width: 50%; }
      ${DashCol}:nth-child(3) { 
        min-width: 100%;
        margin-top: 16px; 
      }
      ${DashCardNumber} { margin-top: 0; }
    }
  `}
`;
