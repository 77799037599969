import {
  GetPromotionalNegotiationPanelParams,
  GetPromotionalNegotiationStoreParams,
} from './types';
import {
  getNegotiationPanelAPI,
  getNegotiationPanelMetricsAPI,
} from './promotionalNegotiationPanelAPI';

export const getPromotionalNegotiationPanel = (
  params: Partial<GetPromotionalNegotiationPanelParams>
) => {
  return getNegotiationPanelAPI(params);
};

export const getNegotiationPanelMetrics = (
  params: Partial<GetPromotionalNegotiationPanelParams>
) => {
  return getNegotiationPanelMetricsAPI(params);
};