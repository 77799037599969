import React from 'react';
import ReactPDF from '@react-pdf/renderer';
import regularFont from './fonts/OpenSans-Regular.ttf';
import boldFont from './fonts/OpenSans-Bold.ttf';
import footerLogo from '../../../assets/logos/full-logo-black.png';
import { normalizeStockValue } from '../../../core/shared/stock';
import styles from './styles';

const { Document, Page, Text, View, Image, Font } = ReactPDF;

Font.register({
  family: 'OpenSansRegular',
  src: regularFont,
});

Font.register({
  family: 'OpenSansBold',
  src: boldFont,
});

export const ShelfBreaksReport = ({ data }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.titleContainer}>
          <View style={styles.section}>
            <Text style={styles.boldTitleText}>
              Relatório de itens em ruptura para abastecimento
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.titleText}>
              Confira se estes produtos em ruptura estão disponíveis e abasteça
              a gôndola
            </Text>
          </View>
        </View>

        <View style={styles.subTitleContainer}>
          <View style={styles.section}>
            <Text style={styles.boldTitleText}>{data.docTitle}</Text>
          </View>
        </View>

        {
          data.reportData ? (
            data.reportData.map((shelfBreak, index) => (
              <View key={index} style={styles.dataSection} wrap={false}>
                <View style={styles.rowFormSection}>
                  <View style={styles.barcodeColumn}>
                    <Image
                      src={shelfBreak.barcode}
                      style={styles.barcode}
                    />
                    <View style={styles.productEanWrapper}>
                      <Text style={styles.productEan}>
                        {shelfBreak.productEan}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.textColumn}>
                    <View style={styles.productInternalCodeWrapper}>
                      <Text style={styles.boldText}>
                        {shelfBreak.productInternalCode}
                      </Text>
                    </View>

                    <View style={styles.productNameWrapper}>
                      <Text style={styles.boldText}>
                        {shelfBreak.productName}
                      </Text>
                    </View>

                    <View style={styles.productGroupWrapper}>
                      <Text style={styles.regularText}>
                        {shelfBreak.group.toUpperCase()}
                      </Text>
                    </View>

                    <View style={styles.totalProductUnitsNeededWrapper}>
                      <Text style={styles.boldTitleText}>
                        {`QUANTIDADE: ${shelfBreak.totalProductUnitsNeeded}`}
                      </Text>
                      <Text style={{...styles.boldTitleText, ...styles.totalStockWrapper}}>
                        {`ESTOQUE: ${normalizeStockValue(shelfBreak.totalStock)}`}
                      </Text>
                      <Text style={styles.regularText}>
                        {`ÚLTIMA ATUALIZAÇÃO: ${shelfBreak.stockUpdatedAt}`}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.checkboxAndRadioColumn}>
                    <View style={styles.checkboxWrapper}>
                      <View style={styles.checkboxSquare}>
                        <Image
                          src={shelfBreak.reportStatusShelfAnswer}
                          style={styles.checkboxSquareImage}
                        />
                      </View>

                      <View style={styles.checkboxLabel}>
                        <Text style={styles.regularText}>
                          Gôndola abastecida
                        </Text>
                      </View>
                    </View>

                    <View style={styles.checkboxWrapperLastChild}>
                      <View style={styles.checkboxSquare }>
                        <Image
                          src={shelfBreak.reportStatusOutOfStockAnswer}
                          style={styles.checkboxSquareImage}
                        />
                      </View>
                      <View style={styles.checkboxLabel}>
                        <Text style={styles.regularText}>
                          Em falta no depósito
                        </Text>
                      </View>
                    </View>

                    <View style={styles.radioOptionsWrapper}>
                      <View style={styles.radioOptionsTitle}>
                        <Text style={styles.regularText}>
                          O produto está em estoque virtual?
                        </Text>
                      </View>
                    </View>

                    <View style={styles.radioListWrapper}>
                      <View style={styles.radioWrapper}>
                        <Image
                          src={shelfBreak.reportStatusIsPresentAnswer}
                          style={styles.radioButtonImage}
                        />
                      </View>
                      <View style={styles.radioLabelYes}>
                        <Text style={styles.regularText}>Sim</Text>
                      </View>
                      <View style={styles.radioWrapper}>
                        <Image
                          src={shelfBreak.reportStatusIsNotPresentAnswer}
                          style={styles.radioButtonImage}
                        />
                      </View>
                      <View style={styles.radioLabelNo}>
                        <Text style={styles.regularText}>Não</Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            ))
          ) : (
            ''
          ) 
        }

        <View fixed style={styles.footerSection}>
          <View fixed style={styles.footerLogoWrapper}>
            <Image src={footerLogo} style={styles.footerLogoImage} />
          </View>
        </View>
      </Page>
    </Document>
  );
};
