import { mainStore } from '../../core/store/mainStore';
import { createResetPasswordToken } from '../../core/services/passwordReset/passwordResetService';

export const isValidForm = email => {
  const pattern = /\S+@\S+\.\S+/;
  return email.length > 0 && pattern.test(email);
};

export const handleSubmit = async (event, email) => {
  event.preventDefault();
  mainStore.resetPassword.creatingToken = true;
  await createResetPasswordToken(email);
  mainStore.resetPassword.creatingToken = false;
};
