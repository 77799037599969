import {
  getStoreAPI,
  getStoreByIdAPI,
  postStoreAPI,
  putStoreAPI,
  deleteStoreAPI,
} from './storesAPI';
import { valueStatus } from '../../profiles';

export const getStoresByRetail = (retailId, status) => {
  return getStoreAPI(retailId, status);
};

export const getActiveStoresByRetail = (retailId) => {
  return getStoreAPI(retailId, valueStatus.ACTIVE);
};

export const getStoreOrderByStoreCode = (retailId) => {
  return getStoreAPI(retailId, valueStatus.ACTIVE, 0, 9999, 'storeCode');
};

export const getStoreById = (id) => {
  return getStoreByIdAPI(id);
};

export const getStore = (retailId, status) => {
  return getStoreAPI(retailId, status);
};

export const postStore = (data) => {
  return postStoreAPI(data);
};

export const putStore = (data) => {
  return putStoreAPI(data);
};

export const deleteStore = (data) => {
  return deleteStoreAPI(data);
};
