import { AxiosResponse } from 'axios';
import API from '../api/config';
import { exist } from '../../validation';
import * as routes from '../api/routes';
import {
  PromotionalSpotAddressingDto,
  UpdatePromotionalSpotAddressingDto,
  GetPromotionalSpotAddressingsParams,
  CreatePromotionalSpotAddressingParams,
  GetPromotionalSpotAddressingsQueryDto,
} from './types';
import { AddressingDto } from '../../types/promotional-spot/promotional-addressing.dto';

export const getAddressingsAPI = ({
  limit,
  offset,
  spotId,
  storeId,
  retailId,
  spotName,
  spotType,
  physicalAddressing,
  periodStartAt
}: GetPromotionalSpotAddressingsParams): Promise<
  AxiosResponse<GetPromotionalSpotAddressingsQueryDto>
> => {
  const params = new URLSearchParams({
    limit: ''+limit,
    offset: ''+offset, 
  });

  if (spotId) {
    params.append('spotId', spotId);
  }
  if (spotType) {
    params.append('spotType', spotType);
  }
  if (storeId) {
    params.append('storeId', storeId);
  }
  if (retailId) {
    params.append('retailId', retailId);
  }
  if (physicalAddressing) {
    params.append('physicalAddressing', physicalAddressing);
  }
  if (spotName) {
    params.append('spotName', spotName);
  }
  if (periodStartAt) {
    params.append('periodStartAt', periodStartAt.toString());
  }

  const url = `${routes.API_PROMOTIONAL_CALENDAR_ADDRESSES}?${params.toString()}`;

  return API.get(url);
};

export const getAddressingByIdAPI = (
  addressingId: string
): Promise<AxiosResponse<AddressingDto>> => {
  const url = `${routes.API_PROMOTIONAL_CALENDAR_ADDRESSES}/${addressingId}`;
  return API.get(url);
};

export const createAddressingAPI = (
  data: Partial<CreatePromotionalSpotAddressingParams>
): Promise<AxiosResponse<PromotionalSpotAddressingDto>> => {
  return API.post(routes.API_PROMOTIONAL_CALENDAR_ADDRESSES, data);
};

export const updateAddressingAPI = (
  data: Partial<UpdatePromotionalSpotAddressingDto>
): Promise<AxiosResponse<PromotionalSpotAddressingDto>> => {
  const url = `${routes.API_PROMOTIONAL_CALENDAR_ADDRESSES}/${data.id}`;
  return API.put(url, data);
};

export const removeAddressingAPI = (
  id: string
): Promise<AxiosResponse<PromotionalSpotAddressingDto>> => {
  const url = `${routes.API_PROMOTIONAL_CALENDAR_ADDRESSES}/${id}`;
  return API.delete(url);
};
