import React from 'react';
import { DashCardStatus } from '../../../../../components/Cards';
import CardDetailItem from './CardDetailItem/';
import RemoveFilter from './CardDetailItem/RemoveFilter';

const cardDetail = props => {
    const { filterIndex, filter, clicked, reset, detail } = props;
    
    return (
        <DashCardStatus 
            className={ filter === filterIndex ? '--selected': ''} 
            onClick={ clicked }
            >
            <CardDetailItem 
                item={ detail.item }
                value={ detail.value }
                sellout={ detail.sellout }
            />
            <RemoveFilter 
                visible={ filter === filterIndex }
                clicked={ reset } 
            />
        </DashCardStatus>
    );
}

export default cardDetail;