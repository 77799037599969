import React from 'react';
import PropTypes from 'prop-types';
import { FormInputDropdown } from '../FormInputDropdown';

const FormInputDropdownCPF = ({
  children,
  id,
  name,
  autoComplete,
  ...props
}) => {
  return (
    <FormInputDropdown
      {...props}
      mask="999.999.999-99"
      large
      id={id}
      name={name}
      autoComplete={autoComplete}
      type="text"
    >
      {children}
    </FormInputDropdown>
  );
};

FormInputDropdownCPF.propTypes = {
  children: PropTypes.element,
  id: PropTypes.string,
  name: PropTypes.string,
  autoComplete: PropTypes.string,
};

FormInputDropdownCPF.defaultProps = {
  children: null,
  id: 'cpf',
  name: 'cpf',
  autoComplete: 'off',
};

export { FormInputDropdownCPF };
