import { suspensionReason } from '../../core/constants/suspensionReason';
import { SuspensionReason } from '../../core/types/suspension-reason.enum';
import { ProductSuspension } from '../../core/types/product-suspension.type';
import { 
  getSupplyStatusAndProblemsDescription, 
  getformattedCurrency,getFormattedDate 
} from '../../core/shared/missions';

export type ProductSuspensionFilter = {
  reason: SuspensionReason[];
  limit: number;
  offset: number;
}

export type ProductSuspensionItemDTO = {
  product: ProductSuspension['product'];
  skuNameAndCode: string;
  createdAt: string;
  storeNameAndCode: string;
  daysWithoutSale: number;
  shoppersPerDay: number;
  avgRevenue: string;
  statusAndProblemsDescription: string;
  expiresOn: string;
}

export type GetProductSuspensionsDTO = {
  count: number;
  items: ProductSuspension[];
}

export const defaultFilters: ProductSuspensionFilter = {
  reason: [
    suspensionReason.SUSPECT_CONFIRMED, 
    suspensionReason.SUSPECT_NOT_CONFIRMED,
  ] as SuspensionReason[],
  limit: 10,
  offset: 0,
};

export const getProductSuspensionItemDTO = (productSuspension: ProductSuspension): ProductSuspensionItemDTO => {
  return {
    product: productSuspension.product,
    skuNameAndCode: `(${productSuspension.product.code}) ${productSuspension.product.name}`,
    createdAt: getFormattedDate(productSuspension.mission.check.createdAt),
    storeNameAndCode: `(${productSuspension.store.code}) ${productSuspension.store.name}`,
    daysWithoutSale: productSuspension.mission.check.daysWithoutSale ?? 0,
    shoppersPerDay: productSuspension.mission.check.shoppersPerDay ?? 0,
    avgRevenue: getformattedCurrency(productSuspension.mission.check.avgRevenue),
    statusAndProblemsDescription: getSupplyStatusAndProblemsDescription(productSuspension.mission),
    expiresOn: getFormattedDate(productSuspension.expiresOn),
  };
}