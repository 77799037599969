import { PromotionalSpotType } from "../../../core/types/promotional-spot/promotional-spot-type.enum";

export enum ModalType {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export type FormFilters = {
  store?: StoreDto | null;
  retailId?: string;
  type?: string | null;
  spotName?: string | null;
}

export type FetchDataParams = {
  limit: number; 
  offset: number;
  storeId?: string;
  spotName?: string;
  spotType?: PromotionalSpotType;
  physicalAddressing: string;
  retailId?: string;
}

export type Addressings = {
  store: string,
  promotionalSpotType: string,
  promotionalSpotName: string,
  addressing: string
};

export type AddressingEntity = {
  store: string,
  storeSector: string,
  storeArea: string,
  physicalAddress: string,
  id: string
};

// deletado quando migração for merged
export type StoreDto = {
  id: string;
  name: string;
  storeCode: string;
  cep: string;
  state: string;
  city: string;
  address: string;
  number: string;
  neighborhood: string;
  retailId: string;
  retailName: string;
};

export type ProductGroup = {
  id: string;
  name: string;
  retailId: string;
}
