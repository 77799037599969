export type PromotionalNegotiationPanelMetricsExecutionsDto = {
  pending: number;
  expired: number;
  executed: number;
  total: number;
};

export type PromotionalNegotiationPanelMetricsAddressingsDto = {
  total: number;
  avaliable: number;
};
export type PromotionalNegotiationPanelMetricsDto = {
  executions: PromotionalNegotiationPanelMetricsExecutionsDto;
  addressings: PromotionalNegotiationPanelMetricsAddressingsDto;
};

export const promotionalNegotiationPanelMetricsInitialValue = {
  addressings: {
    avaliable: 0,
    total: 0,
  },
  executions: {
    executed: 0,
    expired: 0,
    pending: 0,
    total: 0,
  },
};
