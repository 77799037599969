import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { DialogActions, DialogContent, Chip, } from '@material-ui/core';
import * as R from 'ramda';
import TextField from '../../../../components/InputTextField';
import CancelButton from '../../../../components/Buttons/Cancel';
import Autocomplete from '../../../../components/Autocomplete';
import { MemorizedSearchField } from '../../../../components/SearchField';
import { PromotersTable } from '../../components/PromotersTable/PromotersTable';
import { serializeCpfAndNameSearch, utils } from '../../../../core/utils';
import { exist } from '../../../../core/validation';
import {
  addPromoterToAgreement, 
  updatePromoterByAgreement,
} from '../../../../core/services/agreements/agreementsService';
import {
  getPromoterUsers,
  getPromotersByAgreement, 
  getSuppliersByAgreement,
} from '../../../../core/services/users/usersService';
import { profileCheck } from '../../../../core/profiles';
import {
  trackAgreementPromoterEdit, 
  trackAgreementPromoterInactivated, 
  trackAgreementPromoterReleased,
} from '../../../../core/mixpanel/mixPanelEvents';
import { mainStore } from '../../../../core/store/mainStore';
import { useDebounce } from '../../../../core/hooks';
import { loadAgreements, promoterAlreadyExistInArray, } from '../../functions';
import { ACTIVE, PENDING, RELEASED, SUSPENDED, } from '../../../../core/constants/status';
import * as dictionary from '../../../../core/constants/dictionary';

export const EditPromoters = ({
  closeModalCallback,
  currentAgreement,
  nextStepCallback,
  handleOpenModal,
}) => {
  const [promotersOptions, setPromotersOptions] = useState([]);
  const [promoters, setPromoters] = useState([]);
  const [promotersIds, setPromotersIds] = useState([]);
  const [invites] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [suppliersOpts, setSuppliersOpts] = useState([]);
  const [nameOrCpfPart, setNameOrCpfPart] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [toolbarInputFocus, setToolbarInputFocus] = useState(false);
  const [allPromotersQuery, setAllPromotersQuery] = useState("");

  const loadFirstData = useCallback(async () => {
    const promotersByAgreement = await getPromotersByAgreement(
      currentAgreement.id, 
      rowsPerPage * page, 
      rowsPerPage, 
      serializeCpfAndNameSearch(nameOrCpfPart)
    );
    const fullRangePromotersByAgreement = await getPromotersByAgreement(
      currentAgreement.id, 
      0, 
      promotersByAgreement.count
    );
    setTotal(promotersByAgreement.count);
    setPromoters(promotersByAgreement.items);
    setPromotersIds(fullRangePromotersByAgreement.items.map(item => item.user.id));
  }, [page, rowsPerPage, currentAgreement.id, nameOrCpfPart]);

  const reloadPromoterOptions = useCallback(async (queryParam = allPromotersQuery) => {
    const allPromotersData = await getPromoterUsers(mainStore.loggedUser.retailId0, 0, 20, queryParam);
    const promotersNewOptions = allPromotersData.items.filter(
      option => !promotersIds.includes(option.id),
    );
    setPromotersOptions(promotersNewOptions);
  }, [allPromotersQuery, promotersIds]);

  const debouncedOnSearch = useDebounce(async (inputValue) => {
    const searchTerm = String(inputValue[0]);
    setPage(0);
    setNameOrCpfPart(searchTerm);
    
    if (! exist(searchTerm)) {
      setPromotersOptions([]);
    }
  }, 500);

  const debouncedOnSearchInput = useDebounce(async (inputValue) => {
    inputValue = String(inputValue);
    setAllPromotersQuery(inputValue);
    
    if (exist(inputValue)) {
      reloadPromoterOptions(inputValue);
    } else {
      setPromotersOptions([]);
    }   
  }, 500);

  const loadPromoters = useCallback(() => {
    (async () => {
      const promotersByAgreement = await getPromotersByAgreement(
        currentAgreement.id, 
        rowsPerPage * page, 
        rowsPerPage,
        serializeCpfAndNameSearch(nameOrCpfPart)
      );
      setPromoters(promotersByAgreement.items)
    })();
  }, [page, rowsPerPage, nameOrCpfPart, currentAgreement.id]);

  const loadSuppliers = async () => {
    const selectedSuppliers = await getSuppliersByAgreement(
      currentAgreement.id,
      ACTIVE,
    );
    setSuppliers(selectedSuppliers);
    setSuppliersOpts(selectedSuppliers);
  }

  const handleOpenFrequency = useCallback(() => {
    handleOpenModal(dictionary.EDIT_FREQUENCY, currentAgreement);
  }, [currentAgreement, handleOpenModal]);

  const handleUpdatePromoters = useCallback(
    async value => {
      try {
        mainStore.requestLoading = true;
        await addPromoterToAgreement(currentAgreement.id, value.id);
        await loadPromoters();
        await reloadPromoterOptions();

        if (profileCheck.isSalesRep()) {
          utils.openLinkSnackBar(
            dictionary.SUCCESS,
            dictionary.ADD_PROMOTER_SUCCESS,
            dictionary.SEE_FREQUENCY_VISITS,
            handleOpenFrequency,
          );
        } else {
          utils.openSnackBar(
            dictionary.SUCCESS,
            dictionary.ADD_PROMOTER_SUCCESS,
          );
        }

        trackAgreementPromoterEdit(
          {
            internalCode: currentAgreement.internalCode,
          },
          invites.length,
          promoters.length + 1,
          currentAgreement.supplierNames.split(','),
        );
      } catch (error) {
        if (
          R.hasPath(['response', 'data'], error) &&
          R.propEq(
            'message',
            'Inactive users can not be linked to agreements',
          )(error.response.data)
        ) {
          utils.openSnackBar(
            'error',
            'Este promotor esta inativo por isso não é possível adicionar ele ao acordo',
          );
        } else {
          utils.openSnackBar(dictionary.ERROR, dictionary.UNKNOWN_ERROR);
        }
      }
      mainStore.requestLoading = false;
    },
    [
      currentAgreement,
      handleOpenFrequency,
      invites,
      loadPromoters,
      promoters,
      reloadPromoterOptions,
    ],
  );

  useEffect(() => {
    (async function load() {
      mainStore.requestLoading = true;
      await loadFirstData();
      await loadSuppliers();
      mainStore.requestLoading = false;
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prometersViewData = useMemo(() => {
    const invitesView = invites.map(invite => {
      return {
        isInvite: true,
        email: invite.email,
        id: invite.id,
      };
    });
    
    const promotersView = promoters.map(promoter => {
      return {
        isInvite: false,
        email: promoter.user.email,
        id: promoter.user.id,
        name: promoter.user.name,
        cpf: promoter.user.cpf,
        status: promoter.status,
        promoterId: promoter.id,
      };
    });
    
    return invitesView.concat(promotersView);
  }, [invites, promoters]);

  const promoterAlreadyExist = useCallback(
    value => {
      return promoterAlreadyExistInArray(prometersViewData, 'id', value.id);
    },
    [prometersViewData],
  );

  const handleCloseSearch = useCallback(
    value => {
      if (exist(value)) {
        if (promoterAlreadyExist(value)) {
          utils.openSnackBar(
            dictionary.ERROR,
            dictionary.OPTION_ALREADY_SELECTED,
          );
        }
      }
    },
    [promoterAlreadyExist],
  );

  const verifyPromoterAdd = useCallback(async value => {
    if (value) {
      if (promoterAlreadyExist(value)) {
        utils.openSnackBar(
          dictionary.ERROR,
          dictionary.OPTION_ALREADY_SELECTED,
        );
      } else {
        await handleUpdatePromoters(value);
      }
    }
  },
  [handleUpdatePromoters, promoterAlreadyExist],
);

  const handleRemovePromoters = useCallback(async value => {
    try {
      mainStore.requestLoading = true;
      const status =
        value.status === SUSPENDED || value.status === PENDING
        ? RELEASED
        : SUSPENDED;

      await updatePromoterByAgreement(currentAgreement.id, value.promoterId, { status, });

      const trackDto = {
        suppliers: currentAgreement.supplierNames.split(','),
        salesRepEmail: value.email,
        code: currentAgreement.internalCode,
      };

      if (status === RELEASED) {
        utils.openSnackBar('success', 'O acesso do promotor foi liberado');
        trackAgreementPromoterReleased(trackDto);
      } else {
        utils.openSnackBar('success', 'O acesso do promotor foi suspenso');
        trackAgreementPromoterInactivated(trackDto);
      }

      await loadFirstData();
    } 
    catch (error) {
      if (
        R.hasPath(['response', 'data'], error) &&
        R.propEq(
          'message',
          'Inactive users can not be approved into linked  agreements',
        )(error.response.data)
      ) {
        utils.openSnackBar(
          'error',
          'Não é possível liberar um promotor inativo em um acordo',
        );
      } else {
        utils.openSnackBar(dictionary.ERROR, dictionary.UNKNOWN_ERROR);
      }
    } 
    finally {
      mainStore.requestLoading = false;
    }
  },
  [currentAgreement, loadFirstData],
);

  const getOptionLabelSuppliers = option => {
    if (Object.keys(option).length === 0) {
      return '';
    }
    const name = option.internalName ? option.internalName : option.name;
    const code = option.internalCode ? ` (${option.internalCode})` : '';
    return `${name}${code}`;
  };

  const getChipsSuppliers = (value, getTagProps, closeInput) => {
    return value.map((option, index) => {
      const name = option.internalName ? option.internalName : option.name;

      return (
        <Chip
          key={index}
          label={name}
          {...getTagProps({ index })}
          disabled={closeInput}
        />
      );
    });
  };
  const emptyMessage = exist(nameOrCpfPart) ? null : dictionary.TYPE_A_FEW_CHARACTERS

  useEffect(() => {
    const fetchPromoters = async () => {
      const limit = rowsPerPage;
      const offset = page * rowsPerPage;
      const promotersByAgreement = await getPromotersByAgreement(
        currentAgreement.id, 
        offset, 
        limit,
        serializeCpfAndNameSearch(nameOrCpfPart)
      );
      setTotal(promotersByAgreement.count);
      setPromoters(promotersByAgreement.items);
    }
    fetchPromoters();
  }, [page, rowsPerPage, currentAgreement.id, nameOrCpfPart]);

  useEffect(() => {
    const shouldFocus = nameOrCpfPart.length > 0;
    setToolbarInputFocus(shouldFocus);
  }, [nameOrCpfPart]);

  return (
    <>
      <DialogContent style={{ minHeight: '500px' }}>
        <div className="form-two-columns">
          <TextField
            label={dictionary.RETAIL_NAME}
            disabled
            value={currentAgreement.retailName}
          />
          <TextField
            label={dictionary.AGREEMENT_CODE}
            disabled
            value={currentAgreement.internalCode}
          />
        </div>

        <div className="form-two-columns">
          <Autocomplete
            value={suppliers}
            renderTags={getChipsSuppliers}
            style={{ marginRight: 0, width: '100%' }}
            multiple
            getOptionLabel={getOptionLabelSuppliers}
            label={dictionary.AGREEMENT_SUPPLIERS}
            disabled
            options={suppliersOpts}
          />
        </div>

        <div>
          <MemorizedSearchField
            label={dictionary.PROMOTERS}
            value={allPromotersQuery}
            options={promotersOptions}
            noOptionsText={emptyMessage}
            getOptionLabel={opt => (opt ? `(${opt.cpf}) ${opt.name}` : '')}
            handleClose={handleCloseSearch}
            handleUpdate={value => verifyPromoterAdd(value)}
            onInputChange={value => debouncedOnSearchInput(value)}
          />
        </div>

        <div className="reverse-table">
          <PromotersTable
            key={`${prometersViewData.length}${nameOrCpfPart}`}
            data={prometersViewData}
            count={total}
            pageIndex={page}
            rowsPerPage={rowsPerPage}
            searchTextValue={nameOrCpfPart}
            toolbarInputFocus={toolbarInputFocus}
            handleRemove={handleRemovePromoters}
            onChangePageHandler={page => setPage(page)}
            onChangeRowsPerPageHandler={(value) => {
              setPage(0);
              setRowsPerPage(value);
            }} 
            onSearchChangeHandler={value => debouncedOnSearch(value)}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <CancelButton
          label={dictionary.CLOSE}
          onClick={async () => {
            await loadAgreements();
            if (nextStepCallback) {
              nextStepCallback();
              utils.openLinkSnackBar(
                dictionary.SUCCESS,
                dictionary.AGREEMENT_APPROVED_PROMOTERS_ADDED,
                dictionary.SEE_FREQUENCY_VISITS,
                handleOpenFrequency,
              );
            } else {
              closeModalCallback();
            }

            mainStore.requestLoading = false;
          }}
        />
      </DialogActions>
    </>
  );
};
