import React, { ChangeEvent, useState } from 'react';
import PropTypes from 'prop-types';
// @ts-ignore
import { Button, Text, Row, RadioButton, TextArea } from '@involves/violin';
import { sendEvaluation } from '../../../../core/services/visits/visitsService';
import { utils } from '../../../../core/utils';
import { getTime } from '../../../../core/constants/dates';
import { StarsRate } from '../../../../components/StarsRate';
import {
  StyledModal,
  StyledContent,
  StyledNameContent,
  StyledInlineGridRow,
  StyledSubtitle,
  StyledRadioButtonsGrid,
} from './style';
import { Visit } from '../../utils';

type Props = {
  open: boolean;
  visit: Visit;
  onClose: Function;
}

const EvaluationModal = ({ open, onClose, visit }: Props) => {
  const [score, setScore] = useState(visit.ratingScore);
  const [textAreaValue, setTextAreaValue] = useState(visit.ratingDescription);
  const [radiosValue, setRadiosValue] = useState(visit.ratingReason);
  const preview = visit.ratingScore ? visit.ratingScore > 0 : false;
  const disabled = !score || preview;

  const handleScoreChange = (newScore: number) => {
    setScore(newScore);
  };

  const handleTextAreaChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTextAreaValue(event.target.value);
  };

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRadiosValue(Number(event.target.value));
  };

  const handleClose = (event?: ChangeEvent<HTMLInputElement>, reason?: string) => {
    setScore(0);
    setTextAreaValue('');
    setRadiosValue(null);
    onClose();
  };

  const handleSave = async () => {
    try {
      await sendEvaluation({
        score,
        description: textAreaValue,
        reason: radiosValue,
        visitId: visit.id,
      });
      utils.openSnackBar('success', 'A avaliação foi salva com sucesso');
      handleClose();
    } catch (error) {
      console.error(error);
      utils.openSnackBar('error', 'Não vou possível salvar sua avaliação.');
    }
  };

  return (
    <StyledModal
      open={open}
      title="Avaliação de visitas"
      actions={[
        <Button secondary onClick={handleClose}>
          {preview ? 'Voltar' : 'Cancelar'}
        </Button>,
        <Button
          primary
          onClick={handleSave}
          disabled={disabled}
          hidden={preview}
        >
          Enviar avaliação
        </Button>,
      ]}
      onClose={(event: ChangeEvent<HTMLInputElement>, reason: string) => {
        if (reason !== 'backdropClick') {
          handleClose(event, reason);
        }
      }}
    >
      <StyledContent>
        <StyledNameContent>
          <StyledSubtitle corporate>{visit.supplierNames}</StyledSubtitle>
          <Text>
            {visit.promoterName}
            {` - Check-in: ${getTime(visit.checkinAt, visit.timezone)}`}
            {` / Check-out: ${getTime(visit.checkinAt, visit.timezone)}`}
          </Text>
        </StyledNameContent>

        <div>
          <StyledInlineGridRow>
            <StyledSubtitle>Como foi a visita?</StyledSubtitle>
            <StarsRate
              value={score}
              disabled={preview}
              onChange={handleScoreChange}
            />
          </StyledInlineGridRow>
        </div>

        <div>
          <StyledInlineGridRow>
            <StyledSubtitle invisible={disabled}>
              {score === 5 ? 'O que foi mais legal?' : 'O que não foi legal?'}
            </StyledSubtitle>
            <StyledRadioButtonsGrid>
              <RadioButton
                name="option"
                label="Tempo de visita"
                value={0}
                disabled={disabled}
                checked={radiosValue === 0}
                onChange={handleRadioChange}
              />
              <RadioButton
                name="option"
                label="Organização da gôndola"
                value={1}
                disabled={disabled}
                checked={radiosValue === 1}
                onChange={handleRadioChange}
              />
              <RadioButton
                name="option"
                label="Controle de validade"
                value={2}
                disabled={disabled}
                checked={radiosValue === 2}
                onChange={handleRadioChange}
              />
              <RadioButton
                name="option"
                label="Outros"
                value={3}
                disabled={disabled}
                checked={radiosValue === 3}
                onChange={handleRadioChange}
              />
            </StyledRadioButtonsGrid>
          </StyledInlineGridRow>
        </div>

        <Row>
          <TextArea
            placeholder="Quer comentar algo mais?"
            value={textAreaValue}
            onChange={handleTextAreaChange}
            disabled={disabled}
          />
        </Row>
      </StyledContent>
    </StyledModal>
  );
};

EvaluationModal.propTypes = {
  visit: PropTypes.shape({
    ratingDescription: PropTypes.string,
    ratingReason: PropTypes.number,
    ratingScore: PropTypes.number,
  }),
};

EvaluationModal.defaultProps = {
  visit: {
    ratingDescription: '',
    ratingReason: null,
    ratingScore: 0,
  },
};

export { EvaluationModal };
