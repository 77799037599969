import React from 'react';
import { Chip, TextField } from '@material-ui/core';
import { AutocompleteRenderInputParams } from '@material-ui/lab';
import { RetailEntityType } from '../../../core/types/retail/retail-entity.type';
import * as dictionary from '../../../core/constants/dictionary';
import Autocomplete from '../../Autocomplete';

type Props = {
  id?: string;
  label?: string;
  disabled?: boolean;
  value?: RetailEntityType;
  options?: RetailEntityType[];
  size?: 'small' | 'medium' | 'large';
  onChange?: (value: RetailEntityType) => void;
  style?: any;
  variant?: string;
  required?: boolean;
};

export const FormRetailField = ({
  value,
  options = [],
  id = 'retail',
  size = 'small',
  disabled = false,
  label = dictionary.RETAIL,
  onChange,
  style = {},
  variant = undefined,
  required = false,
  ...restProps
}: Props) => {
  return (
    <Autocomplete
      disabled={disabled}
      id={id}
      size={size}
      value={value}
      options={options}
      getOptionLabel={(retail: RetailEntityType) => retail.name}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} label={label} variant="outlined" />
      )}
      renderTags={(
        value: RetailEntityType[],
        getTagProps: Function,
        closeInput: boolean
      ) =>
        value.map((option: RetailEntityType, index: number) => (
          <Chip
            key={index}
            label={option.name}
            {...getTagProps({ index })}
            disabled={closeInput}
          />
        ))
      }
      onChange={(_: never, value: RetailEntityType) => {
        if (value && onChange) {
          onChange(value);
        }
      }}
      style={style}
      required={required}
      {...restProps}
    />
  );
};
