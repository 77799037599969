import React from 'react';
import { Redirect } from 'react-router';
import { mainStore } from './store/mainStore';
import { userProfile } from './profiles';
import { RetailFeature } from './services/retailFeatures/types';
import * as routes from './constants/routes';

export const LOGGED_USER_KEY = '@InvolvesDoor:user';
export const TOKEN_KEY = '@InvolvesDoor:token';

export const isLoggedUserAuthorizedInRoute = ({ pathname }) => {
  const loggedUserProfile = mainStore.loggedUser
    ? mainStore.loggedUser.profile
    : null;

  switch (pathname) {
    case routes.AGREEMENTS:
      return [
        userProfile.SUPER_ADMIN,
        userProfile.RETAIL_ADMIN,
        userProfile.SALES_REP,
        userProfile.BUYER,
        userProfile.PROMOTER_APPROVER,
      ].includes(loggedUserProfile);

    case routes.PROMOTERS:
    case routes.PROMOTERS_SUSPENDED:
    case routes.PROMOTERS_LIBERATE:
      return [
        userProfile.SUPER_ADMIN,
        userProfile.RETAIL_ADMIN,
        userProfile.BUYER,
        userProfile.SALES_REP,
        userProfile.PROMOTER_APPROVER,
        userProfile.STORE_MANAGER,
      ].includes(loggedUserProfile);

    case routes.PROMOTERS_PENDENT:
      return [
        userProfile.SUPER_ADMIN,
        userProfile.RETAIL_ADMIN,
        userProfile.BUYER,
        userProfile.SALES_REP,
        userProfile.PROMOTER_APPROVER,
        userProfile.STORE_MANAGER,
      ].includes(loggedUserProfile);

    case routes.PROMOTERS_REGISTER:
      return [
        userProfile.SUPER_ADMIN,
        userProfile.RETAIL_ADMIN,
        userProfile.BUYER,
        userProfile.SALES_REP,
        userProfile.PROMOTER_APPROVER,
        userProfile.STORE_MANAGER,
      ].includes(loggedUserProfile);
      
    case routes.USERS:
    case routes.STORES:
    case routes.SUPPLIERS:
      return [userProfile.SUPER_ADMIN, userProfile.RETAIL_ADMIN].includes(
        loggedUserProfile
      );

    case routes.RETAILS:
      return userProfile.SUPER_ADMIN === loggedUserProfile;

    case routes.VISITS:
    case routes.VISITS_HISTORY:
      return [
        userProfile.SUPER_ADMIN,
        userProfile.RETAIL_ADMIN,
        userProfile.STORE_MANAGER,
        userProfile.SECURITY,
      ].includes(loggedUserProfile);

    case routes.VISITS_AUTO_CHECK_IN:
      return [userProfile.STORE_MANAGER, userProfile.SECURITY].includes(
        loggedUserProfile
      );

    case routes.EXPIRATION_PANEL:
      return (
        userProfile.SUPER_ADMIN === loggedUserProfile ||
        (mainStore.loggedUser?.features?.includes(
          RetailFeature.PRODUCT_VALIDITY
        ) &&
          [
            userProfile.RETAIL_ADMIN,
            userProfile.BUYER,
            userProfile.SALES_REP,
            userProfile.STORE_MANAGER,
          ].includes(loggedUserProfile))
      );

    case routes.RESTOCK_REPORTS:
      return [
        userProfile.BUYER,
        userProfile.RESTOCKER,
        userProfile.SUPER_ADMIN,
        userProfile.RETAIL_ADMIN,
        userProfile.STORE_MANAGER,
      ].includes(loggedUserProfile);

    case routes.PROMOTIONAL_SPOTS_GROUP:
      return (
        userProfile.SUPER_ADMIN === loggedUserProfile ||
        (
          mainStore.loggedUser?.features?.includes(RetailFeature.PROMOTIONAL_SPOT) &&
          [
            userProfile.BUYER,
            userProfile.RETAIL_ADMIN, 
            userProfile.STORE_MANAGER,
          ].includes(loggedUserProfile)
        )
      );
    case routes.NEGOTIATIONS_PANEL:
      return (
        userProfile.SUPER_ADMIN === loggedUserProfile ||
        (
          mainStore.loggedUser?.features?.includes(RetailFeature.PROMOTIONAL_SPOT) &&
          [
            userProfile.BUYER,
            userProfile.RETAIL_ADMIN, 
          ].includes(loggedUserProfile)
        )
      );

    case routes.ADDRESSINGS:
    case routes.PROMOTIONAL_SPOTS:
    case routes.PROMOTIONAL_CALENDARS:
      return (
        userProfile.SUPER_ADMIN === loggedUserProfile ||
        (
          userProfile.RETAIL_ADMIN === loggedUserProfile &&
          mainStore.loggedUser?.features?.includes(RetailFeature.PROMOTIONAL_SPOT) 
        )
      );

    case routes.LOGIN:
    case routes.RESTOCK:
    case routes.RESTOCK_PANEL:
    default:
      return true;
  }
};

export const setToken = (token) => {
  mainStore.token = token;
  if (token) {
    localStorage.setItem(TOKEN_KEY, token);
  } else {
    localStorage.removeItem(TOKEN_KEY);
  }
};

export const setLoggedUser = (loggedUser) => {
  mainStore.loggedUser = loggedUser;
  if (loggedUser) {
    localStorage.setItem(LOGGED_USER_KEY, JSON.stringify(loggedUser));
  } else {
    localStorage.removeItem(LOGGED_USER_KEY);
  }
};

export const redirectToHome = () => {
  if (!mainStore.loggedUser) {
    return <Redirect to={routes.AGREEMENTS} />;
  }
  
  if (mainStore?.loggedUser?.profile === userProfile.RESTOCKER) {
    return <Redirect to={routes.RESTOCK_REPORTS} />;
  }

  if (mainStore?.loggedUser?.profile === userProfile.STORE_MANAGER) {
    return <Redirect to={routes.VISITS} />;
  }

  return <Redirect to={routes.AGREEMENTS} />;
};

export const publicRoutes = [
  routes.LOGIN,
  routes.REGISTER,
  routes.CREATE_PASSWORD_RESET_TOKEN,
  routes.PRIVACITY_POLICY,
  routes.PROMOTER_TERMS_OF_USE,
  routes.SALESREP_TERMS_OF_USE,
  routes.CHANGE_PASSWORD_URL,
  routes.VISITS_AUTO_CHECK_IN,
  routes.CHANGE_PASSWORD,
];

export const isPublicRoute = (route) => {
  if (route.match(/AutoCheckin/)) {
    return true;
  }

  return publicRoutes.find((regex) => new RegExp(regex).test(route));
};
