import React, { ReactNode } from 'react';
import { Grid, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import Vector from '../../components/Vector';
import CancelButton from '../../components/Buttons/Cancel';
import ModalContainer from '../Modal/Container';
import { CONTINUE } from '../../core/constants/dictionary';
import classes from './WarningModal.module.scss';

type Props = {
  isOpen: boolean;
  width?: number; 
  children: ReactNode;
  notificationText?: string | JSX.Element;
  onCloseModalHandler: Function;
};

export const WarningModal = ({ 
  isOpen, 
  width,
  children, 
  notificationText,
  onCloseModalHandler,
}: Props) => (
  <ModalContainer
    isOpen={isOpen}
    width={width}
    closeModalCallback={onCloseModalHandler}
  >
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.wrapper}
    >
      <Grid item className={classes.warningIcon}>
        <Vector.WarningStore />
      </Grid>
      { children }
      {
        notificationText && (
          <Grid 
            container 
            alignItems="center"
            justifyContent="center"
            className={classes.notificationGrid}
          >
            <WarningIcon className={classes.notificationIcon} />
            <Typography className={classes.notificationText}>
              { notificationText }
            </Typography>
          </Grid>
        )
      }
      <Grid 
        container
        direction="row"
        justifyContent="flex-end"
        className={classes.footer}
      >
        <CancelButton 
          label={CONTINUE} 
          onClick={onCloseModalHandler} 
        />
      </Grid>
    </Grid>
  </ModalContainer>
);

export default WarningModal;
