import axios from 'axios';
import { signOutUser } from '../../../components/SideBar/utils';
import * as routes from '../../constants/routes';
import { 
  axiosDefaultConfig,
  useDefaultInterceptorRequest, 
} from './axiosConfig';

export const useCustomInterceptorResponse = (error) => {
  if (error.message === 'Network Error') {
    signOutUser();
    window.location.href = `/#${routes.LOGIN}`;
  }
  return Promise.reject(error);
}

const customAPI = axios.create(axiosDefaultConfig);

customAPI.interceptors.request.use(
  async config => useDefaultInterceptorRequest(config),
  error => Promise.reject(error),
);

customAPI.interceptors.response.use(
  async response => response,
  async error => useCustomInterceptorResponse(error),
);

export default customAPI;
