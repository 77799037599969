import React from 'react';
import WelcomePage from '../../../../components/WelcomePage';
import * as dictionary from '../../../../core/constants/dictionary';

type Props = {
  onClickButtonHandler: Function;
}

const CreatePromotionalSpotPage = ({ onClickButtonHandler }: Props) => {
  return (
    <WelcomePage
      textButton={dictionary.NEW_PROMOTIONAL_SPOT}
      handleButton={onClickButtonHandler}
    >
      <span>
        {dictionary.CREATE_NEW_PROMOTIONAL_SPOT_PART1}
        <strong>{dictionary.NEW_PROMOTIONAL_SPOT}</strong>
        {dictionary.CREATE_NEW_PROMOTIONAL_SPOT_PART2}
      </span>
    </WelcomePage>
  );
}

export default CreatePromotionalSpotPage;
