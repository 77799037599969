import React from 'react';
import SearchTable from '../../components/Tables/SearchTable';
import { EmptyDataMessageRow } from '../../components/Tables/DefaultTable';
import { getStringValueWith2Digits } from '../../core/constants/dates';
import * as dictionary from '../../core/constants/dictionary';
import { getRestockIconStatus, getStatusValue } from './functions';

export const RestockReportsTable = ({ data }) => {
  return (
    <SearchTable
      data={data}
      columns={[
        {
          title: dictionary.RESTOCK_HOUR,
          field: 'createdAt',
          render: rowData => {
            const date = new Date(rowData.createdAt);
            return `${getStringValueWith2Digits(
              date.getHours(),
            )}:${getStringValueWith2Digits(date.getMinutes())}`;
          },
        },
        { title: dictionary.PRODUCT_NAME, field: 'productName' },
        {
          title: dictionary.AVAILABILITY,
          field: 'shelfStatus',
          lookup: {
            0: dictionary.LOW_STOCKED,
            1: dictionary.IN_SHELF_BREAK,
          },
        },
        {
          title: dictionary.QAUNTITY,
          field: 'totalProductUnitsNeeded',
        },
        {
          title: dictionary.RESTOCK_STATUS,
          field: 'status',
          // eslint-disable-next-line react/display-name
          render: rowData => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                alt="restock status"
                src={getRestockIconStatus(rowData.status, rowData.virtualStock)}
                style={{ marginRight: '10px' }}
              />
              {getStatusValue(rowData.status, rowData.virtualStock)}
            </div>
          ),
          lookup: {
            0: dictionary.AWAITING_REPLACEMENT,
            1: dictionary.STOCKED,
            2: dictionary.OUT_OF_STOCK,
            3: dictionary.VIRTUAL_STOCK,
          },
        },
      ]}
      localization={{
        body: {
          emptyDataSourceMessage: 
            <EmptyDataMessageRow 
              message={dictionary.EMPTY_RESTOCK_REPORT_TABLE} 
            />,
        },
      }}
    />
  );
};
