import { formatWithoutTimezone } from '../../../core/constants/dates';
import * as dictionary from '../../../core/constants/dictionary';
import { PromotionalNegotiationExecutionProblemTypeLabel } from '../../../core/types/promotional-spot/promotional-negotiation-execution-problem-type-label.enum';
import { PromotionalSpotReportProblemDto } from '../../../core/types/promotional-spot/promotional-spot-repot-data-dto';
import { PromotionalSpotTypeLabel } from '../../../core/types/promotional-spot/promotional-spot-type-label.enum';
import { PromotionalSpotType } from '../../../core/types/promotional-spot/promotional-spot-type.enum';
import { ProblemStatusEnum } from '../common/problemStatusEnum';
import { PromotionalSpotProblemsReportStatusLabel } from './types/promotional-spot-problems-report-status.type';
import { PromotionalSpotProblemReportTableLineDto } from './types/promotional-spot-problems-report-table-line-dto';

export const columnsForPromotionalSpotTableReportProblems = [
  {
    field: 'store',
    name: 'store',
    title: dictionary.PROMOTIONAL_SPOT_REPORT_STORE,
    width: '10%',
  },
  {
    field: 'addressing',
    name: 'addressing',
    title: dictionary.ADDRESSING,
    width: '10%',
  },
  {
    field: 'spotType',
    name: 'spotType',
    title: dictionary.PROMOTIONAL_SPOT_REPORT_SPOT_TYPE,
    width: '5%',
    lookup: PromotionalSpotTypeLabel,
  },
  {
    field: 'problemType',
    name: 'problemType',
    title: dictionary.PROBLEM_TYPE,
    width: '10%',
    lookup: PromotionalNegotiationExecutionProblemTypeLabel,
  },
  {
    field: 'reportedIn',
    name: 'reportedIn',
    title: dictionary.PROMOTIONAL_SPOT_REPORT_DATE_REPORTED_LABEL,
    width: '8%',
  },
  {
    field: 'solvedAt',
    name: 'solvedAt',
    title: dictionary.PROMOTIONAL_SPOT_REPORT_DATE_SOLVED_LABEL,
    width: '7%',
  },
  {
    field: 'products',
    name: 'products',
    title: dictionary.PRODUCT,
    width: '15%',
  },
  {
    field: 'reportedBy',
    name: 'reportedBy',
    title: dictionary.REPORTED_BY,
    width: '10%',
  },
  {
    field: 'status',
    name: 'status',
    title: dictionary.STATUS,
    width: '5%',
    lookup: {
      0: PromotionalSpotProblemsReportStatusLabel.INACTIVE,
      1: PromotionalSpotProblemsReportStatusLabel.CREATED,
      2: PromotionalSpotProblemsReportStatusLabel.SOLVED,
    },
  },
  {
    field: 'negotiationObservation',
    name: 'negotiationObservation',
    title: dictionary.OBSERVATIONS,
    width: '10%',
  },
];

export const parseReportResultToTable = (
  data: PromotionalSpotReportProblemDto
): PromotionalSpotProblemReportTableLineDto => {
  return {
    addressing: data?.addressing?.physicalAddress,
    reportedBy: data?.reportedBy.name,
    reportedIn: formatWithoutTimezone(data?.createdAt),
    solvedAt:
      (data.status === ProblemStatusEnum.SOLVED && data.solvedAt)
        ? formatWithoutTimezone(data.solvedAt)
        : undefined,
    products: data?.products?.length
      ? data.products
        ?.filter(product => product.id)
        .map((product) => `(${product?.sku}) ${product.name}`)
      : [],
    store: `(${data.addressing.store.code}) ${data.addressing.store.name}`,
    problemType: data.problemType,
    status: data.status,
    spotType: data.addressing.spot.type as PromotionalSpotType,
    negotiationObservation: data.negotiation?.observation ?? '',
  };
};
