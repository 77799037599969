import React from 'react';
import classes from '../styles.module.scss';

export default () => (
  <section className={classes.section}>
    <h2 className={classes.title}>DESTINATÁRIOS:</h2>

    <p className={classes.noMargin}>
      <strong>Prestadores de Serviços</strong>
    </p>
    <p>
      <strong>Razão do Compartilhamento:</strong>
      Colaboramos com prestadores de serviços que trabalham em nome da
      Involves e que podem necessitar acessar determinados dados pessoais
      com o fim de nos fornecer os seus serviços. Esses prestadores de
      serviço, contudo, têm acesso aos seus dados pessoais somente para
      realizar suas tarefas em nosso nome e são obrigados a não os divulgar
      ou usar para qualquer outra finalidade.
    </p>

    <p className={classes.noMargin}>
      <strong>
        Indústria e Redes Varejistas contratantes do Involves Doors
      </strong>
    </p>
    <p>
      <strong>Razão do Compartilhamento:</strong>O Involves Doors é uma
      Plataforma propicia conexões de dados entre o setor VAREJISTA e a
      INDÚSTRIA, a fim de automatizar e controlar o acesso às informações
      referentes a Ponto de Vendas (PDV’s) e Promotores de Venda, visando
      maior velocidade, segurança e transparência para todos os envolvidos
      no processo de trade marketing. Neste sentido, no contexto da
      prestação de serviços da Involves, as Indústrias e Redes Varejistas
      que contratam o Involves Doors terão acesso a informações como:
      Frequência de visitas nas Redes Varejistas e ações realizadas dentro
      do PDV.
    </p>

    <p className={classes.noMargin}>
      <strong>Investigadores Acadêmicos</strong>
    </p>
    <p>
      <strong>Razão do Compartilhamento:</strong>
      Compartilhamos os seus dados pessoais para atividades como análise
      estatística e estudos acadêmicos, mas apenas em formato de pseudônimo.
    </p>

    <p className={classes.noMargin}>
      <strong>
        Autoridades de observância da lei e de proteção de dados pessoais
      </strong>
    </p>
    <p>
      <strong>Razão do Compartilhamento:</strong>
      Compartilhamos os seus dados pessoais quando, em boa fé, acreditamos
      que é necessário fazê-lo para cumprir uma obrigação legal nos termos
      da legislação aplicável, ou responder a um procedimento legal válido,
      como um mandado de busca, ordem judicial ou intimação. Também
      compartilhamos os seus dados pessoais quando, em boa-fé, acreditamos
      que é necessário fazê-lo para efeitos do nosso próprio interesse
      legítimo ou do interesse legítimo de terceiros em relação à segurança
      nacional, observância da lei, litígio, investigação criminal, proteção
      da segurança de qualquer pessoa ou para evitar a morte ou danos
      físicos eminentes, desde que consideremos que tal interesse não seja
      anulado pelos seus interesses ou direitos e liberdades fundamentais
      que exijam a proteção dos seus dados pessoais.
    </p>

    <p className={classes.noMargin}>
      <strong>Parceiros Involves</strong>
    </p>
    <p>
      <strong>Razão do Compartilhamento:</strong>A Involves, baseada no
      legítimo interesse e desde que tal interesse não prevaleça sobre os
      seus interesses ou direitos fundamentais dos titulares de dados, de
      forma de anonimizada, poderá compartilhar dados com parceiros da
      indústria varejista para ajudá-los no processo de trade marketing,
      propiciando maior velocidade, segurança e transparência para os
      envolvidos no processo.
    </p>

    <p className={[classes.doubleMargin, classes.underline].join(' ')}>
      Desta forma, por meio desta Política você aceita que a Involves
      compartilhe e/ou divulgue seus dados pessoais para as finalidades
      listadas acima.
    </p>
  </section>
);

    