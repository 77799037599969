import { PromotionalSpotType } from "../../../core/types/promotional-spot/promotional-spot-type.enum";
import { PromotionalSpotDto } from "../common/types";

export const emptyPromotionalSpot: PromotionalSpotDto = {
  id: '',
  name: '',
  type: PromotionalSpotType.CHECKSTAND,
  status: 0,
  deleted: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  description: '',
}

export const defaultFormFields = {
  name: {
    errorText: '',
    showError: false,
  },
  type: {
    errorText: '',
    showError: false,
  },
  calendar: {
    errorText: '',
    showError: false,
  },
};
