import React from 'react';
import { Typography, } from '@material-ui/core';
import Backdrop from '../../components/Backdrop';
import InvolvesDoorsLogo from '../../components/DoorsLogo';
import { colors } from '../../Theme/colors';
import * as dictionary from '../../core/constants/dictionary';
import classes from './styles.module.scss';

export default (props) => (
    <div className={classes.container} style={{ background: colors.black_900 }}>
        <div className={classes.section}>
            <InvolvesDoorsLogo width={200} />
            { props.children }
        </div>
        
        <Typography variant="body1" style={{ color: colors.black_600 }}>
            { `${dictionary.VERSION } ${process.env.REACT_APP_WEB_VERSION}` }
        </Typography>
        
        <Backdrop />
    </div>
);