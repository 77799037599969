import React, { useCallback } from 'react';
import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import { mainStore } from '../../../../core/store/mainStore';
import * as dictionary from '../../../../core/constants/dictionary';
import ModalTitle from '../../../../components/Modal/Title';
import CancelButton from '../../../../components/Buttons/Cancel';
import DefaultButton from '../../../../components/Buttons/Default';
import { removeStore, getStores } from '../../functions';
import { findValueInArray } from '../../../../core/validation';
import { trackStoreDelete } from '../../../../core/mixpanel/mixPanelEvents';
import { utils } from '../../../../core/utils';

export const RemoveStore = ({ closeModalCallback, currentStore }) => {
  const handleRemoveStore = useCallback(
    async event => {
      event.preventDefault();
      try {
        const response = await removeStore(currentStore);
        if (response.status === 200) {
          const retail = findValueInArray(
            mainStore.stores.retails,
            'id',
            currentStore.retailId,
          );

          const storeMixpanelDTO = {
            store: {
              name: currentStore.name,
              status: currentStore.status,
              number: currentStore.number,
              city: currentStore.city,
              state: currentStore.state,
            },
            retail: {
              name: retail.name,
            },
          };
          trackStoreDelete(storeMixpanelDTO);
          mainStore.stores.data = await getStores(
            mainStore.loggedUser.retailId,
          );
          closeModalCallback();
          mainStore.requestLoading = false;
          utils.openSnackBar(dictionary.SUCCESS, dictionary.STORE_REMOVED);
        }
      } catch (err) {
        utils.openSnackBar(dictionary.ERROR, dictionary.UNKNOWN_ERROR);
      } finally {
        mainStore.requestLoading = false;
      }
    },
    [closeModalCallback, currentStore],
  );

  return (
    <>
      <ModalTitle title={dictionary.DELETE_STORE} />

      <DialogContent>
        <Typography variant="body2">
          {dictionary.CONFIRM_STORE_REMOVAL}
          <span style={{ fontWeight: 'bold', marginLeft: '0.1rem' }}>
            {currentStore.name}
          </span>
        </Typography>
      </DialogContent>

      <DialogActions>
        <CancelButton onClick={() => closeModalCallback()} />

        <DefaultButton
          onClick={event => handleRemoveStore(event)}
          color="primary"
          style={{ width: 'auto' }}
          label={dictionary.DELETE}
        />
      </DialogActions>
    </>
  );
};
