import React from 'react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import * as dictionary from '../../../../../../../core/constants/dictionary';
import NegotiationHeaderCell from './NegotiationHeaderCell';
import classes from '../../NegotiationTable.module.scss';
import { StoreDto } from '../../../../../Addressing/types';

type Props = {
  stores?: StoreDto[];
};

const NegotiationHeader = ({ stores }: Props) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.sticky} style={{ zIndex: 999 }}>
          <div className={classes.shadow}>
            <TableCell></TableCell>
            <TableCell>{dictionary.SUPPLIERS}</TableCell>
            <TableCell>{dictionary.PRODUCTS}</TableCell>
          </div>
        </TableCell>
        <TableCell align='center'>{dictionary.TOTAL}</TableCell>
        {stores?.map((store) => (
          <NegotiationHeaderCell
            storeCode={store?.storeCode}
            storeName={store?.name}
            key={store.id}
          />
        ))}
      </TableRow>
    </TableHead>
  );
};

export default NegotiationHeader;
