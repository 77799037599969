import { mainStore } from '../../store/mainStore';
import {
  getPromotionalSpotsAPI,
  createPromotionalSpotAPI,
  updatePromotionalSpotAPI,
  removePromotionalSpotAPI,
  getPromotionalSpotByIdAPI,
} from './promotionalSpotsAPI';
import {
  CreatePromotionalSpotDto,
  UpdatePromotionalSpotDto,
  GetPromotionalSpotsParams,
} from './types';

export const getPromotionalSpots = (data: GetPromotionalSpotsParams) => {
  data.retailId = data.retailId ? data.retailId : mainStore.loggedUser.retailId;
  return getPromotionalSpotsAPI(data);
};

export const getPromotionalSpotById = (id: string) => {
  return getPromotionalSpotByIdAPI(id);
};

export const createPromotionalSpot = (
  calendar: Partial<CreatePromotionalSpotDto>
) => {
  return createPromotionalSpotAPI(calendar);
};

export const updatePromotionalSpot = (
  calendar: Partial<UpdatePromotionalSpotDto>
) => {
  return updatePromotionalSpotAPI(calendar);
};

export const removePromotionalSpot = (promotionalSpotId: string) => {
  return removePromotionalSpotAPI(promotionalSpotId);
};
