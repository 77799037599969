import React from 'react';
import classes from '../styles.module.scss';

export default () => (
  <section className={classes.section}>
    <h2 className={classes.title}>
      Para facilitar sua compreensão, a presente Política está dividida da
      seguinte forma:
    </h2>

    <ul className={classes.doubleMargin}>
      <li>Dados coletados, Forma e Finalidade da Coleta</li>
      <li>Compartilhamento ou Divulgação de Dados Pessoais</li>
      <li>Contribuições de Usuário</li>
      <li>Coleta de Informações de Terceiros</li>
      <li>Durante quanto tempo conservamos os seus dados pessoais?</li>
      <li>Segurança da informação</li>
      <li>Proteção da Privacidade de Menores</li>
      <li>Atualizações</li>
      <li>Como Você Pode Entrar em Contato Conosco?</li>
    </ul>
  </section>
);

    