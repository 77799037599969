import React from 'react';
import classes from '../styles.module.scss';
import { CONTACT, getMailAnchor, getTelAnchor } from '../utils';

export default () => (
  <section className={classes.section}>
    <h2 className={classes.title}>
      COMO VOCÊ PODE ENTRAR EM CONTATO CONOSCO?
    </h2>

    <p>Obrigado por ler a nossa Política.</p>

    <p className={classes.noMargin}>
      Estaremos sempre à disposição para atendê-lo. Se você desejar obter
      mais informações, ou caso tenha restado alguma dúvida sobre essa
      Política, por favor, entre em contato conosco:
    </p>

    <p className={classes.noMargin}>
      Telefone:{' '}
      <a href={getTelAnchor()} target="_blank" rel="noopener noreferrer" title="Telefone de contato">
        { CONTACT.PHONE }
      </a>
    </p>

    <p className={classes.noMargin}>
      E-mail:{' '}
      <a href={getMailAnchor()} target="_blank" rel="noopener noreferrer" title="E-mail de contato">
        { CONTACT.MAIL_ADDRESS }
      </a>
    </p>
    
    <p className={classes.marginDouble}>
      Contato: { CONTACT.MAIL_CONTACT }
    </p>

    <p className={classes.underline}>
      Esperamos que goste dos serviços da Involves Doors!{' '}
    </p>
  </section>
);
