import React from 'react';
import Styles from './styles.module.scss';

const FixedBottomFooter = props => {
    return (
        <footer className={Styles.Container}>
            <div className={Styles.Wrapper}>
                {props.children}
            </div>
        </footer>
    );
}

export default FixedBottomFooter;