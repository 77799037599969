import React from 'react';
import { Typography } from '@material-ui/core';
import classes from './ResumeCard.module.scss';

type Props = {
  value: number;
};
const PercentValue = ({ value }: Props) => {
  return (
    <Typography variant="h1" className={classes.label}>
      <span className={classes.highlight}>{value}%</span>
    </Typography>
  );
};

export default PercentValue;
