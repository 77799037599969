import {
  createNegotiationAPI,
  createRecursiveNegotiationAPI,
  deleteNegotiationAPI,
  getNegotiationAPI,
  removeRecursiveNegotiationAPI,
  updateNegotiationAPI,
  upsertNegotiationProductsAPI,
  upsertNegotiationSuppliersAPI,
} from './promotionalNegotiationAPI';
import {
  CreatePromotionalNegotiationParams,
  CreateRecursiveNegotiationDto,
  DeleteNegotiationParams,
  GetPromotionalNegotiationParams,
  UpdatePromotionalNegotiationParams,
  UpsertPromotionalNegotiationProductsParams,
  UpsertPromotionalNegotiationSuppliersParams,
} from './types';

export const getNegotiationPanel = (
  params: Partial<GetPromotionalNegotiationParams>
) => {
  return getNegotiationAPI(params);
};

export const updateNegotiation = (
  params: UpdatePromotionalNegotiationParams
) => {
  return updateNegotiationAPI(params);
};

export const upsertNegotiationSuppliers = (
  params: UpsertPromotionalNegotiationSuppliersParams
) => {
  return upsertNegotiationSuppliersAPI(params);
};

export const upsertNegotiationProducts = (
  params: UpsertPromotionalNegotiationProductsParams
) => {
  return upsertNegotiationProductsAPI(params);
};

export const deleteNegotiation = (params: DeleteNegotiationParams) => {
  return deleteNegotiationAPI(params);
};

export const createNegotiation = (
  params: CreatePromotionalNegotiationParams
) => {
  return createNegotiationAPI(params);
};

export const createRecursiveNegotiation = (
  params: CreateRecursiveNegotiationDto
) => {
  return createRecursiveNegotiationAPI(params);
};

export const removeRecursiveNegotiation = (id: string) => {
  return removeRecursiveNegotiationAPI(id);
};
