import React, { useState, ChangeEvent, useEffect } from 'react';
import brLocale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import { view } from 'react-easy-state';
import { useHistory, useParams } from 'react-router';
import { differenceInDays } from 'date-fns';
import { Paper, Typography } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Loading from '../../../../../components/Loading';
import PageContent from '../../../../../components/PageContent';
import DefaultButton from '../../../../../components/Buttons/Default';
import InputTextField from '../../../../../components/InputTextField';
import { utils } from '../../../../../core/utils';
import {
  addPromotionalCalendar,
  editPromotionalCalendars,
  getPromotionalCalendars,
} from '../../../../../core/services/promotionalSpots/promotionalCalendarsService';
import * as routes from '../../../../../core/constants/routes';
import * as dictionary from '../../../../../core/constants/dictionary';
import {
  CalendarFooter,
  CalendarPeriodsTable,
  EditCalendarPeriodModal,
} from '../index';
import { CalendarPeriodTableRow } from '../../types';
import {
  defaultPeriod,
  defaultFormFields,
  getDatepickerErrorDto,
  validateCalendarPeriods,
} from '../../index';
import { styles } from '../../styles';
import {
  dateForGMTWithoutTimezone,
  daysBetweenDates,
} from '../../../../../core/constants/dates';
import { AddCalendarPeriodForm } from '../../defaultPeriod';
import { PromotionalCalendarPeriodDto } from '../../../../../core/services/promotionalSpots/types';
import { useRetailList } from '../../../../../hooks/RetailHooks';
import { FormRetailField } from '../../../../../components/Form/FormRetailField';
import { RetailEntityType } from '../../../../../core/types/retail/retail-entity.type';
import { 
  sortPeriodsByStartAtAsc,
  getFriendlyFeedbackError, 
} from './utils';

type RouterParams = {
  id: string;
};

type Props = {
  data?: {
    name: string;
    isActiveChecked: boolean;
    periods: CalendarPeriodTableRow[];
  };
  pageTitle: string;
  feedbackError: string;
  feedbackSuccess: string;
  isEditMode?: boolean;
};

const PromotionalCalendarForm = ({
  data,
  pageTitle,
  feedbackError,
  feedbackSuccess,
  isEditMode = false,
}: Props) => {
  const [name, setName] = useState('');
  const [periods, setPeriods] = useState<CalendarPeriodTableRow[]>([]);
  const [retail, setRetail] = useState<RetailEntityType>();
  const [newPeriod, setNewPeriod] =
    useState<AddCalendarPeriodForm>(defaultPeriod);
  const [currentRow, setCurrentRow] = useState<CalendarPeriodTableRow>();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isActiveChecked, setIsActiveChecked] = useState(true);
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { id } = useParams<RouterParams>();
  const history = useHistory();
  const snackBar = utils.getSnackbar();
  const isAddNewPeriodButtonDisabled =
    !newPeriod.endAt || !newPeriod.startAt || !newPeriod.negotiatedAt;

  const {
    retails,
    fetch: fetchRetails,
    isLoading: isLoadingRetails,
    retailInUser,
  } = useRetailList();

  useEffect(() => {
    if (data?.name) {
      setName(data.name);
    }
  }, [data?.name]);

  useEffect(() => {
    if (data?.periods) {
      setPeriods(data.periods);
    }
  }, [data?.periods]);

  useEffect(() => {
    if (data?.isActiveChecked !== undefined) {
      setIsActiveChecked(data.isActiveChecked);
    }
  }, [data?.isActiveChecked]);

  useEffect(() => {
    if (!retailInUser && !isLoadingRetails) {
      fetchRetails();
    }
  }, [retailInUser, isLoadingRetails]);

  const onAddCalendarPeriodHandler = () => {
    const { isEndAtValid, isStartAtValid } = validateCalendarPeriods(
      newPeriod,
      periods
    );

    if (isEndAtValid && isStartAtValid && newPeriod.endAt) {
      setPeriods((prevState) => {
        const updatedPeriods = [
          ...prevState,
          {
            ...newPeriod,
            duration: differenceInDays(
              newPeriod.endAt as Date,
              newPeriod.startAt as Date
            ),
          },
        ];
        updatedPeriods.sort(sortPeriodsByStartAtAsc);
        return updatedPeriods;
      });
      setFormFields((prevState) => ({
        ...prevState,
        endAt: { ...defaultFormFields.endAt },
        startAt: { ...defaultFormFields.startAt },
      }));
      setNewPeriod(defaultPeriod);
    } else {
      setFormFields((prevState) => ({
        ...prevState,
        ...getDatepickerErrorDto(isStartAtValid, isEndAtValid, newPeriod.endAt),
      }));
    }
  };

  const handleUpdateSubmit = async () => {
    const { status } = await editPromotionalCalendars({
      id,
      name,
      periods: periods.filter(
        (period) => !(period.status === 0 && !period.id)
      ) as PromotionalCalendarPeriodDto[],
      status: Number(isActiveChecked),
    });

    if (status) {
      utils.openSnackBar(dictionary.SUCCESS, feedbackSuccess);
      setIsLoading(false);
    }
  };

  const handleCreateSubmit = async () => {
    const { data: response } = await getPromotionalCalendars({ name });
    if (response.items.length > 0) {
      setFormFields((prevState) => ({
        ...prevState,
        name: {
          hasError: true,
          message: dictionary.ALREADY_REGISTERED_CALENDAR_NAME,
        },
      }));
    } else {
      const { status } = await addPromotionalCalendar({
        name,
        periods: periods as PromotionalCalendarPeriodDto[],
        retailId: retail?.id,
      });
      if (status === 201) {
        setName('');
        setPeriods([]);
        utils.openSnackBar(dictionary.SUCCESS, feedbackSuccess);
      } else {
        utils.openSnackBar(dictionary.ERROR, feedbackError);
      }
    }
  };

  const onSubmitFormHandler = async (_e: never) => {
    if (!name || periods.length === 0) {
      setFormFields((prevState) => ({
        ...prevState,
        name: {
          hasError: true,
          message: dictionary.FIELD_NOT_EMPTY,
        },
      }));
      return;
    }

    try {
      setIsLoading(true);
      if (isEditMode) {
        await handleUpdateSubmit();
      } else {
        await handleCreateSubmit();
      }
      setIsLoading(false);
      history.push(routes.PROMOTIONAL_CALENDARS);
    } catch (err) {
      utils.openSnackBar(
        dictionary.ERROR, 
        `${feedbackError}. ${getFriendlyFeedbackError(err)}.`
      );
      setIsLoading(false);
    }
  };

  return (
    <PageContent title={pageTitle}>
      <Paper style={styles.paper}>
        <Typography variant="h1" style={styles.text}>
          {dictionary.NEW_CALENDAR}
        </Typography>

        {!retailInUser && !isEditMode && (
          <div style={styles.inputContainer}>
            <FormRetailField
              required
              disabled={isLoading || isLoadingRetails}
              value={retail}
              key={retail?.id}
              options={retails ?? []}
              onChange={(retail) => {
                setRetail(retail);
                const localStorageFilters = localStorage.getItem('pexFilters');
                let currentForm = {};
                if (localStorageFilters) {
                  currentForm = JSON.parse(localStorageFilters);
                }
                localStorage.setItem(
                  'pexFilters',
                  JSON.stringify({
                    ...currentForm,
                    retailId: retail?.id ?? retailInUser,
                  })
                );
              }}
              style={styles.input}
            />
          </div>
        )}

        <InputTextField
          required
          value={name}
          variant="outlined"
          id="outlined-required"
          errorText={formFields.name.message}
          showError={formFields.name.hasError}
          label={dictionary.CALENDAR_NAME}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;
            if (value.length > 0) {
              setFormFields((prevState) => ({
                ...prevState,
                name: {
                  message: '',
                  hasError: false,
                },
              }));
            }
            setName(value);
          }}
          disabled={!isEditMode ? !retailInUser && !retail : false}
          style={styles.input}
        />

        <div style={styles.datepickerContainer}>
          <Typography variant="h1" style={styles.text}>
            {dictionary.NEW_PERIODS_PROMOTIONAL}
          </Typography>

          <div style={styles.datepickerGrid}>
            <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                margin="normal"
                variant="inline"
                format="dd/MM/yyyy"
                minDate={new Date()}
                value={newPeriod?.negotiatedAt ?? null}
                label={dictionary.LIMIT_CALENDAR}
                error={formFields.negotiatedAt.hasError}
                helperText={formFields.negotiatedAt.message}
                invalidDateMessage={dictionary.INVALID_DATE}
                InputProps={{ readOnly: true }}
                onChange={(date: MaterialUiPickersDate) => {
                  if (date) {
                    const negotiatedAt = dateForGMTWithoutTimezone(date);
                    setNewPeriod((prevState: any) => ({
                      ...prevState,
                      negotiatedAt,
                    }));
                  }
                }}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                margin="normal"
                variant="inline"
                format="dd/MM/yyyy"
                error={formFields.startAt.hasError}
                helperText={formFields.startAt.message}
                value={newPeriod?.startAt ?? null}
                minDate={new Date()}
                maxDate={newPeriod?.endAt ? newPeriod.endAt : undefined}
                label={dictionary.DATE_START_SELECT}
                invalidDateMessage={dictionary.INVALID_DATE}
                InputProps={{ readOnly: true }}
                onChange={(date: MaterialUiPickersDate) => {
                  if (date) {
                    const startAt = dateForGMTWithoutTimezone(date);
                    setNewPeriod((prevState: any) => ({
                      ...prevState,
                      startAt,
                    }));
                    setFormFields((prevState) => ({
                      ...prevState,
                      startAt: {
                        message: '',
                        hasError: false,
                      },
                    }));
                  }
                }}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider locale={brLocale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                margin="normal"
                variant="inline"
                format="dd/MM/yyyy"
                value={newPeriod?.endAt ?? null}
                minDate={newPeriod.startAt}
                error={formFields.endAt.hasError}
                helperText={formFields.endAt.message}
                invalidDateMessage={dictionary.INVALID_DATE}
                label={dictionary.DATE_END_SELECT}
                InputProps={{ readOnly: true }}
                onChange={(date: MaterialUiPickersDate) => {
                  if (date) {
                    const endAt = dateForGMTWithoutTimezone(date);
                    setNewPeriod((prevState: any) => ({
                      ...prevState,
                      endAt,
                    }));
                    setFormFields((prevState) => ({
                      ...prevState,
                      endAt: {
                        message: '',
                        hasError: false,
                      },
                    }));
                  }
                }}
              />
            </MuiPickersUtilsProvider>
            <div style={styles.buttonContainer}>
              <DefaultButton
                label={dictionary.ADD_CALENDAR}
                disabled={isAddNewPeriodButtonDisabled}
                onClick={onAddCalendarPeriodHandler}
                style={styles.button}
              />
            </div>
          </div>
        </div>

        <Typography variant="h1" style={styles.text}>
          {dictionary.PERIODS_REGISTERED}
        </Typography>
        <CalendarPeriodsTable
          data={periods.filter((period) => period.status)}
          onEditCalendarHandler={(rowData: any) => {
            setCurrentRow(rowData);
            setIsModalOpen(true);
          }}
          onDeleteCalendarHandler={(selectedRow: any) => {
            const currentPeriod = periods.find(
              (_period, i) => i === selectedRow.tableData.id
            );
            const filteredPeriods = periods.filter(
              (_period, i) => i !== selectedRow.tableData.id
            );

            if (currentPeriod?.id) {
              setPeriods([...filteredPeriods, { ...currentPeriod, status: 0 }]);
            } else {
              setPeriods(filteredPeriods);
            }
          }}
        />
        <CalendarFooter
          isDisabled={
            !name ||
            periods.length === 0 ||
            (!isEditMode ? !retailInUser && !retail : false)
          }
          isCheckboxChecked={isActiveChecked}
          onClickHandler={onSubmitFormHandler}
          onChangeCheckbox={() => setIsActiveChecked((prevState) => !prevState)}
        />
        {snackBar}
        {currentRow && (
          <EditCalendarPeriodModal
            isOpen={isModalOpen}
            period={currentRow}
            periods={periods}
            onClose={() => setIsModalOpen(false)}
            onUpdatePeriod={(updatedPeriod: any) => {
              const updatedPeriods = [...periods];
              const index = updatedPeriods.findIndex(
                (period) => period?.tableData?.id === updatedPeriod.tableData.id
              );
              updatedPeriods[index] = {
                ...updatedPeriod,
                duration: daysBetweenDates(
                  new Date(updatedPeriod.startAt),
                  new Date(updatedPeriod.endAt)
                ),
              };
              updatedPeriods.sort(sortPeriodsByStartAtAsc);

              setIsModalOpen(false);
              setPeriods(updatedPeriods);
            }}
          />
        )}

        <Loading isOpen={isLoading} />
      </Paper>
    </PageContent>
  );
};

export default view(PromotionalCalendarForm);
