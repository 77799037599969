import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { mainStore } from '../../core/store/mainStore';
import * as routes from '../../core/constants/routes';
import {
  isLoggedUserAuthorizedInRoute,
  redirectToHome,
} from '../../core/authorization';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (!mainStore.token) {
        return <Redirect to={routes.LOGIN} />;
      }
      if (isLoggedUserAuthorizedInRoute(props.location)) {
        return <Component {...props} />;
      }
      return redirectToHome();
    }}
  />
);

export default PrivateRoute;
