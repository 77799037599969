export enum PromotionalSpotTypeLabel {
  GONDOLA_TIP = "Ponta de Gôndola",
  GONDOLA_SIDE = "Lateral de Ponta Gôndola (orelha)",
  CHECKSTAND = "Checkstand",
  ISLAND = 'Ilha',
  EXHIBITOR = "Expositor",
  FREEZER = "Freezer",
  CROSS_MERCHANDISING = 'Cross Merchandising',
  DISPLAY = 'Display',
}
