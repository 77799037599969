import API from '../api/config';
import * as routes from '../api/routes';
import { 
  ExpirationReportResult, 
  GetExpirationReportParams,
} from './types';

export const getExpirationReport = async ({
  offset = 0,
  limit = 10,
  productId,
  storeId,
  endDate,
  startDate,
  productGroupId,
  expirationRisk,
}: GetExpirationReportParams): Promise<ExpirationReportResult> => {
  const queryParams = new URLSearchParams({
    limit: String(limit),
    offset: String(offset),
  });

  if (productId && productId.length > 0) {
    queryParams.append('productId', productId.join(','));
  }
  if (storeId) {
    queryParams.append('storeId', storeId);
  }
  if (endDate) {
    queryParams.append('endDate', endDate);
  }
  if (startDate) {
    queryParams.append('startDate', startDate);
  }
  if (productGroupId && productGroupId.length > 0) {
    queryParams.append('productGroupId', productGroupId.join(','));
  }
  if (expirationRisk && expirationRisk.length > 0) {
    queryParams.append('expirationRisk', expirationRisk.join(','));
  }
  
  const url = `${routes.API_EXPIRATION_REPORTS}?${queryParams.toString()}`;
  const { data } = await API.get(url);
  
  return data;
};

export const exportExpirationReport = async ({
  productId,
  storeId,
  endDate,
  startDate,
  productGroupId,
  expirationRisk,
}: GetExpirationReportParams): Promise<any> => {
  const params = new URLSearchParams();
  if (productId && productId.length > 0) {
    params.append('productId', productId.join(','));
  }
  if (storeId) {
    params.append('storeId', storeId);
  }
  if (endDate) {
    params.append('endDate', endDate);
  }
  if (startDate) {
    params.append('startDate', startDate);
  }
  if (productGroupId && productGroupId.length > 0) {
    params.append('productGroupId', productGroupId.join(','));
  }
  if (expirationRisk && expirationRisk.length > 0) {
    params.append('expirationRisk', expirationRisk.join(','));
  }
  
  const response = await API.get(routes.API_EXPIRATION_REPORTS_EXPORT, {
    params,
    responseType: 'arraybuffer',
  });
  
  return response;
};
