import React, { useMemo } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import { colors } from '../../../Theme/colors';
import * as dictionary from '../../../core/constants/dictionary';

const CssButton = withStyles({
  root: {
    '&:hover': { backgroundColor: colors.black_700 },
  },
})(Button);

const useStyles = makeStyles({
  defaultStyle: {
    textTransform: 'none',
    background: colors.blue_500,
    color: colors.white,
    width: '100%',
    height: '40px',
  },
});

const DefaultButton = ({ 
  label = "", 
  disabled = false, 
  style = {}, 
  loading = false,
  onClick = undefined, 
}) => {
  const classes = useStyles();

  const labelText = useMemo(() => {
    return label || dictionary.SAVE;
  }, [label]);

  return (
    <CssButton
      style={style}
      className={classes.defaultStyle}
      variant="contained"
      disabled={disabled || loading}
      onClick={onClick}
    >
    {
      loading ? 
        <CircularProgress color="inherit" size={20} />
        :
        <Typography variant="button" style={style}>
          {labelText}
        </Typography>
     }
    </CssButton>
  );
};

export default DefaultButton;
