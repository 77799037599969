import React, { ReactNode } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { DefaultButton, CancelButton } from '../../components/Buttons';
import { CANCEL, SEND } from '../../core/constants/dictionary';
import classes from './LoginModal.module.scss';
import SmallModalContainer from '../../containers/Modal/SmallContainer'; 
import DisableGoogleAutocomplete from '../../components/DisableGoogleAutocomplete';

type Props = {
  isOpen: boolean;
  title: string;
  width?: number; 
  children: ReactNode;
  labels?: {
    cancelButton?: string;
    submitButton?: string;
  },
  isSubmitButtonDisabled: boolean;
  onCloseModalHandler: Function;
  onSubmitFormHandler: Function;
};

export const LoginModal = ({ 
  isOpen, 
  title,
  labels,
  width,
  children, 
  isSubmitButtonDisabled,
  onCloseModalHandler,
  onSubmitFormHandler,
}: Props) => (
  (
    <SmallModalContainer
      width={width}
      isOpen={isOpen}
      classes={{ paper: classes.Container }}
      closeModalCallback={onCloseModalHandler}
    >
      <Grid
        container
        direction="column"
        className={classes.Modal}
      >
        <Grid item className={classes.Header}>
          <Typography variant="h1">
            { title }
          </Typography>
        </Grid>
        <DisableGoogleAutocomplete />
        
        { children }
        
        <Grid 
          container
          direction="row"
          justifyContent="flex-end"
          className={classes.Footer}
        >
          <CancelButton 
            label={labels?.cancelButton ?? CANCEL} 
            onClick={onCloseModalHandler} 
          />
          <DefaultButton 
            label={labels?.cancelButton ?? SEND} 
            disabled={isSubmitButtonDisabled}
            style={{ width: 'auto' }}
            onClick={onSubmitFormHandler} 
          />
        </Grid>

      </Grid>
    </SmallModalContainer>
  )
);

export default LoginModal;
