import { mainStore } from '../../store/mainStore';
import { exist } from '../../validation';
import { getStoreById } from '../stores/storesService';
import { profileCheck } from '../../profiles';
import { getRetailById } from '../retails/retailsService';
import { RetailFeature } from '../retailFeatures/types';
import { userIdentityDTO } from '../../mixpanel/identityManagement';
import { 
  setToken, 
  setLoggedUser,  
} from '../../authorization';
import { getAllFeatures } from '../retailFeatures/retailFeaturesService';
import * as routes from '../../constants/routes';
import {
  signInAPI,
  signInAuthCheckinTokenAPI,
  liberateAccessByStoreManagerLoginAPI,
} from './authAPI';

const mixpanel = require('mixpanel-browser');

const hasMissionsOnUserRetail = () => {
  return mainStore.loggedUser?.features?.includes(RetailFeature.SHELF_MISSIONS);
};

const getAllUserAllowedFeatures = async (retailId) => {
  const { data } = await getAllFeatures(retailId);
  if (data.items.length > 0) {
    return data.items
      .filter(feature => Number(feature.status) === 1)
      .map(feature => feature.feature)
  }

  return [];
}

export const signIn = async (cpf, password, setHasError, history) => {
  try {
    const { user, jwt } = await signInAPI(cpf, password);
    setToken(jwt);

    if (exist(user.retailId)) {
      user.retail = await getRetailById(user.retailId);
      user.features = await getAllUserAllowedFeatures(user.retailId);
    }
    if (exist(user.storeId)) {
      user.store = await getStoreById(user.storeId);
    }

    setLoggedUser(user);
    setHasError(false);
    mainStore.requestLoading = false;

    mixpanel.people.set(userIdentityDTO(user));
    mixpanel.identify(user.cpf);

    let routeControl = routes.AGREEMENTS;
    
    if (profileCheck.isSecurity()) {
      routeControl = routes.VISITS;
    }
    else if (profileCheck.isSuperAdmin()) {
      routeControl = routes.RESTOCK_PANEL;
    }
    else if (profileCheck.isBuyer()) {
      if (hasMissionsOnUserRetail()) {
        routeControl = routes.RESTOCK_PANEL;
      }
      else {
        routeControl = routes.AGREEMENTS;
      }
    }
    else if (
      profileCheck.isRetailAdmin() || 
      profileCheck.isStoreManager()
    ) {
      if (hasMissionsOnUserRetail()) {
        routeControl = routes.RESTOCK_PANEL;
      }
      else {
        routeControl = routes.VISITS;
      }
    }
    history.push(routeControl);
  } 
  catch (e) {
    mainStore.requestLoading = false;
    setHasError(true);
  }
};

export const signInAuthCheckinTokenService = async () => {
  return signInAuthCheckinTokenAPI();
};

export const liberateAccessByStoreManagerLogin = async ({
  cpf,
  password,
  promoterId,
  qrcodeId,
  timezone
}) => {
  return liberateAccessByStoreManagerLoginAPI({
    cpf,
    password,
    promoterId,
    qrcodeId,
    timezone,
  });
}
