import React, { useEffect, useState, useMemo } from 'react';
import Autocomplete from '../../../../components/Autocomplete';
import SmallModalContainer from '../../../../containers/Modal/SmallContainer';
import { Grid, Typography, TextField } from '@material-ui/core';
import { CancelButton, DefaultButton } from '../../../../components/Buttons';
import classes from './LoginModal.module.scss';
import { CANCEL, RELEASE_ACCESS } from '../../../../core/constants/dictionary';
import { UserInfo } from '../../../Users/utils';
import { getAllStoreManagersByStoreId } from '../../../../core/services/users/usersService';
import { appendLoggedStoreManager } from '../../../VisitsOfDay/utils';
import { mainStore } from '../../../../core/store/mainStore';

type Props = {
  isOpen: boolean;
  title: string;
  onCloseModalHandler: Function;
  onReleaseAccessHandler: Function;
};

const LoginModal = ({
  isOpen,
  title,
  onCloseModalHandler,
  onReleaseAccessHandler,
}: Props) => {
  const [storeManagers, setStoreManagers] = useState<UserInfo[]>([]);
  const [selectedStoreManager, setSelectedStoreManager] = useState<UserInfo>();
  const [password, setPassword] = useState<string>('');
  const [hasErrorOnLogin, setHasErrorOnLogin] = useState(false);

  const fetchData = async (queryString?: string) => {
    try {
      const { items } = await getAllStoreManagersByStoreId({
        limit: 100,
        query: queryString,
        storeId: mainStore?.loggedUser.storeId ?? '',
      });
      const itemsWithLoggedUser = appendLoggedStoreManager(
        items,
        queryString ?? ''
      );
      setStoreManagers(itemsWithLoggedUser);
    } catch (err) {}
  };

  const onChangePasswordHandler = (event: any) => {
    setPassword(event.target.value);
  };

  const onClickDefaultButtonHandler = async () => {
    try {
      const result = await onReleaseAccessHandler(
        selectedStoreManager?.cpf,
        password
      );
      if (result) {
        setPassword('');
        setSelectedStoreManager(undefined);
      }
      setHasErrorOnLogin(!result);
    } catch (err) {
      setHasErrorOnLogin(true);
    }
  };

  const isButtonDisabled = useMemo(() => {
    return !selectedStoreManager || password.length === 0;
  }, [selectedStoreManager, password]);

  return (
    <SmallModalContainer
      isOpen={isOpen}
      classes={{ paper: classes.Container }}
      closeModalCallback={onCloseModalHandler}
    >
      <Grid container direction="column" className={classes.Modal}>
        <Grid item className={classes.Header}>
          <Typography variant="h1">{title}</Typography>
        </Grid>

        <input
          type="password"
          name="fake-password"
          autoComplete="new-password"
          tabIndex={-1}
          style={{
            opacity: 0,
            float: 'left',
            border: 'none',
            height: '0',
            width: '0',
          }}
        />
        <Grid item className={classes.Input}>
          <Autocomplete
            id="cpf-store-manager"
            size="small"
            style={{ width: '100%' }}
            options={storeManagers}
            value={selectedStoreManager}
            getOptionLabel={(option: UserInfo) =>
              `${option.name} (${option.cpf})`
            }
            renderInput={(params: any) => (
              <TextField
                label={'CPF chefe de loja'}
                variant="outlined"
                error={hasErrorOnLogin}
                helperText={
                  hasErrorOnLogin && 'Usuário ou senha não encontrados'
                }
                {...params}
              />
            )}
            onChange={(_: never, value: any) => {
              if (hasErrorOnLogin) {
                setHasErrorOnLogin(false);
              }
              setSelectedStoreManager(value);
            }}
            onInputChange={(event: any, value: string) => {
              if (value) {
                fetchData(value);
              } else {
                setStoreManagers([]);
              }
            }}
          />
        </Grid>
        <Grid item className={classes.Input}>
          <TextField
            type="password"
            label={'Senha'}
            size="small"
            fullWidth
            variant="outlined"
            value={password}
            onChange={onChangePasswordHandler}
          />
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          className={classes.Footer}
        >
          <CancelButton label={CANCEL} onClick={onCloseModalHandler} />
          <DefaultButton
            label={RELEASE_ACCESS}
            disabled={!!isButtonDisabled}
            style={{ width: 'auto' }}
            onClick={onClickDefaultButtonHandler}
          />
        </Grid>
      </Grid>
    </SmallModalContainer>
  );
};

export default LoginModal;
