import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ProgressBar from '../../../../../components/ProgressBar';
import classes from './Accordion.module.scss';

type Props = {
  title: string;
  count: number;
  total: number;
  color: string;
};

const BarColumn = ({ title, count, total, color }: Props) => {
  const percentage = total > 0 ? Math.floor((count / total) * 100) : 0;

  return (
    <Grid
      item
      className={[classes.removeFontSizeGap, classes.barContainer].join(' ')}
    >
      <Grid container className={classes.progressBarStatusTextGap}>
        <Grid item>
          <Typography className={classes.headerTitle}>
            <Typography variant="overline" className={classes.progressBarTitle}>
              {`${percentage}% ${title}`}
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.headerTitle}>
            <Typography variant="overline" className={classes.progressBarTitle}>
              {count} / {total}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <ProgressBar total={percentage} color={color} />
    </Grid>
  );
};

export default BarColumn;
