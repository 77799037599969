import React, { useEffect, useMemo, useRef, useState } from 'react';
import { view } from 'react-easy-state';
import { FilterList } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import brLocale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import { startOfDay, endOfDay } from 'date-fns';
import { saveAs } from 'file-saver';
import Loading from '../../components/Loading';
import WelcomePage from '../../components/WelcomePage';
import VisitsHistoryTable from './VisitsHistoryTable';
import PageContent from '../../components/PageContent';
import Autocomplete from '../../components/Autocomplete';
import CancelButton from '../../components/Buttons/Cancel';
import DefaultButton from '../../components/Buttons/Default';
import StickyFooter from '../../components/stickyFooter/stickyFooter';
import SupplierFilter from './SupplierFilter';
import { exportVisitsXls } from '../../core/services/exportVisits';
import { getActiveStoresByRetail } from '../../core/services/stores/storesService';
import { getVisitsPaginated } from '../../core/services/visits/visitsService';
import { getSuppliersByRetail } from '../../core/services/suppliers/suppliersService';
import { useDebounce } from '../../core/hooks';
import { utils } from '../../core/utils';
import { profileCheck } from '../../core/profiles';
import { mainStore } from '../../core/store/mainStore';
import * as dictionary from '../../core/constants/dictionary';
import { useRetailList } from '../../hooks/RetailHooks';
import {
  styles,
  parseFilters,
  INITIAL_FILTERS,
  marginLeftOffset,
  FETCH_VISITS_DEFAULT_PARAM,
  XLSX_REPORT,
} from './utils';

const VisitsHistory = () => {
  const snackBar = utils.getSnackbar();
  const [filters, setFilters] = useState({
    ...INITIAL_FILTERS,
    retail: {
      id: mainStore?.loggedUser?.retailId
    },
  });
  const [stores, setStores] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [loadingSuppliers, setLoadingSuppliers] = useState(false);
  const [visits, setVisits] = useState([]);
  const [partQueryParam, setPartQueryParam] = useState('');
  const [toolbarInputFocus, setToolbarInputFocus] = useState(false);
  const [hasInitialContent, setHasInitialContent] = useState(false);
  const {retails, fetch: fetchRetails} = useRetailList();
  const tableRef = useRef(null);

  const fetchVisits = async (filterParams) => {
    mainStore.requestLoading = true;

    let mountParams = {
      offset: page * rowsPerPage,
      q: partQueryParam,
      limit: rowsPerPage,
      orderBy: FETCH_VISITS_DEFAULT_PARAM.checkinAt,
      orderDirection: FETCH_VISITS_DEFAULT_PARAM.orderDirection,
    };

    if (filterParams) {
      mountParams = { ...filterParams, ...mountParams };
    } else {
      mountParams = {
        ...parseFilters(filters),
        ...mountParams,
      };
    }

    try {
      const { data } = await getVisitsPaginated(mountParams);
      setVisits(data.items);
      setTotal(data.count);
      setHasInitialContent(data.total > 0);
      mainStore.requestLoading = false;
    } catch (err) {
      mainStore.requestLoading = false;
    }
  };

  const handleExportXlsx = async () => {
    mainStore.requestLoading = true;

    try {
      const res = await exportVisitsXls(parseFilters(filters));
      const blob = new Blob([res.data], {
        type: XLSX_REPORT.type,
      });
      saveAs(blob, XLSX_REPORT.fileName);
      mainStore.requestLoading = false;
    } catch (e) {
      utils.openSnackBar(dictionary.ERROR, dictionary.UNKNOWN_ERROR);
      mainStore.requestLoading = false;
    }
  };

  const clearFilter = () => {
    setFilters(INITIAL_FILTERS);
    fetchVisits(INITIAL_FILTERS);
  };

  useEffect(() => {
    if (filters.retail) {
      fetchVisits();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, partQueryParam, filters.retail]);

  const getVisitsOnDebounce = async (inputValue) => {
    inputValue = String(inputValue);
    setPage(0);
    setPartQueryParam(inputValue);
  };

  const debouncedOnSearchTable = useDebounce(getVisitsOnDebounce, 500);

  useEffect(() => {
    const shouldFocus = partQueryParam.length > 0;
    setToolbarInputFocus(shouldFocus);
  }, [partQueryParam]);

  const loadStores = async (retail) => {
    setStores(
      await getActiveStoresByRetail(retail.id)
    );
  };

  const loadSuppliers = async (retail, query) => {
    setLoadingSuppliers(true);

    let data = [];
    if (retail) {
      data = await getSuppliersByRetail({
        q: query,
        status: 1, 
        retailId: retail.id, 
      });
    }

    setSuppliers(data.items);
    setLoadingSuppliers(false);
  };

  useEffect(() => {
    if (!profileCheck.isSuperAdmin()) {
      const user = mainStore?.loggedUser;
      const retail = {
        id: user?.retailId,
      };
      setFilters({ ...filters, retail });

      return;
    }

    fetchRetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filters.retail) {
      loadStores(filters.retail);
      loadSuppliers(filters.retail, '');
    }
  }, [filters]);

  const renderRetailFilter = useMemo(() => {
    return (
      <Autocomplete
        required={false}
        label={dictionary.RETAIL}
        value={filters.retail}
        options={retails}
        onChange={(_, retail) => {
          setFilters({
            ...filters,
            retail,
            store: null,
            supplier: null,
          });

          setStores([]);
          setSuppliers([]);
        }}
        style={styles.autocompleteFilter}
      />
    );
  }, [filters, retails]);

  const renderStoreFilter = useMemo(() => {
    return (
      <Autocomplete
        required={false}
        label={dictionary.STORE}
        style={{
          ...styles.autocompleteFilter,
          marginLeft: profileCheck.isSuperAdmin() ? marginLeftOffset : 0,
        }}
        value={filters.store}
        onChange={(e, store) => setFilters({ ...filters, store })}
        options={stores}
        disabled={stores.length === 0}
      />
    );
  }, [filters, stores]);

  const renderSupplierFilter = useMemo(() => {
    return (
      <SupplierFilter
        value={filters.supplier}
        options={suppliers}
        disabled={!filters.retail}
        loading={loadingSuppliers}
        onChange={(e, supplier) => setFilters({ ...filters, supplier })}
        onSearch={(query) => loadSuppliers(filters.retail, query)}
      />
    );
  }, [filters, suppliers, loadingSuppliers]);

  const renderStartDateFilter = useMemo(() => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
        <KeyboardDatePicker
          autoOk
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          value={filters.startDate}
          label={dictionary.DATE_START_SELECT}
          invalidDateMessage={dictionary.INVALID_DATE}
          maxDate={new Date()}
          onChange={(startDate) => {
            setFilters({
              ...filters,
              startDate: startDate ? startOfDay(startDate) : null,
            });
          }}
          style={styles.datePickerStart}
        />
      </MuiPickersUtilsProvider>
    );
  }, [filters]);

  const renderEndDateFilter = useMemo(() => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
        <KeyboardDatePicker
          autoOk
          clearable
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          value={filters.endDate}
          label={dictionary.DATE_END_SELECT}
          invalidDateMessage={dictionary.INVALID_DATE}
          minDate={filters.startDate}
          maxDate={new Date()}
          onChange={(endDate) => {
            setFilters({
              ...filters,
              endDate: endDate ? endOfDay(endDate) : null,
            });
          }}
          style={styles.datePickerEnd}
        />
      </MuiPickersUtilsProvider>
    );
  }, [filters]);

  return (
    <>
      <PageContent
        title={dictionary.VISIT_HISTORY}
        titleStyle={{ color: '#8A90A7', fontWeight: 600, fontSize: 16 }}
      >
        <div className="page-filters" style={{ marginBottom: 40 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 16,
            }}
          >
            <FilterList style={{ color: '#8A90A7', marginRight: 11 }} />
            <Typography variant="h1" style={{ fontSize: 16, fontWeight: 600 }}>
              {dictionary.FILTERS}
            </Typography>
          </div>

          <div style={styles.flex}>
            {profileCheck.isSuperAdmin() && renderRetailFilter}
            {renderStoreFilter}
            {renderSupplierFilter}
          </div>

          <div style={styles.flex}>
            {renderStartDateFilter}
            {renderEndDateFilter}
          </div>

          <div style={styles.flex}>
            <DefaultButton
              label={dictionary.SEARCH}
              color="primary"
              onClick={() => fetchVisits()}
              style={styles.defaultButton}
            />
            <CancelButton
              label={dictionary.CLEAR}
              onClick={clearFilter}
              style={styles.cancelButton}
            />
          </div>
        </div>

        {!hasInitialContent && (
          <WelcomePage>
            <Typography variant="body1">{dictionary.NO_VISITS}</Typography>
          </WelcomePage>
        )}

        <div
          className="reverse-table"
          style={{ visibility: hasInitialContent ? 'visible' : 'hidden' }}
        >
          <VisitsHistoryTable
            ref={tableRef}
            data={visits}
            count={total}
            pageIndex={page}
            rowsPerPage={rowsPerPage}
            searchTextValue={partQueryParam}
            toolbarInputFocus={toolbarInputFocus}
            onChangePageHandler={(page) => setPage(page)}
            onChangeRowsPerPageHandler={(value) => {
              setPage(0);
              setRowsPerPage(value);
            }}
            onSearchChangeHandler={(value) => debouncedOnSearchTable(value)}
          />
        </div>
      </PageContent>

      {total > 0 && (
        <StickyFooter
          actionButton={
            <div className="button-create-container">
              <DefaultButton
                label={dictionary.EXPORT_VISITS}
                color="primary"
                onClick={handleExportXlsx}
                style={styles.defaultButton}
              />
            </div>
          }
        />
      )}

      {snackBar}

      <Loading isOpen={mainStore.requestLoading} />
    </>
  );
};

export default view(VisitsHistory);
