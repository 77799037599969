import { PromotionalSpotType } from '../../../../core/types/promotional-spot/promotional-spot-type.enum';
export enum PromotionalSpotProductReportStatus {
  FUTURE = 'FUTURE',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  EXECUTED = 'EXECUTED',
}

export enum PromotionalSpotProductReportStatusLabel {
  FUTURE = 'Previsto',
  PENDING = 'Pendente',
  EXPIRED = 'Expirado',
  EXECUTED = 'Executado',
}
export type PromotionalSpotReportTableLineDto = {
  store: string;
  product: string;
  suppliers: string[];
  addressing: string;
  spotType: PromotionalSpotType;
  spotName: string;
  startAt: string;
  endAt: string;
  status: PromotionalSpotProductReportStatus;
  negotiationExecutionProblemType: string;
  negotiationObservation: string;
};
