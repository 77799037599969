import { profileCheck } from '../../../core/profiles';
import {
  SUPER_ADMIN,
  RETAIL_ADMIN,
  SALES_REP,
  BUYER,
  PROMOTERS_APPROVER,
  STORE_MANAGER,
  DOOR_SECURITY,
  RESTOCKER,
} from '../../../core/constants/dictionary';
import { mainStore } from '../../../core/store/mainStore';

type ProfileFilter = {
  0?: string;
  1: string;
  2: string;
  3?: string;
  4?: string;
  5: string;
  6: string;
  7: string;
  8: string;
};
export const getProfileFilter = () => {
  const filter: ProfileFilter = {
    1: RETAIL_ADMIN,
    2: BUYER,
    5: PROMOTERS_APPROVER,
    6: STORE_MANAGER,
    7: DOOR_SECURITY,
    8: RESTOCKER,
  };

  if (mainStore.loggedUser && profileCheck.isSuperAdmin()) {
    filter['0'] = SUPER_ADMIN;
    filter['3'] = SALES_REP;
  }

  return filter;
};
