import React, { createContext, useCallback, useContext, useState } from 'react';
import {
  getPromotionalNegotiationPanel,
  getNegotiationPanelMetrics,
} from '../../../../core/services/promotionalSpots/promotionalNegotiationPanelService';
import { PromotionalNegotiationPanelDto } from '../../../../core/services/promotionalSpots/types';
import { getStoreOrderByStoreCode } from '../../../../core/services/stores/storesService';
import {
  PromotionalNegotiationPanelMetricsDto,
  promotionalNegotiationPanelMetricsInitialValue,
} from '../../../../core/types/promotional-spot/promotional-negotiation-panel-metrics.dto';
import { utils } from '../../../../core/utils';
import useRetailInUser from '../../common/components/hooks/use-retail-in-user.hook';
import { translatePromotionalSpotTypeToAPI } from '../../common/utils';
import { OnSubmitPromotionaslNegotiationPanelFilterParams } from '../types/promotional-negotiation-panel.entity';
import * as dictionary from '../../../../core/constants/dictionary';
import { StoreDto } from '../../Addressing/types';
import { useFormField } from '../hooks/FormFieldHook';
import { translateStoreAreaLabelToAPI } from '../../Addressing/utils';

type ContextProps = {
  retailInUser: any;
  periodsSpot: PromotionalNegotiationPanelDto[];
  panelMetrics: PromotionalNegotiationPanelMetricsDto;
  isLoading: boolean;
  isLoadingStores: boolean;
  stores: StoreDto[];
  fetchData: Function;
  fetchStores: Function;
  fetchMetrics: Function;
  onSubmitFilterHandler: Function;
  onClearFilterHandler: Function;
  reloadMetrics: Function;
  form: any;
};

const NegotiationPanelContext = createContext<ContextProps>({
  fetchData: () => {},
  fetchStores: () => {},
  isLoading: false,
  isLoadingStores: false,
  onClearFilterHandler: () => {},
  onSubmitFilterHandler: () => {},
  fetchMetrics: () => {},
  reloadMetrics: () => {},
  panelMetrics: promotionalNegotiationPanelMetricsInitialValue,
  periodsSpot: [],
  stores: [],
  retailInUser: {},
  form: {},
});

export const useNegotiationPanelContext = () =>
  useContext(NegotiationPanelContext);

const NegotiationPanelContextProvider = ({ children }: any) => {
  const retailInUser = useRetailInUser();

  const [periodsSpot, setPeriodsSpot] = useState<
    PromotionalNegotiationPanelDto[]
  >([]);
  const [panelMetrics, setPanelMetrics] =
    useState<PromotionalNegotiationPanelMetricsDto>(
      promotionalNegotiationPanelMetricsInitialValue
    );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [stores, setStores] = useState([]);
  const [isLoadingStores, setIsLoadingStores] = useState<boolean>(false);

  const form = useFormField();
  const fetchStores = useCallback(
    async (retailId: string) => {
      if (!isLoadingStores) {
        try {
          setIsLoadingStores(true);
          const stores = await getStoreOrderByStoreCode(retailId);
          setStores(stores);
          setIsLoadingStores(false);
        } catch (error) {
          setStores([]);
          setIsLoadingStores(false);
          utils.openSnackBar(
            dictionary.ERROR,
            dictionary.GET_PROMOTIONAL_SPOT_PANEL_ERROR
          );
        }
      }
    },
    [isLoadingStores]
  );

  const fetchMetrics = async (
    params?: OnSubmitPromotionaslNegotiationPanelFilterParams & {
      vigentAfterAt: string | undefined;
    }
  ) => {
    try {
      const { data: metrics } = await getNegotiationPanelMetrics({
        ...params,
        startAt: undefined,
        vigentIn: undefined,
      });
      setPanelMetrics(metrics);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = useCallback(
    async (params?: OnSubmitPromotionaslNegotiationPanelFilterParams) => {
      if (!isLoading && params?.retailId) {
        const vigentAfterAt =
          !params?.periodIds?.length && !params?.month && !params?.year
            ? new Date().toISOString()
            : undefined;
        try {
          setIsLoading(true);
          const [{ data }] = await Promise.all([
            getPromotionalNegotiationPanel({
              ...params,
              startAt: undefined,
              vigentIn: undefined,
              vigentAfterAt,
            }),
            fetchMetrics({
              ...params,
              startAt: undefined,
              vigentIn: undefined,
              vigentAfterAt,
            }),
          ]);
          setPeriodsSpot(data);
          setIsLoading(false);
        } catch (err) {
          if (err?.response?.status === 404) {
            setPeriodsSpot([]);
          }
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [isLoading]
  );

  const onSubmitFilterHandler = useCallback(
    async (params?: OnSubmitPromotionaslNegotiationPanelFilterParams) => {
      fetchData(params);
      fetchStores(params?.retailId ?? retailInUser);
    },
    [isLoading, retailInUser]
  );

  const reloadMetrics = () => {
    const periods = form.values.period.map((item: any) => item.id);
    const vigentAfterAt =
      !periods?.length && !form.values?.month && !form.values?.year
        ? new Date().toISOString()
        : undefined;
    fetchMetrics({
      vigentAfterAt,
      year: form.values.year ? parseInt(form.values.year) : undefined,
      month: form.values.month ? form.values.month : undefined,
      periodIds: form.values.period.map((item: any) => item.id ?? ''),
      spotTypes: form.values.type.map(translatePromotionalSpotTypeToAPI),
      sectorIds: form.selectedSector?.map((sector: any) => sector?.id),
      storeAreas: form.values.area.map(translateStoreAreaLabelToAPI),
      retailId: form.values.retail?.id ?? retailInUser,
    });
  };

  const onClearFilterHandler = useCallback(async () => {
    let retailId = retailInUser;
    fetchData({
      retailId,
    });
  }, [retailInUser, isLoading]);

  return (
    <NegotiationPanelContext.Provider
      value={{
        retailInUser,
        periodsSpot,
        panelMetrics,
        isLoading,
        isLoadingStores,
        stores,
        fetchData,
        fetchStores,
        fetchMetrics,
        onSubmitFilterHandler,
        onClearFilterHandler,
        form,
        reloadMetrics,
      }}
    >
      {children}
    </NegotiationPanelContext.Provider>
  );
};

export function withNegotiationPanelContext(Component: any) {
  return (props: any) => {
    return (
      <NegotiationPanelContextProvider>
        <Component {...props} />
      </NegotiationPanelContextProvider>
    );
  };
}

export default NegotiationPanelContextProvider;
