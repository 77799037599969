import retails from '../../assets/menu/retails.svg';
import agreements from '../../assets/menu/handshake.svg';
import promoters from '../../assets/menu/promoters.svg';
import stores from '../../assets/menu/stores.svg';
import suppliers from '../../assets/menu/suppliers.svg';
import users from '../../assets/menu/users.svg';
import visits from '../../assets/menu/visits.svg';
import assignment from '../../assets/menu/assignment.svg';
import promotionalSpots from '../../assets/menu/promotional_spots.svg';
import expiration from '../../assets/menu/expiration.svg';
import * as routes from '../../core/constants/routes';
import * as dictionary from '../../core/constants/dictionary';

export type Route = {
  name: string;
  icon?: string;
  route?: string;
  target?: string;
  goBack?: boolean;
  groupRoute?: string;
  submenu?: boolean | Route[];
};

const sidebarMenuitems: Route[] = [
  {
    icon: retails,
    name: dictionary.RETAILS,
    route: routes.RETAILS,
    submenu: false,
  },
  {
    name: dictionary.AGREEMENTS,
    route: routes.AGREEMENTS,
    icon: agreements,
    submenu: false,
  },
  {
    icon: promoters,
    name: dictionary.PROMOTERS,
    groupRoute: routes.PROMOTERS,
    submenu: [
      {
        name: dictionary.PROMOTERS,
        icon: promoters,
        goBack: true,
      },
      {
        name: dictionary.REGISTER_PROMOTER,
        route: routes.PROMOTERS_REGISTER,
        submenu: false,
      },
      {
        name: dictionary.LIBERATE_ACCESS,
        route: routes.PROMOTERS_LIBERATE,
        submenu: false,
      },
      {
        name: dictionary.SUSPENDED_ACCESSES,
        route: routes.PROMOTERS_SUSPENDED,
        submenu: false,
      },
    ],
  },
  {
    icon: stores,
    name: dictionary.STORES,
    route: routes.STORES,
  },
  {
    icon: users,
    name: dictionary.USERS,
    route: routes.USERS,
    submenu: false,
  },
  {
    icon: visits,
    name: dictionary.VISITS,
    groupRoute: routes.VISITS,
    submenu: [
      {
        name: dictionary.VISITS,
        icon: visits,
        goBack: true,
      },
      {
        name: dictionary.DAY_VISITS,
        route: routes.VISITS,
        submenu: false,
      },
      {
        name: dictionary.VISIT_HISTORY,
        route: routes.VISITS_HISTORY,
        submenu: false,
      },
      {
        name: dictionary.VISITS_DASHBOARD,
        route: routes.VISITS_PANEL,
        submenu: false,
      },
      {
        name: dictionary.AUTO_CHECKIN,
        route: routes.VISITS_AUTO_CHECK_IN,
        target: '_blank',
        submenu: false,
      },
    ],
  },
  {
    icon: suppliers,
    name: dictionary.SUPPLIERS,
    route: routes.SUPPLIERS,
    submenu: false,
  },
  {
    icon: assignment,
    name: dictionary.RESTOCK,
    groupRoute: routes.RESTOCK,
    submenu: [
      {
        name: dictionary.RESTOCK,
        icon: assignment,
        goBack: true,
      },
      {
        name: dictionary.RESTOCK_DASHBOARD,
        route: routes.RESTOCK_PANEL,
        submenu: false,
      },
      {
        name: dictionary.SUSPICIOUS_PRODUCTS,
        route: routes.SUSPICIOUS_PRODUCTS,
        submenu: false,
      },
      {
        name: dictionary.RESTOCK_REPORTS,
        route: routes.RESTOCK_REPORTS,
        submenu: false,
      },
    ],
  },
  {
    icon: expiration,
    name: dictionary.EXPIRATION_PANEL,
    route: routes.EXPIRATION_PANEL,
    submenu: false,
  },
  {
    icon: promotionalSpots,
    name: dictionary.POINT_OF_PURCHASE_DISPLAY,
    groupRoute: routes.PROMOTIONAL_SPOTS_GROUP,
    submenu: [
      {
        name: dictionary.POINT_OF_PURCHASE_DISPLAY,
        icon: promotionalSpots,
        goBack: true,
      },
      {
        name: dictionary.PROMOTIONAL_CALENDARS,
        route: routes.PROMOTIONAL_CALENDARS,
        submenu: false,
      },
      {
        name: dictionary.PROMOTIONAL_SPOTS,
        route: routes.PROMOTIONAL_SPOTS,
        submenu: false,
      },
      {
        name: dictionary.ADDRESSINGS,
        route: routes.ADDRESSINGS,
        submenu: false,
      },
      {
        name: dictionary.NEGOTIATIONS_PANEL,
        route: routes.NEGOTIATIONS_PANEL,
        submenu: false,
      },
      {
        name: dictionary.PROMOTIONAL_SPOT_NEGOTIATIONS_REPORTS_TITLE,
        route: routes.NEGOTIATIONS_PRODUCT_REPORT,
        submenu: false,
      },
      {
        name: dictionary.PROMOTIONAL_SPOT_NEGOTIATIONS_PROBLEMS_REPORTS,
        route: routes.NEGOTIATIONS_PROBLEMS_REPORT,
        submenu: false,
      },
    ],
  },
];

export default sidebarMenuitems;
