import React, { useState } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Button, IconButton, Tooltip, makeStyles, Box, TablePagination } from '@material-ui/core';
import * as dictionary from '../../../../core/constants/dictionary';
import { mainFontFamily } from '../../../../Theme/fonts';
import { colors } from '../../../../Theme/colors';

const useStyles = makeStyles({
  button: {
    fontWeight: '600',
    width: '100%',
  },
});

export const PromotersTable = ({
  data,
  handleRemove,
  loading,
  count,
  rowsPerPage,
  pageIndex,
  searchTextValue,
  toolbarInputFocus,
  onChangePageHandler,
  onChangeRowsPerPageHandler,
  onSearchChangeHandler,
}) => {
  const [filtering, setFiltering] = useState(false);
  const classes = useStyles();

  return (
    <MaterialTable
      title=""
      data={data}
      isLoading={loading}
      localization={{
        header: { actions: '' },
        body: {
          emptyDataSourceMessage: 'Nenhum promotor vinculado a este acordo',
        },
        pagination: {
          labelDisplayedRows: '{from}-{to} de {count}',
          labelRowsSelect: dictionary.LINES,
        },
      }}
      options={{
        filtering,
        actionsColumnIndex: -1,
        searchFieldAlignment: 'left',
        rowStyle: { fontFamily: mainFontFamily, fontSize: '13px' },
        pageSize: rowsPerPage,
        pageSizeOptions: [5, 10, 20],
      }}
      columns={[
        {
          title: dictionary.NAME,
          field: 'name',
        },
        { title: dictionary.CPF, field: 'cpf' },
        {
          title: dictionary.STATUS,
          field: 'status',
          lookup: {
            0: dictionary.PENDING,
            1: dictionary.RELEASED,
            2: dictionary.SUSPENDED,
          },
          cellStyle: rowData => {
            return rowData === dictionary.RELEASED
              ? { color: colors.green_600 }
              : '';
          },
          render: ({ status }) => {
            if (status === 0) return dictionary.PENDING;
            if (status === 1) return dictionary.RELEASED;
            return dictionary.SUSPENDED;
          },
        },
      ]}
      actions={[
        { isFreeAction: true, icon: '', onClick: ()=> {}},
        { icon: 'suspendAccess',  onClick: ()=> {}},
      ]}
      components={{
        Action: ({ action, data }) => {
          const { status } = data;
          if (action.icon === 'suspendAccess') {
            return (
              <>
                {status === 1 && (
                  <Tooltip title="Suspende acesso do promotor nesse contrato">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleRemove(data)}
                      className={classes.button}
                    >
                      {dictionary.SUSPEND_ACCESS}
                    </Button>
                  </Tooltip>
                )}
                {(status === 0 || status === 2) && (
                  <Tooltip title="Liberar acesso do promotor nesse contrato">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleRemove(data)}
                      className={classes.button}
                    >
                      {dictionary.RELEASE_ACCESS}
                    </Button>
                  </Tooltip>
                )}
              </>
            );
          }
          return (
            <Tooltip title="Filtrar">
              <IconButton onClick={() => setFiltering(!filtering)}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          );
        },
        Pagination: (props) => {
          return (
            <Box display="flex" justifyContent="flex-end">
              <TablePagination
                {...props}
                count={count}
                page={pageIndex}
                rowsPerPage={rowsPerPage}
                onChangePage={(evt, page) => onChangePageHandler(page)}
                onChangeRowsPerPage={(event) => onChangeRowsPerPageHandler(parseInt(event.target.value))}
              />
            </Box>
          );
        },
        Toolbar: (props) => {
          if(onSearchChangeHandler) {
            return (
              <MTableToolbar 
                {...props} 
                searchAutoFocus={toolbarInputFocus}
                searchText={searchTextValue}
                onSearchChanged={(value) => onSearchChangeHandler(value)}
              />
            );  
          }
          else return <MTableToolbar {...props} />;  
        },
      }}
    />
  );
};
