import React from 'react';
import classes from './styles.module.scss';

export default ({ title, children }) => {
    return (
        <section className={classes.section}>
            <h2 className={classes.title}>{title}</h2>
            { children }
        </section>
    )
}