import React, {ReactNode, useMemo} from 'react';
import {Paper} from '@material-ui/core';
import DefaultButton from '../Buttons/Default';
import {exist} from '../../core/validation';
import doorsLogo from '../../assets/logos/doors-logo.svg';
import * as dictionary from '../../core/constants/dictionary';
import classesModule from './WelcomePage.module.scss';

type Props = {
  children: ReactNode,
  handleButton?: Function;
  textButton?: string; 
  classes?: {
    logo?: string;
    button?: string;
    children?: string;
    container?: string;
  }
}

const WelcomePage = ({ 
  classes,
  children,
  textButton, 
  handleButton, 
}: Props) => {
  const renderActionButton = useMemo(() => {
    if (exist(handleButton) || exist(textButton)) {
      return (
        <div>
          <DefaultButton 
            label={textButton} 
            onClick={handleButton}
          />
        </div>
      );
    }
    return '';
  }, [handleButton, textButton]);

  return (
    <div className={classesModule.container}>
      <Paper className={`empty-welcome-container ${classesModule.paper} ${classes?.container}`}>
        <div className={classes?.logo}>
          <img 
            src={doorsLogo} 
            alt={dictionary.INVOLVES_DOORS_LOGO} 
          />
        </div>

        <div className={`${classes?.children} ${classesModule.children}`}>
          {children}
        </div>

        {renderActionButton}
      </Paper>
    </div>
  );
};

export default WelcomePage;
