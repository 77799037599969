import React from 'react';
import { TableBody, TableCell, TableRow, } from '@material-ui/core';
import { ProductSuspension } from '../../../core/types/product-suspension.type';
import { getProductSuspensionItemDTO } from '../utils';

type TableBodyProps = {
  data?: ProductSuspension[];
}

const SuspiciousProductsTableBody = ({ data }: TableBodyProps) => {
  return (
    <TableBody>
      {
        data && data.map((productSuspension: ProductSuspension) => {
          const dto = getProductSuspensionItemDTO(productSuspension);
        
          return (
            <TableRow key={dto.product.code}>
              <TableCell>{dto.storeNameAndCode}</TableCell>
              <TableCell>{dto.createdAt}</TableCell>
              <TableCell>{dto.skuNameAndCode}</TableCell>
              <TableCell>{dto.daysWithoutSale}</TableCell>
              <TableCell>{dto.shoppersPerDay}</TableCell>
              <TableCell>{dto.avgRevenue}</TableCell>
              <TableCell>{dto.statusAndProblemsDescription}</TableCell>
              <TableCell>{dto.expiresOn}</TableCell>
            </TableRow>
          );
        })
      }
    </TableBody>
  );
};

export default SuspiciousProductsTableBody;
