import { GetProductFamiliesParams, GetProductParams } from './types';
import {
  getFamiliesProductsInRetailAPI,
  getProductsInRetailAPI,
} from './familyProductAPI';

export const getFamiliesProductsInRetail = (params: GetProductFamiliesParams) => {
  return getFamiliesProductsInRetailAPI(params);
};

export const getProductsInRetail = (params: GetProductParams) => {
  return getProductsInRetailAPI(params);
};
