import React from 'react';
import FormModal from './FormModal';

type Props = {
  isOpen: boolean;
  onClose: Function;
  onSubmit: Function;
  isEditable: boolean;
  data: {
    title?: string;
    dateTime?: string;
    id?: string;
    product: any[];
    supplier: any[];
    description: string;
    familiesIds?: string[];
  };
};

const UpdateModal = ({
  data,
  isOpen,
  onClose,
  onSubmit,
  isEditable,
}: Props) => {
  return (
    <FormModal
      isEditable={isEditable}
      isUpdate={true}
      title={data.title}
      datetime={data.dateTime}
      data={data}
      isOpen={isOpen}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export default UpdateModal;
