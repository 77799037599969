import API from '../api/config';
import STORE_MANAGER_API from '../api/configStoreManagerLogin';
import * as routes from '../api/routes';

export const signInAPI = async (cpf, password) => {
  const url = routes.API_LOGIN;
  const data = { cpf, password };
  const config = { headers: { 'doors-platform': 'web' } };
  const response = await API.post(url, data, config);
  return response.data;
};

export const signInAuthCheckinTokenAPI = async () => {
  const url = routes.API_LOGIN_AUTO_CHECKIN;
  const config = { headers: { 'doors-platform': 'web' } };
  const response = await API.post(url, {}, config);
  return response.data;
};

export const liberateAccessByStoreManagerLoginAPI = async ({cpf, password, promoterId, timezone, qrcodeId }) => {
  const url = routes.API_VISITS + routes.API_VISITS_LIBERATE_ACCESS;
  const data = { 
    cpf, 
    password, 
    promoterId, 
    timezone, 
    qrcodeId,
    promoterUserId: promoterId,
  };
  const response = await STORE_MANAGER_API.post(url, data);
  
  return response.data;
}
