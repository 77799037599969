import { Typography } from '@material-ui/core';
import React from 'react';
import classes from './TitleAndSubtitle.module.scss';
type Props = {
  title: string;
  subtitle: string;
};
const TitleAndSubtitle = ({ title, subtitle }: Props) => {
  return (
    <div className={classes.titleAndSubtitle}>
      <Typography className={classes.subtitle}>{title}:</Typography>
      <Typography className={classes.subtitleValue}>{subtitle}</Typography>
    </div>
  );
};

export default TitleAndSubtitle;
