import React, { useState, useCallback, useMemo } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextFieldMUI from '@material-ui/core/TextField';
import { validator } from '../../core/validator/validator';

const useStyles = makeStyles(theme => ({
  margin: theme.sizes.defaultInputsMarginSize,
  multilineMargin: theme.sizes.multiInputsMarginSize,
}));

const CssTextFieldStyled = withStyles(theme => theme.sizes.defaultTextField)(
  TextFieldMUI,
);

const CssMultiTextFieldStyled = withStyles(theme => theme.sizes.multiTextField)(
  TextFieldMUI,
);

const TextField = ({
  id = undefined,
  autoComplete = "on",
  required = true,
  label = "",
  type = "text",
  value = "",
  onChange = undefined,
  onKeyPress = undefined,
  style = {},
  placeholder = "",
  name = undefined,
  InputProps = {},
  fullWidth = undefined,
  multiline = false,
  validations = undefined,
  disabled = false,
  variant = "outlined",
  showError = false,
  errorText = '',
  className = '',
  inputStyle = {},
}) => {
  const classes = useStyles();
  const [hasError, setHasError] = useState(false);
  const [helperText, setHelperText] = useState('');
  
  const CssTextField = useMemo(() => {
    if (multiline) {
      return CssMultiTextFieldStyled;
    }
    return CssTextFieldStyled;
  }, [multiline]);

  const handleOnBlur = useCallback((event, validatorvalue) => {
    if (validatorvalue) {
      const validatorObject = validatorvalue(event);
      setHasError(validatorObject.hasError);
      setHelperText(validatorObject.helperText);
    }
  }, []);

  const validatorvalue = useMemo(() => {
    return validations && validations.length > 0
      ? validator(validations)
      : undefined;
  }, [validations]);

  const inputprops = useMemo(() => {
    return multiline
      ? {
          style: {
            paddingTop: '10px',
            paddingBottom: '0',
            paddingLeft: '9px',
            minHeight: '30px',
            ...inputStyle,
          },
        }
      : { 
        style: { 
          height: '40px',
          ...inputStyle, 
        } 
      };
  }, [multiline]);

  return (
    <CssTextField
      id={id}
      autoComplete={autoComplete}
      onBlur={event => handleOnBlur(event, validatorvalue)}
      error={showError ? showError : hasError}
      helperText={errorText ? errorText : helperText}
      inputProps={inputprops}
      FormHelperTextProps={{ style: { height: 'auto' } }}
      margin="normal"
      variant={variant}
      fullWidth={fullWidth}
      multiline={multiline}
      placeholder={placeholder}
      name={name}
      style={style}
      className={
        className 
        ? className 
        : (
          multiline === true ? classes.multilineMargin : classes.margin
        )
      }
      required={required}
      label={label}
      type={type}
      value={value}
      onChange={onChange}
      onKeyPress={onKeyPress}
      InputProps={InputProps}
      disabled={disabled}
    />
  );
};

TextField.defaultProps = {
  required: true,
  type: 'text',
  style: { width: '50%' },
  multiline: false,
  disabled: false,
  error: false,
  variant: 'outlined',
  autoComplete: 'on',
};

export default TextField;
