import API from '../api/config';
import * as routes from '../api/routes';
import { GetProductsDto, GetProductsParams } from './types';

export const getProductsAPI = async ({
  offset = 0,
  limit = 999,
  order,
  status,
  orderBy,
  groupId,
  groupName,
  subgroupId,
  subgroupName,
  retailId,
  q,
}: GetProductsParams): Promise<GetProductsDto> => {
  const queryParams = new URLSearchParams({
    limit: ''+limit,
    offset: ''+offset,
  });
  if (order) {
    queryParams.append('order', order);
  }
  if (status) {
    queryParams.append('status', ''+status);
  }
  if (orderBy) {
    queryParams.append('orderBy', orderBy);
  }
  if (groupId) {
    queryParams.append('groupId', groupId.join(','));
  }
  if (groupName) {
    queryParams.append('groupName', groupName);
  }
  if (subgroupId) {
    queryParams.append('subgroupId', subgroupId.join(','));
  }
  if (subgroupName) {
    queryParams.append('subgroupName', subgroupName);
  }
  if (retailId) {
    queryParams.append('retailId', retailId);
  }
  if (q) {
    queryParams.append('q', q);
  }
  const url = `${routes.API_PRODUCTS}?${queryParams.toString()}`;
  const { data } = await API.get(url);
  
  return data;
};