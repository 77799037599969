import React, { useEffect, useMemo } from 'react';
import { view } from 'react-easy-state';
import Hide from '../../../components/Hide';
import Loading from '../../../components/Loading';
import PageContent from '../../../components/PageContent';
import StickyFooter from '../../../components/stickyFooter/stickyFooter';
import * as dictionary from '../../../core/constants/dictionary';
import {
  getPromotionalSpotReportProblems,
  getPromotionalSpotReportReportProblemsXLS
} from '../../../core/services/promotionalSpots/promotionalSpotReportService';
import { GetPromotionalSpotReportProblemsParams } from '../../../core/services/promotionalSpots/types';
import { PromotionalSpotReportProblemDto } from '../../../core/types/promotional-spot/promotional-spot-repot-data-dto';
import { useStoreData } from '../../../hooks/StoreHooks';
import ButtonDownload from '../common/components/ButtonDowload';
import useReport from '../common/components/hooks/use-report';
import PromotionalSpotTableReport from '../common/components/ReportTable';
import FormFilter from './components/FormFilter';
import {
  columnsForPromotionalSpotTableReportProblems,
  parseReportResultToTable
} from './utils';

const PromotionalSpotProblemsReport = () => {
  const {
    total,
    page,
    filter,
    report,
    rowsPerPage,
    excelFileURL,
    downloadXlsxButton,
    isLoading,
    isLoadingExport,
    setPage,
    fetchXls,
    fetchData,
    setFilter,
    setRowsPerPage,
  } = useReport<
    PromotionalSpotReportProblemDto,
    GetPromotionalSpotReportProblemsParams
  >({
    fetchDataRequest: getPromotionalSpotReportProblems,
    fetchXLS: getPromotionalSpotReportReportProblemsXLS,
    fileName: `${dictionary.PROMOTIONAL_SPOT_NEGOTIATIONS_REPORTS_TITLE} - ${dictionary.PROBLEMS}`,
  });

  const { isStoreManager } = useStoreData();
  const dowloadButtonRender = useMemo(
    () => (
      <ButtonDownload
        label={dictionary.EXPORT}
        disabled={isLoading || total === 0}
        onClick={fetchXls}
      />
    ),
    [isLoading, total, isLoadingExport, filter]
  );

  useEffect(() => {
    if (filter?.retailId) {
      fetchData({ ...filter });
    }
  }, [rowsPerPage, total, filter, page]);

  return (
    <>
      <PageContent
        title={dictionary.PROMOTIONAL_SPOT_NEGOTIATIONS_PROBLEMS_REPORTS}
      >
        <FormFilter
          isLoading={false}
          onSubmit={(args?: GetPromotionalSpotReportProblemsParams) => {
            setFilter(args);
            
            if (args?.offset) {
              setPage(0);
            }
          }}
        />
        {isLoading && <Loading isOpen />}
        <PromotionalSpotTableReport
          report={report}
          total={total}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePageHandler={(page: number) => setPage(page)}
          onChangeRowsPerPageHandler={(rowsPerPage: number) => {
            setPage(0);
            setRowsPerPage(rowsPerPage);
          }}
          columns={
            isStoreManager
              ? columnsForPromotionalSpotTableReportProblems.slice(1)
              : columnsForPromotionalSpotTableReportProblems
          }
          parseData={parseReportResultToTable}
          emptyMessage={dictionary.PROMOTIONAL_SPOT_REPORT_PROBLEMS_EMPTY_DATA}
        />
      </PageContent>
      <Hide>
        <a
          ref={downloadXlsxButton}
          href={excelFileURL}
          target="_blank"
          rel="noopener noreferrer"
        />
      </Hide>
      <StickyFooter actionButton={dowloadButtonRender} />
    </>
  );
};

export default view(PromotionalSpotProblemsReport);
