/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useMemo } from 'react';
import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import * as dictionary from '../../../../core/constants/dictionary';
import CancelButton from '../../../../components/Buttons/Cancel';
import DefaultButton from '../../../../components/Buttons/Default';
import { profileCheck } from '../../../../core/profiles';
import ModalTitle from '../../../../components/Modal/Title';

type Props = {
  promoter: any;
  closeModalCallback: Function;
  allowPromoterAccess: any;
  removePromoterAccess: any;
}
export const DetailContentModal = ({
  promoter,
  closeModalCallback,
  allowPromoterAccess,
  removePromoterAccess,
}: Props) => {
  const [title] = useState(
    promoter.status === 1
      ? dictionary.SUSPEND_PROMOTER_FOR_AGREEMENT
      : dictionary.RELEASE_PROMOTER_FOR_AGREEMENT,
  );

  const ProfilePermission = useMemo(() => {
    return (
      profileCheck.isSuperAdmin() ||
      profileCheck.isRetailAdmin() ||
      profileCheck.isPromoterApprover() ||
      profileCheck.isBuyer() ||
      profileCheck.isStoreManager()
    );
  }, []);

  const submitButtonText = useMemo(() => {
    if (promoter.status === 1 && ProfilePermission) {
      return dictionary.SUSPEND_ACCESS;
    }

    if (promoter.status === 2 && ProfilePermission) {
      return dictionary.RELEASE_ACCESS;
    }

    return '';
  }, [ProfilePermission, promoter]);

  const submitButtonAction = useMemo(() => {
    if (promoter.status === 1 && ProfilePermission) {
      return removePromoterAccess;
    }

    if (promoter.status === 2 && ProfilePermission) {
      return allowPromoterAccess;
    }

    return '';
  }, [ProfilePermission, allowPromoterAccess, promoter, removePromoterAccess]);

  const showSubmitButton = useMemo(() => {
    return (
      (promoter.status === 1 || promoter.status === 2) && ProfilePermission
    );
  }, [ProfilePermission, promoter]);

  return (
    <>
      <ModalTitle title={title} />
      <DialogContent>
        <Typography variant="h2" style={{ marginTop: '18px' }}>
          {promoter.agreementSupplierNames}
        </Typography>
        <Typography style={{ marginTop: '8px' }}>
          Promotor: {promoter.promoterUserName}
        </Typography>
        <Typography style={{ marginTop: '8px' }}>
          CPF: {promoter.promoterUserCpf}
        </Typography>
        <Typography style={{ marginTop: '40px', marginBottom: '48px' }}>
          {promoter.status === 1 && (
            <>
              Atenção, você tem certeza que deseja <b>suspender o acesso</b> do
              promotor para esse acordo?
            </>
          )}
          {promoter.status === 2 && (
            <>
              Atenção, você tem certeza que deseja <b>liberar o acesso</b> do
              promotor para esse acordo?
            </>
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={() => closeModalCallback()} />
        {
          showSubmitButton && (
          <DefaultButton
            label={submitButtonText}
            style={{ width: 'auto' }}
            onClick={() => submitButtonAction(promoter)}
          />
        )}
      </DialogActions>
    </>
  );
};
