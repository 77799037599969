import React from 'react';
import { Chip, Grid, Tooltip, Typography } from '@material-ui/core';
import classes from './Accordion.module.scss';

type Props = {
  title: string;
  label?: string;
  color: string;
  className?: string;
  showTooltip?: boolean;
};

const ChipColumn = ({
  title,
  label = undefined,
  color,
  className = '',
  showTooltip = false,
}: Props) => {
  return (
    <Grid item className={[className, classes.removeFontSizeGap].join(' ')}>
      { showTooltip ? (
          <Tooltip title={title} placement='top' arrow>
            <Typography className={classes.headerTitle}>
              {title}
            </Typography>
          </Tooltip>
        ) : ( 
          <Typography className={classes.headerTitle}>
            {title}
          </Typography>
        )
      }
      {label && (
        <Chip
          size="small"
          label={label}
          className={classes.chip}
          style={{ backgroundColor: color }}
        />
      )}
    </Grid>
  );
};

export default ChipColumn;
