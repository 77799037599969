import React from 'react';
import { StarRate } from './StarRate';
import { StyledContainer } from './style';

const StarsRate = ({ onChange, value, disabled }) => {
  return (
    <StyledContainer>
      <StarRate
        onChange={() => onChange(1)}
        selected={value >= 1}
        disabled={disabled}
      />
      <StarRate
        onChange={() => onChange(2)}
        selected={value >= 2}
        disabled={disabled}
      />
      <StarRate
        onChange={() => onChange(3)}
        selected={value >= 3}
        disabled={disabled}
      />
      <StarRate
        onChange={() => onChange(4)}
        selected={value >= 4}
        disabled={disabled}
      />
      <StarRate
        onChange={() => onChange(5)}
        selected={value === 5}
        disabled={disabled}
      />
    </StyledContainer>
  );
};

export { StarsRate };
