import { profileCheck } from '../../../core/profiles';
import { StoreArea } from '../../../core/types/store-area.enum';
import { StoreAreaLabel } from '../../../core/types/store-area-label.enum';
import { sortTextList } from '../../../core/strings';

export const EmptyCurrentAddressing = {
  id: '',
  store: '',
  physicalAddress: '',
  storeSector: '',
  storeArea: '',
  spot: {
    type: undefined,
    name: '',
  },
  sector: {
    id: '',
    name: '',
  },
};

export const INITIAL_FILTERS = {
  store: '',
  type: '',
};
export const profileShouldSeeStores = () => {
  if (profileCheck.isStoreManager()) {
    return false;
  }
  return true;
};

export const PromotionalSpotAddressingAreaOptions = sortTextList([
  StoreAreaLabel.BAKERY,
  StoreAreaLabel.BAZAAR,
  StoreAreaLabel.BUTCHER_SHOP,
  StoreAreaLabel.CELLAR,
  StoreAreaLabel.CENTRAL_HALL,
  StoreAreaLabel.CHECKOUT,
  StoreAreaLabel.ELECTRO,
  StoreAreaLabel.ENTRY_HALL,
  StoreAreaLabel.FRONT_HALL,
  StoreAreaLabel.PRODUCE,
  StoreAreaLabel.REAR_HALL,
  StoreAreaLabel.ROTISSERIA,
  StoreAreaLabel.FLOWER_SHOP,
]);

export const translateStoreAreaLabelToAPI = (searchValue: StoreAreaLabel | string) => {
  const key = Object.keys(StoreAreaLabel)[
    Object.values(StoreAreaLabel).findIndex(
      (currentObjValue) => currentObjValue === searchValue
    )
  ] as keyof typeof StoreArea;

  return StoreArea[key];
};

export const translateAPIToStoreAreaLabel = (type: keyof typeof StoreArea) => {
  return StoreAreaLabel[type];
};

export const gridSize = profileCheck.isSuperAdmin() ? 3 : 4;
