import styled from 'styled-components';

export const StyledInputDropdownContainer = styled.div`
  position: relative;
`;

export const StyledInputDropdownCard = styled.div`
  display: flex;
  align-items: center;

  position: absolute;
  background: ${props => props.theme.palette.misc.white};
  height: 55px;
  width: 100%;
  opacity: ${props => (props.show ? 1 : 0)};
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  transition: ${({ theme }) => theme.common.transition};

  border: 1px solid;
  border-color: ${props => props.theme.palette.black[100]};
  border-radius: 4px;
  box-sizing: border-box;
`;
