import { useEffect, useState } from 'react';
import { getPromoterByIdOrCpf } from '../../../core/services/autoCheckin/autoCheckinService';
import { getValidCpfSubstring } from '../utils';

const usePromoter = (cpf, token) => {
  const [promoter, setPromoter] = useState(null);
  
  useEffect(() => {
    let mounted = true;
    const filteredCPF = getValidCpfSubstring(cpf);
    
    if (filteredCPF.length < 14) {
      setPromoter(null);
      return;
    }
    
    const fetchPromoter = async () => {
      const promoter = await getPromoterByIdOrCpf(filteredCPF, token);
      if (promoter) {
        mounted && setPromoter(promoter);
      }
    };

    if (filteredCPF && token) {
      fetchPromoter();
    } else {
      setPromoter(null);
    }

    return () => {
      mounted = false;
    };
  }, [cpf, token]);

  return promoter;
};

export { usePromoter };
