import React from 'react';
import { Dialog } from '@material-ui/core';

const ModalContainer = ({ 
  isOpen,
  children, 
  closeModalCallback, 
  width = 800, 
}) => (
  <Dialog
    fullWidth
    open={isOpen}
    maxWidth="lg"
    PaperProps={{
      style: {
        width: width,
        padding: '20px',
        overflow: 'hidden',
      },
    }}
    onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        closeModalCallback(event, reason);
      }
    }}
  >
    {children}
  </Dialog>
);

export default ModalContainer;
