import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Typography } from '@material-ui/core';
import { view } from 'react-easy-state';
import { StoreTable } from './components/StoreTable';
import { mainStore } from '../../core/store/mainStore';
import { loadInitialData } from './functions';
import Loading from '../../components/Loading';
import BoldText from '../../components/TextsElements/BoldText';
import DefaultButton from '../../components/Buttons/Default';
import WelcomePage from '../../components/WelcomePage';
import StickyFooter from '../../components/stickyFooter/stickyFooter';
import ModalContainer from '../../components/Modal/Container';
import ModalSmallContainer from '../../components/Modal/SmallContainer';
import PageContent from '../../components/PageContent';
import { CreateStore } from './modals/createStore/createStore';
import { EditStore } from './modals/editStore/editStore';
import { RemoveStore } from './modals/removeStore/removeStore';
import { utils } from '../../core/utils';
import { entityEmpty } from '../../core/validation';
import * as dictionary from '../../core/constants/dictionary';
import '../../Theme/main.css';

const Stores = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [currentStore, setCurrentStore] = useState(null);
  const storesData = mainStore.stores.data;

  useEffect(() => {
    (async function load() {
      await loadInitialData();
    })();
  }, []);

  const closeModal = useCallback(() => {
    setOpenModal(false);
    setModalType(null);
    setCurrentStore(null);
  }, []);

  const createModal = useMemo(() => {
    return (
      <ModalContainer closeModalCallback={closeModal} isOpen={openModal}>
        <CreateStore closeModalCallback={closeModal} />
      </ModalContainer>
    );
  }, [closeModal, openModal]);

  const editModal = useMemo(() => {
    return (
      <ModalContainer closeModalCallback={closeModal} isOpen={openModal}>
        <EditStore
          currentStore={currentStore}
          closeModalCallback={closeModal}
        />
      </ModalContainer>
    );
  }, [closeModal, currentStore, openModal]);

  const removeModal = useMemo(() => {
    return (
      <ModalSmallContainer closeModalCallback={closeModal} isOpen={openModal}>
        <RemoveStore
          currentStore={currentStore}
          closeModalCallback={closeModal}
        />
      </ModalSmallContainer>
    );
  }, [closeModal, currentStore, openModal]);

  const renderModal = useMemo(() => {
    const modals = {
      [dictionary.CREATE]: createModal,
      [dictionary.EDIT_STORE]: editModal,
      [dictionary.REMOVE_STORE]: removeModal,
    };
    return modals[modalType];
  }, [createModal, editModal, modalType, removeModal]);

  const handleOpenModal = useCallback((modalType, currentStore) => {
    setModalType(modalType);
    setOpenModal(true);
    setCurrentStore(currentStore);
  }, []);

  const snackBar = utils.getSnackbar();

  const stickFooter = useMemo(
    () => (
      <div className="button-create-container">
        <DefaultButton
          onClick={() => handleOpenModal(dictionary.CREATE)}
          color="primary"
          style={{ width: 'auto' }}
          label={dictionary.ADD_STORE}
        />
      </div>
    ),
    [handleOpenModal],
  );

  const getPageContent = useCallback(() => {
    if (storesData.length === 0) {
      return (
        <WelcomePage
          handleButton={() => handleOpenModal(dictionary.CREATE)}
          textButton={dictionary.ADD_STORE}
        >
          <Typography variant="body1">
            {dictionary.WELCOME_ADD_STORE}
            <BoldText value={dictionary.NEW_STORE} />
          </Typography>
        </WelcomePage>
      );
    }
    return (
      <div className="reverse-table">
        <StoreTable 
          data={storesData} 
          handleOpenModal={handleOpenModal} 
        />
      </div>
    );
  }, [handleOpenModal, storesData]);

  const content = useMemo(() => {
    return entityEmpty(storesData) ? '' : getPageContent();
  }, [getPageContent, storesData]);

  const renderStickyFooter = useMemo(() => {
    if (storesData && storesData.length > 0) {
      return <StickyFooter actionButton={stickFooter} />;
    }
    return '';
  }, [stickFooter, storesData]);

  return (
    <React.Fragment>
      <PageContent title={dictionary.STORE}>
        {content}
      </PageContent>

      {renderStickyFooter}

      {openModal && renderModal}
      {snackBar}

      <Loading isOpen={mainStore.requestLoading} />
    </React.Fragment>
  );
};

export default view(Stores);
