import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { view } from 'react-easy-state';
import {
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
} from '@material-ui/core';
import TextField from '../../../components/InputTextField';
import ModalTitle from '../../../components/Modal/Title';
import Autocomplete from '../../../components/Autocomplete';
import { CancelButton, DefaultButton } from '../../../components/Buttons';

import { useSnackBar } from '../../../context/Snackbars';
import { utils } from '../../../core/utils';
import { mainStore } from '../../../core/store/mainStore';
import { trackUserCreated } from '../../../core/mixpanel/mixPanelEvents';
import { CreateUserErrorCode } from '../../../core/types/user/create-user-error-code.enum';
import { exist, findValueInArray } from '../../../core/validation';
import { getActiveStoresByRetail } from '../../../core/services/stores/storesService';
import { getAllNotPromotersUsers } from '../../../core/services/users/usersService';
import { userProfile, profileCheck } from '../../../core/profiles';
import {
  invalid,
  SyncValidations,
  valid,
} from '../../../core/validator/validations';
import * as dictionary from '../../../core/constants/dictionary';
import {
  UserInfo,
  editUser,
  registerUser,
  emptyFormValue,
  getUsersProfiles,
  fetchActiveRetails,
  halfWidthInputStyle,
} from '../utils';
import { CpfTextMaskCustom } from '../../../core/masks/masks';
import { UserProfile } from '../../../core/types/user-profile.enum';

type Props = {
  isEditMode?: boolean;
  currentUser?: UserInfo;
  onClose: Function;
  onReload: Function;
};

export const BaseModal = ({
  currentUser,
  isEditMode = false,
  onClose,
  onReload,
}: Props) => {
  const [user, setUser] = useState<UserInfo>();
  const [retailOpts, setRetailOpts] = useState([]);
  const [storeOpts, setStoreOpts] = useState([]);
  const [confirmPassword, setConfirmPassword] = useState('');

  const { openSnackBar } = useSnackBar();

  useEffect(() => {
    (async function load() {
      mainStore.requestLoading = true;

      const retailsOpts = await fetchActiveRetails();
      const stores = await getActiveStoresByRetail(
        mainStore.loggedUser.retailId ?? currentUser?.retailId
      );
      setRetailOpts(retailsOpts);
      setStoreOpts(stores);

      const userValues = {
        ...currentUser,
        retail: findValueInArray(retailsOpts, 'id', currentUser?.retailId),
        store: findValueInArray(stores, 'id', currentUser?.storeId),
        profile: findValueInArray(
          getUsersProfiles(),
          'value',
          currentUser?.profile
        ),
      };
      setUser(userValues);

      mainStore.requestLoading = false;
    })();
  }, [currentUser]);

  const passwordMatch = useCallback(
    (event) => {
      if (user && user.password !== confirmPassword) {
        event.target.setCustomValidity(' ');
        return invalid(dictionary.PASSWORD_NOT_MATCH);
      }
      event.target.setCustomValidity('');
      return valid();
    },
    [user, confirmPassword]
  );

  const resetModal = useCallback(() => {
    setStoreOpts([]);
    setRetailOpts([]);
    setUser(emptyFormValue);
    onClose();
    onReload();
  }, [onClose, onReload]);

  const renderRetailField = useMemo(() => {
    if (!user?.profile || profileCheck.isRetailAdmin()) {
      return false;
    }

    return (
      user.profile.value !== userProfile.SUPER_ADMIN &&
      user.profile.value !== userProfile.SALES_REP &&
      user.profile.value !== userProfile.PROMOTER
    );
  }, [user]);

  const retailDropdown = useMemo(() => {
    if (renderRetailField) {
      return (
        <Autocomplete
          value={user?.retail}
          options={retailOpts}
          label={dictionary.RETAIL}
          validations={[SyncValidations.notEmpty]}
          onChange={async (e: any, retail: any) => {
            if (exist(retail)) {
              setUser({ ...user, retail, store: null });
              const stores = await getActiveStoresByRetail(retail.id);
              setStoreOpts(stores);
            } else {
              setUser({ ...user, retail, store: null });
              setStoreOpts([]);
            }
          }}
          style={halfWidthInputStyle}
        />
      );
    }
    return '';
  }, [renderRetailField, retailOpts, user]);

  const renderStoreField = useMemo(() => {
    if (!user?.profile) {
      return false;
    }

    return (
      user.profile.value === userProfile.STORE_MANAGER ||
      user.profile.value === userProfile.SECURITY ||
      user.profile.value === userProfile.RESTOCKER
    );
  }, [user]);

  const hasRetailId = useMemo(() => {
    return profileCheck.isRetailAdmin() || exist(user?.retail);
  }, [user]);

  const storeDropdown = useMemo(() => {
    if (renderStoreField && hasRetailId) {
      return (
        <Autocomplete
          value={user?.store}
          options={storeOpts}
          label={dictionary.STORES}
          validations={[SyncValidations.notEmpty]}
          getOptionLabel={(option: { name: string; storeCode: string }) =>
            `${option.name} (${option.storeCode})`
          }
          onChange={(e: any, store: any) => setUser({ ...user, store })}
          style={halfWidthInputStyle}
        />
      );
    }
    return '';
  }, [hasRetailId, renderStoreField, storeOpts, user]);

  const checkBoxStatus = useMemo(
    () => (
      <Checkbox
        checked={user?.status === 1}
        onChange={() =>
          setUser({
            ...user,
            status: utils.invertValue(user?.status),
          })
        }
      />
    ),
    [user]
  );

  const renderRGField = useMemo(() => {
    if (!user?.profile) {
      return false;
    }
    return user.profile.value === userProfile.PROMOTER;
  }, [user]);

  const rgField = useMemo(() => {
    if (renderRGField) {
      return (
        <div className="form-two-columns">
          <TextField
            value={user?.rg}
            label={dictionary.USER_RG}
            validations={[SyncValidations.notEmpty]}
            onChange={(e: any) => setUser({ ...user, rg: e.target.value })}
          />
        </div>
      );
    }
    return '';
  }, [renderRGField, user]);

  const submitUser = useCallback(
    async (event) => {
      event.preventDefault();
      if (event.currentTarget.form.reportValidity() && user) {
        let successFeedbackSnackbar = dictionary.USER_CREATED;

        try {
          if (isEditMode) {
            await editUser(user);
            successFeedbackSnackbar = dictionary.USER_EDITED;
          } else {
            await registerUser(user);
            await trackUserCreated({
              email: user.email,
              creationOrigin: dictionary.CREATED,
            });
          }

          const response = await getAllNotPromotersUsers({
            retailId: mainStore.loggedUser.retailId,
          });
          mainStore.users.data = response.items;
          openSnackBar(dictionary.SUCCESS, successFeedbackSnackbar);
          resetModal();
        } catch (e) {
          let errorFeedbackMessage = dictionary.FORM_INVALID;
          const { code } = e.response.data;
          switch (code) {
            case CreateUserErrorCode.DUPLICATED_CPF:
              errorFeedbackMessage = dictionary.CPF_ALREADY_REGISTERED;
              break;
            case CreateUserErrorCode.DUPLICATED_CPF_RETAIL:
              errorFeedbackMessage =
                dictionary.ALREADY_REGISTERED_USER_ON_RETAIL;
              break;
            case CreateUserErrorCode.DUPLICATED_EMAIL:
            case CreateUserErrorCode.DUPLICATED_EMAIL_RETAIL:
              errorFeedbackMessage = dictionary.EMAIL_ALREADY_REGISTERED;
              break;
            case CreateUserErrorCode.INVALID_CPF:
              errorFeedbackMessage = dictionary.INVALID_CPF;
              break;
          }

          openSnackBar(dictionary.ERROR, errorFeedbackMessage);
        } finally {
          mainStore.requestLoading = false;
        }
      } else {
        openSnackBar(dictionary.ERROR, dictionary.FORM_INVALID);
      }
    },
    [user, isEditMode, resetModal]
  );

  const userProfilesOpts = useMemo(() => {
    return getUsersProfiles().filter(
      (item) =>
        ![UserProfile.SALES_REP, UserProfile.PROMOTER_APPROVER].includes(
          item.value
        )
    );
  }, []);

  const handleChangeProfile = useCallback(
    (value) => {
      setUser({
        ...user,
        profile: value,
        retail: null,
        store: null,
        rg: undefined,
      });
    },
    [user]
  );

  const content = useMemo(() => {
    if (!isEditMode || (isEditMode && exist(user?.id))) {
      return (
        <>
          <div className="form-two-columns">
            <TextField
              value={user?.name}
              label={dictionary.USER_NAME}
              validations={[SyncValidations.notEmpty]}
              onChange={(e: any) => setUser({ ...user, name: e.target.value })}
            />

            <TextField
              value={user?.cpf}
              label={dictionary.USER_CPF}
              InputProps={{ inputComponent: CpfTextMaskCustom }}
              validations={[SyncValidations.notEmpty, SyncValidations.validCpf]}
              onChange={(e: any) => setUser({ ...user, cpf: e.target.value })}
            />
          </div>
          {!isEditMode && (
            <div className="form-two-columns">
              <TextField
                id="pass"
                type="password"
                autoComplete="off"
                value={user?.password}
                label={dictionary.PASSWORD}
                validations={[SyncValidations.notEmpty]}
                onChange={(e: any) =>
                  setUser({ ...user, password: e.target.value })
                }
              />
              <TextField
                type="password"
                id="confirmPass"
                value={confirmPassword}
                label={dictionary.CONFIRM_PASSWORD}
                validations={[SyncValidations.notEmpty, passwordMatch]}
                onChange={(e: any) => setConfirmPassword(e.target.value)}
              />
            </div>
          )}

          <div className="form-two-columns">
            <Autocomplete
              value={user?.profile}
              options={userProfilesOpts}
              label={dictionary.USER_PROFILE}
              validations={[SyncValidations.notEmpty]}
              onChange={(e: any, value: any) => handleChangeProfile(value)}
            />

            <TextField
              value={user?.email}
              label={dictionary.USER_EMAIL}
              validations={[
                SyncValidations.notEmpty,
                SyncValidations.validEmail,
              ]}
              onChange={(e: any) => setUser({ ...user, email: e.target.value })}
            />
          </div>
        </>
      );
    }
    return '';
  }, [
    handleChangeProfile,
    user,
    confirmPassword,
    isEditMode,
    passwordMatch,
    userProfilesOpts,
  ]);

  return (
    <>
      <ModalTitle
        title={isEditMode ? dictionary.EDIT_USER : dictionary.CREATE_USER}
      />
      <form noValidate>
        <DialogContent>
          {content}

          <div className="form-two-columns">
            {retailDropdown}
            {storeDropdown}
          </div>

          {rgField}

          <FormControlLabel
            style={{ margin: '9px 0 9px 0' }}
            control={checkBoxStatus}
            label={dictionary.ACTIVE_USER}
          />
        </DialogContent>

        <DialogActions>
          <CancelButton onClick={onClose} />
          <DefaultButton
            label={dictionary.SAVE}
            onClick={async (event: any) => await submitUser(event)}
            style={{ width: 'auto' }}
          />
        </DialogActions>
      </form>
    </>
  );
};
export default view(BaseModal);
